import _ from 'lodash'
import Popper from 'popper.js'

const SONG_UNIQUE_KEY = 'id'

const songTagsComponent = {
    controller: songTagsController,
    require: 'ngModel',
    bindings: {
        placeholder: '@?',
        placeholderArtist: '@?',
        placeholderAlbum: '@?',
        ngModel: '=',
    },
    template: `
    <div class="row">
        <div class="col-4">
            <header class="main-question-title">
                <label class="mb-0">Artist</label>
            </header>
            <div class="form-group">
                <input
                    type="text"
                    class="form-control"
                    placeholder="{{ ::$ctrl.placeholderArtist || 'Filter by Artist' }}"
                    ng-model="$ctrl.artist"
                />
            </div>
        </div>
        <div class="col-4">
            <header class="main-question-title">
                <label class="mb-0">Album</label>
            </header>
            <div class="form-group">
                <input
                    type="text"
                    class="form-control"
                    placeholder="{{ ::$ctrl.placeholderAlbum || 'Filter by Album' }}"
                    ng-model="$ctrl.album"
                />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-9">
            <header class="main-question-title">
                <label class="mb-0">Songs</label>
            </header>
            <div class="form-group highlight-errors" ng-class="{ 'has-error': $ctrl.noSongsFound }">
                <tags-input
                    class="tags-input-songs"
                    ng-model="$ctrl.ngModel"
                    x-key-property="${SONG_UNIQUE_KEY}"
                    display-property="$$computedTitle"
                    add-from-autocomplete-only="true"
                    replace-spaces-with-dashes="false"
                    placeholder="{{ ::$ctrl.placeholder || 'Add a Song' }}"
                    on-tag-removing="$ctrl.thumbnailControl.hideThumbnail()"
                >
                    <auto-complete source="$ctrl.songSearch.search($query, $ctrl.artist, $ctrl.album)"
                        min-length="0"
                        x-key-property="${SONG_UNIQUE_KEY}"
                        max-results-to-show="40"
                        display-property="$$computedTitle"
                    >
                    </auto-complete>
                </tags-input>

                <div class="messages-form-errors">
                    <span class="help-block">
                        No songs matching your search were found.
                    </span>
                </div>
                <span ng-show="$ctrl.songSearch.searching" class="help-block">
                    Searching...
                </span>
            </div>
        </div>
    </div>
    `,
}

export default songTagsComponent

/* @ngInject */
function songTagsController(NO_LOAD_OVERLAY, $q, $http, $scope, $element, $filter) {
    const $ctrl = this

    $ctrl.$onInit = $onInit

    //////////////////////

    class SongThumbnailControl {
        constructor($element) {
            this.$element = $element

            this.popper = null
            this.$thumbnail = null

            this.init()
        }

        init() {
            this.$element.on('mouseenter', 'ti-tag-item', this.fetchThumbnail.bind(this))
            this.$element.on('mouseleave', 'ti-tag-item', this.hideThumbnail.bind(this))
        }

        destroy() {
            this.hideThumbnail()
            this.$element.off('mouseenter', 'ti-tag-item')
            this.$element.off('mouseleave', 'ti-tag-item')
            this.$element = null
        }

        fetchThumbnail(e) {
            const song = this.getSong(e.target)
            if (!song) {
                return
            }
            this.hideThumbnail()

            this.$thumbnail = $(`<div class="popper card fade">`)
                .append(`<div class="popper__arrow"/>`)
                .append(`<div class="card-body"/></div>`)
                .appendTo('body')
                .data('song', song)

            this.popper = new Popper($(e.target).parents('li')[0], this.$thumbnail[0], {
                modifiers: {
                    preventOverflow: {
                        boundariesElement: 'viewport',
                        priority: ['top', 'left', 'right'],
                    },
                    arrow: {
                        element: '.popper__arrow',
                    },
                },
                removeOnDestroy: true,
            })

            if (!song.$$thumbnail) {
                // create a promise for the thumbnail that will be overwitten by the thumbnail itself
                song.$$thumbnail = $http
                    .get(`/api/qa/songs/thumbnail/${song.gnid}`, NO_LOAD_OVERLAY)
                    // extract thumbnail url from backend
                    .then((res) => '//' + res.data)
                    // set it on the song object
                    .then((thumbnailUrl) => (song.$$thumbnail = thumbnailUrl)) // eslint-disable-line
                    // or on 404 display a fallback error image
                    .catch(() => (song.$$thumbnail = '/images/dialog-warning-20.png')) // eslint-disable-line
                    // whatever happens, go to thumbnail display routine
                    .finally(this.displayThumbnail.bind(this))
            } else if (!song.$$thumbnail.then) {
                // if thumbnail is not a promise object, then it has already been loaded
                // so display it
                this.displayThumbnail()
            }

            ////////////////////////////////
        }

        displayThumbnail() {
            if (!this.$thumbnail || !this.popper) {
                return
            }

            const song = this.$thumbnail.data('song')
            if (song.$$thumbnail && !song.$$thumbnail.then) {
                this.$thumbnail.find('.card-body').append(`
                    <img src="${song.$$thumbnail}" />
                `)
                this.$thumbnail.addClass('show')
            }
        }

        hideThumbnail() {
            if (this.popper) {
                this.popper.destroy()
                this.popper = null
            }
            if (this.$thumbnail) {
                this.$thumbnail.remove()
                this.$thumbnail = null
            }
        }

        /**
         * Find a song by the text of the <ng-tags-input> <ti-tag-item> element.
         * We do it this way because we do not control that part of the DOM and cannot
         * easily add, for example, a data-id to the DOMNode
         *
         * @param {DOMNode} element
         * @return {Object|null}
         */
        getSong(element) {
            const $el = $(element)
            const itemText = $el.is('span')
                ? $el.text()
                : $el.is('a')
                ? $el.parent().find('span').text()
                : $el.find('span').text()

            return _.find($ctrl.ngModel, { $$computedTitle: _.trim(itemText) })
        }
    }

    /**
     * A convenience class that improves search behavior for ng-tags-input autocomplete.
     * It provides a single promise for multiple user inputs, and cancels requests that
     * are no longer valid.
     */
    class SongSearch {
        /**
         * @param {function} searchStartCallback Callback executed when a new search is triggered
         * @param {function} songTransformCallback Callback to transform the backend results before
         *      handing them off to <ng-tags-input>
         */
        constructor(searchStartCallback, songTransformCallback) {
            this.searchStartCallback = searchStartCallback
            this.songTransformCallback = songTransformCallback

            this.searching = false
            this.deferred = null
            this.httpCancelDeferred = null
        }

        /**
         * @param {string} title
         * @param {=string} artist
         * @param {=string} album
         *
         * @return {Promise}
         */
        search(title, artist = '', album = '') {
            this.searchStartCallback()

            // If we do not have a deferred (no recent searches) we create one,
            // and set a single resolve on its promise to the songTransformCallback.
            // Subsequent searches will return this same promise, that will be resolved
            // only once when we have a search complete without being interrupted.
            if (!this.deferred) {
                this.deferred = $q.defer()
                this.deferred.promise.then(this.songTransformCallback)
                this.searching = true
            }

            // If we have a http cancel deferred that means there is another incomplete
            // search request in flight. We use $http.timeout option to cancel it.
            if (this.httpCancelDeferred) {
                this.httpCancelDeferred.resolve(new Error('Cancelled'))
            }

            this.httpCancelDeferred = $q.defer()

            $http
                .get('/api/qa/songs/search', {
                    ...NO_LOAD_OVERLAY,
                    params: { title, artist, album },
                    timeout: this.httpCancelDeferred.promise,
                })
                .then((res) => {
                    this.deferred.resolve(res.data)
                    this.deferred = null
                    this.httpCancelDeferred = null
                    this.searching = false
                })
                .catch(angular.noop)

            return this.deferred.promise
        }
    }

    return $ctrl

    /////////////////////////////////////

    function $onInit() {
        $ctrl.artist = ''
        $ctrl.album = ''
        $ctrl.noSongsFound = false

        $ctrl.songSearch = new SongSearch(
            function searchStartCallback() {
                $ctrl.noSongsFound = false
            },
            function songTransformCallback(songs) {
                if (!_.size(songs)) {
                    $ctrl.noSongsFound = true
                }

                return _.map(songs, addComputedTitle)
            }
        )

        // we need some special handling for input so that we delete the `no-songs-found` error
        $scope.$applyAsync(() => {
            $element.find('tags-input').on('input', 'input', () => {
                $scope.$applyAsync(() => {
                    $ctrl.noSongsFound = false
                })
            })
        })

        $scope.$watchCollection('$ctrl.ngModel', (songs, oldSongs) => {
            // if a song is added, clear the artist filter
            if (_.size(songs) > _.size(oldSongs)) {
                $ctrl.artist = ''
            }

            // make sure we have only unique songs
            const uniqueSongs = _.uniqBy(songs, SONG_UNIQUE_KEY)
            if (_.size(songs) !== _.size(uniqueSongs)) {
                $ctrl.ngModel = uniqueSongs
                return
            }

            $ctrl.noSongsFound = false

            return _.map(songs, addComputedTitle)
        })

        $ctrl.thumbnailControl = new SongThumbnailControl($element)
        $scope.$on('$destroy', $ctrl.thumbnailControl.destroy.bind($ctrl.thumbnailControl))
    }

    function addComputedTitle(song) {
        return _.assignIn(song, {
            $$computedTitle: $filter('songTitle')(song),
        })
    }
}
