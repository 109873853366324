import dateIsValid from 'date-fns/is_valid'
import dateFormat from 'date-fns/format'

/**
 * @ngdoc directive
 * @name bootstrapDatepicker
 * @module map3.core
 * @restrict A
 *
 * @description
 *
 * A wrapper around jQuery bootstrap-datepicker that provides an interface
 * for working with dates as short ISO8601 strings (YYYY-MM-DD)
 *
 * Use:
 *      <input type="date" ng-mode="model" bootstrap-datepicker />
 */
export default /* @ngInject */ function bootstrapDatepickerDirective($q) {
    const directive = {
        restrict: 'A',
        require: 'ngModel',
        link: bootstrapDatepickerDirectiveLinkFn,
    }

    return directive

    ////////////////////////////////////////////

    function bootstrapDatepickerDirectiveLinkFn(scope, element, attrs, ngModelCtrl) {
        // convert input string to Date object for angular input[date] internal use
        ngModelCtrl.$formatters.push(function (value) {
            if (value && dateIsValid(new Date(value))) {
                value = new Date(value)
            }

            return value
        })

        // conver back to YYYY-MM-DD as ng-model output
        ngModelCtrl.$parsers.push(function (value) {
            if (value instanceof Date) {
                value = dateFormat(value, 'YYYY-MM-DD')
            }

            return value
        })

        // load bootstrap-datepicker
        $q.all([
            import('bootstrap-datepicker'),
            import('bootstrap-datepicker/dist/css/bootstrap-datepicker3.css'),
        ]).then(function () {
            // if the scope was destroyed while we were loading, do nothing
            if (scope.$$destroyed) {
                return
            }

            // init the datepicker
            element.datepicker({
                format: attrs.format || 'yyyy-mm-dd',
            })

            // remove browser date widget
            element.attr('type', 'text')

            // capture browser date input changes and update bootstrap-datepicker
            ngModelCtrl.$viewChangeListeners.push(function () {
                element.datepicker('update')
            })

            // destroy the datepicker on scope destruction to prevent memory leaks
            scope.$on('$destroy', () => element.datepicker('destroy'))
        })
    }
}
