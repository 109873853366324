import GlobalShortcutsFactory from './GlobalShortcuts.factory'

import shortcutsDirective from './shortcuts.directive'
import prettifyShortcutKeysDirective from './prettifyShortcutKeys.directive'

/**
 * @ngdoc module
 * @name map3.global-shortcuts
 * @module map3.global-shortcuts
 *
 * @description
 * Global shortcuts
 */
const map3globalShortcuts = angular
    .module('map3.globalShortcuts', [])

    .factory('GlobalShortcuts', GlobalShortcutsFactory)
    .directive('shortcuts', shortcutsDirective)
    .directive('prettifyShortcutKeys', prettifyShortcutKeysDirective)

export default map3globalShortcuts.name
