/**
 * @ngdoc directive
 * @name passwordVisible
 * @module map3.core
 * @restrict AE
 *
 * @description
 * Toggle a `<input type=password />` field to `<input type=text />` and back
 *
 * Will search for the password inputs between it siblings and operate on them
 *
 * Example:
 *
 * ```html
 * <div>
 *   <input type="password" />
 *   <i password-visible class="btn-visible icon-eye is-on"></i>
 * </div>
 * ```
 */

export default /* @ngInject */ function passwordVisibleDirective() {
    var directive = {
        restrict: 'AE',
        link: passwordVisibleLinkFn,
    }

    return directive

    function passwordVisibleLinkFn(scope, element, attrs) {
        var inputElement = $(element).parent().find('input')

        if (inputElement.length) {
            element.bind('click', function (event) {
                // toggle field type
                if (inputElement.attr('type') === 'password') {
                    inputElement.attr('type', 'text')
                } else {
                    inputElement.attr('type', 'password')
                }

                element.toggleClass('is-on')
            })
        }
    }
}
