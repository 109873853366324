import _ from 'lodash'
import { ActionCreators } from 'redux-undo'

/**
 * @ngdoc directive
 * @name scenesViewer
 * @module map3.scenes
 * @restrict E
 *
 * @description
 * A convenience directive for displaying scene splitting without enabling full editing mode
 *
 * @scope
 *
 * @param {object} sceneData Object like `{ acts:[], scenes: [], subScenes: [] }`.
 *      Does not have to have all properties
 * @param {boolean=} showActs If the acts should be rendered. Default: `true`
 * @param {boolean=} showScenes If the scenes should be rendered. Default: `true`
 * @param {boolean=} showSubScenes If the sub scenes should be rendered. Default: `true`
 */
export default /* @ngInject */ function scenesViewerDirective(
    SS_ZOOM_MAX_SECONDS,
    SS_ZOOM_SWITCH_SECONDS,
    $ngRedux,
    SharedVideoAPI,
    ScenesReducer,
    SceneActions,
    SceneActionCreators
) {
    var directive = {
        restrict: 'E',
        scope: {
            sceneData: '<',

            interactive: '<?',
            showActs: '<?',
            showScenes: '<?',
            showSubScenes: '<?',
        },
        template: `
            <scene-splitting edit-mode="false" interactive-mode="interactive !== false">
                <section class="scene-section" ng-if="sceneData.acts.length > 1 && showActs">
                    <div class="scene-row d-flex">
                        <selection-info
                            filmstrip-type="actsFilmstrip"
                            type="acts"
                            render-mode="actsRenderMode"
                        ></selection-info>

                        <acts-timeline
                            acts="parsedSceneData.acts"
                            filmstrip-type="actsFilmstrip"
                            render-mode="actsRenderMode"
                        ></acts-timeline>
                    </div>
                </section>

                <section class="scene-section" ng-if="sceneData.scenes.length > 1 && showScenes">
                    <div class="scene-row d-flex">
                        <selection-info
                            filmstrip-type="scenesFilmstrip"
                            type="scenes"
                            render-mode="scenesRenderMode"
                        ></selection-info>

                        <scenes-timeline
                            scenes="parsedSceneData.scenes"
                            filmstrip-type="scenesFilmstrip"
                            render-mode="scenesRenderMode"
                        ></scenes-timeline>
                    </div>
                </section>

                <section class="scene-section" ng-if="sceneData.subScenes.length > 1 && showSubScenes">
                    <div class="scene-row d-flex">
                        <selection-info
                            filmstrip-type="subScenesFilmstrip"
                            type="subScenes"
                            render-mode="subScenesRenderMode"
                        ></selection-info>

                        <sub-scenes-timeline
                            sub-scenes="parsedSceneData.subScenes"
                            filmstrip-type="subScenesFilmstrip"
                            render-mode="subScenesRenderMode"
                        ></sub-scenes-timeline>
                    </div>
                </section>
            </scene-splitting>
        `,
        link: scenesViewerLinkFn,
    }

    return directive

    //////////////////////////////////////////////

    function scenesViewerLinkFn(scope) {
        let videoApi

        // default scene/subscene visibility is true
        scope.showActs = scope.showActs !== false
        scope.showScenes = scope.showScenes !== false
        scope.showSubScenes = scope.showSubScenes !== false

        scope.actsRenderMode = scope.scenesRenderMode = scope.subScenesRenderMode = 'condensed'

        scope.interactive = scope.interactive !== false

        activate()

        ////////////////////

        function activate() {
            scope.$watch('sceneData', parseSceneData)
            SharedVideoAPI.onLoad(handleVideoApiReady)
        }

        function handleVideoApiReady(videoApiParam) {
            videoApi = videoApiParam

            parseSceneData(scope.sceneData)
            autoZoomIn()
            setupFilmstrip()
        }

        function parseSceneData(sceneData) {
            if (!videoApi) {
                return
            }

            if (scope.interactive) {
                // TODO implement interactive scene viewer
                SceneActions.initWith({ sceneData: scope.sceneData, videoApi })
                $ngRedux.dispatch(ActionCreators.clearHistory())
            } else {
                const reducedSegments = ScenesReducer(
                    {},
                    SceneActionCreators.initWith({
                        sceneData: scope.sceneData,
                        videoApi,
                    })
                )

                scope.parsedSceneData = _.mapValues(reducedSegments, parseImmutableSegments)
            }

            //////////////////

            function parseImmutableSegments(immutableSegments) {
                if (immutableSegments) {
                    // non interactive segments must not be rendered as selected
                    immutableSegments = _.map(immutableSegments.toJS(), (segment) => {
                        segment.$$isSelected = false
                        return segment
                    })
                }

                return immutableSegments
            }
        }

        function autoZoomIn() {
            // auto zoom-in
            const zoomMax = videoApi.getDuration() / SS_ZOOM_MAX_SECONDS
            const zoomSwitch = videoApi.getDuration() / SS_ZOOM_SWITCH_SECONDS
            const zoom = (zoomSwitch * 100) / zoomMax + 1

            $ngRedux.dispatch({
                type: 'SCENES_SET_ZOOM',
                payload: zoom,
            })
        }

        function setupFilmstrip() {
            videoApi.getFilmStripDrawer(() => {
                scope.actsFilmstrip = 'disabled'
                scope.scenesFilmstrip = 'disabled'
                scope.subScenesFilmstrip = 'disabled'
            })
        }
    }
}
