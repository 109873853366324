import _ from 'lodash'

export default function EditAnnotationFactory() {
    const EditAnnotation = {
        createEditCopy(annotation) {
            annotation.$$editing = {
                ...angular.copy(annotation),
            }
        },

        duplicate(annotations, annotation) {
            const duplicatedAnnotation = {
                ...angular.copy(annotation),
                $$editing: {
                    ...angular.copy(annotation),
                    $$duplicate: true,
                },
            }

            annotations.splice(_.indexOf(annotations, annotation) + 1, 0, duplicatedAnnotation)

            return duplicatedAnnotation
        },

        duplicatedAnnotation(annotation) {
            return { ...angular.copy(annotation) }
        },

        cancelEdit(annotations, annotation) {
            if (annotation.$$editing.$$duplicate) {
                _.remove(annotations, annotation)
            } else {
                annotation.$$editing = false
            }
        },

        saveEdit(annotations, annotation) {
            annotations.splice(
                _.indexOf(annotations, annotation),
                1,
                _.omit(annotation.$$editing, ['$$duplicate', '$$editMode'])
            )
        },
    }
    return EditAnnotation
}
