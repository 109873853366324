import _ from 'lodash'

const FILMSTRIP_DEFAULT_WIDTH = 144
const FILMSTRIP_DEFAULT_HEIGHT = 80

/**
 * @ngdoc type
 * @name FilmstripDrawParams
 *
 * @property {DOMRect} timelineRect
 * @property {DOMRect} segmentRect
 * @property {number} canvasLeft
 * @property {number} canvasWidth
 * @property {number} startFrame
 * @property {number} endFrame
 * @property {Array.<number>} filmstripFrames
 * @property {number} numFilmstripFrames
 */

/**
 * @ngdoc type
 * @name FilmstripCalculator
 * @module map3.scenes
 *
 * @description
 * A class tasked with calculating the parameters to render a filmstrip
 */
export default class FilmstripCalculator {
    constructor(opts = {}) {
        this.frameWidth = _.get(opts, 'frameWidth', FILMSTRIP_DEFAULT_WIDTH)
        this.frameHeight = _.get(opts, 'frameHeight', FILMSTRIP_DEFAULT_HEIGHT)
        this.frameRatio = _.get(opts, 'frameRatio', this.frameWidth / this.frameHeight)
        this.canvasMaxWidthBase = _.get(opts, 'canvasMaxWidthBase', 5000)

        this.forceRedraw = false

        this.lastDrawParams = null
        this.destroyed = false
    }

    /**
     * @ngdoc method
     * @name FilmstripCalculator#calculateWidth
     *
     * @description
     * Calculate the width of a single fimstrip frame given the timeline
     * element height
     *
     * @param {number} height
     * @return {number}
     */
    calculateFrameWidth(height) {
        return height * this.frameRatio
    }

    /**
     * @ngdoc method
     * @name FilmstripCalculator#getFrameRatio
     *
     * @description
     * Return the calculated frame ratio
     *
     * @return {number}
     */
    getFrameRatio() {
        return this.frameRatio
    }

    /**
     * @ngdoc method
     * @name FilmstripCalculator#findClosestToRatio
     *
     * @description
     * Given a goal number, find the closest integer that is an exact ratio of our filmstrip ratio
     *
     * @param {number} goal
     * @param {number=} clamp Optional maximum difference from goal
     * @return {number}
     */
    findClosestToRatio(goal, clamp = false) {
        const exacts = _.range(1000).filter((i) => (i * this.frameRatio) % 1 === 0)

        const number = _.reduce(exacts, (prev, curr) => {
            return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
        })

        // if we couldn't find a number, or are outside the clamp
        if (!number || (clamp && Math.abs(goal - number) > clamp)) {
            return goal
        }

        return number
    }

    /**
     * @param {object} filmstripParams
     * @param {object=} extraData
     *
     * @return {FilmstripDrawParams|false}
     */
    buildDrawParams({ segmentRect, timelineRect, startFrame, endFrame }, extraData = {}) {
        // do not render segments that are not currently visible
        if (!this.$isVisible(segmentRect, timelineRect)) {
            return false
        }

        // do not render segments that are less half a frame
        if (segmentRect.width < this.frameWidth / 2) {
            return false
        }

        const frameDuration = endFrame - startFrame + 1
        const numFilmstripFrames = this.$calculateFilmstripFrames(segmentRect, frameDuration)
        // do not render if for some reason there are no filmstrip frames
        if (!numFilmstripFrames) {
            return false
        }

        const inSceneEndFrameX = (numFilmstripFrames - 1) * this.frameWidth
        // the newly calculated end frame, based on how many we can render
        endFrame = Math.min(
            endFrame,
            startFrame + Math.round((inSceneEndFrameX / segmentRect.width) * frameDuration)
        )

        // array of frame numbers to be rendered as filmstrip thumbs
        const filmstripFrames = this.$buildFilmstripFrames(startFrame, endFrame, numFilmstripFrames)

        const canvasLeft = 0
        const canvasWidth = this.$calculateCanvasWidth(numFilmstripFrames)

        const baseDrawParams = {
            segmentRect,
            timelineRect,
            canvasLeft,
            canvasWidth,
            startFrame,
            endFrame,
            filmstripFrames,
            numFilmstripFrames: filmstripFrames.length,
            videoIsPlaying: !!extraData.videoIsPlaying,
        }

        if (extraData.forceOneFrame) {
            return {
                ...baseDrawParams,
                canvasWidth: this.frameWidth,
                endFrame: startFrame,
                filmstripFrames: filmstripFrames.slice(0, 1),
                numFilmstripFrames: 1,
                ...extraData,
            }
        }

        const drawParams = this.$applyPartialRender(baseDrawParams)

        return {
            ...drawParams,
            ...extraData,
        }
    }

    /**
     * @param {FilmstripDrawParams} lastDrawParams
     */
    setLastDrawParams(lastDrawParams) {
        if (!this.destroyed) {
            this.lastDrawParams = lastDrawParams
        }
    }

    setForceRedraw(forceRedraw) {
        this.forceRedraw = forceRedraw
    }

    destroy() {
        // prevent memory leaking from leftover DOMRect references
        this.lastDrawParams = null
        this.destroyed = true
    }

    /**
     * @param {FilmstripDrawParams} drawParams
     * @return {boolean}
     */
    needsRedraw(drawParams) {
        if (!drawParams) {
            return false
        }

        // do not attempt to draw if zero frames
        if (drawParams.numFilmstripFrames === 0) {
            return false
        }

        // if force redraw
        if (!this.lastDrawParams || this.forceRedraw) {
            this.forceRedraw = false

            return true
        }

        // if we switch draw modes
        if (drawParams.forceOneFrame !== this.lastDrawParams.forceOneFrame) {
            return true
        }

        // if the start is before the last drawn start frame
        if (drawParams.startFrame !== this.lastDrawParams.startFrame) {
            return true
        }

        // if the number of frames to draw has increased
        if (drawParams.numFilmstripFrames > this.lastDrawParams.numFilmstripFrames) {
            return true
        }

        // we are scrolling back
        if (drawParams.canvasLeft < this.lastDrawParams.canvasLeft) {
            return true
        }

        // if canvas does not reach end of segment
        const lastCanvasRight = this.lastDrawParams.canvasWidth + this.lastDrawParams.canvasLeft
        if (lastCanvasRight < drawParams.segmentRect.width) {
            // and canvas end is within timeline visible part
            const accountedRight =
                drawParams.segmentRect.left - drawParams.timelineRect.left + lastCanvasRight

            if (accountedRight < drawParams.timelineRect.width) {
                return true
            }
        }

        // if segment width has changed by more than 20%
        const widthChange = Math.abs(
            (this.lastDrawParams.segmentRect.width - drawParams.segmentRect.width) /
                this.lastDrawParams.segmentRect.width
        )
        if (widthChange > 0.2) {
            return true
        }

        // fall through to no redraw
        return false
    }

    /**
     * @param {FilmstripDrawParams}
     * @return {FilmstripDrawParams}
     */
    $applyPartialRender({
        segmentRect,
        timelineRect,
        canvasLeft,
        canvasWidth,
        startFrame,
        endFrame,
        filmstripFrames,
        videoIsPlaying,
    }) {
        const BUFFER = videoIsPlaying ? 0 : this.$calculateBuffer(timelineRect)

        // if we are rendering into a canvas smaller than the segment
        if (canvasWidth < segmentRect.width) {
            // if the segment start is outside the timeline start
            if (segmentRect.left < timelineRect.left) {
                // we have to offset our render start frame

                const segmentVisibleLeftEdge = Math.floor(
                    Math.abs(segmentRect.left - timelineRect.left)
                )

                const startFrameIndex = Math.max(
                    0,
                    Math.floor((segmentVisibleLeftEdge - BUFFER) / this.frameWidth)
                )

                canvasLeft = startFrameIndex * this.frameWidth
                filmstripFrames = filmstripFrames.slice(startFrameIndex)
                startFrame = filmstripFrames[0]
            }

            // if the canvas does not reach the segment end
            if (canvasLeft + canvasWidth < segmentRect.width) {
                // we need to update the render end frame

                const endFrameIndex = Math.floor(canvasWidth / this.frameWidth)

                filmstripFrames = filmstripFrames.slice(0, endFrameIndex)
                endFrame = filmstripFrames[filmstripFrames.length - 1]
            }

            canvasWidth = filmstripFrames.length * this.frameWidth
        }

        return {
            segmentRect,
            timelineRect,
            canvasLeft,
            canvasWidth,
            startFrame,
            endFrame,
            filmstripFrames,
            numFilmstripFrames: filmstripFrames.length,
        }
    }

    /**
     * Check if the segment is visible on the timeline
     *
     * @param {DOMRect} segmentRect
     * @param {DOMRect} timelineRect
     *
     * @return {boolean}
     */
    $isVisible(segmentRect, timelineRect) {
        if (segmentRect.left < timelineRect.left && segmentRect.right >= timelineRect.right) {
            return true
        }

        if (_.inRange(segmentRect.right, timelineRect.left, timelineRect.right)) {
            return true
        }

        if (_.inRange(segmentRect.left, timelineRect.left, timelineRect.right)) {
            return true
        }

        return false
    }

    /**
     * Number of frames of filmstrip to be rendered for the current segment
     *
     * @param {DOMRect} segmentRect
     * @param {number} frameDuration
     *
     * @return {number}
     */
    $calculateFilmstripFrames(segmentRect, frameDuration) {
        return _.clamp(Math.ceil(segmentRect.width / this.frameWidth), frameDuration)
    }

    $buildFilmstripFrames(startFrame, endFrame, numberOfFrames) {
        const frameSplit = numberOfFrames < 2 ? 1 : numberOfFrames - 1
        const frameStep = (endFrame - startFrame) / frameSplit

        const frameNumbers = Array(numberOfFrames)
        let frameIndex = -1

        while (++frameIndex < numberOfFrames) {
            frameNumbers[frameIndex] = startFrame + Math.round(frameStep * frameIndex)
        }

        return frameNumbers
    }

    $calculateCanvasWidth(numFilmstripFrames) {
        const maxCanvasWidth =
            Math.floor(this.canvasMaxWidthBase / this.frameWidth) * this.frameWidth

        return _.clamp(numFilmstripFrames * this.frameWidth, maxCanvasWidth)
    }

    /**
     * A draw buffer for partial rendering
     *
     * @param {DOMRect} timelineRect
     * @return {number}
     */
    $calculateBuffer(timelineRect) {
        // return 0

        // the timeline width, divided to two and matched to frame width
        return Math.ceil(timelineRect.width / 2 / this.frameWidth) * this.frameWidth
    }
}
