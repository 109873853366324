import angular from 'angular'
import TaskListCtrl from './TaskList.ctrl'
import AnnotateTaskCtrl from './AnnotateTask.ctrl'
import SplitScenesCtrl from '../scenes/SplitScenes.ctrl'
import HighlightVideoCtrl from '../highlights/HighlightVideo.ctrl'
import AuthorityTaskCtrl from './AuthorityTask.ctrl'
import AnnotateImageCtrl from './AnnotateImage.ctrl'
import AnnotateMultipleImageCtrl from './AnnotateMultipleImage.ctrl'
import ShapeTaggingCtrl from './ShapeTagging.ctrl'
import ContentMarkersCtrl from 'content-markers/ContentMarkers.ctrl'
import WatchVideoTaskCtrl from './WatchVideoTask.ctrl'

import softInvariant from '../util/softInvariant'
import { WORKER_ROUTES } from 'constants.es6'
import { IWorkerAssignment, WorkerAssignmentClass } from './types'

type IWorkerAssignmentWithType = IWorkerAssignment & { task_type: string }

export default /* @ngInject */ function tasksRoutesConfig($stateProvider: ng.ui.IStateProvider) {
    /* eslint angular/controller-as-route: "off" */
    $stateProvider
        .state('worker', {
            url: '/worker',
            abstract: true,
            template: '<ui-view></ui-view>',
            data: { roles: ['Worker'] },
        })
        .state(WORKER_ROUTES.WORKER_LIST.STATE_NAME, {
            url: WORKER_ROUTES.WORKER_LIST.URL,
            controllerAs: 'vm',
            controller: TaskListCtrl,
            resolve: TaskListCtrl.resolve,
            templateUrl: 'js/worker/task.list.html',
        })
        .state('tasks.find', {
            url: '/:assignmentID',
            /*eslint angular/controller-as-route: "off"*/
            controller: /* @ngInject */ function (
                TaskService: any,
                TaskRouter: TaskRouterInstance,
                assignmentID: any
            ) {
                TaskService.getTask(assignmentID).then((task: IWorkerAssignmentWithType) => {
                    return TaskRouter.go(task)
                })
            },
            resolve: {
                assignmentID: function ($stateParams: ng.ui.IStateParamsService) {
                    return $stateParams.assignmentID
                },
            },
        })
        .state('worker.annotate', {
            url: '/:assignmentID/answer-questions?{threadId:int}&{commentId:int}',
            templateUrl: 'js/worker/task.annotate.html',
            controllerAs: 'vm',
            controller: AnnotateTaskCtrl,
            resolve: AnnotateTaskCtrl.resolve,
            data: {
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                showInBugReport: true,
                forceReload: true,
            },
        })
        .state(WORKER_ROUTES.WATCH_VIDEO.STATE_NAME, {
            url: WORKER_ROUTES.WATCH_VIDEO.URL,
            templateUrl: 'js/worker/watch.video.html',
            controllerAs: 'vm',
            controller: WatchVideoTaskCtrl,
            resolve: WatchVideoTaskCtrl.resolve,
            data: {
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                showInBugReport: true,
                forceReload: true,
            },
        })
        .state('worker.splitScenes', {
            url: '/:assignmentID/split-scenes?{threadId:int}&{commentId:int}',
            templateUrl: 'js/scenes/SplitScenes.tpl.html',
            controllerAs: SplitScenesCtrl.controllerAs,
            controller: SplitScenesCtrl,
            resolve: SplitScenesCtrl.resolveWorker,
            data: {
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                showInBugReport: true,
                forceReload: true,
            },
        })
        .state('worker.highlightVideo', {
            url: '/:assignmentID/highlight-video?{threadId:int}&{commentId:int}',
            templateUrl: 'js/highlights/HighlightVideo.html',
            controllerAs: 'vm',
            controller: HighlightVideoCtrl,
            resolve: HighlightVideoCtrl.resolveWorker,
            data: {
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                showInBugReport: true,
                forceReload: true,
            },
        })
        .state(WORKER_ROUTES.CONTENT_MARKERS.STATE_NAME, {
            url: WORKER_ROUTES.CONTENT_MARKERS.URL,
            templateUrl: 'js/content-markers/content-markers.html',
            controllerAs: 'vm',
            controller: ContentMarkersCtrl,
            resolve: ContentMarkersCtrl.resolveWorker,
            data: {
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                showInBugReport: true,
            },
        })
        .state('worker.authority', {
            url: '/:assignmentID/authority?{threadId:int}&{commentId:int}',
            templateUrl: 'js/worker/task.authority.html',
            controllerAs: 'authorityTaskVm',
            controller: AuthorityTaskCtrl,
            resolve: AuthorityTaskCtrl.resolve,
            data: {
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                showInBugReport: true,
                forceReload: true,
            },
        })
        .state('worker.shapeTagging', {
            url: '/:assignmentID/shape-tagging',
            templateUrl: 'js/worker/task.shape-tagging.html',
            controllerAs: 'vm',
            controller: ShapeTaggingCtrl,
            resolve: ShapeTaggingCtrl.resolve,
            data: {
                footerHidden: true,
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                forceReload: true,
            },
        })
        .state('worker.imageTagging', {
            url: '/:assignmentID/annotate-image',
            templateUrl: 'js/worker/task.annotate-image.html',
            controllerAs: 'vm',
            controller: AnnotateImageCtrl,
            resolve: AnnotateImageCtrl.resolve,
            data: {
                footerHidden: true,
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                forceReload: true,
            },
        })
        .state('worker.multipleImageTagging', {
            url: '/:assignmentID/annotate-multiple-images',
            templateUrl: 'js/worker/task.annotate-multiple-images.html',
            controllerAs: 'vm',
            controller: AnnotateMultipleImageCtrl,
            resolve: AnnotateMultipleImageCtrl.resolve,
            data: {
                footerHidden: true,
                trackTime: {
                    userType: 'worker',
                    taskType: 'assignment',
                    taskId: 'assignmentID',
                },
                forceReload: true,
            },
        })
}

export /* @ngInject */ function TaskRouterFactory($state: ng.ui.IStateService) {
    const STATE_FOR_CLASS = {
        [WorkerAssignmentClass.tagScenes]: 'worker.splitScenes',
        [WorkerAssignmentClass.questionVideo]: 'worker.annotate',
        [WorkerAssignmentClass.cutntag]: 'worker.highlightVideo',
        [WorkerAssignmentClass.authorityTagging]: 'worker.authority',
        [WorkerAssignmentClass.shapeTagging]: 'worker.shapeTagging',
        [WorkerAssignmentClass.imageTagging]: 'worker.imageTagging',
        [WorkerAssignmentClass.multipleImageTagging]: 'worker.multipleImageTagging',
        [WorkerAssignmentClass.marker]: WORKER_ROUTES.CONTENT_MARKERS.STATE_NAME,
    }

    class TaskRouter {
        static stateFor(task: IWorkerAssignmentWithType) {
            const state = STATE_FOR_CLASS[task['class']]
            const taskType = task.task_type
            if (taskType === 'Watch' && state === STATE_FOR_CLASS['questionVideo']) {
                return WORKER_ROUTES.WATCH_VIDEO.STATE_NAME
            }

            softInvariant(
                !!state,
                'Unsupported Task type: "%s" for task %s',
                task['class'],
                angular.toJson(task)
            )

            return state
        }

        static urlFor(task: IWorkerAssignmentWithType) {
            return $state.href(TaskRouter.stateFor(task), {
                assignmentID: task.assignmentID,
            })
        }

        static go(task: IWorkerAssignmentWithType) {
            return $state.go(TaskRouter.stateFor(task), {
                assignmentID: task.assignmentID,
            })
        }
    }

    return TaskRouter
}

export type TaskRouterInstance = ReturnType<typeof TaskRouterFactory>
