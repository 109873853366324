import _ from 'lodash'
import { FREE_TEXT_TAGGING_CLASS } from 'constants.es6'

/* @ngInject */
function createQuestionsCtrl($scope, $element) {
    const vm = this

    vm.searchString = ''

    $element.on('hide.bs.dropdown', () => {
        $scope.$applyAsync(() => {
            vm.searchString = ''
        })
    })
}

/**
 * @param {Array(Question)} questions Array of question definitions
 */
export default /* @ngInject */ function createQuestionsDirective(MapDialog) {
    var directive = {
        restrict: 'E',
        scope: {
            questions: '<',
            selection: '=',
            limitMainQuestionsTo: '<?limitTo',
            multipleAllowed: '<?',
            makeMainMandatoryOnDetailsAdd: '<?',
            useAutomationTaxonomy: '=?',
        },
        templateUrl: 'js/admin/directives/createQuestions.tpl.html',
        link: createQuestionsLinkFn,
        controller: createQuestionsCtrl,
        controllerAs: 'vm',
    }

    return directive

    /////////////////////////////////////

    function createQuestionsLinkFn(scope, element, attr) {
        // boolean attrs
        scope.showQuestionDetails = !!_.isUndefined(attr.showMainQuestionsOnly)
        scope.showAlgorithms = !_.isUndefined(attr.isAssistedShowAlgorithms)
        scope.isAssistedHideOptions = !_.isUndefined(attr.isAssistedHideOptions)
        scope.hideMandatoryCheckbox = !_.isUndefined(attr.hideMandatoryCheckbox)
        scope.useFreeTextTagging = !_.isUndefined(attr.useFreeTextTagging)
        scope.multipleAllowed = scope.multipleAllowed || ['main', 'detail']
        scope.makeMainMandatoryOnDetailsAdd = scope.makeMainMandatoryOnDetailsAdd || false

        scope.addNewMainQuestion = addNewMainQuestion
        scope.addDetailToQuestion = addDetailToQuestion
        scope.getAlgorithmsFor = getAlgorithmsFor
        scope.removeQuestion = removeQuestion
        scope.onQuestionMultipleChange = onQuestionMultipleChange
        scope.isRecurring = isRecurring
        scope.addNewFreeTaggingQuestion = addNewFreeTaggingQuestion
        scope.isFreeTaggingQuestion = isFreeTaggingQuestion

        scope.$watch(
            'selection',
            function (selection) {
                if (scope.makeMainMandatoryOnDetailsAdd) {
                    _.forEach(selection, (question) => {
                        if (question.details.length) {
                            question.mandatory = true
                        }
                    })
                }
                if (scope.limitMainQuestionsTo) {
                    scope.disableOnMaxSelection =
                        scope.selection.length >= scope.limitMainQuestionsTo
                }
            },
            /* deep */ true
        )

        ////////////////////////////////////////////////////

        function addNewMainQuestion(question) {
            scope.selection.push({
                class: question['class'],
                label: question.label,
                mandatory: false,
                details: [],
            })
        }

        function addNewFreeTaggingQuestion() {
            scope.selection.push({
                class: FREE_TEXT_TAGGING_CLASS,
                label: '',
                mandatory: false,
                details: [],
            })
        }

        function addDetailToQuestion(detail, question) {
            question.details.push({ ...detail, mandatory: false })
        }

        function removeQuestion(question, questions) {
            _.remove(questions, question)
        }
        function onQuestionMultipleChange(question) {
            if (question.multiple && question.details.length) {
                showDeleteConfirmationModal(question)
            }
        }

        function showDeleteConfirmationModal(question) {
            let confirmDialog = MapDialog.confirm()
                .title('Are you sure?')
                .textContent(
                    `
                    It's not possible to have details on questions with multiple answers allowed.
                    If you continue, all question details will be removed!
                `
                )
                .ok('Yes')
                .cancel('No')

            MapDialog.show(confirmDialog)
                .then(() => {
                    question.details = []
                })
                .catch(() => {
                    question.multiple = false
                })
        }

        function getAlgorithmsFor(question) {
            return _.get(_.find(scope.questions, { class: question.class }), 'algorithms', [])
        }

        function isRecurring(question, selectedArray) {
            if (_.find(selectedArray, { class: question['class'] })) {
                return true
            }

            return false
        }

        function isFreeTaggingQuestion(question) {
            return question.class === FREE_TEXT_TAGGING_CLASS
        }
    }
}
