import _ from 'lodash'
import angular from 'angular'
import Mousetrap from 'mousetrap'
import 'mousetrap/plugins/global-bind/mousetrap-global-bind'
import { handleKeyboardShortcut } from 'util/snowplow'

export interface NonEmptyArray<A> extends Array<A> {
    0: A
}

export type ShortcutDefinition = {
    description: string
    shortcut: string
    global?: boolean
    keyCombo?: string | NonEmptyArray<string>
    useCtrlKey?: true
    callback?: () => void
    metadata?: Record<string, unknown>
}

export type ShortcutsGroup = {
    title: string
    shortcuts: ShortcutDefinition[]
}

export default /* @ngInject */ function GlobalShortcutsFactory($rootScope: ng.IRootScopeService) {
    const DEFAULT_SHORTCUTS: ShortcutsGroup = {
        title: 'Help',
        shortcuts: [
            {
                description: 'Help',
                keyCombo: '?',
                shortcut: '?',
                callback: function () {
                    if (!GlobalShortcuts.isEmpty()) {
                        $rootScope.$emit('globalShortcuts.showHelp')
                    }
                },
            },
        ],
    }

    let currentShortcuts: ShortcutsGroup[] = []
    let suspendedShortcuts: ShortcutsGroup[] = []

    const GlobalShortcuts = {
        bind(shortcutsGroup: ShortcutsGroup) {
            const activeShortcuts = GlobalShortcuts.getActiveShortcuts()

            // groups with the same `title` are merged
            const matchingGroup = _.find(activeShortcuts, {
                title: shortcutsGroup.title,
            })
            if (matchingGroup) {
                matchingGroup.shortcuts = matchingGroup.shortcuts.concat(shortcutsGroup.shortcuts)
            } else {
                activeShortcuts.push(shortcutsGroup)
            }

            if (!GlobalShortcuts.isSuspended()) {
                GlobalShortcuts.$mousetrapBind(shortcutsGroup)
            }

            return () => GlobalShortcuts.unbind(shortcutsGroup)
        },

        $mousetrapBind(shortcutsGroup: ShortcutsGroup) {
            _.forEach(shortcutsGroup.shortcuts, function (shortcut) {
                const { callback, keyCombo } = shortcut

                if (!(callback && keyCombo)) {
                    return
                }

                if (shortcut.global) {
                    Mousetrap.bindGlobal(
                        keyCombo,
                        $digestWrapped(callback, _.get(shortcut, 'preventDefault', true), shortcut)
                    )
                } else {
                    Mousetrap.bind(
                        keyCombo,
                        $digestWrapped(callback, _.get(shortcut, 'preventDefault', false), shortcut)
                    )
                }
            })
        },

        unbind(shortcutsGroup: ShortcutsGroup) {
            const shortcutGroupIdx = currentShortcuts.indexOf(shortcutsGroup)

            if (shortcutGroupIdx !== -1) {
                currentShortcuts.splice(shortcutGroupIdx, 1)
            }

            if (!GlobalShortcuts.isSuspended()) {
                GlobalShortcuts.$mousetrapUnbind(shortcutsGroup)
            }
        },

        $mousetrapUnbind(shortcutsGroup: ShortcutsGroup) {
            _.forEach(shortcutsGroup.shortcuts, function (shortcut) {
                if (shortcut.callback && shortcut.keyCombo) {
                    Mousetrap.unbind(shortcut.keyCombo)
                }
            })
        },

        suspend() {
            if (suspendedShortcuts.length > 0) {
                return
            }

            suspendedShortcuts = [...currentShortcuts]
            let shortcutsGroup: ShortcutsGroup | undefined
            while ((shortcutsGroup = currentShortcuts.pop())) {
                GlobalShortcuts.$mousetrapUnbind(shortcutsGroup)
            }
        },

        resume() {
            currentShortcuts = [...suspendedShortcuts]
            suspendedShortcuts = []
            _.forEach(currentShortcuts, (shortcutsGroup) => {
                GlobalShortcuts.$mousetrapBind(shortcutsGroup)
            })
        },

        reset() {
            currentShortcuts.length = 0
            suspendedShortcuts.length = 0
            Mousetrap.reset()
        },

        getShortcutsList() {
            return angular.copy(GlobalShortcuts.getActiveShortcuts())
        },

        isSuspended() {
            return !!suspendedShortcuts.length
        },

        getActiveShortcuts() {
            return GlobalShortcuts.isSuspended() ? suspendedShortcuts : currentShortcuts
        },

        isEmpty() {
            const activeShortcuts = GlobalShortcuts.getActiveShortcuts()
            return activeShortcuts.length === 1 && activeShortcuts[0] === DEFAULT_SHORTCUTS
        },

        bindDefault() {
            GlobalShortcuts.bind(DEFAULT_SHORTCUTS)
        },
    }

    GlobalShortcuts.bindDefault()

    return GlobalShortcuts

    //////////////////////////////////////////////////

    /**
     * Wraps a function in a way that will call $rootScope.$digest after its
     * execution, but preserve arguments/result seamlessly
     */
    function $digestWrapped(
        func: (...args: any[]) => any,
        preventDefault: boolean,
        shortcut: ShortcutDefinition
    ): () => any {
        return function (...args: any[]) {
            const result = func(...args)

            if (!$rootScope.$$phase) {
                $rootScope.$digest()
            }

            handleKeyboardShortcut(shortcut)

            return _.isUndefined(result) ? !preventDefault : result
        }
    }
}

export type GlobalShortcutsInstance = ReturnType<typeof GlobalShortcutsFactory>
