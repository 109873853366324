import _ from 'lodash'
import invariant from 'util/invariant'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { trackButtonClick } from 'util/snowplow'

const OPTS_ALLOWED = ['hitID', 'threads', 'assignmentID', 'hasOnlyGeneralThread', '$rootScope']
const OPTS_REQUIRED = ['hitID', 'threads']
const map3: TMap3Window = window

export default /* @ngInject */ function CommentInterfaceFactory(
    $rootScope: ng.IRootScopeService,
    $uibModal: ng.ui.bootstrap.IModalService,
    $state: ng.ui.IStateService,

    Notification: NotificationServiceInstance,
    CommentService: any,
    MapDialog: any
) {
    const CommentInterface = {
        // global comment properties
        $rootScope: $rootScope as ng.IRootScopeService | null,
        threads: [] as any[],
        hitID: null,
        assignmentID: null,
        hasOnlyGeneralThread: null,

        /**
         * Initialize the comment interface.
         *
         * @param {string} hitID
         * @param {array} threads
         * @param {string} assignmentID
         * @param {boolean} hasOnlyGeneralThread
         */
        init(opts = {}) {
            invariant(
                _.difference(OPTS_REQUIRED, _.keys(opts)).length === 0,
                '[CommentInterface] You must provide the required options: %s',
                OPTS_REQUIRED.join(', ')
            )
            _.assignIn(
                CommentInterface,
                {
                    $rootScope,
                    assignmentID: false,
                    hasOnlyGeneralThread: false,
                },
                _.pick(opts, OPTS_ALLOWED)
            )

            CommentInterface.tryToOpenCommentFromUrlParams()
        },

        /**
         * Initialize the comment interface from different window, like in videoPopup.
         *
         */
        initFromOpenerWindow() {
            if (!window.opener) {
                throw new Error(
                    'Trying to Initialize CommentInterface from descedent window, but such was not found!'
                )
            }
            // we apply the modifiable variables from the opener commentInterface,
            // most importantly threads and $rootScope, to our own CommentInterface.
            // We make use of their object reference, so that changes to the opener
            // threads are reflected for us as well.
            CommentInterface.init(_.pick(window.opener.commentInterface, OPTS_ALLOWED))
        },

        /**
         * Emit a map3.addBookmark event on the proper root scope (ie, works accross popups)
         */
        emitAddBookmark() {
            CommentInterface.$rootScope!.$emit('map3.addBookmark')
        },

        /**
         * Emit a map3.deleteBookmark event on the proper root scope (ie, works accross popups)
         */
        emitDeleteBookmark(marker: any) {
            CommentInterface.$rootScope!.$emit('map3.deleteBookmark', marker)
        },
        /**
         * Destroy the comment interface data.
         * Should be called when the relevant page is closed.
         */
        destroy() {
            CommentInterface.threads = []
            CommentInterface.hitID = null
            CommentInterface.assignmentID = null
            CommentInterface.$rootScope = null
        },

        /**
         * Check the roting params for `threadId` and `commentId` and if possible,
         * open the matching comment thread
         */
        tryToOpenCommentFromUrlParams() {
            if ($state.params.threadId) {
                CommentInterface.showThreadModal($state.params.threadId, $state.params.commentId)
            }
        },

        /**
         * Helper function to handle video click on marker that represents a comment thread
         *
         * @param {object} event
         * @param {object} marker
         */
        markerClick(event: Event, marker: any) {
            CommentInterface.showThreadModal(marker.thread.id)
        },

        /**
         * Create a comment thread for a given comment, and add it to our local cache
         *
         * @param {object} comment
         * @return {Promise}
         */
        createCommentThread(comment: any) {
            const createThread = CommentService.createCommentThread(
                CommentInterface.assignmentID || CommentInterface.hitID,
                comment,
                CommentInterface.assignmentID
            )

            trackButtonClick({
                label: 'Create comment thread',
            })

            createThread.then((thread: any) => {
                CommentInterface.threads.push(thread)
            })

            return createThread
        },

        /**
         * Reply to a given comment thread, and update our local cache
         *
         * @param {object} thread
         * @param {object} comment
         *
         * @return {Promise}
         */
        replyToThread(thread: any, comment: any) {
            const replyToThread = CommentService.addCommentToThread(
                CommentInterface.assignmentID || CommentInterface.hitID,
                thread.id,
                comment,
                CommentInterface.assignmentID
            )

            trackButtonClick({
                label: 'Reply to Comment',
            })

            replyToThread.then((thread: any) => {
                // Update the thread in our local storage
                _.assign(_.find(CommentInterface.threads, { id: thread.id }), thread)
            })

            return replyToThread
        },

        editComment(thread: any, commentId: string | number, text: string) {
            const editCommentInThread = CommentService.editCommentInThread(
                CommentInterface.assignmentID || CommentInterface.hitID,
                thread.id,
                commentId,
                text
            )

            trackButtonClick({
                label: 'Edit comment',
            })

            editCommentInThread.then((thread: any) => {
                _.assign(_.find(CommentInterface.threads, { id: thread.id }), thread)
            })

            return editCommentInThread
        },

        deleteComment(thread: any, comment: any) {
            const deleteCommentFromThread = CommentService.deleteCommentFromThread(
                CommentInterface.assignmentID || CommentInterface.hitID,
                thread.id,
                comment.id
            )

            trackButtonClick({
                label: 'Delete comment',
            })

            deleteCommentFromThread.then((modifiedThread: any) => {
                const thread = CommentInterface.threads.find(
                    (thread) => thread.id === modifiedThread.id
                )

                if (thread) thread.comments = modifiedThread.comments

                if (modifiedThread.is_deleted) {
                    _.remove(CommentInterface.threads, thread)
                }
            })

            return deleteCommentFromThread
        },

        /**
         * Open a comment thread in a modal, optionally highlighting a specific comment
         *
         * @param {object} thread
         * @param {string=} highlightCommentId
         */
        showThreadModal(threadId: string | number, highlightCommentId = '') {
            const thread = _.find(CommentInterface.threads, { id: threadId })

            if (!thread) {
                return
            }

            $uibModal.open({
                templateUrl: 'partials/commentThreadViewModal.tpl.html',
                controller: CommentModalCtrl,
                controllerAs: CommentModalCtrl.controllerAs,
                resolve: {
                    thread,
                    highlightCommentId: () => highlightCommentId,
                },
            })
        },

        /**
         * Get the general thread, if one exists
         *
         * @return {object}
         */
        getGeneralThread() {
            return _.find(CommentInterface.threads, { timestamp: null })
        },

        /**
         * Show the general thread
         *
         * @throws Error when no general thread exists
         */
        showGeneralThreadModal(highlightCommentId = '') {
            const generalThread = CommentInterface.getGeneralThread()

            if (!generalThread) {
                throw new Error(
                    `No general comment thread exists for ${angular.toJson(
                        CommentInterface.threads
                    )}`
                )
            }

            CommentInterface.showThreadModal(generalThread.id, highlightCommentId)
        },

        /**
         * Open a comment thread creation modal for a given timestamp
         *
         * @param {number} timestamp
         */
        showCreateThreadModal(timestamp: number | null) {
            const $uibModalInstance = $uibModal.open({
                templateUrl: 'partials/commentThreadCreateModal.tpl.html',
                controllerAs: 'vm',
                controller: /* @ngInject */ function (
                    this: any,
                    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                    CommentInterface: CommentInterfaceInstance
                ) {
                    const vm = this

                    vm.addComment = addComment

                    //////////////////////////

                    function addComment(e: Event) {
                        e.preventDefault()

                        const comment = {
                            timestamp: timestamp,
                            text: vm.comment,
                        }

                        Notification.forPromise(
                            CommentInterface.createCommentThread(comment),
                            'Comment successfully added.'
                        )

                        $uibModalInstance.close()

                        return false
                    }
                },
            })

            return $uibModalInstance.result
        },

        /**
         * Create a general thread. A general thread has a timestamp of null
         *
         * @throws Error when a general thread already exists
         */
        showCreateGeneralThreadModal() {
            if (CommentInterface.getGeneralThread()) {
                throw new Error(
                    'A general thread already exists, cannot create a second general thread'
                )
            }

            CommentInterface.showCreateThreadModal(null)
        },

        /**
         * Open all threads in a modal
         *
         * @param {array} threads
         */
        showCommentsListModal(threads: any[]) {
            $uibModal.open({
                templateUrl: 'partials/commentListModal.tpl.html',
                controllerAs: 'vm',
                controller: /* @ngInject */ function (this: any) {
                    const vm = this

                    vm.threads = _.sortBy(threads, ['timestamp'])
                    vm.showThreadModal = CommentInterface.showThreadModal
                },
            })
        },

        showDeleteConfirmationModal(marker: any) {
            const confirmDialog = MapDialog.confirm()
                .title('Are you sure you want to delete this bookmark?')
                .ok('Delete')
                .cancel('No')

            MapDialog.show(confirmDialog).then(() => {
                CommentInterface.emitDeleteBookmark(marker)
            })
        },

        getCommentsCount() {
            let count = 0
            let i = -1
            while (++i < CommentInterface.threads.length) {
                count += CommentInterface.threads[i].comments.length
            }
            return count
        },
    }

    return (map3.commentInterface = CommentInterface) // eslint-disable-line
}

/* @ngInject */
function CommentModalCtrl(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,

    CommentInterface: any,
    Notification: any,
    MapDialog: any,

    thread: any,
    highlightCommentId: string
) {
    const vm = this

    vm.thread = thread
    vm.commentText = ''
    vm.editingCommentId = null
    vm.highlightCommentId = highlightCommentId

    vm.handleCommentEdit = handleCommentEdit
    vm.deleteComment = deleteComment
    vm.cancel = cancel
    vm.submit = submit

    ////////////////////////////////////

    function handleCommentEdit(comment: any) {
        vm.commentText = comment.text
        vm.editingCommentId = comment.id
    }

    function deleteComment(comment: any) {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure you want to delete this comment?')
            .ok('Delete')
            .cancel('No')

        MapDialog.show(confirmDialog).then(() => {
            const deleteCommentPromise = CommentInterface.deleteComment(thread, comment)

            deleteCommentPromise.then((thread: any) => {
                if (thread.is_deleted) {
                    $uibModalInstance.dismiss()
                }
            })

            Notification.forPromise(deleteCommentPromise, 'Comment successfully deleted.')
        })
    }

    function cancel() {
        vm.editingCommentId ? clearCommentEdit() : $uibModalInstance.dismiss()
    }

    function clearCommentEdit() {
        vm.editingCommentId = null
        vm.commentText = ''
    }

    function submit(e: Event) {
        e.preventDefault()
        vm.editingCommentId ? submitCommentEdit() : submitCommentReply()
    }

    function submitCommentReply() {
        const replyToThreadPromise = CommentInterface.replyToThread(thread, {
            text: vm.commentText,
        })

        // clear the comment form
        replyToThreadPromise.then(() => {
            vm.commentText = ''
        })

        Notification.forPromise(replyToThreadPromise, 'Comment successfully added.')

        return false
    }

    function submitCommentEdit() {
        const editCommentPromise = CommentInterface.editComment(
            thread,
            vm.editingCommentId,
            vm.commentText
        )

        editCommentPromise.then(clearCommentEdit)

        Notification.forPromise(editCommentPromise, 'Comment successfully added.')
    }
}

CommentModalCtrl.controllerAs = 'vm'

export type CommentInterfaceInstance = ReturnType<typeof CommentInterfaceFactory>
