import slugifyBase from 'slugify'

export function slugify(
    str: string,
    options: {
        replacement?: string
        remove?: RegExp
        lower?: boolean
    } = {}
): string {
    return slugifyBase(str, {
        remove: /[[\]*+~.()'"!:@]/g,
        ...options,
    })
}
