import { REPUBLISH_STATUS_TYPE } from 'constants.es6'
import _ from 'lodash'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { AdminModuleInstance } from './AdminModule.factory'
import { TRepublishStatus } from './types'

export default class MarkerRedeliveryCtrl {
    static resolve = {
        allWorkflows: /* @ngInject */ (AdminModule: AdminModuleInstance) => {
            return AdminModule.getMarkerWorkflows().then((res) => res)
        },
    }

    $scope: ng.IScope
    $state: ng.ui.IStateService
    $filter: ng.IFilterFunction
    $uibModal: ng.ui.bootstrap.IModalService

    adminModule: AdminModuleInstance
    Notification: NotificationServiceInstance

    markerWorkflows: TMarkerWorkflow[]
    markerWorkflowStatus: TRepublishStatus | false
    selectedForRepublish: string[]
    allWorkflows: TMarkerWorkflow[]
    markerWorkflowSearchInput: string
    perfieldPredicate: any
    perfieldFilter: any
    predicate: any
    totalItems: number
    currentPage: number
    itemsPerPage: number
    dupesArr: any[]

    /* @ngInject */
    constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $filter: ng.IFilterFunction,
        $uibModal: ng.ui.bootstrap.IModalService,

        UserPreferences: any,
        AdminModule: AdminModuleInstance,
        Notification: NotificationServiceInstance,

        allWorkflows: TMarkerWorkflow[]
    ) {
        this.$scope = $scope
        this.$state = $state
        this.$filter = $filter
        this.$uibModal = $uibModal

        this.adminModule = AdminModule
        this.Notification = Notification

        this.allWorkflows = allWorkflows
        this.markerWorkflows = []
        this.markerWorkflowStatus = false
        this.markerWorkflowSearchInput = ''
        this.selectedForRepublish = []

        this.perfieldFilter = $filter('perfieldFilter')
        this.predicate = UserPreferences.get($state.current.name, 'vm.predicate', [])
        this.perfieldPredicate = UserPreferences.get(
            $state.current.name,
            'vm.perfieldPredicate',
            {}
        )

        this.totalItems = 0
        this.currentPage = 1
        this.itemsPerPage = 50
        this.dupesArr = []

        this.$scope.$watchCollection('vm.predicate', () => this.prepareJobsArray())
        this.$scope.$watchCollection('vm.allWorkflows', () => this.prepareJobsArray())
        this.$scope.$watch('vm.perfieldPredicate', () => this.prepareJobsArray(), true)
        this.$scope.$watch('vm.currentPage', () => this.prepareJobsArray())
        this.$scope.$watch('vm.markerWorkflowSearchInput', () => this.prepareJobsArray())
    }

    prepareJobsArray() {
        const filteredWorkflows = this.perfieldFilter(this.allWorkflows, this.perfieldPredicate)
        const orderedWorkflows = this.$filter('orderBy')(filteredWorkflows, this.predicate)
        const filteredBySearch = this.markerWorkflowSearchInput
            ? this.$filter('filter')(orderedWorkflows, this.markerWorkflowSearchInput)
            : orderedWorkflows

        this.markerWorkflows = filteredBySearch.slice(
            (this.currentPage - 1) * this.itemsPerPage,
            this.currentPage * this.itemsPerPage
        )
        this.totalItems = filteredBySearch.length
    }

    republishSingle(eidrL2: string) {
        this.adminModule.republishMarkerWorkflow([eidrL2]).then((res) => {
            const { status, error } = res.data[0]
            const workflow = _.find(this.markerWorkflows, { eidrl2: eidrL2 })

            if (workflow) {
                const workflowIndex = _.findIndex(this.markerWorkflows, workflow)
                const updatedWorkflow = { ...workflow, status, error }

                this.markerWorkflows.splice(workflowIndex, 1, updatedWorkflow)
            }
        })
    }

    checkMarkerWorkflowStatus(eiderL2: string) {
        this.adminModule
            .checkMarkerWorkflowStatus(eiderL2)
            .then((res) => {
                this.markerWorkflowStatus = res.data
            })
            .catch((err) => {
                this.Notification.error(err.data.message)
            })
    }

    republishMultiple(form: ng.IFormController) {
        const republishPromise = this.adminModule
            .republishMarkerWorkflow(this.selectedForRepublish)
            .then(() => {
                form.$setPristine()
                form.$setUntouched()

                this.selectedForRepublish = []
            })

        this.Notification.forPromise(republishPromise, 'Successfully republished')
    }

    canRepublish({ status }: TRepublishStatus) {
        if (status === REPUBLISH_STATUS_TYPE.ERROR || status === REPUBLISH_STATUS_TYPE.PUBLISHED) {
            return true
        }
        return false
    }

    getStatusClass(workflow: TMarkerWorkflow) {
        switch (workflow.status) {
            case REPUBLISH_STATUS_TYPE.PUBLISHED:
                return 'badge-success'
            case REPUBLISH_STATUS_TYPE.IN_PROGRESS:
                return 'badge-warning'
            case REPUBLISH_STATUS_TYPE.ERROR:
                return 'badge-danger'
            default:
                return 'badge'
        }
    }

    removeDupesFromPaste({ text }: { text: string }) {
        if (_.find(this.dupesArr, (d) => d === text)) {
            return false
        }

        this.dupesArr.push(text)
        return true
    }

    clearDupesArray() {
        if (this.dupesArr.length) {
            this.dupesArr.length = 0
        }
    }
}
