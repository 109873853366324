import _ from 'lodash'
import { ASSIGNMENT_STATUSES } from '../constants'
import { IQAAssignment, IQAAssigmentForReviewFailed } from './types'
import { CreateMissingAuthorityHandlerInstance } from 'services/http/createMissingAuthorityHandler.factory'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { QAAnnotationTreeInstance } from './service/QAAnnotationTree.factory'
import { QAAssignmentServiceInstance } from './QAAssignmentService.factory'
import { QARouterInstance } from './routes'
import { trackButtonClick } from 'util/snowplow'

interface IQAListCtrlInstance {
    AUTHORITY_FOUND_STATUS: string
    assignments: IQAAssignment[]
    assignmentSelection: IQAAssignment[]
    predicateReviewing: string[]
    predicateReviewable: string[]
    predicateOnHold: string[]
    reviewablePerfieldPredicate: { [key: string]: unknown }
    reviewingPerfieldPredicate: { [key: string]: unknown }
    onHoldPerfieldPredicate: { [key: string]: unknown }
    urlForAssignment: QARouterInstance['urlFor']
    reviewableAssignments: IQAAssignment[]
    reviewingAssignments: IQAAssignment[]
    onHoldAssignments: IQAAssignment[]
    clearSelection: () => void
    acceptAssignments: (assignments: IQAAssignment[]) => ng.IPromise<void>
    reviewAssignment: (task: any) => void
    retryAssignment: (task: any) => void
    confirmReturnToInProgress: (task: any) => void
    returnAssignment: (assignment: IQAAssignment) => void
    abandonAssignment: (assignment: IQAAssignment) => void
}

export default /* @ngInject */ function QAListCtrl(
    this: unknown,
    $scope: ng.IScope,
    $state: ng.ui.IStateService,
    $q: ng.IQService,
    Notification: NotificationServiceInstance,
    UserPreferences: any,
    QAAssignmentService: QAAssignmentServiceInstance,
    QAAnnotationTree: QAAnnotationTreeInstance,
    QARouter: QARouterInstance,
    MapDialog: any,
    assignments: IQAAssignment[],
    createMissingAuthorityHandler: CreateMissingAuthorityHandlerInstance
) {
    const listVm = this as IQAListCtrlInstance

    listVm.AUTHORITY_FOUND_STATUS = ASSIGNMENT_STATUSES.AUTHORITY_FOUND_STATUS
    listVm.assignments = assignments
    listVm.assignmentSelection = []

    listVm.acceptAssignments = acceptAssignments
    listVm.reviewAssignment = reviewAssignment
    listVm.retryAssignment = retryAssignment
    listVm.returnAssignment = returnAssignment
    listVm.confirmReturnToInProgress = confirmReturnToInProgress

    listVm.clearSelection = () => {
        listVm.assignmentSelection.length = 0
    }

    listVm.predicateReviewing = UserPreferences.get(
        $state.current.name,
        'listVm.predicateReviewing',
        []
    )
    listVm.predicateReviewable = UserPreferences.get(
        $state.current.name,
        'listVm.predicateReviewable',
        []
    )
    listVm.predicateOnHold = UserPreferences.get($state.current.name, 'listVm.predicateOnHold', [])

    listVm.reviewablePerfieldPredicate = UserPreferences.get(
        $state.current.name,
        'listVm.reviewablePerfieldPredicate',
        {}
    )
    listVm.reviewingPerfieldPredicate = UserPreferences.get(
        $state.current.name,
        'listVm.reviewingPerfieldPredicate',
        {}
    )
    listVm.onHoldPerfieldPredicate = UserPreferences.get(
        $state.current.name,
        'listVm.onHoldPerfieldPredicate',
        {}
    )

    listVm.abandonAssignment = abandonAssignment
    listVm.urlForAssignment = QARouter.urlFor

    activate()

    //////////////////////////////

    function activate() {
        $scope.$watchCollection('listVm.assignments', () => {
            listVm.reviewableAssignments = _.filter(listVm.assignments, {
                task_status: 'reviewable',
            })
            listVm.reviewingAssignments = _.filter(listVm.assignments, {
                task_status: 'reviewing',
            })
            listVm.onHoldAssignments = _.filter(listVm.assignments, {
                task_status: 'onHold',
            })

            listVm.assignmentSelection = _.intersection(
                listVm.assignments,
                listVm.assignmentSelection
            )
        })
    }

    function acceptAssignments(assignments: IQAAssignment[]) {
        const promise = QAAssignmentService.addForReview(assignments).then((response) => {
            if (response.failed.length) {
                handleUnavailable(response.failed)
            }

            replaceUpdated(response.success)
        })

        Notification.forPromise(promise, 'Your tasks were moved to the In Progress tab.')

        return promise
    }

    function retryAssignment(task: any) {
        QAAnnotationTree.getReviewData(task.hit_id)
            .then(() => {
                return QARouter.go(task)
            })
            .catch(createMissingAuthorityHandler(task))
    }

    function reviewAssignment(task: any) {
        QAAnnotationTree.getReviewData(task.hit_id)
            .then(() => {
                trackButtonClick({
                    label: 'Review QA Task',
                    value: [
                        {
                            metadata: {
                                hitId: task.hit_id,
                            },
                        },
                    ],
                })
                return QARouter.go(task)
            })
            .catch((err) => {
                if (err.status !== 422) {
                    return $q.reject(err)
                }
                const modalConfig = MapDialog.confirm()
                    .title('There are no answers for all the questions in this task.')
                    .htmlContent(
                        `
                        <div class="alert alert-warning" role="alert">
                            <p class="text-center mb-0 mt-3">The task will be moved to the tab <strong>"On Hold Tasks"</strong>. MAP3 will retry the load of answers and report an issue if no answers are found in the next hours.</p>
                            <p class="text-center mb-3"><strong>If the completion of the task is critical you can manually report the issue.</strong></p>
                            <p class="text-center">In any case you will be notified if answers for the task become available.</p>,
                        </div>
                    `
                    )
                    .cancel('Close')

                task.issue = err.data.issue

                moveToOnHold(task)
                MapDialog.show(modalConfig)
            })
    }

    function confirmReturnToInProgress(task: any) {
        const modalConfig = MapDialog.confirm()
            .title('Return task In Progress')
            .textContent(
                'You are about to return a task with missing terms to your ‘in progress’ tab. This will allow you to continue working and MAP3 will notify you when new terms are available.'
            )
            .ok('Return in progress')
            .okClass('btn-primary')
            .cancel('Cancel')

        MapDialog.show(modalConfig).then(() => {
            returnToInProgress(task)
        })
        ////////////////////////////////////////////////

        function returnToInProgress(task: any) {
            const promise = QAAssignmentService.addToInProgress(task).then(() => {
                const index = _.findIndex(listVm.assignments, { hit_id: task.hit_id })

                if (index !== -1) {
                    const updatedTask = { ...task, task_status: 'reviewing' }
                    listVm.assignments.splice(index, 1, updatedTask)
                }

                Notification.forPromise(promise, 'Your tasks were returned to the In Progress tab.')
            })
        }
    }

    function moveToOnHold(task: any) {
        const index = _.findIndex(listVm.assignments, { hit_id: task.hit_id })

        if (index !== -1) {
            const updatedTask = { ...task, task_status: 'onHold' }
            listVm.assignments.splice(index, 1, updatedTask)
        }
    }

    function replaceUpdated(updatedTasks: any[]) {
        _.forEach(updatedTasks, (task) => {
            const index = _.findIndex(listVm.assignments, { hit_id: task.hit_id })
            if (index !== -1) {
                listVm.assignments.splice(index, 1, task)
            } else {
                listVm.assignments.push(task)
            }
        })
    }

    function handleUnavailable(unavailableHits: IQAAssigmentForReviewFailed[]) {
        const unavailable = _.remove(listVm.assignments, (assignment) =>
            _.includes(unavailableHits, assignment.hit_id)
        )

        Notification.warning(`
            <pre>
                Some task are not available anymore:
                ${_.map(unavailable, 'title').join(', ')}
            </pre>
        `)
    }

    function returnAssignment(assignment: IQAAssignment) {
        QAAssignmentService.returnFromReview(assignment).then(() => {
            $state.reload()
        })
    }

    function abandonAssignment(assignment: IQAAssignment) {
        trackButtonClick({
            label: 'Abandon QA Task',
            value: [
                {
                    metadata: {
                        hitId: assignment['hit_id'],
                    },
                },
            ],
        })

        const modalConfig = MapDialog.confirm()
            .title('Abandon Task')
            .textContent(
                'If you abandon this task any QA edits will be erased and the task will return to the Available Tasks list.'
            )
            .ok('Abandon')
            .cancel('Cancel')

        MapDialog.show(modalConfig).then(() => {
            listVm.returnAssignment(assignment)
        })
    }
}

QAListCtrl.resolve = {
    assignments: /* @ngInject */ function (QAAssignmentService: QAAssignmentServiceInstance) {
        // For when you need LIGHTSPEED
        // return import('../../sample/taskListAssignments.json').then((m) => m.default)
        return QAAssignmentService.getAssignments()
    },
}
