import { NewRelicErrorCode } from 'constants.es6'

export const getNewRelicErrorMessage = (errorCode: NewRelicErrorCode, message: string) => {
    return `Error ${errorCode}: ${message}`
}

const map3Window = window as TMap3Window

export const logError = (condition: boolean, message: string) => {
    if (!condition) {
        if (map3Window.newrelic) {
            map3Window.newrelic.noticeError(message)
        }

        console.error(message) // eslint-disable-line
    }
}

export const newRelic = {
    addPageAction(action: string, payload?: any) {
        if (map3Window.newrelic) {
            map3Window.newrelic.addPageAction(action, payload)
        }
    },
    setCustomAttribute(attr: string, value: string | number) {
        if (map3Window.newrelic) {
            map3Window.newrelic.setCustomAttribute(attr, value)
        }
    },
    noticeError(message: string, payload?: any) {
        if (map3Window.newrelic) {
            map3Window.newrelic.noticeError(message, payload)
        }
    },
    isNewRelicLoaded() {
        return !!map3Window.newrelic
    },
    getNewRelicErrorMessage(errorCode: NewRelicErrorCode, message: string) {
        return `Error ${errorCode}: ${message}`
    },
}
