import _ from 'lodash'
import fp from 'lodash/fp'
import invariant from 'util/invariant'

export default /* @ngInject */ function AdminManageVideosCtrl(
    VIDEO_TYPE_DEFINITONS,
    $scope,
    $uibModal,
    $state,
    VideoService,
    Notification,
    uploads,
    UserPreferences,
    MapDialog
) {
    const VIDEO_STATUS_UPLOADING = 'uploading'
    const VIDEO_STATUS_TRANSMITTING = 'transmitting'
    const VIDEO_STATUS_PROCESSING = 'processing'
    const VIDEO_STATUS_PROCESSED = 'processed'

    const VIDEO_STATUS_HUMAN = {
        [VIDEO_STATUS_UPLOADING]: 'Uploading',
        [VIDEO_STATUS_TRANSMITTING]: 'Transmitting',
        [VIDEO_STATUS_PROCESSING]: 'Processing',
        [VIDEO_STATUS_PROCESSED]: 'Processed',
    }

    let manageVideosVm = this

    manageVideosVm.VIDEO_STATUS_HUMAN = VIDEO_STATUS_HUMAN

    manageVideosVm.groups = uploads.groups
    manageVideosVm.videos = uploads.videos

    manageVideosVm.pauseUpload = pauseUpload
    manageVideosVm.handleColdResume = handleColdResume
    manageVideosVm.handleWarmResume = handleWarmResume
    manageVideosVm.editMetadata = editMetadata
    manageVideosVm.deleteVideo = deleteVideo
    manageVideosVm.reloadVideos = reloadVideos

    manageVideosVm.handleFileUpload = handleFileUpload
    manageVideosVm.definitionByType = definitionByType

    activate()

    ///////////////////////////////////////////////////////////

    function activate() {
        $scope.$watchCollection('manageVideosVm.videos', function (videos) {
            // content type tabs, shown if we have a video with that content type
            manageVideosVm.contentTypes = fp.flow(
                fp.map('contentType'),
                fp.filter((contentType) => _.find(videos, ['metadata.contentType', contentType]))
            )(VIDEO_TYPE_DEFINITONS)

            manageVideosVm.unfinishedUploads = fp.flow(
                fp.filter((video) => video.status === VIDEO_STATUS_UPLOADING)
            )(videos)

            manageVideosVm.finishedUploads = fp.flow(
                fp.filter((video) => video.status !== VIDEO_STATUS_UPLOADING)
            )(videos)
        })

        manageVideosVm.predicateVideos = UserPreferences.get(
            $state.current.name,
            'manageVideosVm.predicateVideos',
            []
        )

        // TODO figure this shit out
        manageVideosVm.predicateUnfinishedUploads = UserPreferences.get(
            $state.current.name,
            'manageVideosVm.predicateUnfinishedUploads',
            []
        )
    }

    function handleFileUpload(files) {
        _.forEach(files, function (file) {
            presentUploadVideoForm(file)
        })
    }

    function presentUploadVideoForm(file) {
        let video = {
            filename: file.name,
            metadata: {
                referenceID: removeFileExtension(file.name),
            },
            notify_admin: false,
        }

        return editMetadata(video).then((video) => {
            handleVideoPromise(video, VideoService.uploadVideo(video, file))
        })
    }

    function editMetadata(video) {
        let $uibModalInstance = $uibModal.open({
            templateUrl: 'js/admin/manage-videos/admin.manage-videos-edit-metadata.tpl.html',
            controller: EditVideoMetadataCtrl,
            controllerAs: 'vm',
            resolve: {
                video: () => video,
                videos: () => manageVideosVm.videos,
                groups: () => manageVideosVm.groups,
            },
        })

        return $uibModalInstance.result
    }

    function handleVideoPromise(video, promise) {
        promise.then(
            function (res) {
                if (res.data) {
                    _.remove(manageVideosVm.videos, { id: video.id })
                    manageVideosVm.videos.push(res.data)
                }
            },
            angular.noop, // errors are handled in the video service
            function (evt) {
                let cachedVideo = _.find(manageVideosVm.videos, { id: video.id })
                if (!cachedVideo) {
                    return
                }
                if (!cachedVideo.totalSize) {
                    cachedVideo.totalSize = evt.total
                }
                if (cachedVideo && cachedVideo.totalSize === evt.total) {
                    cachedVideo.uploadedSize = evt.loaded
                }
            }
        )

        Notification.forPromise(promise, 'Video uploaded successfully')
    }

    function handleColdResume(video, files) {
        if (files.length && video) {
            handleVideoPromise(video, VideoService.uploadVideo(video, files[0]))
        }
    }

    function handleWarmResume(video) {
        handleVideoPromise(video, VideoService.resumeVideoUpload(video))
    }

    function pauseUpload(video) {
        VideoService.abortVideoUpload(video)
    }

    function deleteVideo(video) {
        let confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to delete this video?')
            .ok('Yes')
            .cancel('No')

        MapDialog.show(confirmDialog)
            .then(function () {
                let deleteVideoPromise = VideoService.deleteVideo(video)

                Notification.forPromise(deleteVideoPromise, 'Video successfully deleted')

                return deleteVideoPromise
            })
            .then(function () {
                _.remove(manageVideosVm.videos, { id: video.id })
            })
    }

    function reloadVideos() {
        VideoService.getAllUploads().then(function (uploads) {
            manageVideosVm.videos = uploads.videos
        })
    }

    function definitionByType(contentType) {
        const definition = _.find(VIDEO_TYPE_DEFINITONS, { contentType })
        invariant(definition, 'Cannot find video type definition for content type %s', contentType)

        return definition
    }

    function removeFileExtension(filename) {
        return fp.flow(fp.split('.'), fp.dropRight(1), fp.join('.'))(filename)
    }
}

AdminManageVideosCtrl.resolve = {
    uploads: /* @ngInject */ function (VideoService) {
        // return import('../../../sample/uploads.json').then(m => m.default)
        return VideoService.getAllUploads()
    },
}

/* @ngInject */
function EditVideoMetadataCtrl(
    VIDEO_TYPE_DEFINITONS,
    $scope,
    $uibModalInstance,

    VideoService,
    Notification,

    video,
    videos,
    groups
) {
    let vm = this

    vm.video = angular.copy(video)
    // ensure metadata property
    vm.video.metadata = vm.video.metadata || {}

    vm.groups = groups
    vm.VIDEO_TYPE_DEFINITONS = VIDEO_TYPE_DEFINITONS

    vm.definitionByType = definitionByType
    vm.submit = submitChanges
    vm.isDefined = angular.isDefined
    vm.videoDefinition = definitionByType(vm.video.metadata.contentType)

    activate()

    /////////////////////////////////////////////////////////

    function activate() {
        $scope.$watch('vm.video.metadata.contentType', $resetProgrammingType)
        $scope.$watch('vm.video.metadata.programmingType', $setStaticProgrammingTypes)
    }

    function submitChanges() {
        let metadataPromise
        if (!video.videoId) {
            metadataPromise = VideoService.createVideo(vm.video).then(function (video) {
                videos.push(video)

                $uibModalInstance.close(video)
            })
        } else {
            metadataPromise = VideoService.updateVideoMetadata(vm.video).then(function (
                updatedVideo
            ) {
                // replace video with new version
                const idx = _.findIndex(videos, { id: video.id })
                videos.splice(idx, 1, updatedVideo)

                $uibModalInstance.close(updatedVideo)
            })
        }

        metadataPromise.catch(function (res) {
            vm.video.errors = res.data
        })

        Notification.forPromise(metadataPromise)
    }

    /**
     * @param {string} contentType
     * @return {Object|null} definition
     */
    function definitionByType(contentType) {
        return _.find(VIDEO_TYPE_DEFINITONS, { contentType })
    }

    // When the content type changes, we need to reset the programming type.
    function $resetProgrammingType() {
        vm.videoDefinition = definitionByType(vm.video.metadata.contentType)

        // if the new content type doesn't have this programming type, reset programming type/subType
        const validProgrammingType = !!_.find(
            _.get(vm.videoDefinition, 'programmingTypeSettings.options'),
            {
                value: vm.video.metadata.programmingType,
            }
        )
        if (!validProgrammingType) {
            vm.video.metadata.programmingType = null
            vm.video.metadata.programmingSubType = null
        }

        $setStaticProgrammingTypes()
    }

    function $setStaticProgrammingTypes() {
        vm.videoDefinition = definitionByType(vm.video.metadata.contentType)
        if (!vm.videoDefinition) {
            vm.programmingTypeSettings = null
            vm.programmingSubTypeSettings = null
            return
        }

        vm.programmingTypeSettings = vm.videoDefinition.programmingTypeSettings

        // if current content type has a static programming type value, set it
        if (vm.videoDefinition.programmingTypeSettings.staticValue) {
            vm.video.metadata.programmingType =
                vm.videoDefinition.programmingTypeSettings.staticValue
        }

        const programmingTypeSelectedOption = _.find(
            vm.videoDefinition.programmingTypeSettings.options,
            {
                value: vm.video.metadata.programmingType,
            }
        )

        const programmingSubTypeSettings = _.get(
            programmingTypeSelectedOption,
            'programmingSubTypeSettings'
        )
        vm.programmingSubTypeSettings = programmingSubTypeSettings

        if (programmingSubTypeSettings) {
            const validProgrammingSubType = !!_.find(programmingSubTypeSettings.options, {
                value: vm.video.metadata.programmingSubType,
            })
            if (!validProgrammingSubType) {
                vm.video.metadata.programmingSubType = null
            }

            if (programmingSubTypeSettings.staticValue) {
                vm.video.metadata.programmingSubType = programmingSubTypeSettings.staticValue
            }
        } else {
            vm.video.metadata.programmingSubType = null
        }
    }
}
