export default /* @ngInject */ function ResultsViewerCtrl(
    $scope,
    cfpLoadingBar,
    Notification,
    ResultsModule,
    ErrorStringifier,
    ResultsExportModal,
    BuildsProgrammingTypeGroups
) {
    const vm = this
    vm.loading = true
    vm.videoSearchQuery = ''

    vm.handleExport = ResultsExportModal.handleExport

    activate()

    ///////////////////////////////////////////////

    function activate() {
        const dataPromise = ResultsModule.getResultsList()

        // feedback for the user
        Notification.forPromise(dataPromise)
        cfpLoadingBar.start()
        dataPromise
            .then(updateDataAfterRequest)
            .catch((error) => {
                const errorMessage = ErrorStringifier.stringify(error)
                Notification.error(`Something went wrong ${errorMessage}`)
            })
            .finally(() => cfpLoadingBar.complete())

        $scope.$watch('vm.videoSearchQuery', function () {
            vm.programmingTypeGroups = BuildsProgrammingTypeGroups.build(
                vm.videos,
                vm.videoSearchQuery
            )
        })
    }

    function updateDataAfterRequest(resultsList) {
        vm.videos = resultsList.videos

        vm.programmingTypeGroups = BuildsProgrammingTypeGroups.build(vm.videos, vm.videoSearchQuery)

        vm.loading = false
    }
}

ResultsViewerCtrl.controllerAs = 'vm'
ResultsViewerCtrl.resolve = {}
