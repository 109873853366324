import Storage from 'services/Storage'

const STORAGE_DEBUG_PREFIX = 'map3_debug_'

const MAP3Debug = {
    ALLOW_INVALID_FOR_TAGGING: 'allow_invalid_for_tagging',

    check(debugProperty) {
        return Storage.getItem(STORAGE_DEBUG_PREFIX + debugProperty) || false
    },

    enable(debugProperty) {
        Storage.setItem(STORAGE_DEBUG_PREFIX + debugProperty, true)
    },

    disable(debugProperty) {
        Storage.setItem(STORAGE_DEBUG_PREFIX + debugProperty, false)
    },
}

export default window.MAP3_DEBUG = MAP3Debug
