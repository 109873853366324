import * as d3 from 'd3'
import d3tip from 'd3-tip'
import _ from 'lodash'
import fp from 'lodash/fp'

export default /* @ngInject */ function lineChart($window) {
    const directive = {
        restrict: 'E',
        link: lineChartLinkFn,
        scope: true,
    }

    return directive

    function lineChartLinkFn(scope, element, attrs) {
        let xScale, yScaleMax, yScale, xAxis, yAxis, xAxisTicks, svg

        const parseDate = d3.timeParse('%Y-%m-%d')
        const $$margins = {
            top: 20,
            right: 20,
            bottom: 20,
            left: 50,
        }

        const line = d3
            .line()
            .curve(d3.curveLinear)
            .x(function (d) {
                return xScale(d.date)
            })
            .y(function (d) {
                return yScale(d.value)
            })

        const tip = d3tip()
            .attr('class', 'd3-tip')
            .offset([-10, 0])
            .html(function (d) {
                const backgroundColor = d3.select(this).style('fill')

                return `
                    <div class='graph-tooltip tooltip-top' style='background-color: ${backgroundColor}; border-color: ${backgroundColor};'>
                        <section class='tooltip-arrow'  style='background-color: ${backgroundColor};'></section>
                        Tagging Tasks: ${
                            d.taggingTasks
                        } <br> QA Tasks:  ${d.qaTasks} <br> Total: ${d.qaTasks + d.taggingTasks}
                    </div>
                `
            })

        angular.element($window).on('resize', redrawGraph)
        scope.$on('$destroy', function () {
            angular.element($window).off('resize', redrawGraph)
            if (tip) {
                tip.destroy()
            }
        })

        scope.$watchCollection(attrs.jobs, function (jobs) {
            scope.jobs = angular.copy(jobs)

            scope.jobs.forEach(function (job) {
                _.forEach(job.graphData, function (d) {
                    d.date = parseDate(d.date)
                })
            })

            xAxisTicks = fp.flow(
                fp.flatMap((job) => job.graphData),
                fp.map('date'),
                fp.uniqBy((date) => date.toISOString())
            )(scope.jobs)

            redrawGraph()
        })

        ////////////////////

        function redrawGraph() {
            if (svg) {
                svg.remove()
            }

            const config = {
                width: element.width(),
                height: element.width() * 0.3,
            }

            svg = d3
                .select(element[0])
                .append('svg')
                .style('width', config.width)
                .style('height', config.height)

            if (scope.jobs && scope.jobs.length) {
                prepareScalesAndDrawAxis(config)
                drawBands(config)
                drawLines(config)
            } else {
                drawNoDataText(config)
            }

            svg.call(tip)
        }

        function drawLines(config) {
            const dataLength = scope.jobs.length

            for (let i = 0; i < dataLength; i++) {
                const job = scope.jobs[i]

                svg.append('path')
                    .datum(job.graphData)
                    .attr('fill', 'none')
                    .attr('stroke', job.color)
                    .attr('stroke-linejoin', 'round')
                    .attr('stroke-linecap', 'round')
                    .attr('stroke-width', 2.5)
                    .attr('d', line)

                svg.selectAll('dot')
                    .data(job.graphData)
                    .enter()
                    .append('circle')
                    .attr('r', 5)
                    .attr('cx', function (d) {
                        return xScale(d.date)
                    })
                    .attr('cy', function (d) {
                        return yScale(d.value)
                    })
                    .style('fill', job.color)
                    .on('mouseover', tip.show)
                    .on('mouseout', tip.hide)
            }
        }

        function drawBands(config) {
            svg.append('g')
                .attr('transform', 'translate(0,' + (config.height - $$margins.bottom) + ')')
                .call(xAxis.ticks(xAxisTicks.length || 0))

            svg.append('g')
                .attr('transform', 'translate(' + $$margins.left + ', 0)')
                .call(yAxis.ticks(5))
        }

        function drawNoDataText(config) {
            svg.append('text')
                .attr('class', 'no-data-text')
                .attr('x', config.width / 2)
                .attr('y', config.height / 2)
                .style('text-anchor', 'middle')
                .text('No Data')
                .style('font-size', '40px')
        }

        function prepareScalesAndDrawAxis(config) {
            xScale = d3
                .scaleTime()
                .rangeRound([$$margins.left, config.width - $$margins.right])
                .domain(d3.extent(xAxisTicks))

            yScaleMax = d3.max(_.flatMap(scope.jobs, 'graphData'), function (d) {
                return d.value
            })
            yScale = d3
                .scaleLinear()
                .range([config.height - $$margins.top, $$margins.bottom])
                .domain([0, yScaleMax])

            xAxis = d3.axisBottom(xScale).tickValues(xAxisTicks).tickFormat(d3.timeFormat('%m/%d'))

            yAxis = d3.axisLeft(yScale).tickFormat(d3.format('d'))

            svg.append('text')
                .attr('text-anchor', 'middle') // this makes it easy to centre the text as the transform is applied to the anchor
                .attr('transform', 'translate(15, ' + config.height / 2 + ') rotate(-90)') // text is drawn off the screen top left, move down and out and rotate
                .text('Tasks in Process')
        }
    }
}
