import _ from 'lodash'

import BuildsQueryFilters from './BuildsQueryFilters'
import markExceptionHandled from 'util/markExceptionHandled'
import { SESSION_STORAGE_SELECTED_PROJECT } from 'constants.es6'

export default /* @ngInject */ function QueryBuilderDataHandlerFactory($q, $http) {
    /**
     * @var requestsCache A per-table cache of UniqueColumnValuesRequest
     */
    let requestsCache = {}

    /**
     * @ngdoc type
     * @name QueryBuilderDataHandler
     * @module map3.queryBuilderModule
     *
     * @description
     * An interface for requesting backend table data and building query filters from it
     *
     * For info on the format, check http://querybuilder.js.org/index.html#filters
     */
    const QueryBuilderDataHandler = {
        /**
         * @ngdoc method
         * @name QueryBuilderDataHandler#buildFilters
         *
         * @description
         * Given a table object and current rules, return a promise for new filters
         *
         * @param {object} table
         * @param {object} rules
         *
         * @return {Promise<array>}
         */
        buildFilters(table, rules) {
            return QueryBuilderDataHandler.getOrCreateRequest(table, rules).then(function (data) {
                return BuildsQueryFilters.build(table.fields, data.unique_column_values, rules)
            })
        },

        /**
         * @ngdoc method
         * @name QueryBuilderDataHandler#updateFilters
         *
         * @description
         * Update filters, given a set of current rules.
         *
         * @param {object} table
         * @param {object} rules
         * @param {array} previousFilters
         *
         * @return {Promise<array>}
         */
        updateFilters(table, rules, previousFilters) {
            return QueryBuilderDataHandler.getOrCreateRequest(table, rules).then(function (data) {
                return BuildsQueryFilters.build(
                    table.fields,
                    data.unique_column_values,
                    rules,
                    previousFilters
                )
            })
        },

        /**
         * @ngdoc method
         * @name QueryBuilderDataHandler#getOrCreateRequest
         *
         * @description
         *
         * @param {object} table
         * @param {object} rules
         *
         * @return UniqueColumnValuesRequest
         */
        getOrCreateRequest(table, rules) {
            let isUpdate = false
            let request = requestsCache[table.table_name]

            if (request) {
                if (angular.equals(request.rules, rules)) {
                    return request
                } else {
                    request.cancel()
                    isUpdate = true
                }
            }

            request = QueryBuilderDataHandler.UniqueColumnValuesRequest.create(
                table,
                rules,
                isUpdate
            )
            requestsCache[table.table_name] = request

            return request
        },

        /**
         * @ngdoc method
         * @name QueryBuilderDataHandler#destroy
         *
         * @description
         * Cancel all pending UniqueColumnValuesRequest and clear the cache
         */
        destroy() {
            _.forEach(requestsCache, (request) => request.cancel())
            requestsCache = {}
        },
    }

    /**
     * @ngdoc type
     * @name UniqueColumnValuesRequest
     * @module map3.queryBuilderModule
     *
     * @description
     * A wrapper around a $http request for unique column values.
     *
     * It implements a base `Promise` interface and is cancellable
     */
    class UniqueColumnValuesRequest {
        /**
         * factory method to facilitate testing
         */
        static create(table, rules = {}, isUpdate = false) {
            return new UniqueColumnValuesRequest(table, rules, isUpdate)
        }

        /**
         * @ngdoc method
         * @name UniqueColumnValuesRequest#constructor
         *
         * @description
         *
         *
         * @param {object} table
         * @param {object=} rules
         * @param {boolean=} isUpdate Default: `false`
         */
        constructor(table, rules = {}, isUpdate = false) {
            this.table = table
            this.rules = rules
            this.isUpdate = isUpdate

            this.cancelDeferred = $q.defer()
            this.$$initRequest(table, rules)
        }

        $$initRequest(table, rules) {
            const project_id = sessionStorage.getItem(SESSION_STORAGE_SELECTED_PROJECT)

            this.request = $http
                .get('/api/admin/taskElements/unique-column-values', {
                    params: {
                        table: table.table_name,
                        json: _.isEmpty(rules) ? undefined : angular.toJson(rules),
                        project_id,
                    },
                    timeout: this.cancelDeferred.promise,
                    ignoreLoadingBar: this.isUpdate,
                })
                .then((res) => res.data)

            this.request = markExceptionHandled(this.request)
        }

        then() {
            return this.request.then.apply(this.request, arguments)
        }

        catch() {
            return this.request.catch.apply(this.request, arguments)
        }

        cancel() {
            this.cancelDeferred.resolve()

            delete this.table
            delete this.rules
            delete this.cancel
            delete this.request
        }
    }

    QueryBuilderDataHandler.UniqueColumnValuesRequest = UniqueColumnValuesRequest

    return QueryBuilderDataHandler
}
