import _ from 'lodash'
import fp from 'lodash/fp'

type ScopeBindings = {
    attributes: any[]
    authority: any
    maxDetailsCount: number
    questions: any[]
}

export default /* @ngInject */ function authorityAttributesViewDirective() {
    const directive = {
        restrict: 'E',
        scope: {
            authority: '<',
            questions: '<',
        },
        link: authorityAttributesViewLinkFn,
        templateUrl: 'js/worker/directives/authority-attributes-view.tpl.html',
    }

    return directive

    ////////////////////////////////////

    function authorityAttributesViewLinkFn(
        scope: ng.IScope & ScopeBindings,
        element: ng.IAugmentedJQuery
    ) {
        element.addClass('authority-details')

        scope.attributes = scope.authority.attributes

        activate()

        /////////////////////////////////////////////////

        function activate() {
            scope.maxDetailsCount =
                fp.flow(
                    fp.map((question) => _.get(question, 'details.length', 0)),
                    fp.max
                )(scope.questions) || 0
        }
    }
}
