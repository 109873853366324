import _ from 'lodash'

export default function songTitleFilter() {
    return function songTitle(song) {
        const artist = _.get(song, 'artist', '')
        const track = _.get(song, 'track', '')
        const album = _.get(song, 'album', '')
        const performedByOther = _.get(song, 'performedByOther', false)
            ? '(performed by other)'
            : ''

        return _.trim(`${artist} - ${track} (${album}) ${performedByOther}`)
    }
}
