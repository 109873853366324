import _ from 'lodash'
import fp from 'lodash/fp'

export default /* @ngInject */ function BuildsProgrammingTypeGroupsFactory(
    PROGRAMMING_TYPE_CONSTANTS,
    $filter,
    $log
) {
    const BuildsProgrammingTypeGroups = {
        /**
         * Given a list of videos, group them by programming types
         *
         * @param {array} videos Videos to group by programming type
         * @param {string=} searchString Search string to filter videos
         * @param {array=} tasks Optional, tasks to attach to each matching video
         *
         * @return [
         *   {
         *       type: {string},         // the programming type
         *       fields: [{string}]      // the special fields for the programming type
         *       subTypes: [{string}],   // the programming sub types for the programming type
         *       length: {number}        // the number of items in the group
         *
         *       // for type "episode":
         *       series: [
         *           {
         *               id: {number},           // series unique id
         *               seriesTitle: {string},  // series title
         *               seasons: [
         *                   {
         *                       seriesTitle: {string},
         *                       seasonNumber: {number}
         *                       episodes: [
         *                           {
         *                               seriesTitle: {string}
         *                               seasonNumber: {number}
         *                               episodeNumber: {number},
         *                               video: {
         *                                   ...original video fields,
         *                                   [tasks]: [{object}] // optionally, matching tasks
         *                               }
         *                           }
         *                       ]
         *                   }
         *               ]
         *           }
         *       ]
         *
         *       // for all other types:
         *       videos: [
         *           {
         *               ...original video fields,
         *               [tasks]: [{object}] // optionally, matching tasks
         *           }
         *       ]
         * ]
         */
        build(videos, searchString = null, tasks = null) {
            validateVideoProgrammingTypes(videos)

            const filteredVideos = $filter('filter')(videos, searchString)

            const programmingTypeGroups = _.map(
                PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPES,
                (programmingType) =>
                    buildProgrammingTypeGroup(filteredVideos, programmingType, tasks)
            )

            return programmingTypeGroups
        },
    }

    return BuildsProgrammingTypeGroups

    ////////////////////////////////////////////

    function validateVideoProgrammingTypes(videos) {
        if (videos && !videos.$$wasValidatedForProgrammingTypes) {
            const videoProgrammingTypes = fp.flow(fp.map('programmingType'), fp.uniq)(videos)

            const unknowProgrammingTypes = _.difference(
                videoProgrammingTypes,
                _.map(PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPES, 'type')
            )
            if (unknowProgrammingTypes.length) {
                $log.error('Unknown programming types:', unknowProgrammingTypes.join(', '))
            }

            videos.$$wasValidatedForProgrammingTypes = true
        }
    }

    function buildProgrammingTypeGroup(filteredVideos, programmingType, tasks = null) {
        const programmingTypeVideos = _.filter(filteredVideos, {
            programmingType: programmingType.type,
        })

        // const programmingSubTypePerfieldOptions = _.fromPairs(
        //     _.map(programmingType.subTypes, subType => [subType, $filter('titleCase')(subType) || 'Blank'])
        // )

        switch (programmingType.type) {
            case PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPE_EPISODE: {
                const series = buildEpisodeTypeSeries(programmingTypeVideos, tasks)
                return {
                    ...programmingType,
                    // programmingSubTypePerfieldOptions,
                    series,
                    length: series.length,
                    label: PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPE_EPISODE_LABEL,
                }
            }

            default: {
                return {
                    ...programmingType,
                    // programmingSubTypePerfieldOptions,
                    videos: _.map(programmingTypeVideos, attachVideoTasks(tasks)),
                    length: programmingTypeVideos.length,
                }
            }
        }
    }

    function buildEpisodeTypeSeries(videos, tasks = null) {
        let seriesId = 0

        const seriesTitles = fp.flow(fp.map('seriesTitle'), fp.uniq)(videos)

        const allSeries = _.map(seriesTitles, (seriesTitle) => {
            const seriesVideos = _.filter(videos, { seriesTitle })

            const seasonNumbers = fp.flow(fp.map('seasonNumber'), fp.uniq)(seriesVideos)

            const series = {
                id: ++seriesId,
                seriesTitle,
                seasons: buildSeasons(seriesVideos, seasonNumbers, seriesTitle),
            }

            return series

            //////////////////

            function buildSeasons(seriesVideos, seasonNumbers, seriesTitle) {
                const seasons = _.map(seasonNumbers, (seasonNumber) => {
                    const season = {
                        seriesTitle,
                        seasonNumber,
                        episodes: fp.flow(
                            fp.filter({ seasonNumber }),
                            fp.map(attachVideoTasks(tasks)),
                            fp.map(buildEpisode)
                        )(seriesVideos),
                        tableColumns: fp.flow(
                            fp.filter(
                                (task) =>
                                    task.video.seriesTitle === seriesTitle &&
                                    task.video.seasonNumber === seasonNumber
                            ),
                            fp.map((task) => task.task_details),
                            fp.uniq
                        )(tasks),
                    }

                    return season
                })

                return seasons
            }

            function buildEpisode(video) {
                const episode = {
                    video,
                    seriesTitle: video.seriesTitle,
                    seasonNumber: video.seasonNumber,
                    episodeNumber: video.episodeNumber,
                }

                return episode
            }
        })

        return allSeries
    }

    /**
     * Build a mapper function for videos that attaches matching tasks to a video copy if
     * they were present, or just returns the identity function
     *
     * @param {array|null} tasks
     * @return {function}
     */
    function attachVideoTasks(tasks) {
        if (tasks) {
            return function (video) {
                return {
                    ...video,
                    tasks: _.filter(
                        tasks,
                        (task) => task && task.video && task.video.map3id === video.map3id
                    ),
                }
            }
        } else {
            return _.identity
        }
    }
}
