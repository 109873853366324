import _ from 'lodash'
import { AuthorityManagerInstance } from './AuthorityManager.factory'
import { TaskServiceInstance } from './TaskService.factory'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { trackButtonClick, setSnowplowContext } from 'util/snowplow'

export default /* @ngInject */ function AuthorityTaskCtrl(
    this: unknown,
    $scope: ng.IScope,
    $state: ng.ui.IStateService,
    MapDialog: any,
    TaskService: TaskServiceInstance,
    Notification: any,
    CommentInterface: any,
    AuthorityManager: AuthorityManagerInstance,
    MarkerTransformation: any,

    task: { [key: string]: any },
    assignmentID: string,
    taskQuestions: any[],
    videoData: any,
    taskAuthority: any,
    commentThreads: any
) {
    const authorityTaskVm = this as {
        submitAuthorityTask: () => void
        sceneData: any
        commentThreads: any
        hideVideo: boolean
        authorityRecords: any[]
        taskQuestions: any[]
        taskOptions: any
        taskVideo: any
        bookmarks: any
        markersList: any[]
        videoApi: VideoAPIInstance
    }

    authorityTaskVm.submitAuthorityTask = submitAuthorityTask

    authorityTaskVm.sceneData = task.scenes
    authorityTaskVm.commentThreads = commentThreads

    activate()

    //////////////////////////

    function activate() {
        $setupComments()

        authorityTaskVm.hideVideo = false
        authorityTaskVm.authorityRecords = _.isArray(authorityTaskVm.authorityRecords)
            ? authorityTaskVm.authorityRecords
            : []

        $scope.$on('authority.attributes.changed', saveAttributeChanges)

        authorityTaskVm.taskQuestions = taskQuestions

        authorityTaskVm.authorityRecords = _.map(taskAuthority, function (authority) {
            if (_.isEmpty(authority.attributes)) {
                authority.attributes = AuthorityManager.buildAttributes(taskQuestions)
            }

            return authority
        })

        setSnowplowContext('task', task)

        $scope.$on('$destroy', function destroyAuthorityTask() {
            setSnowplowContext('task', null)
        })

        authorityTaskVm.taskOptions = task.options
        authorityTaskVm.taskVideo = videoData
    }

    function $setupComments() {
        authorityTaskVm.bookmarks = task.bookmarks

        CommentInterface.init({
            hitID: task.hitID,
            threads: authorityTaskVm.commentThreads,
            assignmentID,
            hasOnlyGeneralThread: true,
        })

        $scope.$onRootScope('map3.addBookmark', addBookmark)
        $scope.$onRootScope('map3.deleteBookmark', deleteBookmark)
        $scope.$on('$destroy', CommentInterface.destroy)
        $scope.$watchCollection('authorityTaskVm.bookmarks', () => {
            authorityTaskVm.markersList = MarkerTransformation.bookmarks(authorityTaskVm.bookmarks)
        })
    }

    function addBookmark() {
        const timestamp = authorityTaskVm.videoApi.getCurrentFrameTime()
        authorityTaskVm.bookmarks.push(timestamp)

        TaskService.setBookmarks(assignmentID, authorityTaskVm.bookmarks)
    }

    function deleteBookmark(marker: any) {
        const index = authorityTaskVm.bookmarks.indexOf(marker.time)
        authorityTaskVm.bookmarks.splice(index, 1)

        TaskService.setBookmarks(assignmentID, authorityTaskVm.bookmarks)
    }

    function submitAuthorityTask() {
        const authorityValidation = AuthorityManager.validateAuthorities(
            authorityTaskVm.authorityRecords,
            taskQuestions
        )

        trackButtonClick({
            label: 'Submit Task',
            componentName: 'Authority Task Submit',
        })

        if (authorityValidation.errors.length) {
            const firstError = _.head(authorityValidation.errors)

            $scope.$broadcast('authority.highlight', firstError.authority)
            Notification.error(firstError.message)
        } else if (authorityValidation.warnings.length) {
            const warningModal = MapDialog.confirm()
                .title('Warning! Do you want to continue?')
                .htmlContent(
                    `
                    <strong>The following warnings were generated:</strong>
                    <ul>
                        ${_.map(
                            authorityValidation.warnings,
                            (warning) => `<li>${warning.message}</li>`
                        ).join('')}
                    </ul>
                `
                )
                .ok('Submit')
                .cancel('Go Back')
            MapDialog.show(warningModal).then(submitAuthorities)
        } else {
            showSubmitDialog()
        }

        function showSubmitDialog() {
            const submitConfig = MapDialog.confirm()
                .title('Submit task')
                .htmlContent(
                    `
                <strong>Are you sure you want to proceed?</strong>
                <p>When you submit your changes will be stored and you won't be able to edit them anymore.</p>
                `
                )
                .ok('Yes')
                .cancel('No')

            MapDialog.show(submitConfig).then(submitAuthorities)
        }
    }

    function submitAuthorities() {
        Notification.forPromise(
            TaskService.submitAuthorities(assignmentID, authorityTaskVm.authorityRecords).then(
                function () {
                    $state.go('worker.list')
                }
            )
        )
    }

    function saveAttributeChanges() {
        Notification.forPromise(
            TaskService.saveAuthorities(assignmentID, authorityTaskVm.authorityRecords)
        )
    }
}

AuthorityTaskCtrl.resolve = {
    assignmentID: /* @ngInject */ function ($stateParams: ng.ui.IStateParamsService) {
        return $stateParams.assignmentID
    },

    task: /* @ngInject */ function (TaskService: any, assignmentID: string) {
        return TaskService.getTask(assignmentID)
    },

    videoData: /* @ngInject */ function (VideoService: any, task: { [key: string]: any }) {
        return VideoService.get(task.hitID)
    },

    taskQuestions: /* @ngInject */ function (
        $filter: ng.IFilterFunction,
        task: { [key: string]: any }
    ) {
        return $filter('orderByArray')(task.questions, task.options.order, 'placeholder')
    },

    commentThreads: /* @ngInject */ function (CommentService: any, task: { [key: string]: any }) {
        return CommentService.getThreads(task.hitID) || []
    },

    taskAuthority: /* @ngInject */ (task: { [key: string]: any }) => task.authority,
}
