import _ from 'lodash'
import fp from 'lodash/fp'
import { IValidation } from './types'

export default function AuthorityManagerFactory() {
    const AuthorityManager = {
        /**
         * Build all attributes for an authority from a questions array
         *
         * @param {array} questions
         * @return {array} attributes
         */
        buildAttributes(questions: { [key: string]: any }[]) {
            return _.map(questions, AuthorityManager.buildAttribute)
        },

        /**
         * Build a single authority attribute from a question
         *
         * @param {object} question
         * @return {object} {uri, label, value, mandatory, details}
         */
        buildAttribute(question: { [key: string]: any }) {
            return {
                uri: question.uri,
                label: question.label || question.placeholder,
                value: null,
                mandatory: question.mandatory,
                details: buildDetails(question.details),
            }

            //////////////////////////////////////////////

            function buildDetails(details: { [key: string]: any }[]) {
                return _.map(details, (detail) => {
                    return {
                        uri: detail.uri,
                        label: detail.label || detail.placeholder,
                        value: null,
                        multiple: detail.multiple,
                        mandatory: detail.mandatory,
                    }
                })
            }
        },

        /**
         * Validate that a given set of authorities
         */
        validateAuthorities(authorityRecords: any[], questions: { [key: string]: any }[]) {
            let validation: IValidation = {
                errors: [],
                warnings: [],
            }

            validation = AuthorityManager.$validateEditComplete(authorityRecords, validation)
            validation = AuthorityManager.$validateMandatoryQuestionsAnswered(
                authorityRecords,
                questions,
                validation
            )

            return validation
        },

        $validateEditComplete(authorityRecords: any[], validation: IValidation) {
            _.forEach(authorityRecords, (authority) => {
                if (authority.$$editing) {
                    validation.errors.push({
                        authority,
                        message: `You need to save or cancel editing "${authority.value.label}."`,
                    })
                }
            })

            return validation
        },

        $validateMandatoryQuestionsAnswered(
            authorityRecords: any[],
            questions: { [key: string]: any }[],
            validation: IValidation
        ) {
            const mandatoryQuestions = fp.flow(
                // filter to only mandatory question
                fp.filter({ mandatory: true }),
                // and attach $$mandatoryDetails property to each one
                fp.map((question: { [key: string]: any }) => {
                    return {
                        ...question,
                        $$mandatoryDetails: _.filter(question.details, { mandatory: true }),
                    }
                })
            )(questions)

            _.forEach(authorityRecords, (authority) => {
                const hasSomeAttributes = !!authority.attributes.length

                const hasAllMandatoryAnswers = _.every(
                    mandatoryQuestions,
                    (question: { [key: string]: any }) => {
                        const attributes = _.filter(authority.attributes, { uri: question.uri })

                        const hasMainAnswers =
                            attributes.length && _.every(attributes, (attribute) => attribute.value)

                        const hasDetailAnswers = _.every(
                            question.$$mandatoryDetails,
                            (questionDetail) => {
                                return fp.flow(
                                    // extract attribute details
                                    fp.map(
                                        (attribute: { [key: string]: any }) => attribute.details
                                    ),
                                    fp.flatten,
                                    // filter to mandatory details
                                    fp.filter({ uri: questionDetail.uri }),
                                    // check detail has value
                                    fp.every((detail: { [key: string]: any }) => detail.value)
                                )(attributes)
                            }
                        )

                        return hasMainAnswers && hasDetailAnswers
                    }
                )

                if (!hasAllMandatoryAnswers && hasSomeAttributes) {
                    validation.errors.push({
                        authority,
                        message: `Missing mandatory answers for "${authority.value.label}."`,
                    })
                }

                if (!hasSomeAttributes) {
                    validation.warnings.push({
                        authority,
                        message: `No answers for authority: <strong>${authority.value.label}</strong>`,
                    })
                }
            })

            return validation
        },
    }

    return AuthorityManager
}

export type AuthorityManagerInstance = ReturnType<typeof AuthorityManagerFactory>
