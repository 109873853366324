import _ from 'lodash'
import markExceptionHandled from 'util/markExceptionHandled'
import { AdminModuleInstance } from './AdminModule.factory'
import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'
import { ExportStatusPollerInstance } from 'services/ExportStatusPoller.factory'
import { QARouterInstance } from 'qa/routes'
import { ResultsModuleInstance } from 'services/ResultsModule.factory'
import { QAAssignmentClass } from 'qa/types'
import { ExportStatus, ReportFormat } from 'constants.es6'

const ANOMALY_TABLE_ORDER_PRIORITY = ['critical', 'high', 'medium', 'low']

function sortAnomalyTable(a: TAnomaly, b: TAnomaly) {
    const orderedBySeverity =
        ANOMALY_TABLE_ORDER_PRIORITY.indexOf(a.severity) -
        ANOMALY_TABLE_ORDER_PRIORITY.indexOf(b.severity)

    return orderedBySeverity === 0 ? b.percent_anomaly - a.percent_anomaly : orderedBySeverity
}

interface IAdminDashboardCtrl {
    showGroupFilterModal: ($event: JQuery.TriggeredEvent) => void
    disableKeepAlive: (job: TJob) => void
    disableKeepAliveFromNotification: (notification: TNotification) => void
    confirmDismiss: (questionNotification: TNotification) => void
    confirmDeletion: (questionNotification: TNotification) => void
    postponeKeepAlive: (notification: TNotification, days: number) => void
    handleExport: () => void
    openQATask: (anomaly: TAnomaly) => void
    showMonthlyExportModal: () => void

    itemsPerTable: number
    currentLoadData: any
    monthlyReports: { month: string; year: string; params: string }[]
    accumulatedAnomalies: any

    questionNotificationsFilterBy: any
    keepAliveNotificationsFilterBy: any
    anomalyNotificationsFilterBy: any
    automationErrorNotificationsFilterBy: any

    questionNotificationsOrderBy: any
    keepAliveNotificationsOrderBy: any
    anomalyNotificationsOrderBy: any
    automationErrorNotificationsOrderBy: any

    questionNotificationsSearchInput: any
    keepAliveNotificationsSearchInput: any
    anomalyNotificationsSearchInput: any
    automationErrorNotificationsSearchInput: any

    questionNotifications: any
    keepAliveNotifications: any
    anomalyNotifications: any
    automationErrorNotifications: any

    questionNotificationsCurrentPage: any
    keepAliveNotificationsCurrentPage: any
    anomalyNotificationsCurrentPage: any
    automationErrorNotificationsCurrentPage: any

    questionNotificationsPaginated: any
    keepAliveNotificationsPaginated: any
    anomalyNotificationsPaginated: any
    automationErrorNotificationsPaginated: any

    questionNotificationsTotal: any
    keepAliveNotificationsTotal: any
    anomalyNotificationsTotal: any
    automationErrorNotificationsTotal: any
}

type TQuestionLabel = 'question'

type TKeepAliveLabel = 'keepAlive'

type TAnomalyLabel = 'anomaly'

type TAutomationErrorLabel = 'automationError'

type TAllowedLabels = TQuestionLabel | TAnomalyLabel | TKeepAliveLabel | TAutomationErrorLabel

export default /* @ngInject */ function AdminDashboardCtrl(
    this: unknown,
    $scope: ng.IScope,
    $filter: ng.IFilterFunction,
    $state: ng.ui.IStateService,
    $uibModal: ng.ui.bootstrap.IModalService,

    AdminModule: AdminModuleInstance,
    QARouter: QARouterInstance,

    MapDialog: any,
    Notification: any,

    UserPreferences: any,
    AdminDashboardDeferredLoader: any,
    MonthlyExportModal: any
) {
    const vm = this as IAdminDashboardCtrl

    vm.showGroupFilterModal = showGroupFilterModal

    vm.disableKeepAlive = disableKeepAlive
    vm.disableKeepAliveFromNotification = disableKeepAliveFromNotification
    vm.confirmDismiss = confirmDismiss
    vm.confirmDeletion = confirmDeletion
    vm.postponeKeepAlive = postponeKeepAlive
    vm.handleExport = handleExport
    vm.openQATask = openQATask

    vm.showMonthlyExportModal = MonthlyExportModal.showModal

    activate()

    //////////////////////////

    function activate() {
        vm.itemsPerTable = 15

        setupPaginatedNotificationsFor('question')
        setupPaginatedNotificationsFor('keepAlive')
        setupPaginatedNotificationsFor('anomaly')
        setupPaginatedNotificationsFor('automationError')

        const deferredLoader = new AdminDashboardDeferredLoader(vm)
        $scope.$on('$destroy', () => deferredLoader.destroy())
    }

    function setupPaginatedNotificationsFor(label: TAllowedLabels) {
        vm[`${label}NotificationsFilterBy`] = UserPreferences.get(
            $state.current.name,
            `${label}NotificationsFilterBy`,
            {}
        )
        vm[`${label}NotificationsOrderBy`] = UserPreferences.get(
            $state.current.name,
            `${label}NotificationsOrderBy`,
            []
        )

        $scope.$watch(`vm.${label}NotificationsFilterBy`, preparePaginatedNotificationsList, true)
        $scope.$watchGroup(
            [`vm.${label}NotificationsCurrentPage`, `vm.${label}NotificationsSearchInput`],
            preparePaginatedNotificationsList
        )
        $scope.$watchCollection(`vm.${label}Notifications`, preparePaginatedNotificationsList)
        $scope.$watchCollection(
            `vm.${label}NotificationsOrderBy`,
            preparePaginatedNotificationsList
        )

        const variables = {
            [`${label}NotificationsTotal`]: 0,
            [`${label}NotificationsPaginated`]: [],
            [`${label}NotificationsCurrentPage`]: 1,
        }
        _.assign(vm, variables)

        // --------------------

        function preparePaginatedNotificationsList() {
            const notifications = vm[`${label}Notifications`]
            if (!_.isArray(notifications)) {
                return
            }

            const filteredByFilter = $filter('perfieldFilter')(
                notifications,
                vm[`${label}NotificationsFilterBy`]
            )

            const ordered = $filter('orderBy')(filteredByFilter, vm[`${label}NotificationsOrderBy`])
            const filteredBySearch = vm[`${label}NotificationsSearchInput`]
                ? $filter('filter')(ordered, vm[`${label}NotificationsSearchInput`])
                : ordered

            const currentPage = vm[`${label}NotificationsCurrentPage`]

            vm[`${label}NotificationsPaginated`] = filteredBySearch.slice(
                (currentPage - 1) * vm.itemsPerTable,
                currentPage * vm.itemsPerTable
            )
            vm[`${label}NotificationsTotal`] = filteredBySearch.length
        }
    }

    function showGroupFilterModal($event: JQuery.TriggeredEvent) {
        $event.target.blur()
        const modal = $uibModal.open({
            template: `
                <div class="md-dialog-container shortcuts-dialog">
                    <header class="modal-header">
                        <h4>Filter by Groups and/or Projects</h4> 
                    </header>
                    <section class="modal-body">
                        <section class="form-group">
                            <label class="control-label">Group(s)</label>
                            <div class="chosen-holder">
                                <select
                                    class="w-100"
                                    chosen
                                    multiple
                                    name="User groups"
                                    data-placeholder-text-multiple="'Select Groups'"
                                    ng-model="vm.groupsSelected"
                                    ng-options="group as group.label for group in vm.groups track by group.uri"
                                ></select>
                            </div>
                        </section>
                        <section class="form-group">
                            <label class="control-label">Project(s)</label>
                            <div class="chosen-holder">
                                <select
                                    class="w-100"
                                    chosen
                                    multiple
                                    name="User projects"
                                    data-placeholder-text-multiple="'Select Projects'"
                                    ng-model="vm.projectsSelected"
                                    ng-options="project as project.label for project in vm.projects track by project.uri"
                                ></select>
                            </div>
                        </section>
                    </section>
                    <footer class="modal-footer">
                        <button ng-click="$dismiss()" class="btn" type="button">
                            Cancel
                        </button>
                        <button ng-click="$close({groups: vm.groupsSelected, projects: vm.projectsSelected})" class="btn btn-primary" type="button">
                            Filter
                        </button>
                    </footer>
                </div>
            `,
            controller: /* @ngInject */ function (
                this: unknown,
                groups: Group[],
                groupsSelected: Group[],
                projects: Project[],
                projectsSelected: Project[]
            ) {
                const vm = this as {
                    groups: Group[]
                    groupsSelected: Group[]
                    projects: Project[]
                    projectsSelected: Project[]
                }

                vm.groups = groups
                vm.groupsSelected = groupsSelected

                vm.projects = projects
                vm.projectsSelected = projectsSelected
            },
            controllerAs: 'vm',
            resolve: {
                groups: () => vm.currentLoadData.groups,
                groupsSelected: () => vm.currentLoadData.groupsSelected,
                projects: () => vm.currentLoadData.projects,
                projectsSelected: () => vm.currentLoadData.projectsSelected,
            },
        })

        modal.result.then((result) => {
            const { groups, projects } = result
            const promise = AdminModule.filterJobVelocity(groups, projects).then((data) => {
                vm.currentLoadData = _.get(data, 'currentLoad')
            })

            Notification.forPromise(promise, 'Current Load Filter Applied')
        })
    }

    function disableKeepAlive(job: TJob) {
        const promise = AdminModule.disableKeepAlive(job)

        Notification.forPromise(promise, 'Keep Alive disabled.')
    }

    function disableKeepAliveFromNotification(notification: unknown) {
        const promise = AdminModule.disableKeepAlive(notification as TJob).then(() => {
            _.remove(vm.keepAliveNotifications, notification as TNotification)
        })

        Notification.forPromise(promise, 'Keep Alive disabled.')
    }

    function postponeKeepAlive(notification: TNotification, days: number) {
        const promise = AdminModule.postponeKeepAlive(
            notification.uri,
            notification.expiry_date,
            days
        ).then(() => {
            _.remove(vm.keepAliveNotifications, notification)
        })

        Notification.forPromise(promise, 'Keep Alive postponed.')
    }

    function dismissQuestionNotification(questionNotification: TNotification) {
        AdminModule.dismissQuestionNotification(questionNotification)
            .then(() => {
                _.remove(vm.questionNotifications, questionNotification)

                Notification.success('Successfully dismissed.')
            })
            .catch(() => {
                Notification.error('Something went wrong.')
            })
    }

    function confirmDismiss(questionNotification: TNotification) {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to dismiss this notification?')
            .ok('Dismiss')
            .cancel('No')
            .okClass('btn-danger')

        MapDialog.show(confirmDialog).then(() => {
            dismissQuestionNotification(questionNotification)
        })
    }

    function deleteQuestionNotification(questionNotification: TNotification) {
        AdminModule.deleteQuestionNotification(questionNotification)
            .then(() => {
                _.remove(vm.questionNotifications, questionNotification)

                Notification.success('Successfully deleted.')
            })
            .catch(() => {
                Notification.error('Something went wrong.')
            })
    }

    function confirmDeletion(questionNotification: TNotification) {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to delete this task?')
            .ok('Delete')
            .cancel('No')
            .okClass('btn-danger')

        MapDialog.show(confirmDialog).then(() => {
            deleteQuestionNotification(questionNotification)
        })
    }

    function handleExport() {
        $uibModal.open({
            templateUrl: 'js/admin/missing-songs-export-modal.tpl.html',
            controller: ExportModalCtrl,
            backdrop: 'static',
        })
    }

    function openQATask(anomaly: TAnomaly) {
        const assignment = { id: anomaly.hit_id, class: anomaly.task_class as QAAssignmentClass }
        QARouter.go(assignment)
    }
}

AdminDashboardCtrl.resolve = {}

export /* @ngInject */ function AdminDashboardDeferredLoaderFactory(
    $q: ng.IQService,
    $http: ng.IHttpService,
    $filter: ng.IFilterService
) {
    class AdminDashboardDeferredLoader {
        vm: IAdminDashboardCtrl
        cancelDeferred: ng.IDeferred<unknown>
        constructor(vm: IAdminDashboardCtrl) {
            this.vm = vm
            this.cancelDeferred = $q.defer()
            this.init()
        }

        init() {
            this.setupJobsVelocity(this.vm)
            this.setupKeepAliveNotifications(this.vm)
            this.setupQuestionNotifications(this.vm)
            this.setupMonthlyReports(this.vm)
            this.setupAccumulatedAnomalies(this.vm)
            this.setupAnomalies(this.vm)
            this.setupAutomationErrors(this.vm)
        }

        setupJobsVelocity(vm: IAdminDashboardCtrl) {
            vm.currentLoadData = this.$request('/api/admin/dashboard/current-load').then(
                (res: { data: { currentLoad: TNotification[] } }) => {
                    vm.currentLoadData = _.get(res.data, 'currentLoad')
                }
            )
        }

        setupKeepAliveNotifications(vm: IAdminDashboardCtrl) {
            vm.keepAliveNotifications = this.$request('/api/admin/dashboard/keep-alive').then(
                (res: { data: { keepAliveNotifications: TNotification[] } }) => {
                    vm.keepAliveNotifications = _.get(res.data, 'keepAliveNotifications')
                }
            )
        }

        setupQuestionNotifications(vm: IAdminDashboardCtrl) {
            vm.questionNotifications = this.$request(
                '/api/admin/dashboard/throttling-containers'
            ).then((res: { data: { throttledContainersNotifications: TNotification[] } }) => {
                vm.questionNotifications = _.get(res.data, 'throttledContainersNotifications')
            })
        }

        setupMonthlyReports(vm: IAdminDashboardCtrl) {
            const monthsBack = 12
            const date = new Date()
            vm.monthlyReports = []

            for (let i = 0; i < monthsBack; i++) {
                vm.monthlyReports.push({
                    month: $filter('date')(date, 'MMMM'),
                    year: $filter('date')(date, 'yyyy'),
                    params: $filter('date')(date, 'M') + '/' + $filter('date')(date, 'yyyy'),
                })
                date.setMonth(date.getMonth() - 1)
            }
        }

        setupAccumulatedAnomalies(vm: IAdminDashboardCtrl) {
            vm.accumulatedAnomalies = this.$request(
                '/api/admin/dashboard/accumulated-anomalies'
            ).then((res: { data: { accumulated_anomalies: TAnomaly[] } }) => {
                vm.accumulatedAnomalies = _.get(res.data, 'accumulated_anomalies', []).sort(
                    sortAnomalyTable
                )
            })
        }

        setupAnomalies(vm: IAdminDashboardCtrl) {
            vm.anomalyNotifications = this.$request('/api/admin/dashboard/anomalies').then(
                (res: { data: { anomalies: TAnomaly[] } }) => {
                    vm.anomalyNotifications = _.get(res.data, 'anomalies', []).sort(
                        sortAnomalyTable
                    )
                }
            )
        }

        setupAutomationErrors(vm: IAdminDashboardCtrl) {
            vm.automationErrorNotifications = this.$request('/api/admin/automation/errors').then(
                (res: { data: TAutomationErrors }) => {
                    vm.automationErrorNotifications = res.data
                }
            )
        }

        destroy() {
            this.cancelDeferred.resolve()
        }

        $request(uri: string) {
            const promise = $http.get(uri, {
                timeout: this.cancelDeferred.promise,
                ignoreLoadingBar: true,
            })
            // because the request can be cancelled, swallow the exception
            return markExceptionHandled(promise)
        }
    }

    return AdminDashboardDeferredLoader
}
interface IExportModalCtrl {
    startExport: (format: any) => void
    closeOrCancelExport: () => void

    STATUS_INIT: ExportStatus.Init
    STATUS_CHECKING: ExportStatus.Checking
    STATUS_POLLING: ExportStatus.Polling
    STATUS_DOWNLOAD_READY: ExportStatus.DownloadReady
    STATUS_FAILED: ExportStatus.Failed

    status: ExportStatus.Init | ExportStatus.Checking
    errorMessage: string | false
    poller: any
    exportModel: any
}

// allow closing of modal without warning user
const STATUSES_REQUIRE_USER_CONFIRMATION_FOR_CANCEL = [ExportStatus.Checking]

/* @ngInject */
function ExportModalCtrl(
    $q: ng.IQService,
    $scope: ng.IScope & IExportModalCtrl,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,

    MapDialog: any,
    ErrorStringifier: ErrorStringifierInstance,
    ResultsModule: ResultsModuleInstance,
    ExportStatusPoller: ExportStatusPollerInstance
) {
    let abortCheckDeferred = $q.defer()

    $scope.startExport = startExport
    $scope.closeOrCancelExport = closeOrCancelExport

    // make the statuses available to the template
    $scope.STATUS_INIT = ExportStatus.Init
    $scope.STATUS_CHECKING = ExportStatus.Checking
    $scope.STATUS_POLLING = ExportStatus.Polling
    $scope.STATUS_DOWNLOAD_READY = ExportStatus.DownloadReady
    $scope.STATUS_FAILED = ExportStatus.Failed
    $scope.status = ExportStatus.Init

    activate()

    /////////////////////////////////////////

    function activate() {
        $scope.$on('$destroy', () => {
            if ($scope.poller) {
                $scope.poller.destroy()
                $scope.poller = null
            }
        })
    }

    function startExport(format: ReportFormat) {
        $scope.status = ExportStatus.Checking
        $scope.errorMessage = false

        const exportPromise = ResultsModule.exportMissingSongs(format)
            .then((exportModel: any) => {
                $scope.poller = new ExportStatusPoller($scope, exportModel)
                $scope.exportModel = $scope.poller.exportModel
            })
            // If we get an actial error, display it.
            // MapDialog rejections will not be shown.
            .catch((error: any) => {
                if (error) {
                    $scope.errorMessage = ErrorStringifier.stringify(error)
                }
            })

        return exportPromise
    }

    function closeOrCancelExport() {
        if (_.includes(STATUSES_REQUIRE_USER_CONFIRMATION_FOR_CANCEL, $scope.status)) {
            // if we're currently not downloading, close the modal
            // otherwize ask for verification
            MapDialog.show(
                MapDialog.confirm({
                    title: 'Warning',
                    htmlContent: `
                    <strong>A export is currently in progress!</strong>
                    <br>
                    Do you wish to cancel it?
                `,
                    ok: 'Cancel Export',
                    cancel: 'Continue Export',
                })
            ).then(abortExport)
        } else {
            $uibModalInstance.dismiss()
        }
    }

    function abortExport() {
        // resolve the abort promise
        abortCheckDeferred.resolve()

        // create a new deferred object
        abortCheckDeferred = $q.defer()
        $scope.status = ExportStatus.Init
        $scope.errorMessage = false
    }
}
