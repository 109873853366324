import _ from 'lodash'
import fp from 'lodash/fp'
import { AuthorityManagerInstance } from '../AuthorityManager.factory'
import { IWorkerAttribute, IWorkerDetail } from '../types'

type ScopeBindings = {
    addNewMainQuestionSetFor: (question: { [key: string]: any }) => void
    removeMainQuestionSetFor: (attribute: IWorkerAttribute) => void
    saveAttributes: (form: any) => void
    editAttributes: () => void
    cancelSaveAttributes: () => void
    findQuestionForAttribute: (attribute: IWorkerAttribute) => { [key: string]: any } | undefined
    findQuestionForAttributeDetail: (
        attribute: IWorkerAttribute,
        detail: IWorkerDetail
    ) => { [key: string]: any }
    isFirstOfAttributeType: (attribute: IWorkerAttribute) => boolean
    editingAttributes: boolean
    maxDetailsCount: number
    questions: { [key: string]: any }[]
    authority: any
    authorityAttributesCopy: any
}

export default /* @ngInject */ function authorityAttributesDirective(
    AuthorityManager: AuthorityManagerInstance
) {
    const directive = {
        restrict: 'E',
        scope: {
            authority: '=',
            questions: '<',
            title: '<?',
            help: '@?',
        },
        link: authorityAttributesLinkFn,
        templateUrl: 'js/worker/directives/authority-attributes.tpl.html',
    }

    return directive

    ////////////////////////////////////

    function authorityAttributesLinkFn(
        scope: ng.IScope & ScopeBindings,
        element: ng.IAugmentedJQuery
    ) {
        element.addClass('authority-details')

        scope.addNewMainQuestionSetFor = addNewMainQuestionSetFor
        scope.removeMainQuestionSetFor = removeMainQuestionSetFor

        scope.saveAttributes = saveAttributes
        scope.editAttributes = editAttributes
        scope.cancelSaveAttributes = cancelSaveAttributes

        scope.findQuestionForAttribute = findQuestionForAttribute
        scope.findQuestionForAttributeDetail = findQuestionForAttributeDetail
        scope.isFirstOfAttributeType = isFirstOfAttributeType

        activate()

        /////////////////////////////////////////////////

        function activate() {
            scope.editingAttributes = false

            scope.maxDetailsCount =
                fp.flow(
                    fp.map((question) => _.get(question, 'details.length', 0)),
                    fp.max
                )(scope.questions) || 0

            scope.$watch(
                'authority.attributes',
                function (authorityAttributes) {
                    scope.authorityAttributesCopy = angular.copy(authorityAttributes)
                },
                /* deep */ true
            )

            // scope.$watch('editingAttributes', editingAttributes => {
            //     element.toggleClass('editing', editingAttributes)
            // })

            scope.$on('authority.forceEditAttributes', (e, authority, attributes) => {
                if (scope.authority === authority) {
                    scope.authorityAttributesCopy = angular.copy(attributes)
                    editAttributes()
                }
            })
        }

        function saveAttributes(form: any) {
            // do not allow save of invalid form input
            if (!form.$valid) {
                return
            }

            scope.authority.attributes = angular.copy(scope.authorityAttributesCopy)
            scope.editingAttributes = false
            scope.authority.$$editing = false

            scope.$emit('authority.attributes.changed')
        }

        function editAttributes() {
            scope.authority.$$editing = true
            scope.editingAttributes = true
        }

        function cancelSaveAttributes() {
            scope.authorityAttributesCopy = angular.copy(scope.authority.attributes)
            scope.editingAttributes = false
            scope.authority.$$editing = false
        }

        function addNewMainQuestionSetFor(question: { [key: string]: any }) {
            const attribute = AuthorityManager.buildAttribute(question)
            const index = _.findLastIndex(scope.authorityAttributesCopy, { uri: question.uri })

            scope.authorityAttributesCopy.splice(index + 1, 0, attribute)
        }

        function removeMainQuestionSetFor(attribute: IWorkerAttribute) {
            _.pull(scope.authorityAttributesCopy, attribute)
        }

        function isFirstOfAttributeType(attribute: IWorkerAttribute) {
            const sameTypeAttributes = _.filter(scope.authorityAttributesCopy, {
                uri: attribute.uri,
            })

            return sameTypeAttributes[0] === attribute
        }

        function findQuestionForAttribute(attribute: IWorkerAttribute) {
            return _.find(scope.questions, { uri: attribute.uri })
        }

        function findQuestionForAttributeDetail(
            attribute: IWorkerAttribute,
            detail: IWorkerDetail
        ) {
            const question = _.find(scope.questions, { uri: attribute.uri })

            return _.find(question && question.details, { uri: detail.uri })
        }
    }
}
