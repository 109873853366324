import _ from 'lodash'
import type { AdminModuleInstance } from '../../admin/AdminModule.factory'

const SERVICE_CWR = 'cwr' as const
const SERVICE_KAFKA = 'kafka' as const

type Service = typeof SERVICE_CWR | typeof SERVICE_KAFKA

export type Project = {
    label: string
    uri: string
    groups: Group[]
    enabledServices: Service[]
    cwr: null
    kafka: Record<string, unknown> | null
    errors?: Record<string, unknown> | null
}

export type ProjectFormData = Omit<Project, 'uri'>

/* @ngInject */
export default function AdminManageProjectCtrl(
    this: unknown,
    projects: Project[],
    groups: Group[],
    $uibModal: ng.ui.bootstrap.IModalService,
    MapDialog: any,
    Notification: any,
    AdminModule: AdminModuleInstance
) {
    const baseUrl = 'js/admin/manage-projects/'
    const vm = this as {
        projects: Project[]
        groups: Group[]

        showEditProjectModal: typeof showEditProjectModal
        showConfirmDeleteModal: typeof showConfirmDeleteModal
        showAddNewProjectModal: typeof showAddNewProjectModal
    }

    vm.projects = projects
    vm.groups = groups

    vm.showEditProjectModal = showEditProjectModal
    vm.showConfirmDeleteModal = showConfirmDeleteModal
    vm.showAddNewProjectModal = showAddNewProjectModal

    function showEditProjectModal(project: Project) {
        $uibModal.open({
            templateUrl: `${baseUrl}projectConfigModal.tpl.html`,
            size: 'lg',
            controller: EditProjectCtrl,
            controllerAs: 'vm',
            resolve: {
                project,
                parentVm: vm,
            },
        })
    }

    function showAddNewProjectModal() {
        $uibModal.open({
            templateUrl: `${baseUrl}projectConfigModal.tpl.html`,
            size: 'lg',
            controller: AddNewProjectCtrl,
            controllerAs: 'vm',
            resolve: {
                parentVm: vm,
            },
        })
    }

    function showConfirmDeleteModal(project: Project) {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to delete this project?')
            .ok('Yes')
            .okClass('btn-danger')
            .cancel('No')

        MapDialog.show(confirmDialog).then(() => {
            const promise = AdminModule.deleteProject(project).then(() => {
                _.remove(vm.projects, project)
            })

            Notification.forPromise(promise, `Project ${project.label} successfully deleted!`)
        })
    }
}

AdminManageProjectCtrl.resolve = {
    projects: /* @ngInject */ function (AdminModule: AdminModuleInstance) {
        return AdminModule.getProjects().then((res) => res)
    },
    groups: /* @ngInject */ function (AdminUsersModule: any) {
        return AdminUsersModule.getGroups().then((res: Group[]) => res)
    },
}

/* @ngInject */
function EditProjectCtrl(
    this: unknown,
    parentVm: any,
    project: Project,
    $scope: ng.IScope,
    $uibModalInstance: any,
    AdminModule: AdminModuleInstance,
    MapDialog: any,
    Notification: any
) {
    const vm = this as ng.IController & {
        projectConfig: Project
        projects: Project[]
        groups: Group[]

        isEditMode: true
        kakfkaConnected: boolean

        addFile: typeof addFile
        save: typeof save
        onCancel: typeof onCancel
        testKafkaConnectivity: typeof testKafkaConnectivity
    }

    vm.addFile = addFile
    vm.save = save
    vm.onCancel = onCancel
    vm.testKafkaConnectivity = testKafkaConnectivity

    vm.$onInit = $onInit

    return vm

    /////////////////////////////////////////////////////////////////

    function $onInit() {
        vm.projectConfig = _.cloneDeep(project)
        vm.groups = parentVm.groups
        vm.projects = _.filter(parentVm.projects, (project) => vm.projectConfig !== project)
        vm.isEditMode = true

        vm.SSLKeyfile = _.get(project, 'kafka.sslKeyfile.realFileName')
        vm.hasSSLCertificate = _.get(project, 'kafka.sslCertificate.realFileName')

        $scope.$watch(
            'vm.projectConfig.kakfa',
            () => {
                vm.kafkaConnected = false
            },
            true
        )
    }

    function testKafkaConnectivity(project: Project) {
        AdminModule.testKafkaConnectivity(project)
            .then((res) => {
                mergeProjectData(res)
                vm.kafkaConnected = true
            })
            .catch((res: ng.IHttpResponse<Project>) => {
                mergeProjectData(res.data)
                vm.kafkaConnected = false
            })
    }

    function addFile(file: File, prefix: string) {
        if (vm.projectConfig.kafka) {
            vm.projectConfig.kafka[prefix] = file
        }
    }

    function save() {
        const newProject = { ...vm.projectConfig }

        if (!newProject.enabledServices.includes(SERVICE_KAFKA)) {
            newProject.kafka = null
        }

        const promise = AdminModule.editProject(newProject)
            .then((project: Project) => {
                _.assign(_.find(parentVm.projects, { uri: newProject.uri }), { ...project })

                $uibModalInstance.close()

                Notification.forPromise(promise, 'Project successfully edited')
            })
            .catch((res: ng.IHttpResponse<Project>) => {
                mergeProjectData(res.data)
            })
    }

    function onCancel() {
        const areEqual = _.isEqual(project, vm.projectConfig)

        if (areEqual) {
            $uibModalInstance.close()
        } else {
            const confirmDialog = MapDialog.confirm()
                .title('Are you sure?')
                .textContent(
                    'There are some unsaved changes, if you continue without saving, you are going to lose them'
                )
                .ok('Continue')
                .okClass('btn-danger')
                .cancel('Cancel')

            MapDialog.show(confirmDialog)
                .then(() => {
                    $uibModalInstance.close()
                })
                .catch(() => {
                    confirmDialog.close()
                })
        }
    }

    function mergeProjectData(responseData: Project) {
        const sslCertificate = vm.projectConfig.kafka?.sslCertificate
        const sslKeyfile = vm.projectConfig.kafka?.sslKeyfile

        vm.projectConfig = responseData

        if (sslCertificate) {
            _.set(vm.projectConfig, 'kafka.sslCertificate', sslCertificate)
        }

        if (sslKeyfile) {
            _.set(vm.projectConfig, 'kafka.sslKeyFile', sslCertificate)
        }
    }
}

/* @ngInject */
function AddNewProjectCtrl(
    this: unknown,
    parentVm: any,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    $uibModal: ng.ui.bootstrap.IModalService,
    $uibModalInstance: any,
    AdminModule: AdminModuleInstance,
    Notification: any,
    MapDialog: any
) {
    const initialFormData: ProjectFormData = {
        label: '',
        groups: [],
        enabledServices: [],
        cwr: null,
        kafka: null,
    }

    const vm = this as ng.IController & {
        projectConfig: ProjectFormData
        projects: Project[]
        groups: Group[]

        kakfkaConnected: boolean

        addFile: typeof addFile
        save: typeof save
        onCancel: typeof onCancel
        testKafkaConnectivity: typeof testKafkaConnectivity
    }

    vm.testKafkaConnectivity = testKafkaConnectivity
    vm.addFile = addFile
    vm.save = save
    vm.onCancel = onCancel

    vm.$onInit = $onInit

    return vm

    /////////////////////////////////////////////////////////////////

    function $onInit() {
        vm.projectConfig = initialFormData
        vm.groups = parentVm.groups
        vm.projects = parentVm.projects

        $scope.$watch(
            'vm.projectConfig.kafka',
            () => {
                vm.kafkaConnected = false
            },
            true
        )
    }

    function testKafkaConnectivity(project: ProjectFormData) {
        AdminModule.testKafkaConnectivity(project)
            .then((res) => {
                mergeProjectData(res)
                vm.kakfkaConnected = true
            })
            .catch((res: ng.IHttpResponse<ProjectFormData>) => {
                mergeProjectData(res.data)
                vm.kakfkaConnected = false
            })
    }

    function addFile(file: File, prefix: string) {
        if (vm.projectConfig.kafka) {
            vm.projectConfig.kafka[prefix] = file
        }
    }

    function save() {
        const newProject = { ...vm.projectConfig }

        if (!newProject.enabledServices.includes(SERVICE_KAFKA)) {
            newProject.kafka = null
        }

        const promise = AdminModule.addProject(newProject)
            .then((project: Project) => {
                parentVm.projects.push(project)

                $uibModalInstance.close()

                Notification.forPromise(
                    promise,
                    `Project ${vm.projectConfig.label} successfully created!`
                )
            })
            .catch((res: ng.IHttpResponse<ProjectFormData>) => {
                mergeProjectData(res.data)
            })
    }

    function onCancel() {
        $uibModalInstance.close()
    }

    function mergeProjectData(responseData: ProjectFormData) {
        const sslCertificate = vm.projectConfig.kafka?.sslCertificate
        const sslKeyfile = vm.projectConfig.kafka?.sslKeyfile

        vm.projectConfig = responseData

        if (sslCertificate) {
            _.set(vm.projectConfig, 'kafka.sslCertificate', sslCertificate)
        }

        if (sslKeyfile) {
            _.set(vm.projectConfig, 'kafka.sslKeyFile', sslCertificate)
        }
    }
}
