export default /* @ngInject */ function DataEntryNetworkGuardFactory(
    RoutingAuth: any,
    $uibModal: ng.ui.bootstrap.IModalService
) {
    const DataEntryNetworkGuardFactory = {
        create: function <R, Arg extends any[]>(
            executeSave: (...args: Arg) => angular.IPromise<R>
        ) {
            return (...args: Arg): angular.IPromise<R> =>
                executeSave(...args).catch(() => {
                    return DataEntryNetworkGuardFactory._retry(() => executeSave(...args))
                })
        },
        execute: function <R>(executeSave: () => angular.IPromise<R>) {
            return executeSave().catch(() => {
                return DataEntryNetworkGuardFactory._retry(() => executeSave())
            })
        },
        _retry: function <R>(executeRetry: () => angular.IPromise<R>) {
            type LocalScope = {
                status: string
                STATUS_RETRYING: string
                onDismiss: () => void
                retrySave: () => angular.IPromise<R>
            }
            const modal = $uibModal.open({
                templateUrl: 'js/services/annotation/data-entry-network-guard-modal.tpl.html',
                backdrop: 'static',
                keyboard: false,
                controller: /* @ngInject */ function (
                    $state: ng.ui.IStateService,
                    $scope: ng.IScope & LocalScope
                ) {
                    const STATUS_INITIAL = 'save_failed'
                    const STATUS_RETRYING = 'save_retrying'
                    $scope.STATUS_RETRYING = STATUS_RETRYING
                    $scope.status = STATUS_INITIAL
                    $scope.onDismiss = onDismiss
                    $scope.retrySave = retrySave
                    function retrySave() {
                        $scope.status = STATUS_RETRYING
                        return executeRetry()
                            .then((result) => {
                                modal.close()
                                return result
                            })
                            .catch((result) => {
                                $scope.status = STATUS_INITIAL
                                return result
                            })
                    }
                    function onDismiss() {
                        modal.close()
                        $state.go(RoutingAuth.getDefaultState(), undefined, {
                            reload: true,
                        })
                    }
                },
            })
            return modal.result
        },
    }
    return DataEntryNetworkGuardFactory
}
export type DataEntryNetworkGuardInstance = ReturnType<typeof DataEntryNetworkGuardFactory>
