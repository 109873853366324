import _ from 'lodash'
import angular from 'angular'
import invariant from 'tiny-invariant'
import { DeepRequired } from 'utility-types'

import { VideoAPIInstance } from '../video/VideoAPIBuilder.factory'
import { ANNOTATION_STATUS, ANNOTATION_STATUS_REJECTED } from '../qa/service/QAAnnotation.factory'

const BOUNDING_BOX_COLORS = [
    '#ff2d55',
    '#2196f3',
    '#F7981C',
    '#4db606',
    '#5ec4cc',
    '#e5acf7',
    '#96d0fb',
    '#fae8ff',
    '#aaa',
    '#fff',
]
export type BoundingBox = {
    top: number
    left: number
    width: number
    height: number
}
type PathCoordinates = {
    x: number
    y: number
    h: number
    w: number
}
type ExtendedAnnotation = BaseAnnotation & {
    status?: ANNOTATION_STATUS
    extra_data?: {
        bounding_box?: BoundingBox
        bounding_box_color_index?: number
    }
}

export default /* @ngInject */ function BoundryDrawerFactory() {
    class BoundryDrawer {
        private annotations: ExtendedAnnotation[]
        private canvas: HTMLCanvasElement
        private context: CanvasRenderingContext2D | null
        private videoApi: VideoAPIInstance
        private dirtyCanvas: boolean

        constructor(videoApi: VideoAPIInstance) {
            this.dirtyCanvas = false
            this.annotations = []
            this.canvas = document.createElement('canvas')
            this.context = this.canvas.getContext('2d')
            this.videoApi = videoApi
            this.videoApi.onLoad(() => {
                if (!this.hasVideoWrapper()) {
                    return
                }
                this.attachCanvas()
                this.startLoop()
            })
        }

        setAnnotations(annotations: ExtendedAnnotation[]) {
            this.annotations = annotations
            this.renderAnnotations()
        }

        destroy() {
            this.canvas.remove()
        }

        forceFullRerender() {
            this.resizeCanvas()
            this.renderAnnotations()
        }

        private attachCanvas() {
            this.canvas.id = 'canvasdummy'
            this.canvas.setAttribute(
                'style',
                'position: absolute; top: 0; left: 0; width: 100%; height: 100%'
            )

            const videoWrapper = this.getVideoWrapper()
            videoWrapper.appendChild(this.canvas)
            this.resizeCanvas()
        }

        private resizeCanvas() {
            const videoWrapper = this.getVideoWrapper()
            const boundingContainer = videoWrapper.getBoundingClientRect()
            this.canvas.height = boundingContainer.height
            this.canvas.width = boundingContainer.width
        }

        private hasVideoWrapper(): boolean {
            try {
                this.getVideoWrapper()
                return true
            } catch (e) {
                return false
            }
        }

        private getVideoWrapper(): HTMLDivElement {
            return this.videoApi.getPlayer().player.videoElement?.video.parentNode
        }

        private startLoop() {
            const self = this
            if (!self.videoApi) return

            loop()

            self.videoApi.addEventListener('timeupdate', function () {
                if (self.videoApi && !self.videoApi.isPlaying()) {
                    self.renderAnnotations()
                }
            })

            //////////////////////

            function loop() {
                if (self.videoApi.$destroyed) {
                    return
                }
                if (!self.videoApi.isPlaying()) {
                    self.renderAnnotations()
                } else if (self.dirtyCanvas) {
                    self.clearCanvas()
                }

                window.requestAnimationFrame(loop)
            }
        }

        private renderAnnotations() {
            if (!this.getVideoWrapper()) {
                return
            }
            const currentPlayerTime = this.videoApi.getCurrentFrameTime()
            const annotations = _.filter(
                this.annotations,
                (annotation): annotation is DeepRequired<ExtendedAnnotation> => {
                    if (!_.get(annotation, 'extra_data.bounding_box', false)) {
                        return false
                    }

                    return false === annotation.timestamp
                        ? false
                        : this.isEqualTimestamp(annotation.timestamp, currentPlayerTime)
                }
            )

            this.renderBoundingBoxes(annotations)
        }

        private renderBoundingBoxes(annotations: DeepRequired<ExtendedAnnotation>[]) {
            this.clearCanvas()

            if (annotations.length) {
                this.handleAnnotaionBoxes(annotations)
            }
        }

        private handleAnnotaionBoxes(annotations: DeepRequired<ExtendedAnnotation>[]) {
            let len = annotations.length

            while (len--) {
                const annotation = annotations[len]
                const assignedColor = getColorFromIndex(
                    annotation.extra_data.bounding_box_color_index
                )

                const { value } = _.get(annotation, 'answers[0]')
                const valueNormalized = _.isArray(value) ? value[0] : value
                const text = _.get(valueNormalized, 'label', 'Unknown')
                const boundryBox = this.getPixelsFromFractions(annotation.extra_data.bounding_box)

                const strikethrough = annotation.status === ANNOTATION_STATUS_REJECTED

                this.drawBoundryBox(boundryBox, assignedColor)
                this.darawText(boundryBox.x, boundryBox.y, text, assignedColor, strikethrough)
                this.dirtyCanvas = true
            }
        }

        private getPixelsFromFractions(boundryBox: BoundingBox): PathCoordinates {
            invariant(this.canvas)
            const { left: x, top: y, width: w, height: h } = boundryBox
            const { width, height } = this.canvas

            return {
                x: x * width,
                y: y * height,
                w: w * width,
                h: h * height,
            }
        }

        private drawBoundryBox(coordinates: PathCoordinates, color: string) {
            invariant(this.context)

            const { x, y, w, h } = coordinates
            const ctx = this.context

            ctx.strokeStyle = color
            ctx.strokeRect(x, y, w, h)
            ctx.globalAlpha = 0.3
            ctx.fillStyle = color
            ctx.fillRect(x, y, w, h)
        }

        private darawText(
            x: number,
            y: number,
            text: string,
            color: string,
            strikethrough?: boolean
        ) {
            invariant(this.context)
            const ctx = this.context

            ctx.globalAlpha = 1
            ctx.textBaseline = 'middle'
            ctx.font = 'lighter 12px sans-serif'
            ctx.fillStyle = color
            const textWidth = ctx.measureText(text).width + 8
            ctx.fillRect(x, y, textWidth, 12 + 4)
            ctx.fillStyle = 'white'
            ctx.fillText(text, x + 4, y + 9)
            if (strikethrough) {
                ctx.beginPath()
                ctx.lineWidth = 2
                ctx.strokeStyle = 'white'
                ctx.moveTo(x, y + 7)
                ctx.lineTo(x + textWidth, y + 7)
                ctx.stroke()
            }
        }

        private clearCanvas() {
            invariant(this.context)

            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.dirtyCanvas = false
        }

        isEqualTimestamp(s1: number, s2: number) {
            return Math.abs(s1 - s2) < this.videoApi.computeHalfFrameEpsilon(false)
        }
    }

    return BoundryDrawer
}

export type BoundryDrawerInstance = ReturnType<typeof BoundryDrawerFactory>

export const getColorFromIndex = (index?: number) => {
    if (angular.isUndefined(index) || !angular.isNumber(index)) {
        return BOUNDING_BOX_COLORS[0]
    }
    const position = index % BOUNDING_BOX_COLORS.length

    return BOUNDING_BOX_COLORS[position]
}
