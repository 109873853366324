import { exposeOnRootScope } from './constants'

const PROD_ENVS = ['production', 'preproduction'] // eslint-disable-line
const PREPROD_ENVS = ['preproduction'] // eslint-disable-line
const isDev = PROD_ENVS.indexOf(window.MAP3_ENV) < 0 // eslint-disable-line
const isPreprod = PREPROD_ENVS.indexOf(window.MAP3_ENV) < 0 // eslint-disable-line

const map3features = angular
    .module('map3.features', [])

    .constant('TAXONOMY_MANAGMENT_UI', isDev)
    .constant('TASK_MAPPER_UPDATE_TO_ACCEPT_MARKERS', isDev)
    .constant('FEATURE_MISSING_SEGMENTS_NOTIFIER', isPreprod || isDev)

    .run(exposeOnRootScope(['TAXONOMY_MANAGMENT_UI']))
    .run(exposeOnRootScope(['TASK_MAPPER_UPDATE_TO_ACCEPT_MARKERS']))
    .run(exposeOnRootScope(['FEATURE_MISSING_SEGMENTS_NOTIFIER']))
export default map3features.name
