import _ from 'lodash'
import fp from 'lodash/fp'
import { AdminModuleInstance } from './AdminModule.factory'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { AdminUsersModuleInstance } from './AdminUsersModule.factory'

type PartialUser = Omit<TUser, 'groups'> & { groups: string[] }

const ITEMS_OFFSET = 25
interface IAdminManageUserCtrlInstance {
    users: PartialUser[]
    groups: UserGroup[]
    roles: TRole[]
    selectedGroups: string[]
    usersPredicate: any
    perfieldPredicate: any
    userSearchQuery: string
    requestInProgress: boolean
    allUsersLoaded: boolean
    cancelLastRequest: ng.IDeferred<unknown> | null

    loadMore: () => void
    humanReadableGroups: (users: TUser[]) => PartialUser[]
    showCreateGroupModal: () => void
    suspendUser: (user: TUser) => void
    activateUser: (user: TUser) => void
    deleteUser: (username: string) => void
    filterUsers: (user: TUser) => boolean
    loadFromSearch: () => void
}
export default /* @ngInject */ function AdminManageUsersCtrl(
    this: unknown,
    $state: ng.ui.IStateService,
    $scope: ng.IScope,
    $q: ng.IQService,
    $uibModal: ng.ui.bootstrap.IModalService,

    AdminUsersModule: AdminUsersModuleInstance,
    AdminModule: AdminModuleInstance,
    Notification: NotificationServiceInstance,
    MapDialog: any,
    UserPreferences: any,
    users: TUser[],
    GroupModel: any,

    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY
) {
    const adminVm = this as IAdminManageUserCtrlInstance
    adminVm.users = humanReadableGroups(users)

    adminVm.selectedGroups = []

    adminVm.usersPredicate = UserPreferences.get($state.current.name, 'adminVm.usersPredicate', [])
    adminVm.perfieldPredicate = UserPreferences.get(
        $state.current.name,
        'adminVm.perfieldPredicate',
        {}
    )

    adminVm.loadMore = loadMore
    adminVm.loadFromSearch = loadFromSearch

    adminVm.showCreateGroupModal = showCreateGroupModal

    adminVm.suspendUser = suspendUser
    adminVm.activateUser = activateUser
    adminVm.deleteUser = deleteUser

    adminVm.filterUsers = filterUsers
    adminVm.userSearchQuery = ''

    adminVm.requestInProgress = false
    let cancelLastRequest: ng.IDeferred<unknown> | null = null
    let offset = 0

    activate()

    //////////////////////////////

    function activate() {
        $scope.$watchCollection('adminVm.users', () => {
            adminVm.groups = fp.flow(
                fp.map((user: TUser) => user.groups),
                fp.flatten,
                fp.uniqBy('uri')
            )(users)
            adminVm.roles = fp.flow(
                fp.map((user: PartialUser) => user.roles),
                fp.flatten,
                fp.uniq
            )(adminVm.users)
        })

        $scope.$on('$destroy', () => {
            if (cancelLastRequest) {
                cancelLastRequest.resolve()
            }
        })
    }

    function humanReadableGroups(users: TUser[]): PartialUser[] {
        return users.map((user) => {
            return { ...user, groups: _.map(user.groups, 'label') }
        })
    }

    function loadMore() {
        offset += ITEMS_OFFSET

        loadDataFromBackend()
    }

    function loadFromSearch() {
        offset = 0
        adminVm.users = []
        adminVm.allUsersLoaded = false

        loadDataFromBackend()
    }

    function loadDataFromBackend() {
        if (cancelLastRequest) {
            cancelLastRequest.resolve(false)
            cancelLastRequest = null
        }

        cancelLastRequest = $q.defer()
        adminVm.requestInProgress = true

        AdminUsersModule.getUsers(
            adminVm.userSearchQuery,
            offset,
            cancelLastRequest.promise,
            NO_LOAD_OVERLAY
        )
            .then((res) => {
                if (res.length < ITEMS_OFFSET) {
                    adminVm.allUsersLoaded = true
                }

                adminVm.users = _.concat(adminVm.users, humanReadableGroups(res))
                cancelLastRequest = null
                adminVm.requestInProgress = false
            })
            .catch(() => {
                adminVm.users = []
            })
    }

    const confirmDialog = MapDialog.confirm()
        .title('Are you sure?')
        .textContent('Are you sure you want to suspend this user?')
        .ok('Yes')
        .cancel('No')

    function suspendUser(user: TUser) {
        confirmDialog.textContent('Are you sure you want to suspend this user?')

        MapDialog.show(confirmDialog)
            .then(function () {
                const suspendPromise = AdminUsersModule.suspendUser(user.uri)

                Notification.forPromise(
                    suspendPromise,
                    'User suspended successfully',
                    'Something went wrong'
                )

                return suspendPromise
            })
            .then(function () {
                user.status = 'Suspended'
            })
    }

    function activateUser(user: TUser) {
        confirmDialog.textContent('Are you sure you want to unsuspend this user?')

        MapDialog.show(confirmDialog)
            .then(() => {
                const unsuspendPromise = AdminUsersModule.activateUser(user.uri)

                Notification.forPromise(
                    unsuspendPromise,
                    'User activated successfully',
                    'Something went wrong'
                )

                return unsuspendPromise
            })
            .then(() => {
                user.status = 'Active'
            })
    }

    function deleteUser(username: string) {
        confirmDialog.textContent('Are you sure you want to delete this user?')

        MapDialog.show(confirmDialog)
            .then(() => {
                const deletePromise = AdminUsersModule.deleteUser(username)

                Notification.forPromise(
                    deletePromise,
                    'User deleted successfully',
                    'Something went wrong'
                )

                return deletePromise
            })
            .then(() => {
                _.remove(adminVm.users, { username: username })
            })
    }

    function filterUsers(user: TUser) {
        let result = true

        if (adminVm.selectedGroups.length) {
            result =
                result &&
                user.groups.some((group) => {
                    return _.includes(adminVm.selectedGroups, _.get(group, 'label'))
                })
        }

        return result
    }

    interface IShowCreateGroupModal {
        groups: UserGroup[]

        transformValueToGroup: (name: string) => UserGroup
        addGroup: (group: UserGroup) => void
        removeGroup: (group: UserGroup) => void
        closeModal: () => void
    }

    function showCreateGroupModal() {
        const instance = $uibModal.open({
            templateUrl: 'js/admin/createGroupModal.tpl.html',
            controller: function (this: unknown) {
                const createGroupVm = this as IShowCreateGroupModal

                createGroupVm.groups = adminVm.groups

                createGroupVm.transformValueToGroup = transformValueToGroup

                createGroupVm.addGroup = addGroup

                createGroupVm.removeGroup = removeGroup

                createGroupVm.closeModal = instance.close

                function addGroup(group: UserGroup) {
                    const addPromise = AdminModule.addGroup(group)

                    addPromise
                        .then((res) => {
                            group.uri = res.data
                        })
                        .catch(() => {
                            _.remove(createGroupVm.groups, group)
                        })

                    Notification.forPromise(
                        addPromise,
                        'Group created successfuly!',
                        'Something went wrong'
                    )
                }

                function removeGroup(group: UserGroup) {
                    createGroupVm.groups = _.filter(
                        adminVm.groups,
                        (adminVmGroup) => adminVmGroup !== group
                    )
                    const moveTaskToGroup = _.find(createGroupVm.groups, GroupModel.isPublic)
                    const removePromise = AdminModule.removeGroup(
                        group,
                        moveTaskToGroup as UserGroup
                    )

                    removePromise.catch(function () {
                        createGroupVm.groups.push(group)
                    })

                    Notification.forPromise(removePromise, 'Group successfuly removed!')
                }

                function transformValueToGroup(name: string) {
                    return {
                        label: name,
                        uri: '',
                    }
                }
            },
            controllerAs: 'createGroupVm',
        })
    }
}

AdminManageUsersCtrl.resolve = {
    users: /* @ngInject */ function (AdminUsersModule: AdminUsersModuleInstance) {
        return AdminUsersModule.getUsers()
    },
}
