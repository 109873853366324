// initialize the global VideoAccess singleton
import 'services/VideoAccess'

import _ from 'lodash'
import 'jquery'
import 'chosen-js'
import angular from 'angular'
import 'bootstrap'

import map3core from 'app.core'
import map3features from './features'
import map3auth from './auth/module'
import map3public from './public/module'
import map3worker from 'worker/module'
import map3user from 'user/module'
import map3admin from 'admin/module'
import map3qa from 'qa/module'
import map3video from 'video/module'
import map3scenes from 'scenes/module'
import map3highlights from 'highlights/module'
import map3contentMarkers from 'content-markers/module'
import map3results from 'results/module'
import map3directives from 'directives'

import markExceptionHandled from 'util/markExceptionHandled'
import { newRelic } from 'util/newRelic'

import { setupSnowplow } from './util/snowplow'

angular
    .module('map3', [
        map3core,
        map3features,
        map3auth,
        map3public,
        map3admin,
        map3user,
        map3worker,
        map3qa,
        map3video,
        map3scenes,
        map3highlights,
        map3contentMarkers,
        map3results,
        map3directives,
    ])
    .config(appConfig)
    .config(exceptionHandlerDecorator)
    .config(uibModalConfig)
    .config(setupNgRedux)
    .run(initThemeManager)
    .run(setupNewRelic)
    .run(setupRoutingAuth)
    .run(setupUiRouterHandling)
    // .run(addAndRemoveSlashScreen)
    .run(setupTimeTracker)
    .run(setupSnowplow)
    .run(
        /* @ngInject */ function ($rootScope, User, Idle) {
            /* eslint angular/on-watch: 0 */
            $rootScope.user = User.cached()

            $rootScope.$on('cfpLoadingBar:loading', function () {
                $rootScope.loadingCompleted = false
            })

            $rootScope.$on('cfpLoadingBar:completed', function () {
                $rootScope.loadingCompleted = true
            })

            $rootScope.$on('user.authenticate', function () {
                Idle.watch()
            })
        }
    )
    .run(
        /* @ngInject */ ($http) => {
            const preface = `${process.env.APP_PREFIX}`
            const tokenKey = `${process.env.JWT_TOKEN_KEY}`
            const token = localStorage.getItem(`${preface}:${tokenKey}`)
            if (token) {
                $http.defaults.headers.common.Authorization = `Bearer ${token}`
            }
        }
    )

/* @ngInject */
function appConfig(
    $httpProvider,
    $urlRouterProvider,
    IdleProvider,
    TitleProvider,
    cfpLoadingBarProvider,
    chosenProvider
) {
    $httpProvider.defaults.xsrfHeaderName = 'X-XSRF-TOKEN'
    $httpProvider.defaults.xsrfCookieName = 'XSRF-TOKEN'
    $httpProvider.defaults.withCredentials = true
    $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike'

    cfpLoadingBarProvider.latencyThreshold = 500

    TitleProvider.enabled(false)

    if (
        process.env.NODE_ENV === 'development' ||
        window.location.hostname === 'redesign.map3.space'
    ) {
        // do not setup auto logout on local dev
        // division is to make it usable for timers
        IdleProvider.idle(parseInt(Number.MAX_SAFE_INTEGER / 10000))
        IdleProvider.timeout(30)
    } else {
        // normal behavior
        IdleProvider.idle(1200) // 20 min * 60 sec
        IdleProvider.timeout(30)
    }

    $urlRouterProvider.otherwise('/auth/login')

    chosenProvider.setOption({
        max_shown_results: 100,
    })
}

/* @ngInject */
function setupNgRedux($ngReduxProvider) {
    $ngReduxProvider.createStoreWith(
        {
            lastReduxAction: (_state, action) => {
                return action
            },
            sceneData: 'UndoableScenesReducer',
            highlightsData: 'CombinedHighlightsReducer',
            contentMarkersData: 'CombinedContentMarkersReducer',
            watchData: 'WatchVideoReducer',
        },
        [
            /* trunks */
        ],
        (window.__REDUX_DEVTOOLS_EXTENSION__ && [window.__REDUX_DEVTOOLS_EXTENSION__()]) || []
    )
}

/* @ngInject */
function initThemeManager(ThemeManager) {
    ThemeManager.init()
}

/* @ngInject */
function setupRoutingAuth(RoutingAuth) {
    RoutingAuth.init({
        defaultState: 'worker.list',
        defaultStateForRole: {
            Admin: 'admin.dashboard',
            Manager: 'admin.tasks',
            QA: 'qa.list',
            Worker: 'worker.list',
            Reviewer: 'results.resultsList',
        },
        defaultStateForMultiRole: 'auth.chooseSessionRole',
        denyMultiRole: true,
    })
}

/* @ngInject */
function setupTimeTracker(TimeTracker) {
    TimeTracker.init()
}

/* @ngInject */
function setupUiRouterHandling(
    $rootScope,
    $state,
    $log,
    $injector,
    Notification,
    User,
    Idle,
    cfpLoadingBar
) {
    // expose state on $rootScope
    $rootScope.$state = $state

    $rootScope.$on(
        '$stateChangeSuccess',
        function (_event, toState, _toParams, _fromState, _fromParams) {
            // handle navigation visibility as set on state params
            $rootScope.NAVIGATION_HIDDEN = _.get(toState, 'data.navHidden', false)
            // handle footer visibility as set on state params
            $rootScope.FOOTER_HIDDEN = _.get(toState, 'data.footerHidden', false)
            // handle user automatic logout on idling
            const disableAutomaticLogout = _.get(toState, 'data.disableAutomaticLogout', false)
            if (disableAutomaticLogout) {
                Idle.running() && Idle.unwatch()
            } else {
                !Idle.running() && Idle.watch()
            }

            // store current state
            User.storeRouteResolveDebugData($injector.get('$state').$current)

            // force the loading bar to complete, so that we don't have it animating over a new page
            cfpLoadingBar.complete()
        }
    )

    // make sure ui.router errors are properly outputted to console
    $rootScope.$on(
        '$stateChangeError',
        function (event, toState, toParams, fromState, fromParams, error) {
            Notification.error(error)

            /**
             * Handle 404 inside resolvers,
             * else log an error
             */
            if (error.status === 404) {
                $injector.get('$state').go('errorPageNotFound')
            } else {
                $log.error('$stateChangeError', {
                    event: event,
                    toState: toState,
                    toParams: toParams,
                    fromState: fromState,
                    fromParams: fromParams,
                    error: error,
                })

                $log.error(error)
            }
        }
    )

    // build a debug option for all APP routes
    window.MAP3_ROUTES = _.fromPairs(_.map($state.get(), buildRoute))

    /////////////////////////////////////////////

    function buildRoute(state, _key, allStates) {
        let url = state.url

        if (hasParent(state.name)) {
            let parentStateName = state.name.substring(0, _.lastIndexOf(state.name, '.'))
            let parentState = _.find(allStates, { name: parentStateName })
            if (parentState) {
                let [, parentUrl] = buildRoute(parentState, 0, allStates)
                url = parentUrl + url
            }
        }

        return [state.name, url]

        function hasParent(stateName) {
            return stateName.indexOf('.') > 0
        }
    }
}

/* @ngInject */
function exceptionHandlerDecorator($provide) {
    const PUR_TEST = /Possibly unhandled rejection/

    $provide.decorator(
        '$exceptionHandler',
        /* @ngInject */ function ($delegate, $injector) {
            return function (exception, cause) {
                /**
                 * Check if "Possibly unhandled rejection" errors are to be ignored
                 */
                const $rootScope = $injector.get('$rootScope')
                if ($rootScope.$$IGNORE_PUR && PUR_TEST.test(exception)) {
                    return
                }

                newRelic.noticeError(exception, { cause: cause })

                $delegate(exception, cause)
            }
        }
    )
}

/* @ngInject */
function uibModalConfig($provide, $uibModalProvider) {
    // update uib classes for bootstrap 4
    $uibModalProvider.options.backdropClass = 'show'
    $uibModalProvider.options.windowTopClass = 'show'

    $provide.decorator(
        '$uibModal',
        /* @ngInject */ function ($delegate, $rootScope, $uibModalStack, GlobalShortcuts) {
            const originalOpen = $delegate.open

            $delegate.open = function (options) {
                GlobalShortcuts.suspend()
                const $uibModalInstance = originalOpen.call($delegate, options)

                // mark the result promise for skipping unhandled rejection check
                // https://github.com/angular/angular.js/blob/cdaa6a951b8fce67673bec224a7ad1f24f0a05bf/src/ng/q.js#L675-L683
                markExceptionHandled($uibModalInstance.result)

                // once a modal is closed
                $uibModalInstance.result.finally(() => {
                    // if there are no open modals left
                    if (!$uibModalStack.getTop()) {
                        // resume GlobalShortcuts
                        GlobalShortcuts.resume()
                    }
                })

                // close modal instances on user logout
                const unsubscribeOnUserLogout = $rootScope.$on('user.logout', function () {
                    if ($uibModalInstance.closed.$$state.status === 0) {
                        $uibModalInstance.dismiss()
                    }

                    unsubscribeOnUserLogout()
                })

                return $uibModalInstance
            }

            return $delegate
        }
    )
}

/* @ngInject */
function setupNewRelic($rootScope, User) {
    if (!newRelic.isNewRelicLoaded()) {
        return
    }

    // set the logged in user for New Relic
    $rootScope.$on('user.authenticate', function () {
        newRelic.addPageAction('user.authenticate', User.getUserFormattedForNewRelic())
        newRelic.setCustomAttribute('person', User.getUserFormattedForNewRelic().username)
        fetch('https://jsonip.com', { mode: 'cors' })
            .then((res) => res.json())
            .then(({ ip }) => {
                newRelic.setCustomAttribute('IP Address', ip)
            })
    })

    $rootScope.$on('user.logout', function () {
        newRelic.addPageAction('user.logout')
    })
}

// /* @ngInject */
// function addAndRemoveSlashScreen() {
//     const isNotProdution = window.MAP3_ENV !== 'production'
//     // don't show splash if not on production and more than once per session
//     if (isNotProdution || Storage.getItem('splashHasBeenShown')) {
//         return
//     }

//     Storage.setItem('splashHasBeenShown', true)
//     $('body').prepend('<div class="splash-screen"/>')

//     setTimeout(() => {
//         $('.splash-screen').animate(
//             {
//                 opacity: 0,
//             },
//             {
//                 duration: 500,
//                 always: () => {
//                     $('.splash-screen').remove()
//                 },
//             },
//         )
//     }, 5000)
// }
