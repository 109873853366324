import _ from 'lodash'
import { ActionCreators } from 'redux-undo'
import { ContentMarkerType } from './constants'
import { TContentMarker } from './types'
import {
    ContentMarkersActionsInstance,
    ContentMarkersActionCreatorsInstance,
} from './ContentMarkersReducer'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { getLabelForMarker, isPair } from './utils'
import { TimelineElement } from 'directives/scene-splitting/genericTimeline.component'
import { DOMUtility } from 'services/DOMUtility.factory'
import { trackButtonClick } from 'util/snowplow'

const CONTENT_MARKER_TIMELINE_TYPE = 'content-marker'
const CONTENT_MARKER_INSERTION_POINT_ERROR =
    'Creation of a marker within content marker pair is not allowed'
const CONTENT_MARKER_OVERLAP_ERROR =
    'Creation of a content marker overlapping another marker is not allowed'
const OUT_OF_BOUNDARIES_ERROR = 'Content marker position is going to be outside video boundaries'
const CONTENT_MARKER_ON_THE_WAY_WARNING = 'Cannot advance further, there is a marker on the way'

const CONTENT_MARKER_PAIR_MIN_FRAMES_IN_BETWEEN = 4

class JumpToTimeModalController {
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
    sharedVideoAPI: any
    videoApi?: VideoAPIInstance
    time: number | null = null
    /* @ngInject */
    constructor($uibModalInstance: ng.ui.bootstrap.IModalInstanceService, SharedVideoAPI: any) {
        this.$uibModalInstance = $uibModalInstance
        this.sharedVideoAPI = SharedVideoAPI
    }

    handleApply($event: KeyboardEvent) {
        if ($event.key === 'Enter') {
            this.$uibModalInstance.close()
            const time = Number(this.time)
            if (this.videoApi && !isNaN(time)) {
                this.videoApi.seek(time)
            }
        }
    }

    handleVideoApiReady(videoApi: VideoAPIInstance) {
        this.videoApi = videoApi
    }

    $onInit() {
        const boundHandleVideoApi = this.handleVideoApiReady.bind(this)
        this.sharedVideoAPI.onLoad(boundHandleVideoApi)
    }
}
class ContentMarkersTimelineController {
    $element: ng.IAugmentedJQuery
    $scope: ng.IScope
    $ngRedux: any
    $uibModal: ng.ui.bootstrap.IModalService

    MapDialog: any

    contentMarkersActionCreators: ContentMarkersActionCreatorsInstance
    contentMarkersReducer: any
    contentMarkersActions: ContentMarkersActionsInstance
    globalShortcuts: any
    notification: any

    editMode = false
    // eslint-disable-next-line
    videoApi!: VideoAPIInstance

    sharedVideoAPI: any

    contentMarkers: TContentMarker[] = []
    elements: any[] = []
    selectedContentMarkerId: number | null = null

    contentMarkersState: any

    timelineId = 'generic-timeline-' + DOMUtility.nextUid()

    /* @ngInject */
    constructor(
        $element: ng.IAugmentedJQuery,
        $scope: ng.IScope,
        $ngRedux: any,
        $uibModal: ng.ui.bootstrap.IModalService,

        SharedVideoAPI: any,
        GlobalShortcuts: any,
        ContentMarkersActions: ContentMarkersActionsInstance,
        ContentMarkersActionCreators: ContentMarkersActionCreatorsInstance,
        ContentMarkersReducer: any,
        Notification: any,
        MapDialog: any
    ) {
        this.$element = $element
        this.$ngRedux = $ngRedux
        this.$scope = $scope
        this.$uibModal = $uibModal

        this.sharedVideoAPI = SharedVideoAPI
        this.MapDialog = MapDialog

        this.contentMarkersActionCreators = ContentMarkersActionCreators
        this.contentMarkersReducer = ContentMarkersReducer
        this.contentMarkersActions = ContentMarkersActions
        this.globalShortcuts = GlobalShortcuts
        this.notification = Notification
    }

    handleOnSingleSelect(element: any) {
        if (element.type === CONTENT_MARKER_TIMELINE_TYPE) {
            trackButtonClick({
                label: 'Select Marker By Id',
                value: [
                    {
                        metadata: {
                            markerId: element.contentMarker.id,
                        },
                    },
                ],
            })

            this.contentMarkersActions.selectById({ id: element.contentMarker.id })
        } else {
            this.contentMarkersActions.selectById({ id: null })
        }
    }

    buildElements() {
        const contentMarkers = this.contentMarkers
        const elements = []

        let lastUid = 0
        let lastFrameNumber = 0
        let lastElement = contentMarkers[0]

        // Leading spacer
        if (lastElement && lastElement.frameNumber !== 0) {
            elements.push(buildSpacerElement(0, lastElement.frameNumber - 1))
        }

        _.forEach(contentMarkers, function (contentMarker, idx) {
            // Intermediate spacer
            if (idx > 0 && contentMarker.frameNumber - lastFrameNumber > 1) {
                elements.push(
                    buildSpacerElement(
                        lastFrameNumber + 1,
                        Math.max(0, contentMarker.frameNumber - 1)
                    )
                )
            }

            lastFrameNumber = contentMarker.frameNumber
            lastElement = contentMarker

            elements.push(buildContentMarkerElement(contentMarker))
        })

        const lastFrameIdx = this.videoApi.getLastFrameIdx()

        // Trailing spacer
        if (!contentMarkers.length || lastFrameNumber < lastFrameIdx) {
            const start = contentMarkers.length ? lastFrameNumber + 1 : 0
            const end = lastFrameIdx
            elements.push(buildSpacerElement(start, end))
        }

        const self = this
        this.elements = elements.map((e) => self.attachUIDs(e))

        function buildSpacerElement(startFrame: number, endFrame: number) {
            endFrame = endFrame > 0 ? endFrame : 0

            return {
                id: ++lastUid,
                type: 'spacer',
                startFrame,
                endFrame,
            }
        }

        function buildContentMarkerElement(contentMarker: TContentMarker) {
            // We don't have any specification, except for pairs
            let markerMeetSpecifications = true
            // We assume specs are meet

            if (isPair(contentMarker)) {
                const pairs = _.filter(contentMarkers, { pair: contentMarker.pair })
                // For pairs, specs says: Minimum 4 frames between them.
                // e.g. FFCB on 00:15:36:03 LFCB on 00:15:36:08
                markerMeetSpecifications =
                    Math.abs(pairs[0].frameNumber - pairs[1].frameNumber) >
                    CONTENT_MARKER_PAIR_MIN_FRAMES_IN_BETWEEN
            }

            return {
                id: ++lastUid,
                contentMarker: contentMarker,
                type: CONTENT_MARKER_TIMELINE_TYPE,
                startFrame: contentMarker.frameNumber,
                endFrame: contentMarker.frameNumber,
                $$isSelected: contentMarker.$$isSelected,
                label: getLabelForMarker(contentMarker),
                chapter: _.get(contentMarker, 'chapter', false),
                cssClass: {
                    'marker-does-not-meet-specs': !markerMeetSpecifications,
                },
            }
        }
    }

    attachUIDs(element: any): TimelineElement {
        return {
            ...element,
            $$uid: this.timelineId + '-' + element.type + '-' + element.id,
        }
    }

    goToSelectedContentMarkerFrame() {
        const contentMarker = _.find(this.contentMarkers, {
            id: this.selectedContentMarkerId,
        } as any)
        if (contentMarker) {
            this.videoApi.seekFrame(contentMarker.frameNumber)
        }
    }

    setupShortcuts() {
        const self = this
        const viewShortcuts: any[] = [
            {
                description: 'Next Marker',
                keyCombo: ']',
                shortcut: ']',
                callback: () => {
                    selectNextContentMarker()
                    self.goToSelectedContentMarkerFrame()
                },
            },
            {
                description: 'Previous Marker',
                keyCombo: '[',
                shortcut: '[',
                callback: () => {
                    selectPreviousContentMarker()
                    self.goToSelectedContentMarkerFrame()
                },
            },
            {
                description: 'Jump to the timestamp',
                global: true,
                keyCombo: ['alt+shift+g'],
                shortcut: 'Alt+shift+G',
                callback: function () {
                    self.showJumpToDialogue()
                },
            },
        ]

        const editShortcuts: any[] = [
            {
                description: 'Create FFCB/LFCB',
                keyCombo: ['x'],
                shortcut: 'X',
                callback: () => createContentMarkerPair(),
            },
            {
                description: 'Create FPCI',
                keyCombo: ['ctrl+x', 'meta+x'],
                shortcut: 'control+X',
                callback: () => createFixedPointInsertion(),
            },
            {
                description: 'Delete',
                keyCombo: ['del', 'backspace'],
                shortcut: 'Del',
                callback: () => deleteSelectedContentMarker(),
                metadata: {
                    contentMarkers: this.contentMarkers,
                    markerId: this.selectedContentMarkerId,
                },
            },
            {
                description: 'Move Marker Forward By One Frame',
                global: true,
                keyCombo: ['alt+shift+right'],
                shortcut: 'Alt+shift+Right Arrow',
                callback: () => moveEntireContentMarker(1),
            },
            {
                description: 'Move Marker Backward By One Frame',
                global: true,
                keyCombo: ['alt+shift+left'],
                shortcut: 'Alt+shift+Left Arrow',
                callback: () => moveEntireContentMarker(-1),
            },
            {
                description: 'Move Marker Forward By One Second',
                global: true,
                keyCombo: ['alt+shift+p'],
                shortcut: 'Alt+shift+P',
                callback: () => moveEntireContentMarkerBySeconds(1),
            },
            {
                description: 'Move Marker Backward By One Second',
                global: true,
                keyCombo: ['alt+shift+o'],
                shortcut: 'Alt+shift+O',
                callback: () => moveEntireContentMarkerBySeconds(-1),
            },
            {
                description: 'Move Marker Forward By 30 Seconds',
                global: true,
                keyCombo: ['alt+shift+l'],
                shortcut: 'Alt+shift+L',
                callback: () => moveEntireContentMarkerBySeconds(30),
            },
            {
                description: 'Move Marker Backward By 30 Seconds',
                global: true,
                keyCombo: ['alt+shift+k'],
                shortcut: 'Alt+shift+K',
                callback: () => moveEntireContentMarkerBySeconds(-30),
            },
            {
                description: 'Undo',
                global: true,
                keyCombo: ['ctrl+z', 'meta+z'],
                shortcut: 'control+Z',
                callback: () => self.$ngRedux.dispatch(ActionCreators.undo()),
            },
            {
                description: 'Redo',
                global: true,
                keyCombo: ['ctrl+y', 'meta+y'],
                shortcut: 'control+Y',
                callback: () => self.$ngRedux.dispatch(ActionCreators.redo()),
            },
        ]

        const shortcuts = viewShortcuts.concat(self.editMode ? editShortcuts : [])

        const unbind = this.globalShortcuts.bind({ title: 'Marker Shortcuts', shortcuts })
        this.$scope.$on('$destroy', unbind)

        ///////////////////////////////////////

        function selectNextContentMarker() {
            trackButtonClick({
                label: 'Select Next Marker',
            })

            self.contentMarkersActions.selectNext({ currentFrame: self.videoApi.getCurrentFrame() })
        }

        function selectPreviousContentMarker() {
            trackButtonClick({
                label: 'Select Previous Marker',
            })

            self.contentMarkersActions.selectPrevious({
                currentFrame: self.videoApi.getCurrentFrame(),
            })
        }

        function moveEntireContentMarker(offsetFrames: number) {
            const selectedContentMarker = self.getSelectedContentMarker()
            if (!selectedContentMarker) return

            const { frameNumber: selectedFrameNumber } = selectedContentMarker
            const isLtrDirection = offsetFrames > 0
            const targetFrame = selectedFrameNumber + offsetFrames
            const closestContentMarker = self.getClosestContentMarker(isLtrDirection)

            if (closestContentMarker) {
                const closestFrameNumber = closestContentMarker.frameNumber
                const isMarkerOnTheWay = isLtrDirection
                    ? targetFrame >= closestFrameNumber
                    : targetFrame <= closestFrameNumber

                if (isMarkerOnTheWay) {
                    offsetFrames = isLtrDirection
                        ? closestFrameNumber - selectedFrameNumber - 1
                        : closestFrameNumber - selectedFrameNumber + 1

                    self.notification.warning(CONTENT_MARKER_ON_THE_WAY_WARNING)
                }
            } else {
                const videoBoundary = isLtrDirection ? self.videoApi.getLastFrameIdx() : 0
                const isBoundaryOnTheWay = isLtrDirection
                    ? targetFrame > videoBoundary
                    : targetFrame < videoBoundary

                if (isBoundaryOnTheWay) {
                    offsetFrames = isLtrDirection
                        ? videoBoundary - selectedFrameNumber
                        : videoBoundary - selectedFrameNumber

                    self.notification.error(OUT_OF_BOUNDARIES_ERROR)
                }
            }

            if (offsetFrames === 0) return

            trackButtonClick({
                label: 'Move Marker Frame',
                value: [
                    {
                        metadata: {
                            markerId: self.selectedContentMarkerId,
                        },
                    },
                ],
            })

            self.contentMarkersActions.moveFrame({
                id: self.selectedContentMarkerId,
                offsetFrames,
                videoApi: self.videoApi,
            })

            self.goToSelectedContentMarkerFrame()
        }

        function moveEntireContentMarkerBySeconds(seconds: number) {
            const selectedContentMarker = self.getSelectedContentMarker()
            if (!selectedContentMarker) return

            const { timestamp, frameNumber } = selectedContentMarker
            const targetTime = timestamp + seconds
            const targetFrameNumber = self.videoApi.convertLaxSecondToFrame(targetTime)
            const offsetFrames = targetFrameNumber - frameNumber

            if (targetTime < 0 || targetTime > self.videoApi.getDuration()) {
                self.notification.error(OUT_OF_BOUNDARIES_ERROR)
            }

            moveEntireContentMarker(offsetFrames)
        }

        // edit mode

        function createContentMarkerPair() {
            const currentFrame = self.videoApi.getCurrentFrame()
            const timestampStart = self.videoApi.getCurrentFrameTime()
            const timestampEnd = self.videoApi.convertFrameToSeconds(currentFrame + 1)

            if (
                self.targetFrameWillOverlapMarker(currentFrame) ||
                self.targetFrameWillOverlapMarker(currentFrame + 1)
            ) {
                self.notification.error(CONTENT_MARKER_OVERLAP_ERROR)
                return
            }

            if (self.isTargetFrameWithinContentMarkerPair(currentFrame)) {
                self.notification.error(CONTENT_MARKER_INSERTION_POINT_ERROR)
                return
            }

            trackButtonClick({
                label: 'Create Marker Pair',
            })

            self.contentMarkersActions.createPair({
                frameNumber: currentFrame,
                timestampStart: timestampStart,
                timestampEnd: timestampEnd,
            })

            self.goToSelectedContentMarkerFrame()
        }

        function createFixedPointInsertion() {
            const currentFrame = self.videoApi.getCurrentFrame()
            const timestamp = self.videoApi.getCurrentFrameTime()

            if (self.targetFrameWillOverlapMarker(currentFrame)) {
                self.notification.error(CONTENT_MARKER_OVERLAP_ERROR)
                return
            }

            if (self.isTargetFrameWithinContentMarkerPair(currentFrame)) {
                self.notification.error(CONTENT_MARKER_INSERTION_POINT_ERROR)
                return
            }

            trackButtonClick({
                label: 'Create Fixed Point Insertion Marker',
            })

            self.contentMarkersActions.createFixedPointInsertion({
                frameNumber: currentFrame,
                timestamp,
            })
        }

        function deleteSelectedContentMarker() {
            const selectedContentMarker = self.getSelectedContentMarker()

            if (!selectedContentMarker) return

            if (isPair(selectedContentMarker)) {
                self.confirmPairDeletion().then(() => {
                    trackButtonClick({
                        label: 'Delete Marker Pair',
                        value: [
                            {
                                metadata: {
                                    pairId: selectedContentMarker.pair,
                                },
                            },
                        ],
                    })
                    self.contentMarkersActions.deletePair({ pairId: selectedContentMarker.pair })
                })
            } else {
                trackButtonClick({
                    label: 'Delete Marker',
                    value: [
                        {
                            metadata: {
                                markerId: self.selectedContentMarkerId,
                            },
                        },
                    ],
                })
                self.contentMarkersActions.deleteById({ id: self.selectedContentMarkerId })
            }
        }
    }

    showJumpToDialogue() {
        this.$uibModal.open({
            templateUrl: 'js/content-markers/jumpToTimeModal.tpl.html',
            controllerAs: 'vm',
            controller: JumpToTimeModalController,
        })
    }

    targetFrameWillOverlapMarker(frameNumber: number) {
        return !!_.find(this.contentMarkers, { frameNumber })
    }

    getSelectedContentMarker() {
        return _.find(this.contentMarkers, { id: this.selectedContentMarkerId as any })
    }

    getClosestContentMarker(ltrDirection: boolean) {
        const selectedContentMarkerIndex = this.contentMarkers.findIndex(
            (contentMarker) => contentMarker.id === this.selectedContentMarkerId
        )

        return ltrDirection
            ? this.contentMarkers[selectedContentMarkerIndex + 1]
            : this.contentMarkers[selectedContentMarkerIndex - 1]
    }

    isSFPCI(selectedContentMarker: TContentMarker) {
        return _.get(selectedContentMarker, 'chapter', false)
    }

    isTargetFrameWithinContentMarkerPair(frameNumber: number) {
        const nextMarker = _.find(this.contentMarkers, (marker) => marker.frameNumber > frameNumber)

        if (nextMarker && isPair(nextMarker)) {
            return nextMarker.type === ContentMarkerType.LFCB
        }
    }

    handleVideoApiReady(videoApi: VideoAPIInstance) {
        this.videoApi = videoApi

        if (this.editMode) {
            const unsubscribe = this.$ngRedux.connect(mapStateToThis)(this)
            this.$scope.$on('$destroy', unsubscribe)
        } else {
            const initAction = (this.contentMarkersActionCreators as any).init({
                contentMarkers: this.contentMarkers,
                videoApi: this.videoApi,
            })
            const { contentMarkers } = this.contentMarkersReducer(undefined, initAction)
            this.contentMarkers = contentMarkers
        }

        this.$scope.$watch('$ctrl.contentMarkers', () => this.buildElements())
        this.$scope.$watch('$ctrl.contentMarkersState', () => this.buildElements())

        this.setupShortcuts()

        ///////////////////////////////

        function mapStateToThis({
            contentMarkersData: {
                contentMarkersState,
                undoableContentMarkers: {
                    present: { contentMarkers },
                },
            },
        }: any) {
            const selected = _.filter(contentMarkers, { $$isSelected: true })

            return {
                contentMarkers,
                contentMarkersState,
                selectedContentMarkerId: selected.length === 1 ? selected[0].id : null,
            }
        }
    }

    confirmPairDeletion() {
        const confirmDialog = this.MapDialog.confirm()
            .title('Are you sure?')
            .textContent('This will delete both First and Last Frame of Comercial Black pair?')
            .ok('Delete')
            .cancel('No')
            .okClass('btn-danger')

        return this.MapDialog.show(confirmDialog)
    }

    $onInit() {
        this.$element.addClass('generic-timeline-holder')

        this.editMode = this.editMode !== false
        const boundHandleVideoApi = this.handleVideoApiReady.bind(this)
        this.sharedVideoAPI.onLoad(boundHandleVideoApi)

        $('zoom-level').on('click', () => {
            ;(document.activeElement as HTMLElement).blur()
            $(this.timelineId).trigger('focus')
        })

        this.$scope.$on('$destroy', () => $('zoom-level').off('click'))
    }
}

const contentMarkersTimelineComponent = {
    controller: ContentMarkersTimelineController,
    bindings: {
        filmstripType: '<?',
        renderMode: '<?',
        contentMarkers: '<?',
        selectedContentMarkerId: '=',
        editMode: '<',
        timeFormat: '<',
    },
    template: `
        <content-markers-timeline-base
            elements="$ctrl.elements"
            on-single-select="$ctrl.handleOnSingleSelect(element)"
            render-mode="hidden"
            filmstrip-type="$ctrl.filmstripType"
            timeline-id="$ctrl.timelineId"
            time-format="$ctrl.timeFormat"
            class="content-markers-base"
        ></content-markers-timeline-base>
    `,
}

export default contentMarkersTimelineComponent
