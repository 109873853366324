import _ from 'lodash'
import { AdminModuleInstance } from './AdminModule.factory'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { getTimeFractionsByTimeInSeconds } from 'services/utils'

import { TEidrL2Status, TSMSVideoContent, TSMSVideo } from './types'
import { UserInstance } from 'auth/User.factory'

export default class AdminSMSIngestCtrl {
    static resolve = {
        videos: /* @ngInject */ (AdminModule: AdminModuleInstance) => {
            return AdminModule.getSMSVideos().then((res) => res)
        },
    }

    $scope: ng.IScope

    Notification: NotificationServiceInstance
    AdminModule: AdminModuleInstance

    SMSVideos: TSMSVideo[]

    eidrL2Status: TEidrL2Status | false

    videosOrderBy: string[]

    selectedEidrL2: string[]
    dupesArr: any[]
    username: any
    /* @ngInject */
    constructor(
        $scope: ng.IScope,
        Notification: NotificationServiceInstance,
        User: UserInstance,
        AdminModule: AdminModuleInstance,

        videos: TSMSVideo[]
    ) {
        this.$scope = $scope

        this.Notification = Notification
        this.AdminModule = AdminModule

        this.SMSVideos = videos

        this.eidrL2Status = false
        this.selectedEidrL2 = []
        this.dupesArr = []
        this.username = _.get(User.cached(), ['username'])

        this.videosOrderBy = ['-updated']

        this.$scope.$watchCollection('vm.videosOrderBy', () => {
            if (this.videosOrderBy.length === 0) {
                this.videosOrderBy = ['-updated']
            }
        })
    }

    checkSMSVideoStatus(eidrL2: string) {
        this.AdminModule.checkSMSVideoStatus(eidrL2).then((data) => {
            this.eidrL2Status = data
        })
    }

    removeDupesFromPaste({ text }: { text: string }) {
        if (_.find(this.dupesArr, (d) => d === text)) {
            return false
        }

        this.dupesArr.push(text)
        return true
    }

    clearDupsArray() {
        if (this.dupesArr.length) {
            this.dupesArr.length = 0
        }
    }

    ingestMultiple(form: ng.IFormController) {
        this.AdminModule.ingestVideoFor(this.selectedEidrL2, this.username).then(() => {
            this.AdminModule.getSMSVideos().then((res) => {
                this.SMSVideos = res
            })

            this.selectedEidrL2 = []

            form.$setPristine()
            form.$setUntouched()
        })
    }

    ingestSingle(video: TSMSVideo) {
        this.AdminModule.ingestVideoFor([video.eidrL2], this.username, video.source)
            .then((data) => {
                const { eidrL2, status, error, requester } = data[0]

                const video = _.find(this.SMSVideos, { eidrL2 })
                if (video) {
                    video.status = status
                    video.error = error
                    video.requester = requester
                } else {
                    this.Notification.error(`Video with such EIDR L2 was not found: ${eidrL2}`)
                }
            })
            .catch(({ data }) => {
                const { error, status } = data[0]
                if (!error && !status) {
                    this.Notification.error('This video cannot be ingested.')
                } else {
                    this.Notification.error(`Status: ${status}, Error ${error}.`)
                }
            })
    }

    getStatusCellTitle(item: TSMSVideoContent) {
        return item.status === 'failed' ? item.error || 'Unknown' : null
    }

    getVideoDuration(item: TEidrL2Status) {
        return item.estimatedRuntimeSecs
            ? getTimeFractionsByTimeInSeconds(item.estimatedRuntimeSecs)
            : ''
    }
}
