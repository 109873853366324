import _ from 'lodash'
import angular from 'angular'
import { ReviewDataPartial } from './QAAnnotationTree.factory'
import { BoundingBox } from '../../services/BoundryBoxDrawer.factory'
export const ANNOTATION_STATUS_APPROVED = 'approved'
export const ANNOTATION_STATUS_REJECTED = 'rejected'
export const ANNOTATION_STATUS_UNKNOWN = 'unknown'
export type ANNOTATION_STATUS =
    | typeof ANNOTATION_STATUS_APPROVED
    | typeof ANNOTATION_STATUS_UNKNOWN
    | typeof ANNOTATION_STATUS_REJECTED

const ANNOTATION_COPY_PROPS = ['timestamp', 'answers', 'groupID', 'extra_data'] as const
const ANNOTATION_COPY_PROPS_USER = ['username']

export type BackendQAAnnotation = BaseAnnotation & {
    annotationID: number
    assignmentID: string
    worker: unknown
    extra_data?: {
        mature?: boolean
        cluster_id?: boolean
        cluster_copy_for_annotation?: number
        bounding_box?: BoundingBox
        bounding_box_color_index?: number
        map3_style?: string
        automation_id?: number
    }
    status: ANNOTATION_STATUS
    matchingAnnotationIDs: number[]
}

export type QAAnnotation = BackendQAAnnotation & {
    newAnnotation?: boolean
    // local
    hasComment?: boolean
    editingCopy?: NewAnnotation
    groupID?: number
    groupErrors?: string[]
    groupErrorsString?: string
    errors?: string[]
    errorsString?: string
    hasErrors?: boolean
    isClusterCopy?: boolean
}

export type NewAnnotation = Pick<QAAnnotation, (typeof ANNOTATION_COPY_PROPS)[number]>

export default /* @ngInject */ function QAAnnotationFactory(
    $http: ng.IHttpService,
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    User: any
) {
    const QAAnnotation = {
        /**
         * PUT /api/hit/qa/{hitID} { annotationTreeUpdate: [ {annotationID: 123, status: "approved"} ] }
         */
        approveAnnotation(hitID: string, annotation: QAAnnotation) {
            const annotationTreeUpdate = [
                {
                    annotationID: annotation.annotationID,
                    status: ANNOTATION_STATUS_APPROVED,
                },
            ]

            annotation.status = ANNOTATION_STATUS_APPROVED

            return $http
                .put<ReviewDataPartial>(
                    `/api/hit/qa/${hitID}`,
                    { annotationTreeUpdate },
                    NO_LOAD_OVERLAY
                )
                .then((res) => res.data)
        },

        approveAllAnnotations(hitID: string, annotationTree: ReviewDataPartial['annotationTree']) {
            const annotationTreeUpdate: QAAnnotation[] = []
            const allAnnotations = _.flatMap(annotationTree, (group) => group.annotations)

            _.forEach(allAnnotations, (a) => {
                a.status = ANNOTATION_STATUS_APPROVED
                annotationTreeUpdate.push(a)
            })

            return $http
                .put<ReviewDataPartial>(`/api/hit/qa/${hitID}`, { annotationTreeUpdate })
                .then((res) => res.data)
        },

        /**
         * PUT /api/hit/qa/{hitID} { annotationTreeUpdate: [ {annotationID: 123, status: "unknown"} ] }
         */
        resetAnnotation(hitID: string, annotation: QAAnnotation) {
            const annotationTreeUpdate = [
                {
                    annotationID: annotation.annotationID,
                    status: ANNOTATION_STATUS_UNKNOWN,
                },
            ]

            annotation.status = ANNOTATION_STATUS_UNKNOWN

            return $http
                .put<ReviewDataPartial>(
                    `/api/hit/qa/${hitID}`,
                    { annotationTreeUpdate },
                    NO_LOAD_OVERLAY
                )
                .then((res) => res.data)
        },

        /**
         * PUT /api/hit/qa/{hitID} { annotationTreeUpdate: [ {annotationID: 123, status: "rejected"} ] }
         */
        rejectAnnotation(hitID: string, annotation: QAAnnotation) {
            const annotationTreeUpdate = [
                {
                    annotationID: annotation.annotationID,
                    status: ANNOTATION_STATUS_REJECTED,
                },
            ]

            annotation.status = ANNOTATION_STATUS_REJECTED

            return $http
                .put<ReviewDataPartial>(
                    `/api/hit/qa/${hitID}`,
                    { annotationTreeUpdate },
                    NO_LOAD_OVERLAY
                )
                .then((res) => res.data)
        },

        /**
         * PUT /api/hit/qa/{hitID} { annotationTreeUpdate: [ {annotationID: 123, status: "approved"}, {annotationID: 321, status: "rejected"} ] }
         */
        toggleAnnotationStatus(
            hitID: string,
            approvedAnnotationID: number,
            annotationGroup: { annotations: QAAnnotation[] }
        ) {
            const annotationTreeUpdate: QAAnnotation[] = []

            _.forEach(annotationGroup.annotations, (a) => {
                a.status =
                    a.annotationID === approvedAnnotationID
                        ? ANNOTATION_STATUS_APPROVED
                        : ANNOTATION_STATUS_REJECTED
                annotationTreeUpdate.push(a)
            })

            return $http
                .put<ReviewDataPartial>(
                    `/api/hit/qa/${hitID}`,
                    { annotationTreeUpdate },
                    NO_LOAD_OVERLAY
                )
                .then((res) => res.data)
        },

        /**
         * Create a new annotation for a particular group
         */
        saveAnnotation(hitID: string, newAnnotation: NewAnnotation) {
            const annotation = _.extend({}, newAnnotation, {
                newAnnotation: true,
                status: ANNOTATION_STATUS_APPROVED,
                worker: _.pick(User.cached(), ANNOTATION_COPY_PROPS_USER),
            })

            const annotationTreeUpdate = [annotation]

            return $http
                .put<ReviewDataPartial>(
                    `/api/hit/qa/${hitID}`,
                    { annotationTreeUpdate },
                    NO_LOAD_OVERLAY
                )
                .then((res) => res.data)
        },

        /**
         * Create an annotation copy meant for editing
         */
        createAnnotationEditCopy: function (annotation: QAAnnotation): NewAnnotation {
            const annotationCopy = _.pick(angular.copy(annotation), ANNOTATION_COPY_PROPS)
            return _.assign(annotationCopy, {
                newAnnotation: true,
                worker: _.pick(User.cached(), ANNOTATION_COPY_PROPS_USER),
            })
        },

        duplicateAnnotation: (annotation: QAAnnotation, timestamp: number) => {
            const duplicate = _.pick(angular.copy(annotation), ['answers'])
            return _.assign(duplicate, {
                timestamp,
                newAnnotation: true,
                worker: _.pick(User.cached(), ANNOTATION_COPY_PROPS_USER),
            })
        },

        isPristine(annotation: QAAnnotation): boolean {
            return annotation.status === ANNOTATION_STATUS_UNKNOWN
        },

        isMature(annotation: QAAnnotation): boolean {
            return _.get(annotation, 'extra_data.mature', false)
        },
    }

    return QAAnnotation
}

export type QAAnnotationInstance = ReturnType<typeof QAAnnotationFactory>
