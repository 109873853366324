import ReduxActions from 'services/ReduxActions'
import type ngRedux from 'ng-redux'

/**
 * @ngdoc service
 * @name SceneActions
 * @module map3.scenes
 *
 * @description
 * A bound {@link SceneActionCreators}
 *
 * @see SceneActionCreators
 */
export default /* @ngInject */ function SceneActionsFactory(
    $ngRedux: ngRedux.INgRedux,
    SceneActionCreators: SceneActionCreatorsInstance
) {
    return ReduxActions.bindActionCreators(SceneActionCreators, $ngRedux.dispatch)
}

/**
 * @ngdoc service
 * @name SceneActionCreators
 * @module map3.scenes
 *
 * @description
 * Actions that can be performed on {@link SceneData#scenes SceneData#scenes}
 *
 * Any action sent here will update the {@link $ngRedux} state using the FLUX model
 */
export function SceneActionCreatorsFactory() {
    const createAction = ReduxActions.createAction
    const require = ReduxActions.require
    const defaults = ReduxActions.defaults

    const SceneActionCreators = {
        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#initWith
         * @description
         * Init the scene data, verifying that we have frame times for all scenes
         *
         * @param {Object} payload { videoApi, sceneData }
         */
        initWith: createAction('SCENE_DATA_INIT', require('videoApi', 'sceneData')),

        rawInitWith: createAction('SCENE_DATA_RAW_INIT'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#replaceWith
         * @description
         * Replace the current scenes and subScenes with the given
         *
         * @param {Array<Scene>} scenes
         */
        replaceWith: createAction('SCENE_DATA_REPLACE'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#splitByVideoCurrentTime
         * @description
         * Split scenes by videoApi.getCurrentTime()
         *
         * @param {videoAPI} payload VideoAPI instance
         */
        splitByVideoCurrentTime: createAction('SCENES_SPLIT_BY_VIDEO_CURRENT_TIME'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#selectById
         * @description
         * Select a scene by id
         *
         * @param {number} payload Scene id
         */
        selectById: createAction('SCENES_SELECT_BY_ID'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#selectByIndex
         * @description
         * Select a scene by index
         *
         * @param {number} payload Index
         */
        selectByIndex: createAction('SCENES_SELECT_BY_INDEX'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#selectNext
         * @description
         * Select the next scene
         *
         * @param {number=} payload Offset, default `+1`
         */
        selectNext: createAction('SCENES_SELECT_BY_OFFSET', defaults(1)),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#selectPrevious
         * @description
         * Select the previous scene
         *
         * @param {number=} payload Offset, default `-1`
         */
        selectPrevious: createAction('SCENES_SELECT_BY_OFFSET', defaults(-1)),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#mergeSelected
         * @description
         * Merge currently selected scenes {@link Scene#$$isSelected Scene.$$isSelected}
         * into a single scene
         */
        mergeSelected: createAction('SCENES_MERGE_SELECTED'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#moveStartFrame
         * @description
         * Move scene start by offset frames
         *
         * @param {Object} payload `{ id: number, offsetFrames: number }`
         */
        moveStartFrame: createAction('SCENES_MOVE_START', require('id', 'offsetFrames')),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#moveSelectedStartFrame
         * @description
         * Move single selected scene start by offset frames
         *
         * @param {Object} payload Offset frames
         */
        moveSelectedStartFrame: createAction('SCENES_MOVE_SELECTED_START'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#moveEndFrame
         * @description
         * Move scene end by offset frames
         *
         * @param {Object} payload `{ id: number, offsetFrames: number }`
         */
        moveEndFrame: createAction('SCENES_MOVE_END', require('id', 'offsetFrames')),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#moveSelectedEndFrame
         * @description
         * Move single selected scene end by offset frames
         *
         * @param {Object} payload Offset frames
         */
        moveSelectedEndFrame: createAction('SCENES_MOVE_SELECTED_END'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SceneActionCreators#toggleMarkSelectedAsNonCannon
         * @description
         * Toggle mark-as-non-cannon for the currently selected segments.
         * This action applies to both scenes and acts.
         */
        toggleMarkSelectedActsAsNonCannon: createAction('ACTS_TOGGLE_MARK_SELECTED_AS_NON_CANNON'),
        toggleMarkSelectedScenesAsNonCannon: createAction(
            'SCENES_TOGGLE_MARK_SELECTED_AS_NON_CANNON'
        ),
        toggleMarkSelectedSubScenesAsNonCannon: createAction(
            'SUB_SCENES_TOGGLE_MARK_SELECTED_AS_NON_CANNON'
        ),
    }

    return SceneActionCreators
}

type SceneActionCreatorsInstance = ReturnType<typeof SceneActionCreatorsFactory>
