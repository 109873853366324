import { ASSIGNMENT_STATUSES } from '../../constants'

type Issue = {
    issue_status: string
    issue_type: string
    missing_authority_request_id: string
}

type MISSING_AUTHORITY_ERROR_DATA_TYPE = Error & {
    issue: Issue
}

export default /* @ngInject */ function createMissingAuthorityHandlerFactory(
    $q: ng.IQService,
    MapDialog: any,
    InternalMessaging: any
) {
    return function createMissingAuthorityHandler(task: { issue: Issue }) {
        return function handleMissingAuthority(
            err: ng.IHttpResponse<MISSING_AUTHORITY_ERROR_DATA_TYPE>
        ) {
            if (err.status !== 422) {
                return $q.reject(err)
            }
            const { issue } = err.data
            if (!issue) {
                return $q.reject(err)
            }

            task.issue = issue

            const isBugReportedToAdmin =
                issue.issue_status === ASSIGNMENT_STATUSES.REPORTED_TO_ADMIN_STATUS
            const modalText = isBugReportedToAdmin
                ? 'Bug was already reported.'
                : 'If the completion of the task is critical you can manually report the issue.'
            const okBtnClass = isBugReportedToAdmin ? 'd-none' : 'btn-danger'
            const modalConfig = MapDialog.confirm()
                .title('No answers found during the retry')
                .htmlContent(
                    `
        <div class="alert alert-warning" role="alert">
            <p class="text-center mb-3 mt-4">
                MAP3 will retry the load of answers and report an issue if no answers are found in the next hours.
            </p>
            <p class="text-center mb-3"><strong>${modalText}</strong></p>
            <p class="text-center">In any case you will be notified if answers for the task become available.</p>
        </div>
    `
                )
                .ok('Report a Bug')
                .cancel('Close')
                .okClass(okBtnClass)

            MapDialog.show(modalConfig).then(function () {
                const { missing_authority_request_id: id } = issue
                task.issue.issue_status = ASSIGNMENT_STATUSES.REPORTED_TO_ADMIN_STATUS
                InternalMessaging.reportMissingAutoritis(id)
            })
        }
    }
}

export type CreateMissingAuthorityHandlerInstance = ReturnType<
    typeof createMissingAuthorityHandlerFactory
>
