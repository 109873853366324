import _ from 'lodash'
import fp from 'lodash/fp'
import { ActionCreators as ReduxUndoActionCreators } from 'redux-undo'
import { setSnowplowContext, trackButtonClick } from 'util/snowplow'

const commonResolve = {
    videoData: /* @ngInject */ (VideoService, hitID) => VideoService.get(hitID),
    commentThreads: /* @ngInject */ (CommentService, hitID) => CommentService.getThreads(hitID),
}

HighlightVideoCtrl.resolveWorker = {
    ...commonResolve,

    assignmentID: /* @ngInject */ ($stateParams) => $stateParams.assignmentID,
    hitID: /* @ngInject */ (task) => task.hitID,
    task: /* @ngInject */ (TaskService, assignmentID) => TaskService.getTask(assignmentID),

    userType: _.constant('worker'),

    BackendInterface: /* @ngInject */ (TaskService, $state) => {
        const WorkerBackendInterface = {
            setHighlights(assignmentID, highlights) {
                return TaskService.setHighlights(assignmentID, highlights)
            },
            submitHighlights(assignmentID, highlights) {
                return TaskService.submitHighlights(assignmentID, highlights)
            },
            setBookmarks(assignmentID, bookmarks) {
                TaskService.setBookmarks(assignmentID, bookmarks)
            },
            navigateToTaskList() {
                $state.go('worker.list')
            },
        }

        return WorkerBackendInterface
    },
}

HighlightVideoCtrl.resolveQA = {
    ...commonResolve,

    assignmentID: _.constant(null),
    hitID: /* @ngInject */ ($stateParams) => $stateParams.id,
    task: /* @ngInject */ (QAHighlightsService, hitID) => QAHighlightsService.getData(hitID),

    userType: _.constant('qa'),

    BackendInterface: /* @ngInject */ (
        QAHighlightsService,
        QAAssignmentService,
        QAReturnToWorkers,
        $state
    ) => {
        const QABackendInterface = {
            setHighlights(hitID, highlights) {
                return QAHighlightsService.setHighlights(hitID, highlights)
            },
            submitHighlights(hitID, highlights) {
                return QAHighlightsService.submitHighlights(hitID, highlights)
            },
            setBookmarks(hitID, bookmarks) {
                QAAssignmentService.setBookmarks(hitID, bookmarks)
            },
            navigateToTaskList() {
                $state.go('qa.list')
            },

            returnToWorkers(hitID, workers) {
                QAReturnToWorkers.return(hitID, workers)
            },
        }

        return QABackendInterface
    },
}

export default /* @ngInject */ function HighlightVideoCtrl(
    $scope,
    $ngRedux,
    HighlightActions,
    HighlightsStateActions,
    HighlightActionCreators,

    MapDialog,
    Notification,
    GlobalShortcuts,
    CommentInterface,
    MarkerTransformation,
    AnnotationValidation,
    DataEntryNetworkGuard,
    AnnotationSeeker,
    SharedVideoAPI,
    BackendInterface,
    EditAnnotation,

    userType,
    task,
    hitID,
    assignmentID,
    videoData,
    commentThreads
) {
    const vm = this

    vm.userType = userType

    vm.task = task
    vm.video = videoData

    vm.workers = userType === 'qa' ? task.workers : []

    vm.taskQuestions = task.questions
    vm.controlledVocabulary = task.controlled_vocabulary

    vm.lastSavedBackendHighlights = null

    vm.selectedHighlightId = null
    vm.selectedHighlight = null
    vm.selectedAnnotationGroup = null
    vm.newAnnotation = null

    vm.saveAnnotation = saveAnnotation
    vm.saveEditedAnnotation = saveEditedAnnotation
    vm.saveAnnotationAndClear = saveAnnotationAndClear
    vm.resetNewAnnotation = resetNewAnnotation
    vm.clearAnnotationForm = clearAnnotationForm
    vm.editAnnotation = editAnnotation
    vm.duplicateAnnotation = duplicateAnnotation
    vm.cancelAnnotationAdd = cancelAnnotationAdd
    vm.cancelEditing = cancelEditing
    vm.confirmAnnotationDelete = confirmAnnotationDelete
    vm.returnToWorkers = returnToWorkers

    vm.confirmSaveAndSubmit = confirmSaveAndSubmit

    activate()

    ///////////////////////////

    function activate() {
        resetNewAnnotation()

        $setupShortcuts()

        $setupComments()

        setSnowplowContext('task', task)

        $scope.$on('$destroy', function destroyHighlightVideoCtrl() {
            setSnowplowContext('task', null)
        })

        SharedVideoAPI.onLoad((videoApi) => {
            vm.videoApi = videoApi

            $setupHighlightData()

            $setupWatchFunctions()
            $setupFilmstrip()
        })
    }

    function $setupHighlightData() {
        // separate annotations from highlights
        const { highlights, annotationGroups } = HighlightsManager.separateBackendHighlights(
            vm.task.highlights
        )
        vm.annotationGroups = annotationGroups
        vm.highlights = highlights

        // clear redux (from previous tasks on same session)
        $ngRedux.dispatch(ReduxUndoActionCreators.clearHistory())

        // init redux highlights
        HighlightActions.init({ highlights, videoApi: vm.videoApi })

        // clear redux history (so that we cannot undo the init action)
        $ngRedux.dispatch(ReduxUndoActionCreators.clearHistory())

        /**
         * Here be dragons... or at least big lizards.
         *
         * We are using a very hacky solution to capture the redux merge action and merge
         * the corresponding annotationGroups. We have a reducer that simply writes the last
         * redux action to the global state, and we keep a copy of the last highlights on
         * every redux event. When we catch a merge action, this means that the current state
         * is post-merge and we must use lastHighlights to see what was actually merged (ie,
         * the highlights with `$$isSelected` prop).
         *
         * By necessity, this code has deep knowledge and depends on the inner state of highlighs
         * data, including the hidden `$$globalId` counter and the redux/undo history states.
         *
         * Changes to any of those will probably silently break this.
         */
        let lastHighlights
        const unsubscribeSubscribe = $ngRedux.subscribe(() => {
            const {
                lastReduxAction: { type: lastReduxActionType },
                highlightsData,
            } = $ngRedux.getState()
            const currentHighlights = highlightsData.undoableHighlights.present.highlights

            if (lastReduxActionType === HighlightActionCreators.mergeSelected.toString()) {
                const mergedIds = fp.flow(
                    fp.filter({ $$isSelected: true }),
                    fp.map('id')
                )(lastHighlights)

                HighlightsManager.mergeAnnotationGroups(
                    vm.annotationGroups,
                    mergedIds,
                    highlightsData.undoableHighlights.present.$$globalId
                )
            }

            lastHighlights = currentHighlights
        })

        // setup mapping of highlights to vm
        const unsubscribeConnect = $ngRedux.connect(mapStateToThis)(vm)
        function mapStateToThis({
            highlightsData: {
                undoableHighlights: {
                    present: { highlights },
                },
            },
        }) {
            const selected = _.filter(highlights, { $$isSelected: true })

            return {
                highlights,
                selectedHighlightId: selected.length === 1 ? selected[0].id : null,
            }
        }

        $scope.$on('$destroy', () => {
            unsubscribeSubscribe()
            unsubscribeConnect()
        })

        // handle highlight selection to set editing highlight
        $scope.$watchGroup(
            ['vm.selectedHighlightId', 'vm.highlights'],
            function ([id, highlights]) {
                const highlight = _.find(highlights, { id })

                // set editing highlight only if we have a valid non-empty non-provisional one
                if (!highlight || highlight.$$isProvisional) {
                    vm.selectedHighlight = null
                    vm.selectedAnnotationGroup = null

                    return
                }

                let annotationGroup = _.find(vm.annotationGroups, { id })
                if (!annotationGroup) {
                    annotationGroup = HighlightsManager.makeAnnotationGroup({ id })
                    vm.annotationGroups.push(annotationGroup)
                }

                vm.selectedHighlight = highlight
                vm.selectedAnnotationGroup = annotationGroup
            }
        )
    }

    /**
     * A very simple implementation of "do not re-save on load".
     */
    function $setupWatchFunctions() {
        let lastSavedBackendHighlights = angular.copy(
            HighlightsManager.combineIntoBackendHighlights(
                vm.highlights,
                vm.annotationGroups,
                vm.videoApi
            )
        )

        $scope.$watch(
            'vm.highlights',
            () => {
                saveIfNecessary()
                updateHighlightsState()
            },
            /* deep */ true
        )

        $scope.$watch(
            'vm.annotationGroups',
            () => {
                saveIfNecessary()
                updateHighlightsState()
            },
            /* deep */ true
        )

        function saveIfNecessary() {
            const backendHighlights = HighlightsManager.combineIntoBackendHighlights(
                vm.highlights,
                vm.annotationGroups,
                vm.videoApi
            )

            if (!angular.equals(lastSavedBackendHighlights, backendHighlights)) {
                lastSavedBackendHighlights = angular.copy(backendHighlights)
                executeSave(backendHighlights)
            }
        }

        function updateHighlightsState() {
            const highlightsState = _.map(vm.highlights, (highlight) => {
                const annotationGroup = _.find(vm.annotationGroups, { id: highlight.id })
                const highlightState = {
                    id: highlight.id,
                    isEmpty: HighlightsManager.isAnnotationGroupEmpty(annotationGroup),
                }

                return highlightState
            })

            HighlightsStateActions.update({ highlightsState })
        }
    }

    function $setupFilmstrip() {
        vm.videoApi.getFilmStripDrawer(() => {
            vm.highlightsFilmstrip = 'disabled'
        })
    }

    function $setupShortcuts() {
        const unbindShortcuts = GlobalShortcuts.bind({
            title: 'Tagging Shortcuts',
            shortcuts: [
                {
                    description: 'Next Annotation',
                    keyCombo: ['ctrl+shift+]'],
                    shortcut: 'control + shift+]',
                    callback: () => {
                        if (_.size(_.get(vm.selectedAnnotationGroup, 'tags'))) {
                            AnnotationSeeker.seekOffset(
                                vm.videoApi,
                                vm.selectedAnnotationGroup.tags,
                                1
                            )
                        }
                    },
                },
                {
                    description: 'Previous Annotation',
                    keyCombo: ['ctrl+shift+['],
                    shortcut: 'control + shift+[',
                    callback: () => {
                        if (_.size(_.get(vm.selectedAnnotationGroup, 'tags'))) {
                            AnnotationSeeker.seekOffset(
                                vm.videoApi,
                                vm.selectedAnnotationGroup.tags,
                                -1
                            )
                        }
                    },
                },
                {
                    description: 'Save Annotation',
                    global: true,
                    keyCombo: ['ctrl+s', 'meta+s'],
                    shortcut: 'control+S',
                    callback: saveAnnotationAndClear,
                },
                {
                    description: 'Save and Duplicate Annotation',
                    global: true,
                    keyCombo: ['ctrl+shift+s', 'meta+shift+s'],
                    shortcut: 'control+shift+S',
                    callback: () => {
                        saveAnnotation()
                        return false
                    },
                },
                {
                    description: 'Clear Selected Values',
                    keyCombo: ['esc'],
                    shortcut: 'Esc',
                    callback: cancelAnnotationAdd,
                },
                {
                    description: 'Read comment again',
                    keyCombo: ['alt+r'],
                    shortcut: 'Alt + R',
                    callback: () => CommentInterface.tryToOpenCommentFromUrlParams(),
                },
            ],
        })

        $scope.$on('$destroy', unbindShortcuts)
    }

    function $setupComments() {
        vm.commentThreads = commentThreads
        vm.bookmarks = task.bookmarks

        $scope.$watchCollection('vm.commentThreads', updateMarkersList)
        $scope.$watchCollection('vm.bookmarks', updateMarkersList)

        CommentInterface.init({
            hitID,
            threads: vm.commentThreads,
            assignmentID,
        })

        $scope.$onRootScope('map3.addBookmark', addBookmark)
        $scope.$onRootScope('map3.deleteBookmark', deleteBookmark)
        $scope.$on('$destroy', CommentInterface.destroy)
    }

    function addBookmark() {
        let timestamp = vm.videoApi.getCurrentFrameTime()
        vm.bookmarks.push(timestamp)

        BackendInterface.setBookmarks(assignmentID || hitID, vm.bookmarks)
    }

    function deleteBookmark(marker) {
        const index = vm.bookmarks.indexOf(marker.time)
        vm.bookmarks.splice(index, 1)

        BackendInterface.setBookmarks(assignmentID || hitID, vm.bookmarks)
    }

    const executeSave = DataEntryNetworkGuard.create((backendHighlights) => {
        if (!vm.videoApi) {
            return
        }

        const promise = BackendInterface.setHighlights(assignmentID || hitID, backendHighlights)

        Notification.forPromise(promise, 'Successfully saved highlights')
        return promise
    })

    function saveAnnotationAndClear() {
        if (saveAnnotation()) {
            resetNewAnnotation()
            clearAnnotationForm()
        }
    }

    function saveAnnotation() {
        if (!AnnotationValidation.isValid(vm.newAnnotation, vm.taskQuestions)) {
            return false
        }

        if (task.use_timestamp) {
            const validFrameTime = _.inRange(
                vm.videoApi.getCurrentFrame(),
                vm.selectedHighlight.startFrame,
                vm.selectedHighlight.endFrame
            )
            if (validFrameTime) {
                vm.newAnnotation.timestamp = vm.videoApi.getCurrentFrameTime()
            } else {
                Notification.error(`You must be within the highlight to add a tag to it.`)
                return false
            }
        }

        const newAnnotation = angular.copy(vm.newAnnotation)

        vm.selectedAnnotationGroup.tags.unshift(newAnnotation)

        return true
    }

    function resetNewAnnotation() {
        vm.newAnnotation = null
    }

    function cancelEditing(annotation) {
        EditAnnotation.cancelEdit(vm.selectedAnnotationGroup.tags, annotation)
    }

    function editAnnotation(annotation) {
        if (annotation.timestamp) {
            vm.videoApi.seek(annotation.timestamp)
        }

        EditAnnotation.createEditCopy(annotation)
    }

    function duplicateAnnotation(annotation) {
        EditAnnotation.duplicate(vm.selectedAnnotationGroup.tags, annotation)
    }

    function saveEditedAnnotation(annotation) {
        if (!vm.selectedHighlight) {
            Notification.error('You must select a highlight to edit')
            return false
        }

        if (!AnnotationValidation.isValid(annotation.$$editing, vm.taskQuestions)) {
            // generate an error
            return false
        }

        EditAnnotation.saveEdit(vm.selectedAnnotationGroup.tags, annotation)

        return true
    }

    function cancelAnnotationAdd() {
        clearAnnotationForm()
        resetNewAnnotation()
    }

    function clearAnnotationForm() {
        vm.annotationForm && vm.annotationForm.$setPristine()
    }

    function confirmAnnotationDelete(annotation) {
        const deleteConfig = MapDialog.confirm()
            .title('Delete annotation')
            .textContent('Are you sure you want to delete the annotation?')
            .ok('Yes')
            .cancel('No')

        MapDialog.show(deleteConfig).then(function () {
            deleteAnnotation(annotation)
        })
    }

    function deleteAnnotation(annotation) {
        const index = $getAnnotationIndex(annotation)
        if (index > -1) {
            vm.selectedAnnotationGroup.tags.splice(index, 1)
        }
    }

    function checkForOpenedEditAnnotations() {
        const highlightIds = _.map(vm.highlights, (highlight) => highlight.id)
        const activeAnnotationGroups = _.filter(vm.annotationGroups, (group) =>
            _.includes(highlightIds, group.id)
        )
        let openedEdits = []

        _.forEach(activeAnnotationGroups, (group) => {
            if (_.some(group.tags, '$$editing')) {
                openedEdits.push({
                    groupId: group.id,
                    timestamp: _.find(group.tags, '$$editing').timestamp,
                })
            }
        })

        return openedEdits
    }

    function confirmSaveAndSubmit() {
        let openedEdits = checkForOpenedEditAnnotations()
        if (openedEdits.length) {
            const firstOpenedEdit = _.head(openedEdits)
            const highlightId = firstOpenedEdit.groupId

            HighlightActions.selectById({ id: highlightId })
            vm.videoApi.seek(firstOpenedEdit.timestamp)

            Notification.error(`You haven't finished editing an annotation.`)
            return false
        }

        const backendHighlights = HighlightsManager.combineIntoBackendHighlights(
            vm.highlights,
            vm.annotationGroups,
            vm.videoApi
        )

        const confirmDialog = MapDialog.confirm()
            .title(`Submit Cut & Tag Data`)
            .htmlContent(
                `
                <strong>Are you sure you want to proceed?</strong>
                <p>
                    When you submit your changes will be stored and you won't be able to edit them anymore.
                </p>

                ${
                    _.isEmpty(backendHighlights)
                        ? `
                    <div class="alert alert-warning" role="alert">
                        You have no highlight data. Are you sure you want to proceed?
                    </div>
                    `
                        : ''
                }
            `
            )
            .ok('Yes')
            .cancel('No')

        const promise = MapDialog.show(confirmDialog)
            .then(function () {
                // we want to have a secondary confirmation for empty task submission
                if (_.isEmpty(backendHighlights)) {
                    return MapDialog.confirm()
                        .title('Are you sure you want to submit an empty task?')
                        .htmlContent(
                            `
                        <div class="alert alert-danger">
                            <strong>You are about to submit an empty task</strong>, with no segments and no data.
                            <br />
                            <br />
                            Are you really sure you want to submit an empty task?
                        </div>
                    `
                        )
                        .ok('Yes')
                        .cancel('No')
                        .show()
                }

                // otherwise, just jump to the next in the promise chain
                return true
            })
            .then(function () {
                trackButtonClick({
                    label: 'Submit Highlights',
                    value: [
                        {
                            metadata: {
                                hitId: assignmentID || hitID, // following suit with the rest of the code
                            },
                        },
                    ],
                })

                return BackendInterface.submitHighlights(assignmentID || hitID, backendHighlights)
            })
            .then(function () {
                BackendInterface.navigateToTaskList()
            })

        Notification.forPromise(promise)
    }

    function $getAnnotationIndex(annotation) {
        return _.indexOf(_.get(vm.selectedAnnotationGroup, 'tags', []), annotation)
    }

    function updateMarkersList() {
        vm.markersList = _.concat(
            MarkerTransformation.threads(vm.commentThreads, CommentInterface.markerClick),
            MarkerTransformation.bookmarks(vm.bookmarks)
        )
    }
    function returnToWorkers() {
        return BackendInterface.returnToWorkers(hitID, vm.workers)
    }
}

const HighlightsManager = {
    // KEEP IN SYNC WITH HIGHLIGHT_ANNOTATION_FIELDS !!!
    DEFAULT_DATA: {
        tags: [],
        song_tags: [],
        labels_free: [],
        labels_controlled: [],
    },

    // KEEP IN SYNC WITH DEFAULT_DATA !!!
    HIGHLIGHT_ANNOTATION_FIELDS: ['tags', 'song_tags', 'labels_free', 'labels_controlled'],

    MERGE_UNIQUENESS: {
        song_tags: { type: 'key', key: 'id' },
        labels_controlled: { type: 'key', key: 'value' },
        labels_free: { type: 'identity' },
    },

    separateBackendHighlights(backendHighlights) {
        const highlights = []
        const annotationGroups = []

        _.forEach(backendHighlights, (backendHighlight) => {
            annotationGroups.push(HighlightsManager.makeAnnotationGroup(backendHighlight))

            highlights.push(_.omit(backendHighlight, HighlightsManager.HIGHLIGHT_ANNOTATION_FIELDS))
        })

        return { highlights, annotationGroups }
    },

    makeAnnotationGroup(highlight = {}) {
        return {
            id: highlight.id,
            // start empty
            ...angular.copy(HighlightsManager.DEFAULT_DATA),
            // and add matching data keys from the highlight
            ..._.pick(highlight, _.keys(HighlightsManager.DEFAULT_DATA)),
        }
    },

    combineIntoBackendHighlights(highlights, annotationGroups, videoApi) {
        const backendHighlights = _.map(highlights, (highlight) => {
            return {
                ...highlight,
                start: videoApi.convertFrameToSeconds(highlight.startFrame),
                end: videoApi.convertFrameToSeconds(highlight.endFrame),

                // default empty annotation props
                ...angular.copy(HighlightsManager.DEFAULT_DATA),

                // overwritten by actual annotation props if we have them
                ..._.find(annotationGroups, { id: highlight.id }),
            }
        })

        return _.reject(backendHighlights, { $$isProvisional: true })
    },

    mergeAnnotationGroups(annotationGroups, mergedIds, mergeGroupId) {
        // try to find an existing group with the merge id (possible with undo/redo shenanigans)
        let mergeAnnotationGroup = _.find(annotationGroups, { id: mergeGroupId })
        if (!mergeAnnotationGroup) {
            // if none exists, create a new group and push it to the annotation groups
            mergeAnnotationGroup = {
                id: mergeGroupId,
            }
            annotationGroups.push(mergeAnnotationGroup)
        }

        // zero out the merge annotation group data
        _.assign(mergeAnnotationGroup, angular.copy(HighlightsManager.DEFAULT_DATA))

        // and add the data from the matching to-merge groups
        _.forEach(
            _.filter(annotationGroups, (group) => _.includes(mergedIds, group.id)),
            (annotationGroup) => {
                _.forEach(_.keys(HighlightsManager.DEFAULT_DATA), (prop) => {
                    mergeAnnotationGroup[prop].push(...annotationGroup[prop])
                })
            }
        )

        // and finally, insure data uniqueness is preserved
        _.forEach(HighlightsManager.MERGE_UNIQUENESS, (uniqSettings, prop) => {
            if (uniqSettings.type === 'identity') {
                mergeAnnotationGroup[prop] = _.uniq(mergeAnnotationGroup[prop])
            }
            if (uniqSettings.type === 'key') {
                mergeAnnotationGroup[prop] = _.uniqBy(mergeAnnotationGroup[prop], uniqSettings.key)
            }
        })
    },

    isAnnotationGroupEmpty(annotationGroup) {
        if (!annotationGroup) {
            return true
        }
        return _.every(_.keys(HighlightsManager.DEFAULT_DATA), (prop) => {
            return _.isEmpty(annotationGroup[prop])
        })
    },
}
