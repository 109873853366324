import _ from 'lodash'
import invariant from 'util/invariant'

export default class GraphObject {
    constructor(graph) {
        // convenience wrapper for a raw graph object {nodes, edges, $$globalNodeId}
        // where we attach all static methods of GraphObject directly on it
        GraphObject.graphObjectFns.forEach((fn) => {
            graph[fn] = function () {
                // we call the function as if it were called with a raw graph
                const result = GraphObject[fn](GraphObject.extractRawGraph(this), ...arguments)
                // and check if the result is a graph
                if (GraphObject.isGraph(result)) {
                    // if yes, then re re-wrap
                    return new GraphObject(result)
                } else {
                    // otherwise, we return the result as is
                    return result
                }
            }
        })

        return GraphObject.init(graph)
    }

    static init(graph) {
        return angular.extend(
            {
                nodes: [],
                edges: [],
                $$globalNodeId: _.max(_.map(graph.nodes, 'id')) || 0,
            },
            graph
        )
    }

    static isGraph(object) {
        return _.has(object, 'nodes') && _.has(object, 'edges')
    }

    static extractRawGraph(object) {
        return _.pick(object, ['nodes', 'edges', '$$globalNodeId'])
    }

    static isGraphObject(object) {
        return (
            object &&
            _.every(GraphObject.graphObjectFns, (prop) => {
                return !!object[prop]
            })
        )
    }

    static cancelEditing(graph) {
        const provisionalNode = GraphObject.nodeFindProvisional(graph)
        if (provisionalNode) {
            return GraphObject.nodeRemove(graph, provisionalNode)
        }

        const provisionalEdge = GraphObject.edgeFindProvisional(graph)
        if (provisionalEdge) {
            return GraphObject.edgeRemove(graph, provisionalEdge)
        }

        return graph
    }

    static nodeSelectToggle(graph, node) {
        node = GraphObject.nodeFind(graph, node)
        const newNode = angular.extend(angular.copy(node), {
            $$isSelected: !node.$$isSelected,
        })

        const restNodes = _.without(graph.nodes, node).map((node) => {
            return angular.extend(angular.copy(node), {
                $$isSelected: false,
            })
        })

        // selecting a node deselects edges
        graph = GraphObject.edgeDeselectAll(graph)
        return angular.extend(angular.copy(graph), {
            nodes: restNodes.concat(newNode),
        })
    }

    static nodeDeselectAll(graph) {
        return angular.extend(angular.copy(graph), {
            nodes: _.map(graph.nodes, (node) => {
                return angular.extend(angular.copy(node), {
                    $$isSelected: false,
                })
            }),
        })
    }

    static nodeCoordsSet(graph, node, coords, offsetX, offsetY) {
        offsetX = offsetX || 0
        offsetY = angular.isUndefined(offsetY) ? offsetX : offsetY

        node = GraphObject.nodeFind(graph, node)
        const newNode = angular.extend(angular.copy(node), {
            x: coords[0] + offsetX,
            y: coords[1] + offsetY,
        })

        return GraphObject.nodeReplace(graph, node, newNode)
    }

    static nodeRemove(graph, node) {
        node = GraphObject.nodeFind(graph, node)

        return angular.extend(angular.copy(graph), {
            nodes: _.without(graph.nodes, node),
            edges: _.without(graph.edges, ...GraphObject.edgeFindAllForNode(graph, node)),
        })
    }

    static nodeAdd(graph, node) {
        return angular.extend(angular.copy(graph), {
            nodes: graph.nodes.concat(node),
        })
    }

    static nodeReplace(graph, node, newNode) {
        return angular.extend(angular.copy(graph), {
            nodes: replaceItem(graph.nodes, node, newNode),
        })
    }

    static nodeCreateProvisional(graph, node) {
        const $$globalNodeId = graph.$$globalNodeId + 1
        const provisionalNode = angular.extend(
            {
                id: $$globalNodeId,
                x: -10e10,
                y: -10e10,
                $$isProvisional: true,
            },
            node
        )

        return angular.extend(angular.copy(graph), {
            $$globalNodeId,
            nodes: graph.nodes.concat(provisionalNode),
        })
    }

    static nodeCompleteProvisional(graph) {
        const provisionalNode = GraphObject.nodeFindProvisional(graph)
        if (!provisionalNode) {
            return graph
        }

        return GraphObject.nodeReplace(graph, provisionalNode, {
            ...provisionalNode,
            $$isProvisional: false,
        })
    }

    static nodeFindSelected(graph) {
        return GraphObject.nodeFind(graph, { $$isSelected: true })
    }

    static nodeFindProvisional(graph) {
        return _.find(graph.nodes, { $$isProvisional: true })
    }

    static nodeFind(graph, node) {
        if (_.isObject(node)) {
            if (_.has(node, 'id')) {
                return _.find(graph.nodes, { id: node.id })
            } else {
                return _.find(graph.nodes, node)
            }
        } else {
            return _.find(graph.nodes, { id: node })
        }
    }

    static nodeFindAll(graph, predicate) {
        return _.filter(graph.nodes, predicate)
    }

    static nodeFindConnected(graph, node, ioType, io) {
        const ioIdx = node.info[ioType === 'input' ? 'inputs' : 'outputs'].findIndex((entry) =>
            _.isString(entry) ? entry === io : entry.value === io
        )
        if (ioIdx < 0) {
            return []
        }

        let matchObj
        if (ioType === 'input') {
            matchObj = {
                target: {
                    nodeId: node.id,
                    inId: ioIdx,
                },
            }
        } else {
            matchObj = {
                source: {
                    nodeId: node.id,
                    outId: ioIdx,
                },
            }
        }

        const edges = _.filter(graph.edges, matchObj)
        const nodes = _.map(edges, (edge) => {
            if (ioType === 'input') {
                return GraphObject.nodeFind(graph, edge.source.nodeId)
            } else {
                return GraphObject.nodeFind(graph, edge.target.nodeId)
            }
        })

        // filter empty nodes
        return _.filter(nodes, (node) => !!node)
    }

    static edgeFindSelected(graph) {
        return _.find(graph.edges, { $$isSelected: true })
    }

    static edgeFindProvisional(graph) {
        return _.find(graph.edges, { $$isProvisional: true })
    }

    static edgeFindAllForNode(graph, node) {
        return _.filter(graph.edges, (edge) => {
            return (
                _.get(edge, 'source.nodeId') === node.id || _.get(edge, 'target.nodeId') === node.id
            )
        })
    }

    static edgeRemove(graph, edge) {
        return angular.extend(angular.copy(graph), {
            edges: _.without(graph.edges, edge),
        })
    }

    static edgeCoordsSet(graph, edge, coords, offsetX, offsetY) {
        offsetX = offsetX || 0
        offsetY = angular.isUndefined(offsetY) ? offsetX : offsetY

        const newEdge = angular.extend(angular.copy(edge), {
            $$danglingX: coords[0] + offsetX,
            $$danglingY: coords[1] + offsetY,
        })

        return GraphObject.edgeReplace(graph, edge, newEdge)
    }

    static edgeSelectToggle(graph, edge) {
        const newEdge = angular.extend(angular.copy(edge), {
            $$isSelected: !edge.$$isSelected,
        })

        const restEdges = _.without(graph.edges, edge).map((edge) => {
            return angular.extend(angular.copy(edge), {
                $$isSelected: false,
            })
        })

        // selecting an edge deselects nodes
        graph = GraphObject.nodeDeselectAll(graph)
        return angular.extend(angular.copy(graph), {
            edges: restEdges.concat(newEdge),
        })
    }

    static edgeDeselectAll(graph) {
        return angular.extend(angular.copy(graph), {
            edges: _.map(graph.edges, (edge) => {
                return angular.extend(angular.copy(edge), {
                    $$isSelected: false,
                })
            }),
        })
    }

    static edgeReplace(graph, edge, newEdge) {
        return angular.extend(angular.copy(graph), {
            edges: replaceItem(graph.edges, edge, newEdge),
        })
    }

    static edgeAdd(graph, io) {
        const provisionalEdge = {
            source: io.type === 'output' ? { nodeId: io.nodeId, outId: io.outId } : null,
            target: io.type === 'input' ? { nodeId: io.nodeId, inId: io.inId } : null,
            $$isProvisional: true,
            $$danglingX: false,
            $$danglingY: false,
        }

        return angular.extend(angular.copy(graph), {
            edges: graph.edges.concat(provisionalEdge),
        })
    }

    static edgeCreateOrUpdateProvisional(graph, io) {
        const provisionalEdge = GraphObject.edgeFindProvisional(graph)
        if (!provisionalEdge) {
            return GraphObject.edgeAdd(graph, io)
        }

        // if invalid to connect the two IOs, remove provisional
        if (isInvalidConnection(graph, provisionalEdge, io)) {
            return GraphObject.edgeRemove(graph, provisionalEdge)
        }

        let newEdge
        if (io.type === 'output') {
            newEdge = angular.extend(angular.copy(provisionalEdge), {
                $$isProvisional: false,
                source: { nodeId: io.nodeId, outId: io.outId },
            })
        } else {
            newEdge = angular.extend(angular.copy(provisionalEdge), {
                $$isProvisional: false,
                target: { nodeId: io.nodeId, inId: io.inId },
            })
        }

        // invalid if the same connection already exists on the graph
        const alreadyExists = !!_.find(graph.edges, {
            source: newEdge.source,
            target: newEdge.target,
        })
        if (alreadyExists) {
            return GraphObject.edgeRemove(graph, provisionalEdge)
        }

        // finally, valid, replace the provisional with the new edge
        return GraphObject.edgeReplace(graph, provisionalEdge, newEdge)

        /////////////////////////////

        function isInvalidConnection(graph, provisionalEdge, io) {
            // invalid if we try to connect an edge to the node where it starts
            if (
                io.nodeId === _.get(provisionalEdge, 'source.nodeId') ||
                io.nodeId === _.get(provisionalEdge, 'target.nodeId')
            ) {
                return true
            }

            // invalid if we try to connect an input to input or output to output
            if (
                (io.type === 'output' && provisionalEdge.source !== null) ||
                (io.type === 'input' && provisionalEdge.target !== null)
            ) {
                return true
            }

            // invalid if we try to connect to a different io value type
            const valueForTarget = GraphObject.edgeGetIoValue(
                graph,
                provisionalEdge,
                io.type === 'input' ? 'output' : 'input'
            )
            if (io.value !== valueForTarget) {
                return true
            }

            return false
        }
    }

    static edgeGetIoValue(graph, edge, type) {
        let targetEdge
        if (type === 'output') {
            const node = GraphObject.nodeFind(graph, _.get(edge, 'source.nodeId'))
            targetEdge = _.get(node, ['info', 'outputs', _.get(edge, 'source.outId')])
        } else {
            const node = GraphObject.nodeFind(graph, _.get(edge, 'target.nodeId'))
            targetEdge = _.get(node, ['info', 'inputs', _.get(edge, 'target.inId')])
        }

        // We used to have a simple array with values: string[]
        // Now we have something more complicated: {value: string, label: string}[]
        // We need to account for both, because of backwards compatibility
        return _.get(targetEdge, 'value', targetEdge) // targetEdge as value
    }
}

function replaceItem(array, item, newItem) {
    const idx = array.indexOf(item)
    if (idx === -1) {
        // invariant inside if, because otherwise angular.toJson() executes and is super slow
        invariant(
            false,
            '[GraphObject] Cannot replace non-item "%s" inside "%s"',
            angular.toJson(_.omit(item, ['info'])),
            angular.toJson(_.map(array, (item) => _.omit(item, ['info'])))
        )
    }
    array = array.slice() // copy the array object
    array.splice(idx, 1, newItem)
    return array
}

GraphObject.graphObjectFns = Object.getOwnPropertyNames(GraphObject).filter((name) =>
    _.isFunction(GraphObject[name])
)
