/**
 * @ngdoc directive
 * @name tooltip
 * @module map3.core
 * @restrict A
 *
 * @description
 * Show a tooltip on hover
 *
 * @param {string} tooltip The tooltip to display
 */
export default /* @ngInject */ function tooltipDirective() {
    let directive = {
        restrict: 'A',
        link: tooltipLinkFn,
    }

    return directive

    //////////////////////////////////////////////

    function tooltipLinkFn(scope, element) {
        angular.element(element).tooltip()
        scope.$on('$destroy', () => {
            angular.element(element).tooltip('dispose')
        })
    }
}
