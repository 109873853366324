import _ from 'lodash'

export default /* @ngInject */ function tagValueDirective() {
    const directive = {
        restrict: 'AE',
        scope: {
            classSingle: '@?',
            classMulti: '@?',
        },
        template: `
            <span ng-repeat-start="tagValue in ::$ctrl.tagValues"
                ng-class="::$ctrl.computeClass(tagValue)"
                ng-attr-title="{{ ::$ctrl.computeTitle(tagValue) }}"
                ng-bind="::tagValue.label"
            ></span>
            <span ng-if="::tagValue.validForTagging === false && tagValue.preferredTerm.length"
                ng-repeat-end
            >
                [
                    <span ng-repeat="term in ::tagValue.preferredTerm">
                        {{ ::term.label + ($last ? '' : ', ') }}
                    </span>
                ]
            </span>
        `,
        controller: tagValueCtrl,
        controllerAs: '$ctrl',
    }

    return directive
}

/* @ngInject */
function tagValueCtrl($scope, $attrs) {
    const $ctrl = this

    $ctrl.computeClass = computeClass
    $ctrl.computeTitle = computeTitle

    if (!getTag()) {
        $ctrl.unsubscribeTagWatcher = $scope.$watch(getTag, computeData)
    } else {
        computeData()
    }

    return $ctrl

    //////////////////////

    function computeData() {
        if (!getTag()) {
            return
        } else {
            $ctrl.unsubscribeTagWatcher && $ctrl.unsubscribeTagWatcher()
        }

        $ctrl.questionValues = _.flatMap(getQuestions(), (question) => question.values)

        $ctrl.baseTagValue = _.get(getTag(), 'value')

        $ctrl.isMulti = _.isArray($ctrl.baseTagValue)

        $ctrl.tagValues = _.map(
            $ctrl.isMulti ? $ctrl.baseTagValue : [$ctrl.baseTagValue],
            normalizeValue
        ).filter((tagValue) => !!tagValue)
    }

    function getTag() {
        if (!$ctrl.tag) {
            $ctrl.tag = $scope.$parent.$eval($attrs.tagValue)
        }

        return $ctrl.tag
    }

    function getQuestions() {
        if (!$ctrl.questions) {
            $ctrl.questions = $scope.$parent.$eval($attrs.questions)
        }

        return $ctrl.questions
    }

    function computeClass(tagValue) {
        let classes = $ctrl.isMulti
            ? ($scope.classMulti || '').split(/\s+/)
            : ($scope.classSingle || '').split(/\s+/)

        return classes.concat(tagValue.validForTagging === false ? 'invalid-tagging-term' : '')
    }

    function computeTitle(tagValue) {
        return false === _.get(tagValue, 'validForTagging', true)
            ? 'This tag is no longer valid and should not be used for tagging.'
            : ''
    }

    function normalizeValue(tagValue) {
        if (!tagValue) {
            return null
        }

        const questionValue = _.find($ctrl.questionValues, { value: tagValue.value })
        return questionValue || tagValue
    }
}
