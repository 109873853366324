import { IPromise } from 'angular'
import _ from 'lodash'
import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'
import { ErrorReplacementId } from 'constants.es6'

interface INotification {
    text: string
    type: string
    replacementId?: ErrorReplacementId
    timeoutPromise?: IPromise<void>
}

type ScopeBindings = {
    closeNotificationMessage: (notification: INotification) => void
    getAlertClass: (notification: INotification) => string
    notifications: INotification[]
}

export default /* @ngInject */ function notificationMessageDirective(
    $rootScope: ng.IRootScopeService,
    $timeout: ng.ITimeoutService,
    ErrorStringifier: ErrorStringifierInstance
) {
    const NOTIFICATION_TIMEOUT = 5 * 1000
    const NOTIFICATION_TYPE_TO_CLASS = {
        success: 'alert-success',
        warning: 'alert-warning',
        error: 'alert-danger',
    }

    const directive = {
        restrict: 'E',
        scope: true,
        template: `
                <div ng-repeat="notification in notifications"
                    class="notification"
                    uib-alert
                    ng-class="getAlertClass(notification)"
                    close="closeNotificationMessage(notification)"
                >
                    <strong idle-countdown="countdown" ng-bind-html="notification.text"></strong>
                </div>
        `,
        link: notificationMessageLinkFn,
    }

    return directive

    ////////////////////////////////

    function notificationMessageLinkFn(scope: ng.IScope & ScopeBindings) {
        scope.closeNotificationMessage = closeNotificationMessage
        scope.getAlertClass = getAlertClass
        scope.notifications = []

        const unsubscribeNotification = $rootScope.$on(
            'notification',
            (event, { type, message, replacementId }) => {
                const text = _.isString(message)
                    ? message
                    : ErrorStringifier.stringify(message) || ''
                const activeNotification = _.find(scope.notifications, { text })

                if (activeNotification) {
                    clearNotificationTimeout(activeNotification)
                    setNotificationTimeout(activeNotification)
                } else {
                    const notification = { text, type, replacementId }

                    if (_.isNumber(replacementId)) {
                        const activeNotification = _.find(scope.notifications, {
                            replacementId,
                        })
                        if (activeNotification) reset(activeNotification)
                    }

                    scope.notifications.push(notification)
                    setNotificationTimeout(notification)
                }
            }
        )

        scope.$on('$destroy', () => {
            unsubscribeNotification()
        })

        //////////////////////////////////////////////////

        function getAlertClass(notification: INotification) {
            return _.get(NOTIFICATION_TYPE_TO_CLASS, notification.type, 'alert-warning')
        }

        function setNotificationTimeout(notification: INotification) {
            const hideTimeout =
                notification.type === 'error' ? 3 * NOTIFICATION_TIMEOUT : NOTIFICATION_TIMEOUT

            notification.timeoutPromise = $timeout(() => reset(notification), hideTimeout)
        }

        function closeNotificationMessage(notification: INotification) {
            $timeout(() => reset(notification), 0)
        }

        function clearNotificationTimeout(notification: INotification) {
            if (notification.timeoutPromise) {
                $timeout.cancel(notification.timeoutPromise)
            }
        }

        function reset(notification: INotification) {
            clearNotificationTimeout(notification)

            _.remove(scope.notifications, notification)
        }
    }
}
