import { GlobalShortcutsInstance, ShortcutsGroup } from './GlobalShortcuts.factory'

type ScopeVariables = {
    showHelpModal: () => void
    showHelpButton?: boolean
}

export default /* @ngInject */ function GlobalShortcutsDirective(
    GlobalShortcuts: GlobalShortcutsInstance,
    $uibModal: ng.ui.bootstrap.IModalService
): ng.IDirective<ng.IScope & ScopeVariables> {
    const directive = {
        restrict: 'E',
        template: `
            <a href ng-show="showHelpButton" ng-click="showHelpModal($event)"
                class="nav-item nav-link no-decoration d-flex align-items-center overflow-hidden"
                style="height:20px"
            >
                <i class="material-icons md-24">help</i>
            </a>`,
        link: globalShortcutsLinkFn,
    }

    return directive

    ///////////////////////

    function globalShortcutsLinkFn(scope: ng.IScope & ScopeVariables) {
        scope.showHelpModal = showHelpModal

        scope.$watch(
            () => GlobalShortcuts.isEmpty(),
            (isEmpty) => (scope.showHelpButton = !isEmpty)
        )
        scope.$onRootScope('globalShortcuts.showHelp', showHelpModal)

        function showHelpModal() {
            $uibModal.open({
                templateUrl: 'js/global-shortcuts/help-modal.tpl.html',
                controllerAs: 'helpVm',
                size: 'lg',
                controller: /* @ngInject */ function () {
                    const helpVm: { shortcutsList: ShortcutsGroup[] } = this as any

                    helpVm.shortcutsList = GlobalShortcuts.getShortcutsList()
                },
            })
        }
    }
}
