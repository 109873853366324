/**
 * @ngdoc directive
 * @name onReload
 * @module map3.core
 * @restrict A
 * @element iframe
 * @scope
 *
 * @description
 * This directive detects when a second load (ie, a reload or navigation) triggers on
 * an `<iframe>`.
 *
 * This can be used to infer that after the iframe has loaded, the user performed an
 * action like navigation or form submission that triggered a reload
 *
 * ### This directive works only on `<iframe>` elements!
 *
 * Example:
 *
 * ```html
 * <iframe ng-src="{{ someUrl }}" on-reload="ctrl.executeActionOnIframeReload()"></iframe>
 * ```
 *
 * @param {expression} onReload The {@link expression} to execute when the iframe reloads
 *                     or navigates
 */
export default /* @ngInject */ function onReloadDirective() {
    var directive = {
        restrict: 'A',
        scope: {
            onReload: '&onReload',
        },
        link: onReloadLinkFn,
    }

    return directive

    /////////////////////

    function onReloadLinkFn(scope, element) {
        if (element[0].tagName !== 'IFRAME') {
            throw new Error('onReload directive is available only for <iframe> elements')
        }

        var isFirstLoad = true

        element.on('load', function () {
            if (!isFirstLoad) {
                scope.onReload()
            } else {
                isFirstLoad = false
            }
        })
    }
}
