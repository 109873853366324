import _ from 'lodash'
import { TimestampTransform } from 'services/TimestampTransform.factory'
import { getSecondsFormatter } from 'services/utils'

/**
 * TimelabelsDrawer takes a TimelabelsCalculator and a canvas and renders timelables
 * for generic-timeline component
 */
export default class TimelabelsDrawer {
    /**
     * Timelabels render defaults
     */
    static RENDER_SETTINGS_DEFAULT = {
        height: 30,

        // context draw settings
        fillStyle: 'gray',
        strokeStyle: 'darkgray',
        font:
            '300 0.75rem ' +
            [
                // bootstrap native font stack
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
            ].join(', '),
        textAlign: 'center',
        textBaseline: 'middle',

        // positioning settings
        textY: 6,
        textOffset: 3, // we offset text to the right because we want to center
        // text on the second colon -> 00:00[:]00:000
        notchBigY1: 12,
        notchBigY2: 40,
        notchSmallY1: 24,
        notchSmallY2: 40,
    }

    /**
     * Timelabels render settings for condensed rendering
     */
    static RENDER_SETTINGS_CONDENSED = {
        ...TimelabelsDrawer.RENDER_SETTINGS_DEFAULT,
        textY: 5,
    }

    /**
     * TimelabelsDrawer constructor
     *
     * @param {TimelabelsCalculator} timelabelsCalculator
     * @param {DOMNode} canvas
     * @param {object=} opts Drawer options
     *  - `renderStyle`: One of ['default', 'condensed']
     *  - `canvasMaxWidth` Max canvas width that can be rendered
     */
    constructor(timelabelsCalculator, canvas, opts = {}) {
        this.timelabelsCalculator = timelabelsCalculator
        this.canvas = canvas
        this.ctx = canvas.getContext('2d')
        this.timelabelsTransform = new TimestampTransform()

        this.canvasMaxWidth = _.get(opts, 'canvasMaxWidth', 5000)
        this.renderStyle = _.get(opts, 'renderStyle', 'default')
        this.videoApi = opts.videoApi || null

        this.width = null
        this.left = null
    }

    /**
     * (Re)draw the timelabels canvas
     *
     * This function should be called in a fastdom.mutate() cycle!
     *
     * @param {object} drawParams
     *  - `totalDuration`: Total video duration
     *  - `totalWidth`: Total timeline width after zoom
     *  - `startSec`: Visible starting second of current timeline position
     *  - `endSec`: Visible ending second of current timeline position
     *  - `left`: Timeline scroll left, or more explicitly, the left position where the canvas will be drawn
     *  - `width`: Visible timeline width
     *  - `singleFrameWidth`: The width of a single frame, in pixels. Used for frame-accurate rendering
     */
    draw({
        totalDuration,
        totalWidth,
        startSec,
        endSec,
        left,
        width,
        singleFrameWidth,
        timeFormat,
    }) {
        const timelabels = this.timelabelsCalculator.timelables(
            totalDuration,
            totalWidth,
            startSec,
            endSec + 1,
            singleFrameWidth
        )

        // we cannot render less than 2 timelabels
        if (timelabels.length < 2) {
            return
        }

        const renderSettings =
            this.renderStyle === 'condensed'
                ? TimelabelsDrawer.RENDER_SETTINGS_CONDENSED
                : TimelabelsDrawer.RENDER_SETTINGS_DEFAULT

        // width is 2 times the visible width, clamped to canvasMaxWidth
        width = Math.min(width * 2, this.canvasMaxWidth)
        // we only set the width if it has changed from last draw, because
        // it's an expensive operation that causes reflow
        if (width !== this.width) {
            this.canvas.height = renderSettings.height
            this.canvas.width = width
            this.width = width

            // Because changing width resets canvas styles, we need to set those here.
            // This will always be set on the first time the draw runs
            this.ctx.fillStyle = renderSettings.fillStyle
            this.ctx.strokeStyle = renderSettings.strokeStyle
            this.ctx.font = renderSettings.font
            this.ctx.textAlign = renderSettings.textAlign
            this.ctx.textBaseline = renderSettings.textBaseline
        }

        // same with canvas left position, only change if we need to
        if (left !== this.left) {
            this.canvas.style.transform = `translate3d(${left}px, 0px, 0px)`
            this.left = left
        }

        // clear the canvas from previous render
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        const timelabelWidth = timelabels[1].x - timelabels[0].x

        let index = -1

        let timeConverter = (time) => this.timelabelsTransform.timestampWithMilli(time)

        if (timeFormat && this.videoApi) {
            timeConverter = getSecondsFormatter(timeFormat, this.videoApi)
        }

        while (++index < timelabels.length) {
            const { x, time, divisors, label } = timelabels[index]

            // do not execute draw operations for out of bounds content
            if (x + timelabelWidth < left) {
                continue
            }
            if (x - timelabelWidth > left + width) {
                continue
            }

            let timeString = label || timeConverter(time)

            // write the timestamp text
            this.ctx.fillText(
                timeString,
                Math.round(x - left) + renderSettings.textOffset,
                renderSettings.textY
            )

            // draw the timestamp notch
            // we use 0.5 pixel positions to draw crisp lines:
            // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Applying_styles_and_colors#Line_styles
            this.ctx.beginPath()
            this.ctx.moveTo(Math.round(x - left) + 0.5, renderSettings.notchBigY1)
            this.ctx.lineTo(Math.round(x - left) + 0.5, renderSettings.notchBigY2)
            this.ctx.stroke()

            // draw the divisors
            let divIndex = 0
            while (++divIndex < divisors) {
                this.ctx.beginPath()
                const x1 = Math.round(Math.round(x - left) - timelabelWidth * (divIndex / divisors))
                this.ctx.moveTo(x1 + 0.5, renderSettings.notchSmallY1)
                this.ctx.lineTo(x1 + 0.5, renderSettings.notchSmallY2)
                this.ctx.stroke()
            }
        }
    }

    /**
     * Destroy the TimelabelsDrawer and free any resources we hold
     */
    destroy() {
        this.timelabelsCalculator = null
        this.canvas = null
        this.ctx = null
        this.timelabelsTransform = null
    }
}
