import subMonths from 'date-fns/sub_months'
import { ExportStatus } from 'constants.es6'

export default /* @ngInject */ function DeltaExportModalFactory($uibModal) {
    const DeltaExportModal = {
        showModal() {
            $uibModal.open({
                templateUrl: 'js/admin/delta-export-modal.tpl.html',
                controller: DeltaExportModalCtrl,
                controllerAs: DeltaExportModalCtrl.controllerAs,
                backdrop: 'static',
                resolve: {
                    commonOptions: /* @ngInject */ (AdminModule) => {
                        return AdminModule.getCommonOptions()
                    },
                },
            })
        },
    }

    return DeltaExportModal
}

/* @ngInject */
function DeltaExportModalCtrl(
    $scope,
    $http,
    $uibModalInstance,

    ErrorStringifier,
    ExportStatusPoller,

    commonOptions
) {
    const vm = this

    $scope.STATUS_INIT = ExportStatus.Init
    $scope.STATUS_POLLING = ExportStatus.Polling
    $scope.STATUS_DOWNLOAD_READY = ExportStatus.DownloadReady
    $scope.STATUS_NO_RESULTS = ExportStatus.NoResults
    $scope.STATUS_FAILED = ExportStatus.Failed
    $scope.commonOptions = commonOptions

    vm.status = ExportStatus.Init

    vm.startExport = startExport

    vm.resetExportForm = setupForm

    activate()

    return vm

    ////////////////////////////////////

    function activate() {
        $scope.$on('$destroy', () => {
            if (vm.poller) {
                vm.poller.destroy()
                vm.poller = null
            }
        })

        setupForm()

        $scope.$watch('vm.start', function (v) {
            $scope.endDatePickerOptions.minDate = v
        })
        $scope.$watch('vm.end', function (v) {
            $scope.startDatePickerOptions.maxDate = v
        })
    }

    function setupForm() {
        const today = new Date()
        const minDate = new Date('2015-11-01')

        vm.start = subMonths(today, 3)
        vm.end = today
        vm.workers = []
        vm.reviewers = []
        vm.groups = []

        $scope.startDatePickerOptions = {
            minDate: minDate,
            maxDate: today,
            showWeeks: false,
        }
        $scope.endDatePickerOptions = {
            minDate: minDate,
            maxDate: today,
            showWeeks: false,
        }
    }

    function startExport() {
        const payload = {
            workers: vm.workers,
            reviewers: vm.reviewers,
            groups: vm.groups,
            task_finished_date: {
                start: vm.start,
                end: vm.end,
            },
        }

        const promise = $http
            .post('/api/admin/export/qa-delta-report/', { ...payload })
            .then((res) => res.data)
            .then((exportModel) => {
                vm.poller = new ExportStatusPoller(vm, exportModel)
                vm.exportModel = vm.poller.exportModel
            })
            .catch((err) => {
                if (err) {
                    vm.errorMessage = ErrorStringifier.stringify(err)
                }
            })

        return promise
    }
}

DeltaExportModalCtrl.controllerAs = 'vm'
