/**
 * @ngdoc component
 * @name filmstripControlButton
 * @module map3.scenes
 *
 * @description
 * A button to control the filmstrip render type
 *
 * @param {string} filmstripType one of [full, single, disabled]
 */
const filmstripControlButtonComponent = {
    controller: FilmstripControlButtonCtrl,
    bindings: {
        filmstripType: '=',
    },
    template: `
        <button type="button" class="btn btn-link-light p-0 mx-1"
            title="Cycle between rendering the full filmstrip, only the first frame of each segment, or no filmstrip at all."
            ng-click="$ctrl.cycleFilmstripType()"
        >
            <span class="material-icons md-18">tv</span>
        </button>
    `,
}

export default filmstripControlButtonComponent

const FILMSTRIP_TYPE_FULL = 'full'
const FILMSTRIP_TYPE_SINGLE = 'single'
const FILMSTRIP_TYPE_DISABLED = 'disabled'
const FILMSTRIP_TYPES = [FILMSTRIP_TYPE_FULL, FILMSTRIP_TYPE_SINGLE, FILMSTRIP_TYPE_DISABLED]

function FilmstripControlButtonCtrl() {
    const $ctrl = this

    $ctrl.cycleFilmstripType = cycleFilmstripType

    return $ctrl

    /////////////////////////////////

    function cycleFilmstripType() {
        let nextIndex = FILMSTRIP_TYPES.indexOf($ctrl.filmstripType) + 1

        if (nextIndex > FILMSTRIP_TYPES.length - 1) {
            nextIndex = 0
        }

        $ctrl.filmstripType = FILMSTRIP_TYPES[nextIndex]
    }
}
