/**
 * VIDEO_TYPE_DEFINITONS format overview.
 *
 * Video type definitions are used in /admin/manage-videos to setup the rendering and
 * editing of the video types that MAP3 supports.
 *
 * Because different video types have different metadata fields, we need to define
 * their handling manually for each type. Furthermore, to allow more flexibility,
 * each video type's editing form template is a separate HTML file.
 *
 * Keep in mind that there are some metadata fields that are shared among all video
 * types:
 *  - `contentType`: The MAP3 video content type designator. This is also the main key we
 *          use to select which video type definition to use.
 *  - `programmingType`: SMS-side content type designator. We only allow specific
 *          `programmingType`s per a given `contentType`
 *  - `programmingSubType`: SMS-side content sub type designator
 *
 * Each video type definition has the following fields:
 *  - `contentType`: The main key for the definition
 *  - `label`: The human-readable label for the `contentType`
 *  - `templatePath`: The HTML template for the metadata form. It should have inputs for
 *          metadata fields unique to this `contentType`
 *  - `fields`: List of all metadata fields, with their name, human-readable label and
 *          variable type. This is used by the <render-metadata> directive for display.
 *  - `programmingTypeSettings`: Settings definition for programming types for the
 *          current `contentType`. Has the following fields:
 *      - `staticValue`: If set, the form input is disable and the `programmingType`
 *              property will always have that value set
 *      - `required`: If set, the `programmingType` form select is required
 *      - `options`: Array of `{ value, label, programmingSubTypeSettings }` option objects.
 *              Keep in mind that if you want to allow empty inputs, you must provide it
 *              as an option
 *      - `programmingSubTypeSettings`: For each option, we can define unique programming
 *             sub-type settings. `programmingSubTypeSettings` has the same fields as
 *             `programmingTypeSettings`.
 */
type MetadataFieldDefinition = {
    name: string
    label: string
    type: 'string' | 'groups' | 'date'
}

export const definitionSeries = {
    contentType: 'series',
    programmaticLabel: 'series',
    label: 'TV Show',
    templatePath: 'js/admin/manage-videos/templates/TvSeries.tpl.html',

    programmingTypeSettings: {
        staticValue: 'episode',
        required: true,
        options: [
            {
                value: 'episode',
                label: 'Episode',
                programmingSubTypeSettings: {
                    required: false,
                    options: [
                        { value: null, label: '' },
                        { value: 'news', label: 'News' },
                    ],
                },
            },
        ],
    },

    fields: [
        {
            name: 'contentType',
            label: 'Video Type',
            type: 'string',
        },
        {
            name: 'groups',
            label: 'Groups',
            type: 'groups',
        },
        {
            name: 'seriesTitle',
            label: 'Series Title',
            type: 'string',
        },
        {
            name: 'seasonNumber',
            label: 'Season Number',
            type: 'string',
        },
        {
            name: 'episodeTitle',
            label: 'Episode Title',
            type: 'string',
        },
        {
            name: 'episodeNumber',
            label: 'Episode Number',
            type: 'string',
        },
        {
            name: 'originalAirDate',
            label: 'Original Air Date',
            type: 'date',
        },
        {
            name: 'referenceID',
            label: 'Unique Identifier',
            type: 'string',
        },
    ] as MetadataFieldDefinition[],
} as const

export const definitionClip = {
    contentType: 'clip',
    programmaticLabel: 'clip',
    label: 'Clip',
    templatePath: 'js/admin/manage-videos/templates/Clips.tpl.html',

    programmingTypeSettings: {
        staticValue: 'clip',
        required: true,
        options: [
            {
                value: 'clip',
                label: 'Clip',
                programmingSubTypeSettings: {
                    staticValue: null,
                    required: false,
                    options: [{ value: null, label: '' }],
                },
            },
        ],
    },

    fields: [
        {
            name: 'contentType',
            label: 'Video Type',
            type: 'string',
        },
        {
            name: 'groups',
            label: 'Groups',
            type: 'groups',
        },
        {
            name: 'title',
            label: 'Title',
            type: 'string',
        },
        {
            name: 'parentVideo',
            label: 'Parent Video',
            type: 'string',
        },
        {
            name: 'originalAirDate',
            label: 'Original Air Date',
            type: 'date',
        },
        {
            name: 'referenceID',
            label: 'Unique Identifier',
            type: 'string',
        },
    ] as MetadataFieldDefinition[],
} as const

export const definitionFeature = {
    contentType: 'feature / tv movie',
    programmaticLabel: 'feature',
    label: 'Feature / TV Movie',
    templatePath: 'js/admin/manage-videos/templates/Movies.tpl.html',

    programmingTypeSettings: {
        required: true,
        options: [
            {
                value: 'feature',
                label: 'Feature',
                programmingSubTypeSettings: {
                    required: false,
                    options: [
                        { value: null, label: '' },
                        { value: 'tv adaptation', label: 'TV Adaptation' },
                    ],
                },
            },
            {
                value: 'tv movie',
                label: 'TV Movie',
                programmingSubTypeSettings: {
                    required: false,
                    options: [
                        { value: null, label: '' },
                        { value: 'original tv movie', label: 'Original TV Movie' },
                        {
                            value: 'digital channel original movie',
                            label: 'Digital Channel Original Movie',
                        },
                    ],
                },
            },
        ],
    },

    fields: [
        {
            name: 'contentType',
            label: 'Video Type',
            type: 'string',
        },
        {
            name: 'groups',
            label: 'Groups',
            type: 'groups',
        },
        {
            name: 'title',
            label: 'Title',
            type: 'string',
        },
        {
            name: 'releaseDate',
            label: 'Release Date',
            type: 'date',
        },
        {
            name: 'referenceID',
            label: 'Unique Identifier',
            type: 'string',
        },
    ] as MetadataFieldDefinition[],
} as const

export const definitionPreair = {
    contentType: 'preair series',
    programmaticLabel: 'preair',
    label: 'Preair Series',
    templatePath: 'js/admin/manage-videos/templates/PreAirSeries.tpl.html',

    programmingTypeSettings: {
        staticValue: 'preair',
        required: true,
        options: [
            {
                value: 'preair',
                label: 'PreAir',
            },
        ],
    },

    fields: [
        {
            name: 'contentType',
            label: 'Video Type',
            type: 'string',
        },
        {
            name: 'groups',
            label: 'Groups',
            type: 'groups',
        },
        {
            name: 'cutType',
            label: 'Cut Type',
            type: 'cutType',
        },
        {
            name: 'seriesTitle',
            label: 'Series Title',
            type: 'string',
        },
        {
            name: 'seasonNumber',
            label: 'Season Number',
            type: 'string',
        },
        {
            name: 'episodeNumber',
            label: 'Episode Number',
            type: 'string',
        },
        {
            name: 'episodeTitle',
            label: 'Episode Title',
            type: 'string',
        },
    ] as MetadataFieldDefinition[],
    cutTypeOptions: [
        {
            value: 'final cut',
            label: 'Final Cut',
        },
        {
            value: 'rough cut',
            label: 'Rough Cut',
        },
        {
            value: 'full cut',
            label: 'Full Cut',
        },
        {
            value: 'limited cut',
            label: 'Limited Cut',
        },
    ],
    episodeTitleMap: {
        'full cut': 'Full Cut - Pilot',
        'final cut': 'Pilot',
        'rough cut': 'Rough Cut - Pilot',
        'limited cut': 'Limited Cut - Pilot',
    },
} as const

export default [definitionSeries, definitionClip, definitionFeature, definitionPreair]
