import _ from 'lodash'
import { slugify } from 'services/slugify'

import { ContentMarkerType } from 'content-markers/constants'

export default /* @ngInject */ function MarkerTransformationFactory() {
    let MarkerTransformation = {}

    MarkerTransformation.bookmarks = function (bookmarks) {
        return _.filter(_.map(bookmarks, makeBookmarkMarker), MarkerTransformation.validMarker)

        function makeBookmarkMarker(bookmark) {
            return {
                precedence: 5,
                type: 'bookmark',
                time: bookmark,
                text: 'Bookmark',
                markerClass: 'marker-bookmark',
            }
        }
    }

    MarkerTransformation.tags = function (tags) {
        return _.filter(_.map(tags, makeAnnotationMarker), MarkerTransformation.validMarker)

        function makeAnnotationMarker(tag) {
            // For timestamps, we need to replace `.` for `-`, because otherwize we get an css class name.
            // We use slugify just in case.
            const identifier = '' + (tag.annotationID || tag.$$hashKey)
            const scrollTargetClass = `.annotation-${slugify(identifier)}`

            return {
                $$markerUid: scrollTargetClass,
                precedence: 1,
                type: 'annotation',
                time: tag.timestamp,
                text: 'Annotation',
                markerClass: 'marker-annotation',
                tag: tag,
                scrollTarget: scrollTargetClass,
            }
        }
    }

    MarkerTransformation.threads = function (threads, onClick) {
        return _.filter(_.map(threads, makeThreadsMarker), MarkerTransformation.validMarker)

        function makeThreadsMarker(thread) {
            return {
                precedence: 10,
                type: 'comment-thread',
                time: thread.timestamp,
                markerClass: 'marker-threads',
                thread: thread,
                onClick: onClick,
            }
        }
    }

    MarkerTransformation.contentMarkers = function (markers, onClick) {
        return _.filter(_.map(markers, makeThreadsMarker), MarkerTransformation.validMarker)
        function makeThreadsMarker(marker) {
            const isPair = marker.type !== ContentMarkerType.FPCI
            const markerClass = isPair
                ? 'marker-pair'
                : marker.rating !== false
                ? `marker-single marker-rating-${marker.rating}`
                : 'marker-single'

            return {
                id: marker.id,
                precedence: 2,
                type: 'content-marker',
                subType: marker.type,
                time: marker.timestamp,
                text: isPair ? 'Comercial black' : 'FPCI',
                markerClass,
                frameNumber: marker.frameNumber,
                formattedTime: marker.formattedTime,
                onClick: onClick,
            }
        }
    }

    MarkerTransformation.validMarker = function (marker) {
        return !_.isNil(marker.time) && marker.time !== false
    }

    return MarkerTransformation
}
