import constants from './constants'
import { Environment } from 'constants.es6'

import map3coreDialog from 'directives/map-dialog/module'
import map3globalShortcuts from 'global-shortcuts/module'
import map3comments from 'comments/module'

import NotificationFactory from 'services/notification/Notification.factory'
import UpdateNotifierFactory, { UpdateNotifierInstance } from 'services/UpdateNotifier'
import ErrorStringifierFactory from 'services/ErrorStringifier.factory'
import { TimeTrackerFactory, TimeTrackFactory } from 'services/TimeTracking'
import RetriableRequestFactory from 'services/RetriableRequest.factory'
import DigestFactory from 'services/Digest.factory'
import DOMUtilityFactory from 'services/DOMUtility.factory'
import CommentServiceFactory from 'services/CommentService.factory'
import ResultsModuleFactory from 'services/ResultsModule.factory'
import GroupModelFactory from 'models/GroupModel.factory'
import MarkerTransformationFactory from 'services/MarkerTransformation.factory'
import TimestampTransformFactory from 'services/TimestampTransform.factory'
import AnnotationValidationFactory from 'services/AnnotationValidation.factory'
import AnnotationSeekerFactory from 'services/AnnotationSeeker.factory'
import OSGuesserFactory from 'services/OSGuesser.factory'
import ExportStatusPollerFactory from 'services/ExportStatusPoller.factory'
import BuildsProgrammingTypeGroupsFactory from 'services/BuildsProgrammingTypeGroups.factory'
import TablePerfieldFilterResetFactory from 'user/TablePerfieldFilterReset.factory'

import forceXSRFlocallyInterceptorProvider from 'services/http/forceXSRFlocallyInterceptor.provider'
import HiddenErrorInterceptorFactory from 'services/http/HiddenErrorInterceptor.factory'
import CaptureRequestDebugDataInterceptorFactory from 'services/http/CaptureRequestDebugDataInterceptor.factory'
import cachebustTemplatesInterceptorFactory from 'services/http/cachebustTemplatesInterceptor.factory'
import handleBackendResponseErrorsInterceptorFactory from 'services/http/handleBackendResponseErrorsInterceptor.factory'
import createMissingAuthorityHandlerFactory from 'services/http/createMissingAuthorityHandler.factory'
import baseUrlInterceptorFactory from 'services/http/baseUrlInterceptor'

import RootScopeExtensionsConfig from 'services/RootScopeExtensionsConfig'
import orderByFilterExtensionConfig from 'services/orderByFilterExtensionConfig'
import notificationMessageDirective from 'services/notification/notificationMessage.directive'
import helpMessagesDirective from 'directives/form/helpMessages.directive'
import formInputDirective from 'directives/form/formInput.directive'
import statefulInputDirective from 'directives/form/statefulInput.directive'
import statefulLabelDirective from 'directives/form/statefulLabel.directive'
import formGroupDirective from 'directives/form/formGroup.directive'
import statefulChosenDirective from 'directives/form/statefulChosen.directive'
import fieldFilterDirective from 'directives/fieldFilter.directive'
import dateFieldFilterDirective from 'directives/dateFieldFilter.directive'
import resetFieldFilterDirective from 'directives/resetFieldFilter.directive'
import fsmStickyHeaderDirective from 'directives/fsmStickyHeader.directive'
import videoTimeDirective from 'directives/videoTime.directive'
import chosenCallbacksDirective from 'directives/chosenCallbacks.directive'
import triggerResizeOnClickDirective from 'directives/triggerResizeOnClick.directive'
import onFinishRenderDirective from 'directives/onFinishRender.directive'

import orderByArrayFilter from 'filters/orderByArray.filter'
import orderByNaturalFilter from 'filters/orderByNatural.filter'
import fromNowFilter from 'filters/fromNow.filter'
import timestampFilter from 'filters/timestamp.filter'
import titleCaseFilter from 'filters/titleCase.filter'
import slugifyFilter from 'filters/slugify.filter'
import searchByFieldFilter from 'filters/searchByField.filter'
import perfieldFilterFilter from 'directives/perfieldFilter.filter'
import objectFilterFilter from 'directives/objectFilter.filter'

import LandingCtrl from 'controllers/Landing.ctrl'
import NavigationCtrl from 'controllers/Navigation.ctrl'
import navigationTmpl from 'partials/navigation.html'
import iconSpriteTmpl from 'images/redesign/icons/icon-sprite.html'

import 'angular-chosen-localytics'
import 'ng-tags-input'
import 'jquery-mousewheel'

/**
 * @ngdoc module
 * @name map3.core
 * @module map3.core
 */
const map3core = angular
    .module('map3.core', [
        constants,

        map3globalShortcuts,
        map3comments,
        map3coreDialog,

        require('angular-ui-router'),
        require('angular-ui-bootstrap'),
        require('angular-messages'),
        require('angular-sanitize'),
        require('angular-cookies'),
        require('angular-loading-bar'),
        require('ng-idle'),
        require('ng-file-upload'),
        require('checklist-model'),
        'localytics.directives', // angular-chosen-localytics
        'ngTagsInput',
    ])
    .config(setupHttpInterceptors)
    .config(disableDebugInfoOutsideLocalDev)
    .factory('hiddenErrorInterceptor', HiddenErrorInterceptorFactory)
    .factory('CaptureRequestDebugDataInterceptor', CaptureRequestDebugDataInterceptorFactory)
    .factory('cachebustTemplatesInterceptor', cachebustTemplatesInterceptorFactory)
    .factory(
        'handleBackendResponseErrorsInterceptor',
        handleBackendResponseErrorsInterceptorFactory
    )
    .factory('createMissingAuthorityHandler', createMissingAuthorityHandlerFactory)
    .factory('baseUrlInterceptor', baseUrlInterceptorFactory)
    .factory('TablePerfieldFilterReset', TablePerfieldFilterResetFactory)
    .provider(
        'forceXSRFlocallyInterceptor',
        forceXSRFlocallyInterceptorProvider as ng.IServiceProviderFactory
    )
    .run(exposeBaseUrlOnRootScope)
    .run(
        /* @ngInject */ ($templateCache: ng.ITemplateCacheService) => {
            $templateCache.put('partials/navigation.html', navigationTmpl)
            $templateCache.put('images/new-design/sprite.html', iconSpriteTmpl)
        }
    )
    .config(RootScopeExtensionsConfig)
    .config(orderByFilterExtensionConfig)

    .config(
        /* @ngInject */ function (uibDropdownConfig: { [key: string]: any }) {
            uibDropdownConfig.openClass = 'show'
        }
    )

    .controller('LandingCtrl', LandingCtrl)
    .controller('NavigationCtrl', NavigationCtrl)

    .factory('Digest', DigestFactory)
    .factory('DOMUtility', DOMUtilityFactory)
    .factory('Notification', NotificationFactory)
    .factory('ErrorStringifier', ErrorStringifierFactory)
    .factory('OSGuesser', OSGuesserFactory)
    .factory('ExportStatusPoller', ExportStatusPollerFactory)
    .factory('BuildsProgrammingTypeGroups', BuildsProgrammingTypeGroupsFactory)

    .factory('TimeTracker', TimeTrackerFactory)
    .factory('TimeTrack', TimeTrackFactory)
    .factory('RetriableRequest', RetriableRequestFactory)
    .factory('CommentService', CommentServiceFactory)
    .factory('MarkerTransformation', MarkerTransformationFactory)
    .factory('TimestampTransform', TimestampTransformFactory)
    .factory('AnnotationValidation', AnnotationValidationFactory)
    .factory('AnnotationSeeker', AnnotationSeekerFactory)
    .factory('ResultsModule', ResultsModuleFactory)

    .directive('notificationMessage', notificationMessageDirective)
    .directive('helpMessages', helpMessagesDirective)
    .directive('formInput', formInputDirective)
    .directive('input', statefulInputDirective as ng.IDirectiveFactory)
    .directive('textarea', statefulInputDirective as ng.IDirectiveFactory)
    .directive('select', statefulInputDirective as ng.IDirectiveFactory)
    .directive('label', statefulLabelDirective)
    .directive('formGroup', formGroupDirective)
    .directive('chosen', statefulChosenDirective)
    .directive('fsmStickyHeader', fsmStickyHeaderDirective)
    .directive('fieldFilter', fieldFilterDirective)
    .directive('dateFilter', dateFieldFilterDirective)
    .directive('resetFieldFilter', resetFieldFilterDirective)
    .directive('videoTime', videoTimeDirective)
    .directive('chosenCallbacks', chosenCallbacksDirective)
    .directive('triggerResizeOnClick', triggerResizeOnClickDirective)
    .directive('onFinishRender', onFinishRenderDirective)

    .filter('perfieldFilter', perfieldFilterFilter as ng.IFilterService)
    .filter('orderByArray', orderByArrayFilter)
    .filter('orderByNatural', orderByNaturalFilter)
    .filter('fromNow', fromNowFilter)
    .filter('titleCase', titleCaseFilter)
    .filter('slugify', slugifyFilter)
    .filter('timestamp', timestampFilter)
    .filter('objectFilter', objectFilterFilter)
    .filter('searchByFieldFilter', searchByFieldFilter)

    .factory('UpdateNotifier', UpdateNotifierFactory)
    .run(/* @ngInject */ (UpdateNotifier: UpdateNotifierInstance) => UpdateNotifier.init())

    .factory('GroupModel', GroupModelFactory)
/* @ngInject */
function setupHttpInterceptors($httpProvider: ng.IHttpProvider) {
    $httpProvider.interceptors.push('hiddenErrorInterceptor')
    $httpProvider.interceptors.push('forceXSRFlocallyInterceptor')
    $httpProvider.interceptors.push('CaptureRequestDebugDataInterceptor')
    $httpProvider.interceptors.push('cachebustTemplatesInterceptor')
    $httpProvider.interceptors.push('handleBackendResponseErrorsInterceptor')
    $httpProvider.interceptors.push('baseUrlInterceptor')
}

/* @ngInject */
function disableDebugInfoOutsideLocalDev(
    $compileProvider: ng.ICompileProvider,
    $logProvider: ng.ILogProvider
) {
    const map3: TMap3Window = window

    if (map3.MAP3_ENV !== Environment.LocalDevelopment) {
        $compileProvider.debugInfoEnabled(false)
        // you can manually re-enable debug info in console with
        // angular.reloadWithDebugInfo();
    }
    if (map3.MAP3_ENV === Environment.Production) {
        $logProvider.debugEnabled(false)
    }
}

/* @ngInject */
function exposeBaseUrlOnRootScope($rootScope: TMap3RootScope, API_URL: string) {
    $rootScope.API_URL = API_URL
}

export default map3core.name
