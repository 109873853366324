import _ from 'lodash'

export default /* @ngInject */ function UserProfileCtrl(
    USER_ROLE_ADMIN,
    MAP3_ROLES,
    $state,
    $scope,
    Notification,
    UserProfileModule,
    User,
    Upload,
    UserPreferences,
    InternalMessaging,
    MapDialog,
    userData,
    groups,
    messages
) {
    let userProfileVm = this

    userProfileVm.userData = userData

    userProfileVm.allDefinedRoles = MAP3_ROLES
    userProfileVm.allDefinedGroups = groups

    userProfileVm.tempRoles = userData.roles.slice()
    userProfileVm.tempGroups = userData.groups.slice()
    userProfileVm.rolesError = false

    userProfileVm.updateBasicInfo = updateBasicInfo
    userProfileVm.passwordConfig = {
        oldPass: '',
        password: '',
        reNewPass: '',
    }

    userProfileVm.getUserPref = getUserPref
    userProfileVm.setUserPref = setUserPref
    userProfileVm.toggleUserPref = toggleUserPref

    userProfileVm.uploadAvatar = uploadAvatar

    userProfileVm.messages = messages
    userProfileVm.readMessage = readMessage
    userProfileVm.showConfirmModal = showConfirmModal
    ///////////////////////////

    function updateBasicInfo() {
        const updateData = angular.copy(userProfileVm.userData)

        if (User.isAdmin()) {
            _.assign(updateData, {
                roles: userProfileVm.tempRoles,
                groups: userProfileVm.tempGroups,
            })

            if (!_.includes(updateData.roles, USER_ROLE_ADMIN)) {
                updateData.super_admin = false
            }
        }

        const basicInfoPromise = UserProfileModule.editUserData(updateData)

        Notification.forPromise(basicInfoPromise, 'Basic Info successfully updated')

        basicInfoPromise.then(function () {
            $state.reload()
        })
    }

    function uploadAvatar(file) {
        let uploadPromise = Upload.upload({
            url: '/api/profile/upload-avatar',
            data: { avatar: file },
        })
        uploadPromise.then(function (res) {
            userProfileVm.userData.avatar = res.data.avatar
        })

        Notification.forPromise(uploadPromise, 'Avatar changed successfully')
    }

    function getUserPref(setting, defaultVal) {
        return UserPreferences.get('common', setting, defaultVal)
    }

    function setUserPref(setting, value) {
        return UserPreferences.set('common', setting, value)
    }

    function toggleUserPref(setting) {
        return UserPreferences.set('common', setting, !UserPreferences.get('common', setting))
    }

    function readMessage(message) {
        InternalMessaging.readMessage(message)
        $scope.$applyAsync(function () {
            message.read = 1
        })
    }

    function showConfirmModal($event, message) {
        let modalConfig = MapDialog.confirm()
            .title('Delete Mesaage')
            .textContent('You are about to delete this message, are you sure?')
            .ok('Delete')
            .cancel('Cancel')

        MapDialog.show(modalConfig).then(function () {
            let deletePromise = InternalMessaging.deleteMessage(message.id)

            deletePromise.then(function () {
                _.remove(userProfileVm.messages, message)
            })

            Notification.forPromise(deletePromise, 'Message successfully deleted')
        })
    }
}

UserProfileCtrl.resolve = {
    username: /* @ngInject */ function (User) {
        return User.cached().username
    },

    userData: /* @ngInject */ function (UserProfileModule) {
        return UserProfileModule.getUserData()
    },

    groups: /* @ngInject */ function (User, AdminUsersModule, userData) {
        if (User.isAdmin()) {
            return AdminUsersModule.getGroups()
        } else {
            return userData.groups
        }
    },

    messages: /* @ngInject */ function (InternalMessaging) {
        return InternalMessaging.getAllMessages()
    },
}
