import { DOMUtilityInstance } from 'services/DOMUtility.factory'

type ScopeBindings = {
    authorityDetailsCopy: any
    authority: any
    authorityCollapsed: boolean
}

export default /* @ngInject */ function authorityDirective(DOMUtility: DOMUtilityInstance) {
    const directive = {
        restrict: 'E',
        scope: {
            authority: '=',
            questions: '<',
            title: '@?',
            help: '@?',
        },
        transclude: true,
        link: authoritylineLinkFn,
        templateUrl: 'js/worker/directives/authority.tpl.html',
    }

    return directive

    function authoritylineLinkFn(scope: ng.IScope & ScopeBindings, element: ng.IAugmentedJQuery) {
        activate()

        ///////////////////////////

        function activate() {
            scope.$watch(
                'authority.details',
                function (authorityDetails) {
                    scope.authorityDetailsCopy = angular.copy(authorityDetails)
                },
                /* deep */ true
            )

            scope.$on('authority.highlight', (e, authority) => {
                if (scope.authority === authority) {
                    scrollWindowToSelf()
                    highlightSelf()
                }
            })

            scope.$on('authority.forceEditAttributes', (e, authority) => {
                if (scope.authority === authority) {
                    highlightSelf()
                }
            })
        }

        function scrollWindowToSelf() {
            scope.authorityCollapsed = false
            const elementOffsetTop = element.offset()!.top

            $('html, body').animate(
                {
                    scrollTop: elementOffsetTop - 60, //60 - some padding;
                },
                700
            )
        }

        function highlightSelf() {
            DOMUtility.triggerAnimation(element, 'trigger-highlight')
        }
    }
}
