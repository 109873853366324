/**
 * For requests that end in `.html`, add a cachebust query param
 * like "?rev=aabbccdd123123123"
 */
export default /* @ngInject */ function cachebustTemplatesInterceptorFactory(GIT_REVISION: string) {
    const IS_TEMPLATE_URL = /\.html$/

    const cachebustTemplatesInterceptor = {
        request: function (config: { [key: string]: any }) {
            if (!config.cached && IS_TEMPLATE_URL.test(config.url)) {
                config.url += '?' + config.paramSerializer({ rev: GIT_REVISION })
            }

            return config
        },
    }

    return cachebustTemplatesInterceptor
}
