import _ from 'lodash'
import { NewAnnotation } from '../qa/service/QAAnnotation.factory'
import { findAnswerForQuestion } from 'util/annotateVideo'

type PartialAnnotation = {
    answers: AnnotationAnswer[]
}

export default /* @ngInject */ function AnnotationValidationFactory(
    ANSWER_VALUE_IRRELEVANT: any,
    Notification: any
) {
    const AnnotationValidation = {
        isValid: function <T = PartialAnnotation>(
            annotation: unknown,
            questions: any[]
        ): annotation is T {
            const errors: string[] = AnnotationValidation.validate(
                annotation as NewAnnotation,
                questions
            )

            if (errors.length) {
                Notification.error(errors)
            }

            return errors.length === 0
        },

        validate: function (annotation: PartialAnnotation, questions: any[]) {
            const errors: string[] = []

            if (_.isNil(annotation) || _.isNil(questions)) {
                errors.push('You must provide at least one valid answer')
                return errors
            }

            const allAnswersEmpty = _.every(annotation.answers, isAnswerEmpty)

            if (allAnswersEmpty) {
                errors.push('You must provide at least one valid answer')
                return errors
            }

            const requiredQuestions = _.filter(questions, { mandatory: true })
            _.forEach(requiredQuestions, (question) => {
                const answer = findAnswerForQuestion(annotation.answers, question)

                if (_.isUndefined(answer)) {
                    errors.push(`We couldn't match ${question.placeholder} to any answer`)
                    return
                }

                if (isAnswerEmpty(answer)) {
                    errors.push(
                        `The annotation ${question.placeholder} must have at least one answer`
                    )
                }
            })

            if (errors.length) {
                return errors
            }

            // For single anwsers (non-multiple) that don't contain nested questions
            const nonMultiMainQuestions = questions.filter(
                (q) =>
                    (q.multiple === false || _.isUndefined(q.multiple)) &&
                    (!q.details || !q.details.length)
            )
            _.forEach(nonMultiMainQuestions, (question) => {
                const answer = findAnswerForQuestion(annotation.answers, question)
                if (answer && _.isArray(answer.value) && answer.value.length > 1) {
                    errors.push(
                        `The annotation question ${question.placeholder} must have no more then one answer`
                    )
                }
            })

            if (errors.length) {
                return errors
            }

            _.forEach(annotation.answers, (answer) => {
                const question = _.find(questions, { uri: answer.id })
                _.forEach(answer.detailsRows, (row, rowIdx) => {
                    if (!_.some(row, (answerDetail) => !!answerDetail.value)) {
                        errors.push(
                            `You must give at least one answer for details row ${rowIdx + 1}`
                        )
                        return
                    }

                    _.forEach(row, (answerDetail) => {
                        const questionDetail = _.find(question.details, {
                            uri: answerDetail.id,
                        })
                        if (questionDetail.mandatory && isAnswerEmpty(answerDetail)) {
                            errors.push(
                                `Missing mandatory answer for details row ${rowIdx + 1} question ${
                                    questionDetail.placeholder
                                }`
                            )
                        }
                    })
                })
            })

            return errors

            ///////////////////////////////////////////////////////

            function isAnswerEmpty(answer: any) {
                return _.isArray(answer.value) ? _.isEmpty(answer.value) : _.isNil(answer.value)
            }
        },

        checkForIrrelevant: function (annotation: PartialAnnotation) {
            return _.some(annotation.answers, (answer) =>
                _.isArray(answer.value)
                    ? _.find(answer.value, { value: ANSWER_VALUE_IRRELEVANT })
                    : _.get(answer.value, 'value') === ANSWER_VALUE_IRRELEVANT
            )
        },
    }

    return AnnotationValidation
}

export type AnnotationValidationInstance = ReturnType<typeof AnnotationValidationFactory>
