import ResultsViewerCtrl from './ResultsViewer.ctrl'
import ResultsViewerVideoCtrl from './ResultsViewerVideo.ctrl'
import ExportDownloadCtrl from './ExportDownload.ctrl'

export default /* @ngInject */ function resultsRoutesConfig($stateProvider: ng.ui.IStateProvider) {
    $stateProvider
        .state('results', {
            url: '/results',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('results.resultsList', {
            url: '/list',
            templateUrl: 'js/results/results-viewer.html',
            controller: ResultsViewerCtrl,
            controllerAs: ResultsViewerCtrl.controllerAs,
            data: { roles: [['Worker', 'Admin', 'QA', 'Reviewer', 'Manager']], navHidden: true },
            resolve: ResultsViewerCtrl.resolve,
        })
        .state('results.resultsVideo', {
            url: '/video/:videoId',
            templateUrl: 'js/results/results-viewer-video.html',
            controller: ResultsViewerVideoCtrl,
            controllerAs: ResultsViewerVideoCtrl.controllerAs,
            resolve: ResultsViewerVideoCtrl.resolve,
            data: { roles: [['Worker', 'Admin', 'QA', 'Reviewer', 'Manager']], navHidden: true },
        })
        .state('results.exportDownload', {
            url: '/export/download/:exportToken',
            templateUrl: 'js/results/export-download.tpl.html',
            data: {
                roles: [['Worker', 'Admin', 'QA', 'Reviewer', 'Manager']],
                allowRemember: false,
            },
            controller: ExportDownloadCtrl,
            controllerAs: ExportDownloadCtrl.controllerAs,
            resolve: ExportDownloadCtrl.resolve,
        })
}
