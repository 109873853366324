import _ from 'lodash'
import angular from 'angular'

import { PROGRAMMING_TYPE_CONSTANTS } from './constants.es6'

/* eslint-disable key-spacing */
const constants = angular.module('map3.constants', [])

export const SCENE_PERSIST_PROPS = [
    'id',
    'start',
    'startFrame',
    'end',
    'endFrame',
    'parentId',
    'nonCannonSegment',
] as const

export const SCENE_AUTOSPLITTER_LIMIT = 500

constants
    .constant('ENVIRONMENT', selectEnvForCurrentHost())
    .constant('API_URL', selectApiForCurrentEnv())
    .constant('SMS_API_URL', selectSMSApiForCurrentEnv())

    .constant('MAP3_ROLES', ['Admin', 'Worker', 'QA', 'Reviewer', 'Manager'])

    .constant('USER_ROLE_ADMIN', 'Admin')
    .constant('USER_SUPERPOWER_SUPER_ADMIN', 'super_admin')

    .constant('TASKS_SUPPORTED_CLASSES', ['questionVideo', 'tagScenes'])

    .constant('SCENE_PERSIST_PROPS', SCENE_PERSIST_PROPS)
    .constant('SCENE_AUTOSPLITTER_LIMIT', SCENE_AUTOSPLITTER_LIMIT)

    .constant('GIT_REVISION', process.env.GIT_REVISION)

    .constant('VERSION_NUMBER', process.env.VERSION_NUMBER)

    .constant('NO_LOAD_OVERLAY', { ignoreLoadingBar: true })

    .constant('ANNOTATION_TIMESTAMP_DISABLED_VALUE', false)

    .constant('ANSWER_VALUE_IRRELEVANT', 'http://test.org/fake_uri/irrelevant')

    .constant('PERFIELD_EMPTY_VALUE_KEY', '')

    .constant('PROGRAMMING_TYPE_CONSTANTS', PROGRAMMING_TYPE_CONSTANTS)

    .constant('BUG_TYPE_MISSING_SONG_REQUEST', 'MISSING_SONG_REQUEST')
    .constant('BUG_TYPE_MISSING_AUTHORITY', 'MISSING_AUTHORITY')

    .constant('JOB_KEEP_ALIVE_EXTENSION_DAYS', 30)

    .constant('RESULTS_EXPORT_VIDEO', 'results_export_video')
    .constant('RESULTS_EXPORT_SERIES', 'results_export_series')
    .constant('RESULTS_EXPORT_SERIES_SEASON', 'results_export_series_season')
    .constant('RESULTS_EXPORT_SERIES_EPISODE', 'results_export_series_episode')

const JOB_PRIORITIES = [
    { priority: 10, label: 'Casual' },
    { priority: 20, label: 'As Available' },
    { priority: 30, label: 'Average' },
    { priority: 40, label: 'Important' },
    { priority: 50, label: 'Critical' },
]
export type JOB_PRIORITIES = typeof JOB_PRIORITIES

export const ASSIGNMENT_STATUSES = {
    IN_PROGRESS_STATUS: 'in_progress',
    AUTHORITY_FOUND_STATUS: 'authority_found',
    REPORTED_TO_ADMIN_STATUS: 'reported_to_admin',
}

export const ASSIGNMENT_ISSUE_TYPES = [
    { type: 'no_authorities', label: 'No terms' },
    { type: 'missing_authority', label: 'Missing terms' },
]

const CHOSEN_SHOWN_ITEM_LIMIT_MODIFIER = {
    WORKER_JOB_CREATE_WIZARD: 500,
    TAGGING_TASK: 1000,
    UNLIMITED: Number.POSITIVE_INFINITY,
}

constants // eslint-disable-line
    .constant('CHOSEN_SHOWN_ITEM_LIMIT_MODIFIER', CHOSEN_SHOWN_ITEM_LIMIT_MODIFIER)
    .constant('JOB_PRIORITIES', JOB_PRIORITIES)
    .constant(
        'OPTIONS_JOB_PRIORITY_LABELS',
        JOB_PRIORITIES.map((priority) => priority.label)
    )

    .constant('OPTIONS_JOB_KEEP_ALIVE', ['enabled', 'disabled'])

    .run(
        exposeOnRootScope([
            'ENVIRONMENT',

            'CHOSEN_SHOWN_ITEM_LIMIT_MODIFIER',
            'JOB_PRIORITIES',
            'JOB_KEEP_ALIVE_EXTENSION_DAYS',
            'RESULTS_EXPORT_VIDEO',
            'RESULTS_EXPORT_SERIES',
            'RESULTS_EXPORT_SERIES_SEASON',
            'RESULTS_EXPORT_SERIES_EPISODE',

            'OPTIONS_JOB_PRIORITY_LABELS',
            'OPTIONS_JOB_KEEP_ALIVE',

            'BUG_TYPE_MISSING_SONG_REQUEST',
            'BUG_TYPE_MISSING_AUTHORITY',
        ])
    )
/* eslint-enable key-spacing */

/**
 * Because manually generating constants to be exposed on root scope is super tedious,
 * this is a small function that automates it instead. Given an array of constant names
 * (or really, any angular injectable) it will attach it on $rootScope with the same name
 *
 * @param constantNames
 * @return A function to give to `angular.module().run()`
 */
export function exposeOnRootScope(constantNames: string[]) {
    function exposer($rootScope: ng.IRootScopeService, ...constants: string[]) {
        _.forEach(constantNames, (constantName, index) => {
            ;($rootScope as any)[constantName] = constants[index]
        })
    }

    exposer.$inject = ['$rootScope'].concat(constantNames)

    return exposer
}

function selectEnvForCurrentHost() {
    const win: typeof window & { MAP3_ENV?: string } = window

    if (win.MAP3_ENV) {
        return win.MAP3_ENV
    }

    const host = win.location.hostname
    switch (host) {
        case 'map3-qa-us-east-1.md.aws.seabc.go.com':
            win.MAP3_ENV = 'preproduction'
            break
        case 'tagging.disney.com':
            win.MAP3_ENV = 'production'
            break
        
        case 'map3-us-east-1.md.aws.seabc.go.com':
            win.MAP3_ENV = 'production'
            break

        case 'map3.md.aws.seabc.go.com':
            win.MAP3_ENV = 'production'
            break

        case 'map3-dev-us-east-1.md.aws.seabc.go.com':
            win.MAP3_ENV = 'development'
            break
        case 'redesign.map3.space':
            win.MAP3_ENV = 'development'
            break

        case 'map3.test':
        case 'localhost':
        case 'local.md.aws.seabc.go.com':
            win.MAP3_ENV = 'local-development'
            break

        default:
            win.MAP3_ENV = host
    }

    return win.MAP3_ENV
}

function selectApiForCurrentEnv() {
    const win: typeof window & { API_URL?: string } = window

    if (win.API_URL) {
        return win.API_URL
    }

    if (process.env.API_URL) {
        return process.env.API_URL
    }

    let host = win.location.hostname
    switch (selectEnvForCurrentHost()) {
        case 'preproduction':
            win.API_URL = '//map3-qa.md.aws.seabc.go.com'
            break

        case 'preproduction':
            win.API_URL = '//map3-qa-us-east-1.md.aws.seabc.go.com'
            break

        case 'production':
            win.API_URL = '//tagging.disney.com'
            break

        case 'production':
            win.API_URL = '//map3-us-east-1.md.aws.seabc.go.com'
            break

        case 'development':
            if (host.indexOf('redesign') !== -1) {
                host = host.replace(/redesign./i, '')
            }

            win.API_URL = '//' + host
            break

        case 'local-development':
            win.API_URL = '//localhost'
            break

        // for for unit tests
        case 'testing':
            win.API_URL = '/'
            break

        default:
            win.API_URL = '//' + host
    }

    return win.API_URL
}

function selectSMSApiForCurrentEnv() {
    switch (selectEnvForCurrentHost()) {
        case 'production':
            return 'https://sms-api-latency.metadata.dmed.technology/api'
        case 'preproduction':
            return 'https://sms-api-latency.metadataqa.dmed.technology/api'
        default:
            return 'https://sms-api-latency.metadatasb.dmed.technology/api'
    }
}

export const ENVIRONMENTS_FOR_ANALYTICS = [
    'production',
    'preproduction',
    'development',
    'local-development',
]

export default constants.name
