import { VideoAPIInstance } from './VideoAPIBuilder.factory'

export default /* @ngInject */ function VideoCommunicationFactory(
    $rootScope: ng.IRootScopeService
) {
    const VideoCommunication = {
        buildParent: buildParentCommunication,
        buildPopup: buildPopupCommunication,
    }

    return VideoCommunication

    ////////////////////////////////////////

    /**
     * API exposed on the popup's window object, to be used by the parent tab
     *
     * Used by the PopupVideoCtrl
     *
     * @param {videoAPI} popupVideoApi
     */
    function buildPopupCommunication(popupVideoApi: VideoAPIInstance) {
        const PopupCommunication = {
            getApi: function () {
                return popupVideoApi
            },

            /**
             * Seek the popup video
             * @param {mixed} time
             */
            seek: function (time: number) {
                popupVideoApi.seek(time)
            },

            /**
             * Seek the popup video
             * @param {number} frameNumber
             */
            seekFrame: function (frameNumber: number) {
                popupVideoApi.seekFrame(frameNumber)
            },

            /**
             * Set the markers on the popup window
             * @param {Array.<Marker>} markers
             */
            setMarkers: function (markers: any[]) {
                $rootScope.$applyAsync(function () {
                    popupVideoApi.setMarkers(markers)
                })
            },

            /**
             * Set the annotations on the popup window
             * @param {Array.<Marker>} annotations
             */
            setAnnotations: function (annotations: any[]) {
                $rootScope.$applyAsync(function () {
                    popupVideoApi.setAnnotations(annotations)
                })
            },
        }

        return PopupCommunication
    }

    /**
     * API exposed on the parent window, to be used by the popup window
     *
     * Used by the videoGorillasPlayer directive
     *
     * @param {$scope} scope
     * @param {videoAPI} parentVideoApi
     */
    function buildParentCommunication(
        scope: ng.IScope & { annotations: any[]; markers: any[] },
        parentVideoApi: VideoAPIInstance
    ) {
        const ParentCommunication = {
            getApi: function () {
                return parentVideoApi
            },

            /**
             * Execute actions on popup load
             */
            onPopupVideoApiLoaded: function (popupVideoApi: any) {
                ParentCommunication.autoSyncFrom(popupVideoApi)

                popupVideoApi.setMarkers(scope.markers)
                popupVideoApi.setAnnotations(scope.annotations)
            },

            /**
             * Automatically sync the time from the popup to the parent
             * @param {VG.Player} popupPlayer
             */
            autoSyncFrom: function (popupVideoApi: any) {
                popupVideoApi
                    .getPlayer()
                    .addEventListener('timeupdate', function (newTime: { sec: number }) {
                        parentVideoApi.$seek(newTime.sec)
                    })
            },
        }

        return ParentCommunication
    }
}
