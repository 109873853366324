import _ from 'lodash'
import markExceptionHandled from 'util/markExceptionHandled'

export default /* @ngInject */ function handleBackendResponseErrorsInterceptorFactory(
    $q: ng.IQService,
    $injector: any,
    $timeout: ng.ITimeoutService,
    Notification: any
) {
    const handleBackendResponseErrorsInterceptor = {
        responseError: function (rejection: { [key: string]: any }) {
            if (rejection.xhrStatus === 'abort') {
                return markExceptionHandled($q.reject(rejection))
            }

            /**
             * Handle 401 Unauthorized errors by logging out the user
             */
            if (rejection.status === 401) {
                const User = $injector.get('User')
                const RoutingAuth = $injector.get('RoutingAuth')

                if (User.isAuthenticated()) {
                    User.logout(true)

                    // in a timeout to make sure it superseeds all error notifications
                    $timeout(() => {
                        Notification.error('Session timed out')
                        RoutingAuth.authenticationRequiredHandler()
                    })
                }

                return markExceptionHandled($q.reject(rejection))
            }

            /**
             * Handle network and 500 erros as "Server errors"
             */
            if (rejection.status >= 500 && rejection.status < 600) {
                $timeout(() =>
                    Notification.error(
                        (rejection.data && rejection.data.reason) ||
                            'Server error. If this persist, please, contact an administrator.'
                    )
                )

                return markExceptionHandled($q.reject(rejection))
            }

            if (isCancelledHTTPRequest(rejection)) {
                return markExceptionHandled($q.reject(rejection))
            }

            if (!rejection.config) {
                throw rejection
            }

            return $q.reject(rejection)
        },
    }

    return handleBackendResponseErrorsInterceptor

    ///////////////////

    function isCancelledHTTPRequest(request: { [key: string]: any }) {
        return (
            _.get(request, 'status') === -1 &&
            _.get(request, 'config.timeout.$$state.status', 0) !== 0
        )
    }
}
