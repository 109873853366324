export default /* @ngInject */ function QAHighlightsServiceFactory(
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    $http: ng.IHttpService
) {
    const QAHighlightsService = {
        getData(id: string) {
            return $http.get(`/api/hit/qa/${id}`).then((res) => res.data)
        },

        setHighlights(hitID: string, highlights: any[]) {
            return $http.put(`/api/hit/qa/${hitID}/highlights`, highlights, NO_LOAD_OVERLAY)
        },

        submitHighlights(hitID: string, highlights: any[]) {
            return QAHighlightsService.setHighlights(hitID, highlights).then(() => {
                return $http.post(`/api/hit/qa/${hitID}/approve`, {})
            })
        },
    }

    return QAHighlightsService
}
