import _ from 'lodash'
import Immutable from 'immutable'
import invariant from 'util/invariant'

export default function MapCachedImmutableStateToThis(mappings, extraMapper = _.identity) {
    const cache = new Map()

    const mapStateToThis = function (state) {
        const output = _.reduce(
            mappings,
            (output, localKey, foreignKey) => {
                const immutable = _.get(state, foreignKey)
                invariant(
                    angular.isDefined(immutable),
                    '[MapCachedImmutableStateToThis] No data under key %s',
                    foreignKey
                )

                // handle non-immutable data
                if (!_.isFunction(immutable.toJS)) {
                    output[localKey] = angular.copy(immutable)
                    return output
                }

                if (
                    !cache.has(localKey) ||
                    !Immutable.is(cache.get(localKey).immutable, immutable)
                ) {
                    cache.set(localKey, {
                        immutable,
                        js: (immutable && immutable.toJS()) || [],
                    })
                }

                output[localKey] = angular.copy(cache.get(localKey).js)

                return output
            },
            {}
        )

        return extraMapper(output, state)
    }

    mapStateToThis.clear = function () {
        cache.clear()
    }

    return mapStateToThis
}
