export default /* @ngInject */ function statefulInputDirective(DOMUtility) {
    var directive = {
        restrict: 'E',
        require: ['^^?formGroup', '?ngModel'],
        link: statefulInputLinkFn,
    }

    return directive

    ////////////////////

    function statefulInputLinkFn(scope, element, attr, [formGroupCtrl, ngModelCtrl]) {
        // attach unique IDs to all input type elements
        if (!element.attr('id')) {
            element.attr('id', 'input_' + DOMUtility.nextUid())
        }

        // short circuit if not in .form-group
        if (!formGroupCtrl) {
            return
        }

        formGroupCtrl.input = element

        if (!ngModelCtrl) {
            inputCheckValue()
        } else {
            ngModelCtrl.$parsers.push(ngModelPipelineCheckValue)
            ngModelCtrl.$formatters.push(ngModelPipelineCheckValue)
        }

        element.on('input change', inputCheckValue)

        element.on('focus', function () {
            let timeoutId = setTimeout(function () {
                formGroupCtrl.setHasFocus(true)
                // chosen workaround
                element.trigger('chosen:open')
            }, 0)

            scope.$on('$destroy', () => clearTimeout(timeoutId))
        })
        element.on('blur', function () {
            let timeoutId = setTimeout(function () {
                formGroupCtrl.setHasFocus(false)
                inputCheckValue()
            }, 0)

            scope.$on('$destroy', () => clearTimeout(timeoutId))
        })

        scope.$on('$destroy', function () {
            formGroupCtrl.setHasValue(false)
            formGroupCtrl.setHasFocus(false)
            formGroupCtrl.input = null
        })

        ////////////////////////////

        function inputCheckValue() {
            let hasValue = element.val() && element.val().length > 0
            formGroupCtrl.setHasValue(hasValue)

            return hasValue
        }

        function ngModelPipelineCheckValue(value) {
            formGroupCtrl.setHasValue(!ngModelCtrl.$isEmpty(value))

            return value
        }
    }
}
