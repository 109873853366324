import { CommentInterfaceInstance } from './CommentInterface.factory'

interface ScopeBinding {
    hasOnlyGeneralThread: boolean | null
    commentThreads: any[]
    showCommentsListModal: (threads: any[]) => void
    openAllInOneCommentsModal: () => void
}

export default /* @ngInject */ function commentViewAllDirective(
    CommentInterface: CommentInterfaceInstance
) {
    const directive = {
        restrict: 'E',
        link: commentViewAllLinkFn,
        scope: {
            componentClass: '@?',
        },
        template: `
            <button
                ng-if="::!hasOnlyGeneralThread"
                ng-disabled="!commentThreads.length"
                ng-click="showCommentsListModal()"
                ng-class="componentClass" role="button"
            >
                    <span ng-if="commentThreads.length">
                        <i class="material-icons md-18">list</i>
                        View all comments
                    </span>
                    <span ng-if="!commentThreads.length">No comments yet</span>

            </button>

            <button
                ng-if="::hasOnlyGeneralThread"
                ng-click="openAllInOneCommentsModal()"
                ng-class="componentClass" role="button"
            >
                <i class="material-icons md-18">list</i>
                Comments
            </button>
        `,
    }

    return directive

    //////////////////////////////////

    function commentViewAllLinkFn(scope: ng.IScope & ScopeBinding) {
        scope.hasOnlyGeneralThread = CommentInterface.hasOnlyGeneralThread
        scope.commentThreads = CommentInterface.threads

        scope.showCommentsListModal = showCommentsListModal
        scope.openAllInOneCommentsModal = openAllInOneCommentsModal

        function showCommentsListModal() {
            CommentInterface.showCommentsListModal(scope.commentThreads)
        }

        function openAllInOneCommentsModal() {
            CommentInterface.getGeneralThread()
                ? CommentInterface.showGeneralThreadModal()
                : CommentInterface.showCreateGeneralThreadModal()
        }
    }
}
