import _ from 'lodash'
import extractId from 'util/extractId'
import GraphObject from 'directives/graph/GraphObject'

import { AdminModuleInstance } from './AdminModule.factory'
import { WORKFLOW_ELEMENTS, SESSION_STORAGE_SELECTED_PROJECT } from 'constants.es6'

type taskElementGroup = {
    name: string
    id: number
    elements: TaskElement[]
}
interface IAdminJobCreateCtrlInstance {
    workflow: Workflow
    workflowTemplates: WorkflowTemplate[]
    taskElementByGroup: taskElementGroup[]
    selectedWorkflowTask: TaskElement
    templateSelected: WorkflowTemplate | null
    workflowErrors: string[]

    selectWorkflowTask: (task: TaskElement) => void
    editWorkflowDetails: () => void
    checkWorkflow: () => void
    createWorkflow: () => void
    clearGraph: () => void

    openTemplateModal: () => void

    createTemplate: () => void
    selectTemplate: (template: WorkflowTemplate) => void
    deleteTemplate: (event: Event, template: WorkflowTemplate) => void

    isTaskLimitReached: (task: TaskElement) => boolean
}

export default /* @ngInject */ function AdminJobCreateCtrl(
    this: unknown,

    TASK_DEFINITIONS: any,

    $q: ng.IQService,
    $scope: ng.IScope,
    $state: ng.ui.IStateService,
    $uibModal: ng.ui.bootstrap.IModalService,

    AdminModule: AdminModuleInstance,
    Notification: any,
    MapDialog: any,
    GraphData: any,

    workflowTemplates: WorkflowTemplate[],

    commonOptions: CommonOptions
) {
    const vm = this as IAdminJobCreateCtrlInstance

    vm.workflowTemplates = workflowTemplates
    vm.taskElementByGroup = []

    vm.selectWorkflowTask = selectWorkflowTask
    vm.selectTemplate = selectTemplate
    vm.openTemplateModal = openTemplateModal
    vm.editWorkflowDetails = editWorkflowDetails

    vm.createTemplate = createTemplate
    vm.deleteTemplate = deleteTemplate

    vm.checkWorkflow = checkWorkflow
    vm.createWorkflow = createWorkflow

    vm.clearGraph = clearGraph
    vm.isTaskLimitReached = isTaskLimitReached

    activate()

    ////////////////////////////////////////

    function activate() {
        vm.templateSelected = null
        vm.workflowErrors = []
        vm.workflow = {
            priority: 10,
            owner: null,
            project: null,
            graph: {
                nodes: [],
                edges: [],
                $$globalNodeId: 0,
            },
        }

        // For when you need LIGHTSPEED
        // import('sample/taskElements.json').then((m) => {
        //     vm.taskElements = m.default
        // })
        // import('sample/workflow.json').then((m) => {
        //     vm.workflow = m.default
        // })

        $scope.$watch('vm.workflow.project', (projectUri: string) => {
            if (!projectUri) return

            AdminModule.getTaskElements(projectUri).then((elements) => {
                const projectId = projectUri.split('/').pop()
                sessionStorage.setItem(SESSION_STORAGE_SELECTED_PROJECT, projectId || projectUri)
                vm.taskElementByGroup = buildGroups(elements)
            })
        })

        editWorkflowDetails(/* allowClose */ false)
    }

    //////////////////////////////////

    function buildGroups(elements: TaskElement[]) {
        return [
            {
                name: 'Common Tools',
                id: 0,
                elements: _.filter(elements, (el) => {
                    return WORKFLOW_ELEMENTS.COMMON.includes(el.name)
                }),
            },
            {
                name: 'Basic Annotation',
                id: 1,
                elements: _.filter(elements, (el) => {
                    return WORKFLOW_ELEMENTS.BASIC.includes(el.name)
                }),
            },
            {
                name: 'Ad Markers',
                id: 2,
                elements: _.filter(elements, (el) => {
                    return WORKFLOW_ELEMENTS.MARKER.includes(el.name)
                }),
            },
            {
                name: 'Specialty Annotation',
                id: 3,
                elements: _.filter(elements, (el) => {
                    return WORKFLOW_ELEMENTS.SPECIAL.includes(el.name)
                }),
            },
        ]
    }

    function checkWorkflow() {
        return AdminModule.workflowCheck(vm.workflow)
            .then(() => {
                // remove (possibly) previously set error info
                vm.workflowErrors = []
                GraphData.clearErrorData(vm.workflow.graph, /* validated */ true)

                Notification.success('Task verified, no errors found!')
            })
            .catch((errorData) => {
                vm.workflowErrors = getWorkflowMessages(errorData, 'errors')
                GraphData.attachErrorData(vm.workflow.graph, errorData)

                const validationSummary = getValidationSummary(vm.workflow.graph, errorData)
                if (validationSummary.errors) {
                    Notification.error(validationSummary.errors)
                } else if (validationSummary.warnings) {
                    Notification.warning(validationSummary.warnings)
                }
            })
            .finally(() => {
                vm.workflow.graph.$$forceDraw = true
            })
    }

    function editWorkflowDetails(allowClose = true) {
        const $uibModalInstance = $uibModal.open({
            size: 'lg',
            keyboard: allowClose,
            backdrop: 'static',
            templateUrl: 'js/admin/createJobModal.tpl.html',
            resolve: {
                allowClose,
                originalWorkflow: _.constant(vm.workflow),
            },
            controllerAs: 'vm',
            controller: /* @ngInject */ function (
                $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
                $scope: ng.IScope,
                originalWorkflow: Workflow,
                allowClose: boolean
            ) {
                const vm = this as {
                    workflow: Workflow
                    projects: Project[]
                    groups: Group[]
                    selectedProject: Project | undefined
                    allowClose: typeof allowClose
                    newProject: { name: string | null }
                    save: typeof save
                    getProjectFor: typeof getProjectFor
                    getPriorityAsDays: typeof getPriorityAsDays
                }

                vm.workflow = angular.copy(originalWorkflow)

                vm.allowClose = allowClose
                vm.newProject = { name: null }
                vm.save = save
                vm.getProjectFor = getProjectFor
                vm.getPriorityAsDays = getPriorityAsDays

                $scope.$watch('vm.workflow.owner', (groupUri) => {
                    if (!groupUri) {
                        vm.projects = commonOptions.projects
                    } else {
                        vm.projects = _.get(
                            _.find(commonOptions.groups, { uri: groupUri }),
                            'projects',
                            []
                        )
                    }
                })

                $scope.$watch('vm.workflow.project', (projectUri) => {
                    if (!projectUri) {
                        vm.groups = commonOptions.groups
                    } else {
                        vm.groups = _.get(
                            _.find(commonOptions.projects, { uri: projectUri }),
                            'groups',
                            []
                        )
                    }
                })

                ///////////////////////////

                function getPriorityAsDays() {
                    return _.get(
                        _.find(commonOptions.priority_days, { priority: vm.workflow.priority }),
                        'days',
                        null
                    )
                }

                function save() {
                    const projectOrOwnerChanged =
                        (!!originalWorkflow.project &&
                            vm.workflow.project !== originalWorkflow.project) ||
                        (!!originalWorkflow.owner && vm.workflow.owner !== originalWorkflow.owner)

                    const mwdNodes = GraphObject.nodeFindAll(vm.workflow.graph, {
                        info: { wizards: { template: 'MWD' } },
                    })
                    // if project/owner did not change or we have no MWD nodes
                    if (!projectOrOwnerChanged || !mwdNodes.length) {
                        // we can resolve with our workflow
                        return $uibModalInstance.close(vm.workflow)
                    }

                    // otherwize, MWD node configs will be reset and we need user confirmation
                    const confirmDialog = MapDialog.confirm()
                        .title('Confirm project/group change')
                        .textContent(
                            `
                            You have changed job project and/or group.
                            This will reset you MWD box configs.
                            Are you sure you want to proceed?
                        `
                        )
                        .ok('Yes')
                        .cancel('No')

                    MapDialog.show(confirmDialog).then(() => {
                        vm.workflow.graph = GraphData.resetNodeConfig(vm.workflow.graph, mwdNodes)
                        $uibModalInstance.close(vm.workflow)
                    })
                }

                function getProjectFor(projectUri: string) {
                    vm.selectedProject = _.find(vm.projects, { uri: projectUri })
                }
            },
        })

        $uibModalInstance.result
            .then((workflow) => {
                vm.workflowErrors = []
                vm.workflow = workflow
            })
            .catch(() => {
                if (!allowClose) {
                    $state.go('admin.dashboard')
                }
            })
    }

    function createWorkflow() {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you are ready to start this job?')
            .ok('Yes')
            .cancel('No')

        MapDialog.show(confirmDialog).then(() => {
            vm.workflowErrors = []
            GraphData.clearErrorData(vm.workflow.graph)

            const saveJobPromise = AdminModule.workflowCreate(vm.workflow)
                .then(() => $state.go('admin.jobs'))
                .catch((errorData) => {
                    vm.workflowErrors = getWorkflowMessages(errorData, 'errors')
                    GraphData.attachErrorData(vm.workflow.graph, errorData)

                    const validationSummary = getValidationSummary(vm.workflow.graph, errorData)
                    return $q.reject(validationSummary.errors)
                })
                .finally(() => {
                    vm.workflow.graph.$$forceDraw = true
                })

            Notification.forPromise(saveJobPromise, 'Successfully created Job.')
        })
    }

    function openTemplateModal() {
        $uibModal.open({
            size: 'lg',
            template: `
                <div class="container-fluid">
                    <header class="modal-header row d-flex align-items-center justify-content-between">
                        <h4 class="mb-0">Templates</h4>
                        <form class="form-inline">
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"
                                ng-model="templateSearchQuery"
                            />
                        </form>
                    </header>
                    <section class="row">
                        <table class="table talbe-striped">
                            <thead>
                                <tr>
                                    <th table-order by="name" single no-preserve
                                        predicate="vm.predicateJobs"
                                    >Name</th>
                                    <th>
                                        <div class="d-flex align-items-center">
                                            <section table-order by="label" single no-preserve
                                                predicate="vm.predicateJobs"
                                            >
                                                Group
                                            </section>
                                            <field-filter
                                                items="vm.workflowTemplates"
                                                field="label"
                                                title="Group"
                                                predicate="vm.perfieldPredicate" no-preserve
                                            ></field-filter>
                                        </div>
                                    </th>
                                    <th table-order by="description" single no-preserve
                                        predicate="vm.predicateJobs"
                                    >Description</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr ng-repeat="template in vm.workflowTemplates
                                    | orderBy: vm.predicateJobs
                                    | filter: templateSearchQuery
                                    | perfieldFilter: vm.perfieldPredicate"
                                >
                                    <td>{{ ::template.name }}</td>
                                    <td>{{ ::template.label }}</td>
                                    <td>{{ ::template.description }}</td>
                                    <td>
                                        <button ng-click="vm.selectTemplate(template)"
                                            class="btn btn-sm btn-primary ml-auto py-0 mb-2" type="button"
                                        >Select</button>
                                        <button ng-click="vm.deleteTemplate($event, template)"
                                            type="button" class="btn btn-sm btn-outline-danger ml-auto py-0"
                                        >Delete</button>
                                    </td>
                                </tr>
                                <tr ng-if="!vm.workflowTemplates.length">
                                    <td colspan="99">No results</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            `,
            controller: /* @ngInject */ function (
                this: unknown,
                $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
                parentVm: typeof vm
            ) {
                const vm = this as {
                    selectTemplate: (template: WorkflowTemplate) => void
                    deleteTemplate: (event: Event, template: WorkflowTemplate) => void
                    workflowTemplates: WorkflowTemplate[]
                    templateSelected: WorkflowTemplate | null
                }

                vm.selectTemplate = selectTemplate
                vm.deleteTemplate = parentVm.deleteTemplate
                vm.workflowTemplates = parentVm.workflowTemplates
                vm.templateSelected = parentVm.templateSelected

                function selectTemplate(template: WorkflowTemplate) {
                    parentVm.selectTemplate(template)

                    vm.templateSelected = template

                    $uibModalInstance.close()
                }
            },
            controllerAs: 'vm',
            resolve: {
                parentVm: vm,
            },
        })
    }

    function createTemplate() {
        const templateSelected = vm.templateSelected
        const graph = {
            nodes: angular.copy(vm.workflow.graph.nodes),
            edges: angular.copy(vm.workflow.graph.edges),
        }

        $uibModal.open({
            templateUrl: 'js/admin/createTemplateModal.tpl.html',
            backdrop: 'static',
            controller: /* @ngInject */ function (
                $scope: ng.IScope & {
                    template: WorkflowTemplate & WorkflowModel
                    groups: Group[]
                    originalTemplate: WorkflowTemplate | null
                    submit: (form: ng.IFormController) => void
                    overwriteTemplate: () => void
                },
                $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance
            ) {
                $scope.template = {
                    ...graph,
                    description: '',
                    owner: null,
                    name: '',
                    uri: '',
                    templateId: '',
                }

                $scope.groups = commonOptions.groups
                $scope.originalTemplate = templateSelected

                $scope.submit = (form) => {
                    if (!form.$valid) {
                        return
                    }

                    const saveTemplatePromise = AdminModule.saveWorkflowTemplate($scope.template)

                    saveTemplatePromise.then((data) => {
                        $scope.template.uri = data.uri
                        $scope.template.templateId = extractId(data.uri) || data.uri
                        vm.workflowTemplates.push($scope.template)
                    })

                    Notification.forPromise(saveTemplatePromise, 'Template successfully saved')

                    $uibModalInstance.close()
                }

                $scope.overwriteTemplate = function () {
                    if (!$scope.originalTemplate) {
                        return
                    }

                    $scope.template.uri = $scope.originalTemplate.uri
                    $scope.template.templateId = $scope.originalTemplate.templateId
                    const overwriteTemplatePromise = AdminModule.overwriteTemplate($scope.template)

                    Notification.forPromise(
                        overwriteTemplatePromise,
                        'Overwrite successfully saved'
                    )

                    $uibModalInstance.close()
                }
            },
        })
    }

    function clearGraph() {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to clear the graph?')
            .ok('Yes')
            .cancel('No')

        MapDialog.show(confirmDialog).then(() => {
            vm.workflow.graph = { nodes: [], edges: [], $$globalNodeId: 0 }
        })
    }

    function deleteTemplate(event: Event, template: WorkflowTemplate) {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to delete this template?')
            .ok('Yes')
            .cancel('No')

        event.stopPropagation()

        MapDialog.show(confirmDialog)
            .then(function () {
                const deleteTemplatePromise = AdminModule.deleteTemplate(template.uri)

                Notification.forPromise(deleteTemplatePromise, 'Template successfully deleted')

                return deleteTemplatePromise
            })
            .then(function () {
                _.remove(vm.workflowTemplates, template)
            })
    }

    function selectWorkflowTask(task: TaskElement) {
        vm.selectedWorkflowTask = task
    }

    function selectTemplate(template: WorkflowTemplate) {
        vm.templateSelected = template

        AdminModule.getSingleWorkflowTemplate(template.templateId, vm.workflow.project || '').then(
            (template) => {
                vm.workflowErrors = []
                vm.workflow.graph = {
                    nodes: template.nodes,
                    edges: template.edges,
                    $$globalNodeId: _.max(_.map(template.nodes, 'id')) || 0,
                }
            }
        )
    }

    function isTaskLimitReached(task: TaskElement) {
        const tasksInGraph = _.filter(_.get(vm.workflow.graph, 'nodes', []), [
            'info.name',
            task.name,
        ])
        let definition = TASK_DEFINITIONS[task.name]
        if (definition && _.isArray(definition)) {
            definition = definition[0]
        }

        return _.get(definition, 'limitTo', Number.MAX_SAFE_INTEGER) <= tasksInGraph.length
    }

    function getValidationSummary(graph: Graph, errorData: WorkflowBackendErrorData) {
        return {
            errors: getValidationSummaryFor(graph, errorData, 'errors'),
            warnings: getValidationSummaryFor(graph, errorData, 'warnings'),
        }

        /////////////////////////////////

        function getValidationSummaryFor(
            graph: Graph,
            errorData: WorkflowBackendErrorData,
            type: WorkflowErrorType
        ) {
            const lines = []

            const workflowMessages = getWorkflowMessages(errorData, type)
            if (workflowMessages.length > 0) {
                if (workflowMessages.length === 1) {
                    lines.push(`Workflow ${_.capitalize(type)}: ${workflowMessages[0]}`)
                } else {
                    lines.push(
                        `Workflow ${_.capitalize(type)}: ${workflowMessages.length} ${type} found.`
                    )
                }
            }

            _.forEach(_.get(graph, 'nodes', []), (node) => {
                const validationMessages = _.get(node, type, [])
                if (validationMessages.length > 0) {
                    if (validationMessages.length === 1) {
                        lines.push(`${node.info.title}: ${validationMessages[0]}`)
                    } else {
                        lines.push(
                            `${node.info.title}: ${validationMessages.length} ${type} found.`
                        )
                    }
                }
            })

            return lines.join('<br>')
        }
    }

    function getWorkflowMessages(errorData: WorkflowBackendErrorData, type: WorkflowErrorType) {
        return _.map(_.filter(_.get(errorData, type), { id: -1 }), 'msg')
    }
}

AdminJobCreateCtrl.controllerAs = 'vm'

AdminJobCreateCtrl.resolve = {
    workflowTemplates: /* @ngInject */ function (AdminModule: AdminModuleInstance) {
        return AdminModule.getWorkflowTemplates()
    },

    commonOptions: /* @ngInject */ function (AdminModule: AdminModuleInstance) {
        return AdminModule.getCommonOptions()
    },
}
