import LoginCtrl from './Login.ctrl'
import ChooseRoleCtrl from './ChooseRole.ctrl'

export default /* @ngInject */ function authRoutesConfig($stateProvider: ng.ui.IStateProvider) {
    // define auth states
    $stateProvider
        .state('auth', {
            url: '/auth',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('auth.login', {
            url: '/login',
            templateUrl: 'js/auth/login.html',
            controller: LoginCtrl,
            controllerAs: 'loginVm',
            data: { public: true, allowRemember: false },
        })
        .state('auth.logout', {
            url: '/logout',
            /*eslint angular/controller-as-route: "off"*/
            controller: /* @ngInject */ function (User: any, $state: ng.ui.IStateService) {
                User.logout().then(function () {
                    $state.go('auth.login')
                })
            },
            data: { public: true, allowRemember: false },
        })
        .state('auth.denied', {
            url: '/denied',
            template: '<h1>Access Denied</h1>',
            data: { allowRemember: false },
        })
        .state('auth.chooseSessionRole', {
            url: '/choose-role',
            templateUrl: 'js/auth/choose-role.html',
            controller: ChooseRoleCtrl,
            controllerAs: 'chooseRoleVm',
            data: { allowRemember: false },
        })
        .state('auth.errorMultipleVideosPlaying', {
            templateUrl: 'js/auth/error-multiple-videos-playing.html',
            data: { allowRemember: false, navHidden: false },
        })
    // TODO find out if this used to work at some point?
    // .state('messages', {
    //     url: '/internal-messaging/',
    //     templateUrl: 'js/messages/template.html',
    //     controller: 'InternalMessagesCtrl',
    //     controllerAs: 'imVm',
    //     data: { public: true, allowRemember: false },
    // })
    // .state('messages.open', {
    //     url: '/internal-messaging/open/:commentId',
    //     templateUrl: 'js/messages/template.html',
    //     controller: 'InternalMessagesCtrl',
    //     controllerAs: 'imVm',
    //     data: { public: true, allowRemember: false },
    // })
}
