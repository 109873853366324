import fastdom from 'fastdom'

export type Task = () => void
type WrappedTask<T> = T

/**
 * A wrapper around fastdom that allows the user to create pockets of fastdom
 * tasks and clear them all in one go
 */
export default class FastdomWrapper {
    tasks: Task[] = []
    $$destroyed = false

    /**
     * @param fn
     * @param ctx the context to be bound to `fn` (optional)
     *
     * @return the (optionally bound) fn
     */
    measure<T extends Task>(fn: T, ctx?: any): WrappedTask<T> | undefined {
        if (this.$$destroyed === true) {
            return
        }

        const task = this.$wrap(fn, ctx)

        this.tasks.push(task)
        fastdom.measure(task)

        return task
    }

    /**
     * @param fn
     * @param ctx the context to be bound to `fn` (optional)
     *
     * @return the (optionally bound) fn
     */
    mutate<T extends Task>(fn: T, ctx?: any): WrappedTask<T> | undefined {
        if (this.$$destroyed === true) {
            return
        }

        const task = this.$wrap(fn, ctx)

        this.tasks.push(task)
        fastdom.mutate(task)

        return task
    }

    /**
     * @param task A previously measure/mutate given `fn`
     * @return Whether a task was removed from the queue
     */
    clear<T extends Task>(task: T): boolean {
        remove(this.tasks, task)

        return fastdom.clear(task)
    }

    /**
     * Clear all fastdom tasks that were created from this instance
     */
    clearAll(): void {
        let index = -1
        while (++index < this.tasks.length) {
            fastdom.clear(this.tasks[index])
        }
        this.tasks.length = 0
    }

    /**
     * Clear all tasks and prevent new ones from being created
     */
    destroy() {
        this.clearAll()
        this.$$destroyed = true
    }

    $wrap<T extends Task>(fn: T, ctx?: any): WrappedTask<T> {
        const that = this
        fn = !ctx ? fn : (fn.bind(ctx) as T)

        return function wrappedTask() {
            remove(that.tasks, wrappedTask)
            fn()
        } as WrappedTask<T>
    }
}

/**
 * Remove an item from an array
 *
 * @param array
 * @param item
 * @return  Whether an item was removed from the array
 */
function remove(array: any[], item: any): boolean {
    const index = array.indexOf(item)
    return !!~index && !!array.splice(index, 1)
}
