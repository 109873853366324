import _ from 'lodash'

import { ValuesType } from 'utility-types'
import { definitionPreair } from '../VIDEO_TYPE_DEFINITIONS.constant'

type CutValue = ValuesType<typeof definitionPreair.cutTypeOptions>['value']

type ScopeBindings = {
    cutValue: CutValue
    ngModel: string
}

export default /* @ngInject */ function episodeTitleByCutTypeDirective() {
    const directive = {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            cutValue: '=',
            ngModel: '=',
        },
        transclude: true,
        link: episodeTitleByCutTypeLinkFn,
    }

    return directive

    /////////////////////////////////////

    function episodeTitleByCutTypeLinkFn(scope: ng.IScope & ScopeBindings) {
        const VALUE_UNKNOWN = '### SELECT CUT TYPE TO GENERATE EPISODE TITLE ###'
        const episodeOptions = definitionPreair.episodeTitleMap

        scope.$watch('cutValue', function (cutValue: CutValue) {
            scope.ngModel = _.get(episodeOptions, cutValue) || VALUE_UNKNOWN
        })
    }
}
