const qrCodeComponent = {
    controller: qrCodePopupController,
    bindings: {
        url: '@',
    },
    template: `
        <button
            ng-click="$ctrl.showQRModal($ctrl.url)"
            class="btn btn-primary m-1"
        >
            Work on it
        </button>
    `,
}

export default qrCodeComponent

interface ScopeBindings extends ng.IComponentController {
    url: string
}

/* @ngInject */ function qrCodePopupController(
    this: unknown,
    $uibModal: ng.ui.bootstrap.IModalService
) {
    const $ctrl = this as ScopeBindings
    $ctrl.showQRModal = showQRModal

    function showQRModal(url: string) {
        $uibModal.open({
            template: `<img src="${url}"/>`,
            size: 'lg',
        })
    }
}
