import constants from '../constants'

import ngRedux from 'ng-redux'

import {
    HighlightActionsFactory,
    HighlightActionCreatorsFactory,
    HighlightsStateActionsFactory,
    HighlightsStateActionCreatorsFactory,
    HighlightsReducerFactory,
    HighlightsStateReducerFactory,
    CombinedHighlightsReducerFactory,
} from './HighlightsReducer'
import highlightTimelineComponent from './highlightTimeline.component'

const map3highlights = angular
    .module('map3.highlights', [constants, ngRedux])

    .factory('HighlightActions', HighlightActionsFactory)
    .factory('HighlightActionCreators', HighlightActionCreatorsFactory)

    .factory('HighlightsStateActions', HighlightsStateActionsFactory)
    .factory('HighlightsStateActionCreators', HighlightsStateActionCreatorsFactory)

    .factory('HighlightsReducer', HighlightsReducerFactory)
    .factory('HighlightsStateReducer', HighlightsStateReducerFactory)
    .factory('CombinedHighlightsReducer', CombinedHighlightsReducerFactory)

    .component('highlightTimeline', highlightTimelineComponent)

export default map3highlights.name
