import _ from 'lodash'
import fp from 'lodash/fp'
import softInvariant from 'util/softInvariant'
import { v4 as uuid4 } from 'uuid'
import { hasSearchMatch, filterMarkers } from 'util/answersFilterBySearch'

import Storage from '../services/Storage'
import { ANNOTATIONS_JOB_TYPE, HIGHLIGHTS_JOB_TYPE } from './results.constants'

export default /* @ngInject */ function ResultsViewerVideoCtrl(
    $scope,
    Notification,
    MarkerTransformation,
    DOMUtility,
    ResultsModule,
    VideoService,

    videoId
) {
    const resultsVm = this

    resultsVm.loading = true

    resultsVm.getQuestionDetailFor = getQuestionDetailFor
    resultsVm.getHighlightGroupsForJob = getHighlightGroupsForJob
    resultsVm.getAnnotationGroupsForJob = getAnnotationGroupsForJob
    resultsVm.getProjectForJob = getProjectForJob
    resultsVm.getGroupForJob = getGroupForJob

    resultsVm.filterJobsBySearch = filterJobsBySearch
    resultsVm.filterAnnotationsBySearch = filterAnnotationsBySearch
    resultsVm.filterAnnotationsGroupsBySearch = filterAnnotationsGroupsBySearch
    resultsVm.filterHighlightsBySearch = filterHighlightsBySearch

    resultsVm.triggerSideBySide = triggerSideBySide

    resultsVm.sideBySide = Storage.getOrUseDefault('map3_side_by_side_preference', false)
    resultsVm.searchString = ''

    activate()

    //////////////////////////////

    function activate() {
        const promise = ResultsModule.getTaskDetails(videoId)
            .then(processVideo)
            .then(updateDataAfterRequest)
        Notification.forPromise(promise)

        $scope.$watch('resultsVm.searchString', filterMarkersBySearch)
    }

    function filterJobsBySearch(job) {
        if (job.type === ANNOTATIONS_JOB_TYPE) {
            return filterAnnotationsGroupsBySearch(job)
        } else if (job.type === HIGHLIGHTS_JOB_TYPE) {
            return filterHighlightsGroupBySearch(job)
        } else {
            return true
        }
    }

    function filterHighlightsBySearch(highlight) {
        if (!resultsVm.searchString) {
            return true
        }
        const search = resultsVm.searchString.toLowerCase()
        const freeLabels = highlight.labels_free
        const controlledLabels = highlight.labels_controlled.map((cl) => {
            return cl.label
        })
        const hasFreeLabelsMatch = _.some(freeLabels, (value) =>
            _.includes(value.toLowerCase(), search)
        )
        const hasControlledLabelsMatch = _.some(controlledLabels, (value) =>
            _.includes(value.toLowerCase(), search)
        )
        return hasFreeLabelsMatch || hasControlledLabelsMatch
    }

    function filterHighlightsGroupBySearch(job) {
        return _.some(resultsVm.getHighlightGroupsForJob(job), (group) => {
            return _.some(group.highlights, (highlight) => {
                return filterHighlightsBySearch(highlight)
            })
        })
    }

    function filterAnnotationsBySearch(annotation) {
        return _.some(annotation.tags, (tag) => {
            if (!tag) return

            return hasSearchMatch(tag, resultsVm.searchString)
        })
    }

    function filterAnnotationsGroupsBySearch(job) {
        return _.some(resultsVm.getAnnotationGroupsForJob(job), (group) => {
            return _.some(group.annotations, (annotation) => {
                return filterAnnotationsBySearch(annotation)
            })
        })
    }

    function triggerSideBySide() {
        resultsVm.sideBySide = !resultsVm.sideBySide
        Storage.setItem('map3_side_by_side_preference', resultsVm.sideBySide)
        $scope.$broadcast('resize')
    }

    function processVideo(taskDetails) {
        return VideoService.processVideo(taskDetails.video).then((processedVideo) => {
            taskDetails.video = processedVideo
            return taskDetails
        })
    }

    function updateDataAfterRequest(taskDetails) {
        resultsVm.video = taskDetails.video
        resultsVm.scenes = taskDetails.scenes
        resultsVm.annotationGroups = prepareAnnotations(taskDetails.annotations)
        resultsVm.allMarkers = generateMarkersList(resultsVm.annotationGroups)
        resultsVm.filteredMarkers = resultsVm.allMarkers
        resultsVm.highlightGroups = prepareHighlightGroups(taskDetails.cutntag)
        resultsVm.authorities = taskDetails.authorities

        resultsVm.allJobs = _.uniqBy(
            _.map(resultsVm.annotationGroups, (g) => {
                return { ...g.job, type: ANNOTATIONS_JOB_TYPE }
            }).concat(
                _.map(resultsVm.highlightGroups, (g) => {
                    return { ...g.job, type: HIGHLIGHTS_JOB_TYPE }
                })
            ),
            'id'
        )
        resultsVm.uniqueGroups = _.uniqBy(
            _.map(resultsVm.annotationGroups, 'group').concat(
                _.map(resultsVm.highlightGroups, 'group'),
                _.map(resultsVm.authorities, 'group'),
                _.map(resultsVm.scenes, 'group')
            ),
            'id'
        )
        resultsVm.uniqueProjects = _.uniqBy(
            _.map(resultsVm.annotationGroups, 'project').concat(
                _.map(resultsVm.highlightGroups, 'project'),
                _.map(resultsVm.authorities, 'project'),
                _.map(resultsVm.scenes, 'project')
            ),
            'id'
        )

        resultsVm.filter = {
            groupId: null,
            projectId: null,
        }
        resultsVm.loading = false
    }

    function getQuestionDetailFor(questions, answerDetail) {
        return _.find(questions, { uri: answerDetail.questionURI })
    }

    function getHighlightGroupsForJob(job) {
        return _.filter(
            resultsVm.highlightGroups,
            (group) => _.get(group, 'job.id', NaN) === _.get(job, 'id', NaN)
        )
    }

    function getAnnotationGroupsForJob(job) {
        return _.filter(
            resultsVm.annotationGroups,
            (group) => _.get(group, 'job.id', NaN) === _.get(job, 'id', NaN)
        )
    }

    function getProjectForJob(job) {
        const allGroups = _.concat(resultsVm.highlightGroups, resultsVm.annotationGroups)
        let group = _.find(
            allGroups,
            (group) => _.get(group, 'job.id', NaN) === _.get(job, 'id', NaN)
        )

        softInvariant(group, 'Cannot find project for job: %s', angular.toJson(job))
        return _.get(group, 'project')
    }

    function getGroupForJob(job) {
        const allGroups = _.concat(resultsVm.highlightGroups, resultsVm.annotationGroups)
        let group = _.find(
            allGroups,
            (group) => _.get(group, 'job.id', NaN) === _.get(job, 'id', NaN)
        )

        softInvariant(group, 'Cannot find project for job: %s', angular.toJson(job))
        return _.get(group, 'group')
    }

    function prepareAnnotations(annotationGroups) {
        return _.map(annotationGroups, (annotationGroup) => {
            annotationGroup.$mainQuestions = fp.flow(
                fp.flatMap('tags'),
                fp.uniqBy('questionURI'),
                fp.map((mainQuestion) =>
                    _.find(annotationGroup.questions, { uri: mainQuestion.questionURI })
                )
            )(annotationGroup.annotations)

            annotationGroup.$mainQuestionUris = _.map(annotationGroup.$mainQuestions, 'uri')

            annotationGroup.annotations.forEach((a) => {
                a.matchingAnnotationClass = uuid4()
            })

            return annotationGroup
        })
    }

    function prepareHighlightGroups(highlightGroups) {
        return _.map(highlightGroups, (highlightGroup) => {
            highlightGroup.$mainQuestions = fp.flow(
                fp.flatMap('tags'),
                fp.flatMap('tags'),
                fp.uniqBy('questionURI'),
                fp.map((mainQuestion) =>
                    _.find(highlightGroup.questions, { uri: mainQuestion.questionURI })
                )
            )(highlightGroup.highlights)

            highlightGroup.$mainQuestionUris = _.map(highlightGroup.$mainQuestions, 'uri')

            return highlightGroup
        })
    }

    function generateMarkersList(annotations) {
        // get markers of all annotation groups
        return fp.flow(
            fp.map((group) => _.map(group.annotations, annotationToMarker)),
            fp.flatten,
            fp.filter(MarkerTransformation.validMarker)
        )(annotations)

        /////////////////////////////////

        function annotationToMarker(annotation) {
            return {
                tag: {
                    answers: annotation.tags,
                },
                time: annotation.timestamp,
                text: 'Annotation',
                onClick: markerOnClick,
                matchingAnnotationClass: annotation.matchingAnnotationClass,
                markerClass: 'marker-annotation',
            }
        }
    }

    function filterMarkersBySearch() {
        resultsVm.filteredMarkers =
            resultsVm.searchString === ''
                ? resultsVm.allMarkers
                : filterMarkers(resultsVm.allMarkers, resultsVm.searchString)
    }

    function markerOnClick(e, marker, player) {
        player.seekSec(marker.time)

        if (marker.matchingAnnotationClass) {
            let el = document.getElementsByClassName(marker.matchingAnnotationClass)[0]
            el.scrollIntoView()
            DOMUtility.triggerAnimation(el, 'trigger-highlight')
        }
    }
}

ResultsViewerVideoCtrl.controllerAs = 'resultsVm'

ResultsViewerVideoCtrl.resolve = {
    videoId: /* @ngInject */ function ($stateParams) {
        return $stateParams.videoId
    },
}
