import _ from 'lodash'

export default /* @ngInject */ function objectFilterFilter($filter) {
    let filterFilter = $filter('filter')

    return function (object, expression) {
        let keys = _.keys(object)
        let filteredKeys = filterFilter(keys, expression)

        return _.pick(object, filteredKeys)
    }
}
