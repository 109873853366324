import _ from 'lodash'
import { ActionCreators } from 'redux-undo'

const highlightTimelineComponent = {
    controller: highlightTimelineController,
    bindings: {
        filmstripType: '<?',
        renderMode: '<?',
        highlights: '<?',
        selectedHighlightId: '=',
        editMode: '<',
    },
    template: `
        <generic-timeline
            elements="$ctrl.elements"
            on-single-select="$ctrl.handleOnSingleSelect(element)"
            on-resize="$ctrl.handleOnResize(resizeData)"
            on-multi-select="$ctrl.handleOnMultiSelect(indexRange)"
            filmstrip-type="$ctrl.filmstripType"
            render-mode="$ctrl.renderMode"
        ></generic-timeline>
    `,
}

export default highlightTimelineComponent

/* @ngInject */
function highlightTimelineController(
    $element,
    $scope,
    $ngRedux,

    SharedVideoAPI,
    GlobalShortcuts,
    HighlightActions,
    HighlightActionCreators,
    HighlightsReducer
) {
    const $ctrl = this

    $ctrl.$onInit = $onInit

    $ctrl.handleOnSingleSelect = handleOnSingleSelect
    $ctrl.handleOnMultiSelect = handleOnMultiSelect
    $ctrl.handleOnResize = handleOnResize

    return $ctrl

    ////////////////////////////////

    function $onInit() {
        $element.addClass('generic-timeline-holder')

        $ctrl.editMode = $ctrl.editMode !== false
        SharedVideoAPI.onLoad(handleVideoApiReady)
    }

    function handleVideoApiReady(videoApi) {
        $ctrl.videoApi = videoApi

        if ($ctrl.editMode) {
            const unsubscribe = $ngRedux.connect(mapStateToThis)($ctrl)
            $scope.$on('$destroy', unsubscribe)
        } else {
            const initAction = HighlightActionCreators.init({
                highlights: $ctrl.highlights,
                videoApi: $ctrl.videoApi,
            })
            const { highlights } = HighlightsReducer(undefined, initAction)
            $ctrl.highlights = highlights
        }

        $scope.$watch('$ctrl.highlights', buildElements)
        $scope.$watch('$ctrl.highlightsState', buildElements)

        setupShortcuts()

        ///////////////////////////////

        function mapStateToThis(
            {
                highlightsData: {
                    highlightsState, // extract highlightsState
                    undoableHighlights: {
                        present: { highlights },
                    },
                },
            } // extract highlights
        ) {
            const selected = _.filter(highlights, { $$isSelected: true })

            return {
                highlights,
                highlightsState,
                selectedHighlightId: selected.length === 1 ? selected[0].id : null,
            }
        }
    }

    function setupShortcuts() {
        const viewShortcuts = [
            {
                description: 'Next Highlight',
                keyCombo: ']',
                shortcut: ']',
                callback: () => {
                    selectNextHighlight()
                    goToSelectedHighlightStart()
                },
            },
            {
                description: 'Previous Highlight',
                keyCombo: '[',
                shortcut: '[',
                callback: () => {
                    selectPreviousHighlight()
                    goToSelectedHighlightStart()
                },
            },
            {
                description: 'Jump To Highlight Start',
                keyCombo: 'a',
                shortcut: 'A',
                callback: () => goToSelectedHighlightStart(),
            },
            {
                description: 'Jump To Highlight End',
                keyCombo: 'd',
                shortcut: 'D',
                callback: () => goToSelectedHighlightEnd(),
            },
        ]

        const editShortcuts = [
            {
                description: 'Create',
                keyCombo: ['x'],
                shortcut: 'X',
                callback: () => createHighlight(),
            },
            {
                description: 'Cancel create',
                keyCombo: ['escape'],
                shortcut: 'Esc',
                callback: () => cancelCreateHighlight(),
            },
            {
                description: 'Delete',
                keyCombo: ['del', 'backspace'],
                shortcut: 'Del',
                callback: () => deleteSelectedHighlight(),
            },
            {
                description: 'Merge Selected Highlights',
                keyCombo: 'm',
                shortcut: 'M',
                callback: () => HighlightActions.mergeSelected(),
            },
            {
                description: 'Move Highlight Start Forward By One Frame',
                global: true,
                keyCombo: ['ctrl+right', 'meta+right'],
                shortcut: 'control+Right Arrow',
                callback: () => moveSelectedHighlightStart(1),
            },
            {
                description: 'Move Highlight Start Backward By One Frame',
                global: true,
                keyCombo: ['ctrl+left', 'meta+left'],
                shortcut: 'control+Left Arrow',
                callback: () => moveSelectedHighlightStart(-1),
            },
            {
                description: 'Move Highlight End Forward By One Frame',
                global: true,
                keyCombo: ['alt+right'],
                shortcut: 'Alt+Right Arrow',
                callback: () => moveSelectedHighlightEnd(1),
            },
            {
                description: 'Move Highlight End Backward By One Frame',
                global: true,
                keyCombo: ['alt+left'],
                shortcut: 'Alt+Left Arrow',
                callback: () => moveSelectedHighlightEnd(-1),
            },
            {
                description: 'Undo',
                global: true,
                keyCombo: ['ctrl+z', 'meta+z'],
                shortcut: 'control+Z',
                callback: function () {
                    $ngRedux.dispatch(ActionCreators.undo())
                },
            },
            {
                description: 'Redo',
                global: true,
                keyCombo: ['ctrl+y', 'meta+y'],
                shortcut: 'control+Y',
                callback: function () {
                    $ngRedux.dispatch(ActionCreators.redo())
                },
            },
        ]

        const shortcuts = viewShortcuts.concat($ctrl.editMode ? editShortcuts : [])

        const unbind = GlobalShortcuts.bind({ title: 'Highlight Shortcuts', shortcuts })
        $scope.$on('$destroy', unbind)

        ///////////////////////////////////////

        function selectNextHighlight() {
            HighlightActions.selectNext({ currentFrame: $ctrl.videoApi.getCurrentFrame() })
        }

        function selectPreviousHighlight() {
            HighlightActions.selectPrevious({ currentFrame: $ctrl.videoApi.getCurrentFrame() })
        }

        function moveSelectedHighlightStart(offsetFrames) {
            HighlightActions.moveStartFrame({ id: $ctrl.selectedHighlightId, offsetFrames })
            goToSelectedHighlightStart()
        }

        function moveSelectedHighlightEnd(offsetFrames) {
            HighlightActions.moveEndFrame({ id: $ctrl.selectedHighlightId, offsetFrames })
            goToSelectedHighlightEnd()
        }

        // edit mode

        function createHighlight() {
            HighlightActions.create({ frameNumber: $ctrl.videoApi.getCurrentFrame() })
        }

        function cancelCreateHighlight() {
            HighlightActions.cancelProvisional()
        }

        function deleteSelectedHighlight() {
            HighlightActions.deleteById({ id: $ctrl.selectedHighlightId })
        }
    }

    function goToSelectedHighlightStart() {
        const highlight = _.find($ctrl.highlights, { id: $ctrl.selectedHighlightId })
        if (highlight) {
            $ctrl.videoApi.seekFrame(highlight.startFrame)
        }
    }

    function goToSelectedHighlightEnd() {
        const highlight = _.find($ctrl.highlights, { id: $ctrl.selectedHighlightId })
        if (highlight) {
            $ctrl.videoApi.seekFrame(highlight.endFrame)
        }
    }

    function buildElements() {
        const highlights = $ctrl.highlights
        const elements = []
        const lastFrameIdx = $ctrl.videoApi.getLastFrameIdx()
        let lastUid = 0
        let lastElementStart = 0
        let lastElementEnd = 0

        elements.push(buildSpacerElement(0, 0))

        _.forEach(highlights, function (highlight) {
            const addFrame = lastElementStart !== lastElementEnd ? 1 : 0
            elements.push(
                buildSpacerElement(lastElementEnd + addFrame, Math.max(0, highlight.startFrame - 1))
            )

            lastElementStart = highlight.startFrame
            lastElementEnd = highlight.endFrame

            elements.push(buildHighlightElement(highlight))
        })

        if (lastElementEnd < lastFrameIdx) {
            const addFrame = lastElementStart !== lastElementEnd ? 1 : 0

            elements.push(buildSpacerElement(lastElementEnd + addFrame, lastFrameIdx))
        }

        $ctrl.elements = elements

        ////////////////////////////////////////

        function buildSpacerElement(startFrame, endFrame) {
            endFrame = endFrame > 0 ? endFrame : 0

            return {
                id: ++lastUid,
                type: 'spacer',
                startFrame,
                endFrame,
            }
        }

        function buildHighlightElement(highlight) {
            return {
                id: ++lastUid,
                highlight: highlight,
                type: 'highlight',
                startFrame: highlight.startFrame,
                endFrame: highlight.endFrame,
                cssClass: {
                    'highlight-is-empty': _.get(
                        _.find($ctrl.highlightsState, { id: highlight.id }),
                        'isEmpty',
                        false
                    ),
                },
                $$isSelected: highlight.$$isSelected,
                $$wasSingleSelected: highlight.$$wasSingleSelected,
            }
        }
    }

    function handleOnSingleSelect(element) {
        if (element.type === 'highlight') {
            HighlightActions.selectById({ id: element.highlight.id })
        } else {
            HighlightActions.selectById({ id: null })
        }
    }

    function handleOnMultiSelect(elementIndexRange) {
        const highlights = _.filter($ctrl.elements, (element, idx) => {
            return element.type === 'highlight' && _.includes(elementIndexRange, idx)
        })
        const ids = _.map(highlights, 'highlight.id')
        HighlightActions.selectById({ id: ids })
    }

    function handleOnResize({ leftElementId, rightElementId, offsetFrames }) {
        if (!offsetFrames) {
            return
        }

        const leftElement = _.find($ctrl.elements, { id: leftElementId })
        const rightElement = _.find($ctrl.elements, { id: rightElementId })

        if (leftElement.type === 'highlight') {
            HighlightActions.moveEndFrame({ id: leftElement.highlight.id, offsetFrames })
        }

        if (rightElement.type === 'highlight') {
            HighlightActions.moveStartFrame({ id: rightElement.highlight.id, offsetFrames })
        }
    }
}
