export default /* @ngInject */ function onFinishRenderDirective() {
    return {
        restrict: 'A',
        link: (scope: ng.IRepeatScope) => {
            if (scope.$last === true) {
                scope.$emit('onFinishRender')
            }
        },
    }
}
