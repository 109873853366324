const missingSegmentsNotifier = {
    bindings: {
        titles: '<',
    },
    template: `
        <table class="table">
            <thead>
                <tr ng-if="::$ctrl.titles.length">
                    <th class="txt-xl">
                        Name
                    </th>
                    <th>Error Message</th>
                </tr>
            </thead>
            <tbody>
                <tr ng-if="::$ctrl.titles.length" ng-repeat="video in $ctrl.titles">
                    <td>{{ ::video.title }} {{ ::video.episode_name }}</td>
                    <td>{{ ::video.message }}</td>
            </tbody>
            <tfoot>
                <tr ng-if="::!$ctrl.titles.length">
                    <td colspan="999">No titles found with missing Segments</td>
                </tr>
            </tfoot>
        </table>
    `,
}

export default missingSegmentsNotifier
