export default /* @ngInject */ function statefulChosenDirective($timeout) {
    var directive = {
        restrict: 'A',
        require: '^^?formGroup',
        link: statefulChosenLinkFn,
    }

    return directive

    ///////////////////////////

    function statefulChosenLinkFn(scope, element, attrs, formGroupCtrl) {
        element.on('chosen:ready', () => {
            // fix for <select> tag not being actually set to display: none,
            // but hidden with clip
            element.attr('tabindex', -1)
        })

        // short circuit
        if (!formGroupCtrl) {
            return
        }

        element.on('chosen:showing_dropdown', function () {
            const timeoutId = $timeout(function () {
                formGroupCtrl && formGroupCtrl.setHasFocus(true)
            })

            scope.$on('$destroy', () => $timeout.cancel(timeoutId))
        })
        element.on('chosen:hiding_dropdown', function () {
            const timeoutId = $timeout(function () {
                formGroupCtrl && formGroupCtrl.setHasFocus(false)
            })

            scope.$on('$destroy', () => $timeout.cancel(timeoutId))
        })
    }
}
