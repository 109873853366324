import _ from 'lodash'

/**
 * @ngdoc directive
 * @name videoSpeed
 * @module map3.core
 * @restrict E
 *
 * @description
 * A widget for changing the video playback speed
 *
 * @param {videoAPI} videoApi A {@link videoAPI} instance
 */
export default /* @ngInject */ function videoSpeedDirective(SharedVideoAPI) {
    var directive = {
        restrict: 'E',
        link: videoSpeedDirectiveLinkFn,
        scope: true,
        templateUrl: 'js/directives/video-speed/videoSpeed.tpl.html',
    }

    return directive

    ///////////////////////

    function videoSpeedDirectiveLinkFn(scope, element) {
        let videoApi

        scope.speedsArray = [
            {
                label: '4x',
                class: 'reverse',
                value: -4,
            },
            {
                label: '2x',
                class: 'reverse',
                value: -2,
            },
            {
                label: '1x',
                class: 'reverse',
                value: -1,
            },
            {
                label: '1x',
                class: 'forward',
                value: 1,
            },
            {
                label: '2x',
                class: 'forward',
                value: 2,
            },
            {
                label: '4x',
                class: 'forward',
                value: 4,
            },
        ]

        scope.changeRate = changeRate

        scope.currentSpeed = {
            label: 'Normal',
            value: 1,
        }

        activate()

        ////////////////////

        function activate() {
            SharedVideoAPI.onLoad(function (videoApiParam) {
                videoApi = videoApiParam

                videoApi.addEventListener('timeupdate', function () {
                    let speed = _.find(scope.speedsArray, {
                        value: videoApi.getPlayer().getPlaybackRate(),
                    })
                    if (speed && scope.currentSpeed !== speed) {
                        scope.currentSpeed = speed
                        scope.$digest()
                    }
                })
            })

            scope.currentSpeed = _.find(scope.speedsArray, { value: 1 })
        }

        function changeRate(rate) {
            try {
                if (videoApi) {
                    videoApi.getPlayer().playAtRate(rate.value)
                    scope.currentSpeed = rate
                }
            } catch (e) {}
        }
    }
}
