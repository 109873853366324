import _ from 'lodash'

export default /* @ngInject */ function MapDialogFactory($uibModal) {
    /**
     * @ngdoc type
     * @name MapDialog
     * @module map3.core
     *
     * @description
     * Service for simple confirms and alerts, mimicking the API of
     * $mdDialog
     */
    var MapDialog = {
        /**
         * @ngdoc method
         * @name MapDialog#confirm
         * @description Create a confirm dialog
         *
         * @param {options=} options
         * @return {FluidDialogConfig}
         */
        confirm: function (options = {}) {
            return new FluidDialogConfig({ ...options, $type: 'confirm' })
        },

        /**
         * @ngdoc method
         * @name MapDialog#alert
         * @description Create a alert dialog
         *
         * @param {options=} options
         * @return {FluidDialogConfig}
         */
        alert: function (options = {}) {
            return new FluidDialogConfig({ ...options, $type: 'alert' })
        },

        /**
         * @ngdoc method
         * @name MapDialog#show
         * @description Show a dialog based on a config object
         *
         * @param {FluidDialogConfig} dialogConfig
         * @return {Promise} Fufilled on close, rejected on dismiss
         */
        show: function (dialogConfig) {
            let modalInstance = $uibModal.open({
                templateUrl: 'js/directives/map-dialog/map-dialog.tpl.html',
                controller: /* @ngInject */ function ($scope) {
                    $scope.config = dialogConfig.config
                },
            })

            return modalInstance.result
        },
    }

    /**
     * @ngdoc type
     * @name FluidDialogConfig
     * @module map3.core
     *
     * @description Fluid dialog configuration interface, mimicking $mdDialog
     */
    function FluidDialogConfig(options) {
        this.config = _.assign(
            {},
            {
                title: null,
                htmlTitle: null,
                textContent: null,
                htmlContent: null,
                ok: 'OK',
                cancel: 'Cancel',
                cssClass: null,
                okClass: null,
            },
            options
        )
    }

    function createFluidSetter(field) {
        FluidDialogConfig.prototype[field] = function (value) {
            this.config[field] = value
            return this
        }
    }

    FluidDialogConfig.prototype.show = function () {
        return MapDialog.show(this)
    }

    /**
     * @ngdoc method
     * @name FluidDialogConfig#title
     * @description Set dialog title
     * @param {string} value The value of the field
     * @return {FluidDialogConfig}
     */
    createFluidSetter('title')
    /**
     * @ngdoc method
     * @name FluidDialogConfig#textContent
     * @description Set dialog textContent
     * @param {string} value The value of the field
     * @return {FluidDialogConfig}
     */
    createFluidSetter('textContent')
    /**
     * @ngdoc method
     * @name FluidDialogConfig#htmlContent
     * @description Set dialog htmlContent
     * @param {string} value The value of the field
     * @return {FluidDialogConfig}
     */
    createFluidSetter('htmlContent')
    /**
     * @ngdoc method
     * @name FluidDialogConfig#ok
     * @description Set dialog OK button text
     * @param {string} value The value of the field
     * @return {FluidDialogConfig}
     */
    createFluidSetter('ok')
    /**
     * @ngdoc method
     * @name FluidDialogConfig#cancel
     * @description Set dialog Cancel button text
     * @param {string} value The value of the field
     * @return {FluidDialogConfig}
     */
    createFluidSetter('cancel')
    /**
     * @ngdoc method
     * @name FluidDialogConfig#cssClass
     * @description Set special dialog classes
     * @param {string} value The value of the field
     * @return {FluidDialogConfig}
     */
    createFluidSetter('cssClass')
    /**
     * @ngdoc method
     * @name FluidDialogConfig#okClass
     * @description Set special classe for OK button
     * @param {string} value The value of the field
     * @return {FluidDialogConfig}
     */
    createFluidSetter('okClass')

    return MapDialog
}
