export default /* @ngInject */ function formGroupDirective() {
    var directive = {
        restrict: 'ACE', // attribute, class
        controller: FormGroupCtrl,
    }

    return directive

    /////////////////////////

    /* @ngInject */
    function FormGroupCtrl($scope, $element) {
        let self = this

        self.setHasValue = setHasValue
        self.setHasFocus = setHasFocus

        activate()

        //////////////////////////////////

        function activate() {
            $scope.$watch(
                function () {
                    return self.label && self.input
                },
                function (hasLabelAndInput) {
                    if (hasLabelAndInput && !self.label.attr('for')) {
                        self.label.attr('for', self.input.attr('id'))
                    }
                }
            )
        }

        function setHasValue(hasValue) {
            $element.toggleClass('form-control-has-value', !!hasValue)
        }

        function setHasFocus(hasFocus) {
            $element.toggleClass('form-control-has-focus', !!hasFocus)
        }
    }
}
