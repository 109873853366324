import * as d3 from 'd3'
import { Arc, DefaultArcObject } from 'd3-shape'
import _ from 'lodash'

interface ScopeBindings {
    value: number
}

interface IGaugeChartArc<This, Datum> extends Arc<This, Datum> {
    centroid(...args: any[]): [number, number]
}

export default /* @ngInject */ function gaugeChart($window: ng.IWindowService) {
    const directive = {
        restrict: 'E',
        link: gaugeChartLinkFn,
        scope: {
            value: '<',
        },
    }

    return directive

    ////////////////////

    function gaugeChartLinkFn(scope: ng.IScope & ScopeBindings, element: ng.IAugmentedJQuery) {
        const granularity = [
            [-0.5, 0.5],
            [0.5, 1.5],
            [1.5, 2.5],
            [2.5, 3.5],
        ]

        const labels = ['LOW', 'MED', 'MED-HIGH', 'HIGH']

        const colors = ['steelblue', '#eac753', '#f0ad4e', 'rgba(189, 116, 98, 0.5)']

        const config = {
            width: element.width()!,
            height: element.width()! / 1.33,
            margins: {
                top: 0,
                right: 20,
                bottom: 60,
                left: 20,
            },
        }

        let arcWidth = config.width - (config.margins.left + config.margins.right)
        const sectionExtent = d3.extent(_.flatten(granularity)) as [number, number]
        const gaugeMaxValue = _.max(sectionExtent)!

        const gaugeScale = d3
            .scaleLinear()
            .domain(sectionExtent)
            .range([-0.5 * Math.PI, 0.5 * Math.PI])

        let needleArc = d3
            .arc()
            .innerRadius(0)
            .outerRadius(arcWidth / 2.5)
            .startAngle(gaugeScale(0)!)
            .endAngle(gaugeScale(0)!)

        const chart = d3.select(element[0])

        let svg = chart
            .append('svg')
            .style('width', `${config.width}px`)
            .style('height', `${config.height}px`)

        drawGauge()

        scope.$watch('value', () => {
            svg.selectAll('path.needle').remove()

            addNeedle()
        })

        angular.element($window).on('resize', handleResizeFn)
        scope.$on('$destroy', () => {
            angular.element($window).off('resize', handleResizeFn)
        })

        handleResizeFn()

        ///////////////////

        function centrePoint() {
            return `translate(${config.width / 2}, ${config.height - 30})`
        }

        function createArc(start: number, finish: number) {
            return d3
                .arc()
                .innerRadius(arcWidth / 3)
                .outerRadius(arcWidth / 2)
                .startAngle(gaugeScale(start)!)
                .endAngle(gaugeScale(finish)!)
        }

        function addNeedle() {
            const percentValue = scope.value / gaugeMaxValue
            const needleRotationDeg = 180 * percentValue

            svg.selectAll('path.needle')
                .data([scope.value])
                .enter()
                .append('path')
                .attr('class', 'needle')
                .attr('d', needleArc as any)
                .style('fill', '#444')
                .attr('stroke', '#444')
                .attr('stroke-width', 6)
                .attr('stroke-linejoin', 'round')
                .attr('transform', () => `${centrePoint()} rotate(${needleRotationDeg})`)
        }

        function addArcPart(arc: Arc<any, any>, idx: number) {
            svg.append('path')
                .attr('d', arc)
                .attr('id', `load_path_${idx}`)
                .attr('transform', centrePoint())
                .style('fill', colors[idx])
        }

        function addLabel(idx: number, centroid: [number, number]) {
            svg.append('text')
                .attr(
                    'transform',
                    `translate(${config.width / 2 + centroid[0]}, ${
                        config.height + centroid[1] - 25
                    })`
                )
                .attr('xlink:href', `#load_path_${idx}`) //place the ID of the path here
                .attr('fill', 'white')
                .attr('startOffset', '50%')
                .attr('font-size', '0.83rem')
                .attr('font-weight', 'bold')
                .style('text-anchor', 'middle')
                .style('alignment-baseline', 'middle')
                .style('translate-x', '-40px')
                .text(labels[idx])
        }

        function drawGauge() {
            for (const [i, [startBoundary, endBoundary]] of granularity.entries()) {
                const arc = createArc(startBoundary, endBoundary) as IGaugeChartArc<
                    any,
                    DefaultArcObject
                >
                const centroid = arc.centroid()

                addArcPart(arc, i)
                addLabel(i, centroid)
            }

            addNeedle()
        }

        function handleResizeFn() {
            config.width = element.width()!
            config.height = element.width()! / 1.5
            arcWidth = config.width - (config.margins.left + config.margins.right)

            needleArc = d3
                .arc()
                .innerRadius(0)
                .outerRadius(arcWidth / 2.5)
                .startAngle(gaugeScale(0)!)
                .endAngle(gaugeScale(0)!)

            svg.remove()

            svg = chart
                .append('svg')
                .style('width', `${config.width}px`)
                .style('height', `${config.height}px`)

            drawGauge()
        }
    }
}
