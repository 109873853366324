import _ from 'lodash'
import { generateIdentifier } from 'admin/services/generateUniqueIdentifier'

type TaxonomyTerm = PrefferedTerm & {
    preferedTerm: PrefferedTerm[]
}

interface TaxonomyClass {
    id: string
    title: string
    values: TaxonomyTerm[]
    groupsWithAccess: Group[]
}
interface TaxonomyInstance {
    ownerId: string
    ownerLabel: string
    taxonomy: TaxonomyClass[]
}

export default /* @ngInject */ function AdminManageTaxonomyCtrl(
    this: unknown,
    $uibModal: ng.ui.bootstrap.IModalService,
    groups: Group[],
    taxonomy: TaxonomyInstance
) {
    const baseUrl = 'js/admin/manage-taxonomy/'
    const vm = this as {
        taxonomy: TaxonomyInstance
        groups: Group[]
        showTaxonomyConfigModal: typeof showTaxonomyConfigModal
    }
    vm.taxonomy = taxonomy
    vm.groups = groups

    vm.showTaxonomyConfigModal = showTaxonomyConfigModal

    function showTaxonomyConfigModal(taxonomy: TaxonomyClass | undefined) {
        if (!taxonomy) {
            taxonomy = { title: '', id: generateIdentifier(), values: [], groupsWithAccess: [] }
        }

        const modalInstance = $uibModal.open({
            templateUrl: `${baseUrl}taxonomyConfigModal.tpl.html`,
            size: 'lg',
            controller: TaxonomyConfigCtrl,
            controllerAs: 'vm',
            resolve: {
                taxonomy: () => taxonomy,
                groups: () => vm.groups,
            },
        })

        modalInstance.result.then((taxonomy: TaxonomyClass | undefined) => {
            if (taxonomy) {
                vm.taxonomy.taxonomy.push(taxonomy)
            }
        })
    }
}

AdminManageTaxonomyCtrl.resolve = {
    groups: /* @ngInject */ function (AdminUsersModule: any) {
        return AdminUsersModule.getGroups().then((res: Group[]) => res)
    },
    taxonomy: () => {
        return {
            ownerId: '12321ss-sad',
            ownerLabel: 'Accenture',
            taxonomy: [
                {
                    values: [
                        {
                            label: 'dust',
                            id: 'http://data.disney.com/resources/e33339a-3333-444-55',
                            validForTagging: true,
                            preferredTerm: [],
                        },
                        {
                            label: 'explosion',
                            id: 'http://data.disney.com/resources/e33339a-6666-444-55',
                            validForTagging: false,
                            preferredTerm: [],
                        },
                    ],
                    id: 'http://data.disney.com/map3/e049bdd2-2e28-ecc4-a8cc-779fcdcdc69a',
                    title: 'VFX Materials',
                    groupsWithAccess: [
                        {
                            uri: 'http://test.org/CS/Group/ne2393498-72a0-11e9-af5e-0a70288577a3',
                            label: 'Mood Tagging',
                            num_members: 15,
                        },
                    ],
                },
                {
                    values: [],
                    id: 'http://data.disney.com/map3/f4445-2e28-ecc4-a8cc-779fcdcdc69a',
                    title: 'Spaceships',
                    groupsWithAccess: [
                        {
                            uri: 'http://test.org/CS/Group/n435bc93c-fb06-11e9-87cd-025198655bee',
                            label: 'Demo',
                            num_members: 3,
                        },
                    ],
                },
            ],
        }
    },
}

/* @ngInject */ function TaxonomyConfigCtrl(
    this: unknown,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    groups: Group[],
    taxonomy: TaxonomyClass
) {
    const vm = this as {
        groups: Group[]
        taxonomy: TaxonomyClass
        isEditMode: boolean

        addTerm: typeof addTerm
        removeTerm: typeof removeTerm
        save: typeof save
    }
    vm.groups = groups
    vm.taxonomy = _.cloneDeep(taxonomy)

    vm.addTerm = addTerm
    vm.removeTerm = removeTerm
    vm.save = save

    const isEditMode = taxonomy.title !== ''

    function addTerm(tag: TaxonomyTerm) {
        Object.assign(tag, { id: generateIdentifier(), pefferedTerm: [], validForTagging: true })
    }

    function removeTerm(tag: TaxonomyTerm) {
        if (isEditMode) {
            tag.validForTagging = false
            return false
        }
    }

    function save() {
        if (isEditMode) {
            _.assign(taxonomy, { ...vm.taxonomy })
            $uibModalInstance.close()
            return
        }

        $uibModalInstance.close(vm.taxonomy)
    }

    return vm
}
