import angular from 'angular'
import _ from 'lodash'

const HAS_SESSION_STORAGE = detect$sessionStorage()

/**
 * @ngdoc type
 * @name JSONSessionStorage
 * @module map3.auth
 *
 * @description
 * A SessionStorage-wrapper interface that does automatic serialization/deserialization to JSON
 * or fallbacks to a js object store when session store is not available
 */
class JSONSessionStorage {
    fallbackData: {
        [x in string]: string
    }

    /**
     */
    constructor() {
        this.fallbackData = {}
    }

    /**
     * @ngdoc method
     * @name JSONSessionStorage#setItem
     * @description
     * Set the value for a key name
     *
     * @param {string} keyName
     * @param {*} value
     */
    setItem(keyName: string, value: any) {
        const jsonValue = angular.toJson(value)

        if (HAS_SESSION_STORAGE) {
            window.sessionStorage.setItem(keyName, jsonValue)
        } else {
            this.fallbackData[keyName] = jsonValue
        }
    }

    /**
     * @ngdoc method
     * @name JSONSessionStorage#getItem
     * @description
     * Get the value of a key name
     *
     * @param {string} keyName
     */
    getItem<T extends any>(keyName: string): T | null {
        const jsonValue = HAS_SESSION_STORAGE
            ? window.sessionStorage.getItem(keyName) || 'null'
            : this.fallbackData[keyName] || 'null'

        return angular.fromJson(jsonValue)
    }

    /**
     * @ngdoc method
     * @name JSONSessionStorage#removeItem
     * @description
     * Remove the value for a key name
     *
     * @param {string} keyName
     */
    removeItem(keyName: string) {
        if (HAS_SESSION_STORAGE) {
            window.sessionStorage.removeItem(keyName)
        } else {
            delete this.fallbackData[keyName]
        }
    }

    /**
     * @ngdoc method
     * @name JSONSessionStorage#key
     * @description
     * Find the position of a key name
     *
     * @param {number} i
     * @return {string|null}
     */
    key(i: number): string | null {
        if (HAS_SESSION_STORAGE) {
            return window.sessionStorage.key(i)
        }

        let counter = 0
        for (const prop in this.fallbackData) {
            if (counter === i) {
                return prop
            } else {
                counter++
            }
        }

        return null
    }

    /**
     * @ngdoc property
     * @name JSONSessionStorage#length
     * @description
     * @property {number} length The size of the storage object
     */
    get length(): number {
        return HAS_SESSION_STORAGE ? window.sessionStorage.length : _.size(this.fallbackData)
    }

    /**
     * @ngdoc method
     * @name JSONSessionStorage#serialize
     * @description
     * Serialize the storage to string
     *
     * @return {string}
     */
    serialize(): string {
        return HAS_SESSION_STORAGE
            ? angular.toJson(window.sessionStorage)
            : angular.toJson(this.fallbackData)
    }

    /**
     * Get the value from sotrage, or return a default value
     */
    getOrUseDefault<T extends any>(key: string, defaultValue: T): T {
        const v = this.getItem<T>(key)

        if (v !== null) {
            return v
        }

        this.setItem(key, defaultValue)
        return defaultValue
    }
}

// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/sessionstorage.js
function detect$sessionStorage() {
    const mod = 'modernizr'
    try {
        sessionStorage.setItem(mod, mod)
        sessionStorage.removeItem(mod)
        return true
    } catch (e) {
        return false
    }
}

export default new JSONSessionStorage()
