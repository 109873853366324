import _ from 'lodash'

export default /* @ngInject */ function TaskListServiceFactory($http: ng.IHttpService) {
    const TaskListService = {
        assignments: function () {
            return $http.get('/api/worker/assignments').then((res) => res.data)
        },

        acceptTasks: function (tasks: any[]) {
            const ids = _.map(tasks, 'hitID').filter((hitID) => !!hitID)

            return $http.post('/api/worker/hits/accept/', { hitIds: ids }).then((res) => res.data)
        },

        returnTaskToInProgress: function (task: any) {
            return $http
                .post(`/api/worker/assignments/return-in-progress/${task.assignmentID}`, null)
                .then((res) => res.data)
        },

        returnTask: function (task: any) {
            return $http
                .post(`/api/worker/assignments/return/${task.assignmentID}`, null)
                .then((res) => res.data)
        },
    }

    return TaskListService
}
