import { VGPlayerTimeFormat } from 'constants.es6'
import {
    TIME_FORMAT_DROPDOWN_CHANGE_EVENT,
    TIME_FORMAT_KEYBOARD_CHANGE_EVENT,
} from 'directives/constants'
import timeFormatTpl from './timeFormat.tpl.html'

class TimeFormatComponentController {
    timeFormats = [
        {
            label: 'SMPTE',
            class: 'tape',
            value: VGPlayerTimeFormat.Tape,
        },
        {
            label: 'Standard',
            class: 'standard',
            value: VGPlayerTimeFormat.Clock,
        },
        {
            label: 'Frame',
            class: 'frame',
            value: VGPlayerTimeFormat.Frame,
        },
        {
            label: 'Seconds',
            class: 'timestamp',
            value: VGPlayerTimeFormat.Seconds,
        },
    ]

    timeFormat: VGPlayerTimeFormat = VGPlayerTimeFormat.Clock
    hasDropFrames = false
    $scope: ng.IScope
    currentTimeObj?: { label: string; class: string; value: VGPlayerTimeFormat }
    /* @ngInject */
    constructor($scope: ng.IScope) {
        this.$scope = $scope
    }

    $onInit() {
        if (this.hasDropFrames) {
            const smpteIdx = this.timeFormats.findIndex(
                (tf) => tf.value === VGPlayerTimeFormat.Tape
            )
            this.timeFormats.splice(smpteIdx + 1, 0, {
                label: 'SMPTE DF',
                class: 'tape-df',
                value: VGPlayerTimeFormat.TapeDF,
            })
        }
        this.currentTimeObj = this.timeFormats.find((i) => i.value === this.timeFormat)
        this.$scope.$on(TIME_FORMAT_KEYBOARD_CHANGE_EVENT, (_event, timeFormat) => {
            this.currentTimeObj = this.timeFormats.find((i) => i.value === timeFormat)
            if (this.currentTimeObj) {
                this.$scope.$emit(TIME_FORMAT_DROPDOWN_CHANGE_EVENT, this.currentTimeObj.value)
            }
        })
    }

    changeTimeFormat(timeFormat: { label: string; class: string; value: VGPlayerTimeFormat }) {
        this.currentTimeObj = timeFormat
        this.$scope.$emit(TIME_FORMAT_DROPDOWN_CHANGE_EVENT, timeFormat.value)
    }
}

const timeFormatComponent = {
    controller: TimeFormatComponentController,
    bindings: {
        timeFormat: '<?',
        hasDropFrames: '<?',
    },
    template: timeFormatTpl,
}

export default timeFormatComponent
