import _ from 'lodash'
import lockAndLoad from 'services/lockAndLoad'

var timezones
var timezonesPromise = import('./timezoneInput.timezones.json')
    .then((m) => m.default)
    .then((result) => {
        timezones = result
    })

export default /* @ngInject */ function timezoneInputDirective() {
    const PREFERRED_ZONES = [
        'Europe/London',
        'Europe/Madrid',
        'Europe/Sofia',
        'America/Los_Angeles',
        'America/Edmonton',
        'America/Chicago',
        'America/New_York',
        'Atlantic/Bermuda',
    ]

    let directive = {
        restrict: 'E',
        scope: {
            name: '@',
            ngModel: '=',
            ngRequired: '<',
        },
        template: `
            <div class="chosen-holder">
                <select ng-model="ngModel"
                    chosen
                    name="{{ name }}" class="w-100"
                    data-placeholder-text-single="' '"
                    ng-required="{{ ngRequired }}"
                >
                    <optgroup label="Top picks:">
                        <option ng-repeat="zone in timezonesPreferred track by zone.cannonicalName"
                            value="{{zone.cannonicalName}}"
                        >{{ zone.displayName }}</option>
                    </optgroup>

                    <option value="@@separator" ng-disabled="true">
                        ${_.repeat('\u2014', 10)}
                    </option>

                    <optgroup  label="All Timezones:">
                        <option ng-repeat="zone in timezonesRest"
                            value="{{zone.cannonicalName}}"
                        >{{zone.displayName}}</option>
                    </optgroup>
                </select>
            </div>
        `,
        link: lockAndLoad([timezonesPromise], timezoneInputLinkFn, angular.noop),
    }

    return directive

    //////////////////

    function timezoneInputLinkFn(scope, element) {
        scope.timezonesPreferred = []
        scope.timezonesRest = []

        if (!_.find(timezones, { cannonicalName: scope.ngModel })) {
            scope.ngModel = 'America/New_York'
        }
        _.forEach(timezones, (zone) => {
            if (_.includes(PREFERRED_ZONES, zone.cannonicalName)) {
                scope.timezonesPreferred.push(zone)
            } else {
                scope.timezonesRest.push(zone)
            }
        })
    }
}
