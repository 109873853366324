import _ from 'lodash'
import { trackButtonClick } from 'util/snowplow'

export default /* @ngInject */ function QAReturnToWorkersFactory(
    QAAssignmentService,
    User,
    MapDialog,
    Notification,
    $http,
    $state,
    $uibModal
) {
    /**
     * @ngdoc service
     * @module map3.qa
     * @name QAReturnToWorkers
     */
    let QAReturnToWorkers = {
        /**
         * Open a dialgoue to select which workers to return the task to
         *
         * If workers are selected, we execute QAAssignmentService.returnToWorkers()
         * and route to {qa.list}
         */
        return: function (id, workers) {
            $uibModal.open({
                title: 'Return to Workers',
                templateUrl: 'js/qa/review.returnModal.tpl.html',
                controllerAs: 'modalVm',
                controller: /* @ngInject */ function ($scope, $uibModalInstance) {
                    let modalVm = this
                    modalVm.workers = workers
                    modalVm.message = ''
                    modalVm.showActionConfiramtion = showActionConfiramtion

                    // create an object like: { "John": false, "Peter": false}
                    modalVm.selectedWorkers = _.zipObject(
                        _.map(workers, 'username'),
                        _.fill(Array(workers.length), false)
                    )

                    $scope.$watch(
                        'modalVm.selectedWorkers',
                        function (selectedWorkers) {
                            modalVm.worker_ids = _.keys(_.pickBy(selectedWorkers))
                        },
                        /* deep */ true
                    )

                    function showActionConfiramtion(result) {
                        let confirmDialog = MapDialog.confirm()
                            .title('Return task to worker(s)')
                            .htmlContent(
                                `
                                <strong>Are you sure you want to proceed?</strong>
                            `
                            )
                            .ok('Yes')
                            .cancel('No')

                        MapDialog.show(confirmDialog).then(function () {
                            if (_.size(result.worker_ids)) {
                                $uibModalInstance.close()

                                trackButtonClick({
                                    label: 'Return to workers',
                                    value: [
                                        {
                                            workerIds: result.worker_ids,
                                        },
                                    ],
                                })

                                let promise = QAAssignmentService.returnToWorkers(id, result).then(
                                    function () {
                                        $state.go('qa.list')
                                    }
                                )

                                Notification.forPromise(
                                    promise,
                                    'Successfully returned to worker(s)'
                                )
                            }
                        })
                    }
                },
                resolve: {
                    workers: () => _.reject(workers, { username: User.cached().username }),
                },
            })
        },
    }

    return QAReturnToWorkers
}
