import FastdomWrapper from 'services/FastdomWrapper'
import FilmstripCalculator from './FilmstripCalculator'
import FakeFilmstripDrawer from './FakeFilmstripDrawer'

const debug = require('debug')('map3:Filmstrip')

export default /* @ngInject */ function filmstripDirective($log, SharedVideoAPI, DOMUtility) {
    const USE_FAKE_FILMSTRIP = false // TODO: revert to previous
    // const USE_FAKE_FILMSTRIP = process.env.NODE_ENV === 'development'

    const directive = {
        restrict: 'A',
        scope: {
            filmstrip: '<',
            id: '@filmstripId',
            renderingGroup: '@filmstripRenderingGroup',
            enabled: '<?filmstripEnabled',
        },
        link: filmstripLinkFn,
    }

    return directive

    /////////////////////////////////////

    function filmstripLinkFn(scope, canvas) {
        canvas.addClass('filmstrip')

        const canvasParent = canvas.parent()
        const timeline = canvasParent.parents('.generic-timeline')

        const fastdom = new FastdomWrapper()

        let videoAPI
        let filmstripCalculator
        let filmstripDrawer
        let cancelDrawFn
        let fastdomTask

        let preLoadDrawOptions = {}

        activate()

        /////////////////////////////////////

        function activate() {
            scope.$on('$destroy', () => {
                cleanup()
                fastdom.destroy()
            })

            SharedVideoAPI.onLoad(setupFilmstrip)

            scope.$on('filmstrip.draw', (e, drawOptions) => {
                // on forceRedraw, re-initiate the filmstrip
                if (drawOptions.forceRedraw && videoAPI) {
                    cleanup()
                    setupFilmstrip(videoAPI)
                }

                if (filmstripDrawer) {
                    drawFilmstrip(drawOptions)
                } else {
                    preLoadDrawOptions = drawOptions
                }
            })
        }

        function cleanup() {
            if (cancelDrawFn) {
                cancelDrawFn()
            }

            fastdom.clearAll()

            if (filmstripDrawer && filmstripDrawer.destroy) {
                filmstripDrawer.destroy()
            }
            filmstripDrawer = null

            if (filmstripCalculator) {
                filmstripCalculator.destroy()
            }
        }

        function setupFilmstrip(videoAPIParam) {
            videoAPI = videoAPIParam

            if (USE_FAKE_FILMSTRIP) {
                const fakeFilmstripTimeoutId = setTimeout(function () {
                    const frameRatio = 144 / 80
                    const frameWidth = canvasParent.outerHeight() * frameRatio
                    const frameHeight = canvasParent.outerHeight()

                    filmstripDrawer = new FakeFilmstripDrawer({
                        width: frameWidth,
                        height: frameHeight,
                    })

                    filmstripCalculator = new FilmstripCalculator({
                        frameRatio,
                        frameWidth,
                        frameHeight,
                    })

                    drawFilmstrip(preLoadDrawOptions)
                }, 500)

                scope.$on('$destroy', () => clearTimeout(fakeFilmstripTimeoutId))
            } else {
                videoAPI.getPlayer().getFilmStripDrawer(function (filmstripDrawerParam) {
                    filmstripDrawer = filmstripDrawerParam

                    const info = filmstripDrawer.getInfo()
                    const frameRatio = info.width / info.height
                    const frameWidth = canvasParent.outerHeight() * frameRatio
                    const frameHeight = canvasParent.outerHeight()

                    filmstripCalculator = new FilmstripCalculator({
                        frameRatio,
                        frameWidth,
                        frameHeight,
                    })

                    drawFilmstrip(preLoadDrawOptions)
                })
            }
        }

        function drawFilmstrip(drawOptions = {}) {
            // quickly clear the canvas
            if (drawOptions.enabled === false || scope.enabled === false) {
                clearFilmstrip()
                return
            }

            fastdom.clear(fastdomTask)
            fastdomTask = fastdom.measure(function drawFilmstripMeasure() {
                const segmentRect = canvasParent[0].getBoundingClientRect()
                const timelineRect = timeline[0].getBoundingClientRect()

                const drawParams = filmstripCalculator.buildDrawParams(
                    {
                        segmentRect,
                        timelineRect,
                        startFrame: scope.filmstrip.startFrame,
                        endFrame: scope.filmstrip.endFrame,
                    },
                    {
                        id: scope.id,
                        ...drawOptions,
                    }
                )

                if (drawParams === false) {
                    clearFilmstrip()
                    return
                }

                fastdomTask = fastdom.mutate(function drawFilmstripMutate() {
                    if (filmstripDrawer && filmstripCalculator.needsRedraw(drawParams)) {
                        // if there is an active draw, cancel it
                        if (cancelDrawFn) {
                            cancelDrawFn()
                        }

                        // resize canvas to the element dimensions
                        canvas[0].width = drawParams.canvasWidth
                        canvas[0].height = Math.ceil(filmstripCalculator.frameHeight)
                        canvas.css('left', drawParams.canvasLeft ? drawParams.canvasLeft : '')

                        const drawId = DOMUtility.nextUid()
                        let isDone = false
                        debug('filmstrip draw start', drawId, drawParams)

                        wrapCancelFn(
                            filmstripDrawer.drawFilmstrip(
                                canvas[0],
                                drawParams.startFrame,
                                drawParams.endFrame,
                                drawParams.numFilmstripFrames,
                                /* framePrecise */ true,
                                function onDone() {
                                    cancelDrawFn = null
                                    isDone = true

                                    // save draw params
                                    filmstripCalculator.setLastDrawParams(drawParams)

                                    debug('filmstrip draw done', drawId, drawParams)
                                },
                                function onError() {
                                    cancelDrawFn = null
                                    debug('filmstrip draw error', drawId, drawParams)
                                    $log.error.apply($log, arguments)
                                }
                            ),
                            drawParams,
                            drawId,
                            function isDoneFn() {
                                return isDone
                            }
                        )
                    } else {
                        debug('filmstrip skip', scope.id)
                    }
                })
            })
        }

        function clearFilmstrip() {
            // if there is an active draw, cancel it
            if (cancelDrawFn) {
                cancelDrawFn()
            }

            fastdom.clear(fastdomTask)

            fastdom.mutate(function clearFilmstripMutate() {
                debug('filmstrip clear', scope.id)

                filmstripCalculator.setForceRedraw(true)

                const ctx = canvas[0].getContext('2d')
                ctx.clearRect(0, 0, canvas[0].width, canvas[0].height)
            })
        }

        function wrapCancelFn(originalCancelDrawFn, drawParams, drawId, isDoneFn) {
            if (isDoneFn()) {
                debug(`filmstrip wrap omitted`, drawId, drawParams)
                return
            }

            debug(`filmstrip wrap`, drawId, drawParams)

            cancelDrawFn = function wrappedCancelDrawFn() {
                debug(`filmstrip draw cancel`, drawId, drawParams)

                cancelDrawFn = null

                return originalCancelDrawFn.apply(null, arguments)
            }
        }
    }
}
