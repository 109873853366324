import _ from 'lodash'
import { QARouterInstance } from 'qa/routes'
import { AdminModuleInstance } from './AdminModule.factory'
import { IQAAssignment } from 'qa/types'

interface IAdminJobStatusCtrl {
    jobDetails: TJobDetails
    jobUsers: string[]
    loadingNewData: boolean
    workflowErrors: GraphNode[]
    showCumulative: any

    getContributionStatus: (hit: IQAAssignment, username: string) => void | string
    hitCanBeReviewed: (hit: IQAAssignment) => boolean
    urlForTask: QARouterInstance['urlFor']
    openEditTitleModal: () => void
}

export default /* @ngInject */ function AdminJobStatusCtrl(
    this: unknown,
    $uibModal: ng.ui.bootstrap.IModalService,
    $scope: ng.IScope,
    jobDetails: TJobDetails,
    QARouter: QARouterInstance
) {
    const adminVm = this as IAdminJobStatusCtrl

    adminVm.jobDetails = jobDetails

    adminVm.workflowErrors = jobDetails.nodes.filter((node) => node.info.errors?.length)

    adminVm.jobUsers = []
    adminVm.showCumulative = _.some(
        _.get(adminVm.jobDetails, 'keep_alive_graph'),
        (job: { total: number | null }) => job.total !== null
    )

    adminVm.getContributionStatus = getContributionStatus
    adminVm.urlForTask = QARouter.urlFor
    adminVm.hitCanBeReviewed = hitCanBeReviewed
    adminVm.openEditTitleModal = openEditTitleModal
    adminVm.loadingNewData = false

    $scope.$onRootScope('job-status.update', (event, data) => {
        adminVm.loadingNewData = true
        const tasksStatus = data.tasksStatus

        adminVm.jobDetails = {
            ...tasksStatus,
            graph: {
                nodes: tasksStatus.nodes,
                edges: tasksStatus.edges,
            },
        }
        adminVm.loadingNewData = false
    })

    ////////////////////////

    function getContributionStatus(hit: IQAAssignment, username: string) {
        const contribution = _.find(hit.contributions, { user: username })
        if (contribution) {
            return contribution.status
        }
    }

    function hitCanBeReviewed(hit: IQAAssignment) {
        if (!hit.id || !hit.class) {
            return false
        }

        if (hit.status === 'finished') {
            return true
        } else {
            return (
                hit.contributions &&
                hit.contributions.length &&
                _.some(hit.contributions, (contribution) => contribution.status === 'reviewer')
            )
        }
    }

    function openEditTitleModal() {
        const modalInstance = $uibModal.open({
            size: 'lg',
            template: `
                <form name="editTitle">
                        <header class="modal-header row">
                            <h4 class="mb-3 col-12">Change Title</h4>
                        </header>
                        <section class="modal-body row">
                            <form-input model="vm" field="title" label="New Title" class="col-12">
                                <input
                                    name="title"
                                    ng-model="vm.title"
                                    ng-required="true"
                                    minlength="5"
                                />
                            </form-input>
                        </section>
                        <div class="modal-footer">
                            <button type="button" ng-click="$dismiss()" class="btn">
                                Cancel
                            </button>

                            <button
                                type="submit"
                                ng-click="editTitle.$valid && vm.saveChanges()"
                                class="btn btn-primary"
                            >
                                Save
                            </button>
                        </div>
                </form>
            `,
            controller: /* @ngInject */ function (
                $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                AdminModule: AdminModuleInstance,
                initalTitle: string,
                workflowId: string
            ) {
                const vm = this as { title: string; saveChanges: () => void }

                vm.title = initalTitle

                vm.saveChanges = saveChanges

                function saveChanges() {
                    AdminModule.workflowEditTitle(workflowId, vm.title).then(() => {
                        $uibModalInstance.close(vm.title)
                    })
                }
            },
            controllerAs: 'vm',
            resolve: {
                initalTitle: () => adminVm.jobDetails.general.name,
                workflowId: () => adminVm.jobDetails.general.jobId,
            },
        })

        modalInstance.result.then((title) => {
            adminVm.jobDetails.general.name = title
        })
    }
}

AdminJobStatusCtrl.resolve = {
    jobDetails: /* @ngInject */ function (
        $stateParams: ng.ui.IStateParamsService,
        AdminModule: AdminModuleInstance
    ) {
        return AdminModule.getJobDetails($stateParams.jobId)
    },
}
