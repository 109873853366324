import _ from 'lodash'
import * as d3 from 'd3'

import GraphDrawer from './GraphDrawer'
import GraphObject from './GraphObject'

/**
 * <workflow-graph-component> creates an interface between angular and the dedicated
 * GraphDrawer. It handles graph changes and issues redraws, as well as handling
 * GraphDrawer events that should change the graph's data. It also interfaces with
 * GraphData, which handles data modifications of graph nodes, and whole-graph-data-integrity
 */
const workflowGraphComponent = {
    controller: workflowGraphCtrl,
    template: `
        <svg class="workflow-graph"></svg>

        <div class="no-scroll-overlay">
            <p class="no-scroll-overlay-text">
                Use (<map-system-button button="control"></map-system-button> + scroll) to zoom the graph
            </p>
        </div>
    `,
    bindings: {
        graph: '=',
        taskSelected: '=?',
        workflow: '<',
        isEditable: '<?',
    },
}

export default workflowGraphComponent

/* @ngInject */
function workflowGraphCtrl($scope, $element, GlobalShortcuts, GraphData) {
    const $ctrl = this

    $ctrl.$onInit = $onInit

    return $ctrl

    /////////////////////////////

    function $onInit() {
        $ctrl.isEditable = $ctrl.isEditable !== false
        $ctrl.svg = $element.find('svg')[0]
        $ctrl.$overlay = $ctrl.isEditable && $element.find('.no-scroll-overlay')

        $scope.$watch('$ctrl.graph', (graph) => {
            if (!GraphObject.isGraphObject(graph)) {
                $ctrl.graph = new GraphObject(graph)

                $ctrl.graphDrawer.fullDrawAsync($ctrl.graph)
            }
        })

        // sometimes, we need to force a draw call from outside,
        // use graph.$$forceDraw magic property to handle that
        $scope.$watch('$ctrl.graph.$$forceDraw', ($$forceDraw) => {
            if ($$forceDraw) {
                delete $ctrl.graph.$$forceDraw
                $ctrl.graphDrawer.drawAsync($ctrl.graph)
            }
        })

        $ctrl.graphDrawer = new GraphDrawer($ctrl.svg, $ctrl.$overlay)
        $ctrl.graphDrawer.onNodeDblClick = onNodeDblClick

        if ($ctrl.isEditable) {
            setupShortcuts()

            $scope.$watch('$ctrl.taskSelected', handleProvisionalOnTaskSelectedChange)

            $ctrl.graphDrawer.onNodeClick = onNodeClick
            $ctrl.graphDrawer.onEdgeClick = onEdgeClick
            $ctrl.graphDrawer.onGraphMousemove = onGraphMousemove
            $ctrl.graphDrawer.onGraphClick = onGraphClick
            $ctrl.graphDrawer.onIoClick = onIoClick
            $ctrl.graphDrawer.onNodeDrag = onNodeDrag
        }

        $ctrl.graphDrawer.fullDrawAsync($ctrl.graph)

        $(window).on('resize', fitToSVG)
        $scope.$on('$destroy', () => {
            $(window).off('resize', fitToSVG)
            $ctrl.graphDrawer.destroy()
        })
    }

    function setupShortcuts() {
        const unbindShortcuts = GlobalShortcuts.bind({
            title: 'Graph Shortcuts',
            shortcuts: [
                {
                    description: 'Cancel the creation of a Graph Box',
                    keyCombo: ['escape'],
                    shortcut: 'Esc',
                    callback: () => {
                        $ctrl.graph = $ctrl.graph.cancelEditing()
                        $ctrl.graphDrawer.drawAsync($ctrl.graph)
                    },
                },
                {
                    description: 'Delete selected graph box',
                    keyCombo: ['del', 'backspace'],
                    // TODO better visualisation in help
                    shortcut: 'Delete or Backspace',
                    callback: shortcutOnDelete,
                },
                {
                    description: 'Recenter and fill graph',
                    keyCombo: ['shift+f'],
                    shortcut: 'shift+F',
                    callback: fitToSVG,
                },
            ],
        })

        $scope.$on('$destroy', unbindShortcuts)
    }

    function fitToSVG() {
        $ctrl.graphDrawer.fitToSVG()
    }

    function shortcutOnDelete() {
        const selectedNode = $ctrl.graph.nodeFindSelected()
        if (selectedNode) {
            GraphData.tryRemoveNode($ctrl.graph, selectedNode).then((graph) => {
                $ctrl.graph = graph
                $ctrl.graphDrawer.drawAsync($ctrl.graph)
            })
        }

        const selectedEdge = $ctrl.graph.edgeFindSelected()
        if (selectedEdge) {
            GraphData.tryRemoveEdge($ctrl.graph, selectedEdge).then((graph) => {
                $ctrl.graph = graph
                $ctrl.graphDrawer.drawAsync($ctrl.graph)
            })
        }
    }

    function handleProvisionalOnTaskSelectedChange(taskSelected) {
        const provisionalNode = $ctrl.graph.nodeFindProvisional()
        // if a previous provisional node exists
        // and it's not the same as the new selected task
        if (provisionalNode) {
            const provisionalTemplate = _.get(provisionalNode, 'info.wizards.template')
            const selectedTemplate = _.get(taskSelected, 'wizards.template')
            if (
                provisionalTemplate &&
                selectedTemplate &&
                provisionalTemplate !== selectedTemplate
            ) {
                // remove the provisional node from the nodes list
                $ctrl.graph = $ctrl.graph.nodeRemove(provisionalNode)
                $ctrl.graphDrawer.drawAsync($ctrl.graph)
            } else {
                return
            }
        }

        if (taskSelected) {
            const provisionalNode = {
                info: angular.copy(taskSelected),
            }
            $ctrl.graph = $ctrl.graph.nodeCreateProvisional(provisionalNode)

            // if provisional edge exists, remove it
            const provisionalEdge = $ctrl.graph.edgeFindProvisional()
            if (provisionalEdge) {
                $ctrl.graph = $ctrl.graph.edgeRemove(provisionalEdge)
            }

            $ctrl.graphDrawer.drawAsync($ctrl.graph)

            $ctrl.taskSelected = null
        }
    }

    function onNodeClick(drawNode) {
        const node = $ctrl.graph.nodeFind(drawNode.id)
        if (node.$$isProvisional) {
            $ctrl.graph = $ctrl.graph.nodeCompleteProvisional()
            $ctrl.taskSelected = null
            $scope.$applyAsync()
        } else {
            $ctrl.graph = $ctrl.graph.nodeSelectToggle(drawNode.id)
        }

        $ctrl.graphDrawer.drawAsync($ctrl.graph)
    }

    function onNodeDblClick(drawNode) {
        const node = $ctrl.graph.nodeFind(drawNode)
        if (!node.$$isProvisional) {
            GraphData.openNodeConfig($ctrl.graph, $ctrl.workflow, node, !!$ctrl.isEditable)
        }
    }

    function onEdgeClick(drawEdge) {
        $ctrl.graph = $ctrl.graph.edgeSelectToggle(drawEdge.edge)
        $ctrl.graphDrawer.drawAsync($ctrl.graph)
        $scope.$applyAsync()
    }

    function onNodeDrag(drawNode) {
        $ctrl.graph = $ctrl.graph.nodeCoordsSet(drawNode.id, [d3.event.x, d3.event.y])
        $ctrl.graphDrawer.drawAsync($ctrl.graph)
        $scope.$applyAsync()
    }

    function onGraphClick() {
        // if provisional node exist, finalize it
        const provisionalNode = $ctrl.graph.nodeFindProvisional()
        if (provisionalNode) {
            $ctrl.graph = $ctrl.graph.nodeCompleteProvisional()

            $ctrl.taskSelected = null
            $ctrl.graphDrawer.drawAsync($ctrl.graph)
            $scope.$applyAsync()
        }

        // if provisional edge exists, remove it
        const provisionalEdge = $ctrl.graph.edgeFindProvisional()
        if (provisionalEdge) {
            $ctrl.graph = $ctrl.graph.edgeRemove(provisionalEdge)
            $ctrl.graphDrawer.drawAsync($ctrl.graph)
            $scope.$applyAsync()
        }
    }

    function onGraphMousemove() {
        const provisionalNode = $ctrl.graph.nodeFindProvisional()
        if (provisionalNode) {
            $ctrl.graph = $ctrl.graph.nodeCoordsSet(
                provisionalNode,
                $ctrl.graphDrawer.transformMatrixToOriginalBounds(d3.mouse($ctrl.svg)),
                1,
                1
            )
            $ctrl.graphDrawer.drawAsync($ctrl.graph)
        }

        const provisionalEdge = $ctrl.graph.edgeFindProvisional()
        if (provisionalEdge) {
            $ctrl.graph = $ctrl.graph.edgeCoordsSet(
                provisionalEdge,
                $ctrl.graphDrawer.transformMatrixToOriginalBounds(d3.mouse($ctrl.svg))
            )
            $ctrl.graphDrawer.drawAsync($ctrl.graph)
        }
    }

    function onIoClick(io) {
        $ctrl.graph = $ctrl.graph.edgeCreateOrUpdateProvisional(io)

        $ctrl.graphDrawer.drawAsync($ctrl.graph)
        $scope.$applyAsync()
    }
}
