import _ from 'lodash'

export default function titleCaseFilter() {
    const forceLowerCase =
        /\b(a|abaft|about|above|afore|after|along|amid|among|an|apud|as|aside|at|atop|below|but|by|circa|down|for|from|given|in|into|lest|like|mid|midst|minus|near|next|of|off|on|onto|out|over|pace|past|per|plus|pro|qua|round|sans|save|since|than|thru|till|times|to|under|until|unto|up|upon|via|vice|with|worth|the|and|nor|or|yet|so)\b/i
    const forceUpperCase = /\b(tv|id)\b/i

    return (str: string) => {
        const startCase = _.startCase(str)
        const titleCase = startCase.replace(forceLowerCase, (match) => match.toLowerCase())
        const titleCaseWithUppercase = titleCase.replace(forceUpperCase, (match) =>
            match.toUpperCase()
        )

        return titleCaseWithUppercase
    }
}
