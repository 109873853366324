import _ from 'lodash'

const debug = require('debug')('map3:RetriableRequest')

export default /* @ngInject */ function RetriableRequestFactory($http, $q, $timeout) {
    let retriableRequestIDCounter = 0

    /**
     * RetriableRequest provides an interface for a $http-like request to be automatically
     * retried if it fails, infinitely or up to a max number of retires, with optional
     * cancel and warning callbacks
     */
    class RetriableRequest {
        /**
         * @param {object} [opts={}]
         *      `sleep` - Number of seconds to sleep between retires. Defaut: `1`
         *      `maxRetries` - Number of maximum retires before failing the request. Default: `false`
         *      `maxRetriesCallback` - Callback function to be called if `maxRetries` is reached.
         *      `warnRetries` - Number of retries before calling `warnRetriesCallback`.
         *          It's reset after each call, so it can invoke the callback multiple times per request.
         *      `warnRetriesCallback` - Callback function to be called if `warnRetries` is reached.
         */
        constructor(opts = {}) {
            this.sleep = _.get(opts, 'sleep', 1)

            this.maxRetries = _.get(opts, 'maxRetries', false)
            this.maxRetriesCallback = _.get(opts, 'maxRetriesCallback')
            this.warnRetries = _.get(opts, 'warnRetries', false)
            this.warnRetriesCallback = _.get(opts, 'warnRetriesCallback')

            this.retries = -1

            this.$$loopTimeout = null
            this.$$httpCancelDeferred = null

            this.$$id = ++retriableRequestIDCounter
            debug('RetriableRequest', this.$$id, 'created', this)
        }

        /**
         * Start the request cycle
         *
         * @param {object} httpConfig An `$http` compatible [config object](https://docs.angularjs.org/api/ng/service/$http#usage)
         * @return {Promise} A promise that fulfills when the request completes, or rejects
         *  if `maxRetries` is reached
         */
        request(httpConfig) {
            this.deferred = $q.defer()
            this.httpConfig = angular.copy(httpConfig)

            this.$executeRequest()

            return this.deferred.promise
        }

        /**
         * Cancel the request cycle. Will fail the promise with the supplied `rejectValue`
         *
         * @param {Error} rejectValue
         */
        cancel(rejectValue = new Error('RetriableRequest: cancelled')) {
            if (this.$$cancelled) {
                return
            }
            this.$$cancelled = true

            debug('RetriableRequest', this.$$id, 'cancel')

            this.deferred.promise.catch(angular.noop)
            this.deferred.reject(rejectValue)

            if (this.$$loopTimeout) {
                $timeout.cancel(this.$$loopTimeout)
            }

            if (this.$$httpCancelDeferred) {
                this.$$httpCancelDeferred.resolve(rejectValue)
            }
        }

        $executeRequest() {
            // prepare a cancel deferred if we want to cancel the actual http request
            this.$$httpCancelDeferred = $q.defer()

            debug(
                'RetriableRequest',
                this.$$id,
                'request',
                `${this.httpConfig.method || 'GET'} ${this.httpConfig.url}`
            )

            $http(_.assign({}, this.httpConfig, { timeout: this.$$httpCancelDeferred.promise }))
                .then((response) => {
                    debug('RetriableRequest', this.$$id, 'success', response)
                    this.deferred.resolve(response)
                })
                .catch((response) => {
                    debug('RetriableRequest', this.$$id, 'error', response)
                    this.$loop(response)
                })
        }

        $loop(response) {
            ++this.retries

            if (this.maxRetries !== false && this.retries >= this.maxRetries) {
                debug('RetriableRequest', this.$$id, 'max retries reached, cancelling request')

                if (this.maxRetriesCallback) {
                    this.maxRetriesCallback(response, this.retries)
                }

                return this.deferred.reject(response)
            }

            debug('RetriableRequest', this.$$id, 'queue retry', this.retries + 1)

            if (this.warnRetries !== false && this.retries >= this.warnRetries) {
                debug('RetriableRequest', this.$$id, 'warning')

                if (this.warnRetriesCallback) {
                    this.warnRetriesCallback(null, this.retries)
                }

                // double the warn retries until the next warning
                this.warnRetries += this.warnRetries
            }

            this.$$loopTimeout = $timeout(
                () => {
                    this.$executeRequest()
                },
                this.sleep * 1000,
                /* apply */ true
            )
        }
    }

    return RetriableRequest
}
