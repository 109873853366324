import _ from 'lodash'

/**
 * @ngdoc type
 * @name GraphSearch
 * @module map3.admin
 *
 * @description
 * A class tasked with searching for neighbor graph elements
 */

export default class GraphSearch {
    /**
     * Search for a single upstream node matching a predicate
     *
     * @param {GraphObject.graph} graph
     * @param {GraphObject.node} rootNode The starting node
     * @param {object} predicate
     * @param {int|undefined} maxDepth Max depth to search
     *
     * @return {GraphObject.node|null}
     */
    static searchUpstream(graph: unknown, rootNode: unknown, predicate: unknown, maxDepth: number) {
        return (
            GraphSearch.searchAllUpstream(graph, rootNode, predicate, maxDepth, /* limit */ 1)[0] ||
            null
        )
    }

    static isChainedUpstream(graph: unknown, rootNode: unknown, predicates: unknown[]) {
        return _.every(predicates, (predicate) => {
            rootNode = GraphSearch.searchUpstream(graph, rootNode, predicate, /* maxDepth */ 1)

            return !!rootNode
        })
    }

    /**
     * Search for all upstream node matching a predicate
     *
     * @param {GraphObject.graph} graph
     * @param {GraphObject.node} rootNode The starting node
     * @param {object} predicate
     * @param {int|undefined} maxDepth Max depth to search
     * @param {int|undefined} limit Limit number of results
     *
     * @return {GraphObject.node|null}
     */
    static searchAllUpstream(
        graph: unknown,
        rootNode: unknown,
        predicate: any,
        maxDepth: number,
        limit?: number
    ) {
        const queue = []
        const visited: any = []
        const result = []
        let depthCurrent = 0
        let depthElementsToIncrease = 1
        let depthNextElementsToIncrease = 0

        const rootNeighbors = GraphSearch.findUpstreamNeighbours(graph, rootNode)
        queue.push(...rootNeighbors)
        depthNextElementsToIncrease = rootNeighbors.length

        while (queue.length > 0) {
            const currentNode = queue.shift()

            // Find neighbour nodes
            const neighbours = GraphSearch.findUpstreamNeighbours(graph, currentNode)
            depthNextElementsToIncrease += neighbours.length

            if (--depthElementsToIncrease === 0) {
                if (maxDepth && ++depthCurrent > maxDepth) {
                    return result
                }
                depthElementsToIncrease = depthNextElementsToIncrease
                depthNextElementsToIncrease = 0
            }

            // First check if we have the right node
            if (_.isMatch(currentNode, predicate)) {
                result.push(currentNode)

                if (limit && result.length === limit) {
                    return result
                }
            }
            // It's not the right node, so mark as visited
            visited.push(currentNode)

            // for each not visited  add them to the queue
            _.forEach(neighbours, (node) => {
                if (!_.includes(visited, node)) {
                    queue.push(node)
                }
            })
        }

        return result
    }

    static searchAllDownstream(
        graph: unknown,
        rootNode: unknown,
        predicate: any,
        maxDepth: number,
        limit?: number
    ) {
        const queue = []
        const visited: any[] = []
        const result = []
        let depthCurrent = 0
        let depthElementsToIncrease = 1
        let depthNextElementsToIncrease = 0

        const rootNeighbors = GraphSearch.findDownstreamNeighbours(graph, rootNode)
        queue.push(...rootNeighbors)
        depthNextElementsToIncrease = rootNeighbors.length

        while (queue.length > 0) {
            const currentNode = queue.shift()

            // Find neighbour nodes
            const neighbours = GraphSearch.findDownstreamNeighbours(graph, currentNode)
            depthNextElementsToIncrease += neighbours.length

            if (--depthElementsToIncrease === 0) {
                if (maxDepth && ++depthCurrent > maxDepth) {
                    return result
                }
                depthElementsToIncrease = depthNextElementsToIncrease
                depthNextElementsToIncrease = 0
            }

            // First check if we have the right node
            if (_.isMatch(currentNode, predicate)) {
                result.push(currentNode)

                if (limit && result.length === limit) {
                    return result
                }
            }
            // It's not the right node, so mark as visited
            visited.push(currentNode)

            // for each not visited  add them to the queue
            _.forEach(neighbours, (node) => {
                if (!_.includes(visited, node)) {
                    queue.push(node)
                }
            })
        }

        return result
    }

    static findUpstreamNeighbours(graph: any, rootNode: any) {
        const edges = _.filter(graph.edges, ['target.nodeId', rootNode.id])

        return _.map(edges, (edge) => {
            return _.find(graph.nodes, { id: edge.source.nodeId })
        })
    }

    static findDownstreamNeighbours(graph: any, rootNode: any) {
        const edges = _.filter(graph.edges, ['source.nodeId', rootNode.id])

        return _.map(edges, (edge) => {
            return _.find(graph.nodes, { id: edge.target.nodeId })
        })
    }
}
