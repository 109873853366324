import _ from 'lodash'
import { BroadcastChannel } from 'broadcast-channel'

import FastMutex from 'fast-mutex/dist/fast-mutex'

const debug = require('debug')('map3:VideoAccess')

const COMMS_CHANNEL_MAP3_VIDEO = 'map3:video'
const MUTEX_CHANNEL_MAP3_VIDEO_EXCLUSIVITY = 'map3:video:exclusivity'
const MUTEX_TIMEOUT = 3 * 1000
const ACQUIRE_TIMEOUT = 1 * 1000
const document = window.document

let videoCookiesIframe

export class VideoNotReservedError extends Error {
    constructor(message = 'Video access could not be aquired') {
        super(message)
        this.name = 'VideoNotReservedError'
        Error.captureStackTrace(this, VideoNotReservedError)
    }
}

/**
 * Syncs video access between multiple browsers / tabs
 *
 * Requirements:
 *  - Watching a video requires a unique set of DRM cookies; A user can only hold one set
 *  of DRM cookies at a time, which means they can only watch one video at a time.
 *  - A user can watch the same video in multiple browsers / tabs.
 *  - Trying to open a second video while we have one already loaded must result in an error.
 *  - After closing the currently playing video, we're free to open a new one in a same or a
 *  different tab. A tab crashing should not prevent us from opening a new video in a new tab,
 *  ie, we cannot use any sort of persistent storage (localStorage/sessionStorage) to check if
 *  a video is playing in another tab.
 *
 * Approach of implementation:
 *
 * VideoAccess is a singleton that holds a `reservedVideoId` property in memory. This is the
 * currently playing video for this tab. VideoAccess uses BroadcastChannel to send and parse
 * messages between browsers/tabs. The messages are:
 *
 * Messages:
 *  - { question } - ask any other instance to report their current reservedVideoId
 *  - { response } - respond to { quiestion } with this instance's current reservedVideoId
 *  - { order }    - tell any other listening VideoAccess instances that we have taken access
 *  control and the reservedVideoId of the video we're playing
 *
 * The access aquisition process goes like this:
 *
 *   ‖ Aquire MUTEX_CHANNEL_MAP3_VIDEO_EXCLUSIVITY
 *   ‖
 *   ‖   ‖ Issue message { question, tryingToReserveId: id }
 *   ‖
 *   ‖   ‖ Parse messages { response, reservedVideoId }
 *   ‖
 *   ‖   ‖ Wait for ACQUIRE_TIMEOUT
 *   ‖
 *   ‖   ‖ Check there was a { response } with a different reservedVideoId
 *   ‖   ‖
 *   ‖-<-‖ if yes: -> AQUIRE FAILED, exit with failure
 *   ‖   ‖
 *   ‖   ‖ if no:
 *   ‖-<-‖ Issue message { order, reservedVideoId }
 *   ‖
 *   ‖
 *   ‖ Release Mutex
 *
 *   ‖If successful aquisition:
 *   ‖Async load DRM cookies iframe
 *   ‖
 *
 *
 * Normally the process above should avoid any sort of races to aquire a video, but sometimes
 * another tab can take longer than ACQUIRE_TIMEOUT to respond to our { question }, in which case
 * we can end up aquiring video even though another tab should have been holding the rights to play.
 *
 * In this case, we rely on the { order } message to tell the other tab that they lost access
 * (remember, DRM cookies allow playback of a single video per browser). The original tab can
 * gracefully enter an error state, before the player crashes because of loss of DRM decoding rights.
 */
class VideoAccess {
    constructor() {
        this.reservedVideoId = null

        this.mutex = new FastMutex({ timeout: MUTEX_TIMEOUT })
        this.channel = new BroadcastChannel(COMMS_CHANNEL_MAP3_VIDEO, { webWorkerSupport: false })

        this.channel.addEventListener('message', (msg) => {
            debug('receiv', { msg })

            if (msg.type === 'question') {
                const response = {
                    token: msg.token,
                    type: 'response',
                    reservedVideoId: this.reservedVideoId,
                }
                this.$postMessage(response)
            }

            // someone else forcefully took over video control, our access was revoked
            if (
                this.reservedVideoId &&
                msg.type === 'order' &&
                this.reservedVideoId !== msg.reservedVideoId
            ) {
                this.accessRevokedListeners.forEach((fn) => {
                    fn(msg)
                })
            }
        })

        this.accessRevokedListeners = []
    }

    /**
     * Try to aquire access to a particular video
     *
     * @param {Object} videoData
     * @return {Promise<Object>} videoData
     */
    acquire(videoData) {
        return this.$attemptReserveVideo(videoData)
            .then((success) => {
                if (!success) {
                    return Promise.reject(new VideoNotReservedError())
                }
            })
            .then(() => this.$loadDRMCookies(videoData))
    }

    /**
     * Release access to any currently held video
     */
    releaseVideo() {
        this.reservedVideoId = null
    }

    /**
     * @param {Function} fn Callback
     * @return {Function} Remove listener
     */
    addAccessRevokedListener(fn) {
        this.accessRevokedListeners.push(fn)

        const removeListener = () => {
            _.pull(this.accessRevokedListeners, fn)
        }

        return removeListener
    }

    /**
     * Do the actual access aquisition.
     * This method gets a mutex lock and queries other tabs for playing status, and then
     * returns a promise that resolves to true or false depending on aquisition success
     *
     * @param {Object} videoData
     * @return {Promise<boolean>} Success
     */
    $attemptReserveVideo(videoData) {
        const checkExclusivity = (videoData) => {
            let stopCriteria = false
            let token = randomToken(10)

            const handleMessage = (msg) => {
                if (msg.type === 'response' && msg.token === token) {
                    if (msg.reservedVideoId && msg.reservedVideoId !== videoData.id) {
                        stopCriteria = true
                    }
                }
            }
            this.channel.addEventListener('message', handleMessage)

            debug('send question', videoData.id)

            const ret = this.$postMessage({
                token,
                type: 'question',
                tryingToReserveId: videoData.id,
            })
                .then(() => sleep(ACQUIRE_TIMEOUT))
                .then(() => {
                    if (stopCriteria) return Promise.reject(new Error())
                })
                .then(() => {
                    this.reservedVideoId = videoData.id
                    this.$postMessage({ type: 'order', reservedVideoId: videoData.id })
                })
                .then(() => true)
                .catch(() => false)
                .then((success) => {
                    debug('success', success)
                    return success
                })
                .finally(() => {
                    this.channel.removeEventListener('message', handleMessage)
                })
            return ret
        }

        const ret = this.mutex
            .lock(MUTEX_CHANNEL_MAP3_VIDEO_EXCLUSIVITY)
            .then(() => checkExclusivity(videoData))
            // .catch(err => { console.error(err) })
            .finally(() => this.mutex.release(MUTEX_CHANNEL_MAP3_VIDEO_EXCLUSIVITY))

        return ret
    }

    /**
     * Given a video, if needed load its DRM cookies in a hidden iframe
     *
     * @param {Object} videoData
     * @return {Promise<Object>}
     */
    $loadDRMCookies(videoData) {
        return new Promise((resolve) => {
            // just return the video data if no cookie request param is present
            if (!_.has(videoData, 'video.cookieRequest')) {
                return resolve(videoData)
            }

            // remove leftover iframe from previous cookies acquire
            if (videoCookiesIframe) {
                document.body.removeChild(videoCookiesIframe)
            }

            // create a hidden video cookies iframe and wait for it to load
            videoCookiesIframe = document.createElement('iframe')
            videoCookiesIframe.setAttribute('src', videoData.video.cookieRequest)
            videoCookiesIframe.setAttribute('width', 0)
            videoCookiesIframe.setAttribute('height', 0)
            videoCookiesIframe.addEventListener('load', function () {
                // We need to execute our deferred resolve on the next browser tick
                // to make sure that the iframe JS has executed, because otherwise
                // we're in a race condition with it's <body onload> callback
                setTimeout(function () {
                    resolve(videoData)
                })
            })
            document.body.appendChild(videoCookiesIframe)
        })
    }

    /**
     * 'broadcast-channel'.postMessage() is equivalent to the following native code:
     *
     * postMessage(msg) {
     *   return new Promise(resolve, () => {
     *     (new BroadcastChannel('whatever')).postMessage(JSON.stringify(msg))
     *     setTimeout(resolve)
     *   })
     * }
     *
     * @param {Object} msg The message to be sent
     * @param {Promise<undefined>} fulfilled on the next browser tick after message sent
     */
    $postMessage(msg) {
        debug('post', { msg })
        return this.channel.postMessage(msg)
    }
}

export function sleep(time) {
    if (!time) time = 0
    return new Promise((resolve) => setTimeout(resolve, time))
}

export function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * https://stackoverflow.com/a/1349426/3443137
 */
export function randomToken(length) {
    if (!length) length = 5
    let text = ''
    const possible = 'abcdefghijklmnopqrstuvwxzy0123456789'

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
}

export default new VideoAccess()
