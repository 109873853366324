import _ from 'lodash'
import lockAndLoad from 'services/lockAndLoad'
import BuildsQueryFilters from './BuildsQueryFilters'
/**
 * @ngdoc directive
 * @name queryBuilder
 * @module map3.queryBuilderModule
 * @restrict E
 *
 * @description
 * Directive that wraps [jQuery QueryBuilder](http://querybuilder.js.org/)
 *
 * @param {object} table Table definition object
 * @param {object} json Bound object that represents the query builder json
 */
export default /* @ngInject */ function queryBuilder(QueryBuilderDataHandler) {
    const directive = {
        restrict: 'E',
        scope: {
            table: '<',
            json: '=',
        },
        template: `
            <section flex layout="column" layout-wrap class="qb-holder">
                <div class="query-holder">
                    <div class="query-builder"></div>
                </div>
            </section>
        `,
        link: lockAndLoad(
            [
                // eslint-disable-next-line import/no-webpack-loader-syntax
                import('script-loader!vendors/query-builder.standalone.min.js'),
                import('bootstrap-datepicker'),
                import('bootstrap-datepicker/dist/css/bootstrap-datepicker3.css'),
            ],
            queryBuilderLinkFn,
            angular.noop,
            null
        ),
    }

    const templates = {
        rule: `
            <li id="{{= it.rule_id }}" class="rule-container container-fluid">
                <div class="rule-container-wrap row">
                    <div class="rule-header col-2 order-2 text-right">
                        <div class="btn-group rule-actions">
                            <button type="button" class="btn btn-sm btn-outline-secondary" data-delete="rule">
                                <i class="material-icons md-18">delete</i>
                            </button>
                        </div>
                    </div>
                    <div class="rule-items-holder col-10">
                        <section class="row">
                            <div class="rule-filter-container col-4"></div>
                            <div class="rule-operator-container col-4"></div>
                            <div class="rule-value-container col-4"></div>
                        </section>
                    </div>
                </div>

                {{? it.settings.display_errors }}

                <div class="error-container">
                    <span class="msg">
                        Please select a value from the drop-down
                    </span>
                </div>
                {{?}}
            </li>
        `,
    }

    return directive

    ///////////////////////

    function queryBuilderLinkFn(scope, element, attrs, controller) {
        const isPreviewOnly = angular.isDefined(attrs.previewOnly)
        if (isPreviewOnly) {
            initPreviewOnlyQueryBuilder(scope.table, angular.fromJson(scope.json))
            return
        }

        let queryBuilder
        setupQueryBuilder()

        scope.$watch(
            'table',
            function (table) {
                if (queryBuilder) {
                    scope.json = undefined
                    destroyQueryBuilder(table)
                    if (table) {
                        initQueryBuilder(table)
                    }
                } else if (table) {
                    initQueryBuilder(table, getRules())
                }
            },
            /* deep */ true
        )

        scope.$watch('json', function () {
            if (queryBuilder && queryBuilder.length) {
                updateQueryBuilder(scope.table, getRules())
            }
        })
        scope.$on('$destroy', function () {
            QueryBuilderDataHandler.destroy()
        })

        /////////////////////////////////////////////////////////////

        function getRules() {
            const rules =
                queryBuilder && queryBuilder.length === 1
                    ? queryBuilder.queryBuilder('getRules')
                    : angular.fromJson(scope.json)

            return _.isEmpty(rules) ? undefined : rules
        }

        function initQueryBuilder(table, rules) {
            QueryBuilderDataHandler.buildFilters(table, rules).then(function (filters) {
                if (filters && filters.length) {
                    queryBuilder = element.find('.query-builder')

                    queryBuilder.queryBuilder({
                        rules,
                        filters,
                        templates,
                        plugins: ['map3-chosen-filter', 'map3-datepicker-input'],
                        allow_empty: true,
                    })

                    queryBuilder.on(
                        'afterUpdateRuleValue.queryBuilder afterSetRules.queryBuilder click.queryBuilder',
                        function () {
                            if (queryBuilder.queryBuilder('validate')) {
                                scope.json = angular.toJson(queryBuilder.queryBuilder('getRules'))
                                scope.$applyAsync()
                            }

                            queryBuilder.queryBuilder('clearErrors')
                        }
                    )
                }
            })
        }

        function initPreviewOnlyQueryBuilder(table, rules) {
            const valuesFromRules = _.map(rules.rules, (rule) => rule.label)
            const filters = BuildsQueryFilters.build(table.fields, valuesFromRules, rules)

            if (filters && filters.length) {
                queryBuilder = element
                    .find('.query-builder')
                    .on('afterSetRules.queryBuilder', () => {
                        $('button', element).prop('disabled', true)
                        $('select', element).prop('disabled', true)
                        $('select', element).trigger('chosen:updated')
                        $('input', element).prop('disabled', true)
                    })

                queryBuilder.queryBuilder({
                    rules,
                    filters,
                    templates,
                    allow_empty: true,
                })
            }
        }

        function updateQueryBuilder(table, rules) {
            const filters = queryBuilder.data('queryBuilder').filters

            QueryBuilderDataHandler.updateFilters(table, rules, filters).then(function (filters) {
                if (queryBuilder && filters && filters.length) {
                    let currentRules = queryBuilder.queryBuilder('getRules')

                    queryBuilder.queryBuilder('setFilters', filters)

                    if (_.size(currentRules)) {
                        // set current rules after updating filters dropdown
                        queryBuilder.queryBuilder('setRules', currentRules)
                    }

                    queryBuilder.queryBuilder('clearErrors')
                }
            })
        }

        function destroyQueryBuilder(table) {
            try {
                queryBuilder.queryBuilder('destroy')
            } catch (e) {}
            queryBuilder.remove()
            element.find('.query-holder').append('<div class="query-builder" />')
            queryBuilder = null
        }
    }
}

let qbSetupComplete = false

function setupQueryBuilder() {
    if (qbSetupComplete) {
        return
    }

    let QueryBuilder = angular.element.fn.queryBuilder.constructor

    QueryBuilder.define('map3-chosen-filter', function () {
        this.on('afterCreateRuleFilters', function (e, rule) {
            rule.$el.find(QueryBuilder.selectors.rule_filter).chosen()
        })

        this.on('afterCreateRuleOperators', function (e, rule) {
            rule.$el.find(QueryBuilder.selectors.rule_operator).chosen()
        })

        this.on('afterCreateRuleInput', function (e, rule) {
            if (_.get(rule, 'filter.input') === 'select') {
                rule.$el.find(QueryBuilder.selectors.rule_value).chosen()
            }
        })

        this.on('afterUpdateRuleFilter', function (e, rule) {
            rule.$el.find(QueryBuilder.selectors.rule_filter).trigger('chosen:updated')
        })

        this.on('afterUpdateRuleOperator', function (e, rule) {
            rule.$el.find(QueryBuilder.selectors.rule_operator).trigger('chosen:updated')
        })

        this.on('afterUpdateRuleValue', function (e, rule) {
            if (_.get(rule, 'filter.input') === 'select') {
                rule.$el.find(QueryBuilder.selectors.rule_value).trigger('chosen:updated')
            }
        })

        this.on('beforeDeleteRule', function (e, rule) {
            rule.$el.find(QueryBuilder.selectors.rule_filter).chosen('destroy')
            rule.$el.find(QueryBuilder.selectors.rule_operator).chosen('destroy')

            if (_.get(rule, 'filter.input') === 'select') {
                rule.$el.find(QueryBuilder.selectors.rule_value).chosen('destroy')
            }

            if (_.includes(['date', 'datetime'], _.get(rule, 'filter.type'))) {
                rule.$el.find(QueryBuilder.selectors.rule_value).datepicker('destroy')
            }
        })
    })

    QueryBuilder.define('map3-datepicker-input', function () {
        this.on('afterCreateRuleInput', function (e, rule) {
            if (_.includes(['date', 'datetime'], _.get(rule, 'filter.type'))) {
                rule.$el.find(QueryBuilder.selectors.rule_value).datepicker({
                    format: 'yyyy-mm-dd',
                    autoclose: true,
                })
            }
        })

        this.on('afterUpdateRuleValue', function (e, rule) {
            if (_.includes(['date', 'datetime'], _.get(rule, 'filter.type'))) {
                rule.$el.find(QueryBuilder.selectors.rule_value).datepicker('update')
            }
        })

        this.on('beforeDeleteRule', function (e, rule) {
            if (_.includes(['date', 'datetime'], _.get(rule, 'filter.type'))) {
                rule.$el.find(QueryBuilder.selectors.rule_value).datepicker('destroy')
            }
        })
    })

    qbSetupComplete = true

    return QueryBuilder
}
