const REGEX = /^http:\/\//

/**
 * convert http://test.org/CS/WorkflowTemplate/ncc1a6b08-0e03-11e8-9f3b-0a70288577a3
 * to ncc1a6b08-0e03-11e8-9f3b-0a70288577a3
 *
 * if the given value does not look like a URI, do not convert
 *
 * @param string fullIRI
 * @return string uri
 */
export default function extractId(fullUri: string) {
    if (REGEX.test('' + fullUri)) {
        return fullUri.split('/').pop()
    }

    return fullUri
}
