import angular from 'angular'
import MapCachedImmutableStateToThis from '../../services/MapCachedImmutableStateToThis'
import { SceneHelperInstance } from '../../services/scenes/SceneHelper.factory'
import { ActActionsInstance } from '../../services/scenes/ActActions.factory'
import {
    GlobalShortcutsInstance,
    ShortcutDefinition,
} from '../../global-shortcuts/GlobalShortcuts.factory'
import { VideoAPIInstance } from '../../video/VideoAPIBuilder.factory'
import {
    RENDER_MODE_DEFAULT,
    RENDER_MODE_CONDENSED,
    RENDER_MODE_HIDDEN,
    TimelineElement,
} from './genericTimeline.component'
import type ngRedux from 'ng-redux'

type Act = TimelineElement

const actsTimelineComponent = {
    controller: actsTimelineController,
    require: {
        sceneSplittingCtrl: '^^sceneSplitting',
    },
    bindings: {
        acts: '<?',
        filmstripType: '<?',
        renderMode: '<?',
    },
    template: `
        <generic-timeline
            elements="$ctrl.acts"
            on-single-select="$ctrl.handleOnSingleSelect(element)"
            on-multi-select="$ctrl.handleOnMultiSelect(indexRange)"
            on-resize="$ctrl.handleOnResize(resizeData)"
            filmstrip-type="$ctrl.filmstripType"
            render-mode="$ctrl.renderMode"
        ></generic-timeline>
    `,
}

export default actsTimelineComponent

type Bindings = {
    acts: Act[]
    filmstripType?: string
    renderMode?:
        | typeof RENDER_MODE_DEFAULT
        | typeof RENDER_MODE_CONDENSED
        | typeof RENDER_MODE_HIDDEN
}

/* @ngInject */
function actsTimelineController(
    this: unknown,
    $element: ng.IRootElementService,
    $scope: ng.IScope,
    $ngRedux: ngRedux.INgRedux,
    ActActions: ActActionsInstance,
    SceneHelper: SceneHelperInstance,
    SharedVideoAPI: any,
    GlobalShortcuts: GlobalShortcutsInstance
) {
    type ActsTimelineComponentInstance = ng.IComponentController &
        Bindings & {
            $onInit: typeof $onInit
            videoApi: VideoAPIInstance

            handleOnSingleSelect: typeof handleOnSingleSelect
            handleOnMultiSelect: typeof handleOnMultiSelect
            handleOnResize: typeof handleOnResize
        }
    const $ctrl = this as ActsTimelineComponentInstance

    $ctrl.$onInit = $onInit

    $ctrl.handleOnSingleSelect = angular.noop
    $ctrl.handleOnMultiSelect = angular.noop
    $ctrl.handleOnResize = angular.noop

    return $ctrl

    ///////////////////////////////

    function $onInit() {
        $element.addClass('generic-timeline-holder')

        SharedVideoAPI.onLoad(handleVideoApiReady)

        if ($ctrl.sceneSplittingCtrl.getInteractiveMode()) {
            const mapStateToThis = MapCachedImmutableStateToThis({
                'sceneData.present.acts': 'acts',
            })

            const unsubscribe = $ngRedux.connect(mapStateToThis)($ctrl)
            $scope.$on('$destroy', unsubscribe)
            $scope.$on('$destroy', mapStateToThis.clear)
        }
    }

    function handleVideoApiReady(videoApiParam: VideoAPIInstance) {
        $ctrl.videoApi = videoApiParam

        if ($ctrl.sceneSplittingCtrl.getInteractiveMode()) {
            setupShortcuts()

            $ctrl.handleOnSingleSelect = handleOnSingleSelect
            $ctrl.handleOnMultiSelect = handleOnMultiSelect
            $ctrl.handleOnResize = handleOnResize
        }
    }

    function setupShortcuts() {
        const viewShortcuts: ShortcutDefinition[] = [
            {
                description: 'Next Act',
                keyCombo: 'shift+]',
                shortcut: 'shift+]',
                callback: () => {
                    ActActions.selectNext()
                    SceneHelper.goToSelectedStart($ctrl.acts, $ctrl.videoApi)
                },
            },
            {
                description: 'Previous Act',
                keyCombo: 'shift+[',
                shortcut: 'shift+[',
                callback: () => {
                    ActActions.selectPrevious()
                    SceneHelper.goToSelectedStart($ctrl.acts, $ctrl.videoApi)
                },
            },
            {
                description: 'Jump to Act Start',
                keyCombo: 'shift+a',
                shortcut: 'shift+A',
                callback: () => SceneHelper.goToSelectedStart($ctrl.acts, $ctrl.videoApi),
            },
            {
                description: 'Jump to Act End',
                keyCombo: 'shift+d',
                shortcut: 'shift+D',
                callback: () => SceneHelper.goToSelectedEnd($ctrl.acts, $ctrl.videoApi),
            },
        ]

        const editShortcutsBase: ShortcutDefinition[] = [
            {
                description: 'Split Act at Playhead',
                keyCombo: 'shift+x',
                shortcut: 'shift+X',
                callback: () => ActActions.splitByVideoCurrentTime($ctrl.videoApi),
            },
            {
                description: 'Merge Selected Acts',
                keyCombo: 'shift+m',
                shortcut: 'shift+M',
                callback: () => ActActions.mergeSelected(),
            },
            {
                description: 'Move Act Start Forward By One Frame',
                global: true,
                keyCombo: ['shift+ctrl+right', 'shift+meta+right'],
                shortcut: 'shift+control+Right Arrow',
                callback: () => {
                    ActActions.moveSelectedStartFrame(1)
                    SceneHelper.goToSelectedStart($ctrl.acts, $ctrl.videoApi)
                },
            },
            {
                description: 'Move Act Start Backward By One Frame',
                global: true,
                keyCombo: ['shift+ctrl+left', 'shift+meta+left'],
                shortcut: 'shift+control+Left Arrow',
                callback: () => {
                    ActActions.moveSelectedStartFrame(-1)
                    SceneHelper.goToSelectedStart($ctrl.acts, $ctrl.videoApi)
                },
            },
            {
                description: 'Move Act End Forward By One Frame',
                global: true,
                keyCombo: 'shift+alt+right',
                shortcut: 'shift+Alt+Right Arrow',
                callback: () => {
                    ActActions.moveSelectedEndFrame(1)
                    SceneHelper.goToSelectedEnd($ctrl.acts, $ctrl.videoApi)
                },
            },
            {
                description: 'Move Act End Backward By One Frame',
                global: true,
                keyCombo: 'shift+alt+left',
                shortcut: 'shift+Alt+Left Arrow',
                callback: () => {
                    ActActions.moveSelectedEndFrame(-1)
                    SceneHelper.goToSelectedEnd($ctrl.acts, $ctrl.videoApi)
                },
            },
        ]

        const shortcuts = viewShortcuts.concat(
            $ctrl.sceneSplittingCtrl.getEditMode() ? editShortcutsBase : []
        )

        const unbind = GlobalShortcuts.bind({ title: 'Act Shortcuts', shortcuts })
        $scope.$on('$destroy', unbind)
    }

    function handleOnSingleSelect(act: Act) {
        ActActions.selectById(act.id)
    }

    function handleOnMultiSelect(indexRange: number[]) {
        ActActions.selectByIndex(indexRange)
    }

    function handleOnResize({
        rightElementId,
        offsetFrames,
    }: {
        rightElementId: number
        offsetFrames: number
    }) {
        ActActions.moveStartFrame({
            id: rightElementId,
            offsetFrames,
        })
    }
}
