import parse from 'date-fns/parse'
import isValid from 'date-fns/is_valid'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'

export default /* @ngInject */ function fromNowFilter() {
    return (timestamp: Date | string | number) => {
        const date = parse(timestamp)

        return isValid(date) ? distanceInWordsToNow(date) : timestamp
    }
}
