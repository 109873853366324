import _ from 'lodash'
import MapCachedImmutableStateToThis from 'services/MapCachedImmutableStateToThis'

const scenesTimelineComponent = {
    controller: scenesTimelineController,
    require: {
        sceneSplittingCtrl: '^^sceneSplitting',
    },
    bindings: {
        scenes: '<?',
        filmstripType: '<?',
        renderMode: '<?',
    },
    template: `
        <generic-timeline
            elements="$ctrl.scenes"
            on-single-select="$ctrl.handleOnSingleSelect(element)"
            on-multi-select="$ctrl.handleOnMultiSelect(indexRange)"
            on-resize="$ctrl.handleOnResize(resizeData)"
            filmstrip-type="$ctrl.filmstripType"
            render-mode="$ctrl.renderMode"
        ></generic-timeline>
    `,
}

export default scenesTimelineComponent

/* @ngInject */
function scenesTimelineController(
    $element,
    $scope,
    $ngRedux,
    SceneActions,
    SceneHelper,
    SharedVideoAPI,
    GlobalShortcuts
) {
    const $ctrl = this

    $ctrl.$onInit = $onInit

    $ctrl.handleOnSingleSelect = angular.noop
    $ctrl.handleOnMultiSelect = angular.noop
    $ctrl.handleOnResize = angular.noop

    return $ctrl

    ///////////////////////////////

    function $onInit() {
        $element.addClass('generic-timeline-holder')

        SharedVideoAPI.onLoad(handleVideoApiReady)

        if ($ctrl.sceneSplittingCtrl.getInteractiveMode()) {
            const mapStateToThis = MapCachedImmutableStateToThis(
                {
                    'sceneData.present.acts': 'acts',
                    'sceneData.present.scenes': 'scenes',
                },
                ({ acts, scenes }) => {
                    scenes = _.map(scenes, (scene) => {
                        return { ...scene, $parent: _.find(acts, { id: scene.parentId }) }
                    })

                    return { scenes }
                }
            )

            const unsubscribe = $ngRedux.connect(mapStateToThis)($ctrl)
            $scope.$on('$destroy', unsubscribe)
            $scope.$on('$destroy', mapStateToThis.clear)
        }
    }

    function handleVideoApiReady(videoApiParam) {
        $ctrl.videoApi = videoApiParam

        if ($ctrl.sceneSplittingCtrl.getInteractiveMode()) {
            setupShortcuts()

            $ctrl.handleOnSingleSelect = handleOnSingleSelect
            $ctrl.handleOnMultiSelect = handleOnMultiSelect
            $ctrl.handleOnResize = handleOnResize
        }
    }

    function setupShortcuts() {
        const viewShortcuts = [
            {
                description: 'Next Scene',
                keyCombo: ']',
                shortcut: ']',
                callback: () => {
                    SceneActions.selectNext()
                    SceneHelper.goToSelectedStart($ctrl.scenes, $ctrl.videoApi)
                },
            },
            {
                description: 'Previous Scene',
                keyCombo: '[',
                shortcut: '[',
                callback: () => {
                    SceneActions.selectPrevious()
                    SceneHelper.goToSelectedStart($ctrl.scenes, $ctrl.videoApi)
                },
            },
            {
                description: 'Jump to Scene Start',
                keyCombo: 'a',
                shortcut: 'A',
                callback: () => SceneHelper.goToSelectedStart($ctrl.scenes, $ctrl.videoApi),
            },
            {
                description: 'Jump to Scene End',
                keyCombo: 'd',
                shortcut: 'D',
                callback: () => SceneHelper.goToSelectedEnd($ctrl.scenes, $ctrl.videoApi),
            },
        ]

        const editShortcuts = [
            {
                description: 'Split Scene at Playhead',
                keyCombo: 'x',
                shortcut: 'X',
                callback: () => SceneActions.splitByVideoCurrentTime($ctrl.videoApi),
            },
            {
                description: 'Merge Selected Scenes',
                keyCombo: 'm',
                shortcut: 'M',
                callback: () => SceneActions.mergeSelected(),
            },
            {
                description: 'Move Scene Start Forward By One Frame',
                global: true,
                keyCombo: ['ctrl+right', 'meta+right'],
                shortcut: 'control+Right Arrow',
                callback: () => {
                    SceneActions.moveSelectedStartFrame(1)
                    SceneHelper.goToSelectedStart($ctrl.scenes, $ctrl.videoApi)
                },
            },
            {
                description: 'Move Scene Start Backward By One Frame',
                global: true,
                keyCombo: ['ctrl+left', 'meta+left'],
                shortcut: 'control+Left Arrow',
                callback: () => {
                    SceneActions.moveSelectedStartFrame(-1)
                    SceneHelper.goToSelectedStart($ctrl.scenes, $ctrl.videoApi)
                },
            },
            {
                description: 'Move Scene End Forward By One Frame',
                global: true,
                keyCombo: ['alt+right'],
                shortcut: 'Alt+Right Arrow',
                callback: () => {
                    SceneActions.moveSelectedEndFrame(1)
                    SceneHelper.goToSelectedEnd($ctrl.scenes, $ctrl.videoApi)
                },
            },
            {
                description: 'Move Scene End Backward By One Frame',
                global: true,
                keyCombo: ['alt+left'],
                shortcut: 'Alt+Left Arrow',
                callback: () => {
                    SceneActions.moveSelectedEndFrame(-1)
                    SceneHelper.goToSelectedEnd($ctrl.scenes, $ctrl.videoApi)
                },
            },
        ]

        const shortcuts = viewShortcuts.concat(
            $ctrl.sceneSplittingCtrl.getEditMode() ? editShortcuts : []
        )

        const unbind = GlobalShortcuts.bind({ title: 'Scene Shortcuts', shortcuts })
        $scope.$on('$destroy', unbind)
    }

    function handleOnSingleSelect(scene) {
        SceneActions.selectById(scene.id)
    }

    function handleOnMultiSelect(indexRange) {
        SceneActions.selectByIndex(indexRange)
    }

    function handleOnResize({ leftElementId, rightElementId, offsetFrames }) {
        SceneActions.moveStartFrame({
            id: rightElementId,
            offsetFrames,
        })
    }
}
