/**
 * @ngdoc component
 * @name qaTaskList
 * @module map3.tasks
 * @description
 * <qa-task-list> component is a common render approach for the task list
 * tables for QAers. It concentrates all our styling and implementation in one place,
 * allowing us to vary only what we need to: The per-task actions.
 *
 * The component has a required `<actions>` transclude slot. Inside it, a `task` variable
 * representig the object used for the current table row is presented. The `task` object
 * itself can be either a true task, or an assignment. It's up to the code in `<actions>`
 * to handle either case accordingly.
 */
export const qaTaskListComponent = {
    transclude: {
        actions: 'actions',
    },
    bindings: {
        predicatePerfield: '=',
        predicateOrderBy: '=',
        tasks: '<',
    },
    template: `
        <section class="container-fluid my-3 py-1">
            <header class="row">
                <div class="d-flex align-items-center">
                    <form class="form-inline flex-grow justify-content-between">
                        <input
                            class="form-control"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            ng-model="$ctrl.searchString"
                        />
                    </form>
                </div>
            </header>
        </section>
        <table class="table">
            <thead fsm-sticky-header>
                <th
                    class="txt-xl"
                    table-order
                    single
                    by="title"
                    predicate="$ctrl.predicateOrderBy"
                >
                    Task
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order
                            by="job_priority"
                            single
                            default
                            predicate="$ctrl.predicateOrderBy"
                            predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                            >Priority</table-order
                        >
                        <field-filter
                            items="$ctrl.tasks"
                            field="job_priority_label"
                            title="Priority"
                            options="OPTIONS_JOB_PRIORITY_LABELS"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order single by="user" predicate="$ctrl.predicateOrderBy" predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                            >Worker</table-order
                        >
                        <field-filter
                            items="$ctrl.tasks"
                            field="user"
                            title="Worker"
                            include-empty="true"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th table-order single by="date" predicate="$ctrl.predicateOrderBy">
                    Created
                </th>
                <th
                    table-order
                    single
                    by="qa_ready_date"
                    predicate="$ctrl.predicateOrderBy"
                    predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                    class="text-nowrap"
                >
                    QA Ready
                </th>
                <th table-order single by="due_date" predicate="$ctrl.predicateOrderBy">
                    Due
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order
                            single
                            by="job_name"
                            predicate="$ctrl.predicateOrderBy"
                            predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                            >Job Name</table-order
                        >
                        <field-filter
                            items="$ctrl.tasks"
                            field="job_name"
                            title="Job Name"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th table-order single by="eidrL2" predicate="$ctrl.predicateOrderBy">
                    EIDR L2
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order
                            single
                            by="project_name"
                            predicate="$ctrl.predicateOrderBy"
                            predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                            >Project</table-order
                        >
                        <field-filter
                            items="$ctrl.tasks"
                            field="project_name"
                            title="Project"
                            include-empty="true"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th>Actions</th>
            </thead>
            <tbody>
                <tr ng-repeat="task in $ctrl.paginatedTasks">
                    <td>{{ ::task.title }}</td>
                    <td ng-class="::{'text-danger': task.job_priority === 50}">
                        {{ ::task.job_priority_label }}
                    </td>
                    <td>{{ ::task.user.join(', ') }}</td>
                    <td>
                        <tz-date date="::task.date"></tz-date>
                    </td>
                    <td>
                        <tz-date date="::task.qa_ready_date"></tz-date>
                    </td>
                    <td>
                        <tz-date date="::task.due_date"></tz-date>
                    </td>
                    <td>{{ ::task.job_name }}</td>
                    <td>{{ ::task.eidrL2 }}</td>
                    <td>{{ ::task.project_name }}</td>
                    <td class="actions">
                        <qa-task-list-actions task="task"></qa-task-list-actions>
                    </td>
                </tr>
                <tr ng-if="$ctrl.paginatedTasks.length == 0">
                    <td colspan="99">No results</td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
                <ul
                    uib-pagination
                    total-items="$ctrl.totalItems"
                    ng-model="$ctrl.currentPage"
                    max-size="5"
                    items-per-page="$ctrl.itemsPerPage"
                    boundary-link-numbers="true"
                    rotate="false"
                ></ul>
            </div>
    `,
    controller: qaTaskListCtrl,
}

const SEARCHABLE_FIELDS = [
    'title',
    'project_name',
    'user',
    'job_name',
    'job_priority_label',
    'eidrL2',
]

/* @ngInject */
function qaTaskListCtrl(
    this: unknown,
    $scope: ng.IScope,
    $attrs: ng.IAttributes,
    $transclude: ng.ITranscludeFunction,
    $filter: ng.IFilterFunction
) {
    const $ctrl = this as ng.IController & {
        $onInit: typeof $onInit
    }

    $ctrl.$onInit = $onInit

    return $ctrl

    ////////////////////

    function $onInit() {
        $ctrl.totalItems = $ctrl.tasks.length
        $ctrl.currentPage = 1
        $ctrl.itemsPerPage = 25

        $ctrl.predicateOrderByName = $attrs.predicateOrderBy
        $ctrl.predicatePerfieldName = $attrs.predicatePerfield

        $ctrl.parentScope = $scope.$parent
        $ctrl.$transclude = $transclude

        $scope.$watch('$ctrl.predicatePerfield', prepareJobsArray, true)
        $scope.$watchGroup(['$ctrl.currentPage', '$ctrl.searchString'], prepareJobsArray)
        $scope.$watchCollection('$ctrl.tasks', prepareJobsArray)
        $scope.$watchCollection('$ctrl.predicateOrderBy', prepareJobsArray)

        $scope.$on('$destroy', () => {
            $ctrl.parentScope = null
            $ctrl.$transclude = null
        })
    }

    function prepareJobsArray() {
        const filteredTasks = $filter('perfieldFilter')($ctrl.tasks, $ctrl.predicatePerfield)
        const orderedTasks = $filter('orderBy')(filteredTasks, $ctrl.predicateOrderBy)

        const filteredBySearch = $ctrl.searchString
            ? $filter('searchByFieldFilter')(orderedTasks, SEARCHABLE_FIELDS, $ctrl.searchString)
            : orderedTasks

        $ctrl.totalItems = filteredBySearch.length
        $ctrl.paginatedTasks = filteredBySearch.slice(
            ($ctrl.currentPage - 1) * $ctrl.itemsPerPage,
            $ctrl.currentPage * $ctrl.itemsPerPage
        )
    }
}

export /* @ngInject */ function qaTaskListActionsDirective() {
    const directive = {
        require: '^qaTaskList',
        link: qaTaskListActionsLinkFn as ng.IDirectiveLinkFn,
    }

    return directive

    ///////////////////////////

    function qaTaskListActionsLinkFn(
        scope: ng.IScope,
        element: ng.IRootElementService,
        attrs: ng.IAttributes,
        qaTaskListCtrl: ng.IController
    ) {
        const task = scope.$eval(attrs.task)

        const newScope = qaTaskListCtrl.parentScope.$new(false)
        newScope.task = task

        qaTaskListCtrl.$transclude(
            newScope,
            (actions: JQLite) => {
                element.append(actions)
            },
            null,
            'actions'
        )

        scope.$on('$destroy', () => newScope.$destroy())
    }
}
