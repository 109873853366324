import _ from 'lodash'
import { ResultsModuleInstance } from './ResultsModule.factory'
import { ExportStatus } from 'constants.es6'

export default /* @ngInject */ function ExportStatusPollerFactory(
    $timeout: ng.ITimeoutService,
    ResultsModule: ResultsModuleInstance
) {
    return class ExportStatusPoller {
        static POLLING_INTERVAL = 2000
        static EXPORT_IN_QUEUE = 'in_queue'
        static EXPORT_IN_PROGRESS = 'in_progress'
        static EXPORT_NO_RESULTS = 'no_results'
        static STATUS_POLLING = ExportStatus.Polling
        static STATUS_DOWNLOAD_READY = ExportStatus.DownloadReady
        static STATUS_NO_RESULTS = ExportStatus.NoResults
        static STATUS_FAILED = ExportStatus.Failed

        vm: any
        exportModel: any
        timeout: ng.IPromise<void> | null
        destroyed: boolean

        constructor(vm: any, exportModel: any) {
            this.vm = vm
            this.exportModel = exportModel
            this.timeout = null
            this.destroyed = false

            this.init()
        }

        init() {
            this.executePoll()
        }

        executePoll() {
            this.vm.status = ExportStatusPoller.STATUS_POLLING

            ResultsModule.exportGet(this.exportModel.token)
                .then((updatedModel) => {
                    if (!this.destroyed) {
                        _.assignIn(this.exportModel, updatedModel)
                    }
                })
                .finally(() => {
                    if (this.destroyed) {
                        return
                    }

                    if (
                        this.exportModel.status === ExportStatusPoller.EXPORT_IN_QUEUE ||
                        this.exportModel.status === ExportStatusPoller.EXPORT_IN_PROGRESS
                    ) {
                        this.timeout = $timeout(
                            this.executePoll.bind(this),
                            ExportStatusPoller.POLLING_INTERVAL
                        )
                    } else if (this.exportModel.status === ExportStatusPoller.EXPORT_NO_RESULTS) {
                        this.vm.status = ExportStatusPoller.STATUS_NO_RESULTS
                    } else if (this.exportModel.status === ExportStatusPoller.STATUS_FAILED) {
                        this.vm.status = ExportStatusPoller.STATUS_FAILED
                    } else {
                        this.vm.status = ExportStatusPoller.STATUS_DOWNLOAD_READY
                    }
                })
        }

        destroy() {
            if (this.timeout) {
                $timeout.cancel(this.timeout)
            }

            this.vm = null
            this.exportModel = null
            this.destroyed = true
        }
    }
}

export type ExportStatusPollerInstance = ReturnType<typeof ExportStatusPollerFactory>
