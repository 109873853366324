import dateParse from 'date-fns/parse'
import dateFormat from 'date-fns/format'
import dateIsValid from 'date-fns/is_valid'
import addDays from 'date-fns/add_days'
import { AdminModuleInstance } from './AdminModule.factory'

interface AdminManageJobKeepAliveCtrl {
    jobInfo: TJob
    minKeepAliveDate: Date
    newKeepAliveDate: Date
    expiryDateLabel: string

    disableKeepAlive: () => void
    submit: () => void
}

export default /* @ngInject */ function AdminManageJobKeepAliveCtrl(
    this: unknown,
    $state: ng.ui.IStateService,

    JOB_KEEP_ALIVE_EXTENSION_DAYS: number,

    AdminModule: AdminModuleInstance,
    RoutingAuth: any,
    Notification: any,
    MapDialog: any,

    jobInfo: TJob
) {
    const vm = this as AdminManageJobKeepAliveCtrl

    vm.jobInfo = jobInfo
    vm.minKeepAliveDate = generateMinKeepAlive()
    vm.newKeepAliveDate = generateNewKeepAlive(jobInfo.expiryDate)
    vm.expiryDateLabel = jobInfo.expiryDate
        ? dateFormat(dateParse(jobInfo.expiryDate), 'YYYY-MM-DD')
        : 'No previous Keep Alive Expiry Date'

    vm.disableKeepAlive = disableKeepAlive
    vm.submit = submit

    activate()

    return vm

    ///////////////////////////////////////

    function activate() {
        if (!vm.jobInfo.keepAlive) {
            Notification.error('Keep Alive is disabled for this Job.', /* nextTick */ true)
            $state.go(RoutingAuth.getDefaultState(), undefined, { reload: true })
        }
    }

    function generateMinKeepAlive() {
        return addDays(new Date(), 1)
    }

    function generateNewKeepAlive(expiryDate: Date | undefined) {
        let date = dateParse(expiryDate || '')
        if (!expiryDate || !dateIsValid(date)) {
            date = new Date()
        }

        return addDays(date, JOB_KEEP_ALIVE_EXTENSION_DAYS)
    }

    function disableKeepAlive() {
        const confirm = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to disable Keep Alive for this job?')
            .ok('Yes')
            .cancel('No')

        MapDialog.show(confirm)
            .then(() => {
                const promise = AdminModule.disableKeepAlive(jobInfo)

                Notification.forPromise(promise, 'Successfully disabled Keep Alive.')

                return promise
            })
            .then(() => {
                $state.go(RoutingAuth.getDefaultState(), undefined, { reload: true })
            })
    }

    function submit() {
        const promise = AdminModule.setKeepAlive(jobInfo.uri, vm.newKeepAliveDate)
        Notification.forPromise(promise, 'Successfully set new Keep Alive.')

        promise.then(() => {
            $state.go(RoutingAuth.getDefaultState(), undefined, { reload: true })
        })
    }
}

AdminManageJobKeepAliveCtrl.resolve = {
    jobInfo: /* @ngInject */ function (
        $stateParams: ng.ui.IStateParamsService,
        AdminModule: AdminModuleInstance
    ) {
        return AdminModule.getJobInfo($stateParams.jobId)
    },
}
