import _ from 'lodash'

/**
 * @ngdoc directive
 * @name autoLowercase
 * @module map3.core
 * @restrict A
 *
 * @description
 * This directive will transform any input to `<input>` or `<textarea>` to lowercase.
 */
export default function autoLowercaseDirective() {
    var directive = {
        restrict: 'A',
        require: 'ngModel',
        link: autoLowercaseLinkFn,
    }

    return directive

    ////////////////////

    function autoLowercaseLinkFn(scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$parsers.push(conditionalLowercase)
        ngModelCtrl.$formatters.push(conditionalLowercase)

        function conditionalLowercase(value) {
            if (value) {
                value = _.toString(value).toLowerCase()

                // set the intermediate input value to be lowercase as well
                if (element.val() !== value) {
                    element.val(value)
                }
            }

            return value
        }
    }
}
