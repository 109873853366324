import _ from 'lodash'
import { QA_ROUTES } from 'constants.es6'

import QAListCtrl from './List.ctrl'
import QAReviewQuestionsCtrl from './ReviewQuestions.ctrl'
import SplitScenesCtrl from 'scenes/SplitScenes.ctrl'
import HighlightVideoCtrl from 'highlights/HighlightVideo.ctrl'
import QAAuthorityTaskCtrl from './ReviewAuthority.ctrl'
import ReviewContentMarkersCtrl from 'content-markers/ReviewContentMarkers.ctrl'
import ContentMarkersCtrl from 'content-markers/ContentMarkers.ctrl'

import softInvariant from 'util/softInvariant'
import { IQAAssignment, QAAssignmentClass } from './types'

export default /* @ngInject */ function qaRoutesConfig($stateProvider: ng.ui.IStateProvider) {
    // define qa states
    $stateProvider
        .state('qa', {
            url: '/qa',
            abstract: true,
            template: '<ui-view></ui-view>',
            data: { roles: ['QA'] },
        })
        .state(QA_ROUTES.QA_LIST.STATE_NAME, {
            url: QA_ROUTES.QA_LIST.URL,
            templateUrl: 'js/qa/list.html',
            controller: QAListCtrl,
            controllerAs: 'listVm',
            resolve: QAListCtrl.resolve,
        })
        .state('qa.review', {
            url: '/review',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('qa.review.questions', {
            url: '/:id/questions',
            templateUrl: 'js/qa/review.questions.html',
            controller: QAReviewQuestionsCtrl,
            controllerAs: 'vm',
            resolve: QAReviewQuestionsCtrl.resolve,
            data: {
                trackTime: { userType: 'qa', taskType: 'hit', taskId: 'id' },
                showInBugReport: true,
                forceReload: true,
            },
        })
        .state('qa.review.scenes', {
            url: '/:id/scenes',
            templateUrl: 'js/scenes/SplitScenes.tpl.html',
            controller: SplitScenesCtrl,
            controllerAs: SplitScenesCtrl.controllerAs,
            resolve: SplitScenesCtrl.resolveQA,
            data: {
                trackTime: { userType: 'qa', taskType: 'hit', taskId: 'id' },
                showInBugReport: true,
                forceReload: true,
            },
        })
        .state('qa.review.highlightVideo', {
            url: '/:id/highlight-video',
            templateUrl: 'js/highlights/HighlightVideo.html',
            controllerAs: 'vm',
            controller: HighlightVideoCtrl,
            resolve: HighlightVideoCtrl.resolveQA,
            data: {
                trackTime: { userType: 'qa', taskType: 'hit', taskId: 'id' },
                showInBugReport: true,
                forceReload: true,
            },
        })
        .state(QA_ROUTES.REVIEW_CONTENT_MARKERS.STATE_NAME, {
            url: QA_ROUTES.REVIEW_CONTENT_MARKERS.URL,
            templateUrl: 'js/content-markers/review.content-markers.html',
            controllerAs: 'vm',
            controller: ReviewContentMarkersCtrl,
            resolve: ReviewContentMarkersCtrl.resolve,
            data: {
                trackTime: { userType: 'qa', taskType: 'hit', taskId: 'id' },
                showInBugReport: true,
            },
        })
        .state(QA_ROUTES.CONTENT_MARKERS.STATE_NAME, {
            url: QA_ROUTES.CONTENT_MARKERS.URL,
            templateUrl: 'js/content-markers/content-markers.html',
            controllerAs: 'vm',
            controller: ContentMarkersCtrl,
            resolve: ContentMarkersCtrl.resolveQA,

            data: {
                trackTime: { userType: 'qa', taskType: 'hit', taskId: 'id' },
                showInBugReport: true,
            },
        })
        .state('qa.review.authority', {
            url: '/:id/authority',
            templateUrl: 'js/qa/review.authority.html',
            controllerAs: 'qaAuthorityVm',
            controller: QAAuthorityTaskCtrl,
            resolve: QAAuthorityTaskCtrl.resolve,
            data: {
                trackTime: { userType: 'qa', taskType: 'hit', taskId: 'id' },
                showInBugReport: true,
                forceReload: true,
            },
        })
}

export /* @ngInject */ function QARouterFactory($state: ng.ui.IStateService) {
    const STATE_FOR_CLASS = {
        [QAAssignmentClass.joinQuestions]: 'qa.review.questions',
        [QAAssignmentClass.tagScenes]: 'qa.review.scenes',
        [QAAssignmentClass.cutntag]: 'qa.review.highlightVideo',
        [QAAssignmentClass.authorityTagging]: 'qa.review.authority',
        [QAAssignmentClass.marker]: QA_ROUTES.CONTENT_MARKERS.STATE_NAME,
        [QAAssignmentClass.markerReview]: QA_ROUTES.REVIEW_CONTENT_MARKERS.STATE_NAME,
    }

    class QARouter {
        static stateFor(assignment: IQAAssignment) {
            const state = STATE_FOR_CLASS[assignment['class']]

            softInvariant(
                !!state,
                'Unsupported Assignment type: "%s" for task %s',
                assignment['class'],
                angular.toJson(assignment)
            )

            return state
        }

        static urlFor(assignment: IQAAssignment) {
            return $state.href(QARouter.stateFor(assignment), { id: QARouter.getId(assignment) })
        }

        static go(assignment: IQAAssignment) {
            return $state.go(QARouter.stateFor(assignment), { id: QARouter.getId(assignment) })
        }

        static getId(assignment: IQAAssignment) {
            return _.last(_.split(assignment['id'], '/'))
        }
    }

    return QARouter
}

export type QARouterInstance = ReturnType<typeof QARouterFactory>
