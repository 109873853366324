import _ from 'lodash'
import dateParse from 'date-fns/parse'
import dateIsWithinRange from 'date-fns/is_within_range'

/**
 * @ngdoc filter
 * @name perfieldFilter
 * @module map3.core
 * @kind function
 *
 * @description
 * A special version of [filter](https://docs.angularjs.org/api/ng/filter/filter) that
 * takes a `perfilterPredicate` generated from {@link fieldFilter}.
 *
 * @param {Array} array The source array.
 * @param {Object} perfilterPredicate A predicate object of where each prop is an object of
 *      unique props and a `true/false` denoting if items with that prop are allowed.
 * @param {Function | boolean} comparator Comparator which is used in determining if values
 *      retrieved using expression. Defaults to false.
 * @param {boolean | undefined} force  Force the filter to execute even if the field is not active
 * @return {Array} The filtered array.
 */
export default /* @ngInject */ function perfieldFilterFilter($filter, PERFIELD_EMPTY_VALUE_KEY) {
    let filterFilter = $filter('filter')

    return function (items, perfilterPredicate, comparator, force) {
        return filterFilter(items, predicateFn)

        ////////////

        function predicateFn(item) {
            if (!perfilterPredicate) {
                return item
            }

            return _.every(_.keys(perfilterPredicate), (field) => {
                const fieldValue = _.get(item, field)
                const fieldPredicate = perfilterPredicate[field]

                if (
                    fieldPredicate &&
                    !fieldPredicate.__REMOVE_FROM_PREFERENCES_PROP_ACTIVE_PREDICATE
                ) {
                    return true
                }

                if (_.isObject(fieldPredicate) && fieldPredicate.$$dateFilter) {
                    // If an entry doesn't have a date return true to include it in the list of entries
                    if (!fieldValue && fieldPredicate.showEmpty) {
                        return true
                    } // eslint-disable-line

                    const timestamp = _.toInteger(fieldValue) && _.toInteger(fieldValue) * 1000
                    const parsedDate = dateParse(timestamp || fieldValue)

                    return dateIsWithinRange(parsedDate, fieldPredicate.min, fieldPredicate.max)
                }

                if ((_.isArrayLike(fieldValue) && _.isEmpty(fieldValue)) || _.isNil(fieldValue)) {
                    return _.get(perfilterPredicate, [field, PERFIELD_EMPTY_VALUE_KEY], true)
                }

                if (_.isArray(fieldValue)) {
                    return _.some(fieldValue, (val) =>
                        _.get(perfilterPredicate, [field, val], false)
                    )
                } else {
                    return _.get(perfilterPredicate, [field, fieldValue], false)
                }
            })
        }
    }
}
