import _ from 'lodash'
import angular from 'angular'
import Immutable from 'immutable'
import { SCENE_PERSIST_PROPS, SCENE_AUTOSPLITTER_LIMIT } from '../../constants'
import { VideoAPIInstance } from '../../video/VideoAPIBuilder.factory'

export type Segment = {
    id: number
    startFrame: number
    endFrame: number
    nonCannonSegment?: any
    parentId?: number
    type: string

    $$uid?: string
    $$isSelected?: boolean
    $$wasSingleSelected?: boolean
}

/**
 * @ngdoc service
 * @name SceneHelper
 * @module map3.scenes
 *
 * @description
 * A helper service for manipulating or querying scene arrays
 */
export default /* @ngInject */ function SceneHelperFactory() {
    const SceneHelper = {
        /**
         * @ngdoc method
         * @name SceneHelper#byId
         * @description Find a scene by `id` in an array of scenes
         *
         * @param {Array<Scene>} scenes An array of {@link Scene Scenes}
         * @param {number} id The search `id`
         */
        byId<T extends Segment>(scenes: T[], id: number): T | undefined {
            return _.find(scenes, { id } as any)
        },

        /**
         * @ngdoc method
         * @name SceneHelper#byJq
         * @description Find a scene by a `$(<scene>)` jQuery element
         *
         * @param scenes An array of {@link Scene Scenes}
         * @param jqScene The search jQuery element
         */
        byJq<T extends Segment>(scenes: T[], jqScene: JQuery<any>): T | undefined {
            return _.find(scenes, { $$uid: jqScene.data('uid') })
        },

        /**
         * @ngdoc method
         * @name SceneHelper#getSingleSelected
         * @description
         * If there is only one Scene with {@link Scene#$$isSelected $$isSelected}
         * property set to true, return it; Otherwise return `null`.
         *
         * @param scenes An array of {@link Scene Scenes}
         */
        getSingleSelected<T extends Segment>(scenes: T[]): T | undefined {
            const selected = _.filter(scenes, { $$isSelected: true } as any)
            return selected.length === 1 ? selected[0] : undefined
        },

        /**
         * @ngdoc method
         * @name SceneHelper#goToBeginning
         * @description
         * Go to scene beginning
         *
         * @param {Scene|undefined} scene The scene to go to the start of
         * @param {videoAPI} videoApi A {@link videoAPI} instance
         */
        goToBeginning<T extends Segment>(scene: T | undefined, videoApi: VideoAPIInstance) {
            if (scene) {
                videoApi.seekFrame(scene.startFrame)
            }
        },

        goToSelectedStart<T extends Segment>(elements: T[], videoApi: VideoAPIInstance) {
            const selected = _.find(elements, { $$isSelected: true } as any)
            SceneHelper.goToBeginning(selected, videoApi)
        },

        goToSelectedEnd(elements: Segment[], videoApi: VideoAPIInstance) {
            const selected = _.findLast(elements, { $$isSelected: true })
            SceneHelper.goToEnd(selected, videoApi)
        },

        /**
         * @ngdoc method
         * @name SceneHelper#goToBeginning
         * @description
         * Go to scene end
         *
         * @param {Scene|undefined} scene The scene to go to the end of
         * @param {videoAPI} videoApi A {@link videoAPI} instance
         */
        goToEnd(scene: Segment | undefined, videoApi: VideoAPIInstance) {
            if (scene) {
                videoApi.seekFrame(scene.endFrame)
            }
        },

        /**
         * @ngdoc method
         * @name SceneHelper#getByFrameNumber
         * @description
         * Find a scene in an array of scenes, by frame number.
         *
         * @param  {Array<Scene>} scenes
         * @param  {number} frameNumber
         * @return Guaranteed to return a scene.
         *                 If out of bounds, will return the last scene.
         */
        getByFrameNumber<T extends Segment>(scenes: T[], frameNumber: number): T {
            const scene = _.find(scenes, (scene) => {
                return _.inRange(frameNumber, scene.startFrame, scene.endFrame + 1)
            })

            return scene || (scenes && scenes[scenes.length - 1])
        },

        /**
         * @ngdoc method
         * @name SceneHelper#getFrameDuration
         * @description
         * Get the frame duration of a scene.
         *
         * @param  scene
         * @param  withEndFrame Should the end frame be considered
         *                                part of the scene duration
         * @return The scene's frame duration
         */
        getFrameDuration(scene: Segment, maxDuration = Number.MAX_SAFE_INTEGER) {
            return Math.min(scene.endFrame - scene.startFrame + 1, maxDuration)
        },

        /**
         * @ngdoc method
         * @name SceneHelper#isEqual
         * @description
         * Check if one list of scenes is equal to another, optionally
         * limiting the properties to compare by
         *
         * @param scenesA The scenes to compare
         * @param scenesB The other scenes to compare
         * @param propsToCompare String or Array, defaults to SCENE_PERSIST_PROPS
         */
        isEqual(
            scenesA: Immutable.List<Segment> | Segment[],
            scenesB: Immutable.List<Segment> | Segment[],
            propsToCompare = SCENE_PERSIST_PROPS
        ): boolean {
            scenesA = normalize(scenesA, propsToCompare)
            scenesB = normalize(scenesB, propsToCompare)

            return _.isEqual(scenesA, scenesB)

            ////////

            function normalize(
                scenes: Immutable.List<Segment> | Segment[],
                propsToCompare: readonly string[]
            ): Segment[] {
                if (Immutable.List.isList(scenes)) {
                    scenes = (scenes as Immutable.List<Segment>).toJS()
                }

                if (propsToCompare) {
                    scenes = _.map(scenes as Segment[], (scene) =>
                        _.pick(scene, propsToCompare)
                    ) as Segment[]
                }

                return scenes as Segment[]
            }
        },

        /**
         * @ngdoc method
         * @name SceneHelper#serialize
         * @description
         * Serialize immitable scene data to a format sutable for the backend
         *
         * @param immutableSceneData
         *  `{ acts: Immutable.List, scenes: Immutable.List, subScenes: Immutable.List }`
         * @param videoApi
         * @param selection Scene Data fields to be serialized
         *  `{ acts: true, scenes: true, subScenes: true }`
         */
        serialize<T extends Segment>(
            immutableSceneDataInput: {
                acts?: Immutable.List<T>
                scenes?: Immutable.List<T>
                subScenes?: Immutable.List<T>
            },
            videoApi: VideoAPIInstance,
            selection = { acts: true, scenes: true, sub_scenes: true }
        ): { acts: string; scenes: string; subScenes: string } {
            const selectionMapping = {
                acts: 'acts',
                scenes: 'scenes',
                sub_scenes: 'subScenes',
            } as const

            /* console.log({immutableSceneDataInput, selection}) */
            const immutableSceneData = _.reduce(
                Object.keys(selectionMapping) as any,
                (memo, key: keyof typeof selectionMapping) => {
                    const mappedKey = selectionMapping[key]
                    const input = immutableSceneDataInput[mappedKey]
                    if (selection[key] && input) {
                        memo[mappedKey] = input
                    } else {
                        memo[mappedKey] = false
                    }

                    return memo
                },
                {} as {
                    acts: Immutable.List<T> | false
                    scenes: Immutable.List<T> | false
                    subScenes: Immutable.List<T> | false
                }
            )

            return _.mapValues(immutableSceneData, (segments) => {
                if (segments === false) {
                    return angular.toJson(false)
                }

                const backendFormatted = _.map(segments.toJS(), (segment) => {
                    segment = _.assign({}, segment, {
                        start: videoApi.convertFrameToSeconds(segment.startFrame),
                        end: videoApi.convertFrameToSeconds(segment.endFrame),
                    })

                    return _.pick(segment, SCENE_PERSIST_PROPS)
                })

                return angular.toJson(backendFormatted)
            })
        },

        /**
         * @ngdoc method
         * @name SceneHelper#isOverAutoSplitterLimit
         * @description
         * Check if backend scenes were created by the autosplitter and if their
         * number is over the autosplitter limit
         *
         * @param scenes The scenes given from the backend
         * @param limit The max number of scenes, if comming from autosplitter
         */
        isOverAutoSplitterLimit<T extends Segment>(
            scenes: T[],
            limit = SCENE_AUTOSPLITTER_LIMIT
        ): boolean {
            // either no scenes given, or the scenes were manually created
            if (_.isEmpty(scenes) || scenes[0].endFrame) {
                return false
            }

            return scenes.length > limit
        },
    }

    return SceneHelper
}

export type SceneHelperInstance = ReturnType<typeof SceneHelperFactory>
