/**
 * @ngdoc directive
 * @name stickyHeader
 * @module map3.core
 * @restrict EA
 *
 * @description
 * Sticky Header directive for making an element fixed when
 * it's about to leave the viewport.
 *
 * Note: This directive depends on jQuery to work
 *
 * This directive works by applying a "sticky" class to an element.
 * The "sticky" class has the default definition of:
 *
 * ```css
 *   .sticky {
 *     position: fixed;
 *   }
 * ```
 *
 * You need to combine it with your custom CSS properties to make it work.
 *
 * Example:
 *
 * ```html
 * <header sticky-header class="myHeader" sticky-offset="50">[...]</header>
 * ```
 *
 * ```css
 * .myHeader.sticky {
 *   top: 50px;
 * }
 * ```
 *
 * @param {number} stickyOffsetTop On what offset from the top of the viewport
 *      to trigger the sticky directive. Default: 0
 * @param {boolean} dummyFill When an element gets fixed position, it's removed
 *      from the flow of the document, and all elements below it will jump up.
 *      Enabling {dummyFill} will insert an element in this one's place, with the
 *      exact same dimensions, and remove it when this one gets unsticked
 */
export default /* @ngInject */ function stickyHeaderDirective($window) {
    var directive = {
        restrict: 'EA',
        link: stickyHeaderLinkFn,
    }

    return directive

    ///////////////////////

    function stickyHeaderLinkFn(scope, element, attr) {
        var $$window = angular.element($window)
        var offsetTop = parseInt(attr.stickyOffsetTop, 10) || 0
        var memorizedPosition

        var dummyFill = attr.dummyFill != null && attr.dummyFill !== 'false'
        var dummyElement = angular.element('<div></div>')
        var memorizedDimensions

        // start unstickied
        var isStickied

        activate()

        ////////////////////////////

        function activate() {
            // bind on-scroll event
            $$window.on('scroll', checkPosition)

            // and remove it when the directive gets unloaded
            scope.$on('$destroy', function () {
                // unbind the event
                $$window.off('scroll', checkPosition)
                // destroy the dummy element
                dummyElement.remove()
            })

            // finally, do one initial run to prime all values
            checkPosition()
        }

        function checkPosition() {
            // handle modals fucking with body positioning to hide the scroll
            var bodyPosition = angular.element($window.document.body).offset()
            var bodyOffset = bodyPosition.top

            // calculate actuall scroll position, taking into account
            // negative body offsets as if they were a scroll
            var scrollTop = $$window.scrollTop() + bodyOffset * -1

            // if we are not currently sticky, get the current
            // element position and remember it. If we are sticky,
            // use the remembered position, because it has the original
            // top value against which we need to compare
            var offsetPosition
            if (!isStickied && element.is(':visible')) {
                // the offsetPosition is the finally calculated position of the
                // element, based on it's actual domNode.offsetTop, minus
                // the directive parameter {offsetTop}, plus the inverse
                // body offset. This means that, if the body is modal-locked,
                // and has an offsetTop of -100, we calculate the offsetPosition
                // to be +100 pixels down than its reported top.
                offsetPosition = element.offset().top - offsetTop + bodyOffset * -1
                memorizedPosition = offsetPosition
            } else {
                offsetPosition = memorizedPosition
            }

            // if we use a dummy, rememer the dimensions of the element
            if (dummyFill && !isStickied) {
                // the dimensions include paddings and margins
                memorizedDimensions = {
                    height: element.outerHeight(true),
                    width: element.outerWidth(true),
                }
            }

            var shouldSticky = scrollTop > offsetPosition

            // only apply the costly DOM stuff if {shouldSticky} changed
            // from the last scroll event
            if (isStickied !== shouldSticky) {
                isStickied = shouldSticky

                // set or remove the sticky class
                element.toggleClass('sticky', isStickied)

                // if we need to handle a dummy
                if (dummyFill) {
                    if (isStickied) {
                        // set dummy element dimensions and insert it in the
                        // DOM after our element
                        dummyElement.css('width', memorizedDimensions.width)
                        dummyElement.css('height', memorizedDimensions.height)
                        dummyElement.insertAfter(element)
                    } else {
                        // detach the dummy, but don't destroy it
                        dummyElement.detach()
                    }
                }
            }
        }
    }
}
