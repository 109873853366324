import * as d3 from 'd3'

export default /* @ngInject */ function ZoomScalerFactory(SS_ZOOM_MAX_SECONDS) {
    const ZoomScaler = {
        /**
         * Initialize a Log Scaler setup for dealing with video zoom
         *
         * @param {number} videoDuration In seconds
         * @return {LogScaler}
         */
        fromVideoDuration(videoDuration) {
            return new LogScaler({
                range: [0, 100],
                domain: [1, videoDuration / SS_ZOOM_MAX_SECONDS],
            })
        },

        /**
         * Scale to a maximum number of seconds visible
         */
        toMaxSeconds(videoApi, maxSeconds) {
            return new LogScaler({
                range: [0, 100],
                domain: [1, videoApi.getDuration() / maxSeconds],
            })
        },

        frameAccurate(videoApi, timelineWidth, filmstripFrameWidth) {
            const framesPerTimeline = timelineWidth / filmstripFrameWidth
            const zoomMax = videoApi.getDurationInFrames() / framesPerTimeline

            return new LogScaler({
                range: [0, 100],
                domain: [1, zoomMax],
            })
        },
    }

    return ZoomScaler
}

/**
 * A LogScaler is a construct for creating a logaritmic mapping between two arbitrary scales.
 *
 * For a range: [0, 100]
 * And a domain: [1, 500]
 *
 * It will generate the following scaler:
 *
 *  range:   [ 0         25.00          50.00          75.00         100 ]
 *  domain:  [ 1          4.72          22.36         105.73         500 ]
 *
 * We are using the natural logarithm for our scaling
 */
export class LogScaler {
    /**
     * @param {object} opts {domain, range}
     */
    constructor({ domain, range }) {
        this.scaler = d3.scaleLog().domain(domain).range(range).base(Math.E)
    }

    /**
     * @param {number} domainVal
     * @return {number} domain scaled to range
     */
    scaleDomain(domainVal) {
        return this.scaler(domainVal)
    }

    /**
     * @param {number} rangeVal
     * @return {number} range scaled to domain
     */
    scaleRange(rangeVal) {
        return this.scaler.invert(rangeVal)
    }
}
