export default {
    TagScenesQA: {
        templatePath: 'js/admin/workflow-wizard/templates/TagScenesQA.tpl.html',
        defaults: {
            review_allowed: [],
        },
    },
    QuestionVideos: {
        templatePath: 'js/admin/workflow-wizard/templates/QuestionVideos4.tpl.html',
        defaults: {
            use_timestamp: true,
            use_scenes: true,
            resource: 'internal',
            internal_options: {
                title: '$taskType$: $videoName$ $taskDetails$',
                overwrite_workers: false,
                assignments: 1,
                workers: [],
                groups: [],
                description: '',
            },
            short_description: '',
            dynamic_video_manipulation: null,
            augment_video_manipulation: false,
            extend_input: true,
            requireScenes: true,
            ticket_wait_minutes: 2880,
        },
    },
    AssistedAnnotate: {
        templatePath: 'js/admin/workflow-wizard/templates/QuestionVideosAssisted.tpl.html',
        defaults: {
            use_timestamp: true,
            resource: 'internal',
            internal_options: {
                title: '$taskType$: $videoName$ $taskDetails$',
                overwrite_workers: false,
                assignments: 1,
                workers: [],
                groups: [],
                description: '',
            },
            short_description: '',
            dynamic_video_manipulation: null,
            requireScenes: true,
            ticket_wait_minutes: 2880,
            skip_qa: false,
        },
    },

    AutomationTask: {
        templatePath: 'js/admin/workflow-wizard/templates/AutomationTask.tpl.html',
        defaults: {
            algorithm: [],
        },
    },
    AuthorityTagging: {
        templatePath: 'js/admin/workflow-wizard/templates/AuthorityTagging.tpl.html',
        defaults: {
            use_timestamp: false,
            // allowEdit: false,
            resource: 'internal',
            internal_options: {
                title: '$taskType$: $videoName$ $taskDetails$',
                overwrite_workers: false,
                assignments: 1,
                workers: [],
                groups: [],
                description: '',
            },
            short_description: '',
            ticket_wait_minutes: 2880,
        },
    },
    AuthorityTaggingQA: {
        templatePath: 'js/admin/workflow-wizard/templates/AuthorityTaggingQA.tpl.html',
        defaults: {
            review_allowed: [],
        },
    },
    QATask: {
        templatePath: 'js/admin/workflow-wizard/templates/QATask.tpl.html',
        defaults: {
            review_allowed: [],
        },
    },
    QuestionVideosQA: {
        templatePath: 'js/admin/workflow-wizard/templates/QuestionVideosQA3.tpl.html',
        defaults: {
            title: '$taskType$: $videoName$ $taskDetails$',
            description: 'Some questions aggregated',
            review_allowed: null,
            internal_options: {
                workers: [],
                groups: [],
            },
        },
    },
    ShapeTagging: {
        templatePath: 'js/admin/workflow-wizard/templates/ShapeTagging.tpl.html',
        defaults: {
            description: 'Tag Shapes',
            internal_options: {
                workers: [],
                groups: [],
            },
            max_images_per_task: 10,
        },
    },
    ImageTagging: {
        templatePath: 'js/admin/workflow-wizard/templates/ImageTagging.tpl.html',
        defaults: {
            description: 'Tag Image',
            free_text_enabled: false,
            internal_options: {
                workers: [],
                groups: [],
            },
            max_images_per_task: 10,
        },
    },
    MultipleImageTagging: {
        templatePath: 'js/admin/workflow-wizard/templates/MultipleImageTagging.tpl.html',
        defaults: {
            description: 'Tag Images',
            internal_options: {
                workers: [],
                groups: [],
            },
            max_images_per_task: 10,
        },
    },
    SceneBreaker2: {
        templatePath: 'js/admin/workflow-wizard/templates/SceneBreaker.tpl.html',
        defaults: {
            title: '$taskType$: $videoName$ $taskDetails$',
            description: 'Automated Scene Tagging',
            skip_qa: false,
            internal_options: {
                acts: true,
                scenes: true,
                sub_scenes: true,
            },
        },
    },
    S3: {
        templatePath: 'js/admin/workflow-wizard/templates/s3.tpl.html',
        defaults: { bucket: null, asset_type: null },
    },
    BBTagging: {
        templatePath: 'js/admin/workflow-wizard/templates/BBTagging.tpl.html',
        defaults: {
            title: 'BB Tagging',
            description: 'Bounding box drawing task',
            internal_options: {
                acts: true,
                scenes: false,
                sub_scenes: false,
                workers: [],
                groups: [],
            },
        },
    },
    TagScenes: {
        templatePath: 'js/admin/workflow-wizard/templates/TagScenes3.tpl.html',
        defaults: {
            Resource: 'internal',
            internal_options: {
                acts: false,
                scenes: true,
                sub_scenes: true,
                assignments: 1,
                workers: [],
                groups: [],
                title: '$taskType$: $videoName$ $taskDetails$',
                description: 'Annotate the scenes of a video',
                overwrite_workers: false,
            },
        },
    },
    ViewVideos: {
        templatePath: 'js/admin/workflow-wizard/templates/ViewVideos3.tpl.html',
        defaults: {
            short_description: '',
            always_available: false,
            task_title: '$taskType$: $videoName$ $taskDetails$',
            task_description: 'Just watch',
            require_viewing: false,
            watch_expiration_time: 180,
        },
    },
    GetVideos: {
        templatePath: 'js/admin/workflow-wizard/templates/GetVideos2.tpl.html',
        defaults: {
            query: '',
            jsonArray: [],
            keepAlive: false,
            excludedVideoIds: [],
        },
        autoSubmit: false,
        size: 'lg',
    },
    AutomationInput: {
        templatePath: 'js/admin/workflow-wizard/templates/AutomationInput.tpl.html',
        defaults: {},
        autoSubmit: false,
        size: 'lg',
    },
    CutnTag: {
        templatePath: 'js/admin/workflow-wizard/templates/CutNTag.tpl.html',
        defaults: {
            use_timestamp: true,
            short_description: '',
            Resource: 'internal',
            internal_options: {
                assignments: 1,
                workers: [],
                groups: [],
                title: '$taskType$: $videoName$ $taskDetails$',
                description: '',
                overwrite_workers: false,
            },
            use_scenes: false,
        },
    },
    CutnTagQA: {
        templatePath: 'js/admin/workflow-wizard/templates/CutNTagQa.tpl.html',
        defaults: {
            review_allowed: [],
        },
    },
    TaskMapper: {
        templatePath: 'js/admin/workflow-wizard/templates/TaskMapper.tpl.html',
        defaults: {
            description: '',
            short_description: '',
            algorithm: null,
        },
    },
    GetQuestions: [
        {
            definitionMatch: {
                output: {
                    questions: ['QuestionVideos'],
                },
            },
            templatePath: 'js/admin/workflow-wizard/templates/GetQuestions.tpl.html',
            questionsWithDetailsAreMandatory: true,
            defaults: {
                server: 'http://52.27.252.34:8880/getQuestion.xqy',
                selection: [],
            },
        },
        {
            definitionMatch: {
                output: {
                    questions: ['AssistedAnnotate'],
                },
            },
            templatePath:
                'js/admin/workflow-wizard/templates/GetQuestionsForAssistedAnnotate.tpl.html',
            questionsWithDetailsAreMandatory: true,
            defaults: {
                server: 'http://52.27.252.34:8880/getQuestion.xqy',
                selection: [],
                use_automation_taxonomy: false,
            },
        },
        {
            definitionMatch: {
                output: {
                    questions: ['CutnTag'],
                },
            },
            templatePath: 'js/admin/workflow-wizard/templates/GetQuestionsForCutnTag.tpl.html',
            questionsWithDetailsAreMandatory: true,
            defaults: {
                controlledVocabulary: null,
                server: 'http://52.27.252.34:8880/getQuestion.xqy',
                free_input_text_labels: false,
                selection: [],
            },
        },
        {
            definitionMatch: {
                output: {
                    questions: ['AuthorityTagging'],
                },
            },
            templatePath:
                'js/admin/workflow-wizard/templates/GetQuestionsForAuthorityTagging.tpl.html',
            questionsWithDetailsAreMandatory: true,
            defaults: {
                server: 'http://52.27.252.34:8880/getQuestion.xqy',
                selection: [],
                authority: null,
            },
        },
        {
            definitionMatch: {
                output: {
                    questions: ['ImageTagging'],
                },
            },
            templatePath: 'js/admin/workflow-wizard/templates/GetQuestionsForImageTagging.tpl.html',
            questionsWithDetailsAreMandatory: true,
            defaults: {
                server: 'http://52.27.252.34:8880/getQuestion.xqy',
                selection: [],
            },
        },
        {
            definitionMatch: {
                output: {
                    questions: ['ShapeTagging'],
                },
            },
            templatePath: 'js/admin/workflow-wizard/templates/GetQuestionsForShapeTagging.tpl.html',
            questionsWithDetailsAreMandatory: true,
            defaults: {
                server: 'http://52.27.252.34:8880/getQuestion.xqy',
                selection: [],
            },
        },
        {
            definitionMatch: false,
            templatePath: 'js/admin/workflow-wizard/templates/default.tpl.html',
            defaults: {
                warningMessage:
                    'Please connect Create Questions with a recipient box in order to configure it.',
            },
        },
    ],
    MWD: [
        {
            size: 'xl',
            definitionMatch: {
                input: {
                    video: ['GetVideos'],
                },
            },
            templatePath: 'js/admin/workflow-wizard/templates/MWD.tpl.html',
            defaults: {
                hitIDs: [],
            },
        },
        {
            definitionMatch: false,
            templatePath: 'js/admin/workflow-wizard/templates/default.tpl.html',
            defaults: {
                warningMessage: 'Please connect MWD with a source box in order to configure it.',
            },
        },
    ],
    LoadMarkers: [
        {
            size: 'lg',
            definitionMatch: {
                input: {
                    video: ['GetVideos'],
                },
            },
            templatePath: 'js/admin/workflow-wizard/templates/LoadMarkers.tpl.html',
            defaults: {
                markerSets: [],
            },
        },
        {
            definitionMatch: false,
            templatePath: 'js/admin/workflow-wizard/templates/default.tpl.html',
            defaults: {
                warningMessage:
                    'Please connect Load Markers with a source box in order to configure it.',
            },
        },
    ],
    MarkerAnnotate: {
        templatePath: 'js/admin/workflow-wizard/templates/MarkerAnnotate.tpl.html',
        defaults: {
            marker_type: 'Commercial Blacks',
        },
    },
    MarkerReview: {
        templatePath: 'js/admin/workflow-wizard/templates/MarkerReview.tpl.html',
        defaults: {
            review_allowed: [],
        },
    },
    GetChapters: {
        templatePath: 'js/admin/workflow-wizard/templates/GetChapters.tpl.html',
        defaults: {
            ticket_wait_minutes: 24 * 60 * 2,
        },
    },
}
