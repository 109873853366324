import angular from 'angular'
import _ from 'lodash'

import { IField, TTableRowData } from 'admin/types'
import type { AdminModuleInstance } from '../../AdminModule.factory'

export interface PreviewResponse {
    fields: IField[]
    results: TTableRowData[]
}

export default /* @ngInject */ function queryPreviewTableDirective(
    $q: ng.IQService,
    AdminModule: AdminModuleInstance
) {
    const directive = {
        restrict: 'E',
        scope: {
            table: '<',
            json: '<',
            workflowUri: '<?',
        },
        templateUrl: 'js/admin/directives/query-builder/queryBuilderPreviewTable.tpl.html',
        link: queryPreviewTableLinkFn,
    }

    return directive

    /////////////////////////////

    function queryPreviewTableLinkFn(
        scope: ng.IScope & {
            loading: boolean | undefined
            table: string | false
            predicateField: string[]
            tableData: TTableRowData[] | null
            fields: IField[]
            json: string[] | string
            workflowUri: string | undefined
        }
    ) {
        let cancelLastRequest: angular.IDeferred<unknown> | null
        let lastTable: string | false

        scope.predicateField = []
        scope.$watchGroup(['table', 'limit'], updateTableData)
        scope.$watchCollection('json', updateTableData)

        // cancel any incomplete request when the directive gets destroyed
        scope.$on('$destroy', function () {
            if (cancelLastRequest) {
                cancelLastRequest.resolve()
            }
        })

        function updateTableData() {
            const { table } = scope
            if (lastTable !== table) {
                lastTable = table
                scope.tableData = null
            }

            if (!table) {
                return
            }

            if (_.isArray(scope.json)) {
                if (!scope.json.length) {
                    scope.tableData = null
                    return
                }
            } else {
                scope.json = _.toString(scope.json)
                // if we don't have JSON clear table data
                if (!scope.json || _.isEmpty(_.get(angular.fromJson(scope.json), 'rules', []))) {
                    scope.tableData = null
                    return
                }
            }

            // if we have an uncomplete request, cancel it
            if (cancelLastRequest) {
                cancelLastRequest.resolve()
                cancelLastRequest = null
                scope.loading = false
            }

            // prepare a promise to give to $http.config.timeout,
            // so that we can cancel the request prematurely
            cancelLastRequest = $q.defer()

            AdminModule.getQueryPreview(
                table,
                scope.json,
                cancelLastRequest.promise,
                scope.workflowUri
            )
                .then((data) => {
                    if (!_.isEmpty(data.results)) {
                        scope.fields = data.fields
                        scope.tableData = data.results
                    } else {
                        scope.tableData = []
                    }
                })
                .catch(function () {
                    scope.tableData = []
                })
                .finally(function () {
                    cancelLastRequest = null
                    scope.loading = false
                    scope.predicateField.length = 0
                })

            scope.loading = true
        }
    }
}
