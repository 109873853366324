import _ from 'lodash'
import { DOMUtilityInstance } from 'services/DOMUtility.factory'
import { findAnswerForQuestion, isFreeTaggingInputType } from 'util/annotateVideo'

type ScopeBindings = {
    annotation: any
    questions: any[]
    ngDisabled?: boolean
    autoFocus?: boolean
    maxShownResults?: number
}

type ScopeVariables = {
    findAnswerForQuestion: (answer: any, question: any) => any
    addDetailsForQuestion: (question: any) => void
    removeDetailsForQuestion: (details: any, question: any) => void
    isFreeTaggingQuestion: (question: Question) => boolean
}

/**
 * Directive for answering task questions.
 * Initially developed to unify QA and Worker annotation edit workflow
 *
 * @param {Array(Question)} questions Array of question definitions
 * @param {Annotation} annotation Assignable annotation
 */
export default /* @ngInject */ function answerQuestionsDirective(DOMUtility: DOMUtilityInstance) {
    const directive = {
        restrict: 'E',
        scope: {
            annotation: '=',
            questions: '<',
            ngDisabled: '<?',
            autoFocus: '=?',
            maxShownResults: '<?',
        },
        templateUrl: 'js/directives/answer-questions/answerQuestions.tpl.html',
        link: answerQuestionsLinkFn,
    }

    return directive

    /////////////////////////////////////

    function answerQuestionsLinkFn(
        scope: ng.IScope & ScopeBindings & ScopeVariables,
        element: ng.IAugmentedJQuery
    ) {
        scope.findAnswerForQuestion = findAnswerForQuestion

        scope.addDetailsForQuestion = addDetailsForQuestion
        scope.removeDetailsForQuestion = removeDetailsForQuestion
        scope.isFreeTaggingQuestion = isFreeTaggingInputType

        activate()

        ////////////////////////////////////////////////////

        function activate() {
            scope.$watch('annotation', ensureValidAnnotation)
            // We need to execute this immediately once, because relying on $watch
            // first execution is too slow for dependant directives like questionInput.
            // We need the annotation to be valid for their link function, and that executes
            // before the first $watch() execution
            ensureValidAnnotation()

            if (scope.autoFocus) {
                setupAutoFocus()
            }

            function ensureValidAnnotation() {
                if (!scope.annotation) {
                    scope.annotation = {}
                }
                if (!_.isArray(scope.annotation.answers)) {
                    scope.annotation.answers = buildAnswersFromQuestions(scope.questions)
                }
            }
        }

        /**
         * Add a new details row for one of the questions
         */
        function addDetailsForQuestion(question: any) {
            const answer = findAnswerForQuestion(
                scope.annotation.answers,
                question
            ) as AnnotationAnswer

            answer.detailsRows.push(
                _.map(question.details, function (detail) {
                    return {
                        id: detail.uri,
                        placeholder: detail.placeholder,
                        value: null,
                    }
                })
            )
        }

        /**
         * Remove one of the details rows for a particular question
         */
        function removeDetailsForQuestion(details: any, question: any) {
            const answer = findAnswerForQuestion(
                scope.annotation.answers,
                question
            ) as AnnotationAnswer
            answer.detailsRows.splice(answer.detailsRows.indexOf(details), 1)
        }

        function buildAnswersFromQuestions(questions: any) {
            return _.map(questions, function (question) {
                return {
                    id: question.uri,
                    value: null,
                    detailsRows: [],
                }
            })
        }

        function setupAutoFocus() {
            const elements: any[] = []
            const throttledOpen = DOMUtility.rafThrottle(openChosen)

            element.on('chosen:ready', handleChosenReady)
            scope.$on('$destroy', () => {
                element.off('chosen:ready', handleChosenReady)
                elements.length = 0
            })

            //////////////

            function handleChosenReady(e: any) {
                elements.push(e.target)
                throttledOpen()
            }

            function openChosen() {
                $(elements[0]).trigger('chosen:open')
                elements.length = 0
            }
        }
    }
}
