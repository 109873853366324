import { createMachine, StateMachine, ConditionPredicate, DoneInvokeEvent } from 'xstate'
import { createModel } from 'xstate/lib/model'
import { isEqual } from 'lodash'
import angular from 'angular'

export type APIMachineEvent<T> = {
    type: 'UPDATE_DATA'
    data: T
}
export type APIMachineContext<T> = {
    data: T
    lastSavedData: T | null
}
export type APIMachineState<T> = {
    value: 'idle' | 'saving'
    context: APIMachineContext<T>
}

const apiModel = createModel<APIMachineContext<any>, APIMachineEvent<any>>({
    data: null!,
    lastSavedData: null,
})
const assignData = apiModel.assign((_ctx, e) => ({ data: angular.copy(e.data) }), 'UPDATE_DATA')
const assignLastSavedData = apiModel.assign((_ctx, e: DoneInvokeEvent<any>) => ({
    lastSavedData: angular.copy(e.data),
}))

type createAPIMachineConfig<T> = {
    saveFn: (ctx: APIMachineContext<T>) => Promise<T>
    initialData: T
    handleBackendError?: (error: Error, ctx: APIMachineContext<T>) => void
}
export function createAPIMachine<T>(
    config: createAPIMachineConfig<T>
): StateMachine<APIMachineContext<T>, any, APIMachineEvent<T>, APIMachineState<T>> {
    return createMachine<APIMachineContext<T>, APIMachineEvent<T>, APIMachineState<T>>(
        {
            initial: 'idle',
            context: {
                data: config.initialData,
                lastSavedData: angular.copy(config.initialData),
            },
            on: {
                UPDATE_DATA: { actions: assignData },
            },
            states: {
                idle: {
                    always: { cond: shouldSave, target: 'saving' },
                },
                saving: {
                    invoke: {
                        id: 'save-to-backend',
                        src: (ctx) => config.saveFn(ctx),
                        onDone: {
                            actions: assignLastSavedData as any,
                            target: 'idle',
                        },
                        onError: {
                            target: 'idle',
                            actions: 'escalateError',
                        },
                    },
                },
            },
        },
        {
            actions: {
                escalateError: (ctx, e: any) => {
                    if (config.handleBackendError) {
                        config.handleBackendError(e.data, ctx)
                    }
                },
            },
        }
    )
}

const shouldSave: ConditionPredicate<APIMachineContext<any>, any> = (ctx) => {
    return !isEqual(ctx.data, ctx.lastSavedData)
}
