import _ from 'lodash'

export default /* @ngInject */ function AdminUsersModuleFactory($http: ng.IHttpService) {
    const AdminUsersModule = {
        getUsers(
            search?: string,
            offset?: number,
            cancelPromise?: ng.IPromise<unknown>,
            NO_LOAD_OVERLAY?: NO_LOAD_OVERLAY
        ) {
            const data = _.assign({
                params: { search, offset },
                timeout: cancelPromise,
                ...NO_LOAD_OVERLAY,
            })
            return $http.get<TUser[]>(`/api/admin/users`, data).then((res) => res.data)
        },

        getUserData(username: string) {
            return $http.get(`/api/admin/users/user/${username}`).then((res) => res.data)
        },

        getTasks(username: string, opts = {}) {
            return $http.get(`/api/admin/users/tasks/${username}`, opts).then((res) => res.data)
        },

        getGroups() {
            return $http.get('/api/admin/groups').then((res) => res.data)
        },

        createUser(user: TUser) {
            return $http.post('/api/admin/users/add', user)
        },

        editUserData(userData: TAdminUserData) {
            return $http.post('/api/admin/users/edit', userData)
        },

        suspendUser(uri: string) {
            return $http.post('/api/admin/users/edit', null, {
                params: {
                    uri: uri,
                    status: 'Suspended',
                },
            })
        },

        activateUser(uri: string) {
            return $http.post('/api/admin/users/edit', null, {
                params: {
                    uri: uri,
                    status: 'Active',
                },
            })
        },

        deleteUser(username: string) {
            return $http.post(`/api/admin/users/delete-user/${username}`, null)
        },

        addUserToGroup(user: TAdminUserData, group: UserGroup) {
            return $http.post('/api/admin/users/add-group', {
                user_uri: user.uri,
                group_uri: group.uri,
            })
        },

        removeUserFromGroup(user: TAdminUserData, group: UserGroup) {
            return $http.post('/api/admin/users/remove-group', {
                user_uri: user.uri,
                group_uri: group.uri,
            })
        },

        returnTaskInQueue(tasks: any[]) {
            const assignmentIds = _.map(tasks, 'assignmentId').filter(
                (assignmentId) => !!assignmentId
            )

            return $http
                .post('/api/admin/assignments/return', { assignmentIds })
                .then((res) => res.data)
        },
    }

    return AdminUsersModule
}

export type AdminUsersModuleInstance = ReturnType<typeof AdminUsersModuleFactory>
