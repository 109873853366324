import _ from 'lodash'
import dateIsEqual from 'date-fns/is_equal'

export default function TablePerfieldFitlerResetFactory() {
    return class TablePerfieldFitlerReset {
        static resetFilters(perfieldPredicate) {
            _.forIn(perfieldPredicate, (predicate) => {
                if (predicate.$$dateFilter) {
                    predicate.resetFilter()
                } else {
                    _.forEach(_.keys(predicate), (key) => {
                        predicate[key] = true
                    })
                }
            })
        }

        static isPerfieldPredicateDirty(perfieldPredicate) {
            return _.some(perfieldPredicate, (predicate) => {
                if (predicate && predicate.$$dateFilter) {
                    return (
                        !dateIsEqual(predicate.initialMaxDate, predicate.max) ||
                        !dateIsEqual(predicate.initialMinDate, predicate.min) ||
                        predicate.showEmpty !== true
                    )
                } else {
                    return _.some(_.keys(predicate), (key) => {
                        return predicate[key] === false
                    })
                }
            })
        }
    }
}
