import moment from 'moment'

type ScopeBindings = {
    time: number
    minutes: number
    hours: number
}

export default /* @ngInject */ function timeSpentDirective() {
    const directive = {
        restrict: 'E',
        scope: {
            time: '<',
        },
        link: timeSpentLinkFn,
        template: `{{ hours }} h. {{ minutes }} m.
        `,
    }

    return directive

    /////////////////////////////////////

    function timeSpentLinkFn(scope: ng.IScope & ScopeBindings) {
        const output = moment.duration(scope.time)
        scope.minutes = output.minutes()
        scope.hours = output.hours()
    }
}
