/**
 * @ngdoc directive
 * @name resizableDirective
 * @module map3.core
 * @restrict EA
 *
 * @description
 * Resizable container
 *
 * TODO: Add full documentation
 */
export /* @ngInject */ function resizableDirective($rootScope, $window, DOMUtility) {
    /*eslint-disable*/
    return {
        restrict: 'AEC',
        scope: {
            rDirections: '=',
            rCenteredX: '=',
            rCenteredY: '=',
            rWidth: '=',
            rHeight: '=',
            rMinHeight: '=',
            rMatchChildHeight: '<',
            rFlex: '=',
            rGrabber: '@',
            rDisabled: '@',
            onResize: '&',
        },
        link: function (scope, element, attrs) {
            var flexBasis =
                'flexBasis' in document.documentElement.style
                    ? 'flexBasis'
                    : 'webkitFlexBasis' in document.documentElement.style
                    ? 'webkitFlexBasis'
                    : 'msFlexPreferredSize' in document.documentElement.style
                    ? 'msFlexPreferredSize'
                    : 'flexBasis'
            // register watchers on width and height attributes if they are set
            scope.$watch('rWidth', function (rWidth) {
                element[0].style.width = rWidth + 'px'
            })
            scope.$watch('rHeight', function (rHeight) {
                element[0].style.height = rHeight + 'px'
            })

            element.addClass('resizable')

            var style = $window.getComputedStyle(element[0], null),
                w,
                h,
                dir = scope.rDirections,
                vx = scope.rCenteredX ? 2 : 1, // if centered double velocity
                vy = scope.rCenteredY ? 2 : 1, // if centered double velocity
                inner = scope.rGrabber ? scope.rGrabber : '<span></span>',
                start,
                dragDir,
                axis,
                info = {}

            var updateInfo = function (e) {
                info.width = false
                info.height = false
                if (axis === 'x') {
                    info.width = parseInt(element[0].style[scope.rFlex ? flexBasis : 'width'])
                } else {
                    info.height = parseInt(element[0].style[scope.rFlex ? flexBasis : 'height'])
                }
                if (scope.rMatchChildHeight) {
                    info.childHeight = element[0].firstElementChild.getBoundingClientRect().height
                }
                info.id = element[0].id
                info.evt = e

                scope.onResize({ $width: info.width, $height: info.height })
            }
            var throttledEmit = DOMUtility.rafThrottle(function () {
                $rootScope.$emit('angular-resizable.resizing', info)
            })

            var dragging = function (e) {
                var prop,
                    offset = axis === 'x' ? start - e.clientX : start - e.clientY
                switch (dragDir) {
                    case 'top':
                        prop = scope.rFlex ? flexBasis : 'height'
                        element[0].style[prop] = h + offset * vy + 'px'
                        break
                    case 'bottom':
                        let newHeight = h - offset * vy

                        if (scope.rMatchChildHeight && info.childHeight < newHeight) {
                            return
                        }

                        prop = scope.rFlex ? flexBasis : 'height'
                        element[0].style[prop] =
                            scope.frMinHeight && scope.rMinHeight > newHeight
                                ? scope.rMinHeight
                                : newHeight + 'px'
                        break
                    case 'right':
                        prop = scope.rFlex ? flexBasis : 'width'
                        element[0].style[prop] = w - offset * vx + 'px'
                        break
                    case 'left':
                        prop = scope.rFlex ? flexBasis : 'width'
                        element[0].style[prop] = w + offset * vx + 'px'
                        break
                }
                updateInfo(e)
                throttledEmit()
            }
            var dragEnd = function () {
                updateInfo()
                $rootScope.$emit('angular-resizable.resizeEnd', info)
                $($window).trigger('resize')
                scope.$apply()
                document.removeEventListener('mouseup', dragEnd, false)
                document.removeEventListener('mousemove', dragging, false)
                element.removeClass('no-transition')
            }
            var dragStart = function (e, direction) {
                // element.css({
                //     'height': parseInt(style.getPropertyValue('height')) + 'px',
                //     'min-height': '100px'
                // });
                dragDir = direction
                axis = dragDir === 'left' || dragDir === 'right' ? 'x' : 'y'
                start = axis === 'x' ? e.clientX : e.clientY
                w = parseInt(style.getPropertyValue('width'))
                h = parseInt(style.getPropertyValue('height'))

                //prevent transition while dragging
                element.addClass('no-transition')

                document.addEventListener('mouseup', dragEnd, false)
                document.addEventListener('mousemove', dragging, false)

                // Disable highlighting while dragging
                if (e.stopPropagation) {
                    e.stopPropagation()
                }
                if (e.preventDefault) {
                    e.preventDefault()
                }
                e.cancelBubble = true
                e.returnValue = false

                updateInfo(e)
                $rootScope.$emit('angular-resizable.resizeStart', info)
                scope.$apply()
            }

            dir.forEach(function (direction) {
                var grabber = document.createElement('div')

                // add class for styling purposes
                grabber.setAttribute('class', `rg-${direction} resizable-handle`)
                grabber.innerHTML = inner
                element[0].appendChild(grabber)
                grabber.ondragstart = function () {
                    return false
                }
                grabber.addEventListener(
                    'mousedown',
                    function (e) {
                        var disabled = scope.rDisabled === 'true'
                        if (!disabled && e.which === 1) {
                            // left mouse click
                            dragStart(e, direction)
                        }
                    },
                    false
                )
            })
        },
    }
}

export /* @ngInject */ function resizeColumnsDirective($timeout) {
    return {
        restrict: 'AE',
        scope: {
            rhHandle: '@',
            rhCursor: '@',
            rhMin: '@',
            rhWidth: '@',
        },

        link: function (scope, element) {
            var opt = {
                cursor: scope.rhCursor || 'ew-resize',
                min: scope.rhMin || 5,
            }

            var body = $('body'),
                handles,
                cols,
                priorCursor = body.css('cursor'),
                z_idx

            // Set stored width of columns
            $timeout(function () {
                cols = element.find('.js-col')

                var counter = cols.length,
                    len = counter - 1

                while (counter--) {
                    var storedWidth = $(cols[counter]).data('flexwidth')
                    if (len !== counter) {
                        $(cols[counter])
                            .css({
                                '-webkit-flex-grow': storedWidth,
                                '-moz-flex-grow': storedWidth,
                                'flex-grow': storedWidth,
                            })
                            .after("<div class='resize-handle js-resize-handle'></div>")
                    } else {
                        $(cols[counter]).css({
                            '-webkit-flex-grow': storedWidth,
                            '-moz-flex-grow': storedWidth,
                            'flex-grow': storedWidth,
                        })
                    }
                }

                handles = element.find('.js-resize-handle')

                handles.css('cursor', opt.cursor)

                handles.bind('mousedown', function () {
                    var that = $(this),
                        startHandlePos,
                        leftPercentage,
                        rightPercentage,
                        leftPos = $('.js-col:first-child').offset().left,
                        parentWidth = $(this).parent().innerWidth(),
                        prev = $(this).prev(),
                        next = $(this).next()

                    startHandlePos = ($(this).offset().left - leftPos) / parentWidth

                    priorCursor = body.css('cursor')
                    body.css('cursor', opt.cursor)

                    that.addClass('draggable')

                    z_idx = that.css('z-index')

                    that.css('z-index', 1000)
                        .parents()
                        .bind('mousemove', function (e) {
                            var currnetHandlePos = (e.pageX - leftPos) / parentWidth,
                                offsetHandle = startHandlePos - currnetHandlePos

                            leftPercentage = parseFloat(prev.css('flex-grow')) + -1 * offsetHandle
                            rightPercentage = parseFloat(next.css('flex-grow')) + offsetHandle

                            // Limit min width in %
                            if (leftPercentage * 100 < opt.min || rightPercentage * 100 < opt.min) {
                                e.preventDefault() // disable selection
                                return
                            }

                            // Limit min width in px
                            // if (prev.outerWidth() < opt.min || next.outerWidth() < opt.min) {
                            //     e.preventDefault(); // disable selection
                            //     return;
                            // }

                            prev.css({
                                '-webkit-flex-grow': leftPercentage,
                                '-moz-flex-grow': leftPercentage,
                                'flex-grow': leftPercentage,
                            })
                            next.css({
                                '-webkit-flex-grow': rightPercentage,
                                '-moz-flex-grow': rightPercentage,
                                'flex-grow': rightPercentage,
                            })

                            // Emit event for other components
                            scope.$emit('resize-columns.resizing')

                            // Store width in data attr
                            prev.data('flexwidth', leftPercentage)
                            next.data('flexwidth', rightPercentage)

                            startHandlePos = (that.offset().left - leftPos) / parentWidth

                            e.preventDefault() // disable selection
                        })
                })
            })

            $(document).bind('mouseup', function () {
                body.css('cursor', priorCursor)
                $('.draggable').removeClass('draggable').css('z-index', z_idx)
                handles.parents().unbind('mousemove')
            })

            scope.$on('$destroy', function () {
                handles.unbind('mousedown')
                handles.parents().unbind('mousemove')
                $(document).unbind('mouseup')
            })
        },
    }
}
