import _ from 'lodash'

/**
 * TimelabelsCalculator prepares timelabels data for TimelabelsDrawer
 */
export default class TimelabelsCalculator {
    /**
     * Construct a TimelabelsCalculator from options:
     *
     * - `frameRate`: The video frame rate, needed for per-frame timelabel calculations.
     *   Defaults to `23.976`
     *
     * - `TIMELABEL_MIN_WIDTH`: Minimum distance between major timelabels.
     *   Defaults to `75` pixels
     *
     * - `DIVISORS`: Number of divisors per major non-frame-accurate timelablel.
     *   Defaults to `15`
     */
    constructor(opts = {}) {
        this.frameRate = _.get(opts, 'frameRate', 24000 / 1001) // 23.976 default
        this.TIMELABEL_MIN_WIDTH = _.get(opts, 'TIMELABEL_MIN_WIDTH', 75)
        this.DIVISORS = _.get(opts, 'DIVISORS', 15)
    }

    /**
     * Calculate cardinality time scale - ie, how many seconds between major timelabels
     *
     * Cardinality is caluclated based on what is the maximum number of major timelabels
     * that we can fit on a given timeline, if we want to keep the distance between them
     * always at least TIMELABEL_MIN_WIDTH pixels
     *
     * The returned results can be non-frame-accurate, for timelabels distance > 1 sec,
     * or frame-accurate for timelabels distance < 1 sec, in which case timelabels are
     * placed X frames apart, and the divisors are equal to X instead of their default
     * value.
     *
     * @param {number} width The full timeline width after zoom applied
     * @param {number} duration The full video duration in seconds
     * @return {array} `[secondsPerMajorTimelabel, divisors, isFrameAccurate]`
     */
    cardinality(width, duration) {
        const frame = 1 / this.frameRate
        const second = 1
        const minute = 60
        const hour = 60 * 60

        const framesCardinalities = [1, 5, 15, 30, 60, 120]
            .filter((frames) => frames < this.frameRate)
            .map((frames) => [frames * frame, frames, /* isFrameAccurate */ true])

        /* eslint-disable */
        const secondsCardinalities = [
            1 * second,
            5 * second,
            15 * second,
            30 * second,
            1 * minute,
            5 * minute,
            15 * minute,
            30 * minute,
            1 * hour,
            5 * hour,
            15 * hour,
        ].map((seconds) => [seconds, this.DIVISORS, /* isFrameAccurate */ false])
        /* eslint-enable */

        const cardinalities = [].concat(framesCardinalities, secondsCardinalities)

        let result = false
        let cardinality
        let index = cardinalities.length
        // iterate cardinalites from largest to smallest
        while ((cardinality = cardinalities[--index])) {
            if (width / Math.floor(duration / cardinality[0]) >= this.TIMELABEL_MIN_WIDTH) {
                result = cardinality
            } else {
                break
            }
        }

        return result
    }

    /**
     * Construct an array of timelabels, with the following properties:
     *  - `x`: The X coordinate of the timelabel on the timeline
     *  - `time`: The time value of the timelabel, in seconds
     *  - `isFrameAccurate`: Does the timelable have frame accurate positioning applied
     *
     * Timelabels are provided only for the values between immediately before and immediately
     * after the start and end seconds, so that the draw function doesn't need to do limiting itself.
     *
     * @param {number} totalDuration Video duration, in seconds
     * @param {number} totalWidth Timeline width, after zoom
     * @param {number} startSec The starting sec of the currently visible timeline section
     * @param {number} endSec The ending sec of the currently visible timeline section
     * @param {number=} singleFrameWidth The pixel width of a single frame on the timeline.
     *  If provided, will be used to calculate timelabels spacing on frame accurate cardinalities
     *
     * @return {Array<(x, time, divisors, isFrameAccurate)>}
     */
    timelables(totalDuration, totalWidth, startSec, endSec, singleFrameWidth = null) {
        const cardinalityData = this.cardinality(totalWidth, totalDuration)

        if (cardinalityData === false) {
            return []
        }

        const [cardinality, divisors, cardinalityIsFrameAccurate] = cardinalityData
        const isFrameAccurate = cardinalityIsFrameAccurate && singleFrameWidth

        const lastTimelabelSec = Math.ceil(totalDuration / cardinality) * cardinality
        const numberOfTimelables = 1 + lastTimelabelSec / cardinality

        const cardinalityX = Math.max(
            totalWidth / (totalDuration / cardinality),
            this.TIMELABEL_MIN_WIDTH
        )

        const timelabels = []
        let index = -1

        while (++index < numberOfTimelables) {
            const time = index * cardinality
            const x = isFrameAccurate ? index * divisors * singleFrameWidth : index * cardinalityX

            const renderStart = time + cardinality > startSec || time === startSec
            const renderEnd = time - cardinality < endSec || time === endSec

            if (renderStart && renderEnd) {
                const timelabel = { x, time, divisors, isFrameAccurate }
                timelabels.push(timelabel)
            }
        }

        return timelabels
    }
}
