import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'
import { ExportStatusPollerInstance } from 'services/ExportStatusPoller.factory'
import { ExportStatus } from 'constants.es6'

interface ITasksExportStatuses {
    STATUS_INIT: ExportStatus.Init
    STATUS_POLLING: ExportStatus.Polling
    STATUS_DOWNLOAD_READY: ExportStatus.DownloadReady
    STATUS_FAILED: ExportStatus.Failed
}

interface IDateConfig {
    minDate: Date
    maxDate: Date
    showWeeks: boolean
}

interface ITasksExportModalCtrl {
    status: ExportStatus.Init
    fullReport: boolean
    startDateConfig: IDateConfig
    endDateConfig: IDateConfig
    errorMessage: string | false
    start_date: Date
    end_date: Date
    poller: any
    exportModel: any

    startExport: () => ng.IPromise<void>
}

export default /* @ngInject */ function TasksExportModalFactory(
    $uibModal: ng.ui.bootstrap.IModalService
) {
    const TasksExportModal = {
        showModal() {
            $uibModal.open({
                templateUrl: 'js/admin/tasks-export-modal.tpl.html',
                controller: TasksExportModalCtrl,
                controllerAs: TasksExportModalCtrl.controllerAs,
                backdrop: 'static',
            })
        },
    }

    return TasksExportModal
}

/* @ngInject */
function TasksExportModalCtrl(
    this: unknown,
    $scope: ng.IScope & ITasksExportStatuses,
    $http: ng.IHttpService,

    ErrorStringifier: ErrorStringifierInstance,
    ExportStatusPoller: ExportStatusPollerInstance
) {
    const vm = this as ITasksExportModalCtrl

    $scope.STATUS_INIT = ExportStatus.Init
    $scope.STATUS_POLLING = ExportStatus.Polling
    $scope.STATUS_DOWNLOAD_READY = ExportStatus.DownloadReady
    $scope.STATUS_FAILED = ExportStatus.Failed

    vm.status = ExportStatus.Init
    vm.startExport = startExport
    vm.fullReport = true

    const minDate = new Date('2015-11-01')
    const maxDate = new Date()

    vm.startDateConfig = { minDate, maxDate, showWeeks: false }
    vm.endDateConfig = { minDate, maxDate, showWeeks: false }

    activate()

    return vm

    ////////////////////////////////////

    function activate() {
        $scope.$watch('vm.start_date', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                vm.endDateConfig.minDate = vm.start_date
            }
        })
        $scope.$watch('vm.end_date', (newValue, oldValue) => {
            if (newValue !== oldValue) {
                vm.startDateConfig.maxDate = vm.end_date
            }
        })

        $scope.$on('$destroy', () => {
            if (vm.poller) {
                vm.poller.destroy()
                vm.poller = null
            }
        })
    }

    function startExport() {
        const params = vm.fullReport
            ? {}
            : { params: { start_date: vm.start_date, end_date: vm.end_date || maxDate } }
        const promise = $http
            .get('/api/admin/tasks/excel', { ...params })
            .then((res) => res.data)
            .then((exportModel) => {
                vm.poller = new ExportStatusPoller(vm, exportModel)
                vm.exportModel = vm.poller.exportModel
            })
            .catch((err) => {
                if (err) {
                    vm.errorMessage = ErrorStringifier.stringify(err)
                }
            })

        return promise
    }
}

TasksExportModalCtrl.controllerAs = 'vm'
