import _ from 'lodash'

import { MissingSongsInstance } from './MissingSongs.factory'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'

interface IMissingSongResponseCtrlInstance {
    missingSongRequest: any

    submit: () => void
    extraInformationRequired: () => boolean
}

interface ScopeBinding {
    STATE_DENIED: string
}

export default /* @ngInject */ function MissingSongResponseCtrl(
    this: unknown,
    $scope: ng.IScope & ScopeBinding,
    Notification: NotificationServiceInstance,
    MissingSongs: MissingSongsInstance,

    missingSongRequest: any,
    missingSongRequestToken: string
) {
    const vm = this as IMissingSongResponseCtrlInstance

    $scope.STATE_DENIED = 'denied'

    vm.missingSongRequest = missingSongRequest
    vm.missingSongRequest.response_data = _.defaults(vm.missingSongRequest.response_data, {
        gracenoteId: null,
        state: null,
        information: '',
    })
    vm.extraInformationRequired = extraInformationRequired
    vm.submit = submit

    ////////////////////////////////////////

    function submit() {
        const promise = MissingSongs.resolveMissingSongRequest(
            missingSongRequestToken,
            vm.missingSongRequest.response_data
        ).then((missingSongRequest) => {
            vm.missingSongRequest = missingSongRequest
        })

        Notification.forPromise(promise, 'Your response has been submitted. Thank you.', '')
    }

    function extraInformationRequired() {
        const ret = _.includes(['duplicate', 'denied'], vm.missingSongRequest.response_data.state)
        return ret
    }
}

MissingSongResponseCtrl.resolve = {
    missingSongRequestToken: /* @ngInject */ ($stateParams: any) =>
        $stateParams.missingSongRequestToken,
    missingSongRequest: /* @ngInject */ (
        $state: any,
        MissingSongs: MissingSongsInstance,
        missingSongRequestToken: string
    ) => {
        return (
            MissingSongs.getMissingSongRequest(missingSongRequestToken)
                // treat errors as 404
                .catch(() => {
                    $state.go('errorPageNotFound')
                })
        )
    },
}

MissingSongResponseCtrl.controllerAs = 'vm'
