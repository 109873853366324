export default /* @ngInject */ function baseUrlInterceptorFactory(
    $q: ng.IQService,
    API_URL: string
) {
    const prefixBaseUrlInterceptor = {
        request: function (config: { [key: string]: any }) {
            if (/^\/?api\//.test(config.url)) {
                config.url = API_URL + config.url
            }

            return config || $q.when(config)
        },
    }

    return prefixBaseUrlInterceptor
}
