import _ from 'lodash'
import dateParse from 'date-fns/parse'
import dateIsValid from 'date-fns/is_valid'
import lockAndLoad from 'services/lockAndLoad'

var timezones
var timezonesPromise = import('./timezoneInput.timezones.json')
    .then((m) => m.default)
    .then((result) => {
        timezones = result
    })

/**
 * @ngdoc directive
 * @name tzDate
 * @module map3.core
 * @description
 * A {@link tzDateFilter} helper directive that displays dates in the user's set
 * timezone.
 *
 * @scope true
 * @restrict E
 *
 * @param {number|string} date Unix epoch timestamp
 * @param {string} format Custom display format
 * @param {boolean} noTimezone Do not apply the user timezone to the given date
 */
export /* @ngInject */ function tzDateDirective($filter) {
    const FORMAT_FULL = 'yyyy-MM-ddTHH:mm:ssZ'
    const FORMAT_NORMAL = 'MM/dd/yyyy'
    const $tzDateFilter = $filter('tzDate')

    var directive = {
        restrict: 'E',
        scope: {
            date: '<',
            format: '@?',
        },
        template: `<span></span>`,
        link: lockAndLoad(
            [timezonesPromise],
            tzDateLinkFn,
            angular.noop,
            tzDateLinkFn // execute even if getting timezones fails
        ),
    }

    return directive

    /////////////////////////

    function tzDateLinkFn(scope, element, attr) {
        const isStatic = !_.isUndefined(attr.static) || ('' + attr.date).indexOf('::') === 0
        const useTimezone = angular.isUndefined(attr.noTimezone)

        if (isStatic) {
            // only update the data once
            updateDateStrings()
        } else {
            // setup watchers
            scope.$watch('date', updateDateStrings)
            scope.$watch('format', updateDateStrings)
        }

        //////////////////////////////////////////

        function updateDateStrings() {
            // null dates do not have any reasonable rendering, render blank
            if (_.isNil(scope.date) || 0 === scope.date) {
                return updateDOM(element, '', '')
            } // eslint-disable-line

            let dateStringFull, dateStringNormal

            // backend will sometimes present an unix timestamp in seconds
            // that we need to convert to milliseconds
            const timestamp = _.toInteger(scope.date) && _.toInteger(scope.date) * 1000
            // then we try to parse either the timestamp, or the raw date string we got
            const parsedDate = dateParse(timestamp || scope.date)

            if (dateIsValid(parsedDate)) {
                dateStringFull = $tzDateFilter(parsedDate, FORMAT_FULL, useTimezone)
                dateStringNormal = $tzDateFilter(
                    parsedDate,
                    scope.format || FORMAT_NORMAL,
                    useTimezone
                )
            } else {
                dateStringFull = ''
                dateStringNormal = ''
            }

            // if the dates have changed since the last time we wrote them to the DOM
            if (
                scope.dateStringFull !== dateStringFull ||
                scope.dateStringNormal !== dateStringNormal
            ) {
                // update local copies
                scope.dateStringFull = dateStringFull
                scope.dateStringNormal = dateStringNormal

                // and write to DOM
                updateDOM(element, scope.dateStringFull, scope.dateStringNormal)
            }
        }

        function updateDOM(element, title, text) {
            element.find('span').attr('title', title).text(text)
        }
    }
}

/**
 * @ngdoc filter
 * @name tzDateFilter
 * @module map3.core
 * @kind function
 *
 * @description
 * A wrapper around [date filter](https://docs.angularjs.org/api/ng/filter/date)
 * that automatically applies the current users's timezone.
 *
 * @param {Date|number|string} date Everything that date filter accepts
 * @param {string=} format Formatting rules
 */
export /* @ngInject */ function tzDateFilter($rootScope, $filter, User) {
    var $dateFilter = $filter('date')

    return function (date, format, useTimezone = true) {
        const user = User.cached()

        if (timezones) {
            if (user.$$generationTimezone !== user.timezone) {
                user.$$generationTimezone = user.timezone

                const timezoneData = _.find(timezones, { cannonicalName: user.timezone })
                if (timezoneData) {
                    user.$$zoneOffset = timezoneData.offsetAngular
                }
            }
        }

        return $dateFilter(date, format, (useTimezone && user.$$zoneOffset) || 'UTC')
    }
}
