import _ from 'lodash'
import 'jquery.caret'
import 'at.js'

/**
 * @ngdoc directive
 * @name atComplete
 * @module map3.core
 * @restrict A
 *
 * @description
 * This directive attaches [At.js](https://github.com/ichord/At.js) to an element.
 *
 * @param {Array<string>} atComplete List of autocomplete strings.
 * @param {string=} atChar Alternative at-char. Default is `$`.
 */
export default /* @ngInject */ function atCompleteDirective($parse, $timeout, $log) {
    const DEFAULT_AT_CHAR = '$'

    var directive = {
        restrict: 'A',
        link: atCompleteLinkFn,
    }

    return directive

    ///////////////////////////////////////////////

    function atCompleteLinkFn($scope, element, attr) {
        var getData = $parse(attr.atComplete)
        var atChar = attr.atChar || DEFAULT_AT_CHAR

        let promise = $timeout(
            function () {
                element.atwho({
                    at: atChar,
                    data: parseData(getData($scope), atChar),
                })
            },
            0,
            false
        )

        $scope.$on('$destroy', () => $timeout.cancel(promise))
    }

    function parseData(data, atChar) {
        return _.compact(
            _.map(data, function (item) {
                var stringItem = item + ''

                if (_.startsWith(stringItem, atChar)) {
                    return stringItem.substring(1)
                } else {
                    $log.error(
                        `Invalid at-complete data given: "${stringItem}" must begin with "${atChar}"`
                    )
                    return undefined
                }
            })
        )
    }
}
