import type { BackendQAAnnotation } from 'qa/service/QAAnnotation.factory'
import { getColorFromIndex } from './BoundryBoxDrawer.factory'

const boundingBoxMarkerComponent = {
    transclude: true,
    controller: boundingBoxMarkerController,
    bindings: {
        annotation: '<',
    },
    template: `
        <span ng-style="$ctrl.style" ng-if="$ctrl.annotation.extra_data.bounding_box">
            &#9679;
        </span>
    `,
}

export default boundingBoxMarkerComponent

interface ScopeBindings extends ng.IComponentController {
    annotation: BackendQAAnnotation
}

/* @ngInject */
function boundingBoxMarkerController(this: ScopeBindings) {
    const $ctrl = this

    $ctrl.$onInit = $onInit

    //////////////////////

    return $ctrl

    /////////////////////////////////////

    function $onInit() {
        const color = getColorFromIndex($ctrl.annotation.extra_data?.bounding_box_color_index)

        $ctrl.style = {
            top: '-1px',
            color: `${color}`,
            position: 'relative',
            display: 'inline-block',
            'user-select': 'none',
        }
    }
}
