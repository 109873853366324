import _ from 'lodash'
import fastdom from 'fastdom'
import browserInfo from 'browser-info'
import lockAndLoad from 'services/lockAndLoad'
import VideoAccess from 'services/VideoAccess'
import { VGPlayerTimeFormat, ApiURL, Environment } from 'constants.es6'
import { getSecondsFormatter, timestampWithMilli, timeStampWithSecAndMSec } from 'services/utils'
import videoGorillasPlayerTpl from './videoGorillasPlayer.tpl.html'
import { TIME_FORMAT_KEYBOARD_CHANGE_EVENT } from 'directives/constants'
import StandardInput from './StandardInput'
import SecondsInput from './SecondsInput'
import { newRelic } from 'util/newRelic'
import isAfter from 'date-fns/is_after'

const VIDEO_PLAYER_TIME_TO_CLASS_NAME = {
    //[VGPlayerTimeFormat.Clock]: 'js-video-player-holder--with-standard-clock',
    [VGPlayerTimeFormat.Clock]: 'js-video-player-holder--with-fs-standard-clock',
    [VGPlayerTimeFormat.Seconds]: 'js-video-player-holder--with-seconds',
    [VGPlayerTimeFormat.Tape]: 'js-video-player-holder--with-tape',
    [VGPlayerTimeFormat.TapeDF]: 'js-video-player-holder--with-tape',
    [VGPlayerTimeFormat.Frame]: 'js-video-player-holder--with-frame-time',
}

/**
 * @ngdoc directive
 * @name videoGorillasPlayer
 * @module map3.core
 * @restrict E
 *
 * @scope true
 *
 * @description
 *
 * VideoGorillas player wrapping directive
 *
 * Setups the following shortcuts:
 *  - `ctrl+f` - Rewind 1 sec
 *  - `ctrl+g` - Rewind 30 sec
 *  - `ctrl+v` - Forward 1 sec
 *  - `ctrl+b` - Forward 30 sec
 *  - `ctrl+o` - Open in full screen
 *
 * @param {object} video The video data
 * @param {videoAPI=} api Export a {@link videoAPI} instance for this video player
 * @param {Array.<Marker>=} markers Markes to display with the video
 * @param {string=} isHidden Variable to set if the directive is hidden.
 *                           Will be toggled on popup open/close.
 */
export default /* @ngInject */ function videoGorillasPlayerDirective(
    $state,
    $rootScope,
    $timeout,
    $log,
    $uibModal,
    API_URL,
    ENVIRONMENT,
    VideoCommunication,
    CommentInterface,
    GlobalShortcuts,
    User,
    SharedVideoAPI,
    VideoAPIBuilder,
    BoundryDrawer,
    RoutingAuth
) {
    const directive = {
        restrict: 'E',
        transclude: true,
        scope: {
            videoApi: '=?api',
            video: '<',
            videoDescription: '<?',
            taskDescription: '<?',
            markers: '<',
            annotations: '<',
            useBoudningBoxes: '<',
            isHidden: '=?',
            popupDisabled: '<?',
            hasTimeToggle: '<?',
            timeFormat: '<?',
            hasTimeTooltip: '<?',
            stopPlayerOnMarker: '<?',
            hasDropFrames: '<?',
        },
        template: videoGorillasPlayerTpl,
        link: lockAndLoad(
            [
                import('vendors/video-gorillas/vgplayer').then(() => import('./markers.plugin')),

                (scope) => {
                    if (ENVIRONMENT === Environment.LocalDevelopment && API_URL !== ApiURL.Dev) {
                        return true
                    } else {
                        return VideoAccess.acquire(scope.video)
                    }
                },
            ],
            videoPlayerLinkFn,
            videoPlayerLoadingLinkFn,
            videoPlayerErrorLinkFn
        ),
    }

    return directive

    ////////////////////////////

    function videoPlayerLoadingLinkFn(scope, element, ...rest) {
        element.addClass('loading ')

        const dereg = VideoAccess.addAccessRevokedListener(() => {
            dereg()
            VideoAccess.releaseVideo()
            videoPlayerErrorLinkFn(...[new Error('Video Access Revoked'), scope, element, ...rest])
        })

        scope.$on('$destroy', () => {
            dereg()
            VideoAccess.releaseVideo()
        })
    }

    function videoPlayerErrorLinkFn(err, _scope, element) {
        $log.debug('video access error', err)
        element.removeClass('loading')
        element.addClass('error')

        $state.go('auth.errorMultipleVideosPlaying', {}, { location: false })
    }

    function videoPlayerLinkFn(scope, element) {
        if (browserInfo().name === 'Firefox') {
            const $uibModalInstance = $uibModal.open({
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'js/video/browser-error-notification-tpl.html',
                controller: /* @ngInject */ function ($scope, $uibModalInstance) {
                    const unregister = $rootScope.$on('$stateChangeStart', () => {
                        $uibModalInstance.close()
                    })
                    $scope.$on('$destroy', unregister)
                },
            })
            $uibModalInstance.result.then(() => {
                $state.go(RoutingAuth.getDefaultState(), undefined, {
                    reload: false,
                })
            })

            element.removeClass('loading')

            return
        }

        scope.isHidden = scope.isHidden || false
        if (scope.stopPlayerOnMarker) {
            scope.stopPlayOnMarker = { value: false }
        }

        if (!scope.video) {
            $log.error('Cannot initialize video-gorillas-player directive, video not supplied')
            return
        }

        const options = _.assign(
            {
                hotkeys: true,
                skinless: false,
                theme: 'abc',
                allowCrossSiteCredentials: true,
                enableCORS: false,
            },
            _.pick(_.get(scope.video, 'video'), ['drm', 'queryParams'])
        )

        const videoPlayerElement = element.find('.video-player-holder')

        const player = new window.VG.Player(videoPlayerElement[0], options)

        player.handleBookmarkDeletion = CommentInterface.showDeleteConfirmationModal

        const broadCastTimeToggle = (timeFormat) => {
            scope.$broadcast(TIME_FORMAT_KEYBOARD_CHANGE_EVENT, timeFormat)
        }

        const stopPlayAtMarkerShortcuts = scope.stopPlayerOnMarker
            ? [
                  {
                      description: 'Toggle pause on marker',
                      global: true,
                      keyCombo: ['alt+p'],
                      shortcut: 'Alt+P',
                      callback: () => {
                          scope.stopPlayOnMarker.value = !scope.stopPlayOnMarker.value
                      },
                  },
              ]
            : []

        const timeToggleShortcuts = scope.hasTimeToggle
            ? [
                  {
                      description: 'Display time in SMPTE',
                      global: true,
                      keyCombo: ['alt+shift+1'],
                      shortcut: 'Alt+shift+1',
                      callback: function () {
                          broadCastTimeToggle(VGPlayerTimeFormat.Tape)
                      },
                  },
                  {
                      description: 'Display time in SMPTE DF',
                      global: true,
                      keyCombo: ['alt+shift+2'],
                      shortcut: 'Alt+shift+2',
                      callback: function () {
                          //   if (scope.hasDropFrames) {
                          //       broadCastTimeToggle(VGPlayerTimeFormat.TapeDF)
                          //   }
                          broadCastTimeToggle(VGPlayerTimeFormat.TapeDF)
                      },
                  },
                  {
                      description: 'Display time in standard format',
                      global: true,
                      keyCombo: ['alt+shift+3'],
                      shortcut: 'Alt+shift+3',
                      callback: function () {
                          broadCastTimeToggle(VGPlayerTimeFormat.Clock)
                      },
                  },
                  {
                      description: 'Display time in frames format',
                      global: true,
                      keyCombo: ['alt+shift+4'],
                      shortcut: 'Alt+shift+4',
                      callback: function () {
                          broadCastTimeToggle(VGPlayerTimeFormat.Frame)
                      },
                  },
                  {
                      description: 'Display time in seconds',
                      global: true,
                      keyCombo: ['alt+shift+5'],
                      shortcut: 'Alt+shift+5',
                      callback: function () {
                          broadCastTimeToggle(VGPlayerTimeFormat.Seconds)
                      },
                  },
              ]
            : []

        const shortcutsGroup = {
            title: 'Video Player Shortcuts',
            shortcuts: [
                {
                    description: 'Play/stop',
                    shortcut: 'Space',
                },
                {
                    description: 'Forward 1 frame',
                    shortcut: 'Right Arrow',
                },
                {
                    description: 'Backward 1 frame',
                    shortcut: 'Left Arrow',
                },
                {
                    description: 'Backward 1 sec',
                    global: true,
                    keyCombo: ['ctrl+shift+f', 'meta+shift+f'],
                    shortcut: 'control+shift+F|Down Arrow',
                    callback: function (event) {
                        shortcutOnSeek(event, -1)
                    },
                },
                {
                    description: 'Backward 30 sec',
                    global: true,
                    keyCombo: ['ctrl+shift+g', 'meta+shift+g'],
                    shortcut: 'control+shift+G',
                    callback: function (event) {
                        shortcutOnSeek(event, -30)
                    },
                },
                {
                    description: 'Forward 1 sec',
                    global: true,
                    keyCombo: ['ctrl+shift+v', 'meta+shift+v'],
                    shortcut: 'control+shift+V|Up Arrow',
                    callback: function (event) {
                        shortcutOnSeek(event, 1)
                    },
                },
                {
                    description: 'Forward 30 sec',
                    global: true,
                    keyCombo: ['ctrl+shift+b', 'meta+shift+b'],
                    shortcut: 'control+shift+B',
                    callback: function (event) {
                        shortcutOnSeek(event, 30)
                    },
                },
                {
                    description: 'Open in Full Screen',
                    global: true,
                    keyCombo: ['ctrl+shift+o', 'meta+shift+o'],
                    shortcut: 'control+shift+O',
                    callback: function (event) {
                        $('.vg_fullScreen').click()

                        event.preventDefault()
                        return false
                    },
                },
                {
                    description: '1x forward',
                    global: true,
                    keyCombo: ['shift+1', 'shift+1'],
                    shortcut: 'shift+1',
                    callback: function () {
                        player.playAtRate(1)
                    },
                },
                {
                    description: '2x forward',
                    global: true,
                    keyCombo: ['shift+2', 'shift+2'],
                    shortcut: 'shift+2',
                    callback: function () {
                        player.playAtRate(2)
                    },
                },
                {
                    description: '4x forward',
                    global: true,
                    keyCombo: ['shift+4', 'shift+4'],
                    shortcut: 'shift+4',
                    callback: function () {
                        player.playAtRate(4)
                    },
                },
                {
                    description: '1x backward',
                    global: true,
                    keyCombo: ['ctrl+shift+1', 'meta+shift+1'],
                    shortcut: 'Ctrl+shift+1',
                    callback: function () {
                        player.playAtRate(-1)
                    },
                },
                {
                    description: '2x backward',
                    global: true,
                    keyCombo: ['ctrl+shift+2', 'meta+shift+2'],
                    shortcut: 'Ctrl+shift+2',
                    callback: function () {
                        player.playAtRate(-2)
                    },
                },
                {
                    description: '4x backward',
                    global: true,
                    keyCombo: ['ctrl+shift+4', 'meta+shift+4'],
                    shortcut: 'Ctrl+shift+4',
                    callback: function () {
                        player.playAtRate(-4)
                    },
                },
                ...timeToggleShortcuts,
                ...stopPlayAtMarkerShortcuts,
            ],
        }

        // expose API on scope
        scope.videoApi = VideoAPIBuilder.build(player, scope)
        scope.videoApi.onLoad(function () {
            $rootScope.$broadcast('videoApiReady', scope.videoApi)
            window.videoAPI = scope.videoApi

            videoPlayerElement
                .find('.video-player-hide-while-loading')
                .removeClass('video-player-hide-while-loading')
        })
        SharedVideoAPI.$setup(scope.videoApi)
        scope.$on('$destroy', SharedVideoAPI.$destroy)

        // remove .embed-responsive classes
        $('.vg_player')
            .removeClass('embed-responsive embed-responsive-16by9')
            .find('.responsive-item')
            .removeClass('responsive-item')

        // remove context menu on the video, to prevent easy access to video 'save as'
        element.find('video').on('contextmenu', function (e) {
            e.preventDefault()
            return false
        })

        document.querySelector('video').onloadedmetadata = (e) => {
            const videoWrapper = $('.vg_player')
            const video = $('.vg_player').find('video')[0]
            const aspect = video.videoWidth / video.videoHeight
            videoWrapper.css({
                height: '300px',
                width: `${300 * aspect}px`,
            })
        }

        // Close any opened dropdown menues
        element.find('.scar-video').on('mouseleave', function () {
            element.find('.dropdown-menu.show').removeClass('show')
        })

        player.load(guaranteeUsableURL(_.get(scope, 'video.video.url')), function () {
            scope.videoApi.setDropFramesMode(scope.hasDropFrames)
            const closedCaptons = _.get(scope, 'video.video.caption')
            if (closedCaptons) {
                setupClosedCaptions(closedCaptons)
            } else {
                $('#vg_subtitles').remove()
            }

            $('.vg_icon-subs').html(`
                <i class="material-icons icon-subtitles-disable">closed_caption_disabled</i>
                <i class="material-icons icon-subtitles-enable text-white">closed_caption</i>
            `)

            $('.vg_back30SecButton').html('<b class="vg-btn">-30</b>')
            $('.vg_back1SecButton').html('<b class="vg-btn">-1</b>')
            $('.vg_forward1SecButton').html('<b class="vg-btn">+1</b>')
            $('.vg_forward30SecButton').html('<b class="vg-btn">+30</b>')
            $('.vg_fullScreen').html(`
                <i class="material-icons icon-fullscreen">fullscreen</i>
                <i class="material-icons icon-fullscreen-exit">fullscreen_exit</i>
            `)

            $('.vg_playPauseButton').html(`
                <i class="material-icons md-36 icon-play text-white">play_arrow</i>
                <i class="material-icons md-36 icon-pause text-white">pause</i>
            `)
            $('.vg_icon-volume').html(`
                <i class="material-icons md-24 icon-mute">volume_off</i>
                <i class="material-icons md-24 icon-volume-up">volume_up</i>
            `)

            $('.vg_volumeScreenControls').addClass('col-3')

            $('.vg_controlls').prepend($('.js-video-speed'))

            $('.vg_allControls').prepend($('.js-time-format').css('visibility', 'visible'))
            $('.vg_allControls').prepend($('.vg_timevalues'))

            if (scope.hasTimeToggle === true) {
                const $container = $('<div class="vg_iconControls__container"></div>')
                $container.prepend($('.vg_iconControls__item').has('.vg_timecode__value'))

                const timestampInputTpl = element.find('#vg-timestampInput').html()
                const renderedTimestampInputTpl = _.template(timestampInputTpl)()
                const $timestampInputContainer = $(renderedTimestampInputTpl)

                const fsStandardTimeInputTpl = element.find('#vg-fsStandardTime').html()
                const renderedFSStandardInputTpl = _.template(fsStandardTimeInputTpl)()
                const $fsStandardTimeInputContainer = $(renderedFSStandardInputTpl)

                $container.append($timestampInputContainer)
                $container.append($fsStandardTimeInputContainer)

                $('.vg_timevalues').prepend($container)

                const duration = player.getSeekableDurationSec()
                const standardTime = timestampWithMilli(duration)
                const timestamp = timeStampWithSecAndMSec(duration)
                const tapeTime = scope.videoApi.convertSecondsToTape(duration)
                const frameTime = scope.videoApi.getLastFrameIdx()

                const totalDurationsTpl = element.find('#vg-totalDurations').html()
                const renderedTotalDurationTpl = _.template(totalDurationsTpl)({
                    tapeTime,
                    standardTime,
                    frameTime,
                    timestamp,
                })

                $container.after($(renderedTotalDurationTpl))

                const $timeStampInput = $('input', $timestampInputContainer)
                const timeStampInput = new SecondsInput($timeStampInput[0], scope.videoApi)
                const $fsStandardInput = $('input', $fsStandardTimeInputContainer)
                const standardInput = new StandardInput($fsStandardInput[0], scope.videoApi)

                const handleTimeUpdate = (time) => {
                    const timestamp = scope.videoApi.convertFrameToSeconds(time.frame)
                    timeStampInput.updateValue(timestamp)
                    standardInput.updateValue(timestamp)
                }

                scope.videoApi.addEventListener('timeupdate', handleTimeUpdate)

                scope.$on('$destroy', () => {
                    scope.videoApi.removeEventListener('timeupdate', handleTimeUpdate)
                    timeStampInput.destroy()
                    standardInput.destroy()
                })
            }

            if (scope.hasTimeTooltip === true) {
                const $scrubber = $('.vg_scrubber__wrap')
                const $timelineTooltip = $('<div class="vg_timeline_tooltip"></div>')
                const $timelineTooltipTail = $('<div class="vg_timeline_tooltip-tail"></div>')
                $scrubber.append($timelineTooltip, $timelineTooltipTail)
                const duration = player.getSeekableDurationSec()
                $scrubber.on('mousemove', (e) => {
                    fastdom.clear(scope.task)
                    scope.task = fastdom.measure(() => {
                        const width = $scrubber.innerWidth()
                        const timelineTooltipWidth = $timelineTooltip.outerWidth()
                        const timeInSec = (e.clientX * duration) / width
                        const res = scope.formatTime(timeInSec)
                        const tooltipPositionX = e.offsetX - timelineTooltipWidth / 2
                        const tooltipPositionXwithBoundaries =
                            tooltipPositionX < 0
                                ? 0
                                : width - e.pageX > timelineTooltipWidth / 2
                                ? tooltipPositionX
                                : width - timelineTooltipWidth

                        scope.task = fastdom.mutate(() => {
                            $('.vg_timeline_tooltip').css({
                                left: tooltipPositionXwithBoundaries,
                                top: -33,
                            })
                            $('.vg_timeline_tooltip-tail').css({
                                left: tooltipPositionX,
                                top: -33,
                            })
                            $timelineTooltip.text(`${res}`)
                            $timelineTooltipTail.text(`${res}`)
                        })
                    })
                })
            }

            scope.$watch('timeFormat', function (val) {
                if (scope.hasTimeToggle && !!val) {
                    scope.formatTime = getSecondsFormatter(val, scope.videoApi)
                }
                if (
                    scope.hasTimeToggle &&
                    (val === VGPlayerTimeFormat.Tape || val === VGPlayerTimeFormat.TapeDF)
                ) {
                    // Redraw totals and current time on time format change
                    const $tapeDurationEl = $('.vg_timevalues__tape .vg_duration-time')
                    const $smpteInputEl = $('.vg_timecode__value.vg_ndftc_time')
                    const duration = player.getSeekableDurationSec()
                    const tapeTime = scope.videoApi.convertSecondsToTape(duration)
                    $tapeDurationEl.text(tapeTime)
                    const curFrame = player.getCurrentFrame()
                    const tape = scope.videoApi.convertFrameToTape(curFrame)
                    $smpteInputEl.val(tape)
                }
            })

            $('.vg_allControls').append($('.js-video-right-panel'))
            $('.vg_embed-container').append($('.vg_timescrubber'))

            $('.vg_allControls').on('dblclick click', function (e) {
                if (e.currentTarget === e.target) {
                    $('.vg_player').trigger(e.type)
                }
            })

            let videoPopupUrl = _.get(scope, 'video.popupVideoURL')
            if (videoPopupUrl && scope.popupDisabled !== true) {
                $('.vg_newTab ')
                    .html(`<i class="material-icons icon-open-in-new">open_in_new</i>`)
                    .on('click', openVideoPopup)
            }

            const { fullname, username } = User.cached()

            // TODO: use Sunrise Date once provided from backend
            if (!scope.video.originalPremiereDate && !scope.video.releaseDate) {
                addUsernameOverlay(fullname, username)
            } else {
                const availableDate = scope.video.releaseDate
                    ? scope.video.releaseDate
                    : scope.video.originalPremiereDate
                const parsedDate = new Date(availableDate)

                // Add 30 days on top of original Sunrise date
                parsedDate.setDate(parsedDate.getDate() + 30)

                // Check if Today is after the date defined above
                if (isAfter(parsedDate, new Date())) {
                    addUsernameOverlay(fullname, username)
                }
            }

            setCustomRangeInput()

            $(document).on('onmozfullscreenchange', function () {
                $(videoPlayerElement).toggleClass('vg_fullscreenMode')
            })
            element.removeClass('loading')
        })

        // Notify New Relic for video player init
        setupNewRelicActions()

        // initialize markers plugin
        player.markers({
            markerStyle: {
                bottom: '2px',
            },
            markers: scope.markers,
        })
        // and setup a watcher to update it if we're given new markers
        scope.$watch('markers', function (markers) {
            player.markers.setMarkers(markers)

            if (scope.videoPopupWindow && scope.videoPopupWindow.videoPopupCommunication) {
                scope.videoPopupWindow.videoPopupCommunication.setMarkers(markers)
            }
        })

        scope.formatTime = (time) => time
        scope.getClassNameForTimeFormat = () => {
            return VIDEO_PLAYER_TIME_TO_CLASS_NAME[scope.timeFormat] || ''
        }
        scope.formatTime = getSecondsFormatter(scope.timeFormat, scope.videoApi)

        if (scope.useBoudningBoxes) {
            player.boundryDrawer = new BoundryDrawer(scope.videoApi)

            scope.$on('$destroy', () => {
                player.boundryDrawer.destroy()
            })
            scope.$watch('annotations', () => {
                if (scope.videoPopupWindow && scope.videoPopupWindow.videoPopupCommunication) {
                    scope.videoPopupWindow.videoPopupCommunication.setAnnotations(scope.annotations)
                }

                player.boundryDrawer.setAnnotations(scope.annotations)
            })
        }

        scope.$onRootScope('angular-resizable.resizing', function (_event, info) {
            const videoWrapper = $('.vg_player')
            const video = $('.vg_player').find('video')[0]
            const aspect = video.videoWidth / video.videoHeight
            const containerWidth = videoWrapper.parent().width()

            if (info.height * aspect < containerWidth) {
                if (info.height < 300) return

                videoWrapper.css({
                    height: info.height + 'px',
                    width: info.height * aspect + 'px',
                    'max-width': containerWidth + 'px',
                })
            }
            if (player.boundryDrawer) {
                player.boundryDrawer.forceFullRerender()
            }
        })

        if (scope.stopPlayerOnMarker) {
            scope.$watch('stopPlayOnMarker.value', (val) => {
                val
                    ? scope.videoApi.addEventListener('timeupdate', stopPlayerOnMarker)
                    : scope.videoApi.removeEventListener('timeupdate', stopPlayerOnMarker)
            })
        }

        const unbindShortcuts = GlobalShortcuts.bind(shortcutsGroup)

        // Release resources
        scope.$on('$destroy', function () {
            if (player) {
                try {
                    player.close()
                } catch (e) {}
            }

            scope.videoApi.destroy()

            if (scope.videoPopupWindow) {
                scope.videoPopupWindow.close()
                delete window.videoParentCommunication
            }

            newRelic.addPageAction('player.destroy')

            unbindShortcuts()

            window.cancelAnimationFrame(scope.animationRequestId)
        })

        ////////////////////////////////////
        function setupNewRelicActions() {
            newRelic.addPageAction('player.init', User.getUserFormattedForNewRelic())
        }

        function stopPlayerOnMarker() {
            if (!scope.stopPlayOnMarker.value) {
                window.cancelAnimationFrame(scope.animationRequestId)
                return
            }

            if (scope.videoApi.isPlaying()) {
                const currentTime = scope.videoApi.getCurrentFrameTime()
                const currentFrame = scope.videoApi.convertLaxSecondToFrame(currentTime)
                const nextMarkerFrame = _.get(
                    scope.markers.find((m) => m.frameNumber >= currentFrame - 1),
                    'frameNumber',
                    -1
                )

                if (currentFrame === nextMarkerFrame) {
                    scope.videoApi.pause()
                }
            }

            scope.animationRequestId = window.requestAnimationFrame(stopPlayerOnMarker)
        }

        function setupClosedCaptions(closedCaptons) {
            // ? do we need that?
            //var codec = window.VG.Captions.guessSubtitleCodec(closedCaptons)
            window.VG.Captions.parseSubs(
                player.player.getTimeline(),
                closedCaptons,
                'xml',
                function (err, subs) {
                    if (err) {
                        throw new Error(
                            `Cannot parse subtitles: ${subs};
                            Error: ${angular.toJson(err)}`
                        )
                    }

                    player.addCaptions(subs)

                    var observer = new MutationObserver(function (mutations, observer) {
                        $('.vg_ddown_hover__list-item__label.vg_info.view-item').trigger('click')
                        $('#vg_subtitles .vg_icon-subs').trigger('click')
                    })

                    observer.observe($('.vg_ddown_hover__list')[0], {
                        childList: true,
                        subtree: true,
                    })
                }
            )
        }

        function openVideoPopup() {
            let videoPopupUrl = _.get(scope, 'video.popupVideoURL')
            if (!videoPopupUrl) {
                return
            }

            setHidden(true)
            player.pause()
            player.disableHotKeys()

            window.videoParentCommunication = VideoCommunication.buildParent(scope, scope.videoApi)
            scope.videoPopupWindow = window.open(
                videoPopupUrl,
                'videoPopupWindow',
                'innerWidth=675, innerHeight=400, _blank'
            )
            scope.videoPopupWindow.annotations = scope.annotations
            scope.videoPopupWindow.useBoudningBoxes = scope.useBoudningBoxes
            scope.videoPopupWindow.addEventListener('beforeunload', function () {
                setHidden(false)
                player.enableHotKeys()

                delete scope.videoPopupWindow
                delete window.videoParentCommunication
            })
        }

        /**
         * set hidden/visible variable, and broadcast a delayed resize event
         */
        function setHidden(value) {
            scope.$applyAsync(function () {
                scope.isHidden = value
                $timeout(() => $rootScope.$broadcast('resize'), 50, false)
            })
        }

        function shortcutOnSeek(event, seconds) {
            scope.videoApi.seek(scope.videoApi.getCurrentFrameTime() + seconds)

            event.preventDefault()
            return false
        }
    }

    /**
     * Custom range input
     */
    function setCustomRangeInput() {
        const rangeEl = $('.vg_vertical-inputrange')[0]
        const videoEl = $('video')[0]
        const styleEl = $('<style>')[0]
        const trackSel = '::-webkit-slider-runnable-track'

        // short-circuit
        if (!(rangeEl && videoEl)) {
            return
        }

        document.body.appendChild(styleEl)

        setVolumeBar(rangeEl.value)

        rangeEl.addEventListener('input', () => setVolumeBar(rangeEl.value), false)

        videoEl.addEventListener('volumechange', function () {
            setVolumeBar(this.volume * 100)
        })

        function setVolumeBar(value) {
            styleEl.textContent = `.vg_vertical-inputrange${trackSel} {
                background-size: ${value}% 100%
            }`
        }
    }

    function addUsernameOverlay(fullname, username) {
        $('.vg_player').prepend(`
            <div class="username-overlay">
                <p class="username-overlay__text">${fullname} (${username})</p>
            </div>
        `)
    }

    /**
     * Tries to guarantee we have a url that is usable by Gorillas Videoplayer.
     *  - Will turn relative URLs to absolute
     */
    function guaranteeUsableURL(url) {
        url = '' + url

        if ('/' === url[0] || url.indexOf('http') !== 0) {
            url = API_URL + url
        }

        return url
    }
}
