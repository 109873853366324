/**
 * Deal with "Possibly Unhandled Rejection" errors in Angular 1.6+
 *
 * In many instances, we do not want to have to manually handle rejection values,
 * eg, closing a modal by clicking outside it is a rejection, but we don't care for that,
 * so can use this function to disable PUR exceptions on a specific promise
 *
 * @param {Promise} promise
 * @return {Promise}
 */
export default function markExceptionHandled(promise: any) {
    // https://github.com/angular/angular.js/blob/cdaa6a951b8fce67673bec224a7ad1f24f0a05bf/src/ng/q.js#L675-L683
    if (promise.$$state) {
        promise.$$state.pur = true
    }

    // don't require explicit reject promise handling on the next .then() invocation
    const originalThen = promise.then
    promise.then = function wrappedThen(...args: any[]) {
        const newPromise = originalThen.apply(promise, args)

        if (promise.$$state) {
            return markExceptionHandled(newPromise)
        }

        return newPromise
    }

    const originalCatch = promise.catch
    promise.catch = function wrappedCatch(...args: any[]) {
        const newPromise = originalCatch.apply(promise, args)

        if (promise.$$state) {
            return markExceptionHandled(newPromise)
        }

        return newPromise
    }

    return promise
}
