import _ from 'lodash'
import { AuthorityManagerInstance } from 'worker/AuthorityManager.factory'
import { CommentInterfaceInstance } from 'comments/CommentInterface.factory'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { QAAnnotationTreeInstance } from './service/QAAnnotationTree.factory'
import { QAAssignmentServiceInstance } from './QAAssignmentService.factory'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { IVideoData } from 'video/types'
import {
    IAuthorityData,
    IReviewData,
    IReviewDataOptions,
    IReviewDataScenes,
    IWorker,
    IWorkerData,
} from './types'
import { trackButtonClick } from 'util/snowplow'

interface IMarker {
    markerClass: string
    precedence: number
    text: string
    time: number
    type: string
}

interface IQAReviewAuthorityCtrl {
    hideVideo: boolean
    sceneData: IReviewDataScenes
    authorityRecords: IAuthorityData[]
    workerAuthorities: IWorkerData[]
    questions: Question[]
    taskOptions: IReviewDataOptions
    taskVideo: IVideoData
    workers: IWorker[]
    bookmarks: number[]
    markers: IMarker[]
    videoApi: VideoAPIInstance
    commentThreads: any

    submitAuthorityTask: () => void
    findAuthority: (
        authorities: IAuthorityData[],
        authority: IAuthorityData
    ) => IAuthorityData | undefined
    copyToFinal: (authority: IAuthorityData, wokrerData: IWorkerData) => void
    returnToWorkers: () => void
    openComments: () => void
}

export default /* @ngInject */ function QAReviewAuthorityCtrl(
    this: unknown,
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    $http: ng.IHttpService,
    $scope: ng.IScope,
    $state: ng.ui.IStateService,
    MapDialog: any,
    Notification: NotificationServiceInstance,
    CommentInterface: CommentInterfaceInstance,
    AuthorityManager: AuthorityManagerInstance,
    QAAssignmentService: QAAssignmentServiceInstance,
    MarkerTransformation: any,
    QAReturnToWorkers: any,
    id: string,
    reviewData: IReviewData,
    videoData: IVideoData,
    commentThreads: any
) {
    const URL = '/api/hit/qa'
    const qaAuthorityVm = this as IQAReviewAuthorityCtrl

    qaAuthorityVm.submitAuthorityTask = submitAuthorityTask
    qaAuthorityVm.findAuthority = findAuthority

    qaAuthorityVm.openComments = openComments

    qaAuthorityVm.sceneData = reviewData.scenes
    qaAuthorityVm.commentThreads = commentThreads

    qaAuthorityVm.copyToFinal = copyToFinal
    qaAuthorityVm.returnToWorkers = returnToWorkers

    activate()

    //////////////////////////

    function activate() {
        qaAuthorityVm.hideVideo = false

        qaAuthorityVm.authorityRecords = reviewData.authority
        qaAuthorityVm.workerAuthorities = reviewData.workerAuthorities

        qaAuthorityVm.questions = reviewData.questions

        $setupComments()

        $scope.$on('authority.attributes.changed', saveAttributeChanges)

        qaAuthorityVm.taskOptions = reviewData.options
        qaAuthorityVm.taskVideo = videoData
        qaAuthorityVm.workers = reviewData.workerList
    }

    function $setupComments() {
        qaAuthorityVm.commentThreads = commentThreads
        qaAuthorityVm.bookmarks = reviewData.bookmarks

        $scope.$watchCollection('qaAuthorityVm.bookmarks', () => {
            qaAuthorityVm.markers = MarkerTransformation.bookmarks(qaAuthorityVm.bookmarks)
        })

        CommentInterface.init({
            hitID: id,
            threads: qaAuthorityVm.commentThreads,
            hasOnlyGeneralThread: true,
        })

        $scope.$onRootScope('map3.addBookmark', addBookmark)
        $scope.$onRootScope('map3.deleteBookmark', deleteBookmark)
        $scope.$on('$destroy', CommentInterface.destroy)
    }

    function addBookmark() {
        const timestamp = qaAuthorityVm.videoApi.getCurrentFrameTime()
        qaAuthorityVm.bookmarks.push(timestamp)

        QAAssignmentService.setBookmarks(id, qaAuthorityVm.bookmarks)
    }

    function deleteBookmark(marker: any) {
        const index = qaAuthorityVm.bookmarks.indexOf(marker.time)
        qaAuthorityVm.bookmarks.splice(index, 1)

        QAAssignmentService.setBookmarks(id, qaAuthorityVm.bookmarks)
    }

    function submitAuthorityTask() {
        const authorityValidation = AuthorityManager.validateAuthorities(
            qaAuthorityVm.authorityRecords,
            reviewData.questions
        )

        if (authorityValidation.errors.length) {
            const firstError = _.head(authorityValidation.errors)

            $scope.$broadcast('authority.highlight', firstError.authority)
            Notification.error(firstError.message)
        } else if (authorityValidation.warnings.length) {
            const warningModal = MapDialog.confirm()
                .title('Warning! Do you want to continue?')
                .htmlContent(
                    `
                    <strong>The following warnings were generated:</strong>
                    <ul>
                        ${_.map(
                            authorityValidation.warnings,
                            (warning) => `<li>${warning.message}</li>`
                        ).join('')}
                    </ul>
                `
                )
                .ok('Submit')
                .cancel('Go Back')
            MapDialog.show(warningModal).then(submitAuthorities)
        } else {
            const submitConfig = MapDialog.confirm()
                .title('Submit task')
                .htmlContent(
                    `
                    <strong>Are you sure you want to proceed?</strong>
                    <p>When you submit your changes will be stored and you won't be able to edit them anymore.</p>
                `
                )
                .ok('Yes')
                .cancel('No')

            MapDialog.show(submitConfig).then(submitAuthorities)
        }
    }

    function submitAuthorities() {
        trackButtonClick({
            label: 'Submit Task',
            componentName: 'Review Authority Task Submit',
            value: [
                {
                    metadata: {
                        hitId: id,
                    },
                },
            ],
        })

        Notification.forPromise(
            $http.post(`${URL}/${id}/approve`, {}).then(() => {
                $state.go('qa.list')
            }),
            'Authorities successfully submitted'
        )
    }

    function saveAttributeChanges() {
        Notification.forPromise(
            $http.post(
                `${URL}/${id}/authorities`,
                { authorities: angular.toJson(qaAuthorityVm.authorityRecords) },
                NO_LOAD_OVERLAY
            ),
            'Changes successfully saved'
        )
    }

    function openComments() {
        if (CommentInterface.getGeneralThread()) {
            CommentInterface.showGeneralThreadModal()
        } else {
            CommentInterface.showCreateGeneralThreadModal()
        }
    }

    function findAuthority(authorities: IAuthorityData[], authority: IAuthorityData) {
        return _.find(authorities, ['value.uri', authority.value.uri])
    }

    function copyToFinal(authority: IAuthorityData, wokrerData: IWorkerData) {
        const matchingWorkerAuthority = _.find(wokrerData.authorities, [
            'value.uri',
            authority.value.uri,
        ])
        const attributesToBeCopied = _.get(matchingWorkerAuthority, 'attributes', [])

        if (!_.isEmpty(authority.attributes)) {
            MapDialog.show(
                MapDialog.confirm({
                    title: 'Warning!',
                    textContent: `Do you want to overwrite the current attributes for "${authority.value.label}?"`,
                })
            ).then(executeCopy)
        } else {
            executeCopy()
        }

        function executeCopy() {
            $scope.$broadcast('authority.forceEditAttributes', authority, attributesToBeCopied)
        }
    }

    function returnToWorkers() {
        QAReturnToWorkers.return(id, qaAuthorityVm.workers)
    }
}

QAReviewAuthorityCtrl.resolve = {
    id: /* @ngInject */ function ($stateParams: ng.ui.IStateParamsService) {
        return $stateParams.id
    },

    reviewData: /* @ngInject */ function (QAAnnotationTree: QAAnnotationTreeInstance, id: string) {
        return QAAnnotationTree.getReviewData(id)
    },

    videoData: /* @ngInject */ function (VideoService: any, id: string) {
        return VideoService.get(id)
    },

    commentThreads: /* @ngInject */ function (CommentService: any, id: string) {
        return CommentService.getThreads(id) || []
    },
}
