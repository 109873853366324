import _ from 'lodash'

export default /* @ngInject */ function highlightErrorsDirective($timeout) {
    var directive = {
        restrict: 'A',
        require: '^form',
        link: highlightErrorsLinkFn,
    }

    return directive

    ////////////////////////

    function highlightErrorsLinkFn(scope, element, attr, ngFormCtrl) {
        // This timeout is necessary to handle the case when we have
        // ng generated code inside of the highlight-errors element.

        // We want to allow interpolation of child elements, so we just
        // offset the attachment of the watchers until the browser renders
        // the next frame, by using a $timeout(func, 0, false)
        let promise = $timeout(
            function () {
                // we search for .form-control where the name attriubte is set
                var childInputs = element[0].querySelectorAll('.form-control[name]')

                // and add watchers for all elements
                _.each(childInputs, function (childInput) {
                    var childInputName = childInput && childInput.getAttribute('name')

                    if (!childInputName || angular.isUndefined(ngFormCtrl[childInputName])) {
                        // if we cannot find the expected element, throw an error and
                        // halt compilation
                        throw new Error(`highlightErrors directive requires that you have
                        a child ".form-control" element with a "name" attribute.`)
                    }

                    scope.$watch(
                        function () {
                            // on scope changes, check if the input's validation status has changed.
                            // additionally, if an input has not been touched or the form has not been
                            // yet submitted, consider the element valid
                            var hasFormError =
                                ngFormCtrl[childInputName] &&
                                ngFormCtrl[childInputName].$invalid &&
                                (ngFormCtrl[childInputName].$touched ||
                                    ngFormCtrl[childInputName].$dirty ||
                                    ngFormCtrl.$submitted)

                            var hasModelError = false
                            if (attr.highlightErrors) {
                                hasModelError = !!scope.$eval(
                                    `${attr.highlightErrors}.errors["${childInputName}"].length`
                                )
                            }

                            return hasFormError || hasModelError
                        },
                        function (hasError) {
                            element.toggleClass('has-error', hasError)
                        }
                    )
                })
            },
            0,
            false
        )

        scope.$on('$destroy', () => $timeout.cancel(promise))
    }
}
