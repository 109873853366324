import angular from 'angular'

const resizableImageComponent = {
    controller: resizableImageController,
    bindings: {
        image: '<',
    },
    template: `
        <img ng-style="$ctrl.imageIsVisible" ng-src="{{ $ctrl.image.url }}" />
    `,
}

export default resizableImageComponent

/* @ngInject */ function resizableImageController(
    this: unknown,
    $scope: ng.IScope,
    $element: ng.IAugmentedJQuery
) {
    const $ctrl = this as ng.IComponentController & {
        imageIsVisible: { visibility: 'visible' | 'hidden' }
        $onInit: typeof $onInit
    }

    $scope.$watch(
        '$ctrl.image',
        () => {
            $ctrl.imageIsVisible = { visibility: 'hidden' }

            $element.addClass('image-loading')
        },
        true
    )

    $ctrl.$onInit = $onInit

    return $ctrl

    /////////////////////////////

    function $onInit() {
        $element.addClass('image-loading')

        const image = $element[0].firstElementChild as HTMLImageElement

        $scope.$onRootScope('angular-resizable.resizing', function (_event: any, info: any) {
            const containerWidth = $element.parent().width() || 0
            const aspect = image.naturalWidth / image.naturalHeight

            if (info.height * aspect < containerWidth) {
                if (info.height < 300) {
                    return
                }

                $element.css({
                    height: info.height + 'px',
                    width: info.height * aspect + 'px',
                    'max-width': containerWidth + 'px',
                })
            }
        })

        image.addEventListener('load', () => {
            $scope.$apply(() => {
                resizeImage()
                $element.removeClass('image-loading')
                $ctrl.imageIsVisible = { visibility: 'visible' }
            })
        })

        $scope.$on('$destroy', function () {
            angular.element(image).off('load')
        })

        /////////////////////////////

        function resizeImage() {
            const aspect = image.naturalWidth / image.naturalHeight
            const height = $element.height() || 0

            $element.css({
                width: height * aspect + 'px',
            })
        }
    }
}
