'use strict'

/**
 * Use softInvarian() to assert state which your program assumes to be true.
 *
 * The difference with normal invariant is that under prod environment,
 * this will only produce a New Relic error instead of a full exception
 */

const DEV_ENV = process.env.NODE_ENV === 'development'
const map3: TMap3Window = window

export default function softInvariant(condition: boolean, format: string, ...args: string[]) {
    if (DEV_ENV) {
        // eslint-disable-next-line
        if (format === undefined) {
            throw new Error('invariant requires an error message argument')
        }
    }

    if (!condition) {
        let error: any
        // eslint-disable-next-line
        if (format === undefined) {
            error = new Error(
                'Minified exception occurred; use the non-minified dev environment ' +
                    'for the full error message and additional helpful warnings.'
            )
        } else {
            let argIndex = 0
            error = new Error(
                'Invariant Violation: ' +
                    format.replace(/%s/g, function () {
                        return args[argIndex++]
                    })
            )
        }

        error.framesToPop = 1 // we don't care about invariant's own frame

        // throw the error for dev builds, but only report to New Relic for prod builds
        if (DEV_ENV) {
            throw error
        } else if (map3.newrelic) {
            map3.newrelic.noticeError(error)
        } else {
            console.error(error) // eslint-disable-line
        }
    }
}
