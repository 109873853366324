import { UserInstance } from 'auth/User.factory'

/**
 * @ngdoc service
 * @name UpdateNotifier
 * @module map3.core
 *
 * @description
 * Update Notifier Service
 *
 * A service to continuously check for new app versions and offer the user to reload the app.
 *
 * The UpdateNotifier works by continuously polling with `GET /revision-info.json`.
 *
 * The result of the polling request  must be an object with a `revision` field. If the
 * returned `revision` differs from the compiled script's `GIT_REVISION` constant, then
 * we assume that a new app version is available and offer the user to reload the app.
 *
 * ---
 *
 * On local development env, the modal will not be presented unless the `local storage`
 * variable `$$enableUpdateNotifications` is set to `true`.
 *
 * Use the following in the browser console to set it:
 * ```
 * localStorage.setItem('$$enableUpdateNotifications', true/false)
 * ```
 *
 * @property {boolean} requiresReload A property on {@link UpdateNotifier} that makes it
 * easy to inspect its current status. Useful inside templates, for example, to show
 * conditional text based on if we require application reload.
 */

interface IRevisionInfo {
    revision: string
    date: Date
    message: string
}

export default /* @ngInject */ function UpdateNotifierFactory(
    GIT_REVISION: string,
    ENVIRONMENT: TMap3Environment,
    $http: ng.IHttpService,
    $q: ng.IQService,
    $sanitize: ng.sanitize.ISanitizeService,
    $templateCache: ng.ITemplateCacheService,
    $filter: ng.IFilterService,
    User: UserInstance,
    MapDialog: any
) {
    const DEV_NOTIFICATIONS_FLAG = '$$enableUpdateNotifications'
    const VERSION_CHECK_INTERVAL = 60 // seconds
    const REVISION_INFO_URL = '/revision-info.json'

    const UpdateNotifier = {
        $$checkTimeout: null as ReturnType<typeof setTimeout> | null,
        $$activeRequest: null as ng.IPromise<void> | null,

        requiresReload: false,

        /**
         * @ngdoc method
         * @name UpdateNotifier#init
         *
         * @description
         * Called by `app.core.run()` to initialize the service.
         */
        init() {
            if (process.env.NODE_ENV === 'production') {
                UpdateNotifier.startActiveTracking()
            }
        },

        /**
         * @ngdoc method
         * @name UpdateNotifier#startActiveTracking
         *
         * @description
         * Start active update polling.
         * The revision info url will be polled every 60 seconds for updated version
         * information.
         *
         * When a new revision is detected, {@link UpdateNotifier#handleNewVersion} is
         * called and the active polling is stopped.
         */
        startActiveTracking() {
            if (UpdateNotifier.$$checkTimeout) {
                clearTimeout(UpdateNotifier.$$checkTimeout)
                UpdateNotifier.$$checkTimeout = null
            }

            UpdateNotifier.$$activeRequest = $http
                .get<IRevisionInfo>(REVISION_INFO_URL, {
                    params: { _t: +new Date() },
                    ignoreLoadingBar: true,
                })
                .then((res) => res.data)
                .then(checkRevisionInfo)
                .then(scheduleNextUpdateCheck)
                .catch(angular.noop)

            function checkRevisionInfo(revisionInfo: IRevisionInfo) {
                if (revisionInfo && revisionInfo.revision) {
                    if (GIT_REVISION !== revisionInfo.revision) {
                        UpdateNotifier.handleNewVersion(revisionInfo)

                        // prevent scheduling of further checks
                        return $q.reject()
                    }
                }
            }

            function scheduleNextUpdateCheck() {
                UpdateNotifier.$$checkTimeout = setTimeout(
                    UpdateNotifier.startActiveTracking,
                    VERSION_CHECK_INTERVAL * 1000
                )
            }
        },

        /**
         * @ngdoc method
         * @name UpdateNotifier#handleNewVersion
         *
         * @description
         * Called when a new revision has been detected from the backend
         *
         * Will display a modal asking the user to reload the app.
         *
         * *Note:* This function is a no-op on `local-development` environment unless you
         * have set the `local storage` variable `$$enableUpdateNotifications` to `true`.
         *
         * Use the following in the browser console to set it:
         * ```
         * localStorage.setItem('$$enableUpdateNotifications', true/false)
         * ```
         *
         * @param {Object} revisionInfo `{revision, date, message}` The revision info
         *      returned from the backend.
         */
        handleNewVersion(revisionInfo: IRevisionInfo) {
            // in development, only show notifications if we have manually enabled them in local storage
            if (
                ENVIRONMENT === 'local-development' &&
                'true' !== window.localStorage.getItem(DEV_NOTIFICATIONS_FLAG)
            ) {
                return
            }

            UpdateNotifier.requiresReload = true

            const confirmDialog = MapDialog.confirm()
                .title('New version available!')
                .htmlContent(UpdateNotifier.$generateModalMessage(revisionInfo))
                .cssClass('modal-reload-app')
                .ok('Close and reload later')
                .cancel('Reload now')

            MapDialog.show(confirmDialog).catch(UpdateNotifier.forceReload)
        },

        /**
         * @ngdoc method
         * @name UpdateNotifier#forceReload
         *
         * @description
         * A convinence method for `window.location.reload(true)`
         */
        forceReload() {
            $templateCache.removeAll()
            window.location.reload()
        },

        $generateModalMessage(revisionInfo: IRevisionInfo) {
            let message = [
                '<p>A new version of the MAP3 Platform has been made available.</p>',

                User.isAdmin()
                    ? ` <p>
                            <strong>Note:</strong> Workflows that have not been saved
                            as a template will be erased by this update.  If you are
                            creating a workflow, please either start a job, or save
                            it as template before reloading.
                        </p>`
                    : ` <p>
                            Annotations you have <em>saved</em> in any task will not
                            be affected by this reload.
                        </p>`,
            ].join('\n')

            // add development info to message
            if (ENVIRONMENT.indexOf('development') > -1) {
                message =
                    message +
                    `
                    <p class="well">
                        Revision info: <br>
                        <strong>${revisionInfo.revision}</strong><br>
                        ( ${$filter('date')(revisionInfo.date, 'MMMM d, y h:mm a')} )<br><br>
                        ${escapeCommitMessage(revisionInfo.message)}
                    </p>
                `
            }

            return message

            function escapeCommitMessage(commitMessage: string) {
                if (!commitMessage) {
                    return ''
                }

                return $sanitize((commitMessage + '').replace(/\n/g, '<br>'))
            }
        },
    }

    return UpdateNotifier
}

export type UpdateNotifierInstance = ReturnType<typeof UpdateNotifierFactory>
