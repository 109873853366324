export default class ChooseRoleCtrl {
    $state: ng.ui.IStateService
    userService: any
    routingAuthService: any
    /* @ngInject */
    constructor($state: ng.ui.IStateService, User: any, RoutingAuth: any) {
        this.$state = $state
        this.userService = User
        this.routingAuthService = RoutingAuth
        this.userService.setActiveRoles(false)
    }

    choose(role: string) {
        this.userService.setActiveRoles([role])
        const route =
            this.routingAuthService.getDefaultStateForRole(role) ||
            this.routingAuthService.getDefaultState()
        this.$state.go(route)
        const map3Window = window as TMap3Window
        if (map3Window.newrelic) {
            map3Window.newrelic.addPageAction(
                'user.chooseRole',
                this.userService.getUserFormattedForNewRelic()
            )
        }
    }

    hasRole(role: string) {
        return this.userService.hasRole(role)
    }

    getUserName() {
        const user = this.userService.cached()
        if (user.fullname) {
            return user.fullname
        }
        return ''
    }
}
