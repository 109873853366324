const OPTIONS = {
    scrollCss: {
        height: '20px',
    },
    contentCss: {
        'overflow-x': 'auto',
        'overflow-y': 'hidden',
    },
    scrollEventName: 'scroll.synthetic',
    scrollWrapper: '.synthetic-scroll',
    scrollIndicator: '.synthetic-scroll-scroll',
    scrollWrapperMarkup:
        '<div class="synthetic-scroll"><div class="synthetic-scroll-scroll"></div></div>',
    contentScroll: '.content-scroll',
    contentScrollMarkup: '<div class="content-scroll"></div>',
}

export default function syntheticScrollDirective() {
    const directive = {
        restrict: 'AE',
        link: syntheticScrollLinkFn,
    }

    return directive

    /////////////////////////////

    function syntheticScrollLinkFn(
        scope: ng.IScope,
        element: ng.IAugmentedJQuery,
        attrs: ng.IAttributes
    ) {
        const mutationObserver = new MutationObserver(() => {
            const target = element.find(attrs.syntheticScrollTarget || '>:first-child')

            if (target.length) {
                mutationObserver.disconnect()
                setupSyntheticScroll(target)
            }
        })
        mutationObserver.observe(element[0], {
            childList: true,
            subtree: true,
        })

        scope.$on('$destroy', () => {
            mutationObserver.disconnect()
        })

        /////////////////////////////////////////////////////////////

        function setupSyntheticScroll($content: ng.IAugmentedJQuery) {
            $content.before(OPTIONS.scrollWrapperMarkup).wrap(OPTIONS.contentScrollMarkup)

            const $contentScroll = $(OPTIONS.contentScroll).css(OPTIONS.contentCss)
            const $scrollIndicator = $(OPTIONS.scrollIndicator).css(OPTIONS.scrollCss)
            const $syntheticScroll = $(OPTIONS.scrollWrapper).css({
                ...OPTIONS.scrollCss,
                ...OPTIONS.contentCss,
            })

            $contentScroll.on(OPTIONS.scrollEventName, () => {
                if (shouldScroll()) {
                    scroll($syntheticScroll, $contentScroll)
                }
            })

            $syntheticScroll.on(OPTIONS.scrollEventName, () => {
                if (shouldScroll()) {
                    scroll($contentScroll, $syntheticScroll)
                }
            })

            const resizeObserver = new ResizeObserver(() => {
                $scrollIndicator.width($content.outerWidth() || 0)
            })
            resizeObserver.observe($content[0])

            let scrolling = false
            const scroll = (target: ng.IAugmentedJQuery, source: ng.IAugmentedJQuery) => {
                target.scrollLeft(source.scrollLeft() || 0)
            }
            const shouldScroll = () => {
                if (scrolling) {
                    return (scrolling = false)
                }
                return (scrolling = true)
            }

            scope.$on('$destroy', () => {
                $syntheticScroll.off(OPTIONS.scrollEventName).remove()
                $contentScroll.off(OPTIONS.scrollEventName)
                resizeObserver.unobserve($content[0])
                $content.unwrap(OPTIONS.contentScroll)
            })
        }
    }
}
