import _ from 'lodash'
import angular from 'angular'
import Immutable from 'immutable'
import ReduxUndo, { StateWithHistory } from 'redux-undo'
import ReduxActions from 'services/ReduxActions'
import { Action } from 'redux-actions'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { Segment } from 'services/scenes/SceneHelper.factory'

/**
 * @ngdoc object
 * @name SceneData
 * @module map3.scenes
 *
 * @description
 * An object is part of the {@link $ngRedux} state for the `sceneData` property.
 *
 * It is a full representation of the current `scenes` and `subScenes`, in
 * Immutable form.
 *
 * @property {Immutable.List} scenes
 * @property {Immutable.List} subScenes
 */

/**
 * @ngdoc object
 * @name SceneData.History
 * @module map3.scenes
 *
 * @description
 * A history-wrapped {@link SceneData} using [redux-undo](https://github.com/omnidan/redux-undo)
 * [History API](https://github.com/omnidan/redux-undo#history-api)
 *
 * @property {Array<SceneData>} past
 * @property {SceneData} present
 * @property {Array<SceneData>} future
 */

/**
 * @ngdoc function
 * @name UndoableScenesReducer
 * @module map3.scenes
 *
 * @description
 * A version of {@link ScenesReducer} that has [redux-undo](https://github.com/omnidan/redux-undo)
 * higher order reducer applied to it.
 *
 * It adda new history items only when the immutable scene data actually changes.
 */
export /* @ngInject */ function UndoableScenesReducerFactory(ScenesReducer: ScenesReducer) {
    const undoable = ReduxUndo(ScenesReducer, {
        ignoreInitialState: true,
        syncFilter: true,
        // only add an action to the undo history if we got an actual state difference
        filter: function (action, currentState, previousHistory) {
            // if this is the first dispatch, add it
            const { [previousHistory.past.length - 1]: lastState } = previousHistory.past
            if (!lastState) {
                return true
            }

            // do not put select actions in the undo history
            const { type } = action
            if (
                _.includes(
                    [
                        'ACTS_SELECT_BY_ID',
                        'ACTS_SELECT_BY_INDEX',
                        'ACTS_SELECT_BY_OFFSET',
                        'SCENES_SELECT_BY_ID',
                        'SCENES_SELECT_BY_INDEX',
                        'SCENES_SELECT_BY_OFFSET',
                        'SUB_SCENES_SELECT_BY_ID',
                        'SUB_SCENES_SELECT_BY_INDEX',
                        'SUB_SCENES_SELECT_BY_OFFSET',
                    ],
                    type
                )
            ) {
                return false
            }

            const {
                acts: currentActs,
                scenes: currentScenes,
                subScenes: currentSubScenes,
            } = currentState
            const { acts: pastActs, scenes: pastScenes, subScenes: pastSubScenes } = lastState

            // if any of the scene data properties was modified, add an undo item
            return !(
                Immutable.is(currentActs, pastActs) &&
                Immutable.is(currentScenes, pastScenes) &&
                Immutable.is(currentSubScenes, pastSubScenes)
            )
        },
    })

    return (state: StateWithHistory<SceneData>, action: Action<any>) => {
        switch (action.type) {
            // add a manual handling of zooming, outside of undoable past/present setup
            case 'SCENES_SET_ZOOM':
                return {
                    ...state,
                    zoom: action.payload,
                }

            // Allow raw replace of the whole state object with backend undoable scene data
            case 'SCENE_DATA_RAW_INIT':
                return {
                    ...state,
                    ...parseRawSceneData(action.payload),
                    _latestUnfiltered: null,
                }

            default:
                return {
                    ...state,
                    ...undoable(state, action),
                }
        }
    }

    function parseRawSceneData(rawSceneData: StateWithHistory<SceneData>) {
        return {
            ...rawSceneData,
            present: ScenesReducer.ScenesParser(rawSceneData.present),
            past: _.map(rawSceneData.past, ScenesReducer.ScenesParser),
            future: _.map(rawSceneData.future, ScenesReducer.ScenesParser),
        }
    }
}

type SceneData = {
    // this gives us a type enforcement that we always copy over extra SceneData props
    __type_defintion_to_ensure_extra_SceneData_props_are_not_lost: unknown
    acts: false | ImmutableSegments
    scenes: ImmutableSegments
    subScenes: ImmutableSegments
}
type SegmentImmutable = Immutable.Map<keyof Segment, Segment[keyof Segment]>
type ImmutableSegments = Immutable.List<SegmentImmutable>
type SegmentBackend = {
    id: number
    start: string
    end: string
}
type SceneDataBackend = {
    acts?: SegmentBackend[] | false
    scenes?: SegmentBackend[] | false
    subScenes?: SegmentBackend[] | false
}
type SelectionState = {
    $$isSelected: boolean
    $$wasSingleSelected?: boolean
}

/**
 * @ngdoc function
 * @name ScenesReducer
 * @module map3.scenes
 *
 * @description
 * A pure function reducer for [redux](https://github.com/reactjs/redux)
 * that handles scene state changes
 */
export /* @ngInject */ function ScenesReducerFactory() {
    const MINIMUM_SCENE_DURATION = 1

    const DEFAULT_NON_CANNON_DATA = { type: 'non-cannon' }
    const SCENE_DATA_SEGMENT_PROPS = ['acts', 'scenes', 'subScenes'] as const

    const ScenesActioner = ReduxActions.handleActions<SceneData, any>(
        {
            SCENE_DATA_INIT(sceneData, { payload: { videoApi, sceneData: newSceneData } }) {
                return actionReplaceSceneData(
                    sceneData,
                    actionInitSceneData(newSceneData, videoApi)
                )
            },

            SCENE_DATA_REPLACE(sceneData, { payload: newSceneData }) {
                return actionReplaceSceneData(sceneData, newSceneData)
            },

            ACTS_SPLIT_BY_VIDEO_CURRENT_TIME(sceneData, { payload: videoApi }) {
                if (!sceneData.acts) {
                    return sceneData
                }
                const actsResult = $splitScenes(sceneData.acts, videoApi)

                if (actsResult.wasSplit) {
                    // Deal with scenes and subscenes
                    // They must have their parentId updated in addition
                    // to being split at the same point that the acts were split
                    const scenesResult = $splitScenes(sceneData.scenes, videoApi)
                    const subScenesResult = $splitScenes(sceneData.subScenes, videoApi)

                    return {
                        ...sceneData,
                        acts: actsResult.scenes,
                        scenes: $updateSubsceneToSceneBindings(
                            scenesResult.scenes,
                            actsResult.scenes
                        ),
                        subScenes: $updateSubsceneToSceneBindings(
                            subScenesResult.scenes,
                            scenesResult.scenes
                        ),
                    }
                }

                return sceneData
            },

            SCENES_SPLIT_BY_VIDEO_CURRENT_TIME(sceneData, { payload: videoApi }) {
                const result = $splitScenes(sceneData.scenes, videoApi)

                if (result.wasSplit) {
                    // Deal with subscenes
                    // They must have their parentId updated in addition
                    // to being split at the same point that the scenes were split
                    const subScenesResult = $splitScenes(sceneData.subScenes, videoApi)

                    return {
                        ...sceneData,
                        scenes: result.scenes,
                        subScenes: $updateSubsceneToSceneBindings(
                            subScenesResult.scenes,
                            result.scenes
                        ),
                    }
                }

                return sceneData
            },

            SUB_SCENES_SPLIT_BY_VIDEO_CURRENT_TIME(sceneData, { payload: videoApi }) {
                const result = $splitScenes(sceneData.subScenes, videoApi)

                if (result.wasSplit) {
                    return { ...sceneData, subScenes: result.scenes }
                }

                return sceneData
            },

            ACTS_SELECT_BY_ID(sceneData, { payload: actIds }) {
                return {
                    ...sceneData,
                    acts: sceneData.acts && actionSelectSceneById(sceneData.acts, actIds),
                }
            },

            SCENES_SELECT_BY_ID(sceneData, { payload: sceneIds }) {
                // head(concat()) to deal with array of ids
                const scene = $getSceneById(sceneData.scenes, _.head(_.concat(sceneIds)))
                const act = sceneData.acts && $getSceneById(sceneData.acts, scene.get('parentId'))

                return {
                    ...sceneData,
                    acts:
                        act &&
                        sceneData.acts &&
                        actionSelectSceneById(sceneData.acts, act.get('id')),
                    scenes: actionSelectSceneById(sceneData.scenes, sceneIds),
                }
            },

            SUB_SCENES_SELECT_BY_ID(sceneData, { payload: subSceneIds }) {
                // head(concat()) to deal with array of ids
                const subScene = $getSceneById(sceneData.subScenes, _.head(_.concat(subSceneIds)))
                const scene = $getSceneById(sceneData.scenes, subScene.get('parentId'))
                const act = sceneData.acts && $getSceneById(sceneData.acts, scene.get('parentId'))

                return {
                    ...sceneData,
                    acts:
                        act &&
                        sceneData.acts &&
                        actionSelectSceneById(sceneData.acts, act.get('id')),
                    scenes: actionSelectSceneById(sceneData.scenes, scene.get('id')),
                    subScenes: actionSelectSceneById(sceneData.subScenes, subSceneIds),
                }
            },

            ACTS_SELECT_BY_INDEX(sceneData, { payload: index }) {
                return {
                    ...sceneData,
                    acts: sceneData.acts && actionSelectSceneOnIndex(sceneData.acts, index),
                }
            },

            SCENES_SELECT_BY_INDEX(sceneData, { payload: index }) {
                return { ...sceneData, scenes: actionSelectSceneOnIndex(sceneData.scenes, index) }
            },

            SUB_SCENES_SELECT_BY_INDEX(sceneData, { payload: index }) {
                return {
                    ...sceneData,
                    subScenes: actionSelectSceneOnIndex(sceneData.subScenes, index),
                }
            },

            ACTS_SELECT_BY_OFFSET(sceneData, { payload: offset }) {
                return {
                    ...sceneData,
                    acts: sceneData.acts && actionSelectSceneOffset(sceneData.acts, offset),
                }
            },

            SCENES_SELECT_BY_OFFSET(sceneData, { payload: offset }) {
                return { ...sceneData, scenes: actionSelectSceneOffset(sceneData.scenes, offset) }
            },

            SUB_SCENES_SELECT_BY_OFFSET(sceneData, { payload: offset }) {
                return {
                    ...sceneData,
                    subScenes: actionSelectSceneOffset(sceneData.subScenes, offset),
                }
            },

            ACTS_MERGE_SELECTED(sceneData) {
                if (!sceneData.acts) {
                    return sceneData
                }

                const actsResult = actionMergeSelectedScenes(sceneData.acts)
                if (actsResult.wasMerged) {
                    return {
                        ...sceneData,
                        acts: actsResult.scenes,
                        scenes: $updateSubsceneToSceneBindings(sceneData.scenes, actsResult.scenes),
                        subScenes: $updateSubsceneToSceneBindings(
                            sceneData.subScenes,
                            sceneData.scenes
                        ),
                    }
                }

                return sceneData
            },

            SCENES_MERGE_SELECTED(sceneData) {
                const result = actionMergeSelectedScenes(sceneData.scenes)
                if (result.wasMerged) {
                    return {
                        ...sceneData,
                        scenes: result.scenes,
                        subScenes: $updateSubsceneToSceneBindings(
                            sceneData.subScenes,
                            result.scenes
                        ),
                    }
                }

                return sceneData
            },

            SUB_SCENES_MERGE_SELECTED(sceneData) {
                return {
                    ...sceneData,
                    subScenes: actionMergeSelectedScenes(sceneData.subScenes).scenes,
                }
            },

            ACTS_MOVE_START(sceneData, { payload: { id, offsetFrames } }) {
                return {
                    ...sceneData,
                    acts:
                        sceneData.acts &&
                        actionMoveSceneStartFrame(sceneData.acts, id, offsetFrames),
                }
            },

            ACTS_MOVE_SELECTED_START(sceneData, { payload: offsetFrames }) {
                return {
                    ...sceneData,
                    acts:
                        sceneData.acts &&
                        actionMoveSelectedSceneStartFrame(sceneData.acts, offsetFrames),
                }
            },

            SCENES_MOVE_START(sceneData, { payload: { id, offsetFrames } }) {
                return {
                    ...sceneData,
                    scenes: actionMoveSceneStartFrame(sceneData.scenes, id, offsetFrames),
                }
            },

            SCENES_MOVE_SELECTED_START(sceneData, { payload: offsetFrames }) {
                return {
                    ...sceneData,
                    scenes: actionMoveSelectedSceneStartFrame(sceneData.scenes, offsetFrames),
                }
            },

            SUB_SCENES_MOVE_START(sceneData, { payload: { id, offsetFrames } }) {
                return {
                    ...sceneData,
                    subScenes: actionMoveSceneStartFrame(sceneData.subScenes, id, offsetFrames),
                }
            },

            SUB_SCENES_MOVE_SELECTED_START(sceneData, { payload: offsetFrames }) {
                return {
                    ...sceneData,
                    subScenes: actionMoveSelectedSceneStartFrame(sceneData.subScenes, offsetFrames),
                }
            },

            ACTS_MOVE_END(sceneData, { payload: { id, offsetFrames } }) {
                return {
                    ...sceneData,
                    acts:
                        sceneData.acts && actionMoveSceneEndFrame(sceneData.acts, id, offsetFrames),
                }
            },

            ACTS_MOVE_SELECTED_END(sceneData, { payload: offsetFrames }) {
                return {
                    ...sceneData,
                    acts:
                        sceneData.acts &&
                        actionMoveSelectedSceneEndFrame(sceneData.acts, offsetFrames),
                }
            },

            SCENES_MOVE_END(sceneData, { payload: { id, offsetFrames } }) {
                return {
                    ...sceneData,
                    scenes: actionMoveSceneEndFrame(sceneData.scenes, id, offsetFrames),
                }
            },

            SCENES_MOVE_SELECTED_END(sceneData, { payload: offsetFrames }) {
                return {
                    ...sceneData,
                    scenes: actionMoveSelectedSceneEndFrame(sceneData.scenes, offsetFrames),
                }
            },

            SUB_SCENES_MOVE_END(sceneData, { payload: { id, offsetFrames } }) {
                return {
                    ...sceneData,
                    subScenes: actionMoveSceneEndFrame(sceneData.subScenes, id, offsetFrames),
                }
            },

            SUB_SCENES_MOVE_SELECTED_END(sceneData, { payload: offsetFrames }) {
                return {
                    ...sceneData,
                    subScenes: actionMoveSelectedSceneEndFrame(sceneData.subScenes, offsetFrames),
                }
            },

            ACTS_TOGGLE_MARK_SELECTED_AS_NON_CANNON(sceneData) {
                if (!sceneData.acts) return { ...sceneData }

                const result = actionActsToggleMarkSelectedAsNonCannon(
                    sceneData.acts,
                    sceneData.scenes,
                    sceneData.subScenes
                )

                return {
                    ...sceneData,
                    ...result,
                }
            },

            SCENES_TOGGLE_MARK_SELECTED_AS_NON_CANNON(sceneData) {
                const scenes = actionToggleMarkSelectedAsNonCannon(sceneData.scenes)
                const subScenes = actionToggleSubScenes(sceneData.subScenes, scenes)

                if (sceneData.acts) {
                    const acts = toggleParentFromSelection(
                        sceneData.acts,
                        $getScenesSelected(scenes)
                    )
                    return {
                        ...sceneData,
                        acts,
                        scenes,
                        subScenes,
                    }
                } else {
                    return {
                        ...sceneData,
                        scenes,
                        subScenes,
                    }
                }
            },

            SUB_SCENES_TOGGLE_MARK_SELECTED_AS_NON_CANNON(sceneData) {
                const subScenes = actionToggleMarkSelectedAsNonCannon(sceneData.subScenes)
                const selected = $getScenesSelected(subScenes)

                const scenes = toggleParentFromSelection(sceneData.scenes, selected)

                if (sceneData.acts) {
                    const acts = toggleParentFromSelection(sceneData.acts, selected)
                    return {
                        ...sceneData,
                        acts,
                        scenes,
                        subScenes,
                    }
                } else {
                    return {
                        ...sceneData,
                        scenes,
                        subScenes,
                    }
                }
            },
        },
        {} as any
    )
    function toggleParentFromSelection(
        scenes: ImmutableSegments,
        selection: ImmutableSegments
    ): ImmutableSegments {
        const toggleValue = selection.first().get('nonCannonSegment', false)
        const start = selection.first().get('startFrame')
        const end = selection.last().get('endFrame')

        const toggledScenes = scenes.map((segment) => {
            if (!$$isSelected(segment!)) {
                return segment
            }

            if (segment?.get('startFrame') >= start && segment?.get('endFrame') <= end) {
                if (toggleValue) {
                    return segment!.set('nonCannonSegment', angular.copy(DEFAULT_NON_CANNON_DATA))
                } else {
                    return segment!.set('nonCannonSegment', false)
                }
            } else {
                return segment
            }
        })

        return toggledScenes as ImmutableSegments
    }

    ScenesReducer.DEFAULT_NON_CANNON_DATA = DEFAULT_NON_CANNON_DATA

    ScenesReducer.ScenesParser = ScenesParser

    function ScenesReducer(sceneData: SceneData | undefined, action: Action<any>): SceneData {
        const parsed = ScenesParser(sceneData)
        const actioned = (action && ScenesActioner(parsed, action)) || parsed

        // if the segments have not changed, we do not need to run the costly normalization step
        if (unchangedSegments(sceneData, actioned)) {
            return actioned
        }

        const normalized = ScenesNormalizer(actioned)

        return normalized

        /////////////////////////////

        function unchangedSegments(
            originalSceneData: SceneData | undefined,
            newSceneData: SceneData
        ) {
            return (
                originalSceneData &&
                newSceneData &&
                _.every(SCENE_DATA_SEGMENT_PROPS, (prop) => {
                    return (
                        Immutable.is(originalSceneData[prop], newSceneData[prop]) ||
                        originalSceneData[prop] === newSceneData[prop]
                    )
                })
            )
        }
    }

    return ScenesReducer

    //////////////////////////////

    function ScenesParser(sceneData: any = {}): SceneData {
        const isImmutable =
            !_.isEmpty(sceneData) &&
            _.every(_.pick(sceneData, SCENE_DATA_SEGMENT_PROPS), (segmentHolder) => {
                return Immutable.List.isList(segmentHolder) || segmentHolder === false
            })

        if (isImmutable) {
            return sceneData
        }

        let acts = false
        if (sceneData.acts) {
            acts = Immutable.fromJS(sceneData.acts)
        }

        const scenes = Immutable.fromJS(sceneData.scenes || [])

        const subScenes = Immutable.fromJS(sceneData.subScenes || [])

        return { ...sceneData, acts, scenes, subScenes }
    }

    function ScenesNormalizer(sceneData: any = {}): SceneData {
        let acts: false | ImmutableSegments = false
        if (sceneData.acts && sceneData.acts.size) {
            acts = $normalizeScenes(sceneData.acts, { type: 'act' })
        }

        let scenes = sceneData.scenes
        // if we have acts, use parent nomralization act->scene
        if (acts && scenes) {
            scenes = $normalizeSubScenes(scenes, acts)
        }
        scenes = $normalizeScenes(scenes, { type: 'scene' })

        const subScenes = $normalizeScenes($normalizeSubScenes(sceneData.subScenes, scenes), {
            type: 'subScene',
        })

        return { ...sceneData, acts, scenes, subScenes }
    }

    function actionInitSceneData(sceneData: any, videoApi: VideoAPIInstance) {
        // ensure that we only have the expected scene data keys
        sceneData = _.pick(sceneData, SCENE_DATA_SEGMENT_PROPS)

        // treat empty segment data (ie, empty array) as disabled
        sceneData = _.mapValues(sceneData, (segments) =>
            segments && segments.length ? segments : false
        )

        // convert string start/end to numbers, because we can't rely on backend -_-
        sceneData = _.mapValues(
            sceneData,
            (segments) => segments && _.map(segments, (scene) => convertStartEndToNum(scene))
        )

        // filter out invalid segments
        sceneData = _.mapValues(
            sceneData,
            (segments) => segments && _.filter(segments, startIsValid)
        )

        // sort by start
        sceneData = _.mapValues(sceneData, (segments) => segments && _.sortBy(segments, 'start'))

        // convert sec to frame
        sceneData = _.mapValues(
            sceneData,
            (segments) => segments && _.map(segments, convertSecToFrame)
        )

        // fill in missing data with non-cannon segments
        sceneData = _.mapValues(
            sceneData,
            (segments) => segments && fillInMissingWithNonCannon(segments)
        )

        // fix last segment duration, if needed
        sceneData = _.mapValues(
            sceneData,
            (segments) => segments && fixLastSegmentDurationTooLong(segments)
        )

        // setIDs if missing
        sceneData = _.mapValues(sceneData, (segments) => segments && ensureIds(segments))

        // Previously, only subscenes had parents, and the field was named `parentSceneId`.
        // Now that scenes can also have parents, the field was renamed to `parentId`.
        // On init, fix possible legacy data.
        sceneData.subScenes = _.map(sceneData.subScenes, (subScene) => {
            if (_.has(subScene, 'parentSceneId')) {
                subScene.parentId = subScene.parentSceneId
                delete subScene.parentSceneId
            }

            return subScene
        })

        // match scenes to acts (no full normalization, that is handled later)
        sceneData.scenes = matchSubscenesToScenes(sceneData.scenes, sceneData.acts)

        // match subscenes to scenes (no full normalization, that is handled later)
        sceneData.subScenes = matchSubscenesToScenes(sceneData.subScenes, sceneData.scenes)

        return sceneData

        /////////////////////////////////

        function convertStartEndToNum(segment: SegmentBackend) {
            return _.assign(segment, {
                start: parseFloat(segment.start),
                end: parseFloat(segment.end),
            })
        }
        function startIsValid(segment: SegmentBackend) {
            return +segment.start < videoApi.getDuration()
        }

        function fixLastSegmentDurationTooLong(segments: Segment[]) {
            const segment = segments[segments.length - 1]
            const lastFrame = videoApi.convertLaxSecondToFrame(videoApi.getDuration())
            if (segment && segment.endFrame !== lastFrame) {
                segment.endFrame = lastFrame
            }

            return segments
        }

        function convertSecToFrame(segment: Segment | SegmentBackend): Segment {
            const { startFrame, endFrame } = segment as Segment
            if (!startFrame && !endFrame) {
                const { start, end } = segment as SegmentBackend
                return {
                    ..._.omit(segment, ['start', 'end']),
                    startFrame: videoApi.convertLaxSecondToFrame(start),
                    endFrame: videoApi.convertLaxSecondToFrame(end),
                } as Segment
            }

            return segment as Segment
        }

        function fillInMissingWithNonCannon(segments: Segment[]) {
            let lastEndFrame = -1
            let segment
            let index = 0
            while ((segment = segments[index])) {
                // if there is more than one frame difference between the last end frame and
                // the current segment, we insert a non-cannon segment to fill the void
                if (lastEndFrame + 1 < segment.startFrame) {
                    const startFrame = lastEndFrame + 1
                    const endFrame = segment.startFrame - 1

                    const newSegment = {
                        startFrame,
                        endFrame,
                        nonCannonSegment: angular.copy(DEFAULT_NON_CANNON_DATA),
                    }

                    segments.splice(index, 0, newSegment as any)

                    index += 2
                } else {
                    index += 1
                }

                lastEndFrame = segment.endFrame
            }

            // finally, we check if there is time between the last segment and the video end,
            // and if there is, we fill it with a non-cannon segment
            if (lastEndFrame + 1 < videoApi.convertLaxSecondToFrame(videoApi.getDuration())) {
                const startFrame = lastEndFrame + 1
                const endFrame = videoApi.convertLaxSecondToFrame(videoApi.getDuration())

                const newSegment = {
                    startFrame,
                    endFrame,
                    nonCannonSegment: angular.copy(DEFAULT_NON_CANNON_DATA),
                }

                segments.push(newSegment as any)
            }

            return segments
        }

        function ensureIds(segments: Segment) {
            let uid = _.max(_.map(segments, 'id')) || 0

            return _.map(segments, (segment) => _.assign(segment, { id: segment.id || ++uid }))
        }

        function matchSubscenesToScenes(subScenes: Segment[] = [], scenes: Segment[] = []) {
            return _.map(subScenes, function (subScene) {
                if (!subScene.parentId) {
                    const matchingParentScene = _.find(scenes, function (scene) {
                        return (
                            subScene.startFrame >= scene.startFrame &&
                            subScene.endFrame <= scene.endFrame
                        )
                    })

                    if (matchingParentScene) {
                        subScene.parentId = matchingParentScene.id
                    }
                }

                return subScene
            })
        }
    }

    function actionReplaceSceneData(sceneData: any, newSceneData: SceneDataBackend): SceneData {
        return {
            ...sceneData,
            acts: newSceneData.acts ? Immutable.fromJS(newSceneData.acts) : newSceneData.acts,
            scenes: newSceneData.scenes
                ? Immutable.fromJS(newSceneData.scenes)
                : newSceneData.scenes,
            subScenes: newSceneData.subScenes
                ? Immutable.fromJS(newSceneData.subScenes)
                : newSceneData.subScenes,
        }
    }

    function $splitScenes(scenes: ImmutableSegments, videoApi: VideoAPIInstance) {
        const rightSceneStartFrame = videoApi.getCurrentFrame()
        const leftSceneEndFrame = rightSceneStartFrame - 1

        // The original scene will be split into `leftScene` and
        // `rightScene`, denoting their position in relation to the
        // split time. Both scenes will get new unique ids.
        const originalScene = $getSceneByFrame(scenes, rightSceneStartFrame)

        if (!originalScene) {
            return { scenes, wasSplit: false }
        }

        const leftScene = originalScene.merge({
            id: $getCurrentMaxId(scenes) + 1,
            endFrame: leftSceneEndFrame,
        }) as SegmentImmutable

        let rightScene = Immutable.Map({
            id: $getCurrentMaxId(scenes) + 2,
            startFrame: rightSceneStartFrame,
            endFrame: originalScene.get('endFrame'),
        }) as SegmentImmutable

        if ($hasParent(originalScene)) {
            rightScene = rightScene.set('parentId', originalScene.get('parentId'))
        }

        // do not create a split if either scene will be too short
        if (
            !$hasParent(rightScene) &&
            ($getSceneDurationFrames(rightScene) < MINIMUM_SCENE_DURATION ||
                $getSceneDurationFrames(leftScene) < MINIMUM_SCENE_DURATION)
        ) {
            return { scenes, wasSplit: false }
        }

        // insert the new scenes in the place of the original scene
        scenes = scenes.splice(
            scenes.indexOf(originalScene),
            1,
            leftScene,
            rightScene
        ) as ImmutableSegments

        // this will update all scenes objects
        scenes = actionSelectSceneOnIndex(scenes, scenes.indexOf(rightScene))

        return {
            scenes,
            wasSplit: true,
            // so we need to manually reselect the left/right scene
            // after actionSelectSceneOnIndex() has executed
            leftScene: $getSceneById(scenes, leftScene.get('id')),
            rightScene: $getSceneById(scenes, rightScene.get('id')),
            rightSceneStartFrame,
        }
    }

    function actionSelectSceneById(
        scenes: ImmutableSegments,
        ids: number | number[]
    ): ImmutableSegments {
        if (!_.isArray(ids)) {
            ids = [ids]
        }
        const indexes = scenes
            .filter((scene) => !!scene && _.includes(ids as number[], scene.get('id')))
            .map((scene) => scenes.keyOf(scene!))
            .toArray()

        return actionSelectSceneOnIndex(scenes, indexes)
    }

    function actionSelectSceneOnIndex(
        scenes: ImmutableSegments,
        indexes: number | number[]
    ): ImmutableSegments {
        if (!_.isArray(indexes)) {
            indexes = [indexes]
        }

        indexes = _.uniq(indexes)

        const selectedState: SelectionState = {
            $$isSelected: true,
        }
        if (indexes.length === 1) {
            selectedState.$$wasSingleSelected = true
        }

        return actionDeselectAllScenes(
            scenes,
            /* removeSingleSelect */ indexes.length === 1
        ).withMutations(function (scenes) {
            _.forEach(indexes as number[], function (index) {
                if (scenes.has(index)) {
                    scenes.set(index, scenes.get(index).merge(selectedState) as SegmentImmutable)
                }
            })
        })
    }

    function actionSelectSceneOffset(scenes: ImmutableSegments, offset: number) {
        const selected = $getSceneSingleSelected(scenes)
        if (selected) {
            return actionSelectSceneOnIndex(
                scenes,
                (scenes.indexOf(selected) + offset) % scenes.size
            )
        }

        return scenes
    }

    function actionDeselectAllScenes(
        scenes: ImmutableSegments,
        removeSingleSelect = true
    ): ImmutableSegments {
        const deselectedState: SelectionState = {
            $$isSelected: false,
        }

        if (removeSingleSelect !== false) {
            deselectedState.$$wasSingleSelected = false
        }

        return scenes.map((scene) => scene!.merge(deselectedState)) as ImmutableSegments
    }

    function actionMergeSelectedScenes(scenes: ImmutableSegments): {
        scenes: ImmutableSegments
        wasMerged: boolean
        mergedScenes?: ImmutableSegments
    } {
        const selected = $getScenesSelected(scenes)

        if (selected.size <= 1) {
            return { scenes, wasMerged: false, mergedScenes: undefined }
        }

        // update start scene
        const startScene = selected.minBy((scene) => scene!.get('startFrame'))
        const endScene = selected.maxBy((scene) => scene!.get('endFrame'))
        const startSceneIndex = scenes.indexOf(startScene)
        const endSceneIndex = scenes.indexOf(endScene)

        let mergedScene = Immutable.Map({
            id: $getCurrentMaxId(scenes) + 1,
            startFrame: startScene.get('startFrame'),
            endFrame: endScene.get('endFrame'),
        })

        if ($hasParent(startScene)) {
            mergedScene = mergedScene.set('parentId', startScene.get('parentId'))
        }

        // replace selected scenes with the merged scene
        scenes = scenes.splice(
            startSceneIndex,
            endSceneIndex - startSceneIndex + 1,
            mergedScene
        ) as ImmutableSegments
        // make sure we have the new merged scene selected
        scenes = actionSelectSceneOnIndex(scenes, startSceneIndex)

        return {
            scenes,
            wasMerged: true,
            mergedScenes: selected,
        }
    }

    function actionMoveSelectedSceneStartFrame(
        scenes: ImmutableSegments,
        offsetFrames: number
    ): ImmutableSegments {
        const scene = $getSceneSingleSelected(scenes)

        if (scene) {
            return actionMoveSceneStartFrame(scenes, scene.get('id'), offsetFrames)
        }

        return scenes
    }

    function actionMoveSceneStartFrame(
        scenes: ImmutableSegments,
        id: number,
        offsetFrames: number
    ): ImmutableSegments {
        const scene = $getSceneById(scenes, id)
        const index = scenes.keyOf(scene)
        // don't allow start move on first scene
        if (index > 0) {
            const updatedScene = scene.merge({
                startFrame: scene.get('startFrame') + offsetFrames,
            })

            const prevScene = scenes.get(index - 1)
            // we have to move the endFrame of the previous scene
            const updatedPrevScene = prevScene.merge({
                endFrame: prevScene.get('endFrame') + offsetFrames,
            })

            return scenes.splice(index - 1, 2, updatedPrevScene, updatedScene) as ImmutableSegments
        }

        return scenes
    }

    function actionMoveSelectedSceneEndFrame(
        scenes: ImmutableSegments,
        offsetFrames: number
    ): ImmutableSegments {
        const scene = $getSceneSingleSelected(scenes)

        if (scene) {
            return actionMoveSceneEndFrame(scenes, scene.get('id'), offsetFrames)
        }

        return scenes
    }

    function actionMoveSceneEndFrame(
        scenes: ImmutableSegments,
        id: number,
        offsetFrames: number
    ): ImmutableSegments {
        const scene = $getSceneById(scenes, id)
        const index = scenes.keyOf(scene)
        // don't allow start move on first scene
        if (index < scenes.size - 1) {
            const nextScene = scenes.get(index + 1)
            // we have to move the beginning of the next scene
            const updatedNextScene = nextScene.merge({
                startFrame: nextScene.get('startFrame') + offsetFrames,
            })

            const updatedScene = scene.merge({
                endFrame: scene.get('endFrame') + offsetFrames,
            })

            return scenes.splice(index, 2, updatedScene, updatedNextScene) as ImmutableSegments
        }

        return scenes
    }

    function actionToggleMarkSelectedAsNonCannon(segments: ImmutableSegments): ImmutableSegments {
        const selected = $getScenesSelected(segments)
        if (selected.size === 0) {
            return selected
        }

        // we toggle based on the first element of the selection
        const toggleValue = !selected.first().get('nonCannonSegment', false)

        return segments.map((segment) => {
            if (!$$isSelected(segment!)) {
                return segment
            }

            if (toggleValue) {
                return segment!.set('nonCannonSegment', angular.copy(DEFAULT_NON_CANNON_DATA))
            } else {
                return segment!.set('nonCannonSegment', false)
            }
        }) as ImmutableSegments
    }

    function actionActsToggleMarkSelectedAsNonCannon(
        acts: ImmutableSegments,
        scenes: ImmutableSegments,
        subScenes: ImmutableSegments
    ): { acts: ImmutableSegments; scenes: ImmutableSegments; subScenes: ImmutableSegments } {
        const selected = $getScenesSelected(acts)
        if (selected.size === 0) {
            return { acts, scenes, subScenes }
        }

        // we toggle based on the first element of the selection
        const toggleValue = !selected.first().get('nonCannonSegment', false)

        const toggledActs = acts.map((segment) => {
            if (!$$isSelected(segment!)) {
                return segment
            }

            if (toggleValue) {
                return segment!.set('nonCannonSegment', angular.copy(DEFAULT_NON_CANNON_DATA))
            } else {
                return segment!.set('nonCannonSegment', false)
            }
        }) as ImmutableSegments

        const toggled = toggleByProvided(selected, scenes, subScenes, toggleValue)

        return { acts: toggledActs, scenes: toggled.scenes, subScenes: toggled.subScenes }
    }

    function toggleByProvided(
        selected: ImmutableSegments,
        scenes: ImmutableSegments,
        subScenes: ImmutableSegments,
        toggleValue: boolean
    ): { scenes: ImmutableSegments; subScenes: ImmutableSegments } {
        const selectedParentIds = selected.map((segment) => segment!.get('id')).toArray()
        const scenesWithSelectedParents = scenes.filter((scenes) =>
            _.includes(selectedParentIds, scenes!.get('parentId'))
        )
        const scenesIds = scenesWithSelectedParents.map((segment) => segment!.get('id')).toArray()

        const toggledScenes = scenes.map((segment) => {
            if (!_.includes(selectedParentIds, segment!.get('parentId'))) {
                return segment
            }

            if (toggleValue) {
                return segment!.set('nonCannonSegment', angular.copy(DEFAULT_NON_CANNON_DATA))
            } else {
                return segment!.set('nonCannonSegment', false)
            }
        }) as ImmutableSegments

        const toggledsubSubScenes = subScenes.map((segment) => {
            if (!_.includes(scenesIds, segment!.get('parentId'))) {
                return segment
            }

            if (toggleValue) {
                return segment!.set('nonCannonSegment', angular.copy(DEFAULT_NON_CANNON_DATA))
            } else {
                return segment!.set('nonCannonSegment', false)
            }
        }) as ImmutableSegments

        return { scenes: toggledScenes, subScenes: toggledsubSubScenes }
    }

    function actionToggleSubScenes(
        segments: ImmutableSegments,
        parent: ImmutableSegments
    ): ImmutableSegments {
        const selectedParent = $getScenesSelected(parent)
        const parentIds = selectedParent.map((scene) => scene!.get('id')).toArray()

        const selected = $getScenesSelected(segments)
        if (selected.size === 0) {
            return selected
        }

        const toggleValue = selectedParent.first().get('nonCannonSegment', false)

        return segments.map((segment) => {
            // console.table(segments.map((segment) => segment!.get('parentId'), parentIds))`

            if (_.includes(parentIds, segment!.get('parentId'))) {
                if (toggleValue) {
                    // const sceneIds = scenes.map((scene) => scene!.get('id')).toArray()

                    return segment!.set('nonCannonSegment', angular.copy(DEFAULT_NON_CANNON_DATA))
                } else {
                    return segment!.set('nonCannonSegment', false)
                }
            } else {
                return segment
            }
        }) as ImmutableSegments
    }

    ///////////////////////////////////////////////////////

    function $normalizeScenes(scenes: ImmutableSegments, opts: { type?: string } = {}) {
        scenes = scenes.map((scene) =>
            scene!.withMutations((scene) => {
                // make sure no negative duration is present
                scene.set('startFrame', Math.max(0, scene.get('startFrame', 0)))
                scene.set('endFrame', Math.max(0, scene.get('endFrame', 0)))

                // set the type
                scene.set('type', _.get(opts, 'type', 'scene'))

                // set initial non-cannon-segment value to false
                scene.set('nonCannonSegment', scene.get('nonCannonSegment', false))
            })
        ) as ImmutableSegments

        // sort scenes by start time
        scenes = scenes.sortBy((scene) => scene!.get('startFrame')) as ImmutableSegments

        // make sure that scene start/ends are always 1 frame apart
        let lastSceneStartFrame: number
        scenes = scenes
            .reverse()
            .map(function (scene) {
                return scene!.withMutations(function (scene) {
                    if (lastSceneStartFrame) {
                        scene.set('endFrame', lastSceneStartFrame - 1)
                    }

                    lastSceneStartFrame = scene.get('startFrame')
                })
            })
            .reverse() as ImmutableSegments

        // remove zero-duration scenes
        scenes = scenes.filter((scene) => $getSceneDurationFrames(scene!) > 0) as ImmutableSegments

        scenes = $ensureHasIds(scenes)

        // start with the first scene selected
        if (!$getScenesSelected(scenes).size && scenes.size > 0) {
            scenes = actionSelectSceneOnIndex(scenes, 0)
        }

        return scenes
    }

    function $normalizeSubScenes(
        subScenes: ImmutableSegments,
        scenes: ImmutableSegments
    ): ImmutableSegments {
        const sceneIds = scenes.map((scene) => scene!.get('id')).toArray()
        // remove subScenes pointing to non-existent scenes
        subScenes = subScenes.filter((subScene) =>
            _.includes(sceneIds, subScene!.get('parentId'))
        ) as ImmutableSegments

        // trim subscene length to parent scene length
        subScenes = subScenes.withMutations((subScenes) => {
            scenes.forEach((scene) => {
                const matchingSubScenes = subScenes.filter(
                    $matches({
                        parentId: scene!.get('id'),
                    })
                )

                matchingSubScenes.forEach((subScene) => {
                    const isFirst =
                        matchingSubScenes.size && matchingSubScenes.first().equals(subScene!)
                    const isLast =
                        matchingSubScenes.size && matchingSubScenes.last().equals(subScene!)
                    const index = subScenes.indexOf(subScene!)

                    subScenes.update(index, (subScene) => {
                        return subScene.withMutations((subScene) => {
                            if (isFirst || subScene.get('startFrame') < scene!.get('startFrame')) {
                                subScene.set('startFrame', scene!.get('startFrame'))
                            }
                            if (isLast || subScene.get('endFrame') > scene!.get('endFrame')) {
                                subScene.set('endFrame', scene!.get('endFrame'))
                            }

                            // copy over non-cannon data from parent
                            const parentIsNonCannon = scene!.get('nonCannonSegment')
                            if (parentIsNonCannon) {
                                subScene.set(
                                    'nonCannonSegment',
                                    angular.copy(DEFAULT_NON_CANNON_DATA)
                                )
                            } else {
                                subScene.set(
                                    'nonCannonSegment',
                                    subScene!.get('nonCannonSegment', false)
                                )
                            }
                        })
                    })
                })
            })
        })

        // remove zero-duration scenes
        subScenes = subScenes.filter(
            (subScene) => $getSceneDurationFrames(subScene!) > 0
        ) as ImmutableSegments

        // add missing subScenes for new scenes
        const subSceneParentIds = subScenes.map((subScene) => subScene!.get('parentId')).toArray()
        const missingSceneIds = _.difference(sceneIds, subSceneParentIds)
        subScenes = subScenes.concat(
            _.map(missingSceneIds, (sceneId) => {
                const scene = $getSceneById(scenes, sceneId)

                return Immutable.Map({
                    parentId: sceneId,
                    startFrame: scene.get('startFrame'),
                    endFrame: scene.get('endFrame'),
                })
            })
        ) as ImmutableSegments

        // make sure selected subscenes are always a subset of current selected scene
        const selectedScenes = $getScenesSelected(scenes)
        if (selectedScenes.size === 1) {
            const selectedSceneId = selectedScenes.first().get('id')
            subScenes = subScenes.map((subScene) => {
                if (subScene!.get('parentId') !== selectedSceneId) {
                    return subScene!.set('$$isSelected', false)
                } else {
                    return subScene
                }
            }) as ImmutableSegments

            const selectedSubScenes = $getScenesSelected(subScenes)
            if (selectedSubScenes.size === 0) {
                const targetSubScene = subScenes
                    .filter($matches({ parentId: selectedSceneId }))
                    .sortBy((subScene) => subScene!.get('startFrame'))
                    .first()

                subScenes = subScenes.set(
                    subScenes.indexOf(targetSubScene),
                    targetSubScene.set('$$isSelected', true).set('$$wasSingleSelected', true)
                )
            }
        }

        subScenes = $ensureHasIds(subScenes)

        return subScenes
    }

    function $updateSubsceneToSceneBindings(
        subScenes: ImmutableSegments,
        scenes: ImmutableSegments
    ): ImmutableSegments {
        return subScenes.map((subScene) => {
            const scene = $getSceneByFrame(scenes, subScene!.get('startFrame'))
            return subScene!.set('parentId', scene.get('id'))
        }) as ImmutableSegments
    }

    function $hasParent(scene: SegmentImmutable) {
        return scene.has('parentId')
    }

    function $ensureHasIds(scenes: ImmutableSegments): ImmutableSegments {
        // ensure unique Ids
        let uid = $getCurrentMaxId(scenes)

        return scenes.map((scene) => {
            if (!scene!.has('id')) {
                scene = scene!.set('id', ++uid)
            }

            return scene
        }) as ImmutableSegments
    }

    function $getCurrentMaxId(scenes: ImmutableSegments): number {
        const currentMaxUid = scenes.map((scene) => scene!.get('id', 0)).max()

        return currentMaxUid || 0
    }

    function $getSceneByFrame(scenes: ImmutableSegments, frame: number): SegmentImmutable {
        return scenes.find((scene) => {
            return _.inRange(frame, scene!.get('startFrame'), 1 + scene!.get('endFrame'))
        })
    }

    function $getSceneById(scenes: ImmutableSegments, id: number): SegmentImmutable {
        return scenes.find((scene) => scene!.get('id') === id)
    }

    function $getSceneDurationFrames(scene: SegmentImmutable): number {
        const duration = scene.get('endFrame') - scene.get('startFrame')

        return _.isFinite(duration) ? duration : 0
    }

    function $getScenesSelected(scenes: ImmutableSegments): ImmutableSegments {
        return scenes.filter($matches({ $$isSelected: true })) as ImmutableSegments
    }

    function $getSceneSingleSelected(scenes: ImmutableSegments): SegmentImmutable | null {
        const selected = $getScenesSelected(scenes)
        return selected.size === 1 ? selected.get(0) : null
    }

    function $$isSelected(segment: SegmentImmutable): boolean {
        return segment.get('$$isSelected', false)
    }

    /**
     * Provide a shorthand method that mimics $matches() for Immutable objects
     *
     * https://lodash.com/docs/4.17.4#matches
     */
    function $matches(source: unknown) {
        const baseMatcher = _.matches(source)

        return function matcher(target: unknown) {
            if (Immutable.Map.isMap(target)) {
                target = (target as any).toJS()
            }

            return baseMatcher(target)
        }
    }
}

type ScenesReducer = ReturnType<typeof ScenesReducerFactory>
