const showFullS3Path = {
    controller: showFullS3PathController,
    bindings: {
        config: '<',
    },
    template: `
        <div class="form-group">
            <label class="control-label">Resulting S3 Path and Scanned Extensions</label>  
            <pre ng-bind="$ctrl.fullPath"></pre>
        </div>
    `,
}

type Config = {
    bucket: string
    prefix: string
    path: string | undefined
    fileTypes: string | null
}

export default showFullS3Path

interface ScopeBindings extends ng.IComponentController {
    config: Config
}

/* @ngInject */
function showFullS3PathController(this: unknown, $scope: ng.IScope) {
    const $ctrl = this as ScopeBindings

    $ctrl.$onInit = $onInit

    return $ctrl

    //////////////////////////

    function $onInit() {
        $scope.$watch('$ctrl.config', createPath)
    }

    function createPath() {
        const { bucket, prefix, path = '', fileTypes = '' } = $ctrl.config
        if (!bucket) {
            $ctrl.fullPath = 'You need to select an S3 Input first'
            return
        }

        function trim_slash(input: string) {
            return input.replace(/^\/+|\/+$/g, '')
        }
        function trim_slash_left(input: string) {
            return input.replace(/^\/+/g, '')
        }

        $ctrl.fullPath =
            's3://' +
            bucket +
            '/' +
            trim_slash_left(trim_slash(prefix) + '/' + trim_slash_left(path)) +
            fileTypes
    }
}
