import _ from 'lodash'
/**
 * @ngdoc directive
 * @name formInput
 * @module map3.core
 * @restrict E
 * @scope
 *
 * @description
 * `<form-input>` provides a simplified interface for creating styled form inputs.
 *
 * Instead of having to write out the full form input implementation, you can
 * use `<form-input>` to get a well-behaved input field, with common error messsages
 * already attached and all the rendering goodies we need.
 *
 * Error messages from `app/js/messages/common-error-messages.tpl.html` will be
 * automatically applied to the form field.
 *
 * You can use special elements inside a `<form-input>`
 *
 *  - `<help-message>` will be attached as help text below the input element
 *  - `<error-message for="errorName">` Can be used to override the default error
 *      message for a specific error, or add an new error message.
 *
 *
 * @param {string} model The model object for this form field.
 *      Model objects are used to read backend errors from if set
 * @param {string} field The model field that this field represents
 * @param {string} label The `<label>` for the input. We automatically set its `for` attribute.
 *
 * @example
    <example>
        <file name="form-input.html">
            <form name="testFrom">
                <form-input model="user" field="email" label="EmailAddress">
                    <input type="email"
                        ng-model="user.email"
                        ng-min="5"
                    />

                    <help-message>Please choose your new email.</help-message>
                    <error-message for="min">You must enter a longer email address.</error-message>
                </form-input>
            </form>
        </file>
    </example>
 */
export default /* @ngInject */ function formInputDirective($compile) {
    var directive = {
        restrict: 'E',
        require: '^form',
        scope: true,
        transclude: true,
        template: `
            <div class="form-group chosen-holder" ng-class="{ required: $isRequired }"
                highlight-errors="{{ $modelObject }}"
            >
                <label ng-if="::$label !== 'false'" class="control-label">{{ $label }}</label>

                <form-control></form-control>

                <help-messages
                    model="{{ $modelObject }}" field="{{ $fieldName }}"
                ></help-messages>
            </div>
        `,
        link: formInputLinkFn,
    }

    return directive

    /////////////////////////////

    function formInputLinkFn(scope, element, attr, ngFormCtrl, transcludeFn) {
        if (!attr.field) {
            throw new Error(
                'formInput directive requires a valid field attribute that exists on the parent form'
            )
        } else {
            scope.$fieldName = attr.field
        }

        if (!attr.label) {
            throw new Error('formInput directive requires a label attribute')
        } else {
            scope.$label = attr.label
        }

        scope.$modelObject = attr.model

        var formControlHolder = element.find('form-control')
        var helpMessagesHolder = element.find('help-messages')
        var errorMessagesHolder = helpMessagesHolder.find('[ng-messages]')

        // transclude the form input el
        transcludeFn(scope, function (contents) {
            let errorMessageNodes = []
            angular.forEach(contents, function (node) {
                if (isErrorMessage(node)) {
                    errorMessageNodes.push(node)
                } else if (isHelpMessage(node)) {
                    helpMessagesHolder.append(node)
                } else {
                    if (isControl(node)) {
                        let $node = angular.element(node)
                        $node.addClass('form-control')

                        if ($node.prop('required')) {
                            scope.$isRequired = true
                        }
                    }

                    formControlHolder.append(node)
                }
            })

            compileErrorMessages(errorMessageNodes, errorMessagesHolder, scope)
        })

        var formLabel = element.find('.control-label')
        formLabel.toggleClass('no-float', !!attr.noFloat)

        let timeoutId = setTimeout(function () {
            let formControl = formControlHolder.find(':input')
            scope.$watch(
                function () {
                    return formControl.prop('required')
                },
                function (isRequired) {
                    scope.$isRequired = isRequired
                }
            )
        }, 0)

        scope.$on('$destroy', () => clearTimeout(timeoutId))
    }

    function isHelpMessage(node) {
        return node.tagName && node.tagName.toLowerCase() === 'help-message'
    }

    function isErrorMessage(node) {
        return node.tagName && node.tagName.toLowerCase() === 'error-message'
    }

    function isControl(node) {
        return (
            node.tagName &&
            (node.tagName.toLowerCase() === 'input' ||
                node.tagName.toLowerCase() === 'select' ||
                node.tagName.toLowerCase() === 'textarea')
        )
    }

    function compileErrorMessages(errorMessageNodes, errorMessagesHolder, scope) {
        const html = _.reduce(
            errorMessageNodes,
            function (html, node) {
                const ngMessageAttr = node.getAttribute('for')
                const contents = node.innerHTML
                const nodeHtml = `<span class="help-block" ng-message="${ngMessageAttr}">${contents}</span>`
                return html + nodeHtml
            },
            ''
        )

        if (html !== '') {
            $compile(html)(scope, function (compiledHtml) {
                errorMessagesHolder.prepend(compiledHtml)
                // remove the original error message nodes
                _.forEach(errorMessageNodes, (node) => node.remove())
            })
        }
    }
}
