import { ReportFormat } from 'constants.es6'

export default /* @ngInject */ function ResultsModuleFactory(
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    $q: ng.IQService,
    $http: ng.IHttpService
) {
    const ResultsModule = {
        getResultsList() {
            // for when you need LIGHTSPEED
            // return import('sample/reviewerMwdList.json').then(m => m.default)

            const URL = '/api/reviewer/mwd/list'
            return $http
                .get(URL, NO_LOAD_OVERLAY)
                .then((res) => res.data)
                .catch((error) => $q.reject(error))
        },

        /**
         * @param {number} videoId Video id
         * @param {string} source The data source to use, one of `scar`, `mwd`
         *
         * @return {Promise}
         */
        getTaskDetails(videoId: number) {
            const URL = `/api/reviewer/mwd/video/${videoId}`
            return $http.get(URL).then((res) => res.data)
        },

        /**
         * Verify the export config on the backend
         *
         * @param {object} exportConfigData
         * @param {=Promise} abortPromise
         */
        exportCheckConfig(exportConfigData: TExportConfigData, abortPromise?: ng.IPromise<any>) {
            return $http
                .post<TExportAvailability>('/api/admin/export/check', exportConfigData, {
                    ...NO_LOAD_OVERLAY,
                    timeout: abortPromise,
                })
                .then((res) => res.data)
        },

        /**
         * Export data from the backend, opening a file download dialog
         *
         * @param {object} exportConfigData
         */
        exportStart(exportConfigData: TExportConfigData) {
            return $http
                .post('/api/admin/export/data', exportConfigData, { ...NO_LOAD_OVERLAY })
                .then((res) => res.data)
        },

        exportMissingSongs(format: ReportFormat) {
            return $http
                .get(`/api/admin/export/missing-songs?exportFormat=${format}`, {
                    ...NO_LOAD_OVERLAY,
                })
                .then((res) => res.data)
        },

        exportGet(exportToken: string) {
            return $http
                .get(`/api/admin/export/get/${exportToken}`, { ...NO_LOAD_OVERLAY })
                .then((res) => res.data)
        },

        getVideoProjectsWithScenes(videoIds: number[]) {
            return $http
                .post('/api/admin/video/scenes', { ids: videoIds }, NO_LOAD_OVERLAY)
                .then((res) => res.data)
        },
    }

    return ResultsModule
}

export type ResultsModuleInstance = ReturnType<typeof ResultsModuleFactory>
