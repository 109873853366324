export enum QAAssignmentClass {
    joinQuestions = 'joinQuestions',
    tagScenes = 'tagScenes',
    cutntag = 'cutntag',
    authorityTagging = 'authorityTagging',
    marker = 'marker',
    markerReview = 'markerReview',
}

export interface IQAAssignment {
    id: string
    class: QAAssignmentClass
    [key: string]: any
}

export interface IQAAssigmentForReviewFailed {
    hitID: string
}

export interface IQAAssigmentForReview {
    success: IQAAssignment[]
    failed: IQAAssigmentForReviewFailed[]
}

interface IAuthorityValue {
    label: string
    uri: string
}

export interface IAuthorityData {
    attributes: any[]
    details: any[]
    label: string
    uri: string
    value: IAuthorityValue
}

export interface IWorker {
    displayName: string
    type: string
    username: string
}

export interface IWorkerData {
    authorities: IAuthorityData[]
    user: IWorker
}

export interface IReviewDataOptions {
    require_viewing: boolean
    short_description: string
    use_timestamp: boolean
}

interface IScene {
    id: number
    start: number
    end: number
    startFrame: number
    endFrame: number
    parentId: number
    nonCannonSegment: any
}

export interface IReviewDataScenes {
    scenes: IScene[]
    subScenes: IScene[]
}

export interface IReviewData {
    authority: IAuthorityData[]
    bookmarks: number[]
    hitID: string
    options: IReviewDataOptions
    questions: Question[]
    review_class: string
    short_description: string
    scenes: IReviewDataScenes
    workerAuthorities: IWorkerData[]
    workerList: IWorker[]
}
