import _ from 'lodash'
import fp from 'lodash/fp'
import GraphSearch from 'directives/graph/GraphSearch'
import extractId from 'util/extractId'

export default /* @ngInject */ function mwdTaskSelectionDirective(AdminModule) {
    var directive = {
        restrict: 'E',
        templateUrl: 'js/admin/workflow-wizard/directives/mwdTaskSelection.tpl.html',
        link: mwdTaskSelectionLinkFn,
        scope: {
            isEditable: '<',
            node: '<',
            graph: '<',
            workflow: '<',
            taskConfig: '=',
        },
    }

    return directive

    /////////////////////////////////////

    function mwdTaskSelectionLinkFn(scope) {
        const MWD_WORKFLOW_TASK_EQUAL_MAP = {
            project: 'project_id',
            owner: 'group_id',
        }

        scope.isTaskMeetingRequirements = isTaskMeetingRequirements

        activate()

        ////////////////////////////////////

        function activate() {
            if (scope.isEditable) {
                loadTaskData()
            } else {
                scope.haveMandatoryData = true
            }
        }

        function loadTaskData() {
            scope.tasksLoading = true

            const videoConfigs = getVideoConfigs()
            scope.haveMandatoryData = !_.isEmpty(videoConfigs)

            if (!scope.haveMandatoryData) {
                return
            }

            AdminModule.getTasksForVideoConfigs(videoConfigs)
                .then((tasks) => {
                    // save old video ids
                    const oldVideoIDs = extractVideoIDs(scope.taskConfig.tasks || [])

                    // write new tasks to config
                    scope.taskConfig.tasks = tasks

                    // if the list of tasks has changed, we must invalidate the hit id selection
                    const newVideoIDs = extractVideoIDs(tasks)
                    if (!_.isEqual(oldVideoIDs, newVideoIDs)) {
                        // clear user selected tasks
                        scope.taskConfig.hitIDs = []
                    } else {
                        // we need to validate that stored hitIDs match current workflow
                        scope.taskConfig.hitIDs = _.filter(scope.taskConfig.hitIDs, (hitID) => {
                            const task = _.find(scope.taskConfig.tasks, { hit_id: hitID })
                            return task && isTaskMeetingRequirements(task)
                        })
                    }
                })
                .catch(() => {
                    scope.error = true
                })
                .finally(() => {
                    scope.tasksLoading = false
                })
        }

        function isTaskMeetingRequirements(task) {
            return _.every(MWD_WORKFLOW_TASK_EQUAL_MAP, (taskKey, workflowKey) => {
                const workflowValue = extractId(_.get(scope.workflow, workflowKey))
                const taskValue = extractId(_.get(task, taskKey))

                return workflowValue === taskValue
            })
        }

        function getVideoConfigs() {
            const nodes = GraphSearch.searchAllUpstream(
                scope.graph,
                scope.node,
                { info: { wizards: { template: 'GetVideos' } } },
                /* maxDepth */ 1
            )

            return _.map(nodes, 'info.config')
        }

        function extractVideoIDs(tasks) {
            return fp
                .flow(
                    fp.map((task) => task.video_id),
                    fp.uniq
                )(tasks)
                .sort()
        }
    }
}
