import { trackButtonClick } from 'util/snowplow'
/**
 * @ngdoc directive
 * @name snowplow
 * @module map3.core
 * @description
 * Track link clicks with snowplow
 *
 * Use this to send user events from navigation and tab inside task list pages
 *
 */
export default /* @ngInject */ function snowplowTrackDirective() {
    const directive = {
        link: snowplowTrackLinkFn,
    }

    return directive

    ////////////////////

    function snowplowTrackLinkFn(
        scope: ng.IScope,
        element: ng.IAugmentedJQuery,
        attrs: ng.IAttributes
    ) {
        element.on('click', '.nav-link', trackWithSnowplow)

        scope.$on('$destroy', () => {
            element.off('click', '.nav-link', trackWithSnowplow)
        })

        const section = attrs.snowplowTrack

        function trackWithSnowplow(this: JQuery) {
            const $element = $(this)
            const label = $element.find('.js-label-for-snowplow').text() || $element.text()
            const componentType = $element.prop('role') || 'link'
            const href = $element.prop('href')

            trackButtonClick(
                {
                    label,
                    componentType,
                    value: [
                        {
                            metadata: {
                                href,
                                section,
                            },
                        },
                    ],
                },
                'link'
            )
        }
    }
}
