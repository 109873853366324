import _ from 'lodash'

/**
 * @ngdoc directive
 * @name zoomLevel
 * @module map3.scenes
 * @restrict E
 * @scope
 *
 * @description
 * Zoom level control widget
 *
 * @param {number} zoom
 */
export default /* @ngInject */ function zoomLevelDirective($timeout, $ngRedux, DOMUtility) {
    const PSEUDO_SELECTORS = [
        '::-webkit-slider-runnable-track',
        '::-moz-range-track',
        '::-ms-track',
    ]

    const directive = {
        restrict: 'E',
        scope: {
            step: '@?',
        },
        template: `
            <input id="{{ ::zoomId }}"
                ng-model="zoom"
                type="range"
                class="w-100 m-0 zoom-level js-zoom-level"
                min="0" max="100" step="{{ ::stepValue }}"
            />
        `,
        link: zoomLevelLinkFn,
    }

    return directive

    ///////////////////////////////

    function zoomLevelLinkFn(scope, element, attribute) {
        const styleCtrl = DOMUtility.addCustomStyles()
        scope.$on('$destroy', styleCtrl.remove)

        const stepValue = Number(scope.step)
        scope.stepValue = !isNaN(stepValue) ? stepValue : 0.1

        activate()

        //////////////////////

        function activate() {
            const unsubscribe = $ngRedux.connect((state) => {
                const zoom = _.get(state, 'sceneData.zoom', 0)

                return { zoom }
            })(scope)
            scope.$on('$destroy', unsubscribe)

            setZoomId()
            setupZoomRendering()

            scope.$watch('zoom', updateRangeBackground)
            scope.$watch('zoom', dispatchZoom)

            // set initial value
            scope.zoom = 0
        }

        function setZoomId() {
            scope.zoomId = 'zoomLevel_' + DOMUtility.nextUid()
        }

        function setupZoomRendering() {
            const range = element.find('input.js-zoom-level')
            range.on('input change', function () {
                updateRangeBackground(range.val())
            })
        }

        function dispatchZoom(value) {
            $ngRedux.dispatch({
                type: 'SCENES_SET_ZOOM',
                payload: value,
            })
        }

        function updateRangeBackground(value) {
            value = value || 0

            const selectors = _.map(PSEUDO_SELECTORS, function (pseudoSelector) {
                return '#' + scope.zoomId + pseudoSelector
            })

            const css = _.map(selectors, function (selector) {
                return `${selector} { background-size: ${value}% 100% !important; }`
            })

            styleCtrl.update(css)
        }
    }
}
