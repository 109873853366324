import _ from 'lodash'

export default /* @ngInject */ function chosenCallbacksDirective($parse) {
    let directive = {
        restrict: 'A',
        link: chosenCallbacksLinkFn,
    }

    return directive

    /////////////////////////////////////

    function chosenCallbacksLinkFn(scope, element, attr) {
        const EVENTS = {
            change: 'onChosenChange',
            // Triggered after Chosen has been fully instantiated.
            'chosen:ready': 'onChosenReady',
            // Triggered if max_selected_options is set and that total is broken.
            'chosen:maxselected': 'onChosenMaxSelectedLimitReached',
            // Triggered when Chosen's dropdown is opened.
            'chosen:showing_dropdown': 'onChosenShowingDropdown',
            // Triggered when Chosen's dropdown is closed.
            'chosen:hiding_dropdown': 'onChosenHidingDropdown',
            // Triggered when a search returns no matching results.
            'chosen:no_results': 'onChosenNoResult',
        }

        activate()

        ////////////////////////////////////////////////////

        function activate() {
            _.forEach(EVENTS, function (attrName, eventName) {
                if (Object.prototype.hasOwnProperty.call(attr, attrName)) {
                    element.on(eventName, (e) => {
                        scope.$applyAsync(function () {
                            $parse(attr[attrName])(scope, { $event: e })
                        })
                    })

                    scope.$on('$destroy', () => element.off(eventName))
                }
            })
        }
    }
}
