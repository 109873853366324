import _ from 'lodash'
import FastdomWrapper from 'services/FastdomWrapper'
import { ActionCreators } from 'redux-undo'
import ngRedux from 'ng-redux'
import {
    GlobalShortcutsInstance,
    ShortcutDefinition,
} from 'global-shortcuts/GlobalShortcuts.factory'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'

/**
 * @ngdoc directive
 * @name sceneSplitting
 * @module map3.scenes
 * @restrict E
 * @scope
 *
 * @description
 * The SceneSplitting is a holder for all sub-scene directives, timelines and the like.
 */
export default /* @ngInject */ function sceneSplittingDirective(
    $ngRedux: ngRedux.INgRedux,
    GlobalShortcuts: GlobalShortcutsInstance,
    SharedVideoAPI: { onLoad: (callback: (videoApi: VideoAPIInstance) => void) => void },
    SceneActions: any,
    Notification: { error: (message: string) => void }
) {
    const directive = {
        restrict: 'E',
        scope: true,
        link: sceneSplittingLinkFn,
        controller: sceneSplittingCtrl,
    }

    return directive

    type Scope = ng.IScope & {
        videoApi?: VideoAPIInstance
        zoom?: number
        $editMode: boolean
        $interactiveMode: boolean
        shots?: Array<{
            id: number
            start: number
            end: number
        }>
    }

    ///////////////////////

    /* @ngInject */
    function sceneSplittingCtrl(this: unknown, $scope: Scope) {
        const $ctrl = this as {
            getEditMode: () => boolean
            getInteractiveMode: () => boolean
        }

        $ctrl.getEditMode = function () {
            return $scope.$editMode
        }

        $ctrl.getInteractiveMode = function () {
            return $scope.$interactiveMode
        }
    }

    function sceneSplittingLinkFn(scope: Scope, element: JQLite, attrs: ng.IAttributes) {
        const MOUSESCROLL_ZOOM_SCALING = 0.1
        const fastdom = new FastdomWrapper()

        scope.$editMode = !!scope.$eval(attrs.editMode)
        scope.$interactiveMode = scope.$eval(attrs.interactiveMode) !== false

        activate()

        //////////////////////////////////////////

        function activate() {
            if (attrs.shots) {
                scope.$watch(attrs.shots, (shots: any) => (scope.shots = shots))
            }

            SharedVideoAPI.onLoad(function (videoApi) {
                scope.videoApi = videoApi
                setupShortcuts()
                setupMousewheelZoom()
            })

            const unsubscribe = $ngRedux.connect((state) => {
                const zoom = _.get(state, 'sceneData.zoom', 0)
                return { zoom }
            })(scope)
            scope.$on('$destroy', unsubscribe)

            const timeoutId = setTimeout(setupScrollSync, 0)
            scope.$on('$destroy', () => clearTimeout(timeoutId))
        }

        /**
         * Timelines inside scene splitting must be synchronized.
         *
         * To do that, we watch for scroll events on any timeline element,
         * and then propagate to other non-matching timelines
         */
        function setupScrollSync() {
            let isScrolling = false
            const timelines = element.find('.timeline, .generic-timeline')

            timelines.on('scroll', matchScrolls)
            scope.$on('$destroy', () => {
                timelines.off('scroll', matchScrolls)
                fastdom.destroy()
            })

            ///////////////////////////////

            function matchScrolls(event: JQuery.ScrollEvent) {
                if (isScrolling) {
                    return
                }

                isScrolling = true

                fastdom.measure(() => {
                    isScrolling = false

                    const allCondensed = _.every(timelines.get(), (timeline) => {
                        return $(timeline).hasClass('condensed')
                    })

                    const scolledTimeline = event.target
                    const $target = $(event.target)
                    const newScrollLeft = $target.scrollLeft()

                    timelines.each(function () {
                        const innerTimeline = this

                        // skip mismatched render type timelines
                        if (
                            !allCondensed &&
                            $(innerTimeline).hasClass('condensed') !== $target.hasClass('condensed')
                        ) {
                            return
                        }

                        if (
                            innerTimeline !== scolledTimeline &&
                            newScrollLeft !== $(innerTimeline).scrollLeft()
                        ) {
                            fastdom.mutate(() => {
                                $(innerTimeline).scrollLeft(newScrollLeft!)
                            })
                        }
                    })
                })
            }
        }

        /**
         * Shortcuts section
         */
        function setupShortcuts() {
            const viewShortcuts: ShortcutDefinition[] = [
                {
                    description: 'Zoom In / Out',
                    shortcut: 'control+shift+MouseWheel',
                },
            ]

            let editShortcuts: ShortcutDefinition[] = [
                {
                    description: 'Undo',
                    global: true,
                    keyCombo: ['ctrl+z', 'meta+z'],
                    shortcut: 'control+Z',
                    callback: function () {
                        $ngRedux.dispatch(ActionCreators.undo())
                    },
                },
                {
                    description: 'Redo',
                    global: true,
                    keyCombo: ['ctrl+y', 'meta+y'],
                    shortcut: 'control+Y',
                    callback: function () {
                        $ngRedux.dispatch(ActionCreators.redo())
                    },
                },
                {
                    description: 'Toggle Mark Selected Act(s) as Non-Cannon',
                    keyCombo: 'shift+q',
                    shortcut: 'shift+Q',
                    callback: () => {
                        SceneActions.toggleMarkSelectedActsAsNonCannon()
                    },
                },
                {
                    description: 'Toggle Mark Selected Scene(s) as Non-Cannon',
                    keyCombo: 'q',
                    shortcut: 'Q',
                    callback: () => {
                        SceneActions.toggleMarkSelectedScenesAsNonCannon()
                    },
                },
                {
                    description: 'Toggle Mark Selected Sub Scene(s) as Non-Cannon',
                    keyCombo: 'ctrl+q',
                    shortcut: 'Ctrl+Q',
                    callback: () => {
                        SceneActions.toggleMarkSelectedSubScenesAsNonCannon()
                    },
                },
            ]

            if (scope.shots) {
                editShortcuts = editShortcuts.concat([
                    {
                        description: 'Next Shot',
                        keyCombo: 'ctrl+shift+]',
                        shortcut: 'Ctrl+shift+]',
                        callback: () => goToShotOffset(1),
                    },
                    {
                        description: 'Previous Shot',
                        keyCombo: 'ctrl+shift+[',
                        shortcut: 'Ctrl+shift+[',
                        callback: () => goToShotOffset(-1),
                    },
                ])
            }

            const shortcuts = viewShortcuts.concat(scope.$editMode ? editShortcuts : [])

            const unbind = GlobalShortcuts.bind({ title: 'Scene Splitting Shortcuts', shortcuts })
            scope.$on('$destroy', unbind)
        }

        function setupMousewheelZoom() {
            // need to set the event as an active listener to be able to do e.preventDefault()
            // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
            let passiveSupported = false
            try {
                const options = {
                    get passive() {
                        // This function will be called when the browser
                        //   attempts to access the passive property.
                        passiveSupported = true
                        return false
                    },
                }

                window.addEventListener('test' as any, null!, options)
                window.removeEventListener('test' as any, null!, options as any)
            } catch (err) {
                passiveSupported = false
            }
            const opts = passiveSupported ? { passive: false } : false
            window.addEventListener('wheel', handleMousewheel, opts)

            scope.$on('$destroy', () => {
                window.removeEventListener('wheel', handleMousewheel, opts as any)
            })

            function handleMousewheel(event: WheelEvent) {
                if ((event.ctrlKey && event.shiftKey) || (event.metaKey && event.shiftKey)) {
                    // fix for issue on Safari 9:
                    // https://github.com/jquery/jquery-mousewheel/issues/156#issuecomment-185433754
                    event.preventDefault()

                    const delta = (event.deltaX || event.deltaY) * MOUSESCROLL_ZOOM_SCALING
                    let newZoom = scope.zoom! + delta

                    // make sure zoom doesn't go beyond the allowed min/max
                    newZoom = _.clamp(newZoom, 0, 100)

                    $ngRedux.dispatch({
                        type: 'SCENES_SET_ZOOM',
                        payload: newZoom,
                    })
                }
            }
        }

        function goToShotOffset(offset: 1 | -1) {
            if (!scope.videoApi || !scope.shots) {
                return
            }

            if (!scope.shots.length) {
                Notification.error(
                    'Next Shot/Previous Shot feature is not available for this video'
                )
                return
            }

            const currentTime = scope.videoApi.getCurrentTime()
            const closest = scope.shots.reduce(
                (memo, { start: time }, idx) => {
                    return Math.abs(currentTime - time) < Math.abs(currentTime - memo.time)
                        ? { time, idx }
                        : memo
                },
                { time: 0, idx: 0 }
            )
            const targetTime =
                ((currentTime < closest.time && offset > 0) ||
                    (currentTime > closest.time && offset < 0)) &&
                Math.abs(currentTime - closest.time) > scope.videoApi.computeHalfFrameEpsilon()
                    ? closest.time
                    : scope.shots[
                          Math.max(0, Math.min(closest.idx + offset, scope.shots.length - 1))
                      ].start

            scope.videoApi.seek(targetTime)
        }
    }
}
