import _ from 'lodash'
import fp from 'lodash/fp'

export default function ErrorStringifierFactory() {
    const ErrorStringifier = {
        stringify(error: any): string | false {
            if (_.isString(error)) {
                return error
            }

            if (_.isError(error)) {
                return error.toString()
            }

            if (_.isArray(error)) {
                return fp.flow(fp.flatten, fp.map(ErrorStringifier.stringify), fp.join(', '))(error)
            }

            if (_.isObjectLike(error)) {
                if ($isHttpResponse(error)) {
                    if (!error.data) {
                        if (error.status === -1) {
                            return $isCancelledHTTPRequest(error)
                                ? false
                                : 'Network error or CORS request denied'
                        } else {
                            return `${error.status}: ${error.statusText}`
                        }
                    } else if (
                        error.status === 422 ||
                        error.data.errors ||
                        error.data.reason ||
                        error.data.message
                    ) {
                        return ErrorStringifier.stringify(
                            error.data.message ||
                                error.data.reason ||
                                error.data.errors ||
                                error.data
                        )
                    } else {
                        return `API returned an error: ${ErrorStringifier.stringify(error.data)}`
                    }
                }

                // an error object, return a string that is in the format of:
                // Key: stringifedValue <br />
                // SecondKey: stringifiedValue[...]
                return _.reduce(
                    error,
                    (result, value, key) => {
                        if ('' !== result) {
                            result = `${result}<br />`
                        }

                        return [
                            result,
                            _.capitalize(key.toString().replace(/\./g, ' ')),
                            ': ',
                            ErrorStringifier.stringify(value),
                        ].join('\n')
                    },
                    ''
                )
            }

            // fallback
            return angular.toJson(error)
        },
    }

    return ErrorStringifier

    /////////////////////////////

    function $isHttpResponse(obj: { [key: string]: any }) {
        const HTTP_RESPONSE_PROPS = ['data', 'status', 'statusText', 'headers', 'config']
        return _.every(HTTP_RESPONSE_PROPS, _.partial(_.has, obj))
    }

    function $isCancelledHTTPRequest(request: { [key: string]: any }) {
        return _.get(request, 'config.timeout.$$state.status', 0) !== 0
    }
}

export type ErrorStringifierInstance = ReturnType<typeof ErrorStringifierFactory>
