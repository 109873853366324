import { find } from 'lodash'
import { FREE_TEXT_QUESTION_TYPE } from 'constants.es6'

export const isFreeTaggingInputType = (question: Question) => {
    return question.type === FREE_TEXT_QUESTION_TYPE
}

export const findAnswerForQuestion = (answers: AnnotationAnswer[], question: Question) => {
    return find(answers, { id: question.uri })
}
