import _ from 'lodash'
import browserInfo from 'browser-info'

import { TaskServiceInstance } from './TaskService.factory'
import { DataEntryNetworkGuardInstance } from '../services/annotation/DataEntryNetworkGuard.factory'
import {
    GlobalShortcutsInstance,
    ShortcutDefinition,
} from '../global-shortcuts/GlobalShortcuts.factory'

import { createAPIMachine } from './api.machine'
import { interpret } from 'xstate'
import { trackButtonClick } from 'util/snowplow'

export default /* @ngInject */ function AnnotateMultipleImageCtrl(
    this: unknown,
    $scope: ng.IRootScopeService & { loading: boolean },
    $state: ng.ui.IStateService,
    $q: ng.IQService,
    $timeout: ng.ITimeoutService,
    $uibModal: ng.ui.bootstrap.IModalService,
    task: MultipleImageTaggingData,
    assignmentID: string,
    TaskService: TaskServiceInstance,
    DataEntryNetworkGuard: DataEntryNetworkGuardInstance,
    GlobalShortcuts: GlobalShortcutsInstance,
    Notification: any,
    MapDialog: any
) {
    interface AnnotateMultipleImageCtrlInstance {
        isSafari: boolean
        loading: boolean

        images: MultipleImage[]
        question: string
        currentGroup: number
        totalPages: number
        currentPage: number
        nextPage: number
        prevPage: number
        isSingleClick: boolean

        handleDoubleClick: typeof handleDoubleClick
        handleSingleClick: typeof handleSingleClick
        changePageIndex: typeof changePageIndex
        toggleAll: typeof toggleAll
        onSubmit: typeof onSubmit
    }

    const vm = this as AnnotateMultipleImageCtrlInstance

    vm.isSafari = browserInfo().name === 'Safari'
    vm.loading = false
    vm.isSingleClick = true

    vm.question = task.question
    vm.images = task.images
    vm.totalPages = task.total_groups
    vm.currentPage = task.current_group

    vm.handleSingleClick = handleSingleClick
    vm.handleDoubleClick = handleDoubleClick
    vm.changePageIndex = changePageIndex
    vm.toggleAll = toggleAll
    vm.onSubmit = onSubmit

    activate()

    ///////////////////////////////////////

    function activate() {
        // API machine
        const apiService = interpret(
            createAPIMachine<any[]>({
                saveFn: DataEntryNetworkGuard.create(saveData) as any,
                initialData: vm.images,
            })
        )
        $scope.$watch(
            'vm.images',
            () => apiService.send({ type: 'UPDATE_DATA', data: vm.images }),
            true
        )

        apiService.start()
        $scope.$on('$destroy', () => apiService.stop())
        // end API machine

        setupShortcuts()
        setCurrentPage(task)

        $(window).trigger('resize')
    }

    //////////////////////////////////

    function toggleImage(image: MultipleImage) {
        if (image) {
            image.answer = !image.answer
        }
    }

    function toggleAll(input: 'incorrect' | 'correct') {
        switch (input) {
            case 'incorrect': {
                vm.images.map((image) => (image.answer = false))
                break
            }
            case 'correct': {
                vm.images.map((image) => (image.answer = true))
                break
            }

            default: {
                throw new Error(`Answer: "${input}", is not assaignable to answer`)
            }
        }
    }

    function handleSingleClick(image: MultipleImage) {
        vm.isSingleClick = true

        $timeout(() => {
            if (vm.isSingleClick) {
                toggleImage(image)
            }
        }, 250)
    }
    function handleDoubleClick(image: MultipleImage) {
        vm.isSingleClick = false
        showSingleImageModal(image)
    }

    function showSingleImageModal(image: MultipleImage) {
        $uibModal.open({
            size: 'xl',
            openedClass: 'w-100',
            windowClass: 'force-scrollable',
            template: `
                    <div class="container-fluid">
                        <img ng-src="{{url}}" width="100%" height="auto"/>
                    </div>
                `,
            controller: /* @ngInject */ function ($scope: ng.IScope & { url: string }) {
                $scope.url = image.url
            },
            controllerAs: 'vm',
        })
    }

    function getPageData(groupIndex: number) {
        TaskService.getGroupData<MultipleImageTaggingData>(assignmentID, groupIndex).then(
            setCurrentPage,
            () =>
                Notification.error(
                    'Page fetching failed. Try refreshing the page. If this error persist, please, contact an administrator.'
                )
        )
    }

    const debouncedGetPageData = _.debounce(getPageData, 700, { trailing: true, leading: false })
    function changePageIndex(offset: 1 | -1) {
        if (vm.loading) {
            return
        }
        const totalPages = vm.totalPages
        const nextPage = vm.currentPage + offset

        vm.currentPage = nextPage === 0 ? totalPages : nextPage > totalPages ? 1 : nextPage

        debouncedGetPageData(vm.currentPage)
    }

    function setCurrentPage(task: MultipleImageTaggingData) {
        vm.currentPage = task.current_group
        vm.images = task.images
        vm.loading = false
    }

    function getNextUndecidedPageData() {
        const index = _.get(_.last(vm.images), 'index', -1)

        TaskService.getUndecidedGroupData<MultipleImageTaggingData>(assignmentID, index).then(
            (res) => {
                if (res.status === 204) {
                    Notification.success('There are no more undecided pages.')

                    return
                }

                setCurrentPage(res.data)
            },
            () =>
                Notification.error(
                    'Page fetching failed. Try refreshing the page. If this error persist, please, contact an administrator.'
                )
        )
    }

    function saveData(): ng.IPromise<any[]> {
        const lastSavedData = _.clone(vm.images)
        return TaskService.updateMultiImageAnswer(assignmentID, lastSavedData).then(
            () => lastSavedData
        )
    }

    function onSubmit() {
        trackButtonClick({
            label: 'Submit Task',
            componentName: 'Multiple Image Tagging Task Submit',
        })
        MapDialog.show(submitConfig).then(() => {
            TaskService.submitTask(assignmentID).then(() => {
                $state.go('worker.list')
            })
        })
    }

    const submitConfig = MapDialog.confirm()
        .title('Submit task')
        .htmlContent(
            `
                <strong>Are you sure you want to proceed?</strong>
                <p>When you submit your changes will be stored and you won't be able to edit them anymore.</p>
            `
        )
        .ok('Yes')
        .cancel('No')

    function setupShortcuts() {
        const shortcuts: ShortcutDefinition[] = [
            {
                description: 'Mark All as Correct',
                keyCombo: 'up',
                shortcut: 'UpArrow',
                callback: () => {
                    toggleAll('correct')
                },
            },
            {
                description: 'Mark All as Incorrect',
                keyCombo: 'down',
                shortcut: 'DownArrow',
                callback: () => {
                    toggleAll('incorrect')
                },
            },
            {
                description: 'Go To Next Image',
                keyCombo: 'right',
                shortcut: 'Right Arrow',
                callback: () => {
                    changePageIndex(1)
                },
            },
            {
                description: 'Go To Previous Image',
                keyCombo: 'left',
                shortcut: 'Left Arrow',
                callback: () => {
                    changePageIndex(-1)
                },
            },
            {
                description: 'Go To Undecided',
                keyCombo: 'u',
                shortcut: 'U',
                callback: () => {
                    getNextUndecidedPageData()
                },
            },
            {
                description: 'Select/Deselect First Image',
                keyCombo: '1',
                shortcut: '1',
                callback: () => {
                    toggleImage(vm.images[0])
                },
            },
            {
                description: 'Select/Deselect Second Image',
                keyCombo: '2',
                shortcut: '2',
                callback: () => {
                    toggleImage(vm.images[1])
                },
            },
            {
                description: 'Select/Deselect Third Image',
                keyCombo: '3',
                shortcut: '3',
                callback: () => {
                    toggleImage(vm.images[2])
                },
            },
            {
                description: 'Select/Deselect Fourth Image',
                keyCombo: '4',
                shortcut: '4',
                callback: () => {
                    toggleImage(vm.images[3])
                },
            },
            {
                description: 'Select/Deselect Fifth Image',
                keyCombo: '5',
                shortcut: '5',
                callback: () => {
                    toggleImage(vm.images[4])
                },
            },
            {
                description: 'Select/Deselect Sixth Image',
                keyCombo: '6',
                shortcut: '6',
                callback: () => {
                    toggleImage(vm.images[5])
                },
            },
            {
                description: 'Select/Deselect Seventh Image',
                keyCombo: '7',
                shortcut: '7',
                callback: () => {
                    toggleImage(vm.images[6])
                },
            },
            {
                description: 'Select/Deselect Eight Image',
                keyCombo: '8',
                shortcut: '8',
                callback: () => {
                    toggleImage(vm.images[7])
                },
            },
            {
                description: 'Select/Deselect Ninth Image',
                keyCombo: '9',
                shortcut: '9',
                callback: () => {
                    toggleImage(vm.images[8])
                },
            },
        ]

        const unbind = GlobalShortcuts.bind({ title: 'Multiple Image Tagging', shortcuts })
        $scope.$on('$destroy', unbind)
    }
}

AnnotateMultipleImageCtrl.resolve = {
    assignmentID: /* @ngInject */ function ($stateParams: ng.ui.IStateParamsService) {
        return $stateParams.assignmentID
    },

    task: /* @ngInject */ function (
        TaskService: any,
        assignmentID: string
    ): MultipleImageTaggingData {
        return TaskService.getTask(assignmentID)
    },
}
