import _ from 'lodash'
import fp from 'lodash/fp'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'

export default /* @ngInject */ function AnnotationSeekerFactory($rootScope: ng.IRootScopeService) {
    const AnnotationSeeker = {
        seekOffset(videoApi: VideoAPIInstance, annotations: any[], offset: number) {
            const EPSILON = 0.0001
            const currentTime = videoApi.getCurrentFrameTime()
            const uniqueAnnotations: any[] = fp.flow(
                fp.uniqBy('timestamp'),
                fp.sortBy('timestamp')
            )(annotations)

            let annotation = getClosestAnnotation()

            if (!annotation) {
                return
            }

            // only if we are currently on the timestamp of an annotation
            // go to offset, otherwize first seek to the closest annotation
            if (Math.abs(annotation.timestamp - currentTime) < EPSILON) {
                annotation =
                    uniqueAnnotations[
                        (uniqueAnnotations.indexOf(annotation) + offset) % uniqueAnnotations.length
                    ]
            }

            videoApi.seek(annotation.timestamp)
            $rootScope.$broadcast('focusAnnotation', annotation)

            /////////////////////////////

            function getClosestAnnotation() {
                let currentAnnotation
                if (offset > 0) {
                    _.forEachRight(uniqueAnnotations, (annotation) => {
                        if (annotation.timestamp >= currentTime) {
                            currentAnnotation = annotation
                        } else {
                            return false
                        }
                    })

                    if (!currentAnnotation) {
                        currentAnnotation = uniqueAnnotations[0] // loop to first
                    }
                } else {
                    _.forEach(uniqueAnnotations, (annotation) => {
                        if (annotation.timestamp <= currentTime) {
                            currentAnnotation = annotation
                        } else {
                            return false
                        }
                    })

                    if (!currentAnnotation) {
                        currentAnnotation = uniqueAnnotations[uniqueAnnotations.length - 1] // loop to last
                    }
                }

                return currentAnnotation
            }
        },
    }

    return AnnotationSeeker
}
