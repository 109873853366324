export default /* @ngInject */ function focusAnnotationDirective(DOMUtility) {
    const directive = {
        scope: {
            annotation: '<focusAnnotation',
        },
        restrict: 'EA',

        link: focusAnnotationDirectiveLinkFn,
    }

    return directive

    ////////////////////

    function focusAnnotationDirectiveLinkFn(scope, element) {
        const dereg = scope.$onRootScope('focusAnnotation', function (_, annotation) {
            if (annotation === scope.annotation) {
                DOMUtility.scrollTo(element, {
                    highlight: true,
                    executeOnNextFrame: true,
                })
            }
        })

        scope.$on('$destroy', dereg)
    }
}
