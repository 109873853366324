import _ from 'lodash'
/**
 * @ngdoc directive
 * @name tableOrder
 * @module map3.core
 * @restrict EA
 *
 * @description
 * This directive transforms an element into a table ordering widget.
 * Usually used with the `<th>` element, it will attach a click event and
 * display a small arrow next to the header to denote ordering direction
 * (ascending/descending).
 *
 * You can attach this directive to multiple elements, and either sort by
 * all of them at once using the array form of
 * [orderBy predicate](https://docs.angularjs.org/api/ng/filter/orderBy#api-section)
 * set the `single` boolean property to only allow sorting by one at a time.
 *
 * Underneath, the directive will create an array of sort predicates using the value
 * of the `by` expression with a `+/-` sign in front
 *
 * In Angular, an expression like `list | orderBy:['-title', '+description']` means:
 * Apply descending order by the `title` property, and for objects with the same title,
 * apply ascending order by the `description` proprty
 *
 * @scope
 *
 * @param {string} predicate Variable to store the resulting orderBy predicate in
 * @param {expression} by `string` An Angular {@link expression}. This expression will be
 *                      evaluated against each item and the result will be used for
 *                      sorting. For example, use 'label' to sort by a property called
 *                      label or 'label.substring(0, 3)' to sort by the first 3
 *                      characters of the label property. (The result of a constant
 *                      expression is interpreted as a property name to be used for
 *                      comparison.
 * @param {boolean=} single Use to force the predicate to always have 1 item at most.
 *                      If you have multiple table-order directives, you must set it
 *                      for each one!
 * @param {boolean=} default If the predicate should start with the field attached
 *                      by default (acending order)
 * @param {boolean=} reverse Used with the `default`, set the order to descending
 *
 * @example
    <example>
        <file name="index.html">
            <table>
                <thead>
                    <th table-order by="title" predicate="myPredicate" default single reverse>Title</th>
                    <th table-order by="description" predicate="myPredicate" single>Description</th>
                </thead>
                <tbody>
                    <tr ng-repeat="item in list | orderBy:myPredicate">
                        <td>{{ item.title }}</td>
                        <td>{{ item.description }}</td>
                    </tr>
                </tbody>
            </table>
        </file>
    </example>
 */
export default /* @ngInject */ function tableOrderDirective($state, UserPreferences) {
    var DISABLED = 'disabled'
    var ORDER_ASC = 'order_asc'
    var ORDER_DESC = 'order_desc'

    var STATES = [DISABLED, ORDER_ASC, ORDER_DESC]

    var directive = {
        restrict: 'EA',
        transclude: true,
        scope: {
            predicate: '=',
            predicateName: '@?',
            by: '@?',
            byFn: '<?',
        },
        template: `
            <div class="d-flex align-items-center" ng-click="transitionState()"">
                <sort-indicator direction="sortDirection" class="mr-2"></sort-indicator>
                <ng-transclude></ng-transclude>
            </div>
        `,
        link: tableOrderLinkFn,
    }

    return directive

    ///////////////////////

    function tableOrderLinkFn(scope, _element, attr) {
        // get boolean attrs
        var isDefault = !_.isUndefined(attr.default)
        var isSingle = !_.isUndefined(attr.single)
        var isReverse = !_.isUndefined(attr.reverse)
        var noPreserve = !_.isUndefined(attr.noPreserve)

        scope.currentState = 0
        scope.STATES = STATES
        scope.transitionState = transitionState

        activate()

        ///////////////////////////

        function activate() {
            if (!attr.by && !attr.byFn) {
                throw new Error(
                    'You must specify either `by` or a `by-fn` property for table-order'
                )
            }

            let executeDefault = false

            scope.$watchGroup(['by', 'byFn'], ([by, byFn]) => {
                if (angular.isString(by)) {
                    scope.getter = by
                } else if (angular.isFunction(byFn)) {
                    scope.getter = byFn
                }

                // check if we automatically set the field as a predicate
                // eg: <table-order default reverse by="title" predicate="myPredicate">
                // will set {scope.myPredicate} to ['-title']
                if (isDefault && _.isEmpty(scope.predicate) && !executeDefault) {
                    executeDefault = true
                    setState(
                        scope.predicate,
                        scope.getter,
                        isReverse ? ORDER_DESC : ORDER_ASC,
                        isSingle
                    )
                }
                updateCurrentStateFromPredicate()
            })

            // initialize predicate

            if (UserPreferences.get('common', 'preserveTableOrder', false) && !noPreserve) {
                const predicateName = scope.predicateName || attr.predicate

                if (_.isNil(scope.predicate)) {
                    throw new Error(
                        `
When using tableOrder ${
                            attr.by ? `by="${attr.by}"` : `by-fn="${attr.byFn}"`
                        } with UserPreferences.live() you MUST define the predicate in the controller first.
Please, consider adding something like the following to your controller:

${predicateName} = UserPreferences.get($state.current.name, '${predicateName}', []);
                    `.trim() + '\n\n'
                    )
                }

                scope.predicate = UserPreferences.liveCollection(
                    scope.$parent,
                    attr.predicate,
                    $state.current.name,
                    predicateName,
                    scope.predicate
                )
            } else if (!_.isArray(scope.predicate)) {
                scope.predicate = []
            }

            // update internal state if the predicate is changed externally
            scope.$watchCollection('predicate', updateCurrentStateFromPredicate)
        }

        /**
         * Transition to the "next" state in STATES
         */
        function transitionState() {
            scope.currentState += 1
            if (scope.currentState === STATES.length) {
                scope.currentState = 0
            }

            if (
                angular.isFunction(scope.getter) &&
                scope.currentState === STATES.indexOf(ORDER_DESC)
            ) {
                scope.currentState = 0
            }
            setState(scope.predicate, scope.getter, STATES[scope.currentState], isSingle)
        }

        /**
         * When the predicate changes, make sure {scope.currentState} matches it
         */
        function updateCurrentStateFromPredicate() {
            var index = $findGetterInPredicate(scope.predicate, scope.getter)
            if (index === -1) {
                scope.currentState = STATES.indexOf(DISABLED)
            } else {
                if (angular.isFunction(scope.predicate[index])) {
                    scope.currentState = STATES.indexOf(ORDER_ASC)
                } else if (scope.predicate[index][0] !== '-') {
                    scope.currentState = STATES.indexOf(ORDER_ASC)
                } else {
                    scope.currentState = STATES.indexOf(ORDER_DESC)
                }
            }

            switch (STATES[scope.currentState]) {
                case 'order_asc':
                    scope.sortDirection = 'up'
                    break
                case 'order_desc':
                    scope.sortDirection = 'down'
                    break
                default:
                    scope.sortDirection = 'none'
            }
        }
    }

    /**
     * Set predicate getter to a particular state.
     * If {isSingle} is set, will force the predicate to hold only this field
     *
     * @param {Array} predicate
     * @param {String|Function} getter
     * @param {ENUM(STATES)} state
     * @param {Boolean} isSingle
     */
    function setState(predicate, getter, state, isSingle) {
        var index = $findGetterInPredicate(predicate, getter)

        if (state === DISABLED) {
            if (index !== -1) {
                predicate.splice(index, 1)
            }
        } else {
            if (isSingle) {
                predicate.length = 0
            }

            if (index === -1) {
                index = predicate.length
            }

            if (angular.isFunction(getter)) {
                predicate[index] = getter
                return
            }
            if (state === ORDER_ASC) {
                predicate[index] = '+' + getter
            } else if (state === ORDER_DESC) {
                predicate[index] = '-' + getter
            } else {
                throw new Error('Unknown predicate state: ' + state)
            }
        }
    }

    function $findGetterInPredicate(predicate, getter) {
        return _.findIndex(predicate, function (candidate) {
            if (angular.isFunction(getter)) {
                return candidate === getter
            } else {
                candidate = String(candidate)

                // direct match, or order by asc/desc
                return (
                    candidate === getter || candidate === '+' + getter || candidate === '-' + getter
                )
            }
        })
    }
}

export const sortIndicatorComponent = {
    bindings: {
        direction: '<',
    },
    template: `
        <div class="sort-indicator-icons" ng-class="'sort-direction-' + $ctrl.direction">
            <i class="material-icons sort-indicator-up">keyboard_arrow_up</i>
            <i class="material-icons sort-indicator-down">keyboard_arrow_down</i>
        </div>
    `,
}
