import angular from 'angular'
import _ from 'lodash'

import {
    TContentMarker,
    TContentMarkerBackend,
    IContentMarkerPair,
    IContentMarkerSingle,
} from './types'
import {
    ContentMarkerType,
    CommercialBlackPairLabel,
    FIXED_POINT_CANDIDATE_INSERTION_LABEL,
    SUPER_FIXED_POINT_CANDIDATE_INSERTION_LABEL,
    CONTENT_MARKER_BLACKLIST_PROPERTIES,
} from './constants'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import fp from 'lodash/fp'

export function buildContentMarkersModelCollection(
    markers: TContentMarkerBackend[] = [],
    videoApi: VideoAPIInstance
): TContentMarker[] {
    return markers.map((marker) => {
        return {
            ...marker,
            $$isSelected: false,
            frameNumber: videoApi.convertLaxSecondToFrame(marker.timestamp),
        }
    })
}

export const buildContentMarkersForSave = (
    contentMarkers: TContentMarker[]
): TContentMarkerBackend[] => {
    return contentMarkers.map((marker) => {
        return _.omit(marker, CONTENT_MARKER_BLACKLIST_PROPERTIES) as TContentMarkerBackend
    })
}

export const getLabelForMarker = (contentMarker: TContentMarker) => {
    if (_.get(contentMarker, 'chapter', false)) {
        return SUPER_FIXED_POINT_CANDIDATE_INSERTION_LABEL
    }

    switch (contentMarker.type) {
        case ContentMarkerType.FPCI:
            return FIXED_POINT_CANDIDATE_INSERTION_LABEL
        case ContentMarkerType.FFCB:
            return CommercialBlackPairLabel.FFCB
        case ContentMarkerType.LFCB:
            return CommercialBlackPairLabel.LFCB
        default:
            return `No label found for ${contentMarker.type}`
    }
}

export const getLabelForRating = (rating: number) => {
    switch (rating) {
        case 0:
            return 'Do Not Use'
        case 1:
            return 'Acceptable'
        case 2:
            return 'Good'
        default:
            return `No rating for ${rating} found`
    }
}

export function isPair(
    contentMarker: IContentMarkerPair | IContentMarkerSingle
): contentMarker is IContentMarkerPair {
    return angular.isDefined((<IContentMarkerPair>contentMarker).pair)
}

export function findMaxId(array: TContentMarker[] | TContentMarkerBackend[]): number {
    return fp.flow(fp.map('id'), fp.max)(array) || 0
}

export const isCommercialBlackPairValid = (pair: IContentMarkerPair[]) => {
    // check for ffcb/lfc for now
    const ffcb = pair.find((m) => m.type === ContentMarkerType.FFCB)
    const lfcb = pair.find((m) => m.type === ContentMarkerType.LFCB)
    return !!ffcb && !!lfcb && ffcb.timestamp < lfcb.timestamp
}

export const isMarkersDataValid = (contentMarkers: TContentMarker[]) => {
    return !contentMarkers.some((contentMarker) => {
        if (isPair(contentMarker)) {
            const result = contentMarkers.filter((m) => m.pair === contentMarker.pair)
            if (result.length !== 2) {
                return true
            }

            if (
                result.length === 2 &&
                !isCommercialBlackPairValid(result as IContentMarkerPair[])
            ) {
                return true
            }

            return false
        }
    })
}
