import _ from 'lodash'
import { newRelic } from 'util/newRelic'

export default /* @ngInject */ function InternalMessagingFactory(
    MESSAGE_DEFINITIONS: any,
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    $http: ng.IHttpService,
    $uibModal: ng.ui.bootstrap.IModalService
) {
    const InternalMessaging = {
        getAllMessages: () => {
            return $http.get('/api/communication').then((res) => res.data)
        },

        checkForNewMessages: () => {
            return $http.get('/api/communication/check').then(
                (res) => res.data,
                () => []
            )
        },

        reportMissingAutoritis: (missingAuthorityRequest: string) => {
            return $http
                .post(`/api/communication/send-missing-authority/${missingAuthorityRequest}`, {})
                .then((res) => res.data)
        },

        getBugBackendData: (url: string) => {
            return $http
                .get('/api/communication/bug-compile-backend-data', {
                    params: { url },
                })
                .then((res) => _.get(res.data, 'backend'))
        },

        readMessage: (message: any) => {
            const messageDefinition =
                MESSAGE_DEFINITIONS[message.type] || MESSAGE_DEFINITIONS['Default']

            $uibModal.open({
                templateUrl: 'js/messages/templates/readNotificationModal.tpl.html',
                resolve: {
                    message: angular.copy(message),
                    notificationTemplateUrl: _.constant(messageDefinition.templateUrl),
                    modalTitle: _.constant(messageDefinition.modalTitle),
                },
                controller: /* @ngInject */ function (
                    message: any,
                    notificationTemplateUrl: string,
                    modalTitle: string
                ) {
                    const notificationVm = this as {
                        message: any
                        notificationTemplateUrl: string
                        modalTitle: string
                    }

                    notificationVm.message = message
                    notificationVm.notificationTemplateUrl = notificationTemplateUrl
                    notificationVm.modalTitle = modalTitle
                },
                controllerAs: 'notificationVm',
            })

            if (!message.read_at) {
                InternalMessaging.markMessageAsRead(message.id)
            }
        },

        markMessageAsRead: (id: string) => {
            return $http.post(`/api/communication/read/${id}`, {}, NO_LOAD_OVERLAY)
        },

        deleteMessage: (id: string) => {
            return $http.post(`/api/communication/delete/${id}`, {})
        },
    }

    return InternalMessaging
}

export type InternalMessagingInstance = ReturnType<typeof InternalMessagingFactory>
