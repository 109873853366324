import _ from 'lodash'
import { UserInstance } from './User.factory'
import { trackPageView } from 'util/snowplow'
import Storage from 'services/Storage'

const debug = require('debug')('map3:RoutingAuth')

/*eslint angular/on-watch: "off"*/

export default /* @ngInject */ function RoutingAuthFactory(
    USER_ROLE_ADMIN: TRole,
    $rootScope: ng.IRootScopeService,
    $state: ng.ui.IStateService,
    $urlRouter: ng.ui.IUrlRouterService,
    User: UserInstance
) {
    const options = {
        defaultState: '',
        defaultStateForRole: {
            // admin: 'auth.admin.index',
        },
        defaultStateForMultiRole: '',
        denyMultiRole: false,
        loginState: 'auth.login',
        deniedState: 'auth.denied',
    }

    type IState = ng.ui.IState
    type TDefaultRole = keyof typeof options.defaultStateForRole
    type TDefaultOptions = keyof typeof options

    let lastRequestedState: IState | null
    let lastRequestedStateParams = {}

    const RoutingAuth = {
        // Initialize the service
        init(opts = {}) {
            _.merge(options, opts)

            // listen on angular's location change success event
            // and stop default handling until we have resolved a user
            // (regardless of if the user is authenticated or not)
            $rootScope.$on('$locationChangeSuccess', (e, newUrl: string) => {
                if (!User.isResolved()) {
                    e.preventDefault()

                    User.getCurrent()
                        .catch(angular.noop)
                        .finally(() => {
                            // execute ui.router's handing
                            $urlRouter.sync()
                        })
                }

                trackPageView(newUrl)
            })

            // add ui.router's $locationChangeSuccess listener AFTER our own
            $urlRouter.listen()

            // on ui.router $stateChangeStart, verify that the user has access
            // to the particular route. If not, stop the transitiona and execute
            // RoutingAuth.authenticationRequiredHandler()
            $rootScope.$on('$stateChangeStart', (e, toState, toParams, fromState) => {
                if (!RoutingAuth.checkAccessToState(toState, toParams)) {
                    e.preventDefault()
                }

                if (
                    fromState &&
                    fromState.name !== '' &&
                    toState.data &&
                    toState.data.forceReload
                ) {
                    const href = $state.href(toState, toParams)
                    window.location.href = href
                    window.location.reload()
                }
            })

            // after user logout, verify that the user still has access to the current
            // state, or execute RoutingAuth.authenticationRequiredHandler()
            $rootScope.$on('user.logout', () => {
                debug('checking access to state on user logout', $state.current, $state.params)

                const hasAccess = RoutingAuth.checkAccessToState($state.current, $state.params)
                // if we were logged out and forwarded to the login page, clear
                // the last requested state
                if (!hasAccess) {
                    RoutingAuth.setLastRequestedState(null)
                }
            })

            // TODO: Figure out authentication error interceptor
            // and trigger the authenticationRequiredHandler
        },

        /**
         * Check access to a particular state for the current user.
         *
         * By default, will execute the authenticationRequiredHandler or
         * accessDeniedHandler. To prevent this, set the last param {onlyCheck} param
         * to true.
         *
         * @param {(State|string)} stateOrName
         * @param {Object} stateParams
         * @param {Boolean} onlyCheck
         */
        checkAccessToState(stateOrName: string | IState, stateParams = {}, onlyCheck = false) {
            const state = $state.get(stateOrName)

            debug('checking access to', state, stateParams)
            // preserve the requested state/params
            if (!onlyCheck) {
                RoutingAuth.setLastRequestedState(state, stateParams)
            }

            if (state.data && state.data.enabled === false) {
                RoutingAuth.accessDeniedHandler(/* User.cached(), state, stateParams */)
            }

            // if the state is not public
            if (!RoutingAuth.isPublic(state)) {
                debug('state is not public')

                // and the user is not authenticated
                if (!User.isAuthenticated()) {
                    debug('user is not authenticated, fire authenticationRequiredHandler')

                    // fire the authentication required handler
                    if (!onlyCheck) {
                        RoutingAuth.authenticationRequiredHandler(/* state, stateParams */)
                    }

                    return false
                }

                // if we do not allow multi roles
                if (
                    options.denyMultiRole &&
                    state.name !== options.defaultStateForMultiRole &&
                    User.getRoles().length > 1
                ) {
                    // but the user has access for this state with one of their current roles
                    if (User.hasRoleForState(state)) {
                        // we set the user's current role to the state's role
                        if (!onlyCheck) {
                            debug(
                                `setting user roles to ${angular.toJson(
                                    state.data && state.data.roles
                                )}`
                            )
                            User.setRoleFromState(state)
                        }
                    } else {
                        if (User.isAdmin(true)) {
                            User.setActiveRoles([USER_ROLE_ADMIN])
                            debug('user is admin, always allow access')

                            return true
                        }
                        debug('user has multiple roles, forwarding to multi role state')
                        if (!onlyCheck) {
                            $rootScope.$evalAsync(() => {
                                $state.go(options.defaultStateForMultiRole)
                            })
                        }
                    }
                }

                if (User.isAdmin(true)) {
                    if (User.hasRoleForState(state, /* rawCheck */ true)) {
                        User.setRoleFromState(state)
                    } else {
                        User.setActiveRoles([USER_ROLE_ADMIN])
                    }

                    debug('user is admin, always allow access')

                    return true
                }

                // if the route has specific role requirements
                // and the user does not pass them
                if (!User.hasRoleForState(state)) {
                    debug('user does not have role: ', state.data && state.data.roles)

                    // fire the access denide handler
                    if (!onlyCheck) {
                        RoutingAuth.accessDeniedHandler(/* User.cached(), state, stateParams */)
                    }

                    return false
                }
            }

            // if we did not trigger any of the above, then the user has access
            debug('allowing access to state', state)

            return true
        },

        // Function to detect if a state is public or not;
        // https://github.com/angular-ui/ui-router/wiki#attach-custom-data-to-state-objects
        // We assume that all routes are non-public unless expressly stated as such
        isPublic(state: IState) {
            return state.name === options.loginState || !!_.get(state, 'data.public', false)
        },

        getOption(name: TDefaultOptions, defaultValue = '') {
            return options[name] || defaultValue
        },

        setLastRequestedState(stateOrName: string | IState | null, stateParams = {}) {
            const state = stateOrName && $state.get(stateOrName)

            if (state) {
                const notLoginState = state.name !== options.loginState
                const allowRemember = _.get(state, 'data.allowRemember', true)

                if (notLoginState && allowRemember) {
                    // Set this into session storage because MyID redirect
                    Storage.setItem('lastRequestedStateName', state.name)
                    Storage.setItem('lastRequestedStateParams', stateParams)
                    lastRequestedState = state
                    lastRequestedStateParams = stateParams || {}
                }
            } else {
                Storage.removeItem('lastRequestedStateName')
                Storage.removeItem('lastRequestedStateParams')
                lastRequestedState = null
                lastRequestedStateParams = {}
            }
        },

        getLastRequestedState() {
            // since the user might re-login or change permissions since the last state was set,
            // we want to verify that they have access to it before returning it
            // as to avoid unnecessary Access Denied errors

            // Because now we use MyID as an authentication method we destroy the scope
            // when we redirect to MyID, thus we cannot relay on what has been stored in
            // lastRequestedState and lastRequestedStateParams. To overcome this we
            // store this into session storage and use it from there as a fallback
            const state = lastRequestedState || Storage.getItem('lastRequestedStateName')
            const stateParams = _.size(lastRequestedStateParams)
                ? lastRequestedStateParams
                : Storage.getItem('lastRequestedStateParams') || {}

            if (state && RoutingAuth.checkAccessToState(state, stateParams, true)) {
                return {
                    state,
                    stateParams,
                }
            } else {
                // if not, just return the default state
                return {
                    state: RoutingAuth.getDefaultState(),
                    stateParams: {},
                }
            }
        },

        hasRequestedState() {
            const hasAppDataForRequest = !!_.size(lastRequestedState)
            return hasAppDataForRequest || !!_.size(Storage.getItem('lastRequestedStateName'))
        },

        goToLastRequestedState() {
            const { state, stateParams } = RoutingAuth.getLastRequestedState()
            $state.go(state as Extract<unknown, IState | string>, stateParams)
        },

        getDefaultState() {
            const knownRoles = _.keys(options.defaultStateForRole)
            const matchingRoles = _.intersection(User.getRoles(), knownRoles)

            if (matchingRoles.length > 1) {
                return options.defaultStateForMultiRole
            } else {
                return (
                    options.defaultStateForRole[matchingRoles[0] as TDefaultRole] ||
                    options.defaultState
                )
            }
        },

        getDefaultStateForRole(role: TDefaultRole) {
            return options.defaultStateForRole[role]
        },

        loginState() {
            return options.loginState
        },

        deniedState() {
            return options.deniedState
        },

        // Overwrites the default handler that is invoked if a state is activated that requires
        // authentication but no user is logged in. The handler is passed the state that
        // requires authentication, and its state parameters.
        onAuthenticationRequired(handler: () => void) {
            this.authenticationRequiredHandler = handler
        },

        // Overwrites the default handler that is invoked if a user authenticates successfully.
        onAuthenticationSuccess(handler: () => void) {
            this.authenticationSuccessHandler = handler
        },

        // Overwrites the default handler that is invoked if a state is activated that
        // the currently logged in user has no access to. Access is denied if the user
        // does not have a required permission or authCheck returns false.
        // The currently logged in user is the first parameter passed to the handler.
        // The handler is passed the state that access was denied to, and its state parameters.
        onAccessDenied(handler: () => void) {
            this.accessDeniedHandler = handler
        },

        authenticationRequiredHandler:
            function defaultAuthenticationRequiredHandler(/* state, stateParams */) {
                $rootScope.$evalAsync(() => {
                    $state.go(options.loginState)
                })
            },

        accessDeniedHandler:
            function defaultAccessDeniedHandler(/* currentUser, state, stateParams */) {
                $rootScope.$evalAsync(() => {
                    $state.go(options.deniedState, {}, { location: false })
                })
            },

        authenticationSuccessHandler: function defaultAuthenticationSuccessHandler() {
            $rootScope.$evalAsync(() => {
                RoutingAuth.goToLastRequestedState()
            })
        },
    }

    return RoutingAuth
}

export type RoutingAuthInstance = ReturnType<typeof RoutingAuthFactory>
