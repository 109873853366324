import constants from '../constants'

import routes from './routes'

import ResultsExportModalFactory from './ResultsExportModal.factory'

const map3results = angular
    .module('map3.results', [constants, require('angular-ui-router')])
    .config(routes)

    .factory('ResultsExportModal', ResultsExportModalFactory)

export default map3results.name
