export default function ctrlEnterSubmitDirective() {
    var directive = {
        restrict: 'A',
        link: ctrlEnterSubmitLinkFn,
    }

    return directive

    //////////////////

    function ctrlEnterSubmitLinkFn(scope, element) {
        element.on('keydown', function (e) {
            if (e.keyCode === 13 && (e.metaKey || e.ctrlKey) && e.target.form) {
                angular.element(e.target.form).trigger('submit')
            }
        })

        scope.$on('$destroy', function () {
            element.off('keydown')
        })
    }
}
