import constants from '../constants'

import routes from './routes'
import UserPreferencesFactory from './UserPreferences.factory'
import UserProfileModuleFactory from './UserProfileModule.factory'
import InternalMessagingFactory from './InternalMessaging.factory'
import ThemeManagerFactory from './ThemeManager.factory'

/**
 * @ngdoc module
 * @name map3.user
 * @module map3.user
 *
 * @description
 * Worker tasks module
 */
const map3user = angular
    .module('map3.user', [constants, require('angular-ui-router')])
    .config(routes)
    .constant('MESSAGE_DEFINITIONS', {
        'App\\Notifications\\CommentNotification': {
            templateName: 'commentNotification',
            templateUrl: 'js/messages/templates/CommentNotification.tpl.html',
            modalTitle: 'Comment Notification',
        },
        'App\\Notifications\\BugNotification': {
            templateName: 'bugNotification',
            templateUrl: 'js/messages/templates/BugReportNotification.tpl.html',
            modalTitle: 'Bug Report Notification',
        },
        'App\\Notifications\\TaskStopNotification': {
            templateName: 'taskStopedNotification',
            templateUrl: 'js/messages/templates/DefaultMessage.tpl.html',
            modalTitle: 'Task Stopped Notification',
        },
        'App\\Notifications\\TaskQaReturnNotification': {
            templateName: 'taskReturnededNotification',
            templateUrl: 'js/messages/templates/ReturnTaskNotification.tpl.html',
            modalTitle: 'Task Returned Notification',
        },
        'App\\Notifications\\TaskQaRejectNotification': {
            templateName: 'taskRejectedNotification',
            templateUrl: 'js/messages/templates/RejectTaskNotification.tpl.html',
            modalTitle: 'Task Rejected Notification',
        },
        Default: {
            templateName: 'DefaultTemplateDefinition',
            templateUrl: 'js/messages/templates/DefaultMessage.tpl.html',
            modalTitle: 'Notification',
        },
    })
    .factory('UserPreferences', UserPreferencesFactory)
    .factory('UserProfileModule', UserProfileModuleFactory)
    .factory('InternalMessaging', InternalMessagingFactory)
    .factory('ThemeManager', ThemeManagerFactory)

export default map3user.name
