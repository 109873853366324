import { v4 as uuid4 } from 'uuid'
/**
 * Generate a new Identifier of format:
 *
 * http://data.disney.com/resources/{uuid-v4}-{YEAR}{MONTH}{DAY}
 */
export function generateIdentifier(): string {
    const uuid = uuid4()

    return `http://data.disney.com/resources/map3/${uuid}`
}
