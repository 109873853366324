import _ from 'lodash'

/**
 * @ngdoc directive
 * @name mapSystemButton
 * @module map3.core
 * @restrict E
 * @scope
 *
 * @description
 * Print a button with a particular style based on the user's OS.
 * For example, Control is printed as Meta on Mac OS.
 *
 * @param {string} button The button to print out
 */

export default /* @ngInject */ function mapSystemButtonDirective(OSGuesser) {
    var BUTTONS = {
        control: {
            Windows: 'Ctrl',
            Linux: 'Ctrl',
            Mac: '&#8984;',
        },
    }

    var directive = {
        restrict: 'E',
        scope: {
            button: '@',
        },
        template: '<span ng-bind-html="renderedButton"></span>',
        link: mapSystemButtonLinkFn,
    }

    return directive

    ///////////////////////

    function mapSystemButtonLinkFn(scope) {
        var validButtons = _.keys(BUTTONS)
        if (!_.includes(scope.button, validButtons)) {
            throw new Error('mapSystemButton does not support: ' + scope.button)
        }

        scope.renderedButton = BUTTONS[scope.button][OSGuesser.guess()]
    }
}
