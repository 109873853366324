import constants from '../constants'

import filmstripDirective from '../directives/scene-splitting/filmstrip.directive'
import selectionInfoComponent from '../directives/scene-splitting/selectionInfo.component'
import filmstripControlButtonComponent from '../directives/scene-splitting/filmstripControlButton.component'
import sceneSplittingDirective from '../directives/scene-splitting/sceneSplitting.directive'
import actsTimelineComponent from '../directives/scene-splitting/actsTimeline.component'
import scenesTimelineComponent from '../directives/scene-splitting/scenesTimeline.component'
import subScenesTimeline from '../directives/scene-splitting/subScenesTimeline.component'
import genericTimelineComponent from '../directives/scene-splitting/genericTimeline.component'
import zoomLevelDirective from '../directives/scene-splitting/zoomLevel.directive'
import contentMarkersTimelineBase from '../directives/scene-splitting/content-markers-timeline-base/contentMarkersTimelineBase.component'
import watchVideoTimeline from '../watch-video/watchVideoTimeline.component'
import { WatchVideoReducerFactory } from '../watch-video/WatchVideoReducer'

import {
    UndoableScenesReducerFactory,
    ScenesReducerFactory,
} from '../services/scenes/ScenesReducer'

import ActActionsFactory, { ActActionCreatorsFactory } from 'services/scenes/ActActions.factory'
import SceneActionsFactory, {
    SceneActionCreatorsFactory,
} from 'services/scenes/SceneActions.factory'
import SubSceneActionsFactory, {
    SubSceneActionCreatorsFactory,
} from '../services/scenes/SubSceneActions.factory'

import SceneHelperFactory from '../services/scenes/SceneHelper.factory'
import ZoomScalerFactory from './ZoomScaler.factory'

import scenesViewerDirective from 'directives/scenesViewer.directive'
import ngRedux from 'ng-redux'

/**
 * @ngdoc object
 * @name Scene
 * @module map3.scenes
 *
 * @description
 * A Scene data object
 *
 * @property {number} id Unique Scene ID
 * @property {number} start Scene start, in seconds
 * @property {number} end Scene end, in seconds
 * @property {number=} parentId Defined only for SubScene Scene variant
 * @property {number} $renderStart Same as start, used to for scene-splitting render
 * @property {number} $renderEnd Usually the value of the _next_ scene start (unless
 *                    this is the last scene). Necessary for proper length calculations
 *                    in scene-splitting
 * @property {boolean} $$isSelected Is currently marked as selected?
 * @property {boolean} $$wasSingleSelected Was it selected individually?
 */

/**
 * @ngdoc module
 * @name map3.scenes
 * @module map3.scenes
 *
 * @description
 * Scenes module
 */
const map3scenes = angular
    .module('map3.scenes', [constants, ngRedux])

    // the visible seconds on the timeline at max zoom
    .constant('SS_ZOOM_MAX_SECONDS', 5)
    // the visible seconds on the timeline when we switch to
    // detailed scene view
    .constant('SS_ZOOM_SWITCH_SECONDS', 90)

    .directive('filmstrip', filmstripDirective)
    .component('selectionInfo', selectionInfoComponent)
    .component('filmstripControlButton', filmstripControlButtonComponent)
    .directive('sceneSplitting', sceneSplittingDirective)
    .directive('zoomLevel', zoomLevelDirective)
    .directive('scenesViewer', scenesViewerDirective)

    .component('actsTimeline', actsTimelineComponent)
    .component('scenesTimeline', scenesTimelineComponent)
    .component('subScenesTimeline', subScenesTimeline)
    .component('genericTimeline', genericTimelineComponent)
    .component('contentMarkersTimelineBase', contentMarkersTimelineBase)
    .component('watchVideoTimeline', watchVideoTimeline)

    .factory('UndoableScenesReducer', UndoableScenesReducerFactory)
    .factory('ScenesReducer', ScenesReducerFactory)
    .factory('ActActions', ActActionsFactory)
    .factory('ActActionCreators', ActActionCreatorsFactory)
    .factory('SceneActionCreators', SceneActionCreatorsFactory)
    .factory('SceneActions', SceneActionsFactory)
    .factory('SceneActionCreators', SceneActionCreatorsFactory)
    .factory('SubSceneActions', SubSceneActionsFactory)
    .factory('SubSceneActionCreators', SubSceneActionCreatorsFactory)

    .factory('WatchVideoReducer', WatchVideoReducerFactory)

    .factory('ZoomScaler', ZoomScalerFactory)

    .factory('SceneHelper', SceneHelperFactory)

export default map3scenes.name
