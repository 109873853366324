import _ from 'lodash'
import browserInfo from 'browser-info'
import { OSGuesserInstance } from 'services/OSGuesser.factory'

type ScopeBindings = {
    combo: string
    renderedButton: string
    useCtrlKey: boolean
}

export default /* @ngInject */ function prettifyShortcutKeys(OSGuesser: OSGuesserInstance) {
    const CTRL_DISPLAY_VAL = 'Ctrl'
    const ALT_DISPLAY_VAL = 'Alt'
    const ALT_MAC_DISPLAY_VAL = 'Option'
    const BUTTONS: { [key: string]: any } = {
        control: {
            Windows: CTRL_DISPLAY_VAL,
            Linux: CTRL_DISPLAY_VAL,
            Mac: '&#8984;',
        },
        Alt: {
            Windows: ALT_DISPLAY_VAL,
            Linux: ALT_DISPLAY_VAL,
            Mac: ALT_MAC_DISPLAY_VAL,
        },
        shift: {
            Windows: '&#8679;',
            Linux: '&#8679;',
            Mac: '&#8679;',
        },
    }

    const directive = {
        restrict: 'E',
        scope: {
            combo: '@',
            useCtrlKey: '@',
        },
        template: '<span ng-bind-html="renderedButton"></span>',
        link: prettifyShortcutKeysLinkFn,
    }

    return directive

    ///////////////////////

    function prettifyShortcutKeysLinkFn(scope: ng.IScope & ScopeBindings) {
        const html = scope.combo
            .split('+')
            .map((key) => {
                if (key.includes('|')) {
                    return key.split('|').reduce((firstKey, secondKey) => {
                        return `${prettifyKey(scope, firstKey)}
                                <span class="keyboard-btn-separator mx-1">OR</span>
                                ${prettifyKey(scope, secondKey)}`
                    })
                } else {
                    return prettifyKey(scope, key)
                }
            })
            .join('<span class="keyboard-btn-separator mx-1">+</span>')

        scope.renderedButton = html
    }

    function prettifyKey(scope: ng.IScope & ScopeBindings, key: string) {
        const isSafari = browserInfo().name === 'Safari'
        const validButtons = _.keys(BUTTONS)

        let mappedByOsBrowserKey = key

        if (_.includes(validButtons, key)) {
            mappedByOsBrowserKey = BUTTONS[key][OSGuesser.guess()]
            if (isSafari && scope.useCtrlKey) {
                mappedByOsBrowserKey = CTRL_DISPLAY_VAL
            }
        }

        return `<span class="keyboard-btn">${mappedByOsBrowserKey}</span>`
    }
}
