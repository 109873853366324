import _ from 'lodash'

type VGPlayer = any
type VideoPopupWindow = {
    videoPopupWindow?: {
        videoPopupCommunication: VideoAPIInstance
    }
}

export default /* @ngInject */ function VideoAPIBuilderFactory(
    $rootScope: ng.IRootScopeService,
    $log: ng.ILogService
) {
    const VideoAPIBuilder = {
        build: buildVideoApi,
    }

    return VideoAPIBuilder

    /**
     * @ngdoc type
     * @name videoAPI
     * @module map3.video
     *
     * @description
     * An API for controlling the video directive
     */
    function buildVideoApi(player: VGPlayer, scope: ng.IScope & VideoPopupWindow) {
        let _playerLoadedFlag = false
        let _computedHalfFrameEpsilon: number
        const errorObject = {} as { value: Error }

        const videoAPI = {
            $destroyed: false,

            /**
             * @ngdoc method
             * @name videoAPI#getPlayer
             *
             * @returns The VideoGorillas Player
             */
            getPlayer() {
                return player
            },

            /**
             * @ngdoc method
             * @name videoAPI#getFilmStripDrawer
             *
             * @param {function} callback Callback params that gets a `VG.FilmStripDrawer` object.
             *      The callback is wrapped in a $rootScope.$applyAsync() call
             */
            getFilmStripDrawer(callback: (filmStripDrawer: any) => void): void {
                if (videoAPI.assertPlayerLoaded()) {
                    setupFilmStripDrawerCallback()
                } else {
                    player.addEventListener('load', setupFilmStripDrawerCallback)
                }

                function setupFilmStripDrawerCallback() {
                    videoAPI.getPlayer().getFilmStripDrawer((filmStripDrawer: any) => {
                        $rootScope.$applyAsync(callback.bind(null, filmStripDrawer))
                    })
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#onLoad
             * @private
             * @description Execute a callback on video player load event
             *
             * If the player is loaded, execute on next browser tick
             *
             * @param {function} callback The callback to execute on player loaded
             */
            onLoad(callback: (videoApiParam: any) => void) {
                if (videoAPI.assertPlayerLoaded()) {
                    $rootScope.$applyAsync(callback.bind(null, videoAPI))
                } else {
                    player.addEventListener('load', function () {
                        $rootScope.$applyAsync(callback.bind(null, videoAPI))
                    })
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#setarkers
             * @description
             * Pass-through to {VG.Player#setMarkers}
             *
             * @param {Array.<Marker>} markers The markers to set for the player
             */
            setMarkers(markers: any[]) {
                player.markers.setMarkers(markers)
            },

            setAnnotations(annotaions: any[]) {
                if (player.boundryDrawer) {
                    player.boundryDrawer.setAnnotations(annotaions)
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#getCurrentTime
             * @description
             * `depreciated in favor of {videoAPI#getCurrentFrameTime}`
             *
             * Get current time in seconds (not frame accurate!)
             *
             * Usually, this will return a time slightly above the current
             * frame time
             *
             * @param [noWarn=false] Don't display depreciation warning.
             *      For when you know you really want to use this method
             *
             * @return The current time in seconds
             */
            getCurrentTime(noWarn = false): number {
                if (!noWarn) {
                    $log.debug(
                        'videoAPI.getCurrentTime() does not return accurate frame times and is depreciated in favor of videoAPI.getCurrentFrameTime().'
                    )
                }

                if (!videoAPI.assertPlayerLoaded()) {
                    return 0
                }

                return player.getCurrentTime()
            },

            /**
             * @ngdoc method
             * @name videoAPI#isPlaying
             * @description
             * Is the video playing right now
             *
             * @return Whether the player is currently playing
             */
            isPlaying(): boolean {
                return (videoAPI.assertPlayerLoaded() && player.isPlaying()) || false
            },

            /**
             * @ngdoc method
             * @name videoAPI#addEventListener
             * @description
             * Add event listener to the player.
             * (see [Player.prototype.addEventListener(type, handler)](https://github.com/videogorillas/vgplayer-api/blob/master/Player.md#Player_addEventListener))
             *
             * @param {string} type Event type to listen for
             * @param {function} handler The callback function
             */
            addEventListener: player.addEventListener.bind(player),

            /**
             * @ngdoc method
             * @name videoAPI#removeEventListener
             * @description
             * Remove event listener to the player.
             *
             * @param {string} type Event type to listen for
             * @param {function} handler The callback function
             */
            removeEventListener: player.removeEventListener.bind(player),

            /**
             * @ngdoc method
             * @name videoAPI#getCurrentFrame
             * @description
             * Get the current frame
             */
            getCurrentFrame(): number {
                if (!videoAPI.assertPlayerLoaded()) {
                    return 0
                }

                const currentFrame = videoAPI.convertLaxSecondToFrame(
                    player.getCurrentTime(/* noWarn */ true)
                )
                const lastFrame = videoAPI.getLastFrameIdx()

                return Math.min(currentFrame, lastFrame)
            },

            /**
             * @ngdoc method
             * @name videoAPI#getCurrentFrameTime
             * @description
             * Get the current frame time in seconds (frame accurate)
             */
            getCurrentFrameTime(): number {
                if (!videoAPI.assertPlayerLoaded()) {
                    return 0
                }

                return videoAPI.convertFrameToSeconds(
                    videoAPI.convertLaxSecondToFrame(player.getCurrentTime(/* noWarn */ true))
                )
            },

            /**
             * @ngdoc method
             * @name videoAPI#getDuration
             * @description
             * Get the duration of the video in seconds.
             * Will return false if video information is not yet available.
             *
             * @return The duration in seconds or `false`.
             */
            getDuration(): number {
                return (videoAPI.assertPlayerLoaded() && player.getSeekableDurationSec()) || false
            },

            /**
             * @ngdoc method
             * @name videoAPI#getFrameAccurateDuration
             * @description
             * Get the duration of the video in seconds, aligned to the last frame
             *
             * Will return `false` if video information is not yet available
             *
             * @return The duration in seconds or `false`
             */
            getFrameAccurateDuration(): number | false {
                const duration = videoAPI.getDuration()
                if (duration) {
                    return videoAPI.convertLaxSecondToFrameSecond(duration)
                } else {
                    return false
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#getDurationInFrames
             * @description
             * Get the duration of the videos in frames
             *
             * @return The duration in frames or 0
             */
            getDurationInFrames(): number {
                return videoAPI.getLastFrameIdx() + 1
            },

            /**
             * @ngdoc method
             * @name videoAPI#getLastFrameIdx
             * @description
             * Get the index of the last frames in video or -1
             *
             * @return The index of the last frame or -1
             */
            getLastFrameIdx(): number {
                const duration = player.getSeekableDurationSec()
                return player.getTimeline().getFrameBySec(duration) || -1
            },

            /**
             * @ngdoc method
             * @name videoAPI#seek
             * @description
             *
             * Seek to seconds or timecode. Will seek inside the popup window, if one is open
             *
             * @param time Seconds in float or timecode in string
             */
            seek(time: string | number): void {
                if (scope.videoPopupWindow && scope.videoPopupWindow.videoPopupCommunication) {
                    scope.videoPopupWindow.videoPopupCommunication.seek(time)
                } else {
                    videoAPI.$seek(time)
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#seekFrame
             * @description
             *
             * Seek to frame. Will seek inside the popup window, if one is open
             *
             * @param frameNumber The frame to seek to
             */
            seekFrame(frameNumber: number): void {
                if (scope.videoPopupWindow && scope.videoPopupWindow.videoPopupCommunication) {
                    scope.videoPopupWindow.videoPopupCommunication.seekFrame(frameNumber)
                } else {
                    videoAPI.$seekFrame(frameNumber)
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#$seek
             * @private
             *
             * @description
             * Internal function; Will seek in this player, even if we have a popup open
             *
             * @param time Seconds in float or timecode in string
             */
            $seek(time: number | string | false) {
                // sometimes we have false propagate, we want to not send it to the player
                if (time === false) {
                    return
                }

                // we need this check because if we pass a number to `player.seek()`
                // it will seek by frame, but we intend seek by second.
                if (_.isNumber(time)) {
                    player.seekSec(
                        videoAPI.convertLaxSecondToFrameSecond(time, /* shouldThrow */ false)
                    )
                } else {
                    player.seek(time)
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#$seekFrame
             * @private
             *
             * @description
             * Internal function; Will seek in this player, even if we have a popup open
             *
             * @param frameNumber The frame number to seek to
             */
            $seekFrame(frameNumber: number) {
                const parsedFrameNumber = parseInt('' + frameNumber, 10)
                if (isNaN(parsedFrameNumber)) {
                    throw new Error(`VideoAPI.$seekFrame cannot parse "${frameNumber}" as integer`)
                }

                try {
                    // this can potentially fail, so wrap in a try/catch
                    player.seekFrame(parsedFrameNumber)
                } catch (e) {
                    $log.error(e)

                    // if larger than 0, we probably tried to seek to a frame after
                    // the end of the video, so just seek to the end of the video
                    if (parsedFrameNumber > 0) {
                        videoAPI.$seek(videoAPI.getDuration())
                    } else {
                        videoAPI.$seek(0)
                    }
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#playAtRate
             * @description
             * Play at specific speed
             *
             * @param rate (Integer): playback rate,
             *  possible values: -8, -4, -2, -1, 1, 2, 3, 4, 8.
             */
            playAtRate(rate: number) {
                player.playAtRate(rate)
            },

            /**
             * @ngdoc method
             * @name videoAPI#pause
             * @description
             * Pause a video
             */
            pause(): void {
                player.pause()
            },

            /**
             * @ngdoc method
             * @name videoAPI#play
             * @description
             * Start playback
             *
             * Playback rate is always set to 1x unless preservePlaybackRate option is true.
             */
            play: player.play.bind(player),

            /**
             * @ngdoc method
             * @name videoAPI#convertLaxSecondToFrameSecond
             * @description
             * Convert a possibly imprecise seconds number to an exact frame second.
             * By imprecise, we meen seconds that can potentionally not match an exact frame.
             * In that case, we get the first frame _before_ the given second + halfFrameEpsion.
             *
             * @param seconds Float second
             * @param [shouldThrow=true] should throw if the player is not yet loaded
             *
             * @return The exact second position of the frame
             */
            convertLaxSecondToFrameSecond(seconds: number, shouldThrow = true): number {
                videoAPI.assertPlayerLoaded(shouldThrow)

                return videoAPI.convertFrameToSeconds(
                    videoAPI.convertLaxSecondToFrame(seconds, shouldThrow)
                )
            },

            convertSecondsToTape(seconds: number): number {
                const tl = player.getTimeline()
                return tl.getTapeBySec(seconds)
            },

            convertFrameToTape(frame: number): number {
                const tl = player.getTimeline()
                return tl.getTapeByFrame(frame)
            },

            /**
             * @ngdoc method
             * @name videoAPI#convertLaxSecondToFrame
             * @description
             * Convert a possibly imprecise seconds number to a frame.
             * By imprecise, we meen seconds that can potentionally not match an exact frame.
             * In that case, we get the first frame _before_ the given second + halfFrameEpsion.
             *
             * @param seconds Float second
             * @param [shouldThrow=true] should throw if the player is not yet loaded
             *
             * @return The frame for the given second
             */
            convertLaxSecondToFrame(seconds: string | number, shouldThrow = true): number {
                videoAPI.assertPlayerLoaded(shouldThrow)

                return player
                    .getTimeline()
                    .getFrameBySec(
                        parseFloat('' + seconds) + videoAPI.computeHalfFrameEpsilon(shouldThrow)
                    )
            },

            /**
             * @ngdoc method
             * @name videoAPI#convertFrameToSeconds
             * @description
             * Convert an exact frame to exact frame second.
             *
             * @param frameNumber The frame number
             * @param [shouldThrow=true] should throw if the player is not yet loaded
             *
             * @return Exact second position of the frame
             */
            convertFrameToSeconds(frameNumber: number, shouldThrow = true): number {
                videoAPI.assertPlayerLoaded(shouldThrow)

                const seconds = tryCatch<() => number>(
                    player.getTimeline().getSecByFrame,
                    player.getTimeline(),
                    [frameNumber]
                )

                if (seconds === errorObject) {
                    // getSecByFrame throws if given a frame beyond
                    // the video duration, so we just return that
                    return videoAPI.getFrameAccurateDuration() as number
                } else {
                    return seconds as number
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#computeHalfFrameEpsilon
             * @description
             * Because seconds can be represented as floats, we need to an epsilon value
             * when comparing seconds. For the purposes of converting seconds to frames
             * and vice-versa, a usful epsilon would be the time of half a frame in seconds.
             *
             * This function will compute a half frame for the current video if `shouldThrow`
             * is `true`, or will return a value for 24fps if the video is not yet loaded and
             * `shouldThrow` is `false`
             *
             * @param [shouldThrow=true] should throw if the player is not yet loaded
             */
            computeHalfFrameEpsilon(shouldThrow = true): number {
                const EPSILON_PERCENT_OF_FRAME = 0.4

                if (shouldThrow) {
                    videoAPI.assertPlayerLoaded(true)
                } else if (!videoAPI.assertPlayerLoaded()) {
                    // default to 24fps half frame epsilon
                    return (1 / 24) * EPSILON_PERCENT_OF_FRAME
                } else if (_computedHalfFrameEpsilon) {
                    return _computedHalfFrameEpsilon
                }

                const timeline = player.getTimeline()
                const timescale = timeline.getTimeScale()
                const frameDuration = timeline.getTvByFrame(1) - timeline.getTvByFrame(0)
                const framerate = timescale / frameDuration

                _computedHalfFrameEpsilon = (1 / framerate) * EPSILON_PERCENT_OF_FRAME

                return _computedHalfFrameEpsilon
            },

            /**
             * @ngdoc method
             * @name videoAPI#getFrameRate
             * @description
             * Get the loaded video frame rate, or return a default frame rate of 23.976
             *
             * @param [shouldThrow=true] should throw if the player is not yet loaded
             * @return video frame rate
             */
            getFrameRate(shouldThrow = true): number {
                if (shouldThrow) {
                    videoAPI.assertPlayerLoaded(true)
                } else if (!videoAPI.assertPlayerLoaded()) {
                    return 24000.0 / 1001
                }
                const timeline = player.getTimeline()
                const timescale = timeline.getTimeScale()
                const frameDuration = timeline.getTvByFrame(1) - timeline.getTvByFrame(0)
                const framerate = timescale / frameDuration

                return framerate
            },

            /**
             * @ngdoc method
             * @name videoAPI#assertPlayerLoaded
             * @description
             * Assert if the video player has loaded. Can be used as a simple check, or
             * as an invariant if `shouldThrow` is `true`.
             *
             * @param [shouldThrow=false] Throw exception if player not loaded
             */
            assertPlayerLoaded(shouldThrow = false): boolean {
                if (videoAPI.$destroyed === true) {
                    return false
                }

                if (_playerLoadedFlag) {
                    return true
                }

                const hasDuration = tryCatch<() => number>(player.getDurationSec, player)
                if (hasDuration === errorObject) {
                    if (shouldThrow) {
                        throw new Error('VideoGorillas not yet loaded')
                    } else {
                        return false
                    }
                }

                if (hasDuration) {
                    const hasTimeline = tryCatch(player.getTimeline, player)
                    if (hasTimeline === errorObject) {
                        if (shouldThrow) {
                            throw new Error('VideoGorillas not yet loaded')
                        } else {
                            return false
                        }
                    }
                }

                _playerLoadedFlag = !!hasDuration

                return _playerLoadedFlag
            },

            /**
             * @ngdoc method
             * @name videoAPI#setDropFramesMode
             * @description
             * Sets dropframe mode of the timeline to true/false
             */
            setDropFramesMode(val: boolean) {
                const timeline = player.getTimeline().timeline
                if (timeline.timecode) {
                    timeline.timecode.dropFrame = val
                } else if (timeline.tapeTimecode) {
                    timeline.tapeTimecode.dropFrame = val
                }
            },

            /**
             * @ngdoc method
             * @name videoAPI#destroy
             * @description
             * Mark the video API as destroyed. Subsequent calls to {videoAPI.assertPlayerLoaded}
             * will return `false`.
             */
            destroy() {
                videoAPI.$destroyed = true
            },
        }

        return videoAPI

        function tryCatch<Fn extends (...args: any[]) => any = (...args: any[]) => any, Ctx = any>(
            fn: Fn,
            ctx: Ctx,
            args: any[] = []
        ): ReturnType<Fn> | typeof errorObject {
            try {
                return fn.apply(ctx, args)
            } catch (e) {
                errorObject.value = e as Error
                return errorObject
            }
        }
    }
}

export type VideoAPIInstance = ReturnType<ReturnType<typeof VideoAPIBuilderFactory>['build']>
