export enum CommercialBlackPairLabel {
    FFCB = 'FFCB',
    LFCB = 'LFCB',
}

export const FIXED_POINT_CANDIDATE_INSERTION_LABEL = 'FPCI'
export const SUPER_FIXED_POINT_CANDIDATE_INSERTION_LABEL = 'SFPCI'

export enum ContentMarkerType {
    FFCB = 'FFCB',
    LFCB = 'LFCB',
    FPCI = 'FPCI',
}

export enum CONTENT_MARKER_STATUS {
    APPROVED = 'approved',
    REJECTED = 'rejected',
    UNKNOWN = 'unknown',
}

export const CONTENT_MARKER_BLACKLIST_PROPERTIES = ['$$isSelected', 'frameNumber']

export const QUALIFICATION_DATA = ['chapter', 'video', 'audio', 'story', 'rating', 'comment']
