import { ResultsModuleInstance } from 'services/ResultsModule.factory'

export default /* @ngInject */ function ExportDownloadCtrl(this: unknown, exportModel: any) {
    const vm = this as { exportModel: any }

    vm.exportModel = exportModel

    return vm
}

ExportDownloadCtrl.controllerAs = 'vm'
ExportDownloadCtrl.resolve = {
    exportToken: /* @ngInject */ ($stateParams: ng.ui.IStateParamsService) =>
        $stateParams.exportToken,
    exportModel: /* @ngInject */ (ResultsModule: ResultsModuleInstance, exportToken: string) =>
        ResultsModule.exportGet(exportToken),
}
