import { buildGetAnswerValue } from '../services/annotation/buildGetAnswerValue'

const SORT_NO_SORT = '$noSort'
const SORT_TIMESTAMP = '$timestamp'

const annotationSortingComponent = {
    controller: annotationSortingController,
    bindings: {
        questions: '<',
        predicate: '=',
    },
    template: `
        <section class="d-flex justify-content-between align-items-center">
            <span class="mr-3 text-nowrap">Sorted by </span>

            <div class="chosen-holder">
                <select chosen ng-model="$ctrl.sortingState" class="w-100">
                    <option value="${SORT_NO_SORT}">Newest on Top</option>
                    <option value="${SORT_TIMESTAMP}">Timestamp</option>

                    <option ng-repeat="question in ::$ctrl.questions track by question.uri"
                        value="{{ ::question.uri }}"
                    >{{ ::question.placeholder }}</option>
                </select>
            </div>
        </section>
    `,
}

export default annotationSortingComponent

interface AnnotationSortingControllerInstance extends ng.IComponentController {
    // bindings
    questions: Question[]
    predicate: string | ReturnType<typeof buildGetAnswerValue>
    // vars
    sortingState: string
}

/* @ngInject */
function annotationSortingController(this: unknown, $scope: ng.IScope) {
    const $ctrl = this as AnnotationSortingControllerInstance

    $ctrl.$onInit = $onInit

    return $ctrl

    /////////////////////////////////////////////////////////////////

    function $onInit() {
        // start with no sort
        $ctrl.sortingState = SORT_NO_SORT

        $scope.$watch('$ctrl.sortingState', (sortingState: string) => {
            if (sortingState === SORT_NO_SORT) {
                $ctrl.predicate = ''
            } else if (sortingState === SORT_TIMESTAMP) {
                $ctrl.predicate = 'timestamp'
            } else {
                const questionUri = sortingState
                // so we make the predicate a getter function that will return the label
                // of the answer for this question for an annotation
                $ctrl.predicate = buildGetAnswerValue({ uri: questionUri })
            }
        })
    }
}
