import _ from 'lodash'
import { newRelic } from 'util/newRelic'

export default /* @ngInject */ function orderByArrayFilter($log: ng.ILogService) {
    return (elements: any[], orderArray: string[], key: string, makeSparse = false) => {
        if (!_.isArray(elements)) {
            throw Error(
                [
                    'orderByArray filter requires an array input, given:',
                    angular.toJson(elements),
                ].join('')
            )
        }

        if (!_.isArray(orderArray)) {
            throw Error(
                [
                    'orderByArray filter requires an array as first param, given: ',
                    angular.toJson(orderArray),
                ].join('')
            )
        }

        if (!_.isString(key)) {
            throw Error(
                [
                    'orderByArray filter requires a string as second param, given: ',
                    angular.toJson(key),
                ].join('')
            )
        }

        // when we're given an empty order array, do nothing
        if (orderArray.length === 0) {
            return elements
        }

        if (makeSparse) {
            if (elements.length > orderArray.length) {
                $log.warn(
                    'orderByArray got more elements than order options, with makeSparse: true',
                    {
                        elements,
                        orderArray,
                        key,
                    }
                )

                // something is afoot, but we probably don't want to break the whole app here
                // so instead we'll send a New Relic report and return the elements unchanged
                newRelic.noticeError('orderByArray got arrays of differing length', {
                    elements,
                    orderArray,
                    key,
                })

                return elements
            }

            elements.length = orderArray.length
        }

        if (elements.length !== orderArray.length) {
            $log.warn('orderByArray got arrays of differing length', { elements, orderArray, key })

            // something is afoot, but we probably don't want to break the whole app here
            // so instead we'll send a New Relic report and return the elements unchanged
            newRelic.noticeError('orderByArray got arrays of differing length', {
                elements,
                orderArray,
                key,
            })

            return elements
        }

        const ordered = []

        for (let i = 0; i < orderArray.length; i++) {
            ordered.push(
                _.find(elements, (element) => {
                    return _.get(element, key) === orderArray[i]
                })
            )
        }

        if (ordered.length !== elements.length) {
            throw new Error('orderByArrayFilter missmatching resulting array lengths')
        }

        return ordered
    }
}
