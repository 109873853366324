export default function minutesAsDaysInputDirective() {
    const directive = {
        restrict: 'A',
        require: 'ngModel',
        link: minutesAsDaysInputLinkFn as ng.IDirectiveLinkFn,
    }

    return directive

    /////////////////////////////////////

    function minutesAsDaysInputLinkFn(
        scope: ng.IScope,
        element: ng.IAugmentedJQuery,
        attrs: ng.IAttributes,
        ngModel: ng.INgModelController
    ) {
        ngModel.$validators.min = (modelValue) => {
            return modelValue && modelValue >= 2880
        }

        ngModel.$parsers.push((v: number) => v * 60 * 24)
        ngModel.$formatters.push((v: number) => v / 60 / 24)
    }
}
