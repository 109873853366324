import PopupVideoCtrl from './PopupVideo.ctrl'

export default /* @ngInject */ function videRoutesConfig($stateProvider: ng.ui.IStateProvider) {
    // define qa states
    $stateProvider
        .state('video', {
            url: '/video',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('video.popup', {
            url: '/popup/:id',
            templateUrl: 'js/video/video.popup.html',
            data: {
                navHidden: true,
                footerHidden: true,
            },
            controllerAs: PopupVideoCtrl.controllerAs,
            controller: PopupVideoCtrl,
            resolve: PopupVideoCtrl.resolve,
        })
}
