import _ from 'lodash'
import invariant from 'util/invariant'
import fp from 'lodash/fp'
import ReduxActions from 'services/ReduxActions'
import ReduxUndo from 'redux-undo'
import { combineReducers } from 'redux'

const require = ReduxActions.require

const HighlightActionCreatorsBase = ReduxActions.createActions({
    HIGHLIGHTS: {
        INIT: require('highlights', 'videoApi'),
        CREATE: require('frameNumber'),
        CREATE_PROVISIONAL: require('frameNumber'),
        COMPLETE_PROVISIONAL: require('frameNumber'),
        CANCEL_PROVISIONAL: undefined,

        DELETE_BY_ID: require('id'),

        MOVE_START_FRAME: require('id', 'offsetFrames'),
        MOVE_END_FRAME: require('id', 'offsetFrames'),
        MOVE_FRAME: require('id', 'frameName', 'offsetFrames'),

        SELECT_BY_ID: require('id'),
        SELECT_NEXT: require('currentFrame'),
        SELECT_PREVIOUS: require('currentFrame'),
        SELECT_BY_OFFSET: require('offset', 'currentFrame'),
        SELECT_BY_INDEX: require('index'),

        MERGE_SELECTED: undefined,
    },
    HIGHLIGHTS_STATE: {
        UPDATE: require('highlightsState'),
    },
})

export /* @ngInject */ function HighlightActionCreatorsFactory() {
    const HighlightActionCreators = HighlightActionCreatorsBase.highlights

    return HighlightActionCreators
}

export /* @ngInject */ function HighlightActionsFactory($ngRedux, HighlightActionCreators) {
    return ReduxActions.bindActionCreators(HighlightActionCreators, $ngRedux.dispatch)
}

export /* @ngInject */ function HighlightsStateActionCreatorsFactory() {
    const HighlightsStateActionCreators = HighlightActionCreatorsBase.highlightsState

    return HighlightsStateActionCreators
}

export /* @ngInject */ function HighlightsStateActionsFactory(
    $ngRedux,
    HighlightsStateActionCreators
) {
    return ReduxActions.bindActionCreators(HighlightsStateActionCreators, $ngRedux.dispatch)
}

export /* @ngInject */ function CombinedHighlightsReducerFactory(
    HighlightsReducer,
    HighlightsStateReducer,
    HighlightActionCreators
) {
    const undoableHighlights = ReduxUndo(HighlightsReducer, {
        filter: function (action, currentState, previousHistory) {
            if (action.type === HighlightActionCreators.create.toString()) {
                // We want to filter out the creation of a provisional highlight, but not the creation
                // of the full complete highlight. To do that, we just check if the current state has
                // a provisional highlight. If yes - then we want to not add this to history.
                const hasProvisional = !!_.find(_.get(currentState, 'highlights'), {
                    $$isProvisional: true,
                })
                return !hasProvisional
            }

            // deselect actions should not be added to the history
            if (
                action.type === HighlightActionCreators.selectById.toString() &&
                action.payload.id === null
            ) {
                return false
            }

            // do not add unchanged highlights to history
            if (currentState === previousHistory.present) {
                return false
            }

            return true
        },
    })

    const combinedReducer = combineReducers({
        undoableHighlights,
        highlightsState: HighlightsStateReducer,
    })

    // Hack around needing to preserve $$globalId uptick after merge undo.
    // We end up inflating $$globalId more than needed, but that isn't an
    // issue for our purposes.
    return (state, action = {}) => {
        let $$globalId
        if (action.type === '@@redux-undo/UNDO') {
            $$globalId = _.get(state, 'undoableHighlights.present.$$globalId')
        }

        const res = combinedReducer(state, action)

        if ($$globalId) {
            _.set(res, 'undoableHighlights.present.$$globalId', $$globalId)
        }

        return res
    }
}

export /* @ngInject */ function HighlightsStateReducerFactory(HighlightsStateActionCreators) {
    const actions = HighlightsStateActionCreators

    const HighlightsStateReducer = ReduxActions.handleActions(
        {
            [actions.update]: (_state, { payload: { highlightsState } }) => {
                return angular.copy(highlightsState)
            },
        },
        []
    )

    return HighlightsStateReducer
}

export /* @ngInject */ function HighlightsReducerFactory(HighlightActionCreators) {
    const actions = HighlightActionCreators

    const HighlightsReducer = ReduxActions.handleActions(
        {
            [actions.init]: (state, { payload: { highlights, videoApi } }) => {
                return {
                    ...state,
                    highlights: normalizeExternalInput(highlights, videoApi),
                    $$globalId: findMaxId(highlights),
                }
            },

            [actions.create]: (state, { payload: { frameNumber } }) => {
                const provisionalHighlightExists = !!_.find(state.highlights, {
                    $$isProvisional: true,
                })
                if (!provisionalHighlightExists) {
                    return HighlightsReducer(
                        state,
                        actions.createProvisional({
                            frameNumber,
                        })
                    )
                } else {
                    return HighlightsReducer(
                        state,
                        actions.completeProvisional({
                            frameNumber,
                        })
                    )
                }
            },

            [actions.createProvisional]: (state, { payload: { frameNumber } }) => {
                const originalHighlights = deselectHighlights(
                    normalizeHighlights(state.highlights || [])
                )
                const $$globalId = (state.$$globalId || findMaxId(originalHighlights)) + 1
                const provisionalHighlight = {
                    startFrame: frameNumber,
                    endFrame: frameNumber,
                    id: $$globalId,
                    $$isProvisional: true,
                    $$isSelected: true,
                    $$wasSingleSelected: true,
                }

                const highlights = normalizeHighlights(
                    [provisionalHighlight].concat(originalHighlights)
                )
                return {
                    ...state,
                    $$globalId,
                    highlights,
                }
            },

            [actions.completeProvisional]: (state, { payload: { frameNumber } }) => {
                const provisionalHighlight = _.find(state.highlights, {
                    $$isProvisional: true,
                })
                if (!provisionalHighlight) {
                    return state
                }

                const startFrame = Math.min(provisionalHighlight.startFrame, frameNumber)
                const endFrame = Math.max(provisionalHighlight.endFrame, frameNumber)

                // a create action on the same frame means "remove"
                if (startFrame === endFrame) {
                    return {
                        ...state,
                        highlights: _.without(state.highlights, provisionalHighlight),
                    }
                }

                const newHighlight = {
                    ...provisionalHighlight,
                    startFrame,
                    endFrame,
                }
                delete newHighlight.$$isProvisional

                const resizedHighlights = _.map(
                    _.without(state.highlights, provisionalHighlight),
                    (highlight) => {
                        if (
                            _.inRange(
                                highlight.startFrame,
                                newHighlight.startFrame,
                                newHighlight.endFrame
                            )
                        ) {
                            return {
                                ...highlight,
                                startFrame: newHighlight.endFrame + 1,
                            }
                        }

                        if (
                            _.inRange(
                                highlight.endFrame,
                                newHighlight.startFrame,
                                newHighlight.endFrame
                            )
                        ) {
                            return {
                                ...highlight,
                                endFrame: newHighlight.startFrame - 1,
                            }
                        }

                        return highlight
                    }
                )

                const highlights = normalizeHighlights(resizedHighlights.concat(newHighlight))

                state = {
                    ...state,
                    highlights,
                }

                // select the completed highlight
                return HighlightsReducer(
                    state,
                    actions.selectById({
                        id: newHighlight.id,
                    })
                )
            },

            [actions.cancelProvisional]: (state) => {
                const provisionalHighlight = _.find(state.highlights, {
                    $$isProvisional: true,
                })
                const provisionalHighlightId = provisionalHighlight && provisionalHighlight.id

                return HighlightsReducer(
                    state,
                    actions.deleteById({
                        id: provisionalHighlightId,
                    })
                )
            },

            [actions.deleteById]: (state, { payload: { id } }) => {
                const highlight = _.find(state.highlights, {
                    id,
                })

                return {
                    ...state,
                    highlights: _.without(state.highlights, highlight),
                }
            },

            [actions.moveStartFrame]: (state, { payload: { id, offsetFrames } }) => {
                return HighlightsReducer(
                    state,
                    actions.moveFrame({
                        id,
                        offsetFrames,
                        frameName: 'startFrame',
                    })
                )
            },

            [actions.moveEndFrame]: (state, { payload: { id, offsetFrames } }) => {
                return HighlightsReducer(
                    state,
                    actions.moveFrame({
                        id,
                        offsetFrames,
                        frameName: 'endFrame',
                    })
                )
            },

            [actions.moveFrame]: (state, { payload: { id, offsetFrames, frameName } }) => {
                invariant(
                    _.includes(['startFrame', 'endFrame'], frameName),
                    `HighlightActions.moveFrame() frameName param must be one of "startFrame", "endFrame", but "%s" was given.`,
                    frameName
                )

                const idx = _.findIndex(state.highlights, {
                    id,
                })
                if (idx === -1) {
                    return {
                        ...state,
                    }
                }

                // create highlight copy with new frame data
                const highlight = {
                    ...state.highlights[idx],
                    [frameName]: state.highlights[idx][frameName] + offsetFrames,
                }

                // if highlight no longer has the same start/end frame, it's no longer provisional
                if (highlight.$$isProvisional && highlight.startFrame !== highlight.endFrame) {
                    delete highlight.$$isProvisional
                }

                // create copy of highlights array with updated highlight
                const tempHighlights = state.highlights.slice()
                tempHighlights.splice(idx, 1, highlight)
                const highlights = normalizeHighlights(tempHighlights)

                return {
                    ...state,
                    highlights,
                }
            },

            [actions.selectById]: (state, { payload: { id } }) => {
                id = _.isArray(id) ? id : [id]

                const index = _.filter(state.highlights, (highlight) =>
                    _.includes(id, highlight.id)
                ).map((highlight) => state.highlights.indexOf(highlight))
                return HighlightsReducer(
                    state,
                    actions.selectByIndex({
                        index,
                    })
                )
            },

            [actions.selectNext]: (state, { payload: { currentFrame } }) => {
                return HighlightsReducer(
                    state,
                    actions.selectByOffset({
                        currentFrame,
                        offset: 1,
                    })
                )
            },

            [actions.selectPrevious]: (state, { payload: { currentFrame } }) => {
                return HighlightsReducer(
                    state,
                    actions.selectByOffset({
                        currentFrame,
                        offset: -1,
                    })
                )
            },

            [actions.selectByOffset]: (state, { payload: { offset, currentFrame } }) => {
                let selectedIndexes = _.filter(state.highlights, {
                    $$isSelected: true,
                }).map((highlight) => state.highlights.indexOf(highlight))

                // When there is no initial selection, mark the closest available highlight as selected.
                // The algorithm here is kind of messy and complicated, but it seems to work.
                // It doesn't assume that highlights are ordered.
                // Maybe if it did it would be a bit less messy
                if (selectedIndexes.length === 0 && offset !== 0) {
                    let distance = Number.POSITIVE_INFINITY
                    let initialHighlight
                    _.forEach(state.highlights, (candidate) => {
                        // discard candidates that are behind or ahead of where we're trying to select
                        if (
                            (offset < 0 && candidate.startFrame > currentFrame) ||
                            (offset > 0 && candidate.endFrame < currentFrame)
                        ) {
                            return
                        }

                        // calculate distence to current candidate
                        const candidateDistance =
                            offset > 0
                                ? Math.abs(currentFrame - candidate.startFrame)
                                : Math.abs(currentFrame - candidate.endFrame)

                        // if candidate distance is less than last distance, this is our
                        // new initial highlight
                        if (candidateDistance < distance) {
                            distance = candidateDistance
                            initialHighlight = candidate
                        }
                    })

                    const idx = state.highlights.indexOf(initialHighlight)
                    selectedIndexes = idx === -1 ? [] : [idx]
                    offset = offset + (offset === 0 ? 0 : offset > 0 ? -1 : 1)
                }

                const selectionBoundry = selectedIndexes.length
                    ? offset > 0
                        ? selectedIndexes[selectedIndexes.length - 1]
                        : selectedIndexes[0]
                    : offset > 0
                    ? -1
                    : 0

                let selectedIndex = (selectionBoundry + offset) % state.highlights.length
                if (selectedIndex < 0) {
                    selectedIndex = state.highlights.length + selectedIndex
                }

                return HighlightsReducer(
                    state,
                    actions.selectByIndex({
                        index: selectedIndex,
                    })
                )
            },

            [actions.selectByIndex]: (state, { payload: { index } }) => {
                index = _.isArray(index) ? index : [index]

                const deselectedState = {
                    $$isSelected: false,
                    $$wasSingleSelected: false,
                }
                const selectedState = {
                    $$isSelected: true,
                }
                if (index.length === 1) {
                    selectedState.$$wasSingleSelected = true
                }

                const highlights = _.map(state.highlights, (highlight, idx) => {
                    if (_.includes(index, idx)) {
                        highlight = {
                            ...highlight,
                            ...selectedState,
                        }
                    } else {
                        highlight = {
                            ...highlight,
                            ...deselectedState,
                        }
                    }

                    return highlight
                })

                // return unchanged when no modification made
                if (_.isEqual(highlights, state.highlights)) {
                    return state
                }

                return {
                    ...state,
                    highlights,
                }
            },

            [actions.mergeSelected]: ({ highlights, $$globalId, ...state }) => {
                const selected = _.filter(highlights, {
                    $$isSelected: true,
                })
                if (selected.length > 0) {
                    const mergedHighlight = {
                        id: ++$$globalId,
                        startFrame: _.min(_.map(selected, 'startFrame')),
                        endFrame: _.max(_.map(selected, 'endFrame')),
                        $$isSelected: true,
                        $$wasSingleSelected: true,
                    }
                    highlights = normalizeHighlights(
                        [mergedHighlight].concat(_.without(highlights, ...selected))
                    )
                }

                return {
                    ...state,
                    highlights,
                    $$globalId,
                }
            },
        },
        {
            highlights: [],
        }
    )

    return HighlightsReducer
}

function normalizeExternalInput(highlights = [], videoApi) {
    highlights = angular.copy(highlights)

    _.forEach(highlights, function (highlight) {
        if (!highlight.id) {
            highlight.id = findMaxId(highlights) + 1
        }

        if (_.isUndefined(highlight.startFrame) || _.isUndefined(highlight.endFrame)) {
            _.assign(highlight, {
                startFrame: videoApi.convertLaxSecondToFrame(highlight.start),
                endFrame: videoApi.convertLaxSecondToFrame(highlight.end),
            })
        }
    })

    return normalizeHighlights(highlights)
}

function normalizeHighlights(highlights = []) {
    highlights = _.sortBy(angular.copy(highlights), 'startFrame')

    let lastHighlighStartFrame
    _.forEachRight(highlights, function (highlight) {
        if (lastHighlighStartFrame) {
            highlight.endFrame = Math.min(highlight.endFrame, lastHighlighStartFrame - 1)
        }

        lastHighlighStartFrame = highlight.startFrame
    })

    highlights = _.filter(highlights, function (highlight) {
        // prune negative frames
        if (highlight.startFrame < 0 || highlight.endFrame < 0) {
            return false
        }

        // must have positive length
        if (!highlight.$$isProvisional) {
            return highlight.endFrame - highlight.startFrame > 1
        }

        return true
    })

    return highlights
}

function findMaxId(highlights) {
    return fp.flow(fp.map('id'), fp.max)(highlights) || 0
}

function deselectHighlights(highlights, removeSingleSelect = true) {
    const deselectedState = {
        $$isSelected: false,
    }

    if (removeSingleSelect) {
        deselectedState.$$wasSingleSelected = false
    }

    return _.map(highlights, (highlight) => {
        return {
            ...highlight,
            ...deselectedState,
        }
    })
}
