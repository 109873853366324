export default {
    bindings: {
        description: '<',
        taskDescription: '<',
        fps: '<',
    },
    template: `
        <section class="video-description">
            <h4>
                {{ ::$ctrl.description.shortName || $ctrl.description.videoName }}
            </h4>

            <h5 ng-if="::$ctrl.description.episode_title">
                Episode: {{ ::$ctrl.description.episode_title }}
            </h5>

            <h5 ng-if="::$ctrl.description.programmingType">
                Type: {{ ::$ctrl.description.programmingType | titleCase }}
            </h5>

            <h5 ng-if="::$ctrl.description.programmingSubType">
                Sub Type: {{ ::$ctrl.description.programmingSubType | titleCase }}
            </h5>

            <h6 ng-if="::$ctrl.description.originalPremiereDate">
                Premiere Date: <tz-date no-timezone date="::$ctrl.description.originalPremiereDate"></tz-date>
            </h6>
            <h6 ng-if="!!$ctrl.fps">Frame Rate: {{::$ctrl.fps}} fps</h6>
        </section>

        <section ng-if="::$ctrl.taskDescription"
            class="video-description video-description-right"
        >
            <p class="small text-right">Description: {{ ::$ctrl.taskDescription }}</p>
        </section>
    `,
}
