import _ from 'lodash'
import browserInfo from 'browser-info'
import Storage from 'services/Storage'
import { IHttpResponse } from 'angular'

interface IRequestDebugStorage {
    method: string
    url: string
    status: number
    requestData: string
    responseData: string
    date: string
}

interface IResolveDebugStorage {
    url: string
    name: string
    data: any
    resolve: any
    date: string
}

interface IUser {
    avatar?: string
    roles: TRole[]
    email: string
    fullname: string
    logged: boolean
    password_reset_mode: boolean
    powers: string[]
    preferences: any
    success: boolean
    timezone: string
    username: string
    groups: { label: string; uri: string }[]
}

interface IUserCached {
    authenticated: boolean
    activeRoles: TRole[] | null | boolean
    roles: TRole[]
    avatar?: string
    email?: string
    fullname?: string
    logged?: boolean
    password_reset_mode?: boolean
    powers?: string[]
    preferences?: any
    success?: boolean
    timezone?: string
    username?: string
}

export interface IInternalUserData extends IUserCached {
    browser?: TBrowserInfo
    url?: string
    route?: string
    recentRequests?: IRequestDebugStorage[]
    recentResolves?: IResolveDebugStorage[]
    videoPlayerDebugData?: string | null
    groups?: string[]
    type?: string | null
    date?: string
    set_on_hold?: boolean
}

interface INewRelicUserData extends IUserCached {
    recentRequests?: IRequestDebugStorage[]
    recentResolves?: IResolveDebugStorage[]
    videoPlayerDebugData?: string | null
}

/*eslint angular/json-functions: "off"*/

/**
 * @ngdoc object
 * @name UserObject
 * @module map3.auth
 *
 * @description
 * A plain object that represents the current user.
 *
 * A {@link UserObject} will have some guaranteed fields + whatever the backend sends
 *
 * @property {boolean} authenticated Is currently authenticated?
 * @property {Array<string>} roles The backend-set roles
 * @property {Array<string>} activeRoles The frontend-set roles (eg if the user
 *                           has multiple roles and was limited to some of them)
 */

/**
 * @ngdoc service
 * @name User
 * @module map3.auth
 *
 * @description
 * User Service
 *
 * A service for authenticating, login, logout, session refresh and more
 *
 */
export const SESSION_STORAGE_ACTIVE_ROLES = 'map3_user_active_roles'

export default /* @ngInject */ function UserFactory(
    USER_ROLE_ADMIN: TRole,
    USER_SUPERPOWER_SUPER_ADMIN: string,
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    $rootScope: TMap3RootScope,
    $http: ng.IHttpService,
    $q: ng.IQService
) {
    const SESSION_STORAGE_KEYS = [SESSION_STORAGE_ACTIVE_ROLES]

    const USER_DEFAULTS = {
        authenticated: false,
        roles: [],
        activeRoles: false,
    }

    const REQUEST_DEBUG_STORAGE: IRequestDebugStorage[] = []
    const REQUEST_DEBUG_STORAGE_LIMIT = 10

    const STATE_RESOLVE_DEBUG_STORAGE: IResolveDebugStorage[] = []
    const STATE_RESOLVE_DEBUG_STORAGE_LIMIT = 10

    const currentUser: IUserCached = angular.copy(USER_DEFAULTS)
    let userPromise: any

    const User = {
        /**
         * @ngdoc method
         * @name User#getCurrent
         * @module map3.auth
         *
         * @description
         * Request the current user from the backend
         *
         * If a request was previously made, and {force} is not set, the
         * cached promise is returned
         *
         * A successfull request will authenticate the user
         *
         * @param {boolean} force Force a new request to the backend, even if we have a cached one
         * @return {Promise} UserObject|RejectionReason|$http.response
         *
         * @see User#authenticate()
         */
        getCurrent(force = false) {
            if (!userPromise || force) {
                if (!force && currentUser.authenticated) {
                    userPromise = $q.when(currentUser)
                    return userPromise
                }

                userPromise = $http
                    .get('/api/auth/login', NO_LOAD_OVERLAY)
                    .then((response: IHttpResponse<any>) => {
                        if (response.data.success && response.data.logged) {
                            return User.authenticate(response.data)
                        } else {
                            return $q.reject(response)
                        }
                    })
                    .catch((response) => {
                        User.reset()

                        return $q.reject(response)
                    })
            }

            return userPromise
        },

        /**
         * @ngdoc method
         * @name User#getCurrent
         * @module map3.auth
         *
         * @description
         * Get the currently cached {@link UserObject}
         *
         * @return {UserObject}
         */
        cached() {
            return currentUser
        },

        /**
         * @ngdoc method
         * @name User#isResolved
         * @module map3.auth
         *
         * @description
         * Synchronously check if the User.getCurrent() promise has been resolved
         *
         * @return {Boolean}
         */
        isResolved() {
            return userPromise && userPromise.$$state.status !== 0
        },

        /**
         * @ngdoc method
         * @name User#login
         * @module map3.auth
         *
         * @description
         * Start a new session, logging in the user
         *
         * @param {string} username
         * @param {string} password
         *
         * @return {Promise} UserObject|RejectionReason|$http.response
         *
         * @see User.authenticate()
         */
        login(username: string, password: string) {
            User.reset()

            return User.getCurrent(true).catch(() => {
                return $http
                    .post('/api/auth/login', {
                        username: username,
                        password: password,
                    })
                    .then((response: IHttpResponse<any>) => {
                        if (response.data.success) {
                            return User.authenticate(response.data)
                        } else {
                            return $q.reject(response)
                        }
                    })
            })
        },

        /**
         * @ngdoc event
         * @name map3.auth.User#user.logout
         * @eventType broadcast on root scope
         * @description
         * Broadcast on user logout
         */

        /**
         * @ngdoc method
         * @name User#logout
         * @module map3.auth
         *
         * @description
         * Logs out the user from the backend and resets the UserObject
         *
         * @return {Promise}
         * @see User.reset()
         */
        logout(redirectToMyId = false) {
            return $http
                .post('/api/auth/logout', {})
                .catch(angular.noop)
                .finally(() => {
                    User.reset()
                    const preface = `${process.env.APP_PREFIX}`
                    const tokenKey = `${process.env.JWT_TOKEN_KEY}`
                    localStorage.removeItem(`${preface}:${tokenKey}`)
                    $http.defaults.headers!.common.Authorization = null
                    $rootScope.$broadcast('user.logout')

                    if (redirectToMyId) {
                        window.location.href = process.env.AUTH_URI_OIDC!
                    }
                })
        },

        /**
         * @ngdoc event
         * @name map3.auth.User#user.reset
         * @eventType broadcast on root scope
         * @description
         * Broadcast on user reset
         */

        /**
         * @ngdoc method
         * @name User#logout
         * @module map3.auth
         *
         * @description
         * Reset the internal {@link UserObject} cache to its default state
         *
         * @return {UserObject}
         */
        reset() {
            // delete all cached user fields
            // We use this manually so that we can keep a reference to the same
            // actual JS Object
            _.each(_.keys(currentUser), (key) => {
                delete currentUser[key as keyof IUserCached]
            })

            _.forEach(SESSION_STORAGE_KEYS, (key) => {
                Storage.removeItem(key)
            })

            _.assign(currentUser, USER_DEFAULTS)

            // wipe the cached user promise
            userPromise = undefined

            // broadcast the user session reset event
            $rootScope.$broadcast('user.reset')
            User.broadcastUpdate()

            return currentUser
        },

        /**
         * @ngdoc event
         * @name map3.auth.User#user.authenticate
         * @eventType broadcast on root scope
         * @description
         * Broadcast on user authentification
         *
         * @param {UserObject} currentUser
         */

        /**
         * @ngdoc method
         * @name User#authenticate
         * @module map3.auth
         *
         * @description
         * Set the the cached {@link UserObject#authenticated UserObject#authenticated}
         * property to true and merge with the given `userData`
         *
         * @param {Object} userData
         * @return {UserObject}
         */
        authenticate(userData: IUser) {
            _.assign(currentUser, userData)
            currentUser.authenticated = true

            $rootScope.$broadcast('user.authenticate', currentUser)
            User.broadcastUpdate()

            return currentUser
        },

        /**
         * @ngdoc method
         * @name User#isAuthenticated
         * @module map3.auth
         *
         * @description
         * Check if the currently cached user is authenticated
         *
         * @return {boolean}
         */
        isAuthenticated() {
            return !!currentUser.authenticated
        },

        /**
         * @ngdoc method
         * @name User#getRoles
         * @module map3.auth
         *
         * @description
         * Get the roles of the current user
         *
         * @return {Array}
         */
        getRoles() {
            return User.$getActiveRoles() || User.getRawRoles()
        },

        /**
         * @ngdoc method
         * @name User#$getActiveRoles
         * @module map3.auth
         * @private
         *
         * @description
         * Get active roles, either from session storage or from object cache
         *
         * @return {Array}
         */
        $getActiveRoles(): [TRole] | null {
            return Storage.getItem(SESSION_STORAGE_ACTIVE_ROLES)
        },

        /**
         * @ngdoc method
         * @name User#getRawRoles
         * @module map3.auth
         *
         * @description
         * Get the raw roles of the current user, as set from the backend
         *
         * @return {Array<string>}
         */
        getRawRoles() {
            return _.uniq(currentUser.roles)
        },

        /**
         * @ngdoc method
         * @name User#setActiveRoles
         * @module map3.auth
         *
         * @description
         * Set the active user roles
         * The given roles must be a subset of the user's raw roles.
         *
         * The raw roles are unaffected
         *
         * @param {Array<string>} roles
         * @param {boolean} allowUnknowRoles Allow roles that are not in the user's raw roles to be set
         *
         * @return {UserObject}
         */
        setActiveRoles(roles: TRole[] | false) {
            // verify manual roles don't contain non-existing roles
            const difference = _.difference(roles || [], User.getRawRoles())

            if (difference.length > 0) {
                throw new Error(`Cannot set unknown role for user: ${JSON.stringify(difference)}`)
            }

            Storage.setItem(SESSION_STORAGE_ACTIVE_ROLES, roles)

            User.broadcastUpdate()

            return currentUser
        },

        /**
         * @ngdoc method
         * @name User#setRoleFromState
         * @module map3.auth
         *
         * @description
         * Set one role for the current user that is the first valid role for the given state
         *
         * @param {object} state
         */
        setRoleFromState(state: any) {
            let roles = state && state.data && state.data.roles
            if (roles && _.isArray(roles)) {
                // handle both types of role definitions, normal (AND) and nested (OR)
                // see User.hasRole() for more information
                roles = _.isArray(roles[0]) ? roles[0] : roles
                _.forEach(roles, (role) => {
                    if (User.hasRole(role, false)) {
                        User.setActiveRoles([role])

                        // break foreach
                        return false
                    }
                })
            }
        },

        /**
         * @ngdoc method
         * @name User#hasRole
         * @module map3.auth
         *
         * @description
         * Check if the user has a role or a set of roles
         *
         * Example:
         * ```javascript
         *   User.getRoles() => ['admin', 'qa']
         *
         *   // single role
         *   User.hasRole('admin') => true
         *
         *   // multiple roles with AND
         *   User.hasRole(['admin', 'worker']) => false
         *
         *   // multiple roles with OR
         *   User.hasRole([['admin', 'worker']]) => true
         * ```
         *
         * @param {(Array<string>|string)} roles Roles to check for
         * @param {boolean=} rawCheck `true` Check raw roles, not only active roles
         *
         * @return {boolean}
         */
        hasRole(roles: TRole[] | TRole, rawCheck = true) {
            if (!_.isArray(roles) || !_.isObject(roles)) {
                roles = [roles]
            }

            const userRoles = rawCheck ? User.getRawRoles() : User.getRoles()

            return _.every(roles, (role) => {
                if (!_.isArray(role)) {
                    // simply check if the user has this role
                    return _.includes(userRoles, role)
                } else {
                    // in this case, role is actually an array of roles,
                    // and a match on any of them makes them valid
                    return _.some(role, (andRole) => {
                        return _.includes(userRoles, andRole)
                    })
                }
            })
        },

        /**
         * @ngdoc method
         * @name User#hasActiveRole
         * @module map3.auth
         *
         * @description
         * Check if the user has a currently active role or a set of roles
         *
         * @param {(Array<string>|string)} roles Roles to check for
         * @return {boolean}
         */
        hasActiveRole(roles: TRole) {
            return User.hasRole(roles, false)
        },

        /**
         * @ngdoc method
         * @name User#hasRoleForState
         * @module map3.auth
         *
         * @description
         * Check if the user has the right roles for a particular state.
         *
         * If state with no role requirements was be passed will
         * just return "true"
         *
         * @param {object} state uiRouter.state
         * @param {boolean} rawCheck Check all roles, not only active roles
         * @return {boolean}
         */
        hasRoleForState(state: any, rawCheck = false) {
            if (state && state.data && state.data.roles) {
                return User.hasRole(state.data.roles, rawCheck)
            } else {
                return true
            }
        },

        /**
         * @ngdoc method
         * @name User#hasSuperpower
         * @module map3.auth
         *
         * @description
         * Check if the user has a particular super-power
         *
         * @param {string} superPower
         * @return {boolean}
         */
        hasSuperpower(superPower: string) {
            return _.includes(User.getSuperpowers(), superPower)
        },

        /**
         * @ngdoc method
         * @name User#getSuperpowers
         * @module map3.auth
         *
         * @description
         * Get the list of user super-powers
         *
         * @return {array}
         */
        getSuperpowers() {
            return _.get(currentUser, 'powers', [])
        },

        /**
         * @ngdoc method
         * @name User#isAdmin
         * @module map3.auth
         *
         * @description
         * Shortcut for `User.hasRole('Admin')`
         *
         * @param {boolean} rawCheck `false` Check all roles, not only active roles
         *
         * @return {boolean}
         */
        isAdmin(rawCheck = false) {
            return User.hasRole(USER_ROLE_ADMIN, rawCheck)
        },

        /**
         * @ngdoc method
         * @name User#isSuperAdmin
         * @module map3.auth
         *
         * @description
         * Shortcut for `User.hasSuperpower('super_admin')`
         *
         * @return {boolean}
         */
        isSuperAdmin() {
            return User.hasSuperpower(USER_SUPERPOWER_SUPER_ADMIN)
        },

        /**
         * @ngdoc event
         * @name map3.auth.User#user.update
         * @eventType broadcast on root scope
         * @description
         * Broadcast on user update in meaningful way
         *
         * @param {UserObject} currentUser
         */

        /**
         * @ngdoc method
         * @name User#broadcastUpdate
         * @module map3.auth
         *
         * @description
         * Broadcast a {@link map3.auth.User#user.update user.update} event from $rootScope
         *
         * Useful when one module can change the current user in
         * a meaningful way, and another module must listen for that
         */
        broadcastUpdate() {
            $rootScope.$broadcast('user.update', currentUser)
        },

        /**
         * @ngdoc method
         * @name User#getStorage
         * @module map3.auth
         *
         * @description
         * Get a Storage instance - a wrapper around SessionStorage that can handle
         * arbitrary data types
         *
         * @return {JSONSessionStorage}
         */
        getStorage() {
            return Storage
        },

        /**
         * @ngdoc method
         * @name User#getUserFormattedForNewRelic
         * @module map3.auth
         *
         * @description
         * Format the current user for New Relic, omitting sensitive information
         * like access tokens and adding the mandatory "id" field
         *
         * @return {object}
         */
        getUserFormattedForNewRelic() {
            const BLACKLIST_PROPERTIES = [
                'authenticated',
                'avatar',
                'token',
                'success',
                'logged',
                'preferences',
            ]

            const userData: INewRelicUserData = angular.copy(User.cached())

            userData.recentRequests = REQUEST_DEBUG_STORAGE
            userData.recentResolves = STATE_RESOLVE_DEBUG_STORAGE
            userData.activeRoles = User.$getActiveRoles() || User.getRawRoles()
            userData.videoPlayerDebugData = getVideoPlayerDebugData()

            return _.omit(userData, BLACKLIST_PROPERTIES)
        },

        /**
         * @ngdoc method
         * @name User#getUserDataFormattedForInternal
         * @module map3.auth
         *
         * @description
         * Format the current user for the internal bug reporter
         *
         * @param {object} $state
         * @param {string|null} bugType
         *
         * @return {object}
         */
        getUserDataFormattedForInternal($state: any, bugType: string | null = null) {
            const BLACKLIST_PROPERTIES = [
                'authenticated',
                'avatar',
                'token',
                'success',
                'logged',
                'preferences',
            ]

            const userData: IInternalUserData = angular.copy(User.cached())

            userData.date = new Date().toISOString()
            userData.browser = browserInfo()

            if (_.get($state, 'current.data.showInBugReport')) {
                userData.url = '' + window.location
                userData.route = _.get($state, 'current.name')
            }

            userData.activeRoles = User.$getActiveRoles()
            userData.recentRequests = REQUEST_DEBUG_STORAGE
            userData.recentResolves = STATE_RESOLVE_DEBUG_STORAGE
            userData.groups = _.map(userData.groups, 'label')

            if ('POOR_VIDEO_QUALITY' === bugType) {
                userData.videoPlayerDebugData = getVideoPlayerDebugData()
            }

            userData.type = bugType

            return _.omit(userData, BLACKLIST_PROPERTIES)
        },

        /**
         * @ngdoc method
         * @name User#storeRequestDebugData
         * @module map3.auth
         *
         * @description
         * Store a request object as debug data in case of bug reports
         *
         * @param {object} response
         */

        storeRequestDebugData(response: any) {
            const length = REQUEST_DEBUG_STORAGE.unshift({
                method: _.get(response, 'config.method'),
                url: _.get(response, 'config.url'),
                status: _.get(response, 'status'),
                // attach 2kb of request/response data
                requestData: ('' + JSON.stringify(_.get(response, 'config.data'))).substring(
                    0,
                    2 << 10
                ),
                responseData: ('' + JSON.stringify(_.get(response, 'data'))).substring(0, 2 << 10),
                date: new Date().toISOString(),
            })

            if (length > REQUEST_DEBUG_STORAGE_LIMIT) {
                REQUEST_DEBUG_STORAGE.length = REQUEST_DEBUG_STORAGE_LIMIT
            }
        },

        storeRouteResolveDebugData(currentState: any) {
            const length = STATE_RESOLVE_DEBUG_STORAGE.unshift({
                url: '' + currentState.url,
                name: currentState.name,
                data: currentState.data,
                resolve: _.omit(_.get(currentState, 'locals.resolve.$$values', {}), '$stateParams'),
                date: new Date().toISOString(),
            })

            if (length > STATE_RESOLVE_DEBUG_STORAGE_LIMIT) {
                STATE_RESOLVE_DEBUG_STORAGE.length = STATE_RESOLVE_DEBUG_STORAGE_LIMIT
            }
        },

        matches(user: TUser) {
            return _.get(user, 'username') === currentUser.username
        },
    }

    function getVideoPlayerDebugData() {
        const map3: TMap3Window = window
        const VG = map3.VG
        const Player = VG && VG.players && VG.players.length && VG.players[VG.players.length - 1]
        if (Player && Player.initialized) {
            try {
                // get only the last messages, and not the full debug log
                const lastMessages = _.takeRight(Player.getLog().latestMessages.getAll(), 500)
                return lastMessages.join('\n')
            } catch (e) {}
        }

        return null
    }

    // expose on RootScope
    $rootScope.User = User

    return User
}

export type UserInstance = ReturnType<typeof UserFactory>
