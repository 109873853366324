import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { RoutingAuthInstance } from './RoutingAuth.factory'

export default class LoginCtrl {
    $state: ng.ui.IStateService
    $window: ng.IWindowService
    User: any
    RoutingAuth: RoutingAuthInstance
    Notification: NotificationServiceInstance
    ErrorStringifier: ErrorStringifierInstance
    user: { username?: string; password?: string } = {}
    error: string | false | null = null
    /* @ngInject */
    constructor(
        $state: ng.ui.IStateService,
        $window: ng.IWindowService,
        User: any,
        RoutingAuth: any,
        Notification: NotificationServiceInstance,
        ErrorStringifier: ErrorStringifierInstance
    ) {
        this.$state = $state
        this.$window = $window
        this.User = User
        this.RoutingAuth = RoutingAuth
        this.Notification = Notification
        this.ErrorStringifier = ErrorStringifier
    }

    $onInit() {
        this.activate()
    }

    activate() {
        // check if we have valid session
        this.User.getCurrent()
            .then(() => {
                this.RoutingAuth.authenticationSuccessHandler()
            })
            .catch(() => {
                if (this.RoutingAuth.hasRequestedState()) {
                    this.loginUsingMyId()
                }
            })
    }

    login() {
        this.Notification.hide()

        this.User.login(this.user.username, this.user.password)
            .then(() => {
                this.error = null
                this.RoutingAuth.authenticationSuccessHandler()
            })
            .catch((e: any) => {
                this.error = this.ErrorStringifier.stringify(e)
                this.user.password = ''
            })
    }

    loginUsingMyId() {
        this.$window.location.href = process.env.AUTH_URI_OIDC as string
    }
}
