import _ from 'lodash'

const querySelection = {
    controller: querySelectionController,
    bindings: {
        jsonArray: '=',
        table: '<',
    },
    template: `
    <section class="mb-3">
        <h5 class="mb-3" ng-if="$ctrl.jsonArray.length">
            There {{ $ctrl.jsonArray.length === 1 ? 'is' : 'are' }} {{$ctrl.jsonArray.length}} {{ $ctrl.jsonArray.length === 1 ? 'query' : 'queries' }}:
        </h5>
        <h5 ng-if="!$ctrl.jsonArray.length" class="text-danger">You need to add a query</h5>
        <hr>
        <div ng-repeat="json in $ctrl.jsonArray">
            <section class="d-flex justify-content-between align-items-baseline">
                <p class="mb-0">Query {{$index + 1}}</p>
                <div>
                    <button
                        ng-click="$ctrl.openModal(json, $ctrl.table)"
                        class="btn btn-outline-primary"
                        type="button"
                    >
                        View
                    </button>
                </div>
            </section>
            <hr />
        </div>
    </section>
    `,
}

export default querySelection

interface ScopeBindings extends ng.IComponentController {
    table: any
    jsonArray: string[]
}

/* @ngInject */
function querySelectionController(
    this: unknown,
    $uibModal: ng.ui.bootstrap.IModalService,
    MapDialog: any
) {
    const $ctrl = this as ScopeBindings

    $ctrl.openModal = openModal

    return $ctrl

    //////////////////////////

    function openModal(json: string, table: any) {
        const modalInstance = $uibModal.open({
            template: `
                <div class="no-chosen">
                    <query-builder                        
                        preview-only
                        json="vm.json"
                        table="vm.table"
                    ></query-builder>
                </div>
                <footer class="modal-footer">
                    <button ng-click="$dismiss()" close-modal class="btn" type="button">Back</button>
                    <button
                        ng-click="vm.handleDelete()"
                        class="btn btn-danger"
                        type="submit"
                    >
                        Delete Search
                    </button>
                </footer>
            `,
            controller: /* @ngInject */ function (
                this: unknown,
                $uibModalInstance: ng.ui.bootstrap.IModalInstanceService
            ) {
                const vm: {
                    json: string
                    table: any
                    handleDelete: (json: string) => void
                } = this as any

                vm.json = json
                vm.table = table

                vm.handleDelete = handleDelete

                /////////* ///

                function handleDelete() {
                    const confirmDialog = MapDialog.confirm()
                        .title('Are you sure?')
                        .textContent('This will remove this search and all relevant videos to it.')
                        .ok('Delete')
                        .cancel('Cancel')
                        .okClass('btn-danger')

                    MapDialog.show(confirmDialog).then(() => {
                        $uibModalInstance.close(vm.json)
                    })
                }
            },
            controllerAs: 'vm',
            size: 'xl',
        })

        modalInstance.result.then((json) => {
            const index = _.indexOf($ctrl.jsonArray, json)
            if (index >= 0) {
                $ctrl.jsonArray.splice(index, 1)
            }
        })
    }
}
