export default /* @ngInject */ function spinnerOnLoadDirective() {
    const directive = {
        restrict: 'A',
        link: spinnerOnLoadLinkFn,
    }

    return directive

    ////////////////////

    function spinnerOnLoadLinkFn($scope: angular.IScope, $element: ng.IRootElementService) {
        $element.addClass('image-loading')

        $element.find('img').on('load', function () {
            $element.removeClass('image-loading')
        })
    }
}
