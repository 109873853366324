import _ from 'lodash'
import { ActionCreators } from 'redux-undo'
import { newRelic } from 'util/newRelic'
import { trackButtonClick, setSnowplowContext } from 'util/snowplow'

const commonResolve = {
    videoData: /* @ngInject */ (VideoService, hitID) => VideoService.get(hitID),
    commentThreads: /* @ngInject */ (CommentService, hitID) => CommentService.getThreads(hitID),
}

SplitScenesCtrl.resolveWorker = {
    ...commonResolve,

    assignmentID: /* @ngInject */ ($stateParams) => $stateParams.assignmentID,
    hitID: /* @ngInject */ (task) => task.hitID,
    task: /* @ngInject */ (TaskService, assignmentID) => {
        // for when you need LIGHTSPEED
        // return import('../../sample/workerActsScenesSubScenes.json').then(m => m.default)

        return TaskService.getTask(assignmentID)
    },

    userType: _.constant('worker'),

    BackendInterface: /* @ngInject */ (TaskService, $state) => {
        const WorkerBackendInterface = {
            saveScenes(assignmentID, sceneData, rawSceneData, lastUserAction) {
                return TaskService.saveScenes(assignmentID, sceneData, rawSceneData, lastUserAction)
            },
            saveScenesAndSubmit(assignmentID, sceneData) {
                return TaskService.saveScenesAndSubmit(assignmentID, sceneData)
            },
            setBookmarks(assignmentID, bookmarks) {
                TaskService.setBookmarks(assignmentID, bookmarks)
            },
            navigateToTaskList() {
                $state.go('worker.list')
            },
        }

        return WorkerBackendInterface
    },
}

SplitScenesCtrl.resolveQA = {
    ...commonResolve,

    assignmentID: _.constant(null),
    hitID: /* @ngInject */ ($stateParams) => $stateParams.id,
    task: /* @ngInject */ ($http, hitID) =>
        $http.get(`/api/hit/qa/${hitID}`).then((res) => res.data),

    userType: _.constant('qa'),

    BackendInterface: /* @ngInject */ (
        QAAssignmentService,
        QAReturnToWorkers,
        QAScenesService,
        $state
    ) => {
        const QABackendInterface = {
            saveScenes(hitID, sceneData, rawSceneData, lastUserAction) {
                return QAScenesService.saveScenes(hitID, sceneData, rawSceneData, lastUserAction)
            },
            saveScenesAndSubmit(hitID, sceneData) {
                return QAScenesService.saveScenes(hitID, sceneData).then(() => {
                    return QAScenesService.approve(hitID)
                })
            },
            setBookmarks(hitID, bookmarks) {
                QAAssignmentService.setBookmarks(hitID, bookmarks)
            },
            navigateToTaskList() {
                $state.go('qa.list')
            },
            returnToWorkers(hitID, workers) {
                QAReturnToWorkers.return(hitID, workers)
            },
        }

        return QABackendInterface
    },
}

export default /* @ngInject */ function SplitScenesCtrl(
    $scope,
    $ngRedux,
    cfpLoadingBar,
    SceneActions,
    SceneHelper,
    Notification,
    MapDialog,
    CommentInterface,
    DataEntryNetworkGuard,
    MarkerTransformation,
    GlobalShortcuts,
    SharedVideoAPI,
    BackendInterface,

    userType,
    task,
    hitID,
    assignmentID,
    videoData,
    commentThreads
) {
    const vm = this

    window.vm = vm
    window.BackendInterface = BackendInterface
    vm.userType = userType

    vm.task = task
    vm.video = videoData

    vm.workers = task.workers || false

    vm.confirmSaveAndSubmit = confirmSaveAndSubmit
    vm.returnToWorkers = returnToWorkers

    activate()

    //////////////////////////////////////////

    function activate() {
        $setupComments()

        $setupShortcuts()

        setSnowplowContext('task', task)

        $scope.$on('$destroy', function destroySplitScenesCtrl() {
            setSnowplowContext('task', null)
        })

        SharedVideoAPI.onLoad(handleVideoApiReady)
    }

    function handleVideoApiReady(videoApi) {
        const sceneData = {
            acts: task.params.acts && task.sceneData.acts,
            scenes: task.params.scenes && task.sceneData.scenes,
            subScenes: task.params.sub_scenes && task.sceneData.subScenes,
            rawSceneData: task.sceneData.rawSceneData,
        }

        // in case we cannot use the backend scene data
        const dummyScene = { start: 0, end: videoApi.getDuration() }
        const dummySceneData = {
            acts: task.params.acts && [dummyScene],
            scenes: task.params.scenes && [dummyScene],
            subScenes: task.params.sub_scenes && [dummyScene],
        }

        if (_.isEmpty(sceneData.acts) && _.isEmpty(sceneData.scenes)) {
            // if we have no scenes, setup with dummy scene
            $setupScenes(dummySceneData, videoApi)
        } else if (userType === 'worker' && SceneHelper.isOverAutoSplitterLimit(sceneData.scenes)) {
            // notify New Relic that we're over auto-splitter limit
            newRelic.noticeError(`Autosplitter scene limit exceeded: ${sceneData.scenes.length}`)

            // setup with dummy scene
            $setupScenes(dummySceneData, videoApi)
        } else {
            // normal setup
            $setupScenes(sceneData, videoApi)
        }

        $setupFilmstrip(videoApi)
    }

    function $setupFilmstrip(videoApi) {
        videoApi.getFilmStripDrawer(() => {
            vm.actsFilmstrip = 'disabled'
            vm.scenesFilmstrip = 'disabled'
            vm.subScenesFilmstrip = 'disabled'
        })
    }

    function $setupScenes(sceneData, videoApi) {
        if (sceneData && sceneData.rawSceneData) {
            $ngRedux.dispatch(ActionCreators.clearHistory())
            SceneActions.rawInitWith(sceneData.rawSceneData)
            SceneActions.initWith({ sceneData, videoApi })
        } else {
            SceneActions.initWith({ sceneData, videoApi })
            $ngRedux.dispatch(ActionCreators.clearHistory())
        }

        $setupAutoSave(videoApi)
    }

    function $setupAutoSave(videoApi) {
        let currentValue = selectSceneData($ngRedux.getState())
        const unsubscribe = $ngRedux.subscribe(handleChange)
        $scope.$on('$destroy', unsubscribe)

        ///////////////////////

        function handleChange() {
            const state = $ngRedux.getState()
            const previousValue = currentValue
            currentValue = selectSceneData(state)

            const {
                acts: currentActs,
                scenes: currentScenes,
                subScenes: currentSubScenes,
            } = currentValue

            const {
                acts: previousActs,
                scenes: previousScenes,
                subScenes: previousSubScenes,
            } = previousValue

            // only save the data if one of the persist props has changed
            const isSame =
                SceneHelper.isEqual(currentActs, previousActs) &&
                SceneHelper.isEqual(currentScenes, previousScenes) &&
                SceneHelper.isEqual(currentSubScenes, previousSubScenes)

            if (!isSame) {
                executeSave(currentValue, selectRawSceneData(state), state.lastReduxAction.type)
            }
        }

        const executeSave = DataEntryNetworkGuard.create(
            (immutableSceneData, rawSceneData, lastUserAction) => {
                const promise = BackendInterface.saveScenes(
                    assignmentID || hitID,
                    SceneHelper.serialize(immutableSceneData, videoApi, task.params),
                    rawSceneData,
                    lastUserAction
                )

                Notification.forPromise(promise, 'Progress Saved!')

                return promise
            }
        )
    }

    function $setupComments() {
        vm.commentThreads = commentThreads
        vm.bookmarks = task.bookmarks

        $scope.$watchCollection('vm.commentThreads', updateMarkersList)
        $scope.$watchCollection('vm.bookmarks', updateMarkersList)

        CommentInterface.init({
            hitID,
            threads: vm.commentThreads,
            assignmentID,
        })

        $scope.$onRootScope('map3.addBookmark', addBookmark)
        $scope.$onRootScope('map3.deleteBookmark', deleteBookmark)
        $scope.$on('$destroy', CommentInterface.destroy)
    }

    function updateMarkersList() {
        vm.markersList = _.concat(
            MarkerTransformation.threads(vm.commentThreads, CommentInterface.markerClick),
            MarkerTransformation.bookmarks(vm.bookmarks)
        )
    }

    function addBookmark() {
        const timestamp = vm.videoApi.getCurrentFrameTime()
        vm.bookmarks.push(timestamp)

        BackendInterface.setBookmarks(assignmentID || hitID, vm.bookmarks)
    }

    function deleteBookmark(marker) {
        const index = vm.bookmarks.indexOf(marker.time)
        vm.bookmarks.splice(index, 1)

        BackendInterface.setBookmarks(assignmentID || hitID, vm.bookmarks)
    }

    function $setupShortcuts() {
        const unbindShortcuts = GlobalShortcuts.bind({
            title: 'Scene Splitting Shortcuts',
            shortcuts: [
                {
                    description: 'Read comment again',
                    keyCombo: ['alt+r'],
                    shortcut: 'Alt + R',
                    callback: () => CommentInterface.tryToOpenCommentFromUrlParams(),
                },
            ],
        })
        $scope.$on('$destroy', unbindShortcuts)
    }

    function confirmSaveAndSubmit() {
        const confirmDialog = MapDialog.confirm()
            .title(userType === 'qa' ? 'Approve All' : 'Submit Scene Data')
            .htmlContent(
                userType === 'qa'
                    ? `<strong>Are you sure you want to Approve the task?</strong>
                       <p>
                           When you approve your changes will be stored and you won't be able to edit them anymore.
                       </p>`
                    : `<strong>Are you sure you want to proceed?</strong>
                       <p>
                           When you submit your changes will be stored and you won't be able to edit them anymore.
                       </p>`
            )
            .ok('Yes')
            .cancel('No')

        const promise = MapDialog.show(confirmDialog)
            .then(function () {
                cfpLoadingBar.start()

                const immutableSceneData = selectSceneData($ngRedux.getState())

                trackButtonClick({
                    label: userType === 'qa' ? 'Approve All' : 'Submit Scene Data',
                })

                return BackendInterface.saveScenesAndSubmit(
                    assignmentID || hitID,
                    SceneHelper.serialize(immutableSceneData, vm.videoApi)
                )
            })
            .then(function () {
                BackendInterface.navigateToTaskList()
            })
            .finally(function () {
                cfpLoadingBar.complete()
            })

        Notification.forPromise(promise)
    }

    function returnToWorkers() {
        if (vm.workers) {
            BackendInterface.returnToWorkers(hitID, vm.workers)
        }
    }

    /**
     * Helper for selecting the sceneData from the $ngRedux state
     */
    function selectSceneData(state) {
        return state.sceneData.present
    }

    function selectRawSceneData(state) {
        return state.sceneData
    }
}

SplitScenesCtrl.controllerAs = 'vm'
