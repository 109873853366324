import _ from 'lodash'
/**
 * @ngdoc component
 * @name workerTaskList
 * @module map3.tasks
 * @description
 * <worker-task-list> component is a common render approach for the task list
 * tables for workers. It concentrates all our styling and implementation in one place,
 * allowing us to vary only what we need to: The per-task actions.
 *
 * The component has a required `<actions>` transclude slot. Inside it, a `task` variable
 * representig the object used for the current table row is presented. The `task` object
 * itself can be either a true task, or an assignment. It's up to the code in `<actions>`
 * to handle either case accordingly.
 */
export const workerTaskListComponent = {
    transclude: {
        actions: 'actions',
        selectionActions: '?selectionActions',
    },
    bindings: {
        predicatePerfield: '=',
        predicateOrderBy: '=',
        tasks: '<',
    },
    template: `
        <section class="container-fluid my-3 py-1">
            <header class="row">
                <div class="d-flex align-items-center">
                    <form class="form-inline flex-grow justify-content-between">
                        <input
                            class="form-control"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            ng-model="$ctrl.searchString"
                        />
                    </form>
                </div>
            </header>
        </section>
        <table class="table">
            <thead>
                <th table-order single by="title"
                    predicate="$ctrl.predicateOrderBy"
                    predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                >
                    Task
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order by="job_priority" single default
                            predicate="$ctrl.predicateOrderBy"
                            predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                        >Priority</table-order>
                        <field-filter
                            items="$ctrl.tasks"
                            field="job_priority_label"
                            title="Priority"
                            options="$root.OPTIONS_JOB_PRIORITY_LABELS"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order single by="description"
                            predicate="$ctrl.predicateOrderBy"
                            predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                        >
                            Description
                        </table-order>
                        <field-filter
                            items="$ctrl.tasks"
                            field="description"
                            title="Description"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order single by="task_type"
                            predicate="$ctrl.predicateOrderBy"
                            predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                        >
                            Type
                        </table-order>
                        <field-filter
                            items="$ctrl.tasks"
                            field="task_type"
                            title="Type"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order single by="job_name"
                            predicate="$ctrl.predicateOrderBy"
                            predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                        >
                            Job Name
                        </table-order>
                        <field-filter
                            items="$ctrl.tasks"
                            field="job_name"
                            title="Job Name"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th table-order single by="eidrL2" predicate="$ctrl.predicateOrderBy">
                    EIDR L2
                </th>
                <th>
                    <div class="d-flex align-items-center">
                        <table-order single by="project_name"
                            predicate="$ctrl.predicateOrderBy"
                            predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                        >
                            Project
                        </table-order>
                        <field-filter
                            items="$ctrl.tasks"
                            field="project_name"
                            title="Project"
                            include-empty="true"
                            predicate="$ctrl.predicatePerfield"
                            predicate-name="{{ ::$ctrl.predicatePerfieldName }}"
                        ></field-filter>
                    </div>
                </th>
                <th>
                    <table-order single by="creation_date"
                        predicate="$ctrl.predicateOrderBy"
                        predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                    >
                        Created
                    </table-order>
                </th>
                <th>
                    <table-order single by="due_date"
                        predicate="$ctrl.predicateOrderBy"
                        predicate-name="{{ ::$ctrl.predicateOrderByName }}"
                    >
                        Due
                    </table-order>
                </th>
                <th ng-show="$ctrl.showOnHoldColumn">Issue</th>
                <th class="table-actions">Actions</th>
            </thead>

            <tbody>
                <tr ng-repeat="task in $ctrl.paginatedTasks">
                    <td>
                        <div class="d-flex justify-space-between align-items-middle">
                            <label ng-if="::$ctrl.hasSelectionActions" class="d-flex align-items-center mr-2 mb-0">
                                <input type="checkbox" checklist-model="$ctrl.taskSelection" checklist-value="task" />
                            </label>
                            <span>{{ ::task.title }}</span>
                            <i ng-show="::task.suspended"
                                class="material-icons md-18 text-danger"  tooltip
                                data-toggle="tooltip" data-placement="right"
                                title="The parent job for this task is suspended"
                            >flag</i>
                        </div>
                    </td>
                    <td ng-class="::{'text-danger': task.job_priority === 50}">
                        {{ ::task.job_priority_label }}
                    </td>
                    <td>{{ ::task.description }}</td>
                    <td>{{ ::task.task_type }}</td>
                    <td>{{ ::task.job_name }}</td>
                    <td>{{ ::task.eidrL2 }}</td>
                    <td>{{ ::task.project_name }}</td>
                    <td><tz-date date="::task.creation_date"></tz-date></td>
                    <td><tz-date date="::task.due_date"></tz-date></td>
                    <td ng-show="$ctrl.showOnHoldColumn">
                        <render-issue-type-and-status
                            ng-if="::!!task.issue"
                            issue="task.issue"
                        ></render-issue-type-and-status>
                    </td>
                    <td class="actions">
                        <worker-task-list-actions task="task"></worker-task-list-actions>
                    </td>
                </tr>
                <tr ng-if="!$ctrl.paginatedTasks.length">
                    <td colspan="99">No results</td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
                <ul
                    uib-pagination
                    total-items="$ctrl.totalItems"
                    ng-model="$ctrl.currentPage"
                    max-size="5"
                    items-per-page="$ctrl.itemsPerPage"
                    boundary-link-numbers="true"
                    rotate="false"
                ></ul>
            </div>
        <worker-task-list-selection-actions task-selection="$ctrl.taskSelection"></worker-task-list-selection-actions>
    `,
    controller: workerTaskListCtrl,
}

const SEARCHABLE_FIELDS = [
    'title',
    'description',
    'task_type',
    'project_name',
    'job_name',
    'job_priority_label',
    'eidrL2',
]

/* @ngInject */
function workerTaskListCtrl(
    this: unknown,
    $scope: ng.IScope,
    $attrs: ng.IAttributes,
    $transclude: ng.ITranscludeFunction,
    $filter: ng.IFilterFunction
) {
    const $ctrl = this as ng.IController & {
        $onInit: typeof $onInit
    }

    $ctrl.$onInit = $onInit

    return $ctrl

    ////////////////////

    function $onInit() {
        $ctrl.totalItems = $ctrl.tasks.length
        $ctrl.currentPage = 1
        $ctrl.itemsPerPage = 25

        $ctrl.predicateOrderByName = $attrs.predicateOrderBy
        $ctrl.predicatePerfieldName = $attrs.predicatePerfield

        $ctrl.parentScope = $scope.$parent
        $ctrl.$transclude = $transclude

        $ctrl.hasSelectionActions = $transclude.isSlotFilled('selectionActions')

        $scope.$watch('$ctrl.predicatePerfield', prepareJobsArray, true)
        $scope.$watchGroup(['$ctrl.currentPage', '$ctrl.searchString'], prepareJobsArray)
        $scope.$watchCollection('$ctrl.predicateOrderBy', prepareJobsArray)
        $scope.$watchCollection('$ctrl.tasks', function () {
            $ctrl.taskSelection = _.intersection($ctrl.tasks, $ctrl.taskSelection)
            $ctrl.showOnHoldColumn = _.some($ctrl.tasks, (task) => task.task_status === 'onHold')

            prepareJobsArray()
        })

        $scope.$on('$destroy', () => {
            $ctrl.parentScope = null
            $ctrl.$transclude = null
        })
    }

    function prepareJobsArray() {
        const filteredTasks = $filter('perfieldFilter')($ctrl.tasks, $ctrl.predicatePerfield)
        const orderedTasks = $filter('orderBy')(filteredTasks, $ctrl.predicateOrderBy)

        const filteredBySearch = $ctrl.searchString
            ? $filter('searchByFieldFilter')(orderedTasks, SEARCHABLE_FIELDS, $ctrl.searchString)
            : orderedTasks

        $ctrl.totalItems = filteredBySearch.length
        $ctrl.paginatedTasks = filteredBySearch.slice(
            ($ctrl.currentPage - 1) * $ctrl.itemsPerPage,
            $ctrl.currentPage * $ctrl.itemsPerPage
        )
    }
}

export /* @ngInject */ function workerTaskListActionsDirective() {
    const directive = {
        require: '^workerTaskList',
        link: workerTaskListActionsLinkFn as ng.IDirectiveLinkFn,
    }

    return directive

    ///////////////////////////

    function workerTaskListActionsLinkFn(
        scope: ng.IScope,
        element: ng.IRootElementService,
        attrs: ng.IAttributes,
        workerTaskListCtrl: ng.IController
    ) {
        const task = scope.$eval(attrs.task)

        const newScope = workerTaskListCtrl.parentScope.$new(false)
        newScope.task = task

        workerTaskListCtrl.$transclude(
            newScope,
            function (actions: JQLite) {
                element.append(actions)
            },
            null,
            'actions'
        )

        scope.$on('$destroy', () => newScope.$destroy())
    }
}
export /* @ngInject */ function workerTaskListSelectionActionsDirective() {
    const directive = {
        require: '^workerTaskList',
        link: workerTaskListSelectionActionsLinkFn as ng.IDirectiveLinkFn,
    }

    return directive

    ///////////////////////////

    function workerTaskListSelectionActionsLinkFn(
        scope: ng.IScope,
        element: ng.IRootElementService,
        attrs: ng.IAttributes,
        workerTaskListCtrl: ng.IController
    ) {
        const newScope = workerTaskListCtrl.parentScope.$new(false)
        newScope.clearSelection = () => {
            newScope.taskSelection.length = 0
        }

        workerTaskListCtrl.$transclude(
            newScope,
            (selectionActions: JQLite) => {
                element.append(selectionActions)
            },
            null,
            'selectionActions'
        )

        scope.$watchCollection(attrs.taskSelection, function (taskSelection) {
            newScope.taskSelection = taskSelection
        })

        scope.$on('$destroy', () => newScope.$destroy())
    }
}
