import { TaskServiceInstance } from 'worker/TaskService.factory'
import { TContentMarker } from './types'

export default /* @ngInject */ function ContentMarkerBackendInterfaceServiceFactory(
    $http: ng.IHttpService,
    TaskService: TaskServiceInstance,
    QAAssignmentService: any,
    NO_LOAD_OVERLAY: any
) {
    const BackendInterface = {
        getWorkerData(id: string) {
            return TaskService.getTask(id)
        },
        setWorkerBookmarks(assignmentID: string, bookmarks: any) {
            TaskService.setBookmarks(assignmentID, bookmarks)
        },
        setWorkerContentMarkers: (assignmentID: string, contentMarkers: any[]) => {
            return $http.put(
                `/api/assignment/${assignmentID}/marker`,
                contentMarkers,
                NO_LOAD_OVERLAY
            )
        },
        submitWorkerContentMarkers: (assignmentID: string, contentMarkers: any[]) => {
            return $http.post(`/api/assignment/${assignmentID}/submit`, contentMarkers)
        },
        getQAData(id: string) {
            return $http.get(`/api/hit/qa/${id}`).then((res) => res.data)
        },
        setQABookmarks(assignmentID: string, bookmarks: any) {
            QAAssignmentService.setBookmarks(assignmentID, bookmarks)
        },
        setQAContentMarkers: (hitID: string, contentMarkers: any[]) => {
            return $http.put(`/api/hit/qa/${hitID}/marker`, contentMarkers, NO_LOAD_OVERLAY)
        },
        submitQAContentMarkers: (hitID: string, contentMarkers: any[]) => {
            return $http.post(`/api/hit/qa/${hitID}/approve`, contentMarkers)
        },

        updateMarker(hitID: string, marker: TContentMarker) {
            return $http.put(`/api/hit/qa/${hitID}/marker/update`, marker)
        },
        createMarker(hitID: string, marker: TContentMarker) {
            return $http.put(`/api/hit/qa/${hitID}/marker/create`, marker)
        },
        returnToWorkers(hitID: string) {
            const URL = `/api/hit/qa/${hitID}/return`
            return $http.post(URL, undefined)
        },
    }
    return BackendInterface
}

export type ContentMarkersBackendInterfaceServiceInstance = ReturnType<
    typeof ContentMarkerBackendInterfaceServiceFactory
>
