import _ from 'lodash'

export default /* @ngInject */ function OSGuesserFactory($window: ng.IWindowService) {
    /* Detectable operating systems (order is important). */
    const SUPPORTED_OS = ['Linux', 'Mac', 'Windows']
    const DEFAULT_OS = 'Windows'

    const OSGuesser = {
        guess() {
            /** Browser navigator object. */
            const nav = $window.navigator || {}

            /** Browser user agent string. */
            const userAgent = nav.userAgent || ''

            const guess = _.find(SUPPORTED_OS, (OS) => {
                const re = new RegExp('\\b' + OS + '\\b', 'i')
                return re.test(userAgent)
            })

            return guess || DEFAULT_OS
        },
    }

    return OSGuesser
}

export type OSGuesserInstance = ReturnType<typeof OSGuesserFactory>
