export default /* @ngInject */ function isEditableDirective($parse, $timeout) {
    var directive = {
        restrict: 'A',
        require: '?form',
        link: isEditableLinkFn,
    }

    return directive

    //////////////////////////

    function isEditableLinkFn($scope, $element, $attr, ngFormController) {
        // "is-editable" can be an attribute on another directive. If the element
        // is not a form, assume that's the case and do not try to execute
        if (!ngFormController) {
            return
        }

        let promise = $timeout(setEditable, 0, false)
        $scope.$on('$includeContentLoaded', setEditable)

        $scope.$on('$destroy', () => $timeout.cancel(promise))

        /////////////////////////////////////////////////////////

        function setEditable() {
            var isEditable = $parse($attr.isEditable)($scope)

            if ($element.find('.no-disable').length) {
                return
            }

            $element
                .find(':input:not([no-enable], button[close-modal], button[update-config])')
                .prop('disabled', !isEditable)

            $('select[chosen]').each(function (idx, el) {
                $(el).on('chosen:ready', () => {
                    let promise = $timeout(
                        () => {
                            $(el).prop('disabled', !isEditable).trigger('chosen:updated')
                        },
                        0,
                        false
                    )

                    $scope.$on('$destroy', () => $timeout.cancel(promise))
                })
            })
        }
    }
}
