import _ from 'lodash'
import fp from 'lodash/fp'
import dateFormat from 'date-fns/format'
import dateParse from 'date-fns/parse'
import dateMin from 'date-fns/min'
import dateMax from 'date-fns/max'

/**
 * @ngdoc directive
 * @name fieldFilter
 * @module map3.core
 * @restrict E
 *
 * @description
 * This directive creates a filtering widget, to be used inside `<th>`.
 *
 * This is a convenience directive that creates a dropdown filter for a particular field.
 * The dropdown contains all unique values for that field, allowing us to filter out
 * items by field uniques.
 *
 * This is meant to be used for fields like `status` where we will have few unique
 * values and not fields like `email` - where all values are unique.
 *
 * The generated predicate is meant to be used with {@link perfieldFilter}
 *
 * @scope
 *
 * @param {Array} items The array of objects.
 *      Note that these are the full objects!
 * @param {string} field A string expression, compatible with [lodash.get()](https://lodash.com/docs#get).
 *      This will be the object field we will generate predicate min/max  values from.
 * @param {Object} predicate The {@link perfieldFilter} `perfieldPredicate`.
 *      Has a structure like: `{ field: { $$dateFilter: true, showEmpty: true, min: Date, max: Date } }`
 * @param {string=} title The title used for wai-aria. Will default to the `field` value.
 * @param {string= } field A string expression, compatible with [Bootstrap aligment classes](https://getbootstrap.com/docs/4.0/components/dropdowns/#menu-alignment).
 */
export default /* @ngInject */ function dateFiledFilterDirective() {
    var directive = {
        restrict: 'E',
        scope: {
            items: '<?',
            field: '@',
            predicate: '=',
            predicateName: '@?',
            options: '<?',
            title: '@?',
            dropdownPositionClass: '@?',
        },
        template: `
            <div class="dropdown">
                <button data-toggle="dropdown"
                    class="btn p-0"
                    ng-class="{
                        'text-primary': initialMinDate !== predicate[field].min ||
                            initialMaxDate !== predicate[field].max || !showEmpty
                    }"
                >
                    <i class="material-icons md-18">date_range</i>
                </button>
                <form>
                    <section class="dropdown-menu text-center p-3"
                        ng-class="dropdownPositionClass ? dropdownPositionClass : 'dropdown-menu-center'"
                    >
                        <button ng-click="resetFilter()" class="btn btn-sm btn-secondary mb-3">Reset Filter</button>
                        <div ng-if="items && formatedMinDate && formatedMaxDate" class="input-group input-daterange">
                            <input ng-model="predicate[field].min" bootstrap-datepicker
                                data-date-start-date="{{ formatedMinDate }}"
                                data-date-end-date="{{ formatedMaxDate }}"
                                type="date" style="width: 100px;" class="form-control"
                            />
                            <div class="input-group-addon mx-2">to</div>
                            <input ng-model="predicate[field].max" bootstrap-datepicker
                                data-date-start-date="{{ formatedMinDate }}"
                                data-date-end-date="{{ formatedMaxDate }}"
                                type="date" style="width: 100px;" class="form-control"
                            />
                        </div>
                        <label class="text-left d-block mt-2">
                            <input type="checkbox" ng-model="showEmpty">
                            Include entries without a date?
                        </label>
                    </section>
                </form>
            </div>
        `,
        link: dateFieldFilterLinkFn,
    }

    return directive

    //////////////////

    function dateFieldFilterLinkFn(scope) {
        scope.title = scope.title || scope.field
        scope.showEmpty = true

        scope.resetFilter = resetFilter

        activate()

        ///////////////////

        function activate() {
            if (!_.isObject(scope.predicate)) {
                scope.predicate = {}
            }

            scope.$watchCollection('items', (_items) => {
                if (!_.isArray(scope.items) || !scope.items.length) return
                generatePredicateObject(scope.items)
                updateValues()
            })
            scope.$watch('showEmpty', function () {
                if (!_.isArray(scope.items) || !scope.items.length) return
                generatePredicateObject(scope.items)
                updateValues()
            })
        }

        function generatePredicateObject(items) {
            const uniqueValues = fp.flow(
                fp.map(scope.field),
                fp.flatten,
                // filter out null, undefined, ""
                fp.filter((val) => !!val),
                fp.map(parseDate)
            )(items)

            scope.predicate[scope.field] = {
                $$dateFilter: true,
                showEmpty: scope.showEmpty,
                min: dateMin(...uniqueValues),
                max: dateMax(...uniqueValues),
                initialMinDate: scope.initialMinDate,
                initialMaxDate: scope.initialMaxDate,
                resetFilter: scope.resetFilter,
                __REMOVE_FROM_PREFERENCES_PROP_ACTIVE_PREDICATE: true,
            }
        }

        function updateValues() {
            scope.initialMinDate = scope.predicate[scope.field].min
            scope.initialMaxDate = scope.predicate[scope.field].max

            scope.formatedMinDate = dateFormat(scope.initialMinDate, 'YYYY-MM-DD')
            scope.formatedMaxDate = dateFormat(scope.initialMaxDate, 'YYYY-MM-DD')
        }

        function parseDate(date) {
            const timestamp = _.toInteger(date) && _.toInteger(date) * 1000

            return dateParse(timestamp || date)
        }

        function resetFilter() {
            scope.predicate[scope.field].min = scope.initialMinDate
            scope.predicate[scope.field].max = scope.initialMaxDate

            scope.showEmpty = true
        }
    }
}
