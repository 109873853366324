import angular from 'angular'
import _ from 'lodash'
import fp from 'lodash/fp'
import { findMaxId, isCommercialBlackPairValid, isPair } from './utils'
import { combineReducers } from 'redux'
import ReduxUndo from 'redux-undo'
import ReduxActions from 'services/ReduxActions'
import { ContentMarkerType } from './constants'
import { IContentMarkerPair, TContentMarker } from './types'

const require = ReduxActions.require

const ContentMarkersActionCreatorsBase = ReduxActions.createActions({
    CONTENT_MARKERS: {
        INIT: require('contentMarkers', 'videoApi'),

        CREATE_PAIR: require('frameNumber'),
        CREATE_FIXED_POINT_INSERTION: require('frameNumber'),

        DELETE_BY_ID: require('id'),
        DELETE_PAIR: require('pairId'),

        MOVE_FRAME: require('id', 'offsetFrames', 'videoApi'),

        SELECT_BY_ID: require('id'),
        SELECT_NEXT: require('currentFrame'),
        SELECT_PREVIOUS: require('currentFrame'),
        SELECT_BY_INDEX: require('index'),

        MERGE_SELECTED: undefined,
    },
    CONTENT_MARKERS_STATE: {
        UPDATE: require('contentMarkersState'),
    },
})

export /* @ngInject */ function ContentMarkersActionCreatorsFactory() {
    const ContentMarkersActionCreators = ContentMarkersActionCreatorsBase.contentMarkers

    return ContentMarkersActionCreators
}

export type ContentMarkersActionCreatorsInstance = ReturnType<
    typeof ContentMarkersActionCreatorsFactory
>

export /* @ngInject */ function ContentMarkersActionsFactory(
    $ngRedux: any,
    ContentMarkersActionCreators: any
) {
    return ReduxActions.bindActionCreators(ContentMarkersActionCreators, $ngRedux.dispatch)
}

export type ContentMarkersActionsInstance = ReturnType<typeof ContentMarkersActionsFactory>

export /* @ngInject */ function ContentMarkersStateActionCreatorsFactory() {
    const ContentMarkersStateActionCreators = ContentMarkersActionCreatorsBase.contentMarkersState

    return ContentMarkersStateActionCreators
}

export /* @ngInject */ function ContentMarkersStateActionsFactory(
    $ngRedux: any,
    ContentMarkersStateActionCreators: any
) {
    return ReduxActions.bindActionCreators(ContentMarkersStateActionCreators, $ngRedux.dispatch)
}

export type ContentMarkersStateActionsInstance = ReturnType<
    typeof ContentMarkersStateActionsFactory
>

export /* @ngInject */ function ContentMarkersReducerFactory(ContentMarkersActionCreators: any) {
    const actions = ContentMarkersActionCreators

    const ContentMarkersReducer: any = ReduxActions.handleActions<any>(
        {
            [actions.init]: (state, { payload: { contentMarkers } }) => {
                return {
                    ...state,
                    contentMarkers: normalizeExternalInput(contentMarkers),
                    $$globalId: findMaxOfIds(contentMarkers),
                }
            },

            [actions.createPair]: (
                state,
                { payload: { frameNumber, timestampStart, timestampEnd } }
            ) => {
                const originalContentMarkers = deselectContentMarkers(
                    normalizeContentMarkers(state.contentMarkers || [])
                )
                const $$globalId = (state.$$globalId || findMaxId(originalContentMarkers)) + 1
                const pairId = findMaxPair(originalContentMarkers) + 1

                const first = {
                    frameNumber,
                    id: $$globalId,
                    $$isSelected: false,
                    type: ContentMarkerType.FFCB,
                    timestamp: timestampStart,
                    originalTimestamp: timestampStart,
                    pair: pairId,
                    rating: 2,
                    comment: '',
                }

                const last = {
                    frameNumber: frameNumber + 1,
                    id: $$globalId + 1,
                    $$isSelected: true,
                    type: ContentMarkerType.LFCB,
                    timestamp: timestampEnd,
                    originalTimestamp: timestampEnd,
                    pair: pairId,
                    rating: 2,
                    comment: '',
                }

                const contentMarkers = normalizeContentMarkers([
                    ...originalContentMarkers,
                    first,
                    last,
                ])

                return {
                    ...state,
                    $$globalId: $$globalId + 1,
                    contentMarkers,
                }
            },

            [actions.createFixedPointInsertion]: (
                state,
                { payload: { frameNumber, timestamp } }
            ) => {
                const originalContentMarkers = deselectContentMarkers(
                    normalizeContentMarkers(state.contentMarkers || [])
                )
                const $$globalId = (state.$$globalId || findMaxId(originalContentMarkers)) + 1
                const newContentMarker = {
                    frameNumber,
                    id: $$globalId,
                    $$isSelected: true,
                    type: ContentMarkerType.FPCI,
                    timestamp: timestamp,
                    originalTimestamp: timestamp,
                    chapter: false,
                    video: false,
                    audio: false,
                    story: false,
                    rating: 2,
                    comment: '',
                }

                const contentMarkers = normalizeContentMarkers([
                    ...originalContentMarkers,
                    newContentMarker,
                ])

                return {
                    ...state,
                    $$globalId,
                    contentMarkers,
                }
            },

            [actions.deleteById]: (state, { payload: { id } }) => {
                const contentMarker = _.find(state.contentMarkers, {
                    id,
                })

                return {
                    ...state,
                    contentMarkers: _.without(state.contentMarkers, contentMarker),
                }
            },

            [actions.deletePair]: (state, { payload: { pairId } }) => {
                const contentMarkers = _.filter(state.contentMarkers, (m) => m.pair === pairId)

                return {
                    ...state,
                    contentMarkers: _.without(state.contentMarkers, ...contentMarkers),
                }
            },

            [actions.moveFrame]: (state, { payload: { id, offsetFrames, videoApi } }) => {
                const idx = _.findIndex(state.contentMarkers, {
                    id,
                })
                if (idx === -1) {
                    return {
                        ...state,
                    }
                }

                const updatedFrameNumber = state.contentMarkers[idx].frameNumber + offsetFrames
                const contentMarker = {
                    ...state.contentMarkers[idx],
                    frameNumber: updatedFrameNumber,
                    timestamp: videoApi.convertFrameToSeconds(updatedFrameNumber),
                }

                // create copy of content markers array with updated content marker
                const tempContentMarkers = state.contentMarkers.slice()
                tempContentMarkers.splice(idx, 1, contentMarker)
                const contentMarkers = normalizeContentMarkers(tempContentMarkers)

                return {
                    ...state,
                    contentMarkers,
                }
            },

            [actions.selectById]: (state, { payload: { id } }) => {
                id = _.isArray(id) ? id : [id]

                const index = _.filter(state.contentMarkers, (contentMarker) =>
                    _.includes(id, contentMarker.id)
                ).map((contentMarker) => state.contentMarkers.indexOf(contentMarker))
                return ContentMarkersReducer(
                    state,
                    actions.selectByIndex({
                        index,
                    })
                )
            },

            [actions.selectNext]: (state, { payload: { currentFrame } }) => {
                const selectedMarker = _.find(state.contentMarkers, {
                    $$isSelected: true,
                })

                if (selectedMarker) {
                    currentFrame = selectedMarker.frameNumber
                }

                let next = _.find(state.contentMarkers, (m) => m.frameNumber > currentFrame)
                if (!next) {
                    next = _.first(state.contentMarkers)
                }

                const index = state.contentMarkers.indexOf(next)

                return ContentMarkersReducer(state, actions.selectByIndex({ index }))
            },

            [actions.selectPrevious]: (state, { payload: { currentFrame } }) => {
                const selectedMarker = _.find(state.contentMarkers, {
                    $$isSelected: true,
                })

                if (selectedMarker) {
                    currentFrame = selectedMarker.frameNumber
                }

                let prev = _.findLast(state.contentMarkers, (m) => m.frameNumber < currentFrame)
                if (!prev) {
                    prev = _.last(state.contentMarkers)
                }

                const index = state.contentMarkers.indexOf(prev)

                return ContentMarkersReducer(state, actions.selectByIndex({ index }))
            },

            [actions.selectByIndex]: (state, { payload: { index } }) => {
                index = _.isArray(index) ? index : [index]

                const deselectedState = {
                    $$isSelected: false,
                }
                const selectedState: any = {
                    $$isSelected: true,
                }

                const contentMarkers = _.map(state.contentMarkers, (contentMarker, idx) => {
                    if (_.includes(index, idx)) {
                        contentMarker = {
                            ...contentMarker,
                            ...selectedState,
                        }
                    } else {
                        contentMarker = {
                            ...contentMarker,
                            ...deselectedState,
                        }
                    }

                    return contentMarker
                })

                // return unchanged when no modification made
                if (_.isEqual(contentMarkers, state.contentMarkers)) {
                    return state
                }

                return {
                    ...state,
                    contentMarkers,
                }
            },
        },
        {
            contentMarkers: [],
        }
    )

    return ContentMarkersReducer
}

export /* @ngInject */ function ContentMarkersStateReducerFactory(
    ContentMarkersStateActionCreators: any
) {
    const actions = ContentMarkersStateActionCreators

    const ContentMarkersStateReducer = ReduxActions.handleActions<any>(
        {
            [actions.update]: (_state, { payload: { contentMarkersState } }) => {
                return angular.copy(contentMarkersState)
            },
        },
        []
    )

    return ContentMarkersStateReducer
}

export /* @ngInject */ function CombinedContentMarkersReducerFactory(
    ContentMarkersReducer: any,
    ContentMarkersStateReducer: any,
    ContentMarkersActionCreators: any
) {
    const undoableContentMarkers = ReduxUndo(ContentMarkersReducer, {
        filter: function (action, currentState, previousHistory) {
            // deselect actions should not be added to the history
            if (
                action.type === ContentMarkersActionCreators.selectById.toString() &&
                action.payload.id === null
            ) {
                return false
            }

            // do not add unchanged content markers to history
            if (currentState === previousHistory.present) {
                return false
            }

            return true
        },
    })

    const combinedReducer = combineReducers({
        undoableContentMarkers,
        contentMarkersState: ContentMarkersStateReducer,
    })

    // Hack around needing to preserve $$globalId uptick after merge undo.
    // We end up inflating $$globalId more than needed, but that isn't an
    // issue for our purposes.
    return (state: any, action: any = {}) => {
        let $$globalId
        if (action.type === '@@redux-undo/UNDO') {
            $$globalId = _.get(state, 'undoableContentMarkers.present.$$globalId')
        }

        const res = combinedReducer(state, action)

        if ($$globalId) {
            _.set(res, 'undoableContentMarkers.present.$$globalId', $$globalId)
        }

        return res
    }
}

function normalizeExternalInput(contentMarkers: TContentMarker[] = []) {
    contentMarkers = angular.copy(contentMarkers)

    const normalizedMarkers: TContentMarker[] = []

    const brokenPairs: { [key: string]: true } = {}

    _.forEach(contentMarkers, function (contentMarker) {
        if (isPair(contentMarker)) {
            if (brokenPairs[contentMarker.pair]) {
                return
            }

            const result = contentMarkers.filter((m) => m.pair === contentMarker.pair)

            if (result.length !== 2) {
                brokenPairs[contentMarker.pair] = true
                return
            }

            if (
                result.length === 2 &&
                !isCommercialBlackPairValid(result as IContentMarkerPair[])
            ) {
                brokenPairs[contentMarker.pair] = true
                return
            }
        }

        if (_.isNil(contentMarker.id)) {
            contentMarker.id = findMaxId(contentMarkers) + 1
        }

        normalizedMarkers.push(contentMarker)
    })

    return normalizeContentMarkers(normalizedMarkers)
}

function normalizeContentMarkers(contentMarkers: TContentMarker[] = []) {
    contentMarkers = _.sortBy(angular.copy(contentMarkers), 'frameNumber')

    contentMarkers = _.filter(contentMarkers, function (contentMarker) {
        if (contentMarker.frameNumber < 0) {
            return false
        }

        return true
    })

    return contentMarkers
}

function findMaxOfIds(contentMarkers: TContentMarker[]) {
    const maxId = fp.flow(fp.map('id'), fp.max)(contentMarkers) || 0
    const maxStartId = fp.flow(fp.map('startId'), fp.max)(contentMarkers) || 0
    const maxEndId = fp.flow(fp.map('endId'), fp.max)(contentMarkers) || 0
    return Math.max(maxId, maxStartId, maxEndId)
}

function findMaxPair(contentMarkers: any[]) {
    return fp.flow(fp.map('pair'), fp.max)(contentMarkers) || 0
}

function deselectContentMarkers(contentMarkers: TContentMarker[]): TContentMarker[] {
    const deselectedState: any = {
        $$isSelected: false,
    }

    return _.map(contentMarkers, (contentMarker) => {
        return {
            ...contentMarker,
            ...deselectedState,
        }
    })
}
