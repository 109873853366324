import _ from 'lodash'

import Storage from 'services/Storage'
import { SESSION_STORAGE_ACTIVE_ROLES } from '../auth/User.factory'

const debug = require('debug')('map3:TimeTrack')

/**
 * @ngdoc service
 * @name TimeTracker
 * @module map3.user
 *
 * @description
 * TimeTracker Service
 *
 * Time tracking service. It's used in Workers task and QA review pages.
 *
 * Each time a worker/qa enters a task a POST to `/api/time-tracker` is made.
 * This starts the time tracking and returns a token. Then the service makes GET request
 * to `/api/time-tracker/token/{token}` on every 30 seconds to indicate the user is still alive.
 */
export /* @ngInject */ function TimeTrackerFactory(
    $rootScope,
    $state,

    USER_ROLE_ADMIN,
    TimeTrack
) {
    const TimeTracker = {
        activeTracker: null,

        /**
         * @ngdoc method
         * @name TimeTracker#init
         * @module map3.user
         *
         * @description
         * Sets event handlers for `$stateChangeSuccess`, `$stateChangeError` and `user.logout` and
         * runs {@link TimeTracker#startTimeTracking startTimeTracking()}
         */
        init: function () {
            /*eslint angular/on-watch: "off"*/
            $rootScope.$on('$stateChangeSuccess', function (ev, toState, toParams) {
                TimeTracker.stopActiveTracking()

                const currentRole = Storage.getItem(SESSION_STORAGE_ACTIVE_ROLES)
                const isAdmin = currentRole && currentRole.includes(USER_ROLE_ADMIN)

                if (_.has(toState, 'data.trackTime') && !isAdmin) {
                    TimeTracker.startTimeTracking(toState, toParams)
                }
            })

            // Manage Error situation
            $rootScope.$on('$stateChangeError', TimeTracker.stopActiveTracking)

            // Manage logout
            $rootScope.$on('user.logout', TimeTracker.stopActiveTracking)
        },

        /**
         * @ngdoc method
         * @name TimeTracker#startTimeTracking
         * @module map3.user
         *
         * @description
         * Given a state and params, starts a time tracking if the request taskId
         * {@link TimeTracker#startTimeTrackingFn startTimeTrackingFn()}
         *
         * @param {string|object} stateOrName Target state
         * @param {object} stateParams Target params
         */
        startTimeTracking: function (stateOrName, stateParams) {
            let state = $state.get(stateOrName)

            let trackTimeOpts = _.get(state, 'data.trackTime', {})
            let taskId = _.get(stateParams, trackTimeOpts.taskId)

            if (taskId) {
                debug('TimeTrack Activating time tracker:', taskId, trackTimeOpts)
                TimeTracker.activeTracker = new TimeTrack(taskId, trackTimeOpts)
                TimeTracker.activeTracker.start()
            }
        },

        /**
         * @ngdoc method
         * @name TimeTracker#stopActiveTracking
         * @module map3.user
         *
         * @description
         * Stops active tracking if any by activeTracker identifier.
         */
        stopActiveTracking: function () {
            if (TimeTracker.activeTracker) {
                debug('TimeTrack Stoping time tracker:', TimeTracker.activeTracker.token)
                TimeTracker.activeTracker.stop()
                TimeTracker.activeTracker = null
            }
        },
    }

    return TimeTracker
}

export /* @ngInject */ function TimeTrackFactory(
    NO_LOAD_OVERLAY,
    $timeout,
    $q,
    Notification,
    RetriableRequest
) {
    /**
     * An object representing an active time tracking session, applied to a particular task
     */
    class TimeTrack {
        /**
         * @param {string} taskId The task identifier
         * @param {object=} trackTimeOpts Time tracker options (taskType, userType, etc)
         * @param {object=} opts Options object
         */
        constructor(taskId, trackTimeOpts = {}, opts = {}) {
            this.taskId = taskId
            this.trackTimeOpts = trackTimeOpts

            this.token = null

            this.TRACK_TIME_URL = '/api/time-tracker/token/'
            this.GET_TOKEN_URL = '/api/time-tracker'
            this.TIMER_INTERVAL = _.get(opts, 'TIMER_INTERVAL_SECONDS', 30) * 1000

            this.$$tokenRequest = null
            this.$$trackTimeRequest = null
            this.$$trackTimeTimeout = null
        }

        /**
         * Start time tracking
         */
        start() {
            debug('TimeTrack', this.taskId, 'Requesting fresh token')

            this.$requestToken(this.taskId, this.trackTimeOpts)
                .then((token) => {
                    debug('TimeTrack', this.taskId, 'Fresh Token:', token)
                    this.token = token
                    this.$loop()
                })
                .catch(angular.noop)
        }

        /**
         * Stop time tracking
         */
        stop() {
            if (this.$$tokenRequest) {
                this.$$tokenRequest.cancel()
                this.$$tokenRequest = null
            }

            if (this.$$trackTimeRequest) {
                this.$$trackTimeRequest.cancel()
                this.$$trackTimeRequest = null
            }

            if (this.$$trackTimeTimeout) {
                $timeout.cancel(this.$$trackTimeTimeout)
                this.$$trackTimeTimeout = null
            }
        }

        $loop() {
            this.$trackTime(this.token)
                .then(() => {
                    this.$$trackTimeTimeout = $timeout(() => this.$loop(), this.TIMER_INTERVAL)
                })
                .catch(angular.noop)
        }

        $requestToken(taskId, trackTimeOpts = {}) {
            this.$$tokenRequest = this.$createRequest()

            return this.$$tokenRequest
                .request({
                    ...NO_LOAD_OVERLAY,
                    method: 'POST',
                    url: this.GET_TOKEN_URL,
                    data: {
                        taskId,
                        userType: _.get(trackTimeOpts, 'userType'),
                        taskType: _.get(trackTimeOpts, 'taskType'),
                    },
                })
                .then((res) => res.data.token)
                .finally(() => {
                    this.$$tokenRequest = null
                })
        }

        $trackTime(token) {
            this.$$trackTimeRequest = this.$createRequest({
                maxRetries: 10,
            })

            debug('TimeTrack', this.taskId, 'Pinging:', token)

            return this.$$trackTimeRequest
                .request({
                    ...NO_LOAD_OVERLAY,
                    method: 'GET',
                    url: this.TRACK_TIME_URL + this.token,
                })
                .catch((e) => {
                    debug(
                        'TimeTrack',
                        this.taskId,
                        'Retriable request failed for current token, abandoning:',
                        token,
                        'RetriableRequest rejection:',
                        e
                    )
                    if (this.$$trackTimeRequest && !this.$$trackTimeRequest.$$cancelled) {
                        this.restart()
                    }

                    return $q.reject(e)
                })
                .finally(() => {
                    this.$$trackTimeRequest = null
                })
        }

        restart() {
            // when we restart the time track process, we must make sure to cancel all current requests first
            this.stop()
            this.start()
        }

        $createRequest(opts = {}) {
            return new RetriableRequest({
                ...opts,
                warnRetries: 30,
                warnRetriesCallback: () => {
                    Notification.warning(`
                        Network connectivity issue:
                        Time tracking is not available.
                        Work data might potentionally not be saved.
                    `)
                },
            })
        }
    }

    return TimeTrack
}
