import constants from '../constants'

import routes, { QARouterFactory } from './routes'

import QAScenesServiceFactory from './QAScenesService.factory'
import QAHighlightsServiceFactory from './QAHighlightsService.factory'
import QAAssignmentServiceFactory from './QAAssignmentService.factory'
import QAAnnotationFactory from './service/QAAnnotation.factory'
import QAReturnToWorkersFactory from './service/QAReturnToWorkers.factory'
import QAAnnotationTreeFactory from './service/QAAnnotationTree.factory'

import MarkerTransformationFactory from 'services/MarkerTransformation.factory'

import { qaTaskListComponent, qaTaskListActionsDirective } from './components/qaTaskList.component'

/**
 * @ngdoc module
 * @name map3.qa
 * @module map3.qa
 *
 * @description
 *
 * QA module
 */
const map3qa = angular
    .module('map3.qa', [constants, require('angular-ui-router')])
    .config(routes)
    .factory('QARouter', QARouterFactory)
    .factory('QAScenesService', QAScenesServiceFactory)
    .factory('QAHighlightsService', QAHighlightsServiceFactory)
    .factory('QAAssignmentService', QAAssignmentServiceFactory)
    .factory('QAAnnotation', QAAnnotationFactory)
    .factory('QAReturnToWorkers', QAReturnToWorkersFactory)
    .factory('QAAnnotationTree', QAAnnotationTreeFactory)

    .factory('MarkerTransformation', MarkerTransformationFactory)

    .component('qaTaskList', qaTaskListComponent)
    .directive('qaTaskListActions', qaTaskListActionsDirective)

export default map3qa.name
