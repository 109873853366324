export default /* @ngInject */ function oneClickDirective() {
    const directive = {
        restrict: 'A',
        scope: {
            oneClick: '&',
        },
        link: oneClickLinkFn,
    }

    return directive

    ////////////////////

    function oneClickLinkFn(
        scope: ng.IScope & { oneClick: () => void },
        element: ng.IAugmentedJQuery
    ) {
        element.one('click', scope.oneClick)

        scope.$on('$destroy', () => {
            element.off('click', scope.oneClick)
        })
    }
}
