import _ from 'lodash'
import dateParse from 'date-fns/parse'
import dateFormat from 'date-fns/format'
import dateAddDays from 'date-fns/add_days'
import angular from 'angular'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { JOB_PRIORITIES } from '../constants'
import { SESSION_STORAGE_SELECTED_PROJECT } from 'constants.es6'
import type { Project, ProjectFormData } from '../admin/manage-projects/AdminManageProjects.ctrl'

import type {
    IField,
    TMissingSegmentsForVideo,
    TRepublishStatus,
    TSMSVideo,
    TTableRowData,
} from './types'

import { TEidrL2Status } from './types'
export default /* @ngInject */ function AdminModuleFactory(
    JOB_PRIORITIES: JOB_PRIORITIES,
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    SMS_API_URL: string,
    $q: ng.IQService,
    $http: ng.IHttpService,
    Upload: ng.angularFileUpload.IUploadService
) {
    const AdminModule = {
        getActiveJobs: () => {
            return $http.get<TJob[]>('/api/admin/workflows/active').then((res) => res.data)
        },

        getFinishedJobs: () => {
            return $http.get<TJob[]>('/api/admin/workflows/finished').then((res) => res.data)
        },

        prioritizeJob: function (
            job: Record<string, unknown>,
            priorityLevel: Record<string, unknown>
        ) {
            return $http
                .post('/api/admin/workflows/prioritize ', {
                    uri: job.uri,
                    priority: priorityLevel,
                })
                .then((res) => {
                    const priority = _.find(JOB_PRIORITIES, {
                        priority: priorityLevel,
                    }) as JOB_PRIORITIES[number]
                    if (priority) {
                        job.priorityLabel = priority.label
                    }

                    return res
                })
        },

        getTasks: function () {
            return $http.get('/api/admin/tasks').then((res) => {
                return res.data
            })
        },

        getProjects: function (): ng.IPromise<Project[]> {
            return $http.get<Project[]>('/api/admin/projects').then((res) => res.data)
        },

        addProject: function (project: ProjectFormData): ng.IPromise<Project> {
            return Upload.upload<Project>({
                url: '/api/admin/projects/add',
                data: project,
                method: 'POST',
            }).then((res) => res.data)
        },

        testKafkaConnectivity: function <P extends ProjectFormData | Project>(
            project: P
        ): ng.IPromise<P> {
            return Upload.upload<P>({
                url: '/api/admin/projects/test-kafka-connectivity',
                data: project,
                method: 'POST',
            }).then((res) => res.data)
        },

        editProject: function (project: Project): ng.IPromise<Project> {
            const uri = project.uri?.split('/').pop()

            return Upload.upload<Project>({
                url: `/api/admin/projects/edit/${uri}`,
                data: project,
                method: 'POST',
            }).then((res) => res.data)
        },

        deleteProject: function (project: Project) {
            const uri = project.uri?.split('/').pop()

            return $http.post(`/api/admin/projects/delete/${uri}`, {}).then((res) => res.data)
        },

        getCommonOptions: function () {
            return $http.get('/api/system/misc').then((res) => res.data)
        },

        getGroupDetails: function (group: UserGroup) {
            if (!(group && group.uri)) {
                throw Error('Cannot load details for group: ' + angular.toJson(group))
            }
            return $http
                .get('/api/admin/groups/group/' + group.uri.split('/').pop())
                .then((res) => res.data)
        },

        getAvailableUsers: function (group: UserGroup) {
            if (!(group && group.uri)) {
                throw Error('Cannot load available users for group: ' + angular.toJson(group))
            }

            // GET /api/admin/groups/available-users/{id}
            return $http
                .get('/api/admin/groups/available-users/' + group.uri.split('/').pop())
                .then((res) => res.data)
        },

        getJobInfo: function (jobId: TJob['uri']) {
            return $http.get('/api/admin/workflows/info/' + jobId).then((res) => res.data)
        },

        getJobDetails: function (jobId: TJob['uri']) {
            return $http
                .get<Record<string, unknown>>('/api/admin/workflows/tasks-status/' + jobId)
                .then((res) => {
                    return {
                        ...res.data,
                        graph: {
                            nodes: res.data.nodes,
                            edges: res.data.edges,
                        },
                    }
                })
        },

        resumeJob: function (job: TJob) {
            return $http.post('/api/admin/workflows/resume', { uri: job.uri })
        },

        suspendJob: function (job: TJob) {
            return $http.post('/api/admin/workflows/suspend', { uri: job.uri })
        },

        stopJob: function (job: TJob) {
            return $http.post('/api/admin/workflows/stop', { uri: job.uri })
        },

        deleteJob: function (job: TJob) {
            return $http.post('/api/admin/workflows/delete', { uri: job.uri })
        },

        disableKeepAlive: function (job: TJob) {
            return $http.post('/api/admin/workflows/no-keep-alive', { uri: job.uri }).then(() => {
                job.keepAlive = 'disabled'
            })
        },

        setKeepAlive: function (jobUri: TJob['uri'], newExpiryDate: string | Date) {
            newExpiryDate = dateFormat(dateParse(newExpiryDate), 'YYYY-MM-DD')

            return $http.post('/api/admin/workflows/extend-keep-alive', {
                uri: jobUri,
                newExpiryDate,
            })
        },

        dismissNotification: function (type: string, uri: string) {
            return $http.post('/api/admin/dashboard/notifications/dismiss', {
                type,
                uri,
            })
        },

        republishMarkerWorkflow(eidrl2s: string[]) {
            return $http.post<TRepublishStatus[]>('/api/admin/dashboard/redeliver', { eidrl2s })
        },

        checkMarkerWorkflowStatus(eidrl2: string) {
            return $http.get<TRepublishStatus>(`/api/admin/dashboard/get-video-data/${eidrl2}`)
        },

        filterJobVelocity: function (
            groups: UserGroup[],
            projects: { label: string; uri: string }
        ) {
            return $http
                .post('/api/admin/dashboard/current-load', { groups, projects })
                .then((res) => res.data)
        },

        dismissQuestionNotification: (questionNotificiation: TNotification) => {
            const { task_uri, notification_id } = questionNotificiation
            return $http
                .post('/api/admin/dashboard/throttling-containers/dismiss', {
                    task_uri,
                    notification_id,
                })
                .then((res) => res.data)
        },

        deleteQuestionNotification: (questionNotificiation: TNotification) => {
            const { task_uri, notification_id } = questionNotificiation
            return $http
                .post('/api/admin/dashboard/throttling-containers/delete', {
                    task_uri,
                    notification_id,
                })
                .then((res) => res.data)
        },

        postponeKeepAlive: function (jobUri: TJob['uri'], currentExpiryDate: string, days: number) {
            const newKeepAliveDate = dateAddDays(dateParse(currentExpiryDate), days)

            return AdminModule.setKeepAlive(jobUri, newKeepAliveDate)
        },

        addGroup: (group: UserGroup) => {
            return $http.post<string>('/api/admin/groups/add', {
                group_label: group.label,
            })
        },

        removeGroup: function (group: UserGroup, transferTaskToGroup: UserGroup) {
            return $http.post('/api/admin/groups/delete', {
                group_uri: group.uri,
                move_uri: transferTaskToGroup.uri,
            })
        },

        renameGroup: function (group: UserGroup, groupeNewName: string) {
            return $http.post('/api/admin/groups/rename/' + group.uri.split('/').pop(), {
                new_name: groupeNewName,
            })
        },

        getTaskElements: (projectUri: string) => {
            const id = projectUri.split('/').pop()

            return $http.get<TaskElement[]>(`/api/admin/taskElements/${id}`).then((res) => res.data)
        },

        getVideoTables: () => {
            return $http.get('/api/admin/taskElements/get-video-tables').then((res) => res.data)
        },

        getWorkflowTemplates: () => {
            return $http
                .get<WorkflowTemplate[]>('/api/admin/workflowTemplates')
                .then((res) => res.data)
        },

        getSingleWorkflowTemplate: (templateId: string, projectUri: string) => {
            const projectId = projectUri.split('/').pop()
            return $http
                .get<WorkflowModel>(
                    `/api/admin/workflowTemplates/template/${templateId}/${projectId}`
                )
                .then((res) => res.data)
        },

        deleteTemplate: function (uri: string) {
            return $http.post('/api/admin/workflowTemplates/delete', { uri: uri })
        },

        workflowEditTitle: function (workflowUri: string, title: string) {
            return $http
                .post(`/api/admin/workflows/edit-workflow/${workflowUri}`, { name: title })
                .catch((res) => $q.reject(res.data))
        },

        workflowCheck: function (wf: Workflow) {
            const workflow = AdminModule.$transformWorkflowForBackend(wf)

            return $http
                .post<{ errors: string[]; warnings: string[] }>(
                    '/api/admin/workflows/check',
                    workflow
                )
                .then((res) => {
                    const data = res.data
                    // if we have errors or warnings, reject the promise.
                    if (_.size(data.errors) || _.size(data.warnings)) {
                        return $q.reject(data)
                    }

                    return data
                })
        },

        workflowCreate: function (wf: Workflow) {
            const workflow = AdminModule.$transformWorkflowForBackend(wf)

            return $http
                .post('/api/admin/workflows/save', workflow)
                .catch((res) => $q.reject(res.data))
        },

        testWorkflowEdit: function (workflowUri: string, taskUri: string, config: any) {
            return $http
                .post('/api/admin/workflows/edit-preview', { workflowUri, taskUri, config })
                .then((res) => res.data)
                .catch((res) => $q.reject(res.data))
        },

        workflowEdit: function (workflowUri: string, taskUri: string, config: any) {
            return $http
                .post('/api/admin/workflows/edit', { workflowUri, taskUri, config })
                .catch((res) => $q.reject(res.data))
        },

        saveWorkflowTemplate: (template: WorkflowTemplate & WorkflowModel) => {
            return $http
                .post<WorkflowTemplate>('/api/admin/workflowTemplates/save', template)
                .then((res) => res.data)
        },

        overwriteTemplate: function (template: WorkflowTemplate) {
            return $http
                .post('/api/admin/workflowTemplates/resave', template)
                .then((res) => res.data)
        },

        getTasksForVideoConfigs: function (videoConfigs: unknown[]) {
            return $http
                .post(
                    '/api/admin/taskElements/video-hits',
                    {
                        videoConfigs: _.map(videoConfigs, angular.toJson),
                    },
                    NO_LOAD_OVERLAY
                )
                .then((res) => res.data)
        },

        getSegmentsCompletion: function (
            groupId: string,
            projectId: string,
            videoConfigs: unknown[],
            segmentConfigs: {
                internal_options: { acts: boolean; scenes: boolean; subscenes: boolean }
            },
            cancelPromise: ng.IPromise<unknown>
        ) {
            return $http
                .post<
                    {
                        results: {
                            none: unknown[]
                            some: unknown[]
                            all: unknown[]
                            all_in_progress: unknown[]
                            some_in_progress: unknown[]
                            no_scenes_but_acts: unknown[]
                            no_acts_but_scenes: unknown[]
                        }
                    } & Record<string, unknown>
                >(
                    '/api/admin/taskElements/scenes-completion',
                    {
                        group_id: groupId,
                        project_id: projectId,
                        videoConfigs: _.map(videoConfigs, angular.toJson),
                        segmentConfigs,
                        timeout: cancelPromise,
                    },
                    {
                        timeout: cancelPromise,
                        ...NO_LOAD_OVERLAY,
                    }
                )
                .then((res) => {
                    const { results, fields } = res.data

                    return {
                        fields,
                        results: {
                            needNoAnnotation: _.map(results.none, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            needNoAnnotationLength: _.size(results.none),
                            needNoAnnotationShowVideosList: false,

                            needPartialAnnotation: _.map(results.some, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            needPartialAnnotationLength: _.size(results.some),
                            needPartialAnnotationShowVideosList: false,

                            needFullAnnotation: _.map(results.all, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            needFullAnnotationLength: _.size(results.all),
                            needFullAnnotationShowVideosList: false,

                            allInProgress: _.map(results.all_in_progress, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            allInProgressLength: _.size(results.all_in_progress),
                            allInProgressShowVideosList: false,

                            someInProgress: _.map(results.some_in_progress, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            someInProgressLength: _.size(results.some_in_progress),
                            someInProgressShowVideosList: false,

                            noScenesButActs: _.map(results.no_scenes_but_acts, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            noScenesButActsLength: _.size(results.no_scenes_but_acts),
                            noScenesButActsShowVideosList: false,

                            noActsButScenes: _.map(results.no_acts_but_scenes, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            noActsButScenesLength: _.size(results.no_acts_but_scenes),
                            noActsButScenesShowVideosList: false,

                            videosLength:
                                _.size(results.none) +
                                _.size(results.some) +
                                _.size(results.all) +
                                _.size(results.all_in_progress) +
                                _.size(results.some_in_progress) +
                                _.size(results.no_scenes_but_acts) +
                                _.size(results.no_acts_but_scenes),
                        },
                    }
                })
                .catch((error) => {
                    return $q.reject(error)
                })
        },

        getAnnotationCompletion: function (opts: {
            groupId: string
            projectId: string
            template: string
            videoConfigs: unknown[]
            questionsConfigs: unknown[]
        }) {
            return $http
                .post<
                    {
                        results: {
                            none: unknown[]
                            some: unknown[]
                            all: unknown[]
                            all_in_progress: unknown[]
                            some_in_progress: unknown[]
                            availability: TMissingSegmentsForVideo
                        }
                    } & Record<string, unknown>
                >(
                    '/api/admin/taskElements/annotation-completion',
                    {
                        group_id: opts.groupId,
                        project_id: opts.projectId,
                        template: opts.template,
                        videoConfigs: _.map(opts.videoConfigs, angular.toJson),
                        questionConfigs: _.map(opts.questionsConfigs, angular.toJson),
                    },
                    NO_LOAD_OVERLAY
                )
                .then((res) => {
                    const { results, fields } = res.data

                    return {
                        fields,
                        results: {
                            needNoAnnotation: _.map(results.none, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            needNoAnnotationLength: _.size(results.none),
                            needNoAnnotationShowVideosList: false,

                            needPartialAnnotation: _.map(results.some, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            needPartialAnnotationLength: _.size(results.some),
                            needPartialAnnotationShowVideosList: false,

                            needFullAnnotation: _.map(results.all, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            needFullAnnotationLength: _.size(results.all),
                            needFullAnnotationShowVideosList: false,

                            allInProgress: _.map(results.all_in_progress, (res) =>
                                _.pick(res, 'video_availability_label')
                            ),
                            allInProgressLength: _.size(results.all_in_progress),
                            allInProgressShowVideosList: false,

                            someInProgress: results.some_in_progress,
                            someInProgressLength: _.size(results.some_in_progress),
                            someInProgressShowVideosList: false,

                            missingSegmentsForVideo: Object.values(results.availability)
                                .filter((o) => !o.exists)
                                .map((o) => {
                                    return {
                                        ...o,
                                        message: o.message.replace('boundaries', 'Segments'),
                                    }
                                }),

                            videosLength:
                                _.size(results.none) +
                                _.size(results.some) +
                                _.size(results.all) +
                                _.size(results.all_in_progress) +
                                _.size(results.some_in_progress),
                        },
                    }
                })
                .catch((error) => {
                    return $q.reject(error)
                })
        },

        getQueryPreview: function (
            table: string,
            json: string | string[],
            cancelPromise: ng.IPromise<unknown>,
            workflowUri?: string,
            taskUri?: string
        ) {
            const jsonData = _.isArray(json) ? { jsonArray: json } : { json }
            const project_id = sessionStorage.getItem(SESSION_STORAGE_SELECTED_PROJECT)
            const data = _.assign({ params: { table, ...jsonData }, timeout: cancelPromise })

            if (workflowUri) {
                _.assign(data.params, { workflowUri })
            }

            if (taskUri) {
                _.assign(data.params, { taskUri })
            }

            if (project_id) {
                _.assign(data.params, { project_id })
            }

            return $http
                .get<{
                    fields: IField[]
                    results: TTableRowData[]
                }>('/api/admin/taskElements/preview-selection', { ...data })
                .then((res) => res.data)
        },

        getVideoSelectionPreview(table: string, json: string | string[]) {
            const jsonData = _.isArray(json) ? { jsonArray: json } : { json }
            const project_id = sessionStorage.getItem(SESSION_STORAGE_SELECTED_PROJECT)
            const data = _.assign({ params: { table, ...jsonData } })

            if (project_id) {
                _.assign(data.params, { project_id })
            }

            return $http
                .get<{
                    fields: IField[]
                    results: TTableRowData[]
                }>('/api/admin/taskElements/preview-selection', { ...data })
                .then((res) => res.data)
        },

        getMarkersFromPMD(map3VideoId: string) {
            return $http
                .get<
                    {
                        markerSetId: string
                        createdAt: string
                        createdBy: string
                        hitId: string
                    }[]
                >(`/api/admin/taskElements/get-markers/${map3VideoId}`)
                .then((res) => {
                    return res.data
                })
        },

        getMarkerWorkflows() {
            return $http
                .get<TMarkerWorkflow[]>(`/api/admin/dashboard/get-redeliver`)
                .then((res) => res.data)
        },

        getSMSVideos() {
            return $http.get<TSMSVideo[]>(`${SMS_API_URL}/statuses/all`).then((res) => res.data)
        },

        checkSMSVideoStatus(eidrL2: string) {
            return $http
                .get<TEidrL2Status>(`${SMS_API_URL}/ingest/${eidrL2}`)
                .then((res) => res.data)
        },

        ingestVideoFor(eidrL2: string[], username: any, source = 'all') {
            return $http
                .post<TEidrL2Status[]>(`${SMS_API_URL}/ingest?type=${source}`, {
                    eidrL2s: eidrL2,
                    requester: username,
                })
                .then((res) => res.data)
        },

        /**
         * Transform a frontend editable workflow to a backend one.
         * Main difference is that frontend keeps a separate `graph`
         * property for `nodes` and `edges`
         *
         * @private
         *
         * @param {Object} workflow
         * @return {Object}
         */
        $transformWorkflowForBackend: function (wf: Workflow) {
            type BackendWorkflow = Omit<Workflow, 'graph'> & {
                nodes: Graph['nodes']
                edges: Graph['edges']
            }
            const workflow = angular.copy(wf) as unknown as BackendWorkflow

            workflow.nodes = _.filter(_.get(wf, 'graph.nodes', []) as GraphNode[], notProvisional)
            workflow.edges = _.filter(_.get(wf, 'graph.edges', []) as GraphNode[], notProvisional)

            return _.omit(workflow, 'graph')

            function notProvisional(item: GraphNode | GraphEdge) {
                return !item.$$isProvisional
            }
        },
    }

    return AdminModule
}

export type AdminModuleInstance = ReturnType<typeof AdminModuleFactory>
