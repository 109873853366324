import _ from 'lodash'
import GraphSearch from 'directives/graph/GraphSearch'

export default /* @ngInject */ function dynamicWorkflowDirective(
    AdminModule,
    Notification,
    ErrorStringifier
) {
    var directive = {
        restrict: 'E',
        templateUrl: 'js/admin/workflow-wizard/directives/dynamicWorkflow.tpl.html',
        link: dynamicWorkflowLinkFn,
        scope: {
            workflow: '<',
            node: '<',
            graph: '<',
            isEditable: '<?',
            taskConfig: '=',
        },
    }

    return directive

    /////////////////////////////////////

    function dynamicWorkflowLinkFn(scope) {
        scope.isPartialAnnotationEnabled = function () {
            const template = _.get(scope.node, 'info.wizards.template')

            return template !== 'AssistedAnnotate'
        }
        scope.isEditable = scope.isEditable !== false

        scope.isAnnotateThroughQA = scope.taskConfig.extend_input =
            GraphSearch.isChainedUpstream(scope.graph, scope.node, [
                { info: { wizards: { template: 'QATask' } } },
                { info: { wizards: { template: 'AssistedAnnotate' } } },
            ]) ||
            GraphSearch.isChainedUpstream(scope.graph, scope.node, [
                { info: { wizards: { template: 'QATask' } } },
                { info: { wizards: { template: 'QuestionVideos' } } },
            ])

        activate()

        /////////////////////////////

        function activate() {
            // for display only old tasks that never got video_manipulation set, do not display anything
            if (!scope.isEditable && !scope.taskConfig.video_manipulation_data) {
                scope.hideForOldTask = true
                return
            }

            // for use data from the taskConfig
            if (scope.isEditable) {
                loadDataFromBackend()
            } else {
                scope.questionsValid = scope.videosValid = true
                scope.vmdResults = scope.taskConfig.video_manipulation_data.results
            }
        }

        function loadDataFromBackend() {
            const questionNodes = GraphSearch.searchAllUpstream(scope.graph, scope.node, {
                info: { wizards: { template: 'GetQuestions' } },
            })
            const questionsConfigs = _.map(questionNodes, 'info.config')
            // questions are valid if we have questions selected for all configs
            scope.questionsValid =
                questionsConfigs.length && _.every(questionsConfigs, 'selection.length')

            if (!scope.questionsValid && scope.node.info.wizards.template === 'CutnTag') {
                // If we do not have valid question selection and we are inside Cut & Tag
                // check for controlled vocabulary or free input text
                scope.questionsValid =
                    _.every(questionsConfigs, 'free_input_text_labels') ||
                    _.every(questionsConfigs, 'controlledVocabulary')
            }

            const videoNodes = GraphSearch.searchAllUpstream(scope.graph, scope.node, {
                info: { wizards: { template: 'GetVideos' } },
            })
            const videoConfigs = _.map(videoNodes, 'info.config')
            scope.videosValid =
                videoConfigs.length &&
                _.every(videoConfigs, (videoConfig) => {
                    return !!(videoConfig.json || _.size(videoConfig.jsonArray))
                })

            if (!(scope.videosValid && scope.questionsValid)) {
                // we do not have valid data, discontinue execution
                return
            }

            const groupId = _.get(scope, 'workflow.owner')
            const projectId = _.get(scope, 'workflow.project')

            AdminModule.getAnnotationCompletion({
                groupId,
                projectId,
                videoConfigs,
                questionsConfigs,
                template: scope.node.info.wizards.template,
            })
                .then((data) => {
                    scope.taskConfig.video_manipulation_data = data
                    scope.vmdResults = scope.taskConfig.video_manipulation_data.results
                })
                .catch((error) => {
                    const errorMessage = ErrorStringifier.stringify(error)
                    Notification.error(`Something went wrong ${errorMessage}`)

                    scope.vmdResults = null
                    scope.hasError = errorMessage
                })
        }
    }
}
