import map3core from 'app.core'
import queryBuilder from './directives/query-builder/module'
import TASK_DEFINITIONS from './TASK_DEFINITIONS.constant'
import VIDEO_TYPE_DEFINITONS from './VIDEO_TYPE_DEFINITIONS.constant'

import routes from './routes'

import AdminUsersModuleFactory from './AdminUsersModule.factory'
import AdminTasksListModuleFactory from './AdminTasksListModule.factory'
import AdminModuleFactory from './AdminModule.factory'
import TasksExportModalFactory from './TasksExportModal.factory'
import DeltaExportModalFactory from './DeltaExportModal.factory'
import MonthlyExportModalFactory from './MonthlyExportModal.factory'

import querySelectionComponent from './workflow-wizard/components/querySelection.component'
import queryBuilderPreviewComponent from './workflow-wizard/components/queryBuilderPreview.component'
import acknowledgeSkipQaComponent from './workflow-wizard/components/acknowledgeSkipQa.component'
import showFullS3PathComponent from './workflow-wizard/components/showFullS3Path.component'
import mwdTaskSelectionDirective from './workflow-wizard/directives/mwdTaskSelection.directive'
import dynamicWorkflowDirective from './workflow-wizard/directives/dynamicWorkflow.directive'
import scenesCompletionDirective from './workflow-wizard/directives/scenesCompletion.directive'
import minutesAsDaysInputDirective from './workflow-wizard/directives/minutesAsDaysInput.directive'
import missingSegmentsNotifierComponent from './workflow-wizard/components/missingSegmentsNotifier'

import uniqueFreeTextQuestionValidatorDirective from './directives/uniqueFreeTextQuestionValidator.directive'
import createQuestionsDirective from './directives/createQuestions.directive'
import timeSpentDirective from './directives/timeSpent.directive'
import queryBuilderPreviewTableDirecive from './directives/query-builder/queryBuilderPreviewTable.directive'
import queryPreviewAndSelectionTableDirective from './directives/queryPreviewAndSelectionTable.directive'
import gaugeChartDirective from './directives/gaugeChart.directive'
import donutChartDirective from './directives/donutChart.directive'
import pieChartDirective from './directives/pieChart.directive'
import lineChartDirective from './directives/lineChart.directive'
import stackedBarChartDirective from './directives/stackedBarChart.directive'
import keepAliveChartDirective from './directives/keepAliveChart.directive'
import episodeTitleByCutTypeDirective from './directives/episodeTitleByCutType.directive'
import { AdminDashboardDeferredLoaderFactory } from './AdminDashboard.ctrl'
import { AdminTasksListDataLoaderFactory } from './AdminTasksList.ctrl'
import markerTasksToLoadComponent from './workflow-wizard/components/markerTasksToLoad.component'

import timestampFilter from '../filters/timestamp.filter'

/**
 * @ngdoc module
 * @name map3.admin
 * @module map3.admin
 *
 * @description
 * Admin module
 */
const map3admin = angular
    .module('map3.admin', [map3core, queryBuilder])
    .constant('TASK_DEFINITIONS', TASK_DEFINITIONS)
    .constant('VIDEO_TYPE_DEFINITONS', VIDEO_TYPE_DEFINITONS)
    .config(routes)

    .factory('AdminUsersModule', AdminUsersModuleFactory)
    .factory('AdminTasksListModule', AdminTasksListModuleFactory)
    .factory('AdminModule', AdminModuleFactory)
    .factory('AdminDashboardDeferredLoader', AdminDashboardDeferredLoaderFactory)
    .factory('AdminTasksListDataLoader', AdminTasksListDataLoaderFactory)
    .factory('TasksExportModal', TasksExportModalFactory)
    .factory('DeltaExportModal', DeltaExportModalFactory)
    .factory('MonthlyExportModal', MonthlyExportModalFactory)

    .directive('mwdTaskSelection', mwdTaskSelectionDirective)
    .directive('dynamicWorkflow', dynamicWorkflowDirective)
    .directive('minutesAsDaysInput', minutesAsDaysInputDirective)
    .directive(
        'uniqueFreeTextQuestionValidator',
        uniqueFreeTextQuestionValidatorDirective as ng.IDirectiveFactory
    )
    .directive('createQuestions', createQuestionsDirective)
    .directive('queryPreviewAndSelectionTable', queryPreviewAndSelectionTableDirective)
    .directive('gaugeChart', gaugeChartDirective)
    .directive('donutChart', donutChartDirective)
    .directive('pieChart', pieChartDirective)
    .directive('lineChart', lineChartDirective)
    .directive('stackedBarChart', stackedBarChartDirective)
    .directive('keepAliveChart', keepAliveChartDirective)
    .directive('episodeTitleByCutType', episodeTitleByCutTypeDirective)
    .directive('queryBuilderPreviewTable', queryBuilderPreviewTableDirecive)
    .directive('scenesCompletion', scenesCompletionDirective)
    .directive('timeSpent', timeSpentDirective)

    .component('acknowledgeSkipQa', acknowledgeSkipQaComponent)
    .component('showFullS3Path', showFullS3PathComponent)
    .component('querySelection', querySelectionComponent)
    .component('queryBuilderPreview', queryBuilderPreviewComponent)
    .component('markerTasksToLoad', markerTasksToLoadComponent)
    .component('missingSegmentsNotifier', missingSegmentsNotifierComponent)

    .filter('timestamp', timestampFilter)

export default map3admin.name
