import _ from 'lodash'

export default function recordedAnswerForSingleQuestionDirective() {
    const directive = {
        restrict: 'E',
        scope: {
            question: '<',
            annotation: '<',
        },
        templateUrl: 'js/directives/recorded-answers/recordedAnswerForSingleQuestion.tpl.html',
        link: recordedAnswerForSingleQuestionLinkFn,
    }

    return directive

    ////////////////////////////////////////////

    function recordedAnswerForSingleQuestionLinkFn(scope) {
        scope.answer = $getAnswerForQuestion(scope.question, scope.annotation.answers)

        scope.$getLabelForAnswer = $getLabelForAnswer
        scope.$getAnswerForQuestion = $getAnswerForQuestion
    }

    function $getAnswerForQuestion(question, answers) {
        return _.find(answers, { id: question && question.uri })
    }

    function $getLabelForAnswer(question, answerValue) {
        const value = answerValue && answerValue.value
        const answerLabel = answerValue && answerValue.label

        const questionLabel = _.get(_.find(question.values, { value }), 'label', false)

        // return either the question label, or the label saved in the answer value
        return questionLabel || answerLabel
    }
}
