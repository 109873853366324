export default /* @ngInject */ function triggerResizeOnClickDirective($window) {
    let directive = {
        limit: 'A',
        link: triggerResizeOnClickLinkFn,
    }

    return directive

    function triggerResizeOnClickLinkFn(scope, element) {
        element.on('click', () => {
            $($window).trigger('resize')
        })

        scope.$on('$destroy', () => element.off('click'))
    }
}
