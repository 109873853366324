if (process.env.NODE_ENV !== 'production') {
    var defaultTheme = require('scss/style.default.useable.scss')
    var darkTheme = require('scss/style.dark.useable.scss')
}

export default function ThemeManagerFactory() {
    if (process.env.NODE_ENV === 'production') {
        return window.MAP3_THEMES
    }

    const STORAGE_SELECTED_THEME_KEY = 'map3_theme_selected'

    // MUST be kept in sync with MAP3_THEMES service in app/index.html.ejs
    const ThemeManager = {
        list: {
            default: defaultTheme,
            dark: darkTheme,
        },

        selected: null,

        toggle() {
            let themeNames = Object.keys(ThemeManager.list)
            let idx = themeNames.indexOf(ThemeManager.selected)

            return ThemeManager.use(themeNames[++idx] || themeNames[0])
        },

        use(themeName) {
            const theme = ThemeManager.list[themeName]
            if (!theme) {
                return false
            }

            if (ThemeManager.selected) {
                ThemeManager.list[ThemeManager.selected].unuse()
            }

            ThemeManager.selected = themeName
            ThemeManager.list[ThemeManager.selected].use()

            setStoredTheme(themeName)

            return true
        },

        init() {
            ThemeManager.use(getStoredTheme())
        },
    }

    function setStoredTheme(themeName) {
        try {
            window.localStorage.setItem(STORAGE_SELECTED_THEME_KEY, themeName)
        } catch (e) {}
    }

    function getStoredTheme() {
        try {
            return window.localStorage.getItem(STORAGE_SELECTED_THEME_KEY) || 'default'
        } catch (e) {
            return 'default'
        }
    }

    window.MAP3_THEMES = ThemeManager

    return ThemeManager
}
