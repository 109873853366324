import ReduxActions from '../ReduxActions'
import type ngRedux from 'ng-redux'

/**
 * @ngdoc service
 * @name ActActions
 * @module map3.scenes
 *
 * @description
 * A bound {@link ActActionCreators}
 *
 * @see ActActionsCreators
 */
export default /* @ngInject */ function ActActionsFactory(
    $ngRedux: ngRedux.INgRedux,
    ActActionCreators: ActActionCreatorsInstance
) {
    return ReduxActions.bindActionCreators(ActActionCreators, $ngRedux.dispatch)
}

/**
 * @ngdoc service
 * @name ActActionCreators
 * @module map3.scenes
 *
 * @description
 * Actions that can be performed on {@link SceneData#acts SceneData#acts}
 *
 * Any action sent here will update the {@link $ngRedux} state using the FLUX model
 */
export function ActActionCreatorsFactory() {
    const createAction = ReduxActions.createAction
    const require = ReduxActions.require
    const defaults = ReduxActions.defaults

    const ActActionCreators = {
        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#splitByVideoCurrentTime
         * @description
         * Split acts by videoApi.getCurrentTime()
         *
         * @param {videoAPI} payload VideoAPI instance
         */
        splitByVideoCurrentTime: createAction('ACTS_SPLIT_BY_VIDEO_CURRENT_TIME'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#selectById
         * @description
         * Select a act by id
         *
         * @param {number} payload Scene id
         */
        selectById: createAction('ACTS_SELECT_BY_ID'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#selectByIndex
         * @description
         * Select a act by index
         *
         * @param {number} payload Index
         */
        selectByIndex: createAction('ACTS_SELECT_BY_INDEX'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#selectNext
         * @description
         * Select the next act
         *
         * @param {number=} payload Offset, default `+1`
         */
        selectNext: createAction('ACTS_SELECT_BY_OFFSET', defaults(1)),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#selectPrevious
         * @description
         * Select the previous act
         *
         * @param {number=} payload Offset, default `-1`
         */
        selectPrevious: createAction('ACTS_SELECT_BY_OFFSET', defaults(-1)),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#mergeSelected
         * @description
         * Merge currently selected acts {@link Scene#$$isSelected Scene.$$isSelected}
         * into a single act
         */
        mergeSelected: createAction('ACTS_MERGE_SELECTED'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#moveStartFrame
         * @description
         * Move act start by offset frames
         *
         * @param {Object} payload `{ id: number, offsetFrames: number }`
         */
        moveStartFrame: createAction(
            'ACTS_MOVE_START',
            require<{ id: number; offsetFrames: number }>('id', 'offsetFrames')
        ),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#moveSelectedStartFrame
         * @description
         * Move single selected act start by offset frames
         *
         * @param {Object} payload Offset frames
         */
        moveSelectedStartFrame: createAction('ACTS_MOVE_SELECTED_START'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#moveEndFrame
         * @description
         * Move act end by offset frames
         *
         * @param {Object} payload `{ id: number, offsetFrames: number }`
         */
        moveEndFrame: createAction('ACTS_MOVE_END', require('id', 'offsetFrames')),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name ActActionCreators#moveSelectedEndFrame
         * @description
         * Move single selected act end by offset frames
         *
         * @param {Object} payload Offset frames
         */
        moveSelectedEndFrame: createAction('ACTS_MOVE_SELECTED_END'),
    }

    return ActActionCreators
}

export type ActActionCreatorsInstance = ReturnType<typeof ActActionCreatorsFactory>
export type ActActionsInstance = ReturnType<typeof ActActionsFactory>
