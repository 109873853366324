const querySelection = {
    controller: querySelectionController,
    bindings: {
        table: '<',
        jsonArray: '=',
    },
    template: `
    
        <button
            ng-click="$ctrl.openModal()"
            class="btn btn-outline-primary"
            ng-disabled="!$ctrl.table"
            type="button"
        ">
            Search for videos
        </button>
    `,
}

export default querySelection

interface ScopeBindings extends ng.IComponentController {
    table: unknown
    jsonArray: string[]
    excludedVideoIds: string[] | undefined
}

/* @ngInject */
function querySelectionController(this: unknown, $uibModal: ng.ui.bootstrap.IModalService) {
    const $ctrl = this as ScopeBindings

    $ctrl.openModal = openModal

    return $ctrl

    //////////////////////////

    function openModal() {
        const modalInstance = $uibModal.open({
            template: `
                
                <div>
                    <query-builder
                        table="vm.table"
                        json="vm.json"
                    ></query-builder>
                </div>
                <header class="modal-footer">
                    <button ng-click="$dismiss()" close-modal class="btn" type="button">Close</button>
                    <button
                        ng-click="$close(vm.json)"
                        class="btn btn-primary"
                        type="submit"
                    >
                        Save
                    </button>
                </header>
                <synthetic-scroll synthetic-scroll-target="table" class="mt-1">
                    <query-builder-preview-table
                        table="vm.table.table_name"
                        json="vm.json"
                    ></query-builder-preview-table>
                </synthetic-scroll>
                <footer class="modal-footer mt-2">
                    <button ng-click="$dismiss()" close-modal class="btn" type="button">Close</button>
                    <button
                        ng-click="$close(vm.json)"
                        class="btn btn-primary"
                        type="submit"
                    >
                        Save
                    </button>
                </footer>
            `,
            controller: /* @ngInject */ function (this: unknown, table: unknown) {
                const vm: {
                    table: unknown
                    json: string | undefined
                } = this as any

                vm.table = table
                vm.json = undefined
            },
            controllerAs: 'vm',
            resolve: {
                table: () => $ctrl.table,
            },
            size: 'xl',
        })

        modalInstance.result.then((json) => {
            $ctrl.jsonArray.push(json)
        })
    }
}
