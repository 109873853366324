import _ from 'lodash'

export default /* @ngInject */ function CaptureRequestDebugDataInterceptorFactory(
    $log: ng.ILogService,
    $injector: any
) {
    const IGNORE_PATTERNS = [
        /\.html/,
        /revision-info\.json/,
        /\/api\/communication/,
        /\/api\/time-tracker/,
    ]

    let User: any

    const CaptureRequestDebugDataInterceptor = {
        response(response: { [key: string]: any }) {
            if (!User) {
                User = $injector.get('User')
            }

            const matchesIgnore = _.some(IGNORE_PATTERNS, (pattern) =>
                pattern.test(response.config.url)
            )
            if (!matchesIgnore) {
                User.storeRequestDebugData(response)
            }

            return response
        },
    }

    return CaptureRequestDebugDataInterceptor
}
