import _ from 'lodash'
import browserInfo from 'browser-info'
import markExceptionHandled from 'util/markExceptionHandled'

export default /* @ngInject */ function VideoServiceFactory(
    NO_LOAD_OVERLAY,
    $http,
    $state,
    $q,
    $log,
    $timeout,
    $rootScope,
    Upload,
    Notification
) {
    const uploadResumeChunkSize = '1MB'

    const browser = browserInfo()

    let activeUploads = 0

    const VideoService = {
        /**
         * Get the video data for a particular HIT/Assignment ID
         *
         * @param {String} hitOrAssignmentId
         * @return {Promise} {video: { url }, videoName, seriesTitle, seasonNumber, episodeNumber, episodeTitle}
         */
        get: function (hitOrAssignmentId) {
            return $http
                .get('/api/video/' + hitOrAssignmentId)
                .then((res) => res.data)
                .then(function (videoData) {
                    return VideoService.processVideo(videoData, hitOrAssignmentId)
                })
        },

        /**
         * Given raw video data, select the proper stream for Safary or other browsers,
         * and attach player popup url
         *
         * @param {Object} videoData
         * @param {string=} hitOrAssignmentId
         * @return {Promise} {video: { url }, videoName, seriesTitle, seasonNumber, episodeNumber, episodeTitle}
         */
        processVideo: function (videoData, hitOrAssignmentId = null) {
            return $q.when(videoData).then(selectVideoBasedOnBrowser).then(attachPopupVideoURL)

            /////////////////////

            function selectVideoBasedOnBrowser(videoData) {
                if (_.has(videoData, 'video.hls') && browser.name === 'Safari') {
                    videoData.video = videoData.video.hls
                } else if (_.has(videoData, 'video.dash')) {
                    videoData.video = videoData.video.dash
                } else {
                    throw new Error(
                        'Cannot extract video from video data: ' + angular.toJson(videoData)
                    )
                }

                return videoData
            }

            function attachPopupVideoURL(videoData) {
                if (hitOrAssignmentId) {
                    videoData.popupVideoURL = $state.href('video.popup', { id: hitOrAssignmentId })
                }

                return videoData
            }
        },

        /**
         * Start the upload process for a video object and a file.
         *
         * This can be either a fresh upload, or a resumption of a cold upload
         *
         * Once the upload starts, the following extra properties are added to video:
         *  - $upload => The upload promise object
         *  - $uplaoding => boolean if the upload is currently active
         *  - $uploadConfig => Upload configuration, to be used if we need to do a soft resumption
         *  - $uploadInternalConfig => The internal config object of ngfUpload. It allows us
         *                             access to the raw XHR object for pause/abort
         *
         * @param {Object} video
         * @param {Object} file
         */
        uploadVideo: function (video, file) {
            video.$uploadConfig = {
                url: '/api/admin/uploads/upload/' + video.videoId,
                resumeSizeUrl: '/api/admin/uploads/size/' + video.videoId,
                resumeSizeResponseReader: function (data) {
                    return data.size
                },
                resumeChunkSize: uploadResumeChunkSize,
                data: { file },
                ignoreLoadingBar: true,
            }

            return trackedUpload(video)
        },

        /**
         * Resume a warm video (a video that has an $uploadConfig attached)
         *
         * @param {Object} video
         */
        resumeVideoUpload: function (video) {
            if (!video.$uploadConfig) {
                throw new Error('Invalid video given for resume')
            }

            return trackedUpload(video)
        },

        /**
         * Abort a currently uploading video
         *
         * @param {Object} video
         */
        abortVideoUpload: function (video) {
            if (!video.$uploadInternalConfig.__XHR) {
                throw new Error('Invalid video given for abort')
            }

            $rootScope.$ignorePUR()
            video.$uploadInternalConfig.__XHR.abort()
        },

        getAllUploads: function () {
            return $http.get('/api/admin/uploads').then((res) => res.data)
        },

        createVideo: function (videoData) {
            return $http.post('/api/admin/uploads/create', videoData).then((res) => res.data)
        },

        updateVideoMetadata: function (video) {
            return $http
                .post('/api/admin/uploads/metadata/' + video.videoId, video)
                .then((res) => res.data)
        },

        deleteVideo: function (video) {
            return $http.post('/api/admin/uploads/delete/' + video.videoId)
        },
    }

    /**
     * Handle upload of video with `$uploadConfig` attached
     *
     * This function will keep track of the number of active uploads
     * and show a warning on window unload event if there are still active uploads
     *
     * @param {Object} video
     */
    function trackedUpload(video) {
        if (!video.$uploadConfig) {
            throw new Error('Invalid video given for upload')
        }

        $rootScope.$ignorePUR()

        video.$uploading = true

        activeUploads++
        $(window).on('beforeunload', videoCancelWarnig)

        // must be _.cloneDeep() instead of angular.copy()
        video.$uploadInternalConfig = _.cloneDeep(video.$uploadConfig)
        video.$upload = Upload.upload(video.$uploadInternalConfig, /* internal */ true)

        video.$upload.then(() => resetVideo(video))
        video.$upload.catch(function (response) {
            if (response.status === -1) {
                // assume intentional pause of the upload
                video.$uploading = false
                // overwrite the error notification for the rejection on the next tick
                $timeout(() => Notification.success('Video upload paused'))

                return markExceptionHandled($q.reject(response))
            } else {
                resetVideo(video)

                Notification.error(response)

                return $q.reject(response)
            }
        })

        video.$upload.finally(function () {
            activeUploads--

            if (activeUploads === 0) {
                $(window).off('beforeunload', videoCancelWarnig)
            }
        })

        return markExceptionHandled(video.$upload)

        ////////////////////////////////////////////////

        function videoCancelWarnig(e) {
            /* eslint-disable no-multi-spaces */
            let confirmationMessage = 'Your video upload will be canceled if you leave the page now'

            e.returnValue = confirmationMessage // Gecko, Trident, Chrome 34+

            return confirmationMessage // Gecko, WebKit, Chrome <34
        }

        /**
         * Remove the internal video upload properties from the video object
         */
        function resetVideo(video) {
            delete video.$upload
            delete video.$uploading
            delete video.$uploadConfig
            delete video.$uploadInternalConfig
        }
    }

    return VideoService
}
