/**
 * @ngdoc directive
 * @name uniqueEmailValidator
 * @module map3.core
 * @restrict A
 *
 * @description
 * An async validator for email uniqueness.
 *
 * @param {boolean} uniqueEmailValidator If the validator is active
 * @param {expression=} uniqueEmailAllowed A manually allowed value for the validator.
 *      Used when editing existing users, and their current email should be
 *      allowed.
 */
export /* @ngInject */ function uniqueEmailValidatorDirective($q, $http, $parse) {
    var directive = {
        restrict: 'A',
        require: 'ngModel',
        link: uniqueEmailValidatorLinkFn,
    }

    return directive

    ///////////////////

    function uniqueEmailValidatorLinkFn(scope, element, attrs, ngModel) {
        var allowed = $parse(attrs.uniqueEmailAllowed)(scope)

        if ($parse(attrs.uniqueEmailValidator)(scope)) {
            ngModel.$asyncValidators.uniqueEmail = uniqueEmailValidator
        }

        function uniqueEmailValidator(modelValue, viewValue) {
            var value = modelValue || viewValue
            if (value === allowed) {
                return $q.resolve(true)
            }

            return $http
                .post('/api/admin/users/availability', {
                    email: value,
                })
                .then((res) => {
                    if (res.data && res.data.email) {
                        return true
                    } else {
                        return $q.reject()
                    }
                })
        }
    }
}

/**
 * @ngdoc directive
 * @name uniqueUsernameValidator
 * @module map3.core
 * @restrict A
 *
 * @description
 * An async validator for username uniqueness.
 *
 * @param {boolean} uniqueUsernameValidator If the validator is active
 * @param {expression=} uniqueUsernameAllowed A manually allowed value for the validator.
 *      Used when editing existing users, and their current username should be
 *      allowed.
 */
export /* @ngInject */ function uniqueUsernameValidatorDirective($q, $http, $parse) {
    var directive = {
        restrict: 'A',
        require: 'ngModel',
        link: uniqueUsernameValidatorLinkFn,
    }

    return directive

    ///////////////////

    function uniqueUsernameValidatorLinkFn(scope, element, attrs, ngModel) {
        var allowed = $parse(attrs.uniqueUsernameAllowed)(scope)

        if ($parse(attrs.uniqueUsernameValidator)(scope)) {
            ngModel.$asyncValidators.uniqueUsername = uniqueUsernameValidator
        }

        function uniqueUsernameValidator(modelValue, viewValue) {
            var value = modelValue || viewValue
            if (value === allowed) {
                return $q.resolve(true)
            }

            return $http
                .post('/api/admin/users/availability', {
                    username: value,
                })
                .then((res) => {
                    if (res.data && res.data.username) {
                        return true
                    } else {
                        return $q.reject()
                    }
                })
        }
    }
}
