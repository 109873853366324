import _ from 'lodash'
import * as d3 from 'd3'
import d3tip from 'd3-tip'

export default /* @ngInject */ function stackedBarChart($window) {
    let directive = {
        restrict: 'E',
        link: stackedBarChartLinkFn,
        scope: {
            chartData: '<',
            sortBy: '<',
        },
    }

    return directive

    function stackedBarChartLinkFn(scope, element, attrs) {
        let tip = d3tip()
            .attr('class', 'd3-tip')
            .offset([-10, 0])
            .html(function (d) {
                return `
                    <div class='graph-tooltip tooltip-top' style='background-color: #9ea6ac; border-color: #9ea6ac;'>
                        <section class='tooltip-arrow'  style='background-color: #9ea6ac;'></section>
                        <strong>${d[1] - d[0]} </strong>
                    </div>
                `
            })
        let $$margins = {
            top: 20,
            right: 20,
            bottom: 25,
            left: 60,
        }
        let config = {
            width: element.width(),
            height: element.width() * 0.15,
            barHeight: 40,
        }
        // Set height on element so we can scroll the chart
        element.css('height', config.height)
        let chartData
        let chartHeight
        let chart = d3.select(element[0])
        let svg = chart.append('svg').style('width', config.width)

        svg.call(tip)

        scope.$watchCollection('chartData', function () {
            svg.selectAll('g').remove()
            svg.selectAll('rect').remove()

            if (scope.chartData.length) {
                drawChart()
            } else {
                drawNoDataText()
            }
        })

        angular.element($window).on('resize', handleResizeFn)
        scope.$on('$destroy', function () {
            angular.element($window).off('resize', handleResizeFn)
            if (tip) {
                tip.destroy()
            }
        })

        handleResizeFn()

        function drawChart() {
            chartData = scope.chartData
            chartHeight = $$margins.top + chartData.length * config.barHeight + $$margins.bottom
            svg.style('height', chartHeight)

            // Calculate total tasks
            _.forEach(chartData, (item) => {
                item.qaTasks = item.qa_active + item.qa_assignable
                item.workerTasks = item.worker_active + item.worker_assignable
            })

            svg.append('g').attr('transform', 'translate(0, 0)')

            let xScale = d3
                .scaleLinear()
                .rangeRound([$$margins.left + 10, config.width - $$margins.right])
            let yScale = d3
                .scaleBand()
                .rangeRound([$$margins.top, chartHeight - $$margins.bottom])
                .paddingInner(0.1)
            let color = d3.scaleOrdinal(['#1f77b4', '#ff7f0e'])
            let xAxis = d3.axisBottom(xScale)
            let xAxisTop = d3.axisTop(xScale)
            let yAxis = d3.axisLeft(yScale)

            let stack = d3.stack().keys(scope.sortBy).offset(d3.stackOffsetNone)

            let layers = stack(chartData)

            yScale.domain(chartData.map((d) => d.label))
            xScale.domain([0, d3.max(layers[layers.length - 1], (d) => d[1])]).nice()

            let layer = svg
                .selectAll('.layer')
                .data(layers)
                .enter()
                .append('g')
                .attr('class', 'layer')
                .style('fill', (d, i) => color(i))
            layer
                .selectAll('rect')
                .data((d) => d)
                .enter()
                .append('rect')
                .attr('y', (d) => yScale(d.data.label))
                .attr('x', (d) => xScale(d[0]))
                .attr('height', yScale.bandwidth())
                .attr('width', (d) => xScale(d[1]) - xScale(d[0]))
                .on('mouseover', tip.show)
                .on('mouseout', tip.hide)

            svg.append('g')
                .attr('class', 'axis axis--x')
                .attr('transform', 'translate(0,' + (chartHeight - $$margins.bottom) + ')')
                .call(xAxis)

            svg.append('g')
                .attr('class', 'axis axis--x')
                .attr('transform', 'translate(0,' + $$margins.top + ')')
                .call(xAxisTop)

            svg.append('g')
                .attr('class', 'axis axis--y')
                .attr('width', $$margins.left + 'px')
                .attr('transform', 'translate(' + ($$margins.left + 10) + ', 0)')
                .call(yAxis)
                .selectAll('.axis--y text')
                .call(wrap, $$margins.left)

            svg.append('g')
                .attr('class', 'stacked-bar-grid')
                .attr('transform', 'translate(0,' + $$margins.top + ')')
                .call(xAxis.tickSize(chartData.length * config.barHeight).tickFormat(''))
        }

        function drawNoDataText() {
            svg.append('text')
                .attr('class', 'no-data-text')
                .attr('x', config.width / 2)
                .attr('y', config.height / 2)
                .style('text-anchor', 'middle')
                .text('No Data')
                .style('font-size', '24px')
        }

        function handleResizeFn() {
            svg.remove()

            config.width = element.width()
            config.height = element.width() * 0.2

            element.css('height', config.height)

            svg = chart.append('svg').style('width', config.width + 'px')
            svg.call(tip)

            if (scope.chartData.length) {
                drawChart()
            } else {
                drawNoDataText()
            }
        }

        function wrap(text, width) {
            text.each(function () {
                let text = d3.select(this)
                let titleText = text.text()
                let words = text.text().split(/\s+/)
                let line = []
                let lineNumber = 0
                let lineHeight = 1.1
                let x = text.attr('x')
                let dy = parseFloat(text.attr('dy'))
                let tspan = text
                    .text(null)
                    .append('tspan')
                    .attr('x', x)
                    .attr('dy', dy + 'em')
                text.append('svg:title').text(titleText)
                _.forEach(words, function (word) {
                    line.push(word)
                    tspan.text(line.join(' '))
                    if (tspan.node().getComputedTextLength() > width) {
                        tspan.attr('dy', dy - lineHeight / 2 + 'em')
                        line.pop()
                        lineNumber++
                        tspan.text(line.join(' '))
                        line = [word]
                        tspan = text
                            .append('tspan')
                            .attr('x', x)
                            .attr('text-anchor', 'end')
                            .attr('dy', lineNumber++ * lineHeight + dy + 'em')
                            .text(word)
                    }
                    if (lineNumber > 1) {
                        return false
                    }
                })
            })
        }
    }
}
