import _ from 'lodash'

/**
 * Load one or several resources and then execute the provided directive link function
 *
 * This module aims to provide a simple way for directives that depend on large
 * external resources to load those and have them available for the directive on
 * link time. By necessity, this means that link is guaranteed to be async, which
 * might be problematic in some instances.
 *
 * @param {array} imports Array of import() promises
 * @param {function} linkFn A directive link function
 * @param {function=} loadingLinkFn A directive link function that executes while we wait on the
 *      resources to load
 * @param {function=} errorLinkFn A directive link function that executes on resource loading error.
 *      Defaults to `linkFn`
 *
 * @return {function} A mediary link function
 */
export default function lockAndLoad(
    imports,
    linkFn,
    loadingLinkFn = angular.noop,
    catchLinkFn = linkFn
) {
    return function mediaryLinkFn(scope, ...rest) {
        let $destroyTicket = false

        scope.$on('$destroy', function () {
            $destroyTicket = true
        })

        loadingLinkFn.apply(null, [scope, ...rest]) // eslint-disable-line

        // import can be a function that takes link arguments and returns a promise
        const executedImports = _.map(imports, (i) => {
            if (angular.isFunction(i)) {
                return i.apply(null, [scope, ...rest]) // eslint-disable-line
            }

            return i
        })

        const ret = Promise.all(executedImports)
            .then(function () {
                // check that directive was not destroyed before we could finish initialization
                if ($destroyTicket === true) {
                    return
                }

                scope.$applyAsync(function () {
                    linkFn.apply(null, [scope, ...rest]) // eslint-disable-line
                })
            })
            .catch(function (err) {
                // check that directive was not destroyed before we could finish initialization
                if ($destroyTicket === true) {
                    return
                }

                if (angular.isFunction(catchLinkFn)) {
                    scope.$applyAsync(function () {
                        catchLinkFn.apply(null, [err, scope, ...rest]) // eslint-disable-line
                    })
                } else {
                    throw err
                }
            })

        return ret
    }
}
