import { CommentInterfaceInstance } from './CommentInterface.factory'

interface ScopeBinding {
    CommentInterface: CommentInterfaceInstance
    commentsCount: number
}

export default /* @ngInject */ function commentsDropdownDirective(
    CommentInterface: CommentInterfaceInstance
) {
    const directive = {
        restrict: 'E',
        scope: true,
        template: `
            <div class="dropdown dropup">
                <button id="comments-dropdown"
                    class="btn btn-outline-light dropdown-toggle btn-sm w-100"
                    data-toggle="dropdown"
                >
                    <i class="material-icons md-18">more_vert</i>
                    Comments
                    ({{ commentsCount }})
                </button>

                <section class="dropdown-menu dropdown-menu-right text-nowrap"
                    aria-labelledby="comments-dropdown"
                >
                    <comment-view-all component-class="dropdown-item"></comment-view-all>

                    <button ng-if="::CommentInterface.hasOnlyGeneralThread"
                        ng-click="CommentInterface.emitAddBookmark()"
                        class="dropdown-item" role="button"
                    >
                        <i class="material-icons md-18">star</i>
                        Add Bookmark
                    </button>

                    <comment-add-bookmark ng-if="::!CommentInterface.hasOnlyGeneralThread"
                        component-class="dropdown-item"
                    ></comment-add-bookmarkb>
                </section>
            </div>
        `,
        link: commentsDropDownLinkFn,
    }

    return directive

    ///////////////////////

    function commentsDropDownLinkFn(scope: ng.IScope & ScopeBinding) {
        scope.CommentInterface = CommentInterface

        scope.$watch(
            () => CommentInterface.getCommentsCount(),
            (commentsCount) => {
                scope.commentsCount = commentsCount
            }
        )
    }
}
