import routes from './routes'
import UserFactory from './User.factory'
import RoutingAuthFactory from './RoutingAuth.factory'

/**
 * @ngdoc module
 * @name map3.auth
 * @module map3.auth
 *
 * @description
 * Auth Module for MAP3.
 */
const map3auth = angular
    .module('map3.auth', [require('angular-ui-router')])

    .config(routes)
    .config(
        /* @ngInject */ function ($urlRouterProvider: ng.ui.IUrlRouterProvider) {
            // do not start the automatic ui-router listener.
            // Instead wait for RoutingAuth.init() to be called
            $urlRouterProvider.deferIntercept()
        }
    )

    .factory('User', UserFactory)
    .factory('RoutingAuth', RoutingAuthFactory)

export default map3auth.name
