import _ from 'lodash'

/**
 * Directive for answering task questions.
 * Initially developed to unify QA and Worker annotation edit workflow
 *
 * @param {Array(Question)} questions Array of question definitions
 * @param {Annotation} annotation Assignable annotation
 */
export default function answerQuestionsSideBySideDirective() {
    let directive = {
        restrict: 'E',
        scope: {
            questions: '<',
            annotation: '=',
            onSort: '&',
            ngDisabled: '<',
        },
        templateUrl: 'js/directives/answer-questions/answerQuestionsSideBySide.tpl.html',
        link: answerQuestionsLinkFn,
    }

    return directive

    /////////////////////////////////////

    function answerQuestionsLinkFn(scope, element, attrs) {
        scope.activeQuestion = scope.questions[0]
        scope.sortEnabled = !!attrs.onSort
        scope.$onSort = $onSort

        scope.findAnswerForQuestion = findAnswerForQuestion

        scope.activateQuestion = activateQuestion

        activate()

        ////////////////////////////////////////////////////

        function activate() {
            scope.$watch('annotation', function () {
                if (!scope.annotation) {
                    scope.annotation = {}
                }
                if (!_.isArray(scope.annotation.answers)) {
                    scope.annotation.answers = buildAnswersFromQuestions(scope.questions)
                }
            })
        }

        function activateQuestion(question) {
            scope.activeQuestion = question
        }

        /**
         * Call external on sort handler
         */
        function $onSort($item, $partFrom, $partTo, $indexFrom, $indexTo) {
            scope.onSort({
                $item: $item,
                $partFrom: $partFrom,
                $partTo: $partTo,
                $indexFrom: $indexFrom,
                $indexTo: $indexTo,
            })
        }

        function buildAnswersFromQuestions(questions, withDetails = true) {
            return _.map(questions, function (question) {
                let answer = {
                    id: question.uri,
                    value: null,
                }

                if (withDetails && question.details) {
                    answer.detailsRows = []
                    answer.detailsRows.push(
                        _.map(question.details, function (detail) {
                            return {
                                id: detail.uri,
                                placeholder: detail.placeholder,
                                value: null,
                            }
                        })
                    )
                }

                return answer
            })
        }
    }

    function findAnswerForQuestion(answers, question) {
        return _.find(answers, { id: question.uri })
    }
}
