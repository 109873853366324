import _ from 'lodash'
import { Action, createAction, createActions, handleActions, combineActions } from 'redux-actions'
import type ngRedux from 'ng-redux'

type ActionCreator<T = unknown> = (payload?: T) => T
type ActionCreatorVoid<T = unknown> = (payload?: T) => void

/**
 * A simple, bare-bones implementation of https://github.com/acdlite/redux-actions
 */
const ReduxActions = {
    createAction,
    createActions,
    handleActions,
    combineActions,

    /**
     * A action creator helper method to require props on the action payload
     */
    require<T = unknown>(...requiredProps: string[]) {
        function actionCreator(payload: any): T {
            assertPayload(payload)

            return payload
        }

        function assertPayload(payload: any): asserts payload is T {
            const existingProps = _.keys(payload)
            const diff = _.difference(requiredProps, existingProps)

            if (diff.length > 0) {
                throw new Error(`Required action props were not provided: ${diff.join(', ')}`)
            }
        }

        return actionCreator
    },

    /**
     * Specify an actionCreator with a default value
     */
    defaults<T = unknown>(defaultPayload: T) {
        return function actionCreator(newPayload?: T): T {
            return newPayload || defaultPayload
        } as (newPayload?: T) => T
    },

    bindActionCreators<T extends { [K in string]: ActionCreator<any> }>(
        actionCreators: T,
        dispatch: ngRedux.INgRedux['dispatch']
    ): { [K in keyof T]: ActionCreatorVoid<any> } {
        return _.mapValues(actionCreators, function (actionCreator) {
            return ReduxActions.bindActionCreator(actionCreator as ActionCreator<any>, dispatch)
        })
    },

    bindActionCreator<T extends Action<any>>(
        actionCreator: (payload?: T) => T,
        dispatch: ngRedux.INgRedux['dispatch']
    ): ActionCreatorVoid<T> {
        return function boundActionCreator(payload?: T) {
            dispatch(actionCreator(payload))
        }
    },
}

export default ReduxActions
