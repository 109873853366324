import angular from 'angular'

type BugReport = {
    label: string
    value: string
}

type BugTypeResponse = {
    assignee_emails: BugReport[]
    jira_emails: BugReport[]
}

interface AdminSettingsCtrlInstance {
    assignee_emails: BugReport[]
    jira_emails: BugReport[]
    update: (assignees: BugReport[]) => void
}

export default /* @ngInject */ function AdminSettingsCtrl(
    this: unknown,
    $state: ng.ui.IStateService,
    $http: ng.IHttpService,
    User: any,
    bugTypes: BugTypeResponse
) {
    if (!User.isSuperAdmin()) {
        $state.go('auth.denied')
    }
    const vm = this as AdminSettingsCtrlInstance
    vm.assignee_emails = bugTypes.assignee_emails
    vm.jira_emails = bugTypes.jira_emails

    vm.update = updateAssignee

    ///////////////////////////////////

    function updateAssignee(assignees: BugReport[]) {
        return $http.put('/api/super-admin/settings/bulk-update', {
            records: angular.toJson(assignees),
        })
    }
}

AdminSettingsCtrl.resolve = {
    bugTypes: /* @ngInject */ ($http: ng.IHttpService) => {
        return $http.get('/api/super-admin/settings').then((res) => res.data)
    },
}
