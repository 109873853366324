export default /* @ngInject */ function QAScenesServiceFactory(
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    $http: ng.IHttpService
) {
    const QAScenesService = {
        saveScenes(id: string, sceneData: any, rawSceneData: any = null, lastUserAction: string) {
            return $http.put(
                `/api/hit/qa/${id}/scenes`,
                {
                    ...sceneData,
                    rawSceneData: angular.toJson(rawSceneData),
                    lastUserAction,
                },
                NO_LOAD_OVERLAY
            )
        },

        approve(id: string) {
            return $http.post(`/api/hit/qa/${id}/approve`, {})
        },

        reject(id: string) {
            return $http.post(`/api/hit/qa/${id}/reject`, {})
        },

        returnTask(id: string) {
            return $http.post(`/api/hit/qa/${id}/return`, {})
        },
    }

    return QAScenesService
}
