import angular from 'angular'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { DOMUtility } from 'services/DOMUtility.factory'

class WatchVideoTimelineController {
    $element: ng.IAugmentedJQuery
    $scope: ng.IScope

    // eslint-disable-next-line
    videoApi!: VideoAPIInstance

    sharedVideoAPI: any

    elements: any[] = []

    timelineId = 'generic-timeline-' + DOMUtility.nextUid()

    /* @ngInject */
    constructor($element: ng.IAugmentedJQuery, $scope: ng.IScope, SharedVideoAPI: any) {
        this.$element = $element
        this.$scope = $scope

        this.sharedVideoAPI = SharedVideoAPI
    }

    handleOnSingleSelect = angular.noop

    buildElements() {
        const elementUid = 1
        const lastFrameIdx = this.videoApi.getLastFrameIdx()
        const spacerElementType = 'spacer'
        this.elements = [
            {
                id: elementUid,
                type: spacerElementType,
                startFrame: 0,
                endFrame: lastFrameIdx,
                $$uid: `${this.timelineId}-${spacerElementType}-${elementUid}`,
            },
        ]
    }

    handleVideoApiReady(videoApi: VideoAPIInstance) {
        this.videoApi = videoApi
        this.buildElements()
    }

    $onInit() {
        this.$element.addClass('generic-timeline-holder')
        const boundHandleVideoApi = this.handleVideoApiReady.bind(this)
        this.sharedVideoAPI.onLoad(boundHandleVideoApi)

        $('zoom-level').on('click', () => {
            ;(document.activeElement as HTMLElement).blur()
            $(this.timelineId).trigger('focus')
        })

        this.$scope.$on('$destroy', () => $('zoom-level').off('click'))
    }
}

const watchVideoTimelineComponent = {
    controller: WatchVideoTimelineController,
    bindings: {
        filmstripType: '<?',
        renderMode: '<?',
        contentMarkers: '<?',
        timeFormat: '<',
    },
    template: `
        <content-markers-timeline-base
            elements="$ctrl.elements"
            on-single-select="$ctrl.handleOnSingleSelect(element)"
            render-mode="hidden"
            filmstrip-type="$ctrl.filmstripType"
            timeline-id="$ctrl.timelineId"
            time-format="$ctrl.timeFormat"
            class="content-markers-base"
        ></content-markers-timeline-base>
    `,
}

export default watchVideoTimelineComponent
