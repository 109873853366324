export default /* @ngInject */ function scrollToDirective(DOMUtility) {
    const directive = {
        restrict: 'A',
        link: scrollToLinkFn,
    }

    return directive

    function scrollToLinkFn(scope, element, attr) {
        element.on('click', (event) => {
            event.preventDefault()

            scrollToTarget(attr.href)
        })

        function scrollToTarget(target) {
            const duration = parseInt(attr.duration) || 700
            const offset = parseFloat(attr.offset) || 0
            const relative =
                angular.isDefined(attr.scrollToRelative) && scope.scrollToRelative !== false
            // highlight might be boolean or a highlight animation class string
            const highlight = angular.isDefined(attr.highlight) && (attr.highlight || true)

            DOMUtility.scrollToLegacy(target, { duration, offset, relative, highlight })
        }
    }
}
