import { ErrorReplacementId } from 'constants.es6'

export default /* @ngInject */ function NotificationFactory(
    $rootScope: ng.IRootScopeService,
    $timeout: ng.ITimeoutService
) {
    const Notification = {
        error(message: string, nextTick = false, replacementId: ErrorReplacementId | null = null) {
            if (nextTick) {
                $timeout(sendError, 1000)
            } else {
                sendError()
            }

            ///////////////////////////

            function sendError() {
                $rootScope.$emit('notification', { type: 'error', message, replacementId })
            }
        },

        success(message: string) {
            $rootScope.$emit('notification', { type: 'success', message })
        },

        warning(message: string) {
            $rootScope.$emit('notification', { type: 'warning', message })
        },

        hide() {
            $rootScope.$emit('notification.hide')
        },

        /**
         * Create a notification for a promise;
         *
         * If called without any text arguments, it will only display
         * an error notification if the promise fails with some stringable error
         *
         * @param {Promise} promise
         * @param {string} successText
         * @param {string} errorText
         */
        forPromise(promise: ng.IPromise<unknown>, successText: string, errorText?: string) {
            promise
                .then(() => {
                    if (successText) {
                        Notification.success(successText)
                    }
                })
                .catch((promiseError) => {
                    const error = errorText || promiseError

                    if (error) {
                        Notification.error(error)
                    }
                })
        },
    }

    return Notification
}

export type NotificationServiceInstance = ReturnType<typeof NotificationFactory>
