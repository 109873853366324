import _ from 'lodash'

import { ASSIGNMENT_STATUSES, ASSIGNMENT_ISSUE_TYPES } from '../constants'

type Issue = {
    issue_type: string
    issue_status: string
}

interface ScopeBindings extends ng.IComponentController {
    issueLabel: string
    reported: string
    issue: Issue
}

const renderIssueTypeAndStatusComponent = {
    controller: renderIssueTypeAndStatusController,
    bindings: {
        reported: '<',
        issueLabel: '<',
        issue: '<',
    },
    template: `
        <p class="mb-1">{{$ctrl.issueLabel}}</p>
        <p ng-show="$ctrl.reported" class="text-danger">{{$ctrl.reported}}</p>
    `,
}

//////////////////////////////////////////////

/* @ngInject */ function renderIssueTypeAndStatusController(this: unknown, $scope: ng.IScope) {
    const $ctrl = this as ScopeBindings & { $onInit: typeof $onInit }

    $ctrl.$onInit = $onInit
    $scope.$watch('$ctrl.issue', () => {
        const { issue_status: status } = $ctrl.issue
        $ctrl.reported = status === ASSIGNMENT_STATUSES.REPORTED_TO_ADMIN_STATUS ? 'Reported' : ''
    })
    return $ctrl

    /////////////////////////////////////////////////////////////////

    function $onInit() {
        const { issue_type: type } = $ctrl.issue

        $ctrl.issueLabel = _.get(
            _.find(ASSIGNMENT_ISSUE_TYPES, { type }),
            'label',
            `Unknown issue type: ${type}`
        )
    }
}

export default renderIssueTypeAndStatusComponent
