import _ from 'lodash'

import { TaskServiceInstance } from './TaskService.factory'
import { DataEntryNetworkGuardInstance } from '../services/annotation/DataEntryNetworkGuard.factory'

import {
    GlobalShortcutsInstance,
    ShortcutDefinition,
} from '../global-shortcuts/GlobalShortcuts.factory'
import { trackButtonClick } from 'util/snowplow'

type Image = {
    url: string
    hint: string | null
    id: number
    name: string
    tagging_data: Record<string, any>[]
    questions: Question[]
    suggestedValuesForAnnotation: SuggestedValuesForAnnotation[]
}

export default /* @ngInject */ function AnnotateImageCtrl(
    this: unknown,
    task: ShapeTaggingData,
    assignmentID: string,
    $state: ng.ui.IStateService,
    $scope: ng.IScope,
    TaskService: TaskServiceInstance,
    DataEntryNetworkGuard: DataEntryNetworkGuardInstance,
    Notification: any,
    MapDialog: any,
    GlobalShortcuts: GlobalShortcutsInstance
) {
    interface AnnotateImageCtrlInstance {
        loading: boolean

        currentImage: Image
        totalImagesCount: number
        selectedShape: string
        currentIndex: number
        skipImage: boolean

        changeImageIndex: typeof changeImageIndex
        toggleSkip: typeof toggleSkip
        handleUpdate: typeof handleUpdate
        onSubmit: typeof onSubmit
    }

    const vm = this as AnnotateImageCtrlInstance

    vm.loading = false
    vm.selectedShape = 'rect'
    vm.totalImagesCount = (task && task.total_images) || 0

    vm.changeImageIndex = changeImageIndex
    vm.toggleSkip = toggleSkip
    vm.handleUpdate = DataEntryNetworkGuard.create(handleUpdate)
    vm.onSubmit = onSubmit

    setupShortcuts()
    setCurrentImage(task)

    ///////////////////////////////////////

    function setupShortcuts() {
        const shortcuts: ShortcutDefinition[] = [
            {
                description: 'Go To Next Image',
                keyCombo: 'right',
                shortcut: 'Right Arrow',
                callback: () => {
                    changeImageIndex(1)
                },
            },
            {
                description: 'Go To Previous Image',
                keyCombo: 'left',
                shortcut: 'Left Arrow',
                callback: () => {
                    changeImageIndex(-1)
                },
            },
            {
                description: 'Go To Undecided',
                keyCombo: 'u',
                shortcut: 'U',
                callback: () => {
                    fetchNextUndecided()
                },
            },
            {
                description: 'Toggle Skip Image',
                keyCombo: 's',
                shortcut: 'S',

                callback: () => {
                    toggleSkip()
                },
            },
            {
                description: 'Select Rectangle Tool',
                keyCombo: '1',
                shortcut: '1',
                callback: () => {
                    $scope.$applyAsync(() => {
                        vm.selectedShape = 'rect'
                    })
                },
            },
            {
                description: 'Select Polygon Tool',
                keyCombo: '2',
                shortcut: '2',

                callback: () => {
                    $scope.$applyAsync(() => {
                        vm.selectedShape = 'polygon'
                    })
                },
            },
        ]

        const unbind = GlobalShortcuts.bind({ title: 'Shape Image Tagging', shortcuts })
        $scope.$on('$destroy', unbind)
    }

    const submitConfig = MapDialog.confirm()
        .title('Submit task')
        .htmlContent(
            `
                <strong>Are you sure you want to proceed?</strong>
                <p>When you submit your changes will be stored and you won't be able to edit them anymore.</p>
            `
        )
        .ok('Yes')
        .cancel('No')

    function onSubmit() {
        trackButtonClick({
            label: 'Submit Task',
            componentName: 'Shape Image Tagging Task Submit',
        })
        MapDialog.show(submitConfig).then(() => {
            TaskService.submitTask(assignmentID).then(() => {
                $state.go('worker.list')
            })
        })
    }

    const debouncedGetImageData = _.debounce(fetchImage, 500, { trailing: true, leading: false })

    function changeImageIndex(offset: 1 | -1) {
        if (vm.loading) {
            return
        }
        const totalImages = vm.totalImagesCount
        const nextPage = vm.currentIndex + offset

        vm.currentIndex = nextPage === 0 ? totalImages : nextPage > totalImages ? 1 : nextPage

        debouncedGetImageData()
    }

    function fetchNextUndecided() {
        vm.loading = true

        TaskService.getNextUndecided<ShapeTaggingData>(assignmentID, vm.currentIndex)
            .then(
                (res) => {
                    if (res.status === 204) {
                        Notification.success('There are no more undecided images.')

                        return
                    }

                    setCurrentImage(res.data)
                },
                () =>
                    Notification.error(
                        'Image fetching faild. Try refreshing the page. If this error persist, please, contact an administrator.'
                    )
            )
            .finally(() => {
                vm.loading = false
            })
    }

    function setCurrentImage(data: ShapeTaggingData) {
        vm.currentImage = {
            id: data.image_id,
            hint: data.hint,
            url: data.url,
            tagging_data: data.tagging_data,
            suggestedValuesForAnnotation: data.suggestedValuesForAnnotation,
            questions: data.questions,
            name: data.image_name,
        }

        vm.skipImage = data.skip_tagging
        vm.currentIndex = data.index
    }

    function fetchImage() {
        vm.loading = true

        TaskService.getImage<ShapeTaggingData>(assignmentID, vm.currentIndex)
            .then(setCurrentImage, () =>
                Notification.error(
                    'Image fetching faild. Try refreshing the page. If this error persist, please, contact an administrator.'
                )
            )
            .finally(() => {
                vm.loading = false
            })
    }

    function toggleSkip() {
        TaskService.toggleSkip(assignmentID, vm.currentImage.id)
            .then(() => {
                vm.skipImage = !vm.skipImage
            })
            .catch(() => {
                Notification.error(
                    'Something went wrong. Try refreshing the page. If this error persist, please, contact an administrator.'
                )
            })
    }

    function handleUpdate(annotations: any[]) {
        return TaskService.updateShapeAnnotation(assignmentID, vm.currentImage.id, annotations)
    }
}

AnnotateImageCtrl.resolve = {
    assignmentID: /* @ngInject */ function ($stateParams: ng.ui.IStateParamsService) {
        return $stateParams.assignmentID
    },

    task: /* @ngInject */ function (
        TaskService: any,
        assignmentID: string
    ): Promise<ShapeTaggingData> {
        return TaskService.getTask(assignmentID)
    },
}
