import { timestampWithMilli } from 'services/utils'
import { VideoAPIInstance } from './VideoAPIBuilder.factory'

export default class StandardInput {
    private input: HTMLInputElement
    private mask = '__:__:__.___'
    private newTextStart = 0
    private videoApi: VideoAPIInstance
    private prevValue = ''

    constructor(input: HTMLInputElement, videoApi: VideoAPIInstance) {
        this.input = input
        this.videoApi = videoApi
        this.input.addEventListener('input', this.handleInput)
        this.input.addEventListener('keydown', this.handleKeyDown)
        this.input.addEventListener('keypress', this.handleKeyPress)
        this.input.addEventListener('blur', this.handleBlur)
    }

    updateValue(timestamp: number) {
        const standardFormatted = timestampWithMilli(timestamp)
        this.input.value = standardFormatted
        this.prevValue = standardFormatted
    }

    destroy() {
        this.input.removeEventListener('input', this.handleInput)
        this.input.removeEventListener('keydown', this.handleKeyDown)
        this.input.removeEventListener('keypress', this.handleKeyPress)
        this.input.removeEventListener('blur', this.handleBlur)
    }

    private handleInput = (e: Event) => {
        const target = e.target as HTMLInputElement
        const value = target.value
        const selectionEnd = target.selectionEnd as number
        let beforeInsertion = value.substring(0, Math.min(this.newTextStart, selectionEnd))
        let insertion = cleanInput(
            value.substring(Math.min(this.newTextStart, selectionEnd), selectionEnd),
            false
        )
        let afterInsertion = value.substring(selectionEnd)
        let selectionPos = beforeInsertion.length + insertion.length
        // The check below can be omitted since we have only a Standard mask
        if (!isEmpty(this.mask)) {
            beforeInsertion = maskText(
                cleanInput(beforeInsertion, true),
                0,
                beforeInsertion.length,
                this.mask
            )
            selectionPos =
                beforeInsertion.length +
                maskText(insertion, beforeInsertion.length, 0, this.mask).length
            const isertionLen = Math.max(
                0,
                this.mask.length - beforeInsertion.length - afterInsertion.length
            )
            insertion = maskText(insertion, beforeInsertion.length, isertionLen, this.mask)
            const afterInsertionLen = Math.max(
                0,
                this.mask.length - beforeInsertion.length - insertion.length
            )
            afterInsertion = value.substring(value.length - afterInsertionLen)
            afterInsertion = maskText(
                cleanInput(afterInsertion, true),
                this.mask.length - afterInsertion.length,
                afterInsertion.length,
                this.mask
            )
        }
        target.value = beforeInsertion + insertion + afterInsertion
        target.selectionStart = selectionPos
        target.selectionEnd = selectionPos
    }

    private handleKeyDown = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement
        this.newTextStart = target.selectionStart as number
    }

    private handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            ;(e.target as HTMLInputElement).blur()
        }
    }

    private handleBlur = () => {
        if (isInvalid(this.input.value)) {
            this.input.value = this.prevValue
            return
        }

        const [hh, mm, ss] = this.input.value.split(':')
        const hours = parseInt(hh)
        const minutes = parseInt(mm)
        const seconds = parseFloat(ss)
        const totalSeconds = hours * 60 * 60 + minutes * 60 + seconds
        this.videoApi.seek(totalSeconds)
    }
}

const maskText = (str: string, start: number, end: number, mask: string | null) => {
    if (mask === null) {
        return str
    }
    let res = ''
    const maskSubstr = mask.substring(start)
    for (let i = 0; i < maskSubstr.length && !isBlank(str); i++) {
        const currentChar = maskSubstr.substring(i, i + 1)
        if (currentChar === '_') {
            res += str.substring(0, 1)
            str = str.substring(1)
        } else {
            res += currentChar
        }
    }
    if (end > 0 && res.length < end) {
        res += maskSubstr.substring(res.length, end)
    }
    return res
}

const isBlank = (str: any) => {
    return isEmpty(str) || str.matches('\\s+')
}

const isInvalid = (str: any) => {
    return isEmpty(str) || str.includes('_')
}

const isEmpty = (str: any) => {
    return str === null || str === ''
}

const cleanInput = (str: string, underscoreAllowed: boolean) => {
    let res = ''
    const validChars = underscoreAllowed ? new RegExp('^[0-9_]$') : new RegExp('^[0-9]$')
    for (let i = 0; i < str.length; i++) {
        const currentChar = str.substring(i, i + 1)
        if (validChars.test(currentChar)) {
            res += currentChar
        }
    }
    return res
}
