import _ from 'lodash'

/**
 * Directive for visualizing task answers.
 * Initially developed to unify QA and Worker answers
 *
 * @param {Array(Question)} questions Array of question definitions
 * @param {Annotation} annotation Assignable annotation
 */
export default function recordedAnswersDirective() {
    const directive = {
        restrict: 'E',
        scope: {
            questions: '<',
            annotation: '<',
        },
        templateUrl: 'js/directives/recorded-answers/recordedAnswers.tpl.html',
        link: recordedAnswersLinkFn,
    }

    return directive

    ////////////////////////////

    function recordedAnswersLinkFn(scope) {
        scope.$getAnswerForQuestion = $getAnswerForQuestion
        scope.$getLabelForAnswer = $getLabelForAnswer
    }

    function $getAnswerForQuestion(question, answers) {
        return _.find(answers, { id: question && question.uri })
    }

    function $getLabelForAnswer(question, answerValue) {
        const value = answerValue && answerValue.value
        const answerLabel = answerValue && answerValue.label

        const questionLabel = _.get(_.find(question.values, { value }), 'label', false)

        // return either the question label, or the label saved in the answer value
        return questionLabel || answerLabel
    }
}
