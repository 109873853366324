import _ from 'lodash'

const tablePaginationComponent = {
    controller: tablePaginationCtrl,
    bindings: {
        limit: '=',
        pagination: '=',
    },
    template: `
        <ul ng-show="$ctrl.pagination.needsToPaginate" class="pagination pagination-sm d-flex justify-content-center mb-0">
            <li class="page-item"
                ng-show="$ctrl.pagination.rangePagesIsPartial"
                ng-click="$ctrl.setCurrentPage(1)"
                ng-class="{ 'disabled': $ctrl.pagination.currentPage === 1 }"
            >
                <a class="page-link" href">&laquo;&laquo;</a>
            </li>

            <li class="page-item"
                ng-click="$ctrl.offsetCurrentPage(-1)"
                ng-class="{ 'disabled': $ctrl.pagination.currentPage === 1 }"
            >
                <a class="page-link" href">&laquo;</a>
            </li>

            <li class="page-item"
                ng-click="$ctrl.setCurrentPage(page)"
                ng-repeat="page in $ctrl.pagination.rangePages"
                ng-class="{ 'active': $ctrl.pagination.currentPage === page }"
            >
                <a class="page-link" href" ng-bind="::page"></a>
            </li>

            <li class="page-item"
                ng-click="$ctrl.offsetCurrentPage(+1)"
                ng-class="{ 'disabled': $ctrl.pagination.currentPage === $ctrl.pagination.numPages }"
            >
                <a class="page-link" href">&raquo;</a>
            </li>

            <li class="page-item"
                ng-show="$ctrl.pagination.rangePagesIsPartial"
                ng-click="$ctrl.setCurrentPage($ctrl.pagination.numPages)"
                ng-class="{ 'disabled': $ctrl.pagination.currentPage === $ctrl.pagination.numPages }"
            >
                <a class="page-link" href">&raquo;&raquo;</a>
            </li>
        </ul>
    `,
}

export default tablePaginationComponent

/* @ngInject */
function tablePaginationCtrl($scope, $attrs) {
    const $ctrl = this

    const PAGINATION_VISIBLE_PAGES_LIMIT = 11

    $ctrl.$onInit = $onInit

    $ctrl.offsetCurrentPage = offsetCurrentPage
    $ctrl.setCurrentPage = setCurrentPage

    return $ctrl

    ///////////////////////////////////

    function $onInit() {
        // setup watcher on parent for items
        const unwatchItems = $scope.$parent.$watchCollection($attrs.items, generatePagination)
        $scope.$on('$destroy', () => unwatchItems())

        // set a default limit
        $ctrl.limit = $ctrl.limit || '25'
        // trigger generate pagination on limit change
        $scope.$watch('$ctrl.limit', generatePagination)

        $scope.$watchGroup(
            ['$ctrl.pagination.currentPage', '$ctrl.pagination.needsToPaginate', '$ctrl.limit'],
            () => {
                if ($ctrl.pagination.needsToPaginate) {
                    $ctrl.pagination.offset =
                        ($ctrl.pagination.currentPage - 1) * $ctrl.pagination.limit

                    $ctrl.pagination.rangePagesIsPartial =
                        $ctrl.pagination.numPages > PAGINATION_VISIBLE_PAGES_LIMIT
                    if ($ctrl.pagination.currentPage > PAGINATION_VISIBLE_PAGES_LIMIT / 2) {
                        const halfLimit = Math.floor(PAGINATION_VISIBLE_PAGES_LIMIT / 2)
                        const lastVisible = Math.min(
                            $ctrl.pagination.currentPage + halfLimit,
                            $ctrl.pagination.numPages
                        )
                        const firstVisible = lastVisible - PAGINATION_VISIBLE_PAGES_LIMIT
                        $ctrl.pagination.rangePages = _.range(firstVisible + 1, lastVisible + 1)
                    } else {
                        $ctrl.pagination.rangePages = _.range(
                            1,
                            Math.min(
                                PAGINATION_VISIBLE_PAGES_LIMIT + 1,
                                $ctrl.pagination.numPages + 1
                            )
                        )
                    }
                } else {
                    $ctrl.pagination.offset = 0
                }
            }
        )
    }

    function generatePagination(items) {
        // make pagination an object if not set
        $ctrl.pagination = $ctrl.pagination || {}

        // Ugly optimization hack.
        // If the function param is not an array then we were called from the `$ctrl.limit` watcher.
        // In that case we must manually get the items from the parent scope. But, if we did get
        // an array, then we can scip doing a costly scope evaluation that was already performed.
        if (!_.isArray(items)) {
            items = $scope.$parent.$eval($attrs.items)
        }

        if (!_.isArrayLike(items)) {
            return generateNoPagination()
        }

        // convert into integer
        const limit = $ctrl.limit | 0
        const length = items.length
        const needsToPaginate = limit > 0 && items.length > limit
        if (!needsToPaginate) {
            return generateNoPagination()
        }

        const numPages = Math.ceil(length / limit)
        // curren page is 1 indexed
        const currentPage =
            ($ctrl.pagination.currentPage && Math.min($ctrl.pagination.currentPage, numPages)) || 1
        $ctrl.pagination = {
            ...$ctrl.pagination,
            needsToPaginate,
            limit,
            length,
            numPages,
            currentPage,
        }

        /////////////////////////////////////////////////////////////////////

        function generateNoPagination() {
            $ctrl.pagination = {
                ...$ctrl.pagination,
                needsToPaginate: false,
                limit: undefined,
                offset: 0,
            }
        }
    }

    function offsetCurrentPage(offset) {
        if ($ctrl.pagination.needsToPaginate) {
            $ctrl.pagination.currentPage = _.clamp(
                $ctrl.pagination.currentPage + offset,
                1,
                $ctrl.pagination.numPages
            )
        }
    }

    function setCurrentPage(page) {
        if ($ctrl.pagination.needsToPaginate) {
            $ctrl.pagination.currentPage = _.clamp(page, 1, $ctrl.pagination.numPages)
        }
    }
}
