import _ from 'lodash'
import Popper from 'popper.js'

/**
 * @ngdoc directive
 * @name tooltipTableDetails
 * @module map3.core
 * @restrict A
 *
 * @description
 * Show a tooltip on hover
 *
 * @param {object} task The task to display in the template
 */

const tooltipTemplate = `<div class="card">
    <div class="card-header">
        <span ng-bind="::task.job_name"></span>
        <strong class="ml-3 mr-2">Task Available:</strong>
        <tz-date date="::task.creation_date"></tz-date>
        <strong class="ml-3 mr-2">Task Type:</strong>
        <span ng-bind="::task.task_type"></span>
    </div>
    <div class="card-body">
        <table class="table mb-0">
            <thead>
                <tr>
                    <th>Role</th>
                    <th>Username</th>
                    <th>Pickup</th>
                    <th>Submit</th>
                    <th>Aging (hrs)</th>
                    <th>Duration (hrs)</th>
                </tr>
            </thead>
            <tbody>
                <tr ng-repeat="worker in task.workers_info">
                    <td>{{ ::worker.role}}</td>
                    <td>{{ ::worker.username }}</td>
                    <td>
                        <tz-date date="::worker.pickup"></tz-date>
                    </td>
                    <td>
                        <tz-date date="::worker.submit"></tz-date>
                    </td>
                    <td>
                        {{ ::worker.aging }}
                    </td>
                    <td>
                        {{ ::worker.duration }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>`

export default /* @ngInject */ function tooltipTableDetailsDirective($compile) {
    let directive = {
        restrict: 'A',
        scope: {
            task: '<',
        },
        link: tooltipTableDetailsLinkFn,
    }

    return directive

    //////////////////////////////////////////////

    function tooltipTableDetailsLinkFn(scope, element) {
        // do not show tooltip table if there are no workers attached to the task
        if (_.isEmpty(scope.task.workers_info)) {
            return
        }

        $compile(tooltipTemplate)(scope, function (compiledHtml) {
            scope.$applyAsync(() => {
                activate(compiledHtml)
            })
        })

        ///////////////////////////////////////

        function activate(compiledHtml) {
            let popperInstance
            let popperContent

            element.on('mouseenter', function () {
                popperContent = document.createElement('div')

                $(popperContent)
                    .addClass('popper')
                    .html(compiledHtml)
                    .append('<div class="popper__arrow"/>')
                    .appendTo('body')

                popperInstance = new Popper(element[0], popperContent, {
                    placement: 'top',
                    modifiers: {
                        preventOverflow: {
                            boundariesElement: 'viewport',
                            priority: ['top', 'left', 'right'],
                        },
                        arrow: {
                            element: '.popper__arrow',
                        },
                    },
                    removeOnDestroy: true,
                })
            })

            element.on('mouseleave', function () {
                if (popperInstance) {
                    popperInstance.destroy()
                }
                if (popperContent) {
                    $(popperContent).remove()
                }
            })
        }
    }
}
