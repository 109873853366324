export default /* @ngInject */ function fsmStickyHeaderDirective() {
    var directive = {
        restrict: 'EA',
        replace: false,
        scope: {
            scrollBody: '@',
            scrollStop: '<?',
            scrollableContainer: '@',
            contentOffset: '=',
            fsmZIndex: '=',
        },
        link: fsmStickyHeaderFn,
    }

    return directive

    ////////////////////
    function fsmStickyHeaderFn(scope, element, attrs, control) {
        var content
        var header = $(element)
        var clonedHeader = null
        var scrollableContainer = $(scope.scrollableContainer)
        var contentOffset = scope.contentOffset || 0

        if (scrollableContainer.length === 0) {
            scrollableContainer = $(window)
        }

        if (element.is('thead')) {
            content = element.closest('table')
            init()
        } else {
            content = $(scope.scrollBody)
            init()
        }

        function setColumnHeaderSizes() {
            if (clonedHeader.is('tr') || clonedHeader.is('thead')) {
                var clonedColumns = clonedHeader.find('th')
                header.find('th').each(function (index, column) {
                    var clonedColumn = $(clonedColumns[index])
                    clonedColumn.css('width', column.offsetWidth + 'px') //fixed thead width
                    // fluid thead / table
                    // var finalWidthSet = column.offsetWidth / ($(window).innerWidth()-20)*100; // $(window) can be replace with a custom wrapper / container
                    // clonedColumn.css('width',finalWidthSet + '%');
                })
            }
        }

        function determineVisibility() {
            var scrollStop = scope.scrollStop || 0
            var scrollTop = scrollableContainer.scrollTop() + scrollStop
            var contentTop = content.offset().top + contentOffset
            var contentBottom = contentTop + content.outerHeight(false)

            if (scrollTop > contentTop && scrollTop < contentBottom) {
                if (!clonedHeader) {
                    createClone()
                    clonedHeader.css({ visibility: 'visible' })
                }

                if (
                    scrollTop < contentBottom &&
                    scrollTop > contentBottom - clonedHeader.outerHeight(false)
                ) {
                    var top =
                        contentBottom - scrollTop + scrollStop - clonedHeader.outerHeight(false)
                    clonedHeader.css('top', top + 'px')
                } else {
                    calculateSize()
                }
            } else {
                if (clonedHeader) {
                    /*
                     * remove cloned element (switched places with original on creation)
                     */
                    header.remove()
                    header = clonedHeader
                    clonedHeader = null

                    header.removeClass('fsm-sticky-header')
                    header.css({
                        position: 'relative',
                        left: 0,
                        top: 0,
                        width: 'auto',
                        'z-index': 0,
                        visibility: 'visible',
                    })
                }
            }
        }

        function calculateSize() {
            clonedHeader.css({
                top: scope.scrollStop || 0,
                width: header.outerWidth(),
                left: header.offset().left,
            })

            setColumnHeaderSizes()
        }

        function createClone() {
            /*
             * switch place with cloned element, to keep binding intact
             */
            clonedHeader = header
            header = clonedHeader.clone()
            clonedHeader.after(header)
            clonedHeader.addClass('fsm-sticky-header')
            clonedHeader.css({
                position: 'fixed',
                'z-index': scope.fsmZIndex || 750,
                visibility: 'hidden',
            })
            calculateSize()
        }

        function init() {
            scrollableContainer.on('scroll.fsmStickyHeader', determineVisibility).trigger('scroll')
            scrollableContainer.on('resize.fsmStickyHeader', determineVisibility)

            scope.$on('$destroy', function () {
                scrollableContainer.off('.fsmStickyHeader')
            })
        }
    }
}
