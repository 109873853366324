interface ILandingCtrlInstance {
    openModal: () => void
}

export default /* @ngInject */ function LandingCtrl(
    this: unknown,
    $uibModal: ng.ui.bootstrap.IModalService
) {
    const landingVm = this as ILandingCtrlInstance
    landingVm.openModal = openModal

    function openModal() {
        $uibModal.open({
            templateUrl: 'landing.videoPlayModal.tpl.html',
            windowTemplateUrl: 'landingVideoWindow.tpl.html',
            backdropClass: 'video-backdrop',
        })
    }
}
