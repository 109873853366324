import _ from 'lodash'

export default /* @ngInject */ function RootScopeExtensionsConfig($provide) {
    /**
     * @ngdoc object
     * @name $rootScope.Scope
     * @module map3.core
     * @description
     * map3 extensions for [$rootScope.Scope](https://docs.angularjs.org/api/ng/type/$rootScope.Scope)
     */
    $provide.decorator(
        '$rootScope',
        /* @ngInject */ function ($delegate, $injector) {
            /**
             * @ngdoc method
             * @name $rootScope.Scope#$onRootScope
             * @module map3.core
             *
             * @description
             * A convenience method to bind an event listener directly to $rootScope from any $scope object.
             * Used in conjunction with [$rootScope.Scope#$emit](https://docs.angularjs.org/api/ng/type/$rootScope.Scope#$emit)
             * to get fast events that don't traverse the scope tree
             *
             * @param {string} name Event name to listen on.
             * @param {function(event, ...args)} listener Function to call when the event is emitted.
             * @return {function} Returns a deregistration function for this listener.
             */
            $delegate.constructor.prototype.$onRootScope = function (name, listener) {
                var scope = this
                var unsubscribeOnRootScope = $delegate.$on(name, listener)
                scope.$on('$destroy', unsubscribeOnRootScope)

                return unsubscribeOnRootScope
            }

            /**
             * @ngdoc method
             * @name $rootScope.Scope#$watchOnce
             * @module map3.core
             *
             * @description
             * Limits the creation of watchers to only one watcher per expression.
             * Additional calls to {@link $rootScope.Scope#$watchOnce $watchOnce()} will not
             * attach further listeners
             *
             * See [$rootScope.Scope#$watch()](https://docs.angularjs.org/api/ng/type/$rootScope.Scope#$watch)
             *
             * @param {function|expression} watchExpression
             * @param {function(newVal, oldVal, scope)} listener
             * @param {boolean=} objectEquality
             * @return {function} Deregistration function.
             */
            $delegate.constructor.prototype.$watchOnce = function (
                watchExp,
                listener,
                objectEquality,
                prettyPrintExpression
            ) {
                var scope = this
                var expression = prettyPrintExpression || watchExp
                var array = scope.$$watchOnceExpressions
                if (!array) {
                    array = scope.$$watchOnceExpressions = []
                }

                if (!scope.$$hasRemoveWatcOnceExpressionsCb) {
                    scope.$$hasRemoveWatcOnceExpressionsCb = true
                    scope.$on('$destroy', function () {
                        scope.$$watchOnceExpressions = null
                        scope.$$watchCollectionOnceExpressions = null
                    })
                }

                if (array.indexOf(expression) < 0) {
                    array.push(expression)
                    var deregFn = scope.$watch(
                        watchExp,
                        listener,
                        objectEquality,
                        prettyPrintExpression
                    )

                    return function () {
                        _.remove(array, expression)
                        return deregFn()
                    }
                } else {
                    return angular.noop
                }
            }

            /**
             * @ngdoc method
             * @name $rootScope.Scope#$watchCollectionOnce
             * @module map3.core
             *
             * @description
             * Limits the creation of watchers to only one watcher per expression.
             * Additional calls to {@link $rootScope.Scope#$watchCollectionOnce $watchCollectionOnce()}
             * will not attach further listeners
             *
             * See [$rootScope.Scope#$watchCollection()](https://docs.angularjs.org/api/ng/type/$rootScope.Scope#$watchCollection)
             *
             * @param {string|function(scope)} obj Evaulated as {@link expression}
             * @param {function(newCollection, oldCollection, scope)} listener
             * @return {function()} Deregistration function
             */
            $delegate.constructor.prototype.$watchCollectionOnce = function (obj, listener) {
                var scope = this
                var expression = obj
                var array = scope.$$watchCollectionOnceExpressions
                if (!array) {
                    array = scope.$$watchCollectionOnceExpressions = []
                }

                if (!scope.$$hasRemoveWatcOnceExpressionsCb) {
                    scope.$$hasRemoveWatcOnceExpressionsCb = true
                    scope.$on('$destroy', function () {
                        scope.$$watchOnceExpressions = null
                        scope.$$watchCollectionOnceExpressions = null
                    })
                }

                if (array.indexOf(expression) < 0) {
                    array.push(expression)
                    let deregFn = scope.$watchCollection(obj, listener)
                    return function () {
                        _.remove(array, expression)
                        return deregFn()
                    }
                } else {
                    return angular.noop
                }
            }

            /**
             * @ngdoc method
             * @name $rootScope.Scope#$ignorePUR
             * @module map3.core
             *
             * @description
             * Globally disable reporting of "Possibly Unhandled Rejection" exceptions,
             * execute the callback, and then restore reporting.
             *
             * Useful for dealing with third-party code that is not written with Angular 1.6+ in mind
             *
             * @param {function=} callback
             * @param {Object=} thisArg
             */
            $delegate.constructor.prototype.$ignorePUR = function (callback, thisArg) {
                $delegate.$$IGNORE_PUR = true

                if (callback) {
                    callback.apply(thisArg)
                }

                // we need to restore the setting one browser tick later, because the check for unhandled
                // rejections will be performed at the end of the current browser tick
                $injector.get('$timeout')(
                    () => {
                        $delegate.$$IGNORE_PUR = false
                    },
                    0,
                    /* digest */ false
                )
            }

            return $delegate
        }
    )
}
