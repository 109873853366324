import _ from 'lodash'
import { GroupedSegment } from 'services/scenes/SegmentGrouper'

export const filterAnnotations = (annotation: BaseAnnotation, searchString: string) => {
    return _.some(annotation.answers, (answer) => {
        return hasSearchMatch(answer, searchString)
    })
}

export const filterSegments = (segment: GroupedSegment, searchString: string) => {
    // This only search for annotation answer match by search string
    // If there are no annotations in segment we don't filter it out
    if (!segment.annotations.length) return true

    return segment.annotations.some((annotation) => {
        return filterAnnotations(annotation, searchString)
    })
}

export const filterMarkers = (markers: any[], searchString: string) => {
    return markers.filter((m) => {
        if (!m.tag) {
            return true
        }
        return m.tag.answers.some((answer: AnnotationAnswer) => {
            return hasSearchMatch(answer, searchString)
        })
    })
}

export const hasSearchMatch = (answer: AnnotationAnswer, searchString: string) => {
    const search = searchString.toLowerCase()
    if (search === '') {
        return true
    }

    return _.isArray(answer.value)
        ? _.some(answer.value, (value) => _.includes(value?.label.toLowerCase(), search))
        : _.includes(answer.value?.label.toLowerCase(), search)
}
