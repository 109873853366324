import _ from 'lodash'
import { QARouterInstance } from './routes'
import { IQAAssignment, IQAAssigmentForReview } from './types'

export default /* @ngInject */ function QAAssignmentServiceFactory(
    $http: ng.IHttpService,
    QARouter: QARouterInstance,
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY
) {
    const QA_ASSIGNMENTS_URL = '/api/qa/assignments'

    const QAAssignmentService = {
        getAssignments() {
            return $http.get(QA_ASSIGNMENTS_URL).then((res) => res.data)
        },

        addForReview(assignments: IQAAssignment[]) {
            const ids = _.map(assignments, 'hit_id').filter((hitId) => !!hitId)

            return $http
                .post<IQAAssigmentForReview>(`${QA_ASSIGNMENTS_URL}/accept/`, {
                    hitOrAssignmentIds: ids,
                })
                .then((res) => res.data)
        },

        addToInProgress(assignment: IQAAssignment) {
            const id = assignment.hit_id

            return $http
                .post(`${QA_ASSIGNMENTS_URL}/return-in-progress/${id}`, {})
                .then((res) => res.data)
        },

        returnFromReview(assignment: IQAAssignment) {
            return $http.post(`${QA_ASSIGNMENTS_URL}/return/${QARouter.getId(assignment)}`, {})
        },

        /**
         * Return to workers for a second attempt
         *
         * @param {string} hitID Task ID
         * @param {Object} payload `{worker_ids: array, message: string}
         * @return {Promise}
         */
        returnToWorkers(hitID: string, data: any) {
            const URL = `/api/hit/qa/${hitID}/return`

            return $http.post(URL, data)
        },

        setBookmarks(assignmentID: string, markersArray: any[]) {
            return $http.post(
                `/api/hit/qa/${assignmentID}/bookmarks`,
                { bookmarks: markersArray },
                NO_LOAD_OVERLAY
            )
        },
    }

    return QAAssignmentService
}

export type QAAssignmentServiceInstance = ReturnType<typeof QAAssignmentServiceFactory>
