import { $modulo60, getTimeFractionsByTimeInSeconds, timestampWithMilli } from './utils'

export class TimestampTransform {
    /**
     * @param {number} timeInSeconds
     * @return string like '01:23:45'
     */
    timestamp(timeInSeconds: number): string {
        return getTimeFractionsByTimeInSeconds(timeInSeconds)
    }

    /**
     * @param {number} timeInSeconds
     * @return string like '01:23:45:678'
     */
    timestampWithMilli(timeInSeconds: number): string {
        return timestampWithMilli(timeInSeconds)
    }

    /**
     * Convert seconds into a string representation + number of seconds left after modulo 60
     */
    $modulo60(seconds: number): [string, number] {
        return $modulo60(seconds)
    }
}

export default /* @ngInject */ function TimestampTransformFactory() {
    return new TimestampTransform()
}

export type TimestampTransformInstance = ReturnType<typeof TimestampTransformFactory>
