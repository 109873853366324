import ngRedux from 'ng-redux'
import constants from '../constants'

import {
    ContentMarkersActionCreatorsFactory,
    ContentMarkersActionsFactory,
    ContentMarkersStateActionCreatorsFactory,
    ContentMarkersReducerFactory,
    ContentMarkersStateReducerFactory,
    ContentMarkersStateActionsFactory,
    CombinedContentMarkersReducerFactory,
} from './ContentMarkersReducer'
import ContentMarkersBackendInterfaceServiceFactory from './ContentMarkersBackendInterfaceService.factory'
import contentMarkersTimelineComponent from './contentMarkersTimeline.component'

const map3ContentMarkers = angular
    .module('map3.contentMarkers', [constants, ngRedux])
    .factory('ContentMarkersActionCreators', ContentMarkersActionCreatorsFactory)
    .factory('ContentMarkersActions', ContentMarkersActionsFactory)
    .factory('ContentMarkersStateActionCreators', ContentMarkersStateActionCreatorsFactory)
    .factory('ContentMarkersReducer', ContentMarkersReducerFactory)
    .factory('ContentMarkersStateReducer', ContentMarkersStateReducerFactory)
    .factory('ContentMarkerStateActions', ContentMarkersStateActionsFactory)
    .factory('CombinedContentMarkersReducer', CombinedContentMarkersReducerFactory)
    .factory('ContentMarkersBackendInterfaceService', ContentMarkersBackendInterfaceServiceFactory)
    .component('contentMarkersTimeline', contentMarkersTimelineComponent)

export default map3ContentMarkers.name
