export default /* @ngInject */ function asyncGuardDirective() {
    const directive = {
        restrict: 'AE',
        transclude: true,
        template: `
            <div class="loading-container-min">
                <div class="loading-spinner">
                    <div class="spinner-icon"></div>
                </div>
            </div>
        `,
        controller: asyncGuardCtrl,
        controllerAs: '$ctrl',
    }

    return directive
}

/* @ngInject */
function asyncGuardCtrl($scope, $element, $attrs, $transclude, $q) {
    const $ctrl = this

    $ctrl.$onInit = $onInit

    return $ctrl

    //////////////////////

    function $onInit() {
        const noSpinner = angular.isDefined($attrs.noSpinner)
        if (noSpinner) {
            $element.find('.loading-container-min').addClass('d-none')
        }

        let deregWatch = $scope.$watch($attrs.guard || $attrs.asyncGuard, (guard) => {
            if (angular.isDefined(guard)) {
                $q.resolve(guard).then(removeLoadingSpinner).catch(angular.noop)
            }
        })

        function removeLoadingSpinner() {
            // already removed
            if (!deregWatch) {
                return
            }

            deregWatch()
            deregWatch = null

            $transclude(function (clone) {
                $element.empty()
                $element.append(clone)
            })
        }
    }
}
