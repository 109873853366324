import constants from '../../../constants'

import queryBuilderDirective from './queryBuilder.directive'
import QueryBuilderDataHandlerFactory from './QueryBuilderDataHandler.factory'

/**
 * @ngdoc module
 * @name map3.queryBuilderModule
 * @module map3.queryBuilderModule
 *
 * @description
 *
 * QA module
 */
const queryBuilderModule = angular
    .module('queryBuilderModule', [constants])
    .directive('queryBuilder', queryBuilderDirective as ng.IDirectiveFactory)
    .factory('QueryBuilderDataHandler', QueryBuilderDataHandlerFactory)

export default queryBuilderModule.name
