import _ from 'lodash'

/**
 * @ngdoc service
 * @name UserPreferences
 * @module map3.core
 *
 * @description
 * UserSetting Service
 *
 * A service for storing user setting in backend
 *
 */
export default /* @ngInject */ function UserPreferencesFactory(
    NO_LOAD_OVERLAY,
    $http,
    $q,
    $rootScope,
    User
) {
    let $activeBackendRequestPromise
    let $waitingBackendPreferences

    let UserPreferences = {
        /**
         * @ngdoc method
         * @name UserPreferences#set
         * @module map3.core
         *
         * @description
         * Set the new value to given predicate filed
         * (see {@link tableOrder})
         *
         * @param {string} namespace the namespa
         * @param {string} predicateFiled predicate filed
         * @param {*} value new value
         *
         */
        set: function (namespace, setting, value) {
            let cached = UserPreferences.get(namespace, setting)

            if (!_.isEqual(cached, value)) {
                _.set(User.cached().preferences, [namespace, setting], angular.copy(value))

                UserPreferences.$setInBackend(namespace, setting, value)
            }
        },

        /**
         * @ngdoc method
         * @name UserPreferences#get
         * @module map3.core
         *
         * @description
         * Get the value from given predicate filed in given namespace
         *
         * @param {string} namespace
         * @param {string} predicateField
         * @param {*} defaultVal
         *
         * @return {*}
         */
        get: function (namespace, setting, defaultVal) {
            return angular.copy(
                _.get(User.cached(), ['preferences', namespace, setting], defaultVal)
            )
        },

        /**
         * @ngdoc method
         * @name UserPreferences#getNamespace
         * @module map3.core
         *
         * @param {string} namespace
         * @return {object} All settings in the given namespace
         */
        getNamespace: function (namespace) {
            return angular.copy(_.get(User.cached(), ['preferences', namespace], {}))
        },

        /**
         * @ngdoc method
         * @name UserPreferences#liveCollection
         * @module map3.core
         *
         * @description
         * Create a live settings binding; Any change to `variableName` on the scope will be preserved
         *
         * @param {$rootScope.Scope} scope
         * @param {expression} expression
         * @param {string} namespace
         * @param {string} predicateField
         * @param {*=} default
         */
        liveCollection: function (scope, expression, namespace, setting, defaultVal) {
            let value = UserPreferences.get(namespace, setting, defaultVal)

            scope.$watchCollectionOnce(expression, function (newValue) {
                UserPreferences.set(namespace, setting, newValue)
            })

            return value
        },

        /**
         * @ngdoc method
         * @name UserPreferences#live
         * @module map3.core
         *
         * @description
         * Create a live settings binding; Any change to `variableName` on the scope will be preserved
         *
         * @param {$rootScope.Scope} scope
         * @param {expression} expression
         * @param {string} namespace
         * @param {string} predicateField
         * @param {*=} default
         */
        live: function (scope, expression, namespace, setting, defaultVal) {
            let value = UserPreferences.get(namespace, setting, defaultVal)

            scope.$watchOnce(
                expression,
                function (newValue) {
                    UserPreferences.set(namespace, setting, newValue)
                },
                true
            )

            return value
        },

        /**
         * @ngdoc method
         * @name UserPreferences#resetPreferences
         * @module map3.core
         *
         * @description
         * Reset user preferences to default settings
         *
         * @return {Promise} Promise that fullfils when the request has been completed
         */
        resetPreferences: function () {
            return $http.post('/api/profile/reset-preferences').then((res) => {
                User.cached().preferences = res.data.preferences
            })
        },

        /**
         * @private
         * @description
         * Set a setting in the backend.
         * Will queue a new backend request
         *
         * @param {string} namespace
         * @param {string} setting
         * @param {*} value
         */
        $setInBackend: function (namespace, setting, value) {
            let currentNamespaceData = _.pick(_.get(User.cached(), 'preferences', {}), namespace)
            let newNamespaceData = { [namespace]: { [setting]: value } }

            UserPreferences.$queueBackendRequest(
                UserPreferences.$mergeNamespacedPreferences(
                    {},
                    currentNamespaceData,
                    newNamespaceData
                )
            )
        },

        /**
         * @private
         * @description
         * Queue a preferences update request.
         * If no request is currently queued, one will be issued immedaitely.
         * If a request is currently processed, the preferences are queued for after
         * the current request.
         * If there are already queued preferences, we merge them with the new ones.
         *
         * @param {object} preferences
         */
        $queueBackendRequest: function (preferences) {
            if (!$activeBackendRequestPromise) {
                $activeBackendRequestPromise = makeBackendPromise(preferences)
            } else {
                $waitingBackendPreferences = UserPreferences.$mergeNamespacedPreferences(
                    $waitingBackendPreferences,
                    preferences
                )
            }

            ///////////////////////////////////////////////////////

            function makeBackendPromise(preferences) {
                return $http
                    .post('/api/profile/update', { preferences }, NO_LOAD_OVERLAY)
                    .then((res) => res.data)
                    .catch(function (e) {
                        $waitingBackendPreferences = null
                        $activeBackendRequestPromise = null

                        throw e
                    })
                    .then(handleBackendRequestCompletion)
            }

            function handleBackendRequestCompletion(data) {
                if ($waitingBackendPreferences) {
                    $activeBackendRequestPromise = makeBackendPromise($waitingBackendPreferences)
                } else {
                    $activeBackendRequestPromise = null
                    User.cached().preferences = data.user.preferences
                }

                $waitingBackendPreferences = null
            }
        },

        /**
         * @private
         * @description
         * Merge namespaced preferences in a way that can merge several settings in a namespace,
         * but will overwrite the setting value completely if a new one is given. It will also
         * remove settings that should not be persisted
         *
         * @param {Object}
         * @param {...Object} preferenceSources one or more sources to merge into target
         * @return updated `target`
         */
        $mergeNamespacedPreferences: function (target, ...preferenceSources) {
            // make sure target is always an object
            target = Object(target)

            _.forEach(preferenceSources, (preferences) => {
                _.forEach(_.keys(preferences), (namespace) => {
                    if (_.isObject(target[namespace]) || _.isObject(preferences[namespace])) {
                        target[namespace] = _.assign({}, target[namespace], preferences[namespace])
                    } else {
                        target[namespace] = preferences[namespace]
                    }

                    target[namespace] = UserPreferences.$prunePreferences(target[namespace])
                })
            })

            return target
        },
        $prunePreferences: function (preferences) {
            if (!_.isPlainObject(preferences)) return preferences

            const prunedPreferences = {}
            for (const [key, value] of Object.entries(preferences)) {
                if (_.isPlainObject(value)) {
                    const prunedObject = {}

                    for (const [key, value] of Object.entries(value)) {
                        if (!key.startsWith('__REMOVE_FROM_PREFERENCES')) {
                            prunedObject[key] = value
                        }
                    }

                    prunedPreferences[key] = prunedObject
                } else {
                    prunedPreferences[key] = value
                }
            }

            return prunedPreferences
        },
    }

    $rootScope.UserPreferences = UserPreferences

    return UserPreferences
}
