export default function compareToDirective() {
    const directive = {
        restrict: 'A',
        require: 'ngModel',
        link: compareToLinkFn,
    }

    return directive

    /////////////////////////////

    function compareToLinkFn(scope, element, attr, ngModel) {
        ngModel.$validators.compareTo = function (modelValue, viewValue) {
            const value = modelValue || viewValue
            return value + '' === scope.$eval(attr.compareTo) + ''
        }
    }
}
