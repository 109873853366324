import _ from 'lodash'
import { AdminModuleInstance } from 'admin/AdminModule.factory'
import { IField, TTableRowData } from 'admin/types'

const QUERY_STATUSES = [
    {
        status: 'new',
        label: 'New',
    },
    {
        status: 'finished',
        label: 'Finished',
    },
    {
        status: 'processing',
        label: 'Processing',
    },
    {
        status: 'automation_queue',
        label: 'Automation Queue',
    },
    {
        status: 'skipped',
        label: 'Skipped',
    },
    {
        status: 'not_started',
        label: 'Not Started',
    },
    {
        status: 'throttled',
        label: 'Queue',
    },
    {
        status: 'disabled',
        label: 'Disabled',
    },
]

interface ScopeBindings {
    table: string
    tableData: TTableRowData[] | null
    fields: IField[]
    predicateField: string[]
    excludedVideoIds: string[]
    loading: boolean
    haveVideos: boolean
    workflowUri: string
    taskUri: string
    json: string[] | string

    getExcluded: () => string[] | null
    getStatusLabel: (status: string) => string
    isExcluded: (id: string) => boolean
    toggleExcluded: (video: any, isChecked: boolean) => void
    isNotDisabled: (video: any) => boolean
}

export default /* @ngInject */ function queryPreviewTableDirective(
    $q: ng.IQService,
    AdminModule: AdminModuleInstance,
    MapDialog: any
) {
    const directive = {
        restrict: 'E',
        scope: {
            table: '<',
            json: '<',
            haveVideos: '=',
            limit: '<?',
            excludedVideoIds: '=?',
            editMode: '<?',
            taskUri: '<?',
            workflowUri: '<?',
        },
        templateUrl: 'js/admin/directives/queryPreviewAndSelectionTable.directive.tpl.html',
        link: queryPreviewTableLinkFn,
    }

    return directive

    /////////////////////////////

    function queryPreviewTableLinkFn(scope: ng.IScope & ScopeBindings) {
        scope.excludedVideoIds = _.isArray(scope.excludedVideoIds) ? scope.excludedVideoIds : []

        let cancelLastRequest: ng.IDeferred<unknown> | null = null
        let lastTable: string | null
        const modalConfig = MapDialog.confirm()
            .title('Disable video?')
            .textContent(
                'The work for this video has started, are you sure you want to disable the video, and delete all of the work?'
            )
            .ok('Delete')
            .okClass('btn-danger')
            .cancel('Cancel')

        scope.predicateField = []
        scope.$watchGroup(['table', 'limit'], updateTableData)
        scope.$watchCollection('json', updateTableData)

        scope.getExcluded = getExcluded
        scope.getStatusLabel = getStatusLabel
        scope.isExcluded = isExcluded
        scope.toggleExcluded = toggleExcluded
        scope.isNotDisabled = isNotDisabled

        // cancel any incomplete request when the directive gets destroyed
        scope.$on('$destroy', () => {
            if (cancelLastRequest) {
                cancelLastRequest.resolve()
            }
        })

        function getStatusLabel(status: string) {
            return _.get(_.find(QUERY_STATUSES, { status }), 'label', 'Unknown Status')
        }

        function getExcluded() {
            return scope.excludedVideoIds
        }

        function toggleExcluded(video: any, isChecked: boolean) {
            const index = scope.excludedVideoIds.indexOf(video.map3id)
            if (index >= 0 && !isChecked) {
                scope.excludedVideoIds.splice(index, 1)
            }

            if (index < 0 && isChecked) {
                scope.excludedVideoIds.push(video.map3id)

                if (video.workflow_video_status === 'processing') {
                    MapDialog.show(modalConfig).catch(() => {
                        const index = scope.excludedVideoIds.indexOf(video.map3id)
                        scope.excludedVideoIds.splice(index, 1)
                    })
                }
            }
        }

        function isExcluded(id: string) {
            return _.includes(scope.excludedVideoIds, id)
        }

        function isNotDisabled(video: any) {
            return !isExcluded(_.get(video, 'map3id'))
        }

        function updateTableData() {
            const { table } = scope
            if (lastTable !== table) {
                lastTable = table
                scope.tableData = null
            }

            if (!table) {
                return
            }

            if (_.isArray(scope.json)) {
                if (!scope.json.length) {
                    scope.tableData = null
                    return
                }
            } else {
                scope.json = _.toString(scope.json)
                // if we don't have JSON clear table data
                if (!scope.json || _.isEmpty(_.get(angular.fromJson(scope.json), 'rules', []))) {
                    scope.tableData = null
                    return
                }
            }

            // if we have an uncomplete request, cancel it
            if (cancelLastRequest) {
                cancelLastRequest.resolve()
                cancelLastRequest = null
                scope.loading = false
            }

            // prepare a promise to give to $http.config.timeout,
            // so that we can cancel the request prematurely
            cancelLastRequest = $q.defer()

            AdminModule.getQueryPreview(
                table,
                scope.json,
                cancelLastRequest.promise,
                scope.workflowUri,
                scope.taskUri
            )
                .then((data) => {
                    if (!_.isEmpty(data.results)) {
                        scope.fields = data.fields
                        scope.tableData = data.results.sort(orderTableResults)
                        scope.haveVideos = true
                    } else {
                        scope.haveVideos = false
                        scope.tableData = []
                    }
                })
                .catch(() => {
                    scope.haveVideos = false
                    scope.tableData = []
                })
                .finally(() => {
                    cancelLastRequest = null
                    scope.loading = false
                    scope.predicateField.length = 0
                })

            scope.loading = true
        }
    }
}

function orderTableResults(a: any, b: any) {
    let result = 0
    if (a.workflow_video_status === 'finished' && b.workflow_video_status !== 'finished') {
        result = 1
    } else if (a.workflow_video_status === 'new' && b.workflow_video_status !== 'new') {
        result = -1
    } else if (b.workflow_video_status === 'finished') {
        result = -1
    }

    return result
}
