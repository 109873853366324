import _ from 'lodash'
import FastdomWrapper, { Task } from '../../services/FastdomWrapper'
import softInvariant from '../../util/softInvariant'
import Mousetrap from 'mousetrap'
import FilmstripCalculator from './FilmstripCalculator'
import TimelabelsDrawer from './TimelabelsDrawer'
import TimelabelsCalculator from './TimelabelsCalculator'
import { VideoAPIInstance } from '../../video/VideoAPIBuilder.factory'
import { SceneHelperInstance } from '../../services/scenes/SceneHelper.factory'
import { DOMUtilityInstance } from '../../services/DOMUtility.factory'
import type ngRedux from 'ng-redux'
import {
    CHROME_MAX_WIDTH,
    FILMSTRIP_DEFAULT_WIDTH,
    PERMANENT_VERTICAL_SCROLL_CLASS,
} from 'constants.es6'

export const RENDER_MODE_DEFAULT = 'default'
export const RENDER_MODE_CONDENSED = 'condensed'
export const RENDER_MODE_HIDDEN = 'hidden'

type ResizeData = {
    rightElementId: number
    leftElementId: number
    offsetFrames: number
    lastFrameNumber: number
}

export type TimelineElement = {
    id: number
    type: string
    startFrame: number
    endFrame: number

    $$uid: string
    $$isSelected?: boolean
    $$wasSingleSelected?: boolean
}

const genericTimelineComponent = {
    controller: genericTimelineController,
    require: {
        sceneSplittingCtrl: '?^^sceneSplitting',
    },
    bindings: {
        elements: '<',

        onSingleSelect: '&',
        onMultiSelect: '&',
        onResize: '&',

        filmstripType: '<?',
        renderMode: '<?',
    },
    template: [
        `
        <div class="zoomer">
            <playhead><frame-indicator></frame-indicator></playhead>
            <canvas class="timelabels"></canvas>

            <!-- Dummy timeline-element for calculating exact playhead height, removed on video load -->
            <timeline-element class="timeline-element timeline-element-dummy"></timeline-element>

            <timeline-element class="timeline-element"
                id="{{ ::element.$$uid }}" data-uid="{{ ::element.$$uid }}"
                ng-repeat="element in $ctrl.elements track by element.id"
                ng-class="[
                    element.cssClass,
                    'timeline-element-' + element.type,
                    {
                        active: element.$$isSelected,
                        disabled: !!element.nonCannonSegment,
                    }
                ]"
            >
                <div class="active-border"></div>

                <div class="highlight-is-empty-error-wrapper">
                    <div class="highlight-is-empty-error"
                        title="This highlight has no tagging information added."
                    ></div>
                </div>

                <div class="timeline-element-handle left"
                    ng-if="::$ctrl.getEditMode() && !$first"
                ></div>
                <div class="timeline-element-handle right"
                    ng-if="::$ctrl.getEditMode() && !$last"
                ></div>

                <canvas
                    filmstrip="element"
                    filmstrip-enabled="!element.nonCannonSegment"
                    filmstrip-id="{{ ::element.$$uid }}"
                    filmstrip-rendering-group="{{ ::$ctrl.filmstripRenderId }}"
                ></canvas>
            </timeline-element>
        </div>
        `,
    ].join(''),
}

export default genericTimelineComponent

type Bindings = {
    elements: TimelineElement[]

    onSingleSelect: ({ element }: { element: TimelineElement }) => void
    onMultiSelect: ({ indexRange }: { indexRange: number[] }) => void
    onResize: ({ resizeData }: { resizeData: ResizeData }) => void

    filmstripType?: string
    renderMode:
        | typeof RENDER_MODE_DEFAULT
        | typeof RENDER_MODE_CONDENSED
        | typeof RENDER_MODE_HIDDEN
}

/* @ngInject */
function genericTimelineController(
    this: unknown,
    $log: ng.ILogService,
    $ngRedux: ngRedux.INgRedux,
    $scope: ng.IScope,
    $rootScope: ng.IRootScopeService,
    $element: ng.IRootElementService,
    SceneHelper: SceneHelperInstance,
    DOMUtility: DOMUtilityInstance,
    SharedVideoAPI: any,
    ZoomScaler: any
) {
    const $ctrl = this as ng.IComponentController &
        Bindings & {
            timelineId: string
            videoApi: VideoAPIInstance
            videoFrameDuration: number
            filmstripRenderId: string
            filmstripWidth: number

            getEditMode: typeof getEditMode

            styleCtrl: any
            timelabelsDrawer: InstanceType<typeof TimelabelsDrawer>
            singleFrameWidth: number | false
            zoom: number
        }

    const timeline = $element.addClass('generic-timeline')

    const playhead = timeline.find('playhead')
    const zoomer = timeline.find('.zoomer')

    const timelabels = timeline.find('.timelabels')

    const MOUSESCROLL_SCROLL_SCALING = 100

    const fastdom = new FastdomWrapper()

    let fastdomRenderTask: Task
    let fastdomTimelabelsRenderTask: Task

    const throttledRender = DOMUtility.rafThrottle(render)
    const throttledSendFilmstripDrawEvent = DOMUtility.rafThrottle(sendFilmstripDrawEvent)
    const throttledUpdatePlayheadPosition = DOMUtility.rafThrottle(updatePlayheadPosition)
    const throttledRenderTimelabels = DOMUtility.rafThrottle(renderTimelabels)

    $ctrl.filmstripRenderId = 'filmstrip-' + _.random(0, Number.MAX_SAFE_INTEGER)
    $ctrl.timelineId = 'generic-timeline-' + DOMUtility.nextUid()
    timeline.attr('id', $ctrl.timelineId)

    $ctrl.$onInit = $onInit
    $ctrl.$onChanges = $onChanges
    $ctrl.getEditMode = getEditMode

    $ctrl.filmstripWidth = FILMSTRIP_DEFAULT_WIDTH

    return $ctrl

    ////////////////////////////

    function $onInit() {
        const inferredRenderMode = $ctrl.getEditMode() ? RENDER_MODE_DEFAULT : RENDER_MODE_CONDENSED
        $ctrl.renderMode = $ctrl.renderMode || inferredRenderMode
        $('html').toggleClass(PERMANENT_VERTICAL_SCROLL_CLASS, true)

        SharedVideoAPI.onLoad(handleVideoApiReady)
        const unsubscribe = $ngRedux.connect(selectTimelineData)($ctrl)
        $scope.$on('$destroy', () => {
            $('html').toggleClass(PERMANENT_VERTICAL_SCROLL_CLASS, false)
            unsubscribe()
            fastdom.destroy()
        })

        ////////////////////////////////

        function selectTimelineData(state: { sceneData: { zoom: number } }) {
            const zoom = _.get(state, 'sceneData.zoom', 0) as number
            return { zoom }
        }
    }

    function $onChanges(changes: {
        elements?: ng.IChangesObject<Bindings['elements']>
        filmstripType?: ng.IChangesObject<Bindings['filmstripType']>
        renderMode?: ng.IChangesObject<Bindings['renderMode']>
    }) {
        if (changes.elements) {
            $ctrl.elements = _.map(changes.elements.currentValue, attachUIDs)

            render()
        }

        if (changes.filmstripType) {
            throttledSendFilmstripDrawEvent()
        }

        if (changes.renderMode) {
            updateCustomStyles()
            forceFullRerender()
        }

        /////////////////////////////

        function attachUIDs(element: TimelineElement) {
            return {
                ...element,
                $$uid: $ctrl.timelineId + '-' + element.type + '-' + element.id,
            }
        }
    }

    function setupCustomStyles() {
        $ctrl.styleCtrl = DOMUtility.addCustomStyles()
        $scope.$on('$destroy', $ctrl.styleCtrl.remove)

        updateCustomStyles()
        forceFullRerender()
    }

    function updateCustomStyles() {
        if (!$ctrl.styleCtrl) {
            return
        }

        if ($ctrl.timelabelsDrawer) {
            $ctrl.timelabelsDrawer.renderStyle = $ctrl.renderMode
        }

        if ($ctrl.renderMode === RENDER_MODE_HIDDEN) {
            timeline.toggleClass('ng-hide', true)
            $rootScope.$broadcast('resize')
            return
        } else {
            timeline.toggleClass('ng-hide', false)
        }

        timeline.toggleClass('condensed', $ctrl.renderMode === 'condensed')

        const playheadTopOffsetByRenderMode = {
            [RENDER_MODE_DEFAULT]: 12,
            [RENDER_MODE_CONDENSED]: 0,
        }

        const playheadTopOffset = playheadTopOffsetByRenderMode[$ctrl.renderMode]
        softInvariant(_.isNumber(playheadTopOffset), 'Unknown render mode: %s', $ctrl.renderMode)

        // Resets inline height to evaluate height for different modes
        timeline.css('height', '')

        fastdom.measure(() => {
            const timelineElementMargin = parseInt(
                timeline.find('.timeline-element').css('margin-top')
            )
            let timelineElementHeight =
                (timeline.height() as number) - DOMUtility.scrollHeight - timelineElementMargin

            const totalFrames = $ctrl.videoApi.getDurationInFrames()
            const frameRatio = $ctrl.filmstripCalculator.getFrameRatio()

            // Shrinks frame width so all frames combined fit max html element width
            // See https://bugs.chromium.org/p/chromium/issues/detail?id=401762
            while (timelineElementHeight * frameRatio * totalFrames > CHROME_MAX_WIDTH) {
                timelineElementHeight--
            }

            while ($ctrl.filmstripCalculator.calculateFrameWidth(timelineElementHeight) % 1 !== 0) {
                timelineElementHeight--
            }

            const calculatedNewTimelineHeight =
                DOMUtility.scrollHeight + timelineElementHeight + timelineElementMargin

            timeline.height(`${calculatedNewTimelineHeight}px`)

            const playheadHeight = timelineElementHeight + timelineElementMargin - playheadTopOffset
            const css = `
                #${$ctrl.timelineId}.generic-timeline playhead {
                    top: ${playheadTopOffset}px;
                    height: ${playheadHeight}px;
                }
                #${$ctrl.timelineId}.generic-timeline .timeline-element {
                    height: ${timelineElementHeight}px;
                }
            `

            $ctrl.styleCtrl.update(css)

            $rootScope.$broadcast('resize')
        })
    }

    function handleVideoApiReady(videoApiParam: VideoAPIInstance) {
        // remove the dummy element used for playhead height calc
        timeline.find('.timeline-element-dummy').remove()

        $ctrl.videoApi = videoApiParam
        $ctrl.videoFrameDuration = $ctrl.videoApi.getDurationInFrames()

        setTimeout(() => {
            setupFilmstripWidth()
            setupTimelabels()
            setupZoomScaler()
            setupZoom()
            setupCustomStyles()
        })

        setupInteractions()

        startPlayheadLoop()
        throttledRender()

        // and on window resize
        $(window).on('resize', forceFullRerender)
        $scope.$on('$destroy', () => $(window).off('resize', forceFullRerender))

        // on scroll, send filmstrip draw event
        const sendFilmstripDrawEventNoArgs = () => sendFilmstripDrawEvent()
        timeline.on('scroll', sendFilmstripDrawEventNoArgs)
        $scope.$on('$destroy', () => timeline.off('scroll', sendFilmstripDrawEventNoArgs))
    }

    function forceFullRerender() {
        window.requestAnimationFrame(() => {
            window.requestAnimationFrame(() => {
                setupFilmstripWidth()

                if ($ctrl.videoApi) {
                    setupZoomScaler()
                    updateZoom()
                    throttledRenderTimelabels()
                    throttledRender()
                    throttledUpdatePlayheadPosition({})
                    sendFilmstripDrawEvent({ forceRedraw: true })
                }
            })
        })
    }

    function setupFilmstripWidth() {
        $ctrl.filmstripCalculator = new FilmstripCalculator()
        $ctrl.filmstripWidth = $ctrl.filmstripCalculator.calculateFrameWidth(
            timeline.find('timeline-element').outerHeight() as number
        )
    }

    function setupTimelabels() {
        const timelabelsCalculator = new TimelabelsCalculator({
            frameRate: $ctrl.videoApi.getFrameRate(),
        })

        $ctrl.timelabelsDrawer = new TimelabelsDrawer(timelabelsCalculator, timelabels[0], {
            renderStyle: $ctrl.renderMode,
        })

        timeline.on('scroll', renderTimelabels)
        $scope.$on('$destroy', () => {
            timeline.off('scroll', renderTimelabels)
            fastdom.clear(fastdomTimelabelsRenderTask)
            $ctrl.timelabelsDrawer.destroy()
            ;($ctrl.timelabelsDrawer as any) = null
        })

        renderTimelabels()
    }

    function renderTimelabels() {
        fastdom.clear(fastdomTimelabelsRenderTask)

        fastdomTimelabelsRenderTask = fastdom.measure(() => {
            const totalDuration = $ctrl.videoApi.getFrameAccurateDuration() as number
            const totalFrames = $ctrl.videoApi.getDurationInFrames()
            const totalWidth = zoomer.width() as number
            const left = timeline.scrollLeft() as number
            const width = timeline.width() as number
            const startSec = (left / totalWidth) * totalDuration
            const endSec = ((left + width) / totalWidth) * totalDuration

            fastdomTimelabelsRenderTask = fastdom.mutate(() => {
                $ctrl.timelabelsDrawer &&
                    $ctrl.timelabelsDrawer.draw({
                        totalDuration,
                        totalFrames,
                        totalWidth,
                        left,
                        width,
                        startSec,
                        endSec,
                        singleFrameWidth: $ctrl.singleFrameWidth,
                    })
            }) as Task
        }) as Task
    }

    function setupZoomScaler() {
        $ctrl.zoomScaler = ZoomScaler.frameAccurate(
            $ctrl.videoApi,
            timeline[0].getBoundingClientRect().width,
            $ctrl.filmstripWidth
        )
    }

    function setupZoom() {
        $scope.$watch('$ctrl.zoom', updateZoom)
    }

    function updateZoom() {
        if (!$ctrl.zoomScaler) {
            setTimeout(() => updateZoom())
            return
        }
        const zoom = $ctrl.zoom

        zoomer.css('width', $ctrl.zoomScaler.scaleRange(zoom) * 100 + '%')

        setupFilmstripWidth()
        updateSingleFrameWidth()
        renderTimelabels()

        updatePlayheadPosition({ forceCenterPlayhead: true })
        throttledSendFilmstripDrawEvent()
    }

    function updateSingleFrameWidth() {
        const frameIndicatorCutoffWidth = 5

        fastdom.measure(() => {
            $ctrl.singleFrameWidth =
                $ctrl.zoom === 100
                    ? $ctrl.filmstripWidth
                    : (zoomer.width() as number) / $ctrl.videoFrameDuration

            if ($ctrl.singleFrameWidth < frameIndicatorCutoffWidth) {
                $ctrl.singleFrameWidth = false
            }

            fastdom.mutate(() => {
                if ($ctrl.singleFrameWidth) {
                    // + 1 because it looks better than being exact width to the next notch
                    playhead.find('frame-indicator').width($ctrl.singleFrameWidth + 1)
                } else {
                    playhead.find('frame-indicator').width(0)
                }
            })
        })
    }

    function setupInteractions() {
        disableTimelineKeyboardScroll()
        setupInteractionsTimelineElementMouseClick()
        setupInteractionsPlayheadMousedrag()
        setupInteractionsMousewheel()
        setupInteractionsResize()
    }

    function disableTimelineKeyboardScroll() {
        // we are disabling arrow scrolls globally for all containsers,
        // but there is no way to do it only for the timeline that wouldn't introduce jitter
        Mousetrap.bind(['left', 'right'], ignoreArrowScroll)
        $scope.$on('$destroy', () => Mousetrap.unbind(['left', 'right']))

        //////////////////////////////

        function ignoreArrowScroll(e: KeyboardEvent) {
            e.preventDefault()
        }
    }

    function setupInteractionsTimelineElementMouseClick() {
        timeline.on('click', handleClick)
        $scope.$on('$destroy', () => timeline.off('click', handleClick))

        //////////////////////////////////////

        function handleClick(event: JQuery.MouseEventBase) {
            // only handle left click
            if (event.button !== 0) {
                return
            }

            // playhead has special click handling, so we back off here
            if (event.target === playhead[0]) {
                return
            }

            fastdom.measure(() => {
                const timelineX = $measure$getTimelineX(event)
                const frameNumber = $measure$getFrameNumberByTimelineX(timelineX)

                // If frame number is 0, this means that a angular ng-repeat
                // re-render happened in the same frame, and elements have zero width.
                // In this case, we will not attempt to handle the click.
                if (frameNumber === 0) {
                    return
                }

                const element = SceneHelper.getByFrameNumber($ctrl.elements, frameNumber)

                $ctrl.videoApi.seekFrame(frameNumber)
                updatePlayheadPosition({ timelineX })

                $scope.$applyAsync(function () {
                    if (!event.shiftKey) {
                        // single select
                        $ctrl.onSingleSelect({ element })
                    } else {
                        // multi select
                        let selectionStart, selectionEnd
                        const selectionAnchor = _.findIndex($ctrl.elements, {
                            $$wasSingleSelected: true,
                        })
                        // we're trying to do a multi select but cant find an anchor, so we fallback to single select
                        if (selectionAnchor === -1) {
                            $ctrl.onSingleSelect({ element })
                            return
                        }

                        const newSelection = _.indexOf($ctrl.elements, element)

                        if (newSelection > selectionAnchor) {
                            selectionStart = selectionAnchor
                            selectionEnd = newSelection
                        } else {
                            selectionStart = newSelection
                            selectionEnd = selectionAnchor
                        }

                        $ctrl.onMultiSelect({
                            indexRange: _.range(selectionStart, selectionEnd + 1),
                        })
                    }
                })
            })
        }
    }

    function setupInteractionsPlayheadMousedrag() {
        let playheadDragData = { isDragging: false }
        const throttledPlayheadDragMove = DOMUtility.rafThrottle(playheadDragMove)

        playhead.on('mousedown', playheadDragStart)
        window.document.addEventListener('mousemove', throttledPlayheadDragMove)
        window.document.addEventListener('mouseup', playheadDragStop)

        $scope.$on('$destroy', function () {
            playhead.off('mousedown', playheadDragStart)
            window.document.removeEventListener('mousemove', throttledPlayheadDragMove)
            window.document.removeEventListener('mouseup', playheadDragStop)
        })

        ////////////////////////////////////////////////////////////////////

        function playheadDragStart(event: JQuery.MouseDownEvent) {
            // only start drag with left click
            if (event.button === 0) {
                playheadDragData = {
                    isDragging: true,
                }
            }
        }

        function playheadDragMove(event: MouseEvent) {
            if (playheadDragData.isDragging) {
                fastdom.measure(() => {
                    const timelineX = $measure$getTimelineX(event)
                    const frameNumber = $measure$getFrameNumberByTimelineX(timelineX)

                    $ctrl.videoApi.seekFrame(frameNumber)
                    updatePlayheadPosition({ timelineX })
                })
            }
        }

        function playheadDragStop() {
            playheadDragData.isDragging = false
        }
    }

    function setupInteractionsResize() {
        // TODO cancel resize from onChange
        let resizeData: ResizeData | null = null

        let fastdomHandleMoveTask: Task

        timeline.on('mousedown', '.timeline-element-handle', handleResizeStart)
        window.document.addEventListener('mousemove', handleResizeMove)
        window.document.addEventListener('mouseup', handleResizeStop)

        function handleResizeStart(event: JQuery.MouseDownEvent) {
            if ($rootScope.$$phase) {
                return
            }
            const target = event.target as Element

            const timelineX = $measure$getTimelineX(event)
            const frameNumber = $measure$getFrameNumberByTimelineX(timelineX)
            const $element = $(target).parent()
            const element = SceneHelper.byJq($ctrl.elements, $element) as TimelineElement
            const elementIndex = _.indexOf($ctrl.elements, element)

            // Elements look like:
            // [[left handle]  element  [right handle]] [[left handle]  element  [right handle]]

            if (target.classList.contains('left')) {
                // we have clicked on the left handle, which means that we have the right element
                resizeData = {
                    rightElementId: element.id,
                    leftElementId: $ctrl.elements[elementIndex - 1].id,
                    offsetFrames: 0,
                    lastFrameNumber: frameNumber,
                }
            } else if (target.classList.contains('right')) {
                // we have clicked on the right handle, which means that we have the left element
                resizeData = {
                    rightElementId: $ctrl.elements[elementIndex + 1].id,
                    leftElementId: element.id,
                    offsetFrames: 0,
                    lastFrameNumber: frameNumber,
                }
            }

            timeline.addClass('resizing')
        }

        function handleResizeMove(event: MouseEvent) {
            if (!resizeData) {
                return
            }

            fastdom.clear(fastdomHandleMoveTask)
            fastdomHandleMoveTask = fastdom.measure(() => {
                if (!resizeData) {
                    return
                }

                const timelineX = $measure$getTimelineX(event)
                const frameNumber = $measure$getFrameNumberByTimelineX(timelineX)

                // fast circuit if we are at the same frame number
                if (resizeData.lastFrameNumber === frameNumber) {
                    return
                }

                const leftElement = _.find($ctrl.elements, {
                    id: resizeData.leftElementId,
                })
                const rightElement = _.find($ctrl.elements, {
                    id: resizeData.rightElementId,
                })

                if (!leftElement || !rightElement) {
                    // someone overwrote the timeline on us, bail!
                    resizeData = null
                    return
                }

                const offsetFrames = frameNumber - resizeData.lastFrameNumber

                const leftInBounds = _.inRange(
                    leftElement.endFrame + offsetFrames,
                    leftElement.startFrame,
                    Math.min(
                        rightElement.endFrame - 1,
                        _.get(leftElement, '$parent.endFrame', Number.MAX_SAFE_INTEGER) - 1
                    )
                )
                const rightInBounds = _.inRange(
                    rightElement.startFrame + offsetFrames,
                    Math.max(
                        leftElement.startFrame + 1,
                        _.get(rightElement, '$parent.startFrame', 0)
                    ),
                    rightElement.endFrame
                )

                if (leftInBounds && rightInBounds) {
                    resizeData.lastFrameNumber = frameNumber
                    resizeData.offsetFrames += offsetFrames

                    rightElement.startFrame += offsetFrames
                    leftElement.endFrame += offsetFrames

                    render()
                }
            }) as Task
        }

        function handleResizeStop() {
            if (!resizeData) {
                return
            }

            $scope.$applyAsync(function () {
                if (!resizeData) {
                    return
                }

                $ctrl.onResize({ resizeData })
                $ctrl.videoApi.seekFrame(resizeData.lastFrameNumber)
                resizeData = null
                timeline.removeClass('resizing')
            })
        }
    }

    function setupInteractionsMousewheel() {
        timeline.on('mousewheel', handleMousewheel as any)
        $scope.$on('$destroy', () => timeline.off('mousewheel', handleMousewheel as any))

        /////////////////////////////////////

        function handleMousewheel(
            event: JQuery.MouseEventBase & { deltaX: number; deltaY: number }
        ) {
            if (!(event.ctrlKey && event.shiftKey) && !(event.metaKey && event.shiftKey)) {
                // for normal scroll event, apply a faster scrolling based on zoom
                fastdom.measure(() => {
                    const scrollLeft = timeline.scrollLeft() as number

                    fastdom.mutate(() => {
                        const delta = (event.deltaX || event.deltaY) * MOUSESCROLL_SCROLL_SCALING
                        timeline.scrollLeft(scrollLeft + delta)
                    })
                })
            }
        }
    }

    function startPlayheadLoop() {
        animatePlayheadLoop()

        $ctrl.videoApi.addEventListener('timeupdate', function () {
            if (!$ctrl.videoApi.isPlaying()) {
                updatePlayheadPosition({})
            }
        })

        //////////////////////

        function animatePlayheadLoop() {
            // end animation loop
            if (!$ctrl || !$ctrl.videoApi || $ctrl.videoApi.$destroyed) {
                return
            }
            if ($ctrl.videoApi.isPlaying()) {
                updatePlayheadPosition({})
            }

            window.requestAnimationFrame(animatePlayheadLoop)
        }
    }

    function updatePlayheadPosition(
        opts: {
            playheadX?: number
            forceCenterPlayhead?: boolean
            timelineX?: number
        } = {}
    ) {
        fastdom.measure(() => {
            let newPlayheadX: number

            if (opts.playheadX) {
                newPlayheadX = opts.playheadX
            } else {
                const frameNumber = $ctrl.videoApi.getCurrentFrame()

                const element = SceneHelper.getByFrameNumber($ctrl.elements, frameNumber)
                if (!element) {
                    $log.warn(`No element found for frame ${frameNumber}`)
                    return
                }

                const $element = $(document.getElementById(element.$$uid) as Element)
                softInvariant(!!$element.length, 'No timeline element for current frame')

                const elemAnimationCache = DOMUtility.getAnimationData($element)
                // $log.debug('elemAnimationCache', elemAnimationCache)

                const inElementFrame = frameNumber - element.startFrame
                // $log.debug('inElementFrame', inElementFrame)

                if ($ctrl.zoom === 100) {
                    // frame-accurate zoom, place playhead directly by frame number pos
                    newPlayheadX = elemAnimationCache.left + inElementFrame * $ctrl.filmstripWidth
                } else {
                    let inElementRatio =
                        inElementFrame /
                        SceneHelper.getFrameDuration(element, $ctrl.videoFrameDuration)
                    inElementRatio = _.isFinite(inElementRatio) ? inElementRatio : 0
                    // $log.debug('inElementRatio', inElementRatio)
                    const inElementX = inElementRatio * elemAnimationCache.width
                    // $log.debug('inElementX', inElementX)

                    newPlayheadX = Math.round(elemAnimationCache.left + inElementX)
                }
            }

            const rightEarlySwitchPx = 10
            const timelineAnimationCache = DOMUtility.getAnimationData(timeline)
            const timelineVisibleLeft = timeline.scrollLeft() as number
            const timelineVisibleRight =
                timelineVisibleLeft + timelineAnimationCache.width - rightEarlySwitchPx

            fastdom.mutate(() => {
                playhead[0].style.transform = `translate3d(${newPlayheadX}px, 0, 0)`

                // scroll the timeline to center the playhead if we were forced,
                // or if the playhead would fall outside the visible part of the
                // timeline viewport
                if (_.get(opts, 'forceCenterPlayhead', false)) {
                    timeline.scrollLeft(newPlayheadX - timelineAnimationCache.width / 2)
                } else if (
                    timelineVisibleLeft > newPlayheadX ||
                    timelineVisibleRight < newPlayheadX
                ) {
                    timeline.scrollLeft(newPlayheadX)
                }
            })
        })
    }

    function $measure$getFrameNumberByTimelineX(timelineX: number): number {
        const domElements = timeline.find('.timeline-element')
        let $element: JQLite | undefined

        _.forEachRight(domElements, function (DOMNode) {
            const animationCache = DOMUtility.getAnimationData(DOMNode)
            if (animationCache.right > timelineX) {
                if (animationCache.width > 0) {
                    $element = $(DOMNode)
                }
            } else {
                // early stop forEach
                return false
            }
        })

        if (!$element) {
            return 0
        }

        const elemAnimationCache = DOMUtility.getAnimationData($element)
        const element = _.find($ctrl.elements, {
            $$uid: $element.data('uid'),
        }) as TimelineElement
        const inElementX = timelineX - elemAnimationCache.left
        let inElementFrame =
            (inElementX / elemAnimationCache.width) *
            SceneHelper.getFrameDuration(element, $ctrl.videoFrameDuration)
        inElementFrame = _.isFinite(inElementFrame) ? inElementFrame : 0
        const frameNumber = element.startFrame + inElementFrame

        return Math.floor(frameNumber) || 0
    }

    function $measure$getTimelineX(event: { pageX: number }): number {
        return event.pageX - timeline.offset()!.left + (timeline.scrollLeft() as number)
    }

    function render() {
        // render does not work until we have video api loaded
        if (!$ctrl.videoApi) {
            return
        }

        $log.debug('generic timeline render')

        fastdom.clear(fastdomRenderTask)
        fastdomRenderTask = fastdom.mutate(() => {
            const domElements = timeline.find('.timeline-element')
            _.forEach(domElements, function (domElement) {
                const $element = $(domElement)
                const element = _.find($ctrl.elements, { $$uid: $element.data('uid') })

                // bail on resize of a scene that was removed
                if (!element) {
                    return
                }

                const elementWidth =
                    (SceneHelper.getFrameDuration(element, $ctrl.videoFrameDuration) /
                        $ctrl.videoApi.getDurationInFrames()) *
                        100 +
                    '%'

                // TODO check if we need the cache at all anymore
                // we don't need to change the width
                if ($element.data('$renderedWidth') === elementWidth) {
                    return
                }

                // else, reset caches and resize
                $element.css('width', elementWidth)
                $element.data('$renderedWidth', elementWidth)
            })

            throttledSendFilmstripDrawEvent()
        }) as Task
    }

    function sendFilmstripDrawEvent(opts: { forceRedraw?: boolean } = {}) {
        const filmstripIds = _.map($ctrl.elements, '$$uid')

        if (filmstripIds.length !== 0) {
            const videoIsPlaying = $ctrl.videoApi && $ctrl.videoApi.isPlaying()

            $scope.$broadcast('filmstrip.draw', {
                forceRedraw: !!opts.forceRedraw,
                ids: filmstripIds,
                renderingGroup: $ctrl.filmstripRenderId,
                videoIsPlaying: videoIsPlaying,

                enabled: $ctrl.filmstripType !== 'disabled',
                forceOneFrame: $ctrl.filmstripType === 'single',
            })
        }
    }

    function getEditMode(): boolean {
        if ($ctrl.sceneSplittingCtrl) {
            return $ctrl.sceneSplittingCtrl.getEditMode() as boolean
        } else {
            return true
        }
    }
}
