import _ from 'lodash'
import naturalCompare from 'natural-compare'
const ALLOWED_TYPES = ['string', 'integer', 'double', 'date', 'time', 'datetime', 'boolean']

/**
 * @ngdoc service
 * @name BuildsQueryFilters
 * @module map3.queryBuilderModule
 *
 * @description
 * Build Query Filters does what it says on the label - it builds the
 * `filters` object for jQuery Query Builder
 *
 * For info on the format, check http://querybuilder.js.org/index.html#filters
 *
 * This class is concerned with the raw filter manipulation, and is used by
 * QueryBuilderDataHandler.
 *
 * @property {array.<string>} operators Static list of allowed operators. Since we force
 *  select boxes for values, operators like `betwen`, `in`, `begins_with` etc are meaningless.
 */
export default class BuildsQueryFilters {
    static operators = [
        'equal',
        'not_equal',
        'is_empty',
        'is_not_empty',
        'is_null',
        'is_not_null',
        'less',
        'less_or_equal',
        'greater',
        'greater_or_equal',
    ]

    /**
     * @ngdoc method
     * @name BuildsQueryFilters#build
     *
     * @description
     * Given an array of fields and a perFieldUniqueValues object,
     * build QueryBuilder filters.
     *
     * The rules object is necessary to make sure a filter will always have
     * a value that is currently used in a rule as an available option, regardless
     * of whether it is available in the current `perFieldUniqueValues`.
     *
     * If we're given an array of previous filters, and the newly generated
     * filter would have no values, we use the values from the previous filter instead.
     *
     * @param {array.<string>} fields Array of fields for which to build
     * @param {object} perFieldUniqueValues Object where field names are keys
     * @param {array.<object>=} previousFilters The filters used for the previous generation
     *
     * @return {array.<object>} { id, label, type, input, values: array }
     */
    static build(fields, perFieldUniqueValues, rules, previousFilters = []) {
        fields = _.filter(fields, function (field) {
            return ALLOWED_TYPES.includes(field.type) && _.get(field, 'visible', true)
        })

        return _.flatMap(fields, function (field) {
            const previousFilter = _.find(previousFilters, { id: field.field })
            let values

            if (previousFilter && _.isEmpty(perFieldUniqueValues[field.field])) {
                values = BuildsQueryFilters.options(
                    previousFilter.values,
                    BuildsQueryFilters.extractRuleValues(rules, field.field)
                )
            } else {
                values = BuildsQueryFilters.options(
                    perFieldUniqueValues[field.field],
                    BuildsQueryFilters.extractRuleValues(rules, field.field)
                )
            }

            // This hack is in order to achieve desired needs and requirements;
            // We don’t have a good way to allow us to have different inputs per operator;
            // So we find out the field we need, and then we transform it into two different entries;
            // Both fields points to the same exact table field but allows us to have the ability
            // to have a single select field with dropdown values and a simple text input that
            // supports space separated entires as a bulk action;
            if (field.shortField === 'eidrL2') {
                return [
                    {
                        id: 'originalEidr',
                        field: field.field,
                        type: field.type,
                        input: 'select',
                        label: field.title,
                        operators: field.operators || BuildsQueryFilters.operators,
                        values,
                    },
                    {
                        id: 'bulkEidr',
                        field: field.field,
                        type: field.type,
                        input: 'text',
                        label: `${field.title} Bulk`,
                        operators: ['in'],
                        value_separator: ' ',
                    },
                ]
            }

            return {
                id: field.field,
                label: field.title,
                type: field.type,
                values,
                input: { date: 'text', datetime: 'text' }[field.type] || 'select',
                operators: field.operators || BuildsQueryFilters.operators,
            }
        })
    }

    /**
     * @ngdoc method
     * @name BuildsQueryFilters#options
     * @private
     *
     * @description
     * Generate `<select>` options array from field values.
     *
     * The options will be case-ignore naturally sorted
     *
     * @param {array} values Values to generate from
     * @param {array.<string>=} extra Manually added extra `<option>`s
     *
     * @return {array.<string>}
     */
    static options(values, extra = []) {
        let options = [''].concat(values).concat(extra)

        return _.uniq(convertNilToEmptyString(options)).sort(function (a, b) {
            return naturalCompare(('' + a).toLowerCase(), ('' + b).toLowerCase())
        })

        /////////////////////////////

        function convertNilToEmptyString(options) {
            return _.map(options, (option) => (_.isNil(option) ? '' : option))
        }
    }

    /**
     * @ngdoc method
     * @name BuildsQueryFilters#extractRuleValues
     * @private
     *
     * @description
     * Extract the rule values for a particular field.
     *
     * Given a rules object generated from `$el.queryBuilder('getRules')` and
     * a field name, extract all values for rules used by that field.
     *
     * This ended up being a somewhat convoluted recursive reducer.
     * Change with care and make sure to run the tests.
     *
     * @param {object} rules The rules from queryBuilder `getRules()`
     * @param {string} field The field we're extracting values for
     *
     * @return {array<string>}
     */
    static extractRuleValues(rules, field) {
        return _.reduce(
            rules,
            function (result, value, key) {
                if (key === 'rules') {
                    return result.concat(BuildsQueryFilters.extractRuleValues(value, field))
                }

                if (_.isObject(value)) {
                    if (_.isArray(value.rules)) {
                        return result.concat(
                            BuildsQueryFilters.extractRuleValues(value.rules, field)
                        )
                    }

                    if (_.isString(value.field) && value.field === field) {
                        return result.concat(value.value)
                    }
                }

                return result
            },
            []
        )
    }
}
