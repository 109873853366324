import _ from 'lodash'
/**
 * @ngdoc directive
 * @name uniqueFreeTextQuestionValidator
 * @module map3.admin
 * @restrict A
 *
 * @description
 * Validates if free text question has unique name.
 *
 * @param {Question} question Current question
 * @param {Array(Question)} selection Questions selected/created by user
 */

interface IDirectiveScope extends ng.IScope {
    selection: { label: string }[]
    question: { label: string }
}

export default /* @ngInject */ function uniqueFreeTextQuestionValidatorDirective() {
    const directive = {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            question: '=',
            selection: '=',
        },
        link: uniqueFreeTextQuestionValidatorLinkFn,
    }

    return directive

    function uniqueFreeTextQuestionValidatorLinkFn(
        scope: IDirectiveScope,
        _element: ng.IAugmentedJQuery,
        _attrs: ng.IAttributes,
        ngModel: ng.INgModelController
    ) {
        ngModel.$validators.uniqueFreeTextQuestion = (modelValue, viewValue) => {
            const value = modelValue || viewValue
            if (scope.selection.length > 1) {
                return !scope.selection
                    .filter((q) => q !== scope.question)
                    .some((q) => _.trim(q.label) === _.trim(value))
            } else {
                return true
            }
        }
    }
}
