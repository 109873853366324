import type { AdminModuleInstance } from './AdminModule.factory'

/* @ngInject */
export default function AdminSearchVideosCtrl(this: unknown, tables: any[]) {
    const vm = this as Record<string, unknown> & {
        tables: QueryBuilderTable[]
    }
    vm.tables = tables
}

AdminSearchVideosCtrl.resolve = {
    tables: /* @ngInject */ function (AdminModule: AdminModuleInstance) {
        return AdminModule.getVideoTables()
    },
}
