import _ from 'lodash'
import angular from 'angular'

export const GROUPING_TIMESTAMP = {
    value: 'timestamp',
    label: 'Timestamp',
} as const

export const GROUPING_SCENE = {
    value: 'scene',
    label: 'Scene',
} as const

export const GROUPING_SUBSCENE = {
    value: 'subscene',
    label: 'Sub Scene',
} as const

export const GROUPING_LIST = [GROUPING_TIMESTAMP, GROUPING_SCENE, GROUPING_SUBSCENE]

export type GroupedSegment<A extends BaseAnnotation = BaseAnnotation> = Segment & {
    annotations: A[]
    identifier: string

    isZeroSegment: boolean
    isCollapsed: boolean
}

export class SegmentGrouper {
    segments: {
        scenes: GroupedSegment[] | null
        subScenes: GroupedSegment[] | null
        allInOne: GroupedSegment[]
    }

    selectedGrouping: typeof GROUPING_TIMESTAMP | typeof GROUPING_SCENE | typeof GROUPING_SUBSCENE

    availableGroupings: typeof GROUPING_LIST

    $destroyed = false

    constructor(
        segments: {
            scenes: readonly Segment[] | null
            subScenes: readonly Segment[] | null
        },
        annotations: BaseAnnotation[]
    ) {
        // temporary setup segments
        this.segments = segments as any
        // propertly setup segments
        this.updateAnnotations(annotations)

        // setup selectedGrouping
        if (_.size(segments.subScenes)) {
            this.selectedGrouping = GROUPING_SUBSCENE
        } else if (_.size(segments.scenes)) {
            this.selectedGrouping = GROUPING_SCENE
        } else {
            this.selectedGrouping = GROUPING_TIMESTAMP
        }

        this.availableGroupings = [GROUPING_TIMESTAMP]
        if (segments.scenes !== null) {
            this.availableGroupings.push(GROUPING_SCENE)
        }
        if (segments.subScenes !== null) {
            this.availableGroupings.push(GROUPING_SUBSCENE)
        }
    }

    destroy() {
        this.$destroyed = true
        this.segments = {
            scenes: null,
            subScenes: null,
            allInOne: [],
        }
    }

    updateAnnotations(annotations: BaseAnnotation[]) {
        this.segments = {
            scenes: baseAttachAnnotationsToSegments(this.segments.scenes, annotations, 'scenes'),
            subScenes: baseAttachAnnotationsToSegments(
                this.segments.subScenes,
                annotations,
                'subScenes'
            ),
            allInOne: [
                {
                    id: -1,
                    start: 0,
                    end: 0,
                    annotations: annotations,
                    identifier: 'zero-segment',
                    isZeroSegment: true,
                    isCollapsed: false,
                },
            ],
        }
    }

    getSelectedGrouping() {
        return this.selectedGrouping
    }

    setSelectedGrouping(group: typeof GROUPING_LIST[number]) {
        if (!GROUPING_LIST.includes(group)) {
            throw Error(`Invalid grouping supplied "${angular.toJson(group)}"`)
        }

        this.selectedGrouping = group
    }

    getGroupedSegments(): GroupedSegment[] {
        switch (this.selectedGrouping.value) {
            case 'timestamp': {
                return this.segments.allInOne
            }

            case 'subscene': {
                if (this.segments.subScenes === null) {
                    throw new Error(
                        `Input data does not support grouping by ${this.selectedGrouping.label}`
                    )
                }

                return this.segments.subScenes
            }

            case 'scene': {
                if (this.segments.scenes === null) {
                    throw new Error(
                        `Input data does not support grouping by ${this.selectedGrouping.label}`
                    )
                }

                return this.segments.scenes
            }
        }
    }

    setAllSegmentsCollapsed(collapsed: boolean) {
        _.forEach(this.segments, (segments) => {
            _.forEach(segments, (segment) => {
                segment.isCollapsed = collapsed
            })
        })
    }

    forEachSegment<SegmentType extends GroupedSegment = GroupedSegment>(
        fn: (segment: SegmentType, index: number, array: SegmentType[]) => void
    ) {
        _.forEach(['scenes', 'subScenes'] as const, (key) => {
            const segments = this.segments[key]
            if (segments !== null) {
                segments.forEach(fn as any)
            }
        })
    }
}

export function baseAttachAnnotationsToSegments(
    segments: readonly Segment[] | null,
    annotations: readonly BaseAnnotation[],
    segmentLabel: string
): GroupedSegment[] {
    const sortedSegments = _.map(_.sortBy(segments, 'start'), (segment) => {
        return {
            isCollapsed: false,
            ...segment,
            annotations: [],
            identifier: segmentLabel + segment.id,
            isZeroSegment: false,
        } as GroupedSegment
    })

    _.forEach(annotations, (a) => {
        if (false === a.timestamp) {
            return
        }

        const ts = a.timestamp

        _.forEach(sortedSegments, (segment, idx) => {
            // if directly inside, just push it in
            if (inside(ts, segment.start, segment.end)) {
                segment.annotations.push(a)
                return false
            }

            // if between two segments
            const prevEnd = getPrevEnd(idx)
            if (false !== prevEnd && inside(ts, prevEnd, segment.start)) {
                // put it in either segment based on which one is closer
                if (ts - prevEnd < segment.start - ts) {
                    sortedSegments[idx - 1].annotations.push(a)
                } else {
                    segment.annotations.push(a)
                }
                return false
            }

            // if after all segments, put it in the last segment
            const nextStart = getNextStart(idx)
            if (false === nextStart) {
                segment.annotations.push(a)
            }
        })
    })

    return sortedSegments

    /////////////////////////////////////////////////

    function inside(timestamp: number, start: number, end: number): boolean {
        return timestamp >= start && timestamp <= end
    }

    function getPrevEnd(idx: number): number | false {
        const segment = sortedSegments[idx - 1]
        return segment ? segment.end : false
    }

    function getNextStart(idx: number): number | false {
        const segment = sortedSegments[idx + 1]
        return segment ? segment.start : false
    }
}
