import * as d3 from 'd3'
import d3tip from 'd3-tip'
import _ from 'lodash'

export default /* @ngInject */ function donutChart($window) {
    let directive = {
        restrict: 'E',
        link: donutChartLinkFn,
        scope: {
            job: '<',
            taskTypeColors: '<',
        },
    }

    return directive

    ////////////////////

    function donutChartLinkFn(scope, element, attrs) {
        let tip = d3tip()
            .attr('class', 'd3-tip')
            .offset([-10, 0])
            .html(function (d) {
                return `
                    <div class='graph-tooltip tooltip-top' style='background-color: ${d.data.color}; border-color: ${d.data.color};'>
                        <section class='tooltip-arrow'  style='background-color: ${d.data.color};'></section>
                        <strong>${d.data.name}:</strong>
                        <span> ${d.value} </span>
                    </div>
                `
            })

        let data = []

        _.forOwn(scope.job.tasks.task_type, function (value, key) {
            data.push({
                name: key,
                value,
            })
        })

        let config = {
            width: 120,
            height: 120,
            thickness: 120 * 0.3,
        }
        config.radius = Math.min(config.width, config.height) / 2

        let svg = d3
            .select(element[0])
            .append('svg')
            .attr('class', 'pie')
            .attr('width', config.width)
            .attr('height', config.height)

        svg.call(tip)

        let g = svg
            .append('g')
            .attr('transform', 'translate(' + config.width / 2 + ',' + config.height / 2 + ')')

        let arc = d3
            .arc()
            .innerRadius(config.radius - config.thickness)
            .outerRadius(config.radius)

        let pie = d3
            .pie()
            .value((d) => d.value)
            .sort(null)

        g.selectAll('path')
            .data(pie(data))
            .enter()
            .append('g')
            .append('path')
            .attr('d', arc)
            .attr('fill', (d) => {
                return scope.taskTypeColors[d.data.name]
            })
            .on('mouseover', tip.show)
            .on('mouseout', tip.hide)
            .each(function (d, i) {
                this._current = i
                d.data.color = scope.taskTypeColors[d.data.name]
            })
    }
}
