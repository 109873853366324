import type { ShortcutDefinition } from 'global-shortcuts/GlobalShortcuts.factory'
import { UserInstance } from 'auth/User.factory'
import { ENVIRONMENTS_FOR_ANALYTICS } from '../constants'
import noop from 'lodash/noop'
import _merge from 'lodash/merge'

const Snowplow = require('@fitt-addons/analytics/vendors/snowplow/ClientVendor')
const Debug = require('debug')
const debug = Debug('map3:snowplow')
const debugTrack = Debug('map3:snowplow:track')
const debugContext = Debug('map3:snowplow:context')

/**
 * See: https://gitlab.disney.com/dtci-tech-dmsc/shared-services/first-party-data/iglu-schema-registry/-/blob/main/schemas/com.disney.cp.dmed/component/jsonschema/1-0-0
 */
export type TrackEventOptions = {
    componentType?: string // "The type of the component. (typically the component file name).",
    actionName?: string // "The name of the action performed on the component.",
    label?: string // "The label of the component. (Optional: Could be the name/label of a button, a modal header/title, etc.)",
    value?: Record<string, unknown>[] // "The value of the component. (Optional: Could be the value of a form, the state of a component, etc.)",
    componentName?: string // "The name of the component. (Optional: Typically will be a custom name passed as a prop. (i.e. a Button with the label 'Create' that creates a title might be called 'Create Title Button'))"
    params?: Record<string, unknown> // "Additional parameters to pass to the component event. (Optional: Any additional information associated with the component.)",
}

export type SnowplowVendor = {
    trackComponent: (args: any) => void
    firePageView: (args: any) => void
    enableAutomaticLinkTracking: () => void
    refreshLinkTracking: () => void
    enableFormTracking: () => void
    enableErrorTracking: () => void
    trackSpellbookComponent: (args: any) => void
    trackSearch: (args: any) => void
    trackTiming: (args: any) => void
    setUserId: (args: any) => void
    fireSelfDescribingEvent: (args: any) => void
    enableActivityTracking: (args: any) => void
    init: (args: any) => void
    constructor: (args: any) => void
    fireStructEvent: (args: any) => void
    enableMediaTracking: (args: any) => void
}

let snowplowVendor: SnowplowVendor | null
let snowplowContexts: Record<string, Record<string, unknown> | any> = {}

let resolveUserContext: (value: unknown) => void
const userContext = new Promise((resolve) => {
    resolveUserContext = resolve
})

export function onUserReady(user: any) {
    const userEmail = user && user.email

    if (userEmail) {
        debug('onUserReady', user.email)
        snowplowVendor?.setUserId(user.email)
        setSnowplowContext('user', user)
        resolveUserContext(user)
    } else {
        // lets not hold up tracking if we
        // didn't get expected user data
        debug('onUserReady', 'error getting user')
        resolveUserContext({})
    }
}

export function getConfig(env: string, overrides: Record<string, unknown> = {}) {
    return _merge(
        {
            env,
            appName: `map3-ui-${env}`,
            activityTracking: {
                minimumVisitLength: 10,
                heartbeatDelay: 10,
            },
        },
        overrides
    )
}

export function setSnowplowContext(name: string, context: Record<string, unknown> | any) {
    debugContext('setSnowplowContext', name, context)
    snowplowContexts[name] = context
}

export function shouldEnable(env?: string) {
    return env && ENVIRONMENTS_FOR_ANALYTICS.includes(env)
}

/* @ngInject */
export function setupSnowplow($rootScope: ng.IRootScopeService, User: UserInstance) {
    debug('setupSnowplow')

    // eslint-disable-next-line prefer-rest-params
    const _testClass = arguments && arguments[2]

    const { MAP3_ENV } = window as TMap3Window
    if (!(MAP3_ENV && shouldEnable(MAP3_ENV))) return

    if (snowplowVendor) {
        throw new Error('Snowplow already initialized')
    }

    const config = getConfig(MAP3_ENV)
    const SnowplowClass = _testClass || Snowplow

    snowplowVendor = new SnowplowClass({ config })

    if (snowplowVendor) {
        snowplowVendor.enableAutomaticLinkTracking()
        snowplowVendor.refreshLinkTracking()
        snowplowVendor.enableFormTracking()
        snowplowVendor.enableErrorTracking()
    }

    // init user context
    const user = User.cached()

    let authHandle: () => void
    if (user && user.authenticated) {
        onUserReady(user)
        authHandle = noop
    } else {
        authHandle = $rootScope.$on('user.update', function afterAuth() {
            onUserReady(User.cached())
        })
    }

    $rootScope.$on('$destroy', function destroy() {
        authHandle()

        if (snowplowVendor) {
            snowplowVendor = null
        }

        snowplowContexts = {}
    })
}

export function getSnowplowVendor() {
    return snowplowVendor
}

export function getContextData() {
    return snowplowContexts
}

export function injectIntoValueMetadata(opts: TrackEventOptions) {
    const value = opts.value || []
    let objWithMetadata = value.find((obj) => 'metadata' in obj)
    if (!objWithMetadata) {
        objWithMetadata = { metadata: {} }
        value.push(objWithMetadata)
    }
    opts.value = value
    return objWithMetadata.metadata
}

export function injectContextData(opts: TrackEventOptions, targetCallback: any) {
    const target = targetCallback(opts) || {}
    const task = snowplowContexts.task

    if (snowplowContexts.user) {
        target.email = snowplowContexts.user.email
    }

    if (snowplowContexts.role) {
        target.role = snowplowContexts.role
    }

    if (task) {
        if (task.assignmentID) {
            target.assignmentId = task.assignmentID
        }

        if (task.hitID) {
            target.hitId = task.hitID
        }
    }
}

export function trackComponentWithContexts(
    opts: TrackEventOptions,
    componentType?: string,
    waitForUser = false
) {
    const trackComponentOptions: TrackEventOptions = {
        componentType,
        ...opts,
    }

    const first = waitForUser ? userContext : Promise.resolve()
    first.then(() => {
        injectContextData(trackComponentOptions, injectIntoValueMetadata)
        debugTrack('trackComponent', trackComponentOptions)
        snowplowVendor?.trackComponent?.(trackComponentOptions)
    })
}

export function trackButtonClick(opts: TrackEventOptions, componentType?: string) {
    trackComponentWithContexts(
        {
            actionName: 'click',
            ...opts,
        },
        componentType || 'Button'
    )
}

export async function trackPageView(url: string) {
    // wait until we know the user info is ready
    // to avoid sending page views with no user info
    await userContext

    const title = formatTitle(url)

    debugTrack('trackPageView', title)
    snowplowVendor?.firePageView?.({
        title,
    })
}

export function formatTitle(str_url: string) {
    let newURL

    const url = new URL(str_url)
    const HAS_NUM = /\d/
    const HAS_DOT = /\./i

    const formatPathOrHash = (segments: string[]): string => {
        let result = segments[0] || ''
        for (let i = 1; i < segments.length; i++) {
            if (!HAS_NUM.test(segments[i]) && !HAS_DOT.test(segments[i])) {
                result += `.${segments[i]}`
            }
        }
        return result
    }

    if (url.pathname === '/' && url.hash !== '') {
        const segments = url.hash.split('/').slice(1)
        newURL = formatPathOrHash(segments)
    } else if (url.pathname !== '/') {
        const segments = url.pathname.split('/').slice(1)
        newURL = formatPathOrHash(segments)
    }

    return newURL || 'default'
}

export async function handleKeyboardShortcut(shortcut: ShortcutDefinition) {
    const metadata = shortcut.metadata
    const keyCombo = shortcut.keyCombo

    const value = []
    if (metadata) {
        value.push({ metadata })
    }
    if (keyCombo) {
        value.push({ keyCombo })
    }

    trackComponentWithContexts(
        {
            actionName: shortcut.shortcut,
            label: shortcut.description || 'unknown keypress',
            value,
        },
        'Map3 Keyboard Shortcut'
    )
}
