import MissingSongResponseCtrl from './MissingSongResponse.ctrl'
import { Environment } from 'constants.es6'

export default /* @ngInject */ function publicRoutesConfig($stateProvider: ng.ui.IStateProvider) {
    // define auth states
    $stateProvider
        .state('about', {
            url: '/about',
            templateUrl: 'js/public/about-page.html',
            data: { public: true, allowRemember: false },
        })
        .state('project', {
            url: '/project',
            templateUrl: 'js/public/project-page.html',
            data: { public: true, allowRemember: false },
        })
        .state('missingSongResponse', {
            url: '/song-notification/:missingSongRequestToken',
            templateUrl: 'js/public/missing-song-response.tpl.html',
            controller: MissingSongResponseCtrl,
            controllerAs: MissingSongResponseCtrl.controllerAs,
            resolve: MissingSongResponseCtrl.resolve,
            data: { public: true, allowRemember: false },
        })
        .state('errorPageNotFound', {
            templateUrl: 'js/public/error-page-not-found.tpl.html',
            controller: /* @ngInject */ function (
                User: any,
                RoutingAuth: any,
                $state: ng.ui.IStateService
            ) {
                const vm = this as ng.ui.IState & {
                    goToHome: () => void
                }

                vm.goToHome = goToHome

                function goToHome() {
                    const route = User.isAuthenticated()
                        ? RoutingAuth.getDefaultState()
                        : RoutingAuth.loginState()

                    $state.go(route, undefined, { reload: true })
                }
            },
            controllerAs: 'vm',
            data: { allowRemember: false },
        })
        /**
         * DEVELOPMENT SPECIFIC ROUTES
         *
         * Sometimes, it makes sense to isolate a component to drive its dev and testing separate
         * from the rest of the system. These routes should only be visible on local-dev
         */
        .state('devGraph', {
            url: '/dev/graph',
            data: { public: true },
            controller: /* @ngInject */ function (
                graphData: any,
                workflowTemplates: WorkflowTemplate[]
            ) {
                const vm = this as ng.ui.IState & {
                    graph: any
                    workflowTemplates: WorkflowTemplate[]
                }

                vm.graph = graphData
                vm.workflowTemplates = workflowTemplates
                console.log(vm.workflowTemplates) // eslint-disable-line

                return vm
            },
            controllerAs: 'vm',
            template: `
                <workflow-graph
                    graph="vm.graph"
                ></workflow-graph>
            `,
            resolve: {
                graphData: () => {
                    return import('sample/graphData.json').then((d) => angular.copy(d.default))
                },
                workflowTemplates: () => {
                    return import('sample/workflowTemplates.json').then((d) =>
                        angular.copy(d.default)
                    )
                },
                access: /* @ngInject */ (ENVIRONMENT: TMap3Environment, $q: ng.IQService) => {
                    if (ENVIRONMENT !== Environment.LocalDevelopment) {
                        return $q.reject(new Error('No access outside of dev env'))
                    }
                },
            },
        })
}
