import _ from 'lodash'

import { trackButtonClick, setSnowplowContext } from 'util/snowplow'
import { DF_LIKE_VIDEOS, VGPlayerTimeFormat, WORKER_ROUTES } from 'constants.es6'
import { TIME_FORMAT_DROPDOWN_CHANGE_EVENT } from 'directives/constants'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { Task } from './types'
import { NotificationServiceInstance } from 'services/notification/Notification.factory'
import { TaskServiceInstance } from './TaskService.factory'

export default class WatchVideoTaskCtrl {
    static resolve = {
        assignmentID: /* @ngInject */ function ($stateParams: ng.ui.IStateParamsService) {
            return $stateParams.assignmentID
        },

        task: /* @ngInject */ function (TaskService: any, assignmentID: string): Promise<Task> {
            // for when you need LIGHTSPEED
            // return import('../../sample/answerQuestion.json').then((m) => m.default)

            return TaskService.getTask(assignmentID)
        },

        videoData: /* @ngInject */ function (VideoService: any, task: Task) {
            return VideoService.get(task.hitID)
        },
    }

    $scope: ng.IScope
    $state: ng.ui.IStateService

    MapDialog: any
    TaskService: TaskServiceInstance
    TaskListService: any
    Notification: NotificationServiceInstance
    task: Task
    assignmentID: string
    videoData: any
    hasDropFrames = false
    timeFormat = VGPlayerTimeFormat.Tape
    SharedVideoAPI: any
    videoApi!: VideoAPIInstance
    video: any
    filmStripType = 'single'
    renderMode = 'condensed'

    /* @ngInject */
    constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,

        MapDialog: any,
        TaskService: any,
        TaskListService: any,
        Notification: any,
        task: Task,
        assignmentID: string,
        videoData: any,
        SharedVideoAPI: any
    ) {
        this.MapDialog = MapDialog
        this.TaskListService = TaskListService
        this.Notification = Notification
        this.TaskService = TaskService
        this.$scope = $scope
        this.$state = $state
        this.task = task
        this.assignmentID = assignmentID
        this.videoData = videoData
        this.SharedVideoAPI = SharedVideoAPI
        this.video = videoData

        setSnowplowContext('task', task)

        $scope.$on('$destroy', () => {
            setSnowplowContext('task', null)
        })

        this.hasDropFrames =
            !!this.videoData.dropFrameTimecode || DF_LIKE_VIDEOS.includes(this.videoData.frameRate)
        this.timeFormat = this.hasDropFrames ? VGPlayerTimeFormat.TapeDF : VGPlayerTimeFormat.Tape

        $scope.$on(TIME_FORMAT_DROPDOWN_CHANGE_EVENT, (_$event, timeFormat) => {
            const isDF = timeFormat === VGPlayerTimeFormat.TapeDF
            this.videoApi.setDropFramesMode(isDF)
            this.timeFormat = timeFormat
        })
    }

    submitAllAndEnd() {
        const submitConfig = this.MapDialog.confirm()
            .title('Submit task')
            .htmlContent(
                `
                <strong>Are you sure you want to proceed?</strong>
            `
            )
            .ok('Yes')
            .cancel('No')

        this.MapDialog.show(submitConfig).then(() => {
            trackButtonClick({
                label: 'Submit Task',
                componentName: 'Annotate Task Submit',
                value: [
                    {
                        metadata: {
                            hitId: this.task.hitID,
                        },
                    },
                ],
            })

            this.submitAnnotations()
        })
    }

    submitAnnotations() {
        this.Notification.forPromise(
            this.TaskService.submitAnnotations(this.assignmentID, []).then(() => {
                this.$state.go('worker.list')
            }),
            'Task successfully submited'
        )
    }

    confirmReturnTask() {
        const modalConfig = this.MapDialog.confirm()
            .title('Abandon Task')
            .textContent('If you abandon this task, it will return to the Available Tasks list.')
            .ok('Abandon')
            .cancel('Cancel')

        this.MapDialog.show(modalConfig).then(() => {
            trackButtonClick({
                label: 'Abandon Task',
                value: [
                    {
                        metadata: {
                            hitId: this.task.hitID,
                        },
                    },
                ],
            })

            // returnTask(task)
            this.TaskListService.returnTask(this.task).then(() => {
                window.location.href = this.$state.href(WORKER_ROUTES.WORKER_LIST.STATE_NAME)
                window.location.reload()
            })
        })
    }

    $setupFilmstrip() {
        this.$scope.$on('change-film-strip-mode', (_, zoom) => {
            if (zoom === 100) {
                this.filmStripType = 'full'
            } else {
                this.filmStripType = 'single'
            }
        })
        this.videoApi.getFilmStripDrawer(() => {
            this.filmStripType = 'single'
        })
    }

    $onInit() {
        this.SharedVideoAPI.onLoad((videoApi: VideoAPIInstance) => {
            this.videoApi = videoApi
            this.$setupFilmstrip()
        })
    }
}
