export default /* @ngInject */ function hiddenErrorInterceptorFactory($q: ng.IQService) {
    const hiddenErrorInterceptor = {
        /**
         * Check successful $http responses for `data.success = false`
         * and mark the whole response as a failure if present
         */
        response: function (response: { [key: string]: any }) {
            if (
                response.data &&
                angular.isDefined(response.data.success) &&
                !response.data.success
            ) {
                return $q.reject(response)
            } else {
                return response
            }
        },
    }

    return hiddenErrorInterceptor
}
