export default /* @ngInject */ function UserProfileModuleFactory(
    $http: ng.IHttpService,
    User: any
) {
    const UserProfileModule = {
        getUserData() {
            return $http.get('/api/profile').then((res) => res.data)
        },

        editUserData(userData: TUserData) {
            return $http.post('/api/profile/update', userData).then((res) => {
                // request the updated user from the backend and then return the original result
                return User.getCurrent(true).then(() => res)
            })
        },
    }

    return UserProfileModule
}
