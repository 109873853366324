import _ from 'lodash'

import GraphSearch from 'directives/graph/GraphSearch'
import { AdminModuleInstance } from 'admin/AdminModule.factory'
import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'

export default /* @ngInject */ function scenesCompletion(
    $q: ng.IQService,
    AdminModule: AdminModuleInstance,
    Notification: any,
    ErrorStringifier: ErrorStringifierInstance
) {
    const directive = {
        restricted: 'E',
        templateUrl: 'js/admin/workflow-wizard/directives/scenesCompletion.tpl.html',
        link: scenesCompletionLinkFn,
        scope: {
            workflow: '<',
            node: '<',
            graph: '<',
            isEditable: '<?',
            taskConfig: '=',
        },
    }

    return directive

    /////////////////////////////////////

    interface ScopeVariables {
        videosValid: boolean
        hideForOldTask: boolean
        isAnnotateThroughQA: boolean
        vmdResults: Record<string, any> | null
        loading: boolean
        isPartialAnnotationEnabled: () => boolean
    }

    interface ScopeBindings {
        node: Record<string, any>
        graph: any
        isEditable: boolean
        taskConfig: Record<string, any>
    }

    function scenesCompletionLinkFn(scope: ng.IScope & ScopeBindings & ScopeVariables) {
        scope.isPartialAnnotationEnabled = function () {
            const template = _.get(scope.node, 'info.wizards.template')

            return template !== 'AssistedAnnotate'
        }
        scope.isEditable = scope.isEditable !== false

        scope.isAnnotateThroughQA = scope.taskConfig.extend_input =
            GraphSearch.isChainedUpstream(scope.graph, scope.node, [
                { info: { wizards: { template: 'QATask' } } },
                { info: { wizards: { template: 'AssistedAnnotate' } } },
            ]) ||
            GraphSearch.isChainedUpstream(scope.graph, scope.node, [
                { info: { wizards: { template: 'QATask' } } },
                { info: { wizards: { template: 'QuestionVideos' } } },
            ])

        activate()

        /////////////////////////////////////////////////////////////////

        function activate() {
            // for display only old tasks that never got video_manipulation set, do not display anything
            if (!scope.isEditable && !scope.taskConfig.video_manipulation_data) {
                scope.hideForOldTask = true
                return
            }

            let cancelLastRequest: angular.IDeferred<unknown> | null
            scope.$watchGroup(
                ['taskConfig.internal_options.acts', 'taskConfig.internal_options.scenes'],
                () => {
                    // for use data from the taskConfig
                    if (scope.isEditable) {
                        loadDataFromBackend()
                    } else {
                        // scope.questionsValid = scope.videosValid = true
                        scope.vmdResults = scope.taskConfig.video_manipulation_data.results
                    }
                }
            )

            // cancel any incomplete request when the directive gets destroyed
            scope.$on('$destroy', function () {
                if (cancelLastRequest) {
                    cancelLastRequest.resolve()
                }
            })

            function loadDataFromBackend() {
                if (cancelLastRequest) {
                    cancelLastRequest.resolve(false)
                    cancelLastRequest = null
                }

                cancelLastRequest = $q.defer()

                const segmentConfigs = scope.taskConfig.internal_options
                const videoNodes = GraphSearch.searchAllUpstream(
                    scope.graph,
                    scope.node,
                    {
                        info: { wizards: { template: 'GetVideos' } },
                    },
                    1,
                    1
                )
                const videoConfigs = _.map(videoNodes, 'info.config')
                scope.videosValid =
                    !!videoConfigs.length &&
                    _.every(videoConfigs, (videoConfig) => {
                        return !!(videoConfig.json || _.size(videoConfig.jsonArray))
                    })

                if (!scope.videosValid) {
                    // we do not have valid data, discontinue execution
                    return
                }

                const groupId = _.get(scope, 'workflow.owner', '')
                const projectId = _.get(scope, 'workflow.project', '')

                scope.loading = true

                AdminModule.getSegmentsCompletion(
                    groupId,
                    projectId,
                    videoConfigs,
                    segmentConfigs,
                    cancelLastRequest.promise
                )
                    .then((data) => {
                        scope.taskConfig.video_manipulation_data = data
                        scope.vmdResults = scope.taskConfig.video_manipulation_data.results
                    })
                    .catch((error) => {
                        const errorMessage = ErrorStringifier.stringify(error)
                        Notification.error(`Something went wrong ${errorMessage}`)

                        scope.vmdResults = null
                    })
                    .finally(() => (scope.loading = false))
            }
        }
    }
}
