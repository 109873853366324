import AdminJobsListCtrl from './AdminJobsList.ctrl'
import AdminJobCreateCtrl from './AdminJobCreate.ctrl'
import AdminJobStatusCtrl from './AdminJobStatus.ctrl'
import AdminManageUsersCtrl from './AdminManageUsers.ctrl'
import AdminCreateUserCtrl from './AdminCreateUser.ctrl'
import AdminEditUserCtrl from './AdminEditUser.ctrl'
import AdminTasksListCtrl from './AdminTasksList.ctrl'
import AdminManageGroupsCtrl from './manage-groups/AdminManageGroups.ctrl'
import AdminManageProjectsCtrl from './manage-projects/AdminManageProjects.ctrl'
import AdminManageVideosCtrl from './manage-videos/AdminManageVideos.ctrl'
import AdminDashboardCtrl from './AdminDashboard.ctrl'
import AdminManageJobKeepAliveCtrl from './AdminManageJobKeepAlive.ctrl'
import AdminSettingsCtrl from './AdminSettings.ctrl'
import AdminSearchVideosCtrl from './AdminSearchVideos.ctrl'
import AdminManageTaxonomyCtrl from './manage-taxonomy/AdminManageTaxonomy.ctrl'
import MarkerRepublishCtrl from './MarkerRepublish.ctrl'
import AdminSMSIngestCtrl from './AdminSMSIngest.ctrl'

import { Environment } from 'constants.es6'

export default /* @ngInject */ function adminRoutesConfig($stateProvider: ng.ui.IStateProvider) {
    $stateProvider
        .state('admin', {
            url: '/admin',
            abstract: true,
            template: '<ui-view></ui-view>',
            data: { roles: ['Admin'], showInBugReport: true },
        })
        .state('admin.jobs', {
            url: '/jobs',
            controller: AdminJobsListCtrl,
            controllerAs: 'adminVm',
            templateUrl: 'js/admin/jobs-list.html',
            data: { roles: ['Admin'] },
            resolve: AdminJobsListCtrl.resolve,
        })
        .state('admin.jobCreate', {
            url: '/jobs/create',
            controller: AdminJobCreateCtrl,
            controllerAs: AdminJobCreateCtrl.controllerAs,
            templateUrl: 'js/admin/job-create.html',
            data: {
                roles: ['Admin'],
                stateProvider: $stateProvider,
            },
            resolve: AdminJobCreateCtrl.resolve,
        })
        .state('admin.manageJobKeepAlive', {
            url: '/jobs/:jobId/manage-keep-alive',
            controller: AdminManageJobKeepAliveCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/job-manage-keep-alive.html',
            resolve: AdminManageJobKeepAliveCtrl.resolve,
        })
        .state('admin.jobStatus', {
            url: '/jobs/:jobId',
            controller: AdminJobStatusCtrl,
            controllerAs: 'adminVm',
            templateUrl: 'js/admin/job-status.html',
            data: { roles: ['Admin'] },
            resolve: AdminJobStatusCtrl.resolve,
        })
        .state('admin.settings', {
            url: '/settings',
            controller: AdminSettingsCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/settings.html',
            data: { roles: ['SuperAdmin'] },
            resolve: AdminSettingsCtrl.resolve,
        })
        .state('admin.manageUsers', {
            url: '/manage-users',
            controller: AdminManageUsersCtrl,
            controllerAs: 'adminVm',
            templateUrl: 'js/admin/manage-users.html',
            data: { roles: ['Admin'] },
            resolve: AdminManageUsersCtrl.resolve,
        })
        .state('admin.createUser', {
            url: '/create-users',
            controller: AdminCreateUserCtrl,
            controllerAs: 'adminVm',
            templateUrl: 'js/admin/create-user.html',
            data: { roles: ['Admin'] },
            resolve: AdminCreateUserCtrl.resolve,
        })
        .state('admin.editUser', {
            url: '/edit-user/:username',
            controller: AdminEditUserCtrl,
            controllerAs: 'adminVm',
            templateUrl: 'js/admin/edit-user.html',
            data: { roles: ['Admin'] },
            resolve: AdminEditUserCtrl.resolve,
        })
        .state('admin.tasks', {
            url: '/tasks',
            controller: AdminTasksListCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/tasks-list.html',
            data: { roles: [['Admin', 'Manager']] },
            resolve: AdminTasksListCtrl.resolve,
        })
        .state('admin.manageGroups', {
            url: '/manage-groups',
            controller: AdminManageGroupsCtrl,
            controllerAs: 'adminVm',
            templateUrl: 'js/admin/manage-groups/manage-groups.html',
            data: { roles: ['Admin'] },
            resolve: AdminManageGroupsCtrl.resolve,
        })
        .state('admin.manageProjects', {
            url: '/manage-projects',
            controller: AdminManageProjectsCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/manage-projects/manage-projects.html',
            data: { roles: ['Admin'] },
            resolve: AdminManageProjectsCtrl.resolve,
        })
        .state('admin.uploadVideo', {
            url: '/manage-videos',
            controller: AdminManageVideosCtrl,
            controllerAs: 'manageVideosVm',
            templateUrl: 'js/admin/manage-videos/manage-videos.html',
            data: { roles: ['Admin'], disableAutomaticLogout: true },
            resolve: AdminManageVideosCtrl.resolve,
        })
        .state('admin.dashboard', {
            url: '/dashboard',
            controller: AdminDashboardCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/dashboard.html',
            data: { roles: ['Admin'] },
            resolve: AdminDashboardCtrl.resolve,
        })
        .state('admin.searchVideos', {
            url: '/search-videos',
            controller: AdminSearchVideosCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/search-videos.html',
            data: { roles: ['Admin'] },
            resolve: AdminSearchVideosCtrl.resolve,
        })
        .state('admin.manageTaxonomy', {
            url: '/manage-taxonomy',
            controller: AdminManageTaxonomyCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/manage-taxonomy/manage-taxonomy.html',
            data: { roles: ['Admin'] },
            resolve: AdminManageTaxonomyCtrl.resolve,
        })
        .state('admin.MarkerRepublish', {
            url: '/marker-republish',
            controller: MarkerRepublishCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/marker-republish.html',
            data: { roles: ['Admin'] },
            resolve: MarkerRepublishCtrl.resolve,
        })
        .state('admin.SMSIngest', {
            url: '/sms-ingest',
            controller: AdminSMSIngestCtrl,
            controllerAs: 'vm',
            templateUrl: 'js/admin/sms-ingest.html',
            data: { roles: ['Admin'] },
            resolve: AdminSMSIngestCtrl.resolve,
        })

        /**
         * DEVELOPMENT SPECIFIC ROUTES
         *
         * Sometimes, it makes sense to isolate a component to drive its dev and testing separate
         * from the rest of the system. These routes should only be visible on local-dev
         */
        .state('admin.devGraph', {
            url: '/dev/graph',
            controller: /* @ngInject */ function (
                graphData: any,
                workflowTemplates: WorkflowTemplate[]
            ) {
                const vm = this as ng.ui.IState & {
                    graph: any
                    workflowTemplates: WorkflowTemplate[]
                }

                vm.graph = graphData
                vm.workflowTemplates = workflowTemplates
                console.log(vm.workflowTemplates) // eslint-disable-line

                return vm
            },
            controllerAs: 'vm',
            template: `
                <workflow-graph
                    class="with-border"
                    graph="vm.graph"
                    workflow="vm.workflow"
                    task-selected="vm.selectedWorkflowTask"
                ></workflow-graph>
            `,
            resolve: {
                graphData: () => {
                    return import('sample/graphData.json').then((d) => angular.copy(d.default))
                },
                workflowTemplates: () => {
                    return import('sample/workflowTemplates.json').then((d) =>
                        angular.copy(d.default)
                    )
                },
                access: /* @ngInject */ (ENVIRONMENT: TMap3Environment, $q: ng.IQService) => {
                    if (ENVIRONMENT !== Environment.LocalDevelopment) {
                        return $q.reject(new Error('No access outside of dev env'))
                    }
                },
            },
        })
}
