import GraphSearch from '../../../directives/graph/GraphSearch'

const acknowledgeSkipQa = {
    controller: acknowledgeSkipQaController,
    bindings: {
        workflow: '<',
        node: '<',
        graph: '<',
        taskConfig: '=',
    },
    template: `
        <div class="form-check form-group" ng-if="::$ctrl.displayAcknowledgeCheckbox">
            <input
                ng-model="$ctrl.taskConfig.skip_qa"
                type="checkbox"
                class="form-check-input"
                id="skip_qa"
            />
            <label class="form-check-label" for="skip_qa">Skip QA</label>
        </div>
    `,
}

export default acknowledgeSkipQa

interface ScopeBindings extends ng.IComponentController {
    workflow: unknown
    node: unknown
    graph: unknown
    taskConfig: unknown
}

/* @ngInject */
function acknowledgeSkipQaController(this: unknown) {
    const $ctrl = this as ScopeBindings

    $ctrl.$onInit = $onInit

    return $ctrl

    //////////////////////////

    function $onInit() {
        const { node, graph } = $ctrl
        const neighbors = GraphSearch.findDownstreamNeighbours(graph, node)

        // Don't show the Skip QA checkbox if we have a direct QA Task box connected
        $ctrl.displayAcknowledgeCheckbox = !neighbors.some((neighbor) => {
            return neighbor.info.wizards.template === 'QATask'
        })
    }
}
