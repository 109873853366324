export const PROGRAMMING_TYPE_FEATURE = 'feature'
export const PROGRAMMING_TYPE_FEATURE_FIELDS = ['title', 'releaseDate']

export const PROGRAMMING_TYPE_TV_MOVIE = 'tv movie'
export const PROGRAMMING_TYPE_TV_MOVIE_FIELDS = ['title', 'releaseDate']

export const PROGRAMMING_TYPE_SUPPLEMENTAL = 'supplemental'
export const PROGRAMMING_TYPE_SUPPLEMENTAL_FIELDS = ['title', 'releaseDate']

export const PROGRAMMING_TYPE_EPISODE_LABEL = 'series'
export const PROGRAMMING_TYPE_EPISODE = 'episode'
export const PROGRAMMING_TYPE_EPISODE_FIELDS = [
    'seriesTitle',
    'seasonNumber',
    'episodeTitle',
    'episodeNumber',
    'originalAirDate',
]

export const PROGRAMMING_TYPE_CLIP = 'clip'
export const PROGRAMMING_TYPE_CLIP_FIELDS = ['title', 'parentVideo', 'originalAirDate']

export const PROGRAMMING_SUB_TYPE_$BLANK = null
export const PROGRAMMING_SUB_TYPE_NEWS = 'news'
export const PROGRAMMING_SUB_TYPE_TV_ADAPTATION = 'tv adaptation'
export const PROGRAMMING_SUB_TYPE_ORIGINAL_TV_MOVIE = 'original tv movie'
export const PROGRAMMING_SUB_TYPE_DIGITAL_CHANNEL_ORIGINAL_MOVIE = 'digital channel original movie'

export const PROGRAMMING_TYPE_SHORT = 'short'
export const PROGRAMMING_TYPE_SHORT_FIELDS = ['title', 'releaseDate']

export const PROGRAMMING_TYPES = [
    {
        type: PROGRAMMING_TYPE_EPISODE,
        fields: PROGRAMMING_TYPE_EPISODE_FIELDS,
        subTypes: [PROGRAMMING_SUB_TYPE_$BLANK, PROGRAMMING_SUB_TYPE_NEWS],
    },
    {
        type: PROGRAMMING_TYPE_FEATURE,
        fields: PROGRAMMING_TYPE_FEATURE_FIELDS,
        subTypes: [PROGRAMMING_SUB_TYPE_$BLANK, PROGRAMMING_SUB_TYPE_TV_ADAPTATION],
    },
    {
        type: PROGRAMMING_TYPE_SHORT,
        fields: PROGRAMMING_TYPE_SHORT_FIELDS,
        subTypes: [],
    },
    {
        type: PROGRAMMING_TYPE_TV_MOVIE,
        fields: PROGRAMMING_TYPE_TV_MOVIE_FIELDS,
        subTypes: [
            PROGRAMMING_SUB_TYPE_$BLANK,
            PROGRAMMING_SUB_TYPE_ORIGINAL_TV_MOVIE,
            PROGRAMMING_SUB_TYPE_DIGITAL_CHANNEL_ORIGINAL_MOVIE,
        ],
    },
    {
        type: PROGRAMMING_TYPE_CLIP,
        fields: PROGRAMMING_TYPE_CLIP_FIELDS,
        subTypes: [],
    },
    {
        type: PROGRAMMING_TYPE_SUPPLEMENTAL,
        fields: PROGRAMMING_TYPE_SUPPLEMENTAL_FIELDS,
        subTypes: [],
    },
]
