import ReduxActions from 'services/ReduxActions'
import type ngRedux from 'ng-redux'

/**
 * @ngdoc service
 * @name SubSceneActions
 * @module map3.scenes
 *
 * @description
 * A bound {@link SceneActionCreators}
 *
 * @see SubSceneActionCreators
 */
export default /* @ngInject */ function SubSceneActionsFactory(
    $ngRedux: ngRedux.INgRedux,
    SubSceneActionCreators: any
) {
    return ReduxActions.bindActionCreators(SubSceneActionCreators, $ngRedux.dispatch)
}

/**
 * @ngdoc service
 * @name SubSceneActionCreators
 * @module map3.scenes
 *
 * @description
 * Actions that can be performed on {@link SceneData#subScenes SceneData#subScenes}
 *
 * Any action sent here will update the {@link $ngRedux} state using the FLUX model
 */
export function SubSceneActionCreatorsFactory() {
    const createAction = ReduxActions.createAction
    const require = ReduxActions.require
    const defaults = ReduxActions.defaults

    const SubSceneActionCreators = {
        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#splitByVideoCurrentTime
         * @description
         * Split scenes by videoApi.getCurrentTime()
         *
         * @param {videoAPI} payload VideoAPI instance
         */
        splitByVideoCurrentTime: createAction('SUB_SCENES_SPLIT_BY_VIDEO_CURRENT_TIME'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#selectById
         * @description
         * Select a scene by id
         *
         * @param {number} payload Scene id
         */
        selectById: createAction('SUB_SCENES_SELECT_BY_ID'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#selectByIndex
         * @description
         * Select a scene by index
         *
         * @param {number} payload Index
         */
        selectByIndex: createAction('SUB_SCENES_SELECT_BY_INDEX'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#selectNext
         * @description
         * Select the next scene
         *
         * @param {number=} payload Offset, default `+1`
         */
        selectNext: createAction('SUB_SCENES_SELECT_BY_OFFSET', defaults(1)),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#selectPrevious
         * @description
         * Select the previous scene
         *
         * @param {number=} payload Offset, default `-1`
         */
        selectPrevious: createAction('SUB_SCENES_SELECT_BY_OFFSET', defaults(-1)),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#mergeSelected
         * @description
         * Merge currently selected scenes {@link Scene#$$isSelected Scene.$$isSelected}
         * into a single scene
         */
        mergeSelected: createAction('SUB_SCENES_MERGE_SELECTED'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#moveStartFrame
         * @description
         * Move scene start by offset frames
         *
         * @param {Object} payload `{ videoApi, id: number, offsetFrames: number }`
         */
        moveStartFrame: createAction('SUB_SCENES_MOVE_START', require('id', 'offsetFrames')),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#moveSelectedStartFrame
         * @description
         * Move single selected scene start by offset frames
         *
         * @param {Object} payload Offset frames
         */
        moveSelectedStartFrame: createAction('SUB_SCENES_MOVE_SELECTED_START'),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#moveEndFrame
         * @description
         * Move scene end by offset frames
         *
         * @param {Object} payload `{ videoApi, id: number, offsetFrames:number }`
         */
        moveEndFrame: createAction('SUB_SCENES_MOVE_END', require('id', 'offsetFrames')),

        /**
         * @ngdoc method
         * @module map3.scenes
         * @name SubSceneActionCreators#moveSelectedEndFrame
         * @description
         * Move single selected scene end by offset frames
         *
         * @param {Object} payload Offset frames
         */
        moveSelectedEndFrame: createAction('SUB_SCENES_MOVE_SELECTED_END'),
    }

    return SubSceneActionCreators
}
