export default /* @ngInject */ function jsonTextareaDirective() {
    var directive = {
        require: 'ngModel',
        restrict: 'A',
        link: jsonTextareaLinkFn,
    }

    return directive

    function jsonTextareaLinkFn(scope, element, attrs, ngModelCtrl) {
        ngModelCtrl.$formatters.push(jsonFormatter)
        ngModelCtrl.$parsers.push(jsonParser)

        // force ngModel to re-render on deep proprety changes
        scope.$watch(
            attrs.ngModel,
            (model) => (ngModelCtrl.$modelValue = angular.copy(model)), // eslint-disable-line no-return-assign
            /* deep */ true
        )
    }

    function jsonFormatter(modelValue) {
        return angular.toJson(modelValue || {}, null, 4)
    }

    function jsonParser(viewValue) {
        try {
            return angular.fromJson(viewValue)
        } catch (e) {
            return undefined
        }
    }
}
