import map3core from 'app.core'

import routes from './routes'
import VideoServiceFactory from './VideoService.factory'
import VideoAPIBuilderFactory from './VideoAPIBuilder.factory'
import SharedVideoAPIFactory from './SharedVideoAPI.factory'
import VideoCommunicationFactory from './VideoCommunication.factory'

import videoGorillasPlayerDirective from './videoGorillasPlayer.directive'

/**
 * @ngdoc module
 * @name map3.video
 * @module map3.video
 *
 * @description
 * Video module
 */
const map3video = angular
    .module('map3.video', [map3core])
    .config(routes)

    .factory('VideoService', VideoServiceFactory)
    .factory('VideoAPIBuilder', VideoAPIBuilderFactory)
    .factory('SharedVideoAPI', SharedVideoAPIFactory)
    .factory('VideoCommunication', VideoCommunicationFactory)

    .directive('videoGorillasPlayer', videoGorillasPlayerDirective as ng.IDirectiveFactory)

    .constant('FRAME_EPSILON', 0.000001)

export default map3video.name
