import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { CommentInterfaceInstance } from './CommentInterface.factory'

interface ScopeBinding {
    videoApi: VideoAPIInstance | null
    showCreateThreadModal: () => void
}

export default /* @ngInject */ function commentAddDirective(
    CommentInterface: CommentInterfaceInstance,
    SharedVideoAPI: any
) {
    const directive = {
        restrict: 'E',
        link: commentAddLinkFn,
        scope: {
            componentClass: '@?',
        },
        template: `
            <button ng-disabled="!videoApi" ng-click="showCreateThreadModal()"
                ng-class="componentClass" role="button"
            >
                <i class="material-icons md-18">star</i>
                Add Comment
            </button>
        `,
    }

    return directive

    //////////////////////////////////

    function commentAddLinkFn(scope: ng.IScope & ScopeBinding) {
        scope.videoApi = null
        scope.showCreateThreadModal = showCreateThreadModal
        SharedVideoAPI.onLoad((videoApi: VideoAPIInstance) => (scope.videoApi = videoApi))

        function showCreateThreadModal() {
            CommentInterface.showCreateThreadModal(scope.videoApi!.getCurrentFrameTime())
        }
    }
}
