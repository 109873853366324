import map3core from 'app.core'
import map3auth from 'auth/module'

import routes, { TaskRouterFactory } from './routes'
import TaskListServiceFactory from './TaskListService.factory'
import TaskServiceFactory from './TaskService.factory'
import AuthorityManagerFactory from './AuthorityManager.factory'
import authorityDirective from './directives/authority.directive'
import authorityAttributesDirective from './directives/authorityAttributes.directive'
import authorityAttributesViewDirective from './directives/authorityAttributesView.directive'
import {
    workerTaskListComponent,
    workerTaskListActionsDirective,
    workerTaskListSelectionActionsDirective,
} from './directives/workerTaskList.component'
import annotoriousComponent from './components/annotorious.component'
import resizableImageComponent from './components/resizableImage.component'
import EditAnnotationFactory from 'services/EditAnnotation.factory'
import BoundryDrawerFactory from '../services/BoundryBoxDrawer.factory'
import DataEntryNetworkGuardFactory from '../services/annotation/DataEntryNetworkGuard.factory'
/**
 * @ngdoc module
 * @name map3.tasks
 * @module map3.tasks
 *
 * @description
 * Worker tasks module
 */
const map3tasks = angular
    .module('map3.tasks', [map3core, map3auth])
    .config(routes)
    .factory('TaskRouter', TaskRouterFactory)
    .factory('TaskListService', TaskListServiceFactory)
    .factory('AuthorityManager', AuthorityManagerFactory)
    .factory('TaskService', TaskServiceFactory)
    .factory('EditAnnotation', EditAnnotationFactory)
    .factory('BoundryDrawer', BoundryDrawerFactory)
    .factory('DataEntryNetworkGuard', DataEntryNetworkGuardFactory)
    .directive('authority', authorityDirective)
    .directive('authorityAttributes', authorityAttributesDirective)
    .directive('authorityAttributesView', authorityAttributesViewDirective)
    .component('workerTaskList', workerTaskListComponent)
    .component('annotorious', annotoriousComponent)
    .component('resizableImage', resizableImageComponent)
    .directive('workerTaskListActions', workerTaskListActionsDirective)
    .directive('workerTaskListSelectionActions', workerTaskListSelectionActionsDirective)

export default map3tasks.name
