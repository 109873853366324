import _ from 'lodash'

const glossaryImageComponent = {
    controller: glossaryImageController,
    bindings: {
        values: '<',
        parsedValues: '<',
        hasExamples: '<',
    },
}

export default glossaryImageComponent

/* @ngInject */ function glossaryImageController(
    this: unknown,
    $scope: ng.IScope,
    $element: ng.IAugmentedJQuery
) {
    const $ctrl = this as ng.IComponentController & {
        values: { value: string; label: string; exampleGifSrc?: string }[]
        parsedValues: { value: string; label: string }[]
        image: ng.IAugmentedJQuery
        loader: ng.IAugmentedJQuery

        $onInit: typeof $onInit
    }

    $ctrl.$onInit = $onInit

    return $ctrl

    /////////////////////////////

    function $onInit() {
        if (!$ctrl.hasExamples) {
            return
        }

        $element.on('mouseenter', '.active-result', function () {
            clearDOM()

            const zeroBasedIndex = $(this).data('option-array-index') - 1
            const term = $ctrl.parsedValues[zeroBasedIndex]
            const example = findExampleForTerm(term.value)

            if (!example) {
                return
            }

            const { x, y, width } = $element[0].getBoundingClientRect()
            const offsetX = window.innerWidth < x + width * 2

            const loaderHtml = `
                <div 
                    id="example-gif-container"
                    class="
                        ${offsetX ? 'left' : 'right'}
                        top card mt-0
                    "
                    style="width: ${width}px; height: 124px;"
                >
                    <div class="card-body text-center">
                        Example loading
                        <div class="loading-spinner" style="top: 84px;">
                            <div class="spinner-icon"></div>
                        </div>
                    </div>
                </div>
            `
            $ctrl.loader = $(loaderHtml).appendTo($element)

            const imageHtml = `
                <img
                    src="${example}"
                    id="example-gif"
                    class="
                        ${offsetX ? 'left' : 'right'}
                        d-none top bottom
                    "
                    style="width: ${width}px"
                />
            `

            $ctrl.image = $(imageHtml).appendTo($element)

            $ctrl.image
                .on('load', function (this: HTMLImageElement) {
                    // If we are in side-by-side layout, show bigger examples
                    const extendedWidth = $element
                        .parents('answer-questions')
                        .hasClass('is-side-by-side-ui')
                        ? $(document).find('.side-by-side').width()
                        : width

                    const computedHeight = extendedWidth
                        ? extendedWidth / (this.naturalWidth / this.naturalHeight)
                        : width / (this.naturalWidth / this.naturalHeight)

                    if (computedHeight < window.innerHeight - y) {
                        $ctrl.image.removeClass('bottom')
                    }

                    $ctrl.image.removeClass('d-none')
                    $ctrl.image.width(`${extendedWidth}px`)
                    $ctrl.loader.remove()
                })
                .on('error', () => {
                    $ctrl.loader.find('.card-body').html(`
                        <p>There has been error fetching the image!</p>
                    `)
                })
        })

        $element.on('mouseleave', '.active-result', clearDOM)
        $element.on('mouseleave', clearDOM)

        $scope.$on('$destroy', () => {
            $element.off('mouseleave mouseenter')
            clearDOM()
        })
    }

    function findExampleForTerm(uri: string) {
        return _.get(_.find($ctrl.values, { value: uri }), 'exampleGifSrc')
    }

    function clearDOM() {
        if ($ctrl.loader) {
            $ctrl.loader.remove()
        }

        if ($ctrl.image) {
            $ctrl.image.remove()
        }
    }
}
