import _ from 'lodash'

export default function GroupModelFactory() {
    let GroupModel = {
        isPublic: function (group) {
            return _.get(group, 'uri', '').indexOf('Public') > -1
        },
    }

    return GroupModel
}
