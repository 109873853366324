import _ from 'lodash'
import { AdminUsersModuleInstance } from './AdminUsersModule.factory'

interface IAdminEditUserCtrlInstance {
    passWidgetStep: number
    userData: any
    allDefinedRoles: TRole[]
    allDefinedGroups: Group[]
    tempGroups: Group[]
    tempRoles: TRole[]
    rolesError: boolean
    superAdminControlEnabled: boolean
    taskSelection: any
    showStep: (step: number) => void
    updateBasicInfo: () => void
    updateWorkingInfo: () => void
    returnTaskInQueue: () => ng.IPromise<any>
    clearSelection: () => void
}

export default /* @ngInject */ function AdminEditUserCtrl(
    this: unknown,
    USER_ROLE_ADMIN: string,
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    MAP3_ROLES: TRole[],
    $scope: ng.IScope,
    Notification: any,
    AdminUsersModule: AdminUsersModuleInstance,
    userData: any,
    groups: Group[]
) {
    const adminVm = this as IAdminEditUserCtrlInstance

    adminVm.passWidgetStep = 1
    adminVm.userData = userData

    adminVm.allDefinedRoles = MAP3_ROLES
    adminVm.allDefinedGroups = groups

    adminVm.tempRoles = userData.roles.slice()
    adminVm.tempGroups = userData.groups.slice()
    adminVm.rolesError = false

    adminVm.superAdminControlEnabled = _.includes(userData.roles, USER_ROLE_ADMIN)

    adminVm.showStep = showStep
    adminVm.updateBasicInfo = updateBasicInfo
    adminVm.updateWorkingInfo = updateWorkingInfo
    adminVm.returnTaskInQueue = returnTaskInQueue
    adminVm.clearSelection = () => {
        adminVm.taskSelection.length = 0
    }

    activate()

    ////////////////////////////////////////////

    function activate() {
        $scope.$watchCollection('adminVm.tempRoles', updateSuperAdmin)

        adminVm.userData.tasks = AdminUsersModule.getTasks(
            adminVm.userData.username,
            NO_LOAD_OVERLAY
        ).then((tasks) => {
            adminVm.userData.tasks = tasks
        })
    }

    function returnTaskInQueue() {
        const promise = AdminUsersModule.returnTaskInQueue(adminVm.taskSelection).then(
            (response: any) => {
                if (response.failed.length) {
                    const tasksRemoved = removeTasksFromUserTasks(response.failed)

                    handleUnavailable(tasksRemoved)
                }

                if (_.size(response.updated)) {
                    handleUpdated(response.updated)
                }

                if (response.success.length) {
                    removeTasksFromUserTasks(response.success)

                    Notification.forPromise(
                        promise,
                        'Selected tasks were moved to the Available tasks list.'
                    )
                }

                adminVm.clearSelection()
            }
        )

        return promise

        ////////////////////////////////////////////

        function handleUnavailable(unavailableTasks: any[]) {
            Notification.warning(`
                <pre>
                    Some task are not available anymore:
                    ${_.map(unavailableTasks, 'job_name').join(', ')}
                </pre>
            `)
        }

        function handleUpdated(updatedTasks: any[]) {
            _.forEach(updatedTasks, (task) => {
                const index = _.findIndex(adminVm.userData.tasks, {
                    assignmentId: task.assignmentId,
                })

                if (index !== -1) {
                    adminVm.userData.tasks.splice(index, 1, task)
                } else {
                    adminVm.userData.tasks.push(task)
                }
            })
        }

        function removeTasksFromUserTasks(removedTasks: any[]) {
            return _.remove(adminVm.userData.tasks, (task: any) =>
                _.includes(removedTasks, task.assignmentId)
            )
        }
    }

    function updateSuperAdmin(roles: TRole[]) {
        if (!_.includes(roles, USER_ROLE_ADMIN)) {
            if (!_.isUndefined(adminVm.userData.super_admin)) {
                adminVm.userData.super_admin = false
            }
            adminVm.superAdminControlEnabled = false
        } else {
            adminVm.superAdminControlEnabled = true
        }
    }

    function showStep(step: number) {
        adminVm.passWidgetStep = step
    }

    function updateBasicInfo() {
        const basicInfoPromise = AdminUsersModule.editUserData(adminVm.userData)

        Notification.forPromise(basicInfoPromise, 'Basic Info successfully updated')
    }

    function updateWorkingInfo() {
        // Check for choosen Roles. They are a must.
        if (adminVm.tempRoles.length === 0) {
            adminVm.rolesError = true
            return
        }

        const params = {
            uri: adminVm.userData.uri,
            rate: adminVm.userData.rate,
            roles: adminVm.tempRoles,
            super_admin: adminVm.userData.super_admin,
            groups: adminVm.tempGroups,
        }

        const workingInfoPromise = AdminUsersModule.editUserData(params)

        Notification.forPromise(workingInfoPromise, 'Working Info successfully updated')
    }
}

AdminEditUserCtrl.resolve = {
    username: /* @ngInject */ function ($stateParams: ng.ui.IStateParamsService) {
        return $stateParams.username
    },

    userData: /* @ngInject */ function (
        AdminUsersModule: AdminUsersModuleInstance,
        username: string
    ) {
        return AdminUsersModule.getUserData(username)
    },

    groups: /* @ngInject */ function (AdminUsersModule: AdminUsersModuleInstance) {
        return AdminUsersModule.getGroups()
    },
}
