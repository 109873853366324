import _ from 'lodash'

import { QA_ROUTES } from 'constants.es6'
import { DOMUtility } from 'services/DOMUtility.factory'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'

import { ContentMarkersBackendInterfaceServiceInstance } from './ContentMarkersBackendInterfaceService.factory'
import { getLabelForRating, findMaxId } from './utils'
import { CONTENT_MARKER_STATUS, ContentMarkerType } from './constants'
import { IContentMarkerVideoPlayerMarker, TContentMarker, TContentMarkerBackend } from './types'

import { trackButtonClick, setSnowplowContext } from 'util/snowplow'

export default class ReviewContentMarkersCtrl {
    static resolve = {
        videoData: /* @ngInject */ (VideoService: any, hitID: string) => VideoService.get(hitID),
        hitID: /* @ngInject */ ($stateParams: ng.ui.IStateParamsService) => $stateParams.id,
        task: /* @ngInject */ (
            ContentMarkersBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance,
            hitID: string
        ) => ContentMarkersBackendInterfaceService.getQAData(hitID),
    }

    $scope: ng.IScope
    $uibModal: ng.ui.bootstrap.IModalService
    MapDialog: any
    AnnotationSeeker: any
    User: any

    globalShortcuts: any
    markerTransformation: any
    sharedVideoAPI: any
    contentMarkersBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance

    task: any
    video: any
    hitID: string

    markers: TContentMarkerBackend[]
    timelineMarkersList: any[] = []

    // eslint-disable-next-line
    videoApi!: VideoAPIInstance

    executeSave: (marker: TContentMarkerBackend, newMarker?: boolean) => ng.IPromise<any>

    createMarker: () => void
    approveMarker: (marker: TContentMarkerBackend) => void
    rejectMarker: (marker: TContentMarkerBackend) => void
    updateComment: (marker: TContentMarkerBackend) => void

    submitTask: () => ng.IPromise<any>
    navigateToTaskList: () => void

    getLabelForRating: (rating: number) => string

    /* @ngInject */
    constructor(
        $scope: ng.IScope,
        $state: ng.ui.IStateService,
        $uibModal: ng.ui.bootstrap.IModalService,

        MapDialog: any,
        AnnotationSeeker: any,
        User: any,
        Notification: any,
        GlobalShortcuts: any,
        MarkerTransformation: any,
        DataEntryNetworkGuard: any,
        SharedVideoAPI: any,
        ContentMarkersBackendInterfaceService: ContentMarkersBackendInterfaceServiceInstance,

        task: any,
        hitID: string,
        videoData: any
    ) {
        this.$scope = $scope
        this.$uibModal = $uibModal

        this.MapDialog = MapDialog
        this.globalShortcuts = GlobalShortcuts
        this.markerTransformation = MarkerTransformation
        this.sharedVideoAPI = SharedVideoAPI
        this.contentMarkersBackendInterfaceService = ContentMarkersBackendInterfaceService

        this.task = task
        this.video = videoData
        this.hitID = hitID
        this.markers = this.task.markers || []

        const unbind = this.globalShortcuts.bind({
            title: 'Review Markers',
            shortcuts: [
                {
                    description: 'Next Marker',
                    keyCombo: [']'],
                    shortcut: ']',
                    callback: () => {
                        AnnotationSeeker.seekOffset(this.videoApi, this.markers, 1)
                    },
                },
                {
                    description: 'Previous Marker',
                    keyCombo: ['['],
                    shortcut: '[',
                    callback: () => AnnotationSeeker.seekOffset(this.videoApi, this.markers, -1),
                },
                {
                    description: 'Add Marker',
                    global: true,
                    keyCombo: ['ctrl+x', 'meta+x'],
                    shortcut: 'control+X',
                    callback: () => this.createMarker(),
                },
                {
                    description: 'Approve Task',
                    global: true,
                    keyCombo: ['ctrl+shift+d', 'meta+shift+d'],
                    shortcut: 'control+shift+D',
                    callback: () => this.submitTask(),
                },
                {
                    description: 'Return to Worker',
                    global: true,
                    keyCombo: ['ctrl+shift+x', 'meta+shift+x'],
                    shortcut: 'control+shift+X',
                    callback: () => {
                        this.returnToWorkers()
                    },
                },
            ],
        })

        setSnowplowContext('task', task)
        this.$scope.$on('$destroy', function destroyReviewContentMarkersCtrl() {
            setSnowplowContext('task', null)
            unbind()
        })

        this.executeSave = DataEntryNetworkGuard.create(
            (marker: TContentMarker, newMarker = false) => {
                const promise = newMarker
                    ? ContentMarkersBackendInterfaceService.createMarker(hitID, marker)
                    : ContentMarkersBackendInterfaceService.updateMarker(hitID, marker)

                Notification.forPromise(promise, 'Successfully saved markers')
                return promise
            }
        )

        function trackMarkerAction(label: string, marker: TContentMarkerBackend) {
            trackButtonClick({
                label,
                value: [
                    {
                        metadata: {
                            marker,
                        },
                    },
                ],
            })
        }

        this.createMarker = () => {
            const worker = _.get(User.cached(), ['username'])
            const marker = this.createNewMarkerFor(worker)

            this.task.markers.push(marker)

            trackMarkerAction('Create Marker', marker)

            this.executeSave(marker, true)
        }

        this.approveMarker = (marker) => {
            marker.status = CONTENT_MARKER_STATUS.APPROVED

            trackMarkerAction('Approve Marker', marker)

            this.executeSave(marker)
        }

        this.rejectMarker = (marker) => {
            marker.status = CONTENT_MARKER_STATUS.REJECTED

            trackMarkerAction('Reject Marker', marker)

            this.executeSave(marker)
        }

        this.updateComment = (marker) => {
            const modalInstance = this.openCommentModal(marker)

            return modalInstance.result.then((comment) => {
                marker.comment = comment

                trackMarkerAction('Update Marker Comment', marker)

                this.executeSave(marker)
            })
        }

        this.submitTask = () => {
            trackButtonClick({
                label: 'Submit Task',
                value: [
                    {
                        metadata: {
                            markers: this.markers,
                        },
                    },
                ],
            })

            return ContentMarkersBackendInterfaceService.submitQAContentMarkers(
                this.hitID,
                this.markers
            )
                .then(() => this.navigateToTaskList())
                .catch(() => Notification.error('All markers should be Approved or Rejected'))
        }

        this.navigateToTaskList = () => {
            $state.go(QA_ROUTES.QA_LIST.STATE_NAME)
        }

        this.getLabelForRating = getLabelForRating
    }

    returnToWorkers() {
        // ? Probably irrelevant for workflows with no worker stage
        //return this.contentMarkersBackendInterfaceService.returnToWorkers(this.hitID)
    }

    getUndecided(markers: TContentMarkerBackend[]) {
        return _.filter(markers, (m) => m.status === CONTENT_MARKER_STATUS.UNKNOWN)
    }

    updateTimelineMarkersList() {
        this.timelineMarkersList = _.concat(
            this.markerTransformation.contentMarkers(
                (this.markers || []).map((m) => {
                    return {
                        id: m.id,
                        frameNumber: this.videoApi.convertLaxSecondToFrame(m.timestamp),
                        timestamp: m.timestamp,
                        type: m.type,
                        rating: _.get(m, 'rating', false),
                    }
                }),
                (_e: any, data: IContentMarkerVideoPlayerMarker) => {
                    this.focusMarker(data.id)
                }
            )
        )
    }

    createNewMarkerFor(worker: string): TContentMarkerBackend {
        const timestamp = this.videoApi.getCurrentFrameTime()
        return {
            id: this.markers?.length ? findMaxId(this.markers) + 1 : 0,
            timestamp: timestamp,
            originalTimestamp: timestamp,
            type: ContentMarkerType.FPCI,
            chapter: false,
            video: false,
            audio: false,
            story: false,
            rating: 2,
            comment: '',
            worker,
            status: CONTENT_MARKER_STATUS.APPROVED,
        }
    }

    focusMarker(id: number) {
        DOMUtility.scrollTo(`.content-marker-${id}`, {
            highlight: true,
            executeOnNextFrame: true,
        })
    }

    openCommentModal(marker: TContentMarkerBackend) {
        return this.$uibModal.open({
            size: 'md',
            template: `
                <form name="updateComment">
                    <header class="modal-header row">
                        <h4 class="mb-3 col-12">Update Comment</h4>
                    </header>
                    <section class="modal-body row mb-3">
                        <div class="col-12">
                            <textarea class="textarea w-100"
                                id="comment"
                                cols="30"
                                rows="10"
                                name="comment"
                                ng-model="comment"
                                ng-required="true"
                            ></textarea>
                        </div>
                    </section>
                    <footer class="modal-footer">
                        <button type="button" ng-click="$dismiss()" class="btn">
                            Cancel
                        </button>

                        <button
                            type="submit"
                            ng-click="$close(comment)"
                            class="btn btn-primary"
                        >
                            Save
                        </button>
                    </footer>
                </form>
             `,
            controller: /* @ngInject */ function (
                $scope: ng.IScope & { comment: string },
                initalComment: string
            ) {
                $scope.comment = initalComment
            },
            resolve: {
                initalComment: () => marker.comment,
            },
        })
    }

    confirmSaveAndSubmit() {
        const confirmDialog = this.MapDialog.confirm()
            .title(`Submit Markers Data`)
            .htmlContent(
                `
                    <strong>Are you sure you want to proceed?</strong>
                    <p>
                        When you submit your changes will be stored and you won't be able to edit them anymore.
                    </p>
                    ${
                        !_.isEmpty(this.getUndecided(this.markers))
                            ? `
                        <div class="alert alert-warning" role="alert">
                            Ther are undecided marker statuses. Are you sure you want to proceed?
                        </div>
                        `
                            : ''
                    }
                `
            )
            .ok('Yes')
            .cancel('No')

        this.MapDialog.show(confirmDialog).then(() => {
            trackButtonClick({
                label: 'Submit Markers Data',
                value: [
                    {
                        metadata: {
                            markers: this.markers,
                        },
                    },
                ],
            })

            return this.submitTask()
        })
    }

    $setupWatchFunctions() {
        this.$scope.$watch(
            'vm.task.markers',
            () => {
                this.markers = this.task.markers
                this.updateTimelineMarkersList()
            },
            /* deep */ true
        )
    }

    $onInit() {
        this.sharedVideoAPI.onLoad((videoApi: VideoAPIInstance) => {
            this.videoApi = videoApi
            this.$setupWatchFunctions()
        })
    }
}
