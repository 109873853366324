import { VGPlayerTimeFormat } from 'constants.es6'
import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'

/**
 * Convert seconds into a string representation + number of seconds left after modulo 60
 */
export const $modulo60 = (seconds: number): [string, number] => {
    return [('' + (seconds % 60)).padStart(2, '0'), Math.floor(seconds / 60)]
}

/**
 * @param {number} timeInSeconds
 * @return string like '01:23:45'
 */
export const getTimeFractionsByTimeInSeconds = (timeInSeconds: number) => {
    let remainder: number
    let seconds: string
    let minutes: string
    let hours: string
    ;[seconds, remainder] = $modulo60(timeInSeconds | 0)
    ;[minutes, remainder] = $modulo60(remainder)
    ;[hours, remainder] = $modulo60(remainder)

    return `${hours}:${minutes}:${seconds}`
}

export const timeStampWithSecAndMSec = (timeInSeconds: number): string => {
    return timeInSeconds.toLocaleString('en-EN', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    })
}

/**
 * @param {number} timeInSeconds
 * @return string like '01:23:45:678'
 */
export const timestampWithMilli = (timeInSeconds: number): string => {
    const timeInSecStr = timeStampWithSecAndMSec(timeInSeconds)
    const milliseconds = timeInSecStr.split('.')[1]
    return `${getTimeFractionsByTimeInSeconds(timeInSeconds)}:${milliseconds}`
}

export const getFrameFormatter = (timeFormat: VGPlayerTimeFormat, videoApi: VideoAPIInstance) => {
    if (timeFormat === VGPlayerTimeFormat.Clock) {
        return (time: number) => {
            return timestampWithMilli(videoApi.convertFrameToSeconds(time))
        }
    } else if (timeFormat === VGPlayerTimeFormat.Seconds) {
        return (time: number) => {
            return timeStampWithSecAndMSec(videoApi.convertFrameToSeconds(time))
        }
    } else if (timeFormat === VGPlayerTimeFormat.Frame) {
        return (frame: number) => frame
    } else {
        return (time: number) => {
            return videoApi.convertFrameToTape(time)
        }
    }
}

export const getSecondsFormatter = (timeFormat: VGPlayerTimeFormat, videoApi: VideoAPIInstance) => {
    if (timeFormat === VGPlayerTimeFormat.Clock) {
        return (time: number) => {
            const frame = videoApi.convertLaxSecondToFrame(time)
            const seconds = videoApi.convertFrameToSeconds(frame)
            return timestampWithMilli(seconds)
        }
    } else if (timeFormat === VGPlayerTimeFormat.Seconds) {
        return (time: number) => {
            const frame = videoApi.convertLaxSecondToFrame(time)
            const seconds = videoApi.convertFrameToSeconds(frame)
            return timeStampWithSecAndMSec(seconds)
        }
    } else if (timeFormat === VGPlayerTimeFormat.Frame) {
        return (time: number) => {
            const frame = videoApi.convertLaxSecondToFrame(time)
            const seconds = videoApi.convertFrameToSeconds(frame)
            return videoApi.convertLaxSecondToFrame(seconds)
        }
    } else {
        return (time: number) => {
            const frame = videoApi.convertLaxSecondToFrame(time)
            const seconds = videoApi.convertFrameToSeconds(frame)
            return videoApi.convertSecondsToTape(seconds)
        }
    }
}
