import { timeStampWithSecAndMSec } from 'services/utils'
import { VideoAPIInstance } from './VideoAPIBuilder.factory'

export default class SecondsInput {
    private static VALID_INPUT_REGEXP = /^(?=.{1,9}(\.|$))\d{1,}(,\d{3})*(\.\d+)?$/
    private static ERROR_CLASS_NAME = 'vg_timecode__value--error'
    private input: HTMLInputElement
    private videoApi: VideoAPIInstance
    private prevValue = ''
    constructor(input: HTMLInputElement, videoApi: VideoAPIInstance) {
        this.input = input
        this.videoApi = videoApi
        this.input.addEventListener('input', this.handleInput)
        this.input.addEventListener('keypress', this.handleKeyPress)
        this.input.addEventListener('paste', this.handlePaste)
        this.input.addEventListener('blur', this.handleBlur)
    }

    updateValue(timestamp: number) {
        const secondsFormatted = timeStampWithSecAndMSec(timestamp)
        this.input.value = secondsFormatted
        this.prevValue = secondsFormatted
    }

    destroy() {
        this.input.removeEventListener('input', this.handleInput)
        this.input.removeEventListener('keypress', this.handleKeyPress)
        this.input.removeEventListener('paste', this.handlePaste)
        this.input.removeEventListener('blur', this.handleBlur)
    }

    private handleKeyPress = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement
        const value = target.value
        if (
            !(
                // Allow only digits, and only one dot (.)
                (
                    (e.keyCode >= 48 && e.keyCode <= 57) ||
                    (e.keyCode === 46 && !_.includes(value, '.'))
                )
            )
        ) {
            e.preventDefault()
        }

        if (e.key === 'Enter') {
            ;(e.target as HTMLInputElement).blur()
        }
    }

    private handlePaste = (e: ClipboardEvent) => {
        const paste = e.clipboardData?.getData('text') || ''
        if (!SecondsInput.VALID_INPUT_REGEXP.test(paste)) {
            e.preventDefault()
        }
    }

    private handleInput = (e: Event) => {
        const target = e.target as HTMLInputElement
        const isValid = SecondsInput.VALID_INPUT_REGEXP.test(target.value)
        if (isValid) {
            this.input.classList.remove(SecondsInput.ERROR_CLASS_NAME)
        } else {
            this.input.classList.add(SecondsInput.ERROR_CLASS_NAME)
        }
    }

    private handleBlur = () => {
        const value = this.input.value
        const isNumberValid = SecondsInput.VALID_INPUT_REGEXP.test(value)
        if (isNumberValid) {
            const parsedValue = parseFloat(value.replaceAll(',', ''))
            const duration = this.videoApi.getPlayer().getSeekableDurationSec()
            const valueToSet = parsedValue > duration ? duration : parsedValue
            this.videoApi.seek(valueToSet)
        } else {
            this.input.value = this.prevValue
        }
        this.input.classList.remove(SecondsInput.ERROR_CLASS_NAME)
    }
}
