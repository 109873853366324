export default /* @ngInject */ function forceXSRFlocallyInterceptorProvider(
    this: ng.IServiceProvider,
    $httpProvider: ng.IHttpProvider
) {
    this.$get = /* @ngInject */ function forceXSRFlocallyInterceptorFactory(
        $cookies: any,
        ENVIRONMENT: TMap3Environment
    ) {
        return {
            request: function (config: { [key: string]: any }) {
                // if we are not in local development, do not force the XSRF header
                if (ENVIRONMENT !== 'local-development') {
                    return config
                }

                const headerName = config.xsrfHeaderName || $httpProvider.defaults.xsrfHeaderName
                const cookieName = config.xsrfCookieName || $httpProvider.defaults.xsrfCookieName

                config.headers[headerName] = $cookies.get(cookieName)

                return config
            },
        }
    }
}
