import angular from 'angular'
import _ from 'lodash'
import fp from 'lodash/fp'

import { buildGetAnswerValue } from '../services/annotation/buildGetAnswerValue'
import Storage from '../services/Storage'

import {
    QAAnnotationTreeInstance,
    AnnotationGroup,
    AnnotationTree,
    ReviewDataFull,
    ReviewDataPartial,
    isReviewDataFull,
} from './service/QAAnnotationTree.factory'
import {
    QAAnnotationInstance,
    QAAnnotation,
    NewAnnotation,
    ANNOTATION_STATUS_APPROVED,
} from './service/QAAnnotation.factory'
import { AnnotationValidationInstance } from '../services/AnnotationValidation.factory'
import { DataEntryNetworkGuardInstance } from '../services/annotation/DataEntryNetworkGuard.factory'
import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'
import { GlobalShortcutsInstance } from '../global-shortcuts/GlobalShortcuts.factory'
import { getColorFromIndex } from '../services/BoundryBoxDrawer.factory'

import { SegmentGrouper, GroupedSegment } from '../services/scenes/SegmentGrouper'
import { newRelic } from 'util/newRelic'
import { trackButtonClick, setSnowplowContext } from 'util/snowplow'
import { filterAnnotations, filterMarkers, filterSegments } from 'util/answersFilterBySearch'
import { QAAssignmentServiceInstance } from './QAAssignmentService.factory'
import { QA_ROUTES } from 'constants.es6'
import { DOMUtilityInstance } from 'services/DOMUtility.factory'

type QASegment = GroupedSegment<QAAnnotation> & {
    allPristineAndMature?: boolean
}

type ScopeVars = {
    QAAnnotation: QAAnnotationInstance
}

export default /* @ngInject */ function QAReviewQuestionsCtrl(
    this: unknown,

    ANNOTATION_TIMESTAMP_DISABLED_VALUE: boolean,
    $q: ng.IQService,
    $state: ng.ui.IStateService,
    $scope: ng.IScope & ScopeVars,
    $window: ng.IWindowService & { newrelic: any },
    $uibModal: ng.ui.bootstrap.IModalService,
    Notification: any,
    User: any,
    GlobalShortcuts: GlobalShortcutsInstance,
    MapDialog: any,
    QAAnnotation: QAAnnotationInstance,
    QAAnnotationTree: QAAnnotationTreeInstance,
    QAAssignmentService: QAAssignmentServiceInstance,
    QAReturnToWorkers: any,
    MarkerTransformation: any,
    CommentInterface: any,
    DataEntryNetworkGuard: DataEntryNetworkGuardInstance,
    AnnotationValidation: AnnotationValidationInstance,
    AnnotationSeeker: any,
    DOMUtility: DOMUtilityInstance,
    ErrorStringifier: ErrorStringifierInstance,

    id: string,
    reviewData: ReviewDataFull,
    videoData: any,
    commentThreads: any
) {
    const vm = this as {
        segmentGrouper: InstanceType<typeof SegmentGrouper>

        // any types
        video: any
        videoApi: any
        sceneData: {
            scenes: readonly Segment[] | null
            subScenes: readonly Segment[] | null
        }
        markers: any[]
        allMarkers: any[]
        commentThreads: any[]
        bookmarks: any[]
        shortcutsGroup: {
            title: string
            shortcuts: any[]
        }
        sideBySide: boolean
        triggerSideBySide: () => void

        annotationTree: AnnotationTree
        workers: ReviewDataFull['workers']
        questions: ReviewDataFull['options']['questions']
        options: ReviewDataFull['options']
        allAnnotations: QAAnnotation[]
        boundryBoxAnnnotations: QAAnnotation[]

        newAnnotation: NewAnnotation | null
        currentUndecidedAnnotation: QAAnnotation | null
        generalForm: undefined | ng.IFormController

        buildGetAnswerValue: typeof buildGetAnswerValue
        approveAnnotation: typeof approveAnnotation
        approveAll: typeof approveAll
        toggleAnnotationStatus: typeof toggleAnnotationStatus
        updateGroupOfAnnotations: typeof updateGroupOfAnnotations
        resetAnnotation: typeof resetAnnotation
        rejectAnnotation: typeof rejectAnnotation
        saveNewAnnotation: typeof executeSave
        editAnnotation: typeof editAnnotation
        duplicateAtCurrentTimestamp: typeof duplicateAtCurrentTimestamp
        updateAnnotation: typeof updateAnnotation
        goToNextUndecidedAnnotation: typeof goToNextUndecidedAnnotation
        cancelNewAnnotation: typeof cancelNewAnnotation
        loadOrCreateCommentThread: typeof loadOrCreateCommentThread
        approve: typeof approve
        reject: typeof reject
        returnToWorkers: typeof returnToWorkers
        confirmReturnTask: typeof confirmReturnTask
        getAnnotationTitle: typeof getAnnotationTitle
        findGroupsForSegment: typeof findGroupsForSegment
        findGroupForAnnotation: typeof findGroupForAnnotation
        getColorFromIndex: (index?: number) => string
        annotationsHasBoundingBox: (id: number) => boolean

        filterAnnotationsBySearch: typeof filterAnnotationsBySearch
        filterSegmentsBySearch: typeof filterSegmentsBySearch
        hideEmptyGroups: boolean
        searchString: string
    }

    $scope.QAAnnotation = QAAnnotation

    vm.segmentGrouper = new SegmentGrouper(
        { scenes: reviewData.scenes, subScenes: reviewData.subScenes },
        parseAnnotations(reviewData.annotationTree)
    )

    vm.video = videoData
    vm.commentThreads = commentThreads

    vm.buildGetAnswerValue = buildGetAnswerValue

    vm.approveAnnotation = DataEntryNetworkGuard.create(approveAnnotation)
    vm.resetAnnotation = DataEntryNetworkGuard.create(resetAnnotation)
    vm.rejectAnnotation = DataEntryNetworkGuard.create(rejectAnnotation)
    vm.toggleAnnotationStatus = DataEntryNetworkGuard.create(toggleAnnotationStatus)
    vm.updateGroupOfAnnotations = DataEntryNetworkGuard.create(updateGroupOfAnnotations)
    vm.saveNewAnnotation = DataEntryNetworkGuard.create(executeSave)
    vm.editAnnotation = editAnnotation
    vm.duplicateAtCurrentTimestamp = duplicateAtCurrentTimestamp
    vm.updateAnnotation = updateAnnotation
    vm.cancelNewAnnotation = cancelNewAnnotation

    vm.goToNextUndecidedAnnotation = goToNextUndecidedAnnotation

    vm.loadOrCreateCommentThread = loadOrCreateCommentThread

    vm.approve = approve
    vm.approveAll = approveAll
    vm.reject = reject
    vm.returnToWorkers = returnToWorkers
    vm.confirmReturnTask = confirmReturnTask

    vm.getAnnotationTitle = getAnnotationTitle
    vm.findGroupsForSegment = findGroupsForSegment
    vm.findGroupForAnnotation = findGroupForAnnotation
    vm.getColorFromIndex = getColorFromIndex
    vm.annotationsHasBoundingBox = annotationsHasBoundingBox
    vm.triggerSideBySide = triggerSideBySide

    vm.filterAnnotationsBySearch = filterAnnotationsBySearch
    vm.filterSegmentsBySearch = filterSegmentsBySearch
    vm.hideEmptyGroups = false
    vm.searchString = ''

    vm.shortcutsGroup = {
        title: 'QA Review Shortcuts',
        shortcuts: [
            {
                description: 'Next Annotation',
                keyCombo: ['ctrl+shift+]'],
                shortcut: 'control + shift+]',
                callback: () => AnnotationSeeker.seekOffset(vm.videoApi, vm.allAnnotations, 1),
            },
            {
                description: 'Previous Annotation',
                keyCombo: ['ctrl+shift+['],
                shortcut: 'control + shift+[',
                callback: () => AnnotationSeeker.seekOffset(vm.videoApi, vm.allAnnotations, -1),
            },
            {
                description: 'Next Undecided Annotation',
                keyCombo: ['ctrl+alt+shift+]'],
                shortcut: 'control + alt + shift+]',
                callback: () => goToNextUndecidedAnnotation(),
            },
            {
                description: 'Save Annotation',
                global: true,
                keyCombo: ['ctrl+s', 'meta+s'],
                shortcut: 'control+S',
                callback: shortcutOnSaveNewAnnotation,
            },
            {
                description: 'Cancel',
                keyCombo: ['esc'],
                shortcut: 'Esc',
                callback: shortcutOnCancel,
            },
            {
                description: 'Approve Task',
                global: true,
                keyCombo: ['ctrl+shift+d', 'meta+shift+d'],
                shortcut: 'control+shift+D',
                callback: shortcutOnApprove,
            },
            {
                description: 'Reject All',
                global: true,
                keyCombo: ['ctrl+shift+x', 'meta+shift+x'],
                shortcut: 'control+shift+X',
                callback: shortcutOnReject,
            },
            {
                description: 'Return to Worker',
                global: true,
                keyCombo: ['ctrl+x', 'meta+x'],
                shortcut: 'control+W',
                callback: shortcutOnReturnToWorkers,
            },
        ],
    }

    activate()

    ////////////////////

    function activate() {
        const skipInstructions = localStorage.getItem('skip_instructions')
        if (reviewData.show_instructions && !skipInstructions) {
            $uibModal.open({
                size: 'lg',
                templateUrl: 'js/qa/review.questions.instructions.tpl.html',
                controller: /* @ngInject */ function (this: unknown, $scope: ng.IScope) {
                    const instuctionModalVm = this as {
                        skip_instructions: boolean
                    }

                    instuctionModalVm.skip_instructions =
                        localStorage.getItem('skip_instructions') === 'present'

                    $scope.$watch(
                        'instuctionModalVm.skip_instructions',
                        (skipInstructions: boolean) => {
                            if (skipInstructions) {
                                localStorage.setItem('skip_instructions', 'present')
                            } else {
                                localStorage.removeItem('skip_instructions')
                            }
                        }
                    )
                },
                controllerAs: 'instuctionModalVm',
            })
        }

        $updateReviewData(reviewData)
        applyInitialSegmentCollapsedState()

        $scope.$watch('vm.annotationTree', updateMarkers)
        $scope.$watch('vm.searchString', filterMarkersBySearch)

        $setupShortcuts()
        $setupComments()

        setSnowplowContext('task', { hitID: id })

        $scope.$on('$destroy', function destroyReviewQuestionsCtrl() {
            setSnowplowContext('task', null)
        })

        // start in side-by-side mode when we have less than 2 questions
        vm.sideBySide =
            Storage.getOrUseDefault('map3_side_by_side_preference', true) &&
            vm.questions.length <= 2
    }

    function updateMarkers() {
        const markers = QAAnnotationTree.extractMarkers(vm.annotationTree)
        const commentMarkers = MarkerTransformation.threads(
            vm.commentThreads,
            CommentInterface.markerClick
        )

        // merge comment markers
        _.forEach(commentMarkers, function (commentMarker) {
            // comment markers either overwrite annotation markers or are added to the list
            const idx = _.findIndex(markers, { time: commentMarker.time })
            if (idx > -1) {
                markers[idx] = commentMarker
            } else {
                markers.push(commentMarker)
            }
        })

        vm.allMarkers = markers.concat(MarkerTransformation.bookmarks(vm.bookmarks))

        filterMarkersBySearch()
    }

    function $setupShortcuts() {
        // Activate global shortcuts
        const unbindShortcuts = GlobalShortcuts.bind(vm.shortcutsGroup)
        // when exiting from this page, remove global shortcuts
        $scope.$on('$destroy', unbindShortcuts)
    }

    function $setupComments() {
        vm.commentThreads = commentThreads
        vm.bookmarks = reviewData.bookmarks

        CommentInterface.init({
            hitID: id,
            threads: vm.commentThreads,
        })

        $scope.$watchCollection('vm.commentThreads', updateMarkers)

        $scope.$onRootScope('map3.addBookmark', addBookmark)
        $scope.$onRootScope('map3.deleteBookmark', deleteBookmark)

        $scope.$on('$destroy', CommentInterface.destroy)
    }

    function addBookmark() {
        const timestamp = vm.videoApi.getCurrentFrameTime()
        vm.bookmarks.push(timestamp)
        updateMarkers()

        QAAssignmentService.setBookmarks(id, vm.bookmarks)
    }

    function deleteBookmark(_: any, marker: any) {
        const index = vm.bookmarks.indexOf(marker.time)
        vm.bookmarks.splice(index, 1)
        updateMarkers()

        QAAssignmentService.setBookmarks(id, vm.bookmarks)
    }

    /**
     * @param {object=} annotation A specific annotation for which to add a comment.
     *      If not provided, the comment will be added to the current time.
     */
    function loadOrCreateCommentThread(annotation: QAAnnotation | null = null) {
        if (annotation) {
            const thread = _.find(vm.commentThreads, {
                timestamp: annotation.timestamp,
            })

            if (thread) {
                CommentInterface.showThreadModal(_.get(thread, 'id'))
            } else {
                CommentInterface.showCreateThreadModal(annotation.timestamp).then(function () {
                    annotation.hasComment = true
                })
            }
        } else {
            CommentInterface.showCreateThreadModal(vm.videoApi.getCurrentFrameTime())
        }
    }

    function $updateReviewData(data: ReviewDataPartial | ReviewDataFull) {
        if (isReviewDataFull(data)) {
            vm.options = data.options
            vm.questions = data.options.questions
            vm.sceneData = {
                scenes: data.scenes,
                subScenes: data.subScenes,
            }
        }

        vm.annotationTree = QAAnnotationTree.mergeAnnotationTree(
            vm.annotationTree,
            data.annotationTree
        )
        vm.workers = data.workers

        $updateMetadata()
    }

    function triggerSideBySide() {
        vm.sideBySide = !vm.sideBySide
        Storage.setItem('map3_side_by_side_preference', vm.sideBySide)
        $scope.$broadcast('resize')
    }

    function parseAnnotations(tree: AnnotationTree): QAAnnotation[] {
        return _.map(QAAnnotationTree.extractAllAnnotations(tree), (annotation) => {
            annotation.hasComment = !!_.find(vm.commentThreads, {
                timestamp: annotation.timestamp,
            })
            const clusterCopyId = _.get(annotation, 'extra_data.cluster_copy_for_annotation', false)
            annotation.isClusterCopy =
                _.isNumber(clusterCopyId) && clusterCopyId !== annotation.annotationID

            return annotation
        })
    }

    function annotationsHasBoundingBox(id: number): boolean {
        return !!_.find(vm.boundryBoxAnnnotations, { annotationID: id })
    }

    function $updateMetadata() {
        vm.allAnnotations = parseAnnotations(vm.annotationTree)
        vm.boundryBoxAnnnotations = QAAnnotationTree.extractBBAnnotations(vm.annotationTree)

        vm.segmentGrouper.updateAnnotations(vm.allAnnotations)
        vm.segmentGrouper.forEachSegment<QASegment>((segment) => {
            segment.allPristineAndMature = allPristineAndMature(segment.annotations)
            const hasErrorsInAnnotations = _.some(segment.annotations, 'hasErrors')
            if (hasErrorsInAnnotations) {
                segment.isCollapsed = false
            }
        })

        ////////////////////////////////////

        function allPristineAndMature(annotations: QAAnnotation[]): boolean {
            return (
                !!annotations.length &&
                _.every(annotations, (a) => QAAnnotation.isPristine(a) && QAAnnotation.isMature(a))
            )
        }
    }

    function goToNextUndecidedAnnotation(): void {
        const undecided = _.filter(vm.allAnnotations, (annotation) => isUndecided(annotation))

        if (!undecided.length) {
            return
        }

        // move the currentUndecidedAnnotation to the next one in the undecided list
        const nextIndex =
            (undecided.indexOf(vm.currentUndecidedAnnotation as any) + 1) % undecided.length
        vm.currentUndecidedAnnotation = undecided[nextIndex]

        // and scroll to it
        focusAnnotation(vm.currentUndecidedAnnotation)

        ////////////////////////////////////

        function isUndecided(annotation: QAAnnotation): boolean {
            // if pristine and not mature, it's undecided
            if (QAAnnotation.isPristine(annotation) && !QAAnnotation.isMature(annotation)) {
                return true
            }

            // if answer is irrelevant and has no siblings with approved answers, it's undecided
            if (AnnotationValidation.checkForIrrelevant(annotation)) {
                const group = getGroupForAnnotation(annotation)
                return !_.some(
                    group.annotations,
                    (annotation) => annotation.status === ANNOTATION_STATUS_APPROVED
                )
            }

            return false
        }

        function getGroupForAnnotation(annotation: QAAnnotation): AnnotationGroup {
            return _.find(vm.annotationTree, (group) =>
                group.annotations.includes(annotation)
            ) as AnnotationGroup
        }
    }

    function focusAnnotation(annotation: QAAnnotation): void {
        annotation.timestamp && vm.videoApi.seek(annotation.timestamp)

        vm.segmentGrouper.forEachSegment<QASegment>((segment) => {
            if (_.find(segment.annotations, { annotationID: annotation.annotationID })) {
                segment.isCollapsed = false
            }
        })

        DOMUtility.scrollTo(`.annotation-${annotation.annotationID}`, {
            highlight: true,
            executeOnNextFrame: true,
        })
    }

    function applyInitialSegmentCollapsedState() {
        vm.segmentGrouper.forEachSegment<QASegment>((segment) => {
            segment.isCollapsed = !!segment.allPristineAndMature
        })
    }

    /**
     * Approve an annotation, and refresh the
     * annotation tree
     */
    function approveAnnotation(annotation: QAAnnotation): ng.IPromise<any> {
        trackButtonClick({
            label: 'Approve Annotation',
            ...getAnnotationMetadata(annotation),
        })

        return QAAnnotation.approveAnnotation(id, annotation).then($updateReviewData)
    }

    function approveAll(event: Event) {
        const confirmDialog = MapDialog.confirm()
            .title('Approve all annotations')
            .htmlContent(
                `
                <strong>Are you sure you want to proceed?</strong>
                <p>
                    This will change the status of all tags to Accepted and is intended for revisions to previously published data, not as a substitute for QA. If you select Yes, you can still edit and add tags.
                </p>
            `
            )
            .ok('Yes')
            .cancel('No')

        MapDialog.show(confirmDialog)
            .then(() => {
                trackButtonClick({
                    label: 'Approve All Annotations',
                })

                const promise = QAAnnotation.approveAllAnnotations(id, vm.annotationTree).then(
                    $updateReviewData
                )

                Notification.forPromise(promise)
            })
            .finally(() => {
                if (event.target) {
                    $(event.target).trigger('blur')
                }
            })
    }

    function toggleAnnotationStatus(
        annotation: QAAnnotation,
        annotationGroup: { annotations: QAAnnotation[] }
    ): ng.IPromise<any> {
        trackButtonClick({
            label: 'Toggle Annotation Status',
            ...getAnnotationMetadata(annotation),
        })

        return QAAnnotation.toggleAnnotationStatus(id, annotation.annotationID, annotationGroup)
    }

    /**
     * Rest annotation to unknown sate, and refresh the annotation tree
     */
    function resetAnnotation(annotation: QAAnnotation): ng.IPromise<any> {
        trackButtonClick({
            label: 'Reset Annotation',
            ...getAnnotationMetadata(annotation),
        })

        return QAAnnotation.resetAnnotation(id, annotation).then($updateReviewData)
    }

    /**
     * Reject an annotation, and refresh the annotation tree
     */
    function rejectAnnotation(annotation: QAAnnotation): ng.IPromise<any> {
        trackButtonClick({
            label: 'Reject Annotation',
            ...getAnnotationMetadata(annotation),
        })

        return QAAnnotation.rejectAnnotation(id, annotation).then($updateReviewData)
    }

    /**
     * Save new annotation
     */
    function executeSave(): ng.IPromise<any> {
        const newAnnotation = vm.newAnnotation
        if (!AnnotationValidation.isValid<QAAnnotation>(newAnnotation, vm.questions)) {
            return $q.resolve(false)
        }
        // make sure we have the most current timestamp
        newAnnotation.timestamp = vm.options.use_timestamp
            ? vm.videoApi.getCurrentFrameTime()
            : ANNOTATION_TIMESTAMP_DISABLED_VALUE

        trackButtonClick({
            label: 'Save Annotation',
            ...getAnnotationMetadata(newAnnotation),
        })
        const promise = QAAnnotation.saveAnnotation(id, newAnnotation)
            .then((res) => {
                newAnnotation.annotationID = res.annotationTree[0].annotations[0].annotationID

                $updateReviewData(res)
            })
            .then(vm.cancelNewAnnotation)
            .then(() => {
                const mutationObserver = DOMUtility.waitForAnnotationMountAndScrollTo(
                    `.annotation-${newAnnotation.annotationID}`
                )

                $scope.$on('$destroy', () => {
                    mutationObserver.disconnect()
                })
            })

        return promise
    }

    function shortcutOnSaveNewAnnotation(event: KeyboardEvent) {
        // only save if there is an annotation on the controller
        if (vm.newAnnotation) {
            DataEntryNetworkGuard.execute(() => vm.saveNewAnnotation())
        }

        event.preventDefault()
        return false
    }
    /**
     * Cancel adding annotation
     */
    function cancelNewAnnotation() {
        resetNewAnnotation()
        clearForm()
    }

    function resetNewAnnotation() {
        vm.newAnnotation = null
    }

    function clearForm() {
        if (vm.generalForm) {
            vm.generalForm.$setPristine()
        }
    }

    function shortcutOnCancel(event: KeyboardEvent) {
        vm.cancelNewAnnotation()

        event.preventDefault()
        return false
    }

    /**
     * Open editing control for existing annotation
     */
    function editAnnotation(annotation: QAAnnotation) {
        annotation.editingCopy = QAAnnotation.createAnnotationEditCopy(annotation)
    }

    function duplicateAtCurrentTimestamp(annotation: QAAnnotation) {
        vm.newAnnotation = QAAnnotation.duplicateAnnotation(
            annotation,
            vm.videoApi.getCurrentFrameTime()
        )
        vm.generalForm?.$setDirty()
    }

    /**
     * Mark the existing annotation as rejected, and save its editing copy as a new annotation
     */
    function updateAnnotation(annotation: QAAnnotation) {
        const newAnnotation = annotation.editingCopy

        if (!AnnotationValidation.isValid(newAnnotation, vm.questions)) {
            return false
        }

        if (
            _.get(annotation, 'extra_data.automation_id', false) !==
            _.get(newAnnotation, 'extra_data.automation_id', false)
        ) {
            newRelic.noticeError('Automation IDs does not match for editing copy of annotation', {
                annotation,
                newAnnotation,
            })
        }

        const updateAnnotation = QAAnnotation.rejectAnnotation(id, annotation)
            // write the rejected annotation to the review data
            .then($updateReviewData)
            // and then save the new one
            .then(function () {
                return QAAnnotation.saveAnnotation(id, newAnnotation)
            })
            .then($updateReviewData)

        Notification.forPromise(updateAnnotation, 'Successfully updated annotation')
    }

    function updateGroupOfAnnotations(
        annotation: QAAnnotation,
        annotationGroup: { annotations: QAAnnotation[] }
    ): ng.IPromise<any> {
        const newAnnotation = annotation.editingCopy

        if (!AnnotationValidation.isValid(newAnnotation, vm.questions)) {
            return $q.resolve(false)
        }

        return QAAnnotation.toggleAnnotationStatus(id, -1, annotationGroup)
            .then(() => {
                return QAAnnotation.saveAnnotation(id, newAnnotation)
            })
            .then($updateReviewData)
    }

    function approve() {
        const confirmDialog = MapDialog.confirm()
            .title('Approve')
            .htmlContent(
                `
                <strong>Are you sure you want to proceed?</strong>
                <p>
                    When you approve your changes will be stored and you won't be able to edit them anymore.
                </p>
            `
            )
            .ok('Yes')
            .cancel('No')

        MapDialog.show(confirmDialog)
            .then(function () {
                const approvePromise = QAAnnotationTree.approve(id).catch(function (res) {
                    res.data = $applyAnnotationTreeErrors(res.data)

                    $scope.$applyAsync(scrollToFirstError)

                    return $q.reject(res)
                })

                trackButtonClick({
                    label: 'Approve Task',
                })

                Notification.forPromise(approvePromise)

                return approvePromise
            })
            .then(function () {
                $state.go('qa.list')
            })
    }

    function $applyAnnotationTreeErrors(errorData: any) {
        vm.annotationTree = _.map(vm.annotationTree, (group, groupKey) => {
            const groupErrors = _.get(errorData.errors, `annotationTree.${groupKey}`, [])
            const groupErrorsString = ErrorStringifier.stringify(groupErrors)

            return angular.extend(group, {
                errors: groupErrors,
                errorsString: groupErrorsString,
                annotations: _.map(group.annotations, (annotation, annotationKey) => {
                    const annotationErrors = _.get(
                        errorData.errors,
                        `annotationTree.${groupKey}.annotations.${annotationKey}`,
                        []
                    )
                    const annotationErrorsString = ErrorStringifier.stringify(annotationErrors)

                    return angular.extend(annotation, {
                        groupErrors,
                        groupErrorsString,
                        errors: annotationErrors,
                        errorsString: annotationErrorsString,
                        hasErrors: !!groupErrors.length || !!annotationErrors.length,
                    })
                }),
            })
        })

        $updateMetadata()

        // we don't want to now keep all the group.1 => [must have at least one...] errors
        // but instead convert them to one error to be displayed to the user.
        let hasUnreviewedAnnotations = false
        errorData.errors = _.reject(errorData.errors, (errors, key) => {
            const isUnreviewedAnnotationGroup =
                /^annotationTree.\d+$/.test('' + key) &&
                angular.equals(errors, [
                    'Must have at least one annotation approved or all rejected!',
                ])
            hasUnreviewedAnnotations = hasUnreviewedAnnotations || isUnreviewedAnnotationGroup

            return isUnreviewedAnnotationGroup
        })
        if (hasUnreviewedAnnotations) {
            errorData.message = 'You have unreviewed annotations.'
        }

        return errorData
    }

    function getAnnotationTitle(annotation: QAAnnotation): string | undefined {
        if (annotation.errorsString) {
            return annotation.errorsString
        }

        if (annotation.groupErrorsString) {
            return annotation.groupErrorsString
        }

        if (QAAnnotation.isPristine(annotation) && QAAnnotation.isMature(annotation)) {
            return 'High confidence annotation.'
        }

        return ''
    }

    function scrollToFirstError() {
        $scope.$applyAsync(() => {
            const errorAnnotation = $('.annotationHasErrors').first()
            if (errorAnnotation.length) {
                DOMUtility.scrollTo(errorAnnotation, {
                    highlight: true,
                    executeOnNextFrame: true,
                })
            }
        })
    }

    function findGroupsForSegment(segment: QASegment) {
        const groupsId = _.uniq(_.map(segment.annotations, (annotation) => annotation.groupID))

        return _.map(groupsId, (id) => {
            return _.find(vm.annotationTree, (group) => group.groupID === id)
        })
    }

    function filterMarkersBySearch() {
        vm.markers = filterMarkers(vm.allMarkers, vm.searchString)
    }

    function filterAnnotationsBySearch(annotation: BaseAnnotation) {
        return filterAnnotations(annotation, vm.searchString)
    }

    function filterSegmentsBySearch(segment: GroupedSegment) {
        return filterSegments(segment, vm.searchString)
    }

    function findGroupForAnnotation(annotation: QAAnnotation) {
        return _.find(vm.annotationTree, (group) => group.groupID === annotation.groupID)
    }

    function reject() {
        const dialogInstance = $uibModal.open({
            templateUrl: 'js/qa/review.rejectAllModal.tpl.html',
            controllerAs: 'modalVm',
            controller: /* @ngInject */ function (this: Record<string, any>) {
                const modalVm = this
                modalVm.message = ''
            },
        })

        dialogInstance.result
            .then(function (result) {
                const workers = fp.flow(
                    fp.map('username'),
                    fp.pull(User.cached().username)
                )(vm.workers) as string[]

                const rejectPromise = QAAnnotationTree.reject(id, workers, result.message)

                Notification.forPromise(rejectPromise)

                return rejectPromise
            })
            .then(function () {
                $state.go('qa.list')
            })
    }

    function confirmReturnTask() {
        const modalConfig = MapDialog.confirm()
            .title('Abandon Task')
            .textContent(
                'If you abandon this task any QA edits will be erased and the task will return to the Available Tasks list.'
            )
            .ok('Abandon')
            .cancel('Cancel')

        MapDialog.show(modalConfig).then(() => {
            trackButtonClick({
                label: 'Abandon QA Task',
                value: [
                    {
                        metadata: {
                            hitId: id,
                        },
                    },
                ],
            })

            returnAssignment()
        })

        ////////////////////////////////////////////////

        function returnAssignment() {
            QAAssignmentService.returnFromReview({ id, class: reviewData.review_class }).then(() =>
                $state.go(QA_ROUTES.QA_LIST.STATE_NAME)
            )
        }
    }

    function shortcutOnApprove(event: KeyboardEvent) {
        vm.approve()

        event.preventDefault()
        return false
    }

    function shortcutOnReject(event: KeyboardEvent) {
        vm.reject()

        event.preventDefault()
        return false
    }

    function returnToWorkers() {
        QAReturnToWorkers.return(id, vm.workers)
    }

    function shortcutOnReturnToWorkers(event: KeyboardEvent) {
        vm.returnToWorkers()

        event.preventDefault()
        return false
    }

    function getAnnotationMetadata(annotation: QAAnnotation | NewAnnotation) {
        const metadata: Record<string, any> = {}

        if ('annotationID' in annotation) {
            metadata.annotationId = annotation.annotationID
        }
        if (annotation.groupID) {
            metadata.groupId = annotation.groupID
        }
        if (annotation.timestamp) {
            metadata.timestamp = annotation.timestamp
        }
        if ('annotationType' in annotation) {
            metadata.annotationType = annotation.annotationType
        }

        return {
            value: [
                {
                    metadata,
                },
            ],
        }
    }
}

QAReviewQuestionsCtrl.resolve = {
    id: /* @ngInject */ ($stateParams: ng.ui.IStateParamsService) => {
        return $stateParams.id
    },
    videoData: /* @ngInject */ (VideoService: any, id: string) => {
        return VideoService.get(id)
    },
    reviewData: /* @ngInject */ (QAAnnotationTree: QAAnnotationTreeInstance, id: string) => {
        // return import('../../sample/qaReviewWithExtraData.json').then((m) => m.default)
        return QAAnnotationTree.getReviewData(id)
    },
    commentThreads: /* @ngInject */ (CommentService: any, id: string) => {
        return CommentService.getThreads(id) || [] // eslint-disable-line no-mixed-operators
    },
}
