import _ from 'lodash'

export default function simpleTagsDirective() {
    var directive = {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            parentModel: '=ngModel',

            ngDisabled: '=?',
            placeholder: '@',
        },
        template: `
            <tags-input
                ng-model="tags"
                ng-attr-text="text"
                ng-disabled="ngDisabled"
                placeholder="{{ placeholder }}"
                replace-spaces-with-dashes="false"
            ></tags-input>
        `,
        link: simpleTagsLinkFn,
    }

    return directive

    ////////////////////

    function simpleTagsLinkFn(scope) {
        scope.$watchCollection('parentModel', function (tagsArray) {
            scope.tags = _.map(tagsArray, function (tag) {
                return { text: tag }
            })
        })

        scope.$watchCollection('tags', function (tags) {
            if (!scope.ngDisabled) {
                tags = _.map(tags, 'text')
                scope.parentModel = tags
            }
        })
    }
}
