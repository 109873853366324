import _ from 'lodash'
import fp from 'lodash/fp'
import invariant from 'util/invariant'
import passthroughError from 'util/passthroughError'
import WorkflowWizardCtrl from 'admin/workflow-wizard/WorkflowWizard.ctrl'
import GraphObject from './GraphObject'

/**
 * GraphData deals with reading/manipulation of graph data that is dependent on
 * the actual node data and edge connections. Unlike GraphObject, this class can
 * have side effects and/or directly manipulate data stored in node.info
 */
export default /* @ngInject */ function GraphDataFactory(
    TASK_DEFINITIONS,
    $q,
    $uibModal,
    MapDialog
) {
    const GraphData = {
        /**
         * Open WorkflowWizard for editing of the given node's data
         *
         * @param {GraphObject.graph} graph
         * @param {Object} workflow
         * @param {GraphObject.node} node
         * @param {boolean} isEditable
         *
         * @return {Promise<WorkflowWizardModal>}
         */
        openNodeConfig(graph, workflow, node, isEditable) {
            const taskDefinition = GraphData.getTaskDefinition(graph, node)

            // Some nodes may not have templates
            if (!taskDefinition.templatePath) {
                return
            }

            const taskDefaultConfig = _.merge(
                angular.copy(node.info.defaultConfig),
                angular.copy(taskDefinition.defaults)
            )

            let taskConfig
            const isDefaultConfig = angular.equals(node.info.config, node.info.defaultConfig)
            if (isDefaultConfig) {
                taskConfig = angular.copy(taskDefaultConfig)
            } else {
                taskConfig = angular.copy(node.info.config)
            }

            const $uibModalInstance = $uibModal.open({
                size: _.get(taskDefinition, 'size', 'lg'),
                templateUrl: 'js/admin/workflow-wizard/templates/modal.tpl.html',
                resolve: {
                    node,
                    graph,
                    taskDefinition,
                    taskConfig,
                    workflow,
                    isEditable,
                    taskDefaultConfig: taskDefaultConfig,
                    taskTemplateUrl: _.constant(taskDefinition.templatePath),
                    autoSubmit: taskDefinition.autoSubmit || true,
                    wizards: node.info.wizards,
                },
                controllerAs: WorkflowWizardCtrl.controllerAs,
                controller: WorkflowWizardCtrl,
            })

            return $uibModalInstance.result
        },

        /**
         * Get the TASK_DEFINITION instance for a node inside a graph
         *
         * @param {GraphObject.graph} graph
         * @param {GraphObject.node} node
         *
         * @return {Object<TASK_DEFINITION>}
         */
        getTaskDefinition(graph, node) {
            const DEFAULT_DEFINITION = {
                taskTemplateUrl: 'js/admin/jobCreateGraph.nodeConfigModal.tpl.html',
            }

            const task = _.get(node, 'info')
            invariant(task, 'Node does not have valid task info: %s', angular.toJson(node))

            let definition = TASK_DEFINITIONS[_.get(task, 'wizards.template')]
            if (_.isArray(definition)) {
                definition = selectFromMultiDefinition(graph, node, definition)
            }

            return definition || DEFAULT_DEFINITION

            ///////////////////////////////////////////////

            /**
             * For multi-definition tasks, select the right definition based
             * on the graph connections
             * @param {GraphObject.graph} graph
             * @param {GraphObject.node} node
             * @param {Array<TASK_DEFINITION>} definitionList
             *
             * @return {TASK_DEFINITION|false}
             */
            function selectFromMultiDefinition(graph, node, definitionList) {
                const definition = _.find(definitionList, matches)
                const nullDefinition = _.find(definitionList, { definitionMatch: false }) || false

                return definition || nullDefinition

                ////////////////////

                /**
                 * Check if a particular definition is a match for our node
                 * @return {boolean}
                 */
                function matches(definition) {
                    const definitionMatch = definition.definitionMatch
                    invariant(
                        angular.isDefined(definitionMatch),
                        'Multi-definitions must have a `definitionMatch` property'
                    )

                    // Definition match can match on inputs and outputs, but if not defined
                    // we consider them a match.
                    return _.every(['input', 'output'], (ioType) => {
                        // if not defined, we match
                        if (!definitionMatch[ioType]) {
                            return true
                        }

                        // otherwise execute all definition match checks for input or output
                        return _.every(_.keys(definitionMatch[ioType]), (io) => {
                            const typeList = definitionMatch[ioType][io]
                            const connectedNodes = GraphObject.nodeFindConnected(
                                graph,
                                node,
                                ioType,
                                io
                            )
                            if (connectedNodes.length === 0) {
                                return false
                            }

                            const nodeTypes = _.map(connectedNodes, 'info.wizards.template')
                            // We compare the definition match typeList against the types of nodes
                            // that we are connected to. If the nodes we are connected to are not
                            // all in the allowed definition type list, then we do not match the
                            // definition.
                            const difference = _.difference(nodeTypes, typeList)
                            return difference.length === 0
                        })
                    })
                }
            }
        },

        /**
         * Check if a node can be removed.
         *
         * Because one node might depend on it's connections to others for its data,
         * removing a node or edge requires user confirmation when that data will be
         * affected. This method verifies if
         *
         *
         * @param {GraphObject} graph
         * @param {GraphObject.node} node
         *
         * @return {Promise<GraphObject>} fulfills with new GraphObject, or rejects empty
         */
        tryRemoveNode(graph, node) {
            const mandatoryNodes = GraphData.$getMandatoryNodesForEdges(
                graph,
                GraphObject.edgeFindAllForNode(graph, node)
            )
            if (_.size(mandatoryNodes)) {
                const confirmDialog = MapDialog.confirm()
                    .title('Are you sure?')
                    .textContent(
                        `Are you sure you want to remove this node?
                        This will remove all data in
                        ${_.map(mandatoryNodes, 'info.title')
                            .map((title) => `"${title}"`)
                            .join(', ')}.
                    `
                    )
                    .ok('Yes')
                    .cancel('No')

                return MapDialog.show(confirmDialog)
                    .then(() => {
                        // ORDER IS IMPORTANT
                        graph = GraphData.resetNodeConfig(graph, mandatoryNodes)
                        graph = GraphObject.nodeRemove(graph, node)
                        return graph
                    })
                    .catch(passthroughError) // eslint-disable-line
            }

            graph = GraphObject.nodeRemove(graph, node)
            return $q.resolve(new GraphObject(graph))
        },

        /**
         * Check if an edge can be removed.
         *
         * Checks if the edge is mandatory, and requires user confirmation if it is
         *
         * @param {GraphObject} graph
         * @param {GraphObject.edge} edge
         *
         * @return {Promise<GraphObject>} fulfills on yes, rejects on no
         */
        tryRemoveEdge(graph, edge) {
            const mandatoryNodes = GraphData.$getMandatoryNodesForEdges(graph, [edge])
            if (_.size(mandatoryNodes)) {
                const confirmDialog = MapDialog.confirm()
                    .title('Are you sure?')
                    .textContent(
                        `Are you sure you want to remove
                        ${mandatoryNodes.length > 1 ? 'these connections' : 'this connection'}?
                        This will remove all data in
                        ${_.map(mandatoryNodes, 'info.title')
                            .map((title) => `"${title}"`)
                            .join(', ')}.
                    `
                    )
                    .ok('Yes')
                    .cancel('No')

                return MapDialog.show(confirmDialog)
                    .then(() => {
                        graph = GraphObject.edgeRemove(graph, edge)
                        return GraphData.resetNodeConfig(graph, mandatoryNodes)
                    })
                    .catch(passthroughError) // eslint-disable-line
            }

            graph = GraphObject.edgeRemove(graph, edge)
            return $q.resolve(new GraphObject(graph))
        },

        /**
         * Attach error/warning data from backend to all graph nodes
         * Modifies the graph in place
         *
         * @param {GraphObject.graph} graph
         * @param {Object<{errors:array, warnings:array}>} errorData
         */
        attachErrorData(graph, errorData) {
            _.forEach(_.get(graph, 'nodes', []), (node) => {
                node.errors = mapMessages(errorData.errors, node)
                node.warnings = mapMessages(errorData.warnings, node)
            })

            function mapMessages(messages, node) {
                return fp.flow(fp.filter({ id: node.id }), fp.map('msg'))(messages)
            }
        },

        /**
         * Clear error/warning data from all graph nodes
         * Modifies the graph in place
         *
         * @param {GraphObject.graph} graph
         * @param {boolean} validated Set graph nodes to the "validated" state
         */
        clearErrorData(graph, validated = false) {
            _.forEach(_.get(graph, 'nodes', []), (node) => {
                node.errors = validated ? [] : null
                node.warnings = validated ? [] : null
            })
        },

        /**
         * @param {GraphObject.graph} graph
         * @param {GraphObject.node|[GraphObject.node]} nodes or a single node
         *
         * @return {GraphObject}
         */
        resetNodeConfig(graph, nodes) {
            if (!_.isArray(nodes)) {
                nodes = [nodes]
            }

            _.forEach(nodes, (node) => {
                // reset node.info.config to node.info.configDefault
                graph = GraphObject.nodeReplace(graph, node, resetNode(node))
            })

            return new GraphObject(graph)

            //////////////////////////////

            function resetNode(node) {
                node = angular.copy(node)
                return angular.extend({}, node, {
                    info: angular.extend({}, _.get(node, 'info', {}), {
                        config: angular.extend({}, _.get(node, 'info.defaultConfig', {})),
                    }),
                })
            }
        },

        /**
         * @private
         * @param {GraphObject.graph}
         * @param {Array<GraphObject.edge>}
         *
         * @return {Array<GraphObject.edge>}
         */
        $getMandatoryNodesForEdges(graph, edges) {
            return _.reduce(
                edges,
                (results, edge) => {
                    const sourceNode = GraphObject.nodeFind(graph, edge.source.nodeId)
                    const targetNode = GraphObject.nodeFind(graph, edge.target.nodeId)

                    if (
                        hasChanges(sourceNode) &&
                        GraphData.$isMandatoryIO('output', sourceNode, edge.source.outId)
                    ) {
                        results.push(sourceNode)
                    }

                    if (
                        hasChanges(targetNode) &&
                        GraphData.$isMandatoryIO('input', targetNode, edge.target.inId)
                    ) {
                        results.push(targetNode)
                    }

                    return results

                    /////////////////////

                    function hasChanges(node) {
                        return !angular.equals(node.info.config, node.info.defaultConfig)
                    }
                },
                []
            )
        },

        /**
         * @private
         * @param {'input'|'output'}
         * @param {GraphObject.node}
         * @param {Number}
         *
         * @return {Boolean}
         */
        $isMandatoryIO(type, node, id) {
            let mandatory = []
            invariant(
                _.includes(['input', 'output'], type),
                '[GraphData] unknown io type "%s"',
                type
            )

            // NODE info part looks something like
            // {
            //     info: {
            //        inputs: [{label: 'task label', value: 'task'}],
            //        mandatoryInputs: [{label: 'task label', value: 'task'}],
            //
            //        outputs: [{label: 'video label', value: 'video'}, {label: 'task label', value: 'task'}],
            //        mandatoryOutputs: [{label: 'task label', value: 'task'}],
            //
            //        [...other info fields...]
            //     },
            //
            //     [...other node fields...]
            // }
            //
            // So we check if the label at the io type is in the list of mandatory ios for that type
            if (type === 'input') {
                mandatory = _.get(node, 'info.mandatoryInputs', [])
                if (_.includes(mandatory, _.get(node, ['info', 'inputs', id, 'value']))) {
                    return true
                }
            } else {
                mandatory = _.get(node, 'info.mandatoryOutputs', [])
                if (_.includes(mandatory, _.get(node, ['info', 'outputs', id, 'value']))) {
                    return true
                }
            }

            return false
        },
    }

    return GraphData
}
