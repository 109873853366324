import _ from 'lodash'
import invariant from 'tiny-invariant'

type ScopeBindings = {
    questions: Question[]
    annotation: BaseAnnotation
}

type Scope = ng.IScope & ScopeBindings

/**
 * Directive for answering task questions fast.
 *
 * @param {Array(Question)} questions Array of question definitions
 * @param {Annotation} annotation Assignable annotation
 */
export default /* @ngInject */ function answerQuestionsWithSuggestionsDirective() {
    const directive = {
        restrict: 'E',
        scope: {
            questions: '<',
            annotation: '=',
        },
        templateUrl: 'js/directives/answer-questions/answerQuestionsWithSuggestions.tpl.html',
        link: answerQuestionsWithSuggestionsLinkFn,
    }

    return directive

    /////////////////////////////////////

    function answerQuestionsWithSuggestionsLinkFn(
        scope: Scope & {
            toggleAnswer: typeof toggleAnswer
            optionIsSelected: typeof optionIsSelected
            findAnswerForQuestion: typeof findAnswerForQuestion
        }
    ) {
        scope.toggleAnswer = toggleAnswer
        scope.optionIsSelected = optionIsSelected
        scope.findAnswerForQuestion = findAnswerForQuestion

        activate()

        ////////////////////////////////////////////////////

        function activate() {
            scope.$watch('annotation', ensureValidAnnotation)
            // We need to execute this immediately once, because relying on $watch
            // first execution is too slow for dependant directives like questionInput.
            // We need the annotation to be valid for their link function, and that executes
            // before the first $watch() execution
            ensureValidAnnotation()

            function ensureValidAnnotation() {
                if (!scope.annotation) {
                    scope.annotation = {
                        answers: buildAnswersFromQuestions(scope.questions),
                        timestamp: 0,
                    }
                }
                if (_.isArray(scope.annotation.answers) && !scope.annotation.answers.length) {
                    scope.annotation.answers = buildAnswersFromQuestions(scope.questions)
                }
            }
        }

        function optionIsSelected(questionValue: string, question: Question): boolean {
            const answer = findAnswerForQuestion(_.get(scope.annotation, 'answers', []), question)

            if (!answer) {
                return false
            }

            if (_.isArray(answer.value)) {
                return _.map(answer.value, (answerValue) => answerValue.value).includes(
                    questionValue
                )
            } else {
                return questionValue === _.get(answer, 'value.value')
            }
        }

        function toggleAnswer(answer: AnnotationAnswerAnswer, question: Question) {
            const { value, label } = answer

            const annotationAnswer = _.find(scope.annotation.answers, { id: question.uri })

            invariant(annotationAnswer, 'Annotation answer must exist')

            if (_.isArray(annotationAnswer.value)) {
                const index = _.findIndex(
                    annotationAnswer.value,
                    (annotationAnswerValue) => annotationAnswerValue.value === value
                )

                if (index === -1) {
                    annotationAnswer.value.push({ value, label })
                } else {
                    annotationAnswer.value.splice(index, 1)
                }
            } else {
                if (annotationAnswer.value?.value === value) {
                    annotationAnswer.value = null
                    return
                }

                annotationAnswer.value = { value, label }
            }
        }

        function buildAnswersFromQuestions(questions: Question[]) {
            return _.map(questions, function (question) {
                const answer = {
                    value: null,
                    id: question.uri,
                    detailsRows: [],
                }

                return answer
            })
        }
    }

    function findAnswerForQuestion(answers: AnnotationAnswer[], question: Question) {
        return _.find(answers, { id: question.uri })
    }
}
