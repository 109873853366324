import { toJson } from 'angular'
import { DigestInstance } from '../services/Digest.factory'
import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'

export default /* @ngInject */ function TaskServiceFactory(
    NO_LOAD_OVERLAY: NO_LOAD_OVERLAY,
    $q: ng.IQService,
    $http: ng.IHttpService,
    Digest: DigestInstance,
    Notification: any,
    ErrorStringifier: ErrorStringifierInstance
) {
    const TaskService = {
        getTask: function (assignmentID: string) {
            return $http
                .get(`/api/assignment/${assignmentID}`)
                .then((res) => res.data)
                .catch((error) => {
                    const errorMessage = ErrorStringifier.stringify(error)
                    Notification.error(`Something went wrong ${errorMessage}`)

                    return $q.reject(error)
                })
        },

        setBookmarks: function (assignmentID: string, markersArray: Array<unknown>) {
            return $http.post(
                `/api/assignment/${assignmentID}/bookmarks`,
                { bookmarks: markersArray },
                NO_LOAD_OVERLAY
            )
        },

        setAnnotations: function (assignmentID: string, annotations: Array<unknown>) {
            const tags = toJson(annotations)

            return $http.post(
                `/api/assignment/${assignmentID}/annotations`,
                {
                    tags: tags,
                    checksum: Digest.crc32(tags),
                },
                NO_LOAD_OVERLAY
            )
        },

        setHighlights: function (assignmentID: string, highlights: Array<unknown>) {
            return $http.put(
                `/api/assignment/${assignmentID}/highlights`,
                highlights,
                NO_LOAD_OVERLAY
            )
        },

        submitHighlights: function (assignmentID: string, highlights: Array<unknown>) {
            return TaskService.setHighlights(assignmentID, highlights).then(function () {
                return $http.post(`/api/assignment/${assignmentID}/submit`, {})
            })
        },

        submitAnnotations: function (assignmentID: string, annotations: Array<unknown>) {
            const tags = toJson(annotations)

            return $http.post(`/api/assignment/${assignmentID}/submit`, {
                tags: tags,
                checksum: Digest.crc32(tags),
            })
        },

        submitTask: function (assignmentID: string) {
            return $http.post(`/api/assignment/${assignmentID}/submit`, {})
        },

        saveScenes: function (
            assignmentID: string,
            sceneData: Array<unknown>,
            rawSceneData = null,
            lastUserAction: string
        ) {
            return $http.put(
                `/api/assignment/${assignmentID}/scenes`,
                {
                    ...sceneData,
                    rawSceneData: toJson(rawSceneData),
                    lastUserAction,
                },
                NO_LOAD_OVERLAY
            )
        },

        saveScenesAndSubmit: function (assignmentID: string, sceneData: Array<unknown>) {
            return $http.post(`/api/assignment/${assignmentID}/submit`, sceneData)
        },

        saveAuthorities: function (assignmentID: string, authorityData: Array<unknown>) {
            const authorities = toJson(authorityData)

            return $http.post(`/api/assignment/${assignmentID}/authorities`, {
                authorities,
            })
        },

        submitAuthorities: function (assignmentID: string, authorityData: Array<unknown>) {
            const authorities = toJson(authorityData)

            return $http.post(`/api/assignment/${assignmentID}/submit`, { authorities })
        },

        getImage: function <T extends ShapeTaggingData | ImageTaggingData>(
            assignmentID: string,
            index: number
        ) {
            return $http
                .get<T>(`/api/assignment/${assignmentID}/`, {
                    params: { index },
                })
                .then((res) => res.data)
        },

        getNextUndecided: function <T extends ShapeTaggingData | ImageTaggingData>(
            assignmentID: string,
            index: number
        ) {
            return $http.get<T>(`/api/assignment/${assignmentID}/nextUndecided`, {
                params: { index },
            })
        },

        toggleSkip: function (assignmentID: string, image_id: number) {
            return $http.post(
                `/api/assignment/${assignmentID}/toggleSkip`,
                {
                    image_id,
                },
                NO_LOAD_OVERLAY
            )
        },

        updateShapeAnnotation: function (
            assignmentID: string,
            image_id: number,
            annotations: unknown[]
        ) {
            return $http.post<ShapeTaggingData>(
                `/api/assignment/${assignmentID}/shapeTagging`,
                {
                    image_id,
                    tagging_data: annotations,
                },
                NO_LOAD_OVERLAY
            )
        },

        updateImageAnnotation: function (
            assignmentID: string,
            image_id: number,
            data: {
                annotations: unknown[]
                free_text_labels: string[]
            }
        ) {
            return $http.post<ImageTaggingData>(
                `/api/assignment/${assignmentID}/imageTagging`,
                {
                    image_id,
                    ...data,
                },
                NO_LOAD_OVERLAY
            )
        },

        updateMultiImageAnswer: function (assignmentID: string, images: any[]) {
            return $http.post<ImageTaggingData>(
                `/api/assignment/${assignmentID}/multipleImageTagging`,
                {
                    images,
                },
                NO_LOAD_OVERLAY
            )
        },
        getGroupData: function <MultipleImageTaggingData>(assignmentID: string, group: number) {
            return $http
                .get<MultipleImageTaggingData>(`/api/assignment/${assignmentID}/`, {
                    params: { group },
                })
                .then((res) => res.data)
        },

        getUndecidedGroupData: function <MultipleImageTaggingData>(
            assignmentID: string,
            index: number
        ) {
            return $http.get<MultipleImageTaggingData>(
                `/api/assignment/${assignmentID}/nextUndecided`,
                {
                    params: { index },
                }
            )
        },
    }

    return TaskService
}

export type TaskServiceInstance = ReturnType<typeof TaskServiceFactory>
