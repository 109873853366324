import _ from 'lodash'

export default /* @ngInject */ function isUnsupportedDirective(TASKS_SUPPORTED_CLASSES) {
    var directive = {
        restrict: 'E',
        scope: {
            task: '=',
        },
        template: ['<span ng-if="isUnsupported" style="color: red;">[ unsupported ]</span>'].join(
            '\n'
        ),
        link: isUnsupportedLinkFn,
    }

    return directive

    ////////////////////////////

    function isUnsupportedLinkFn(scope) {
        scope.isUnsupported = _.includes(TASKS_SUPPORTED_CLASSES, scope.task['class'])
    }
}
