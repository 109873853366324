export default /* @ngInject */ function DigestFactory() {
    const CRC_TABLE = makeCRCTable()

    const Digest = {
        crc32: function (str: string) {
            str = unescape(encodeURIComponent(str))
            let crc = 0 ^ -1

            for (let i = 0; i < str.length; i++) {
                crc = (crc >>> 8) ^ CRC_TABLE[(crc ^ str.charCodeAt(i)) & 0xff]
            }

            return (crc ^ -1) >>> 0
        },
    }

    return Digest

    ///////////////////

    function makeCRCTable() {
        let c
        const crcTable = []

        for (let n = 0; n < 256; n++) {
            c = n
            for (let k = 0; k < 8; k++) {
                c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1
            }
            crcTable[n] = c
        }

        return crcTable
    }
}

export type DigestInstance = ReturnType<typeof DigestFactory>
