import FastdomWrapper from 'services/FastdomWrapper'

/**
 * @ngdoc directive
 * @name scrollableContainerDirective
 * @module map3.core
 * @restrict E
 *
 * @description
 * Scrollable container
 *
 * Listens for angular `resize` events on scope, or DOM resize on window,
 * and automatically sets the appropriate height for the container
 */
export default /* @ngInject */ function scrollableContainerDirective($rootScope, SharedVideoAPI) {
    const directive = {
        restrict: 'EA',
        link: scrollableLinkFn,
    }

    return directive

    ///////////////////////

    function scrollableLinkFn(scope, element) {
        const fastdom = new FastdomWrapper()
        const $$window = angular.element(window)

        element.addClass('scroller')

        scope.$on('resize', onResize)
        $$window.on('resize', onResize)
        SharedVideoAPI.onLoad(onResize)

        onInit()

        scope.$on('$destroy', function () {
            $$window.off('resize', onResize)
            fastdom.destroy()
            $rootScope.FOOTER_HIDDEN = false
        })

        /////////////////////////

        function onInit() {
            // Sometimes a scroll animation might still be executing when we load into a scrollable container.
            // In that case, we need to manually reset the window scroll to 0 after a small delay, so that
            // we don't end up scrolled a bit down with scroll disabled.
            setTimeout(() => {
                const windowScrollTop = $$window.scrollTop()
                if (windowScrollTop !== 0) {
                    $$window.scrollTop(0)
                }
            }, 100)

            $rootScope.FOOTER_HIDDEN = true
        }

        function onResize() {
            fastdom.measure(() => {
                const height = $$window.height()
                const maxHeight = height - element.offset().top

                fastdom.mutate(() => {
                    element.css('height', maxHeight + 'px')
                })
            })
        }
    }
}
