import _ from 'lodash'
import angular from 'angular'

import fastdom from 'fastdom'
import softInvariant from '../util/softInvariant'

const debug = require('debug')('map3:DOMUtility')

/**
 *  {
 *      offset: -100, // scroll offset, so that there is a bit of extra space on top before element
 *      container: 'html, body', container to scroll
 *      duration: 500, // animation duration in ms
 *      relative: false, // if target position should be calculated globally, or relative to parent
 *      highlight: false, // if the scrolled to element should be highlighted. Bool or animation class
 *  }
 */
type scrollToLegacyOpts = {
    offset: number
    container: string
    duration: number
    relative: boolean
    highlight: boolean
}

let globalUid = 0

export default /* @ngInject */ function DOMUtilityFactory() {
    const DOM_ANIMATION_CACHE_DATA_KEY = '$$map3DomUtilityAnimationCache'

    const DOMUtility = {
        scrollHeight: 17, // default in most browsers
        scrollWidth: 17, // default in most browsers

        /**
         * Throttle the given function to be executed at most once per animation frame
         */
        rafThrottle: function <T extends (...args: any[]) => any>(
            callback: T,
            context: any = undefined
        ): T & { cancel: () => void } {
            let requestId: null | number

            function rafThrottled(this: unknown) {
                if (!requestId) {
                    requestId = window.requestAnimationFrame(
                        // eslint-disable-next-line
                        createExecuteCallback(context || this, arguments as any)
                    )
                }
            }

            rafThrottled.cancel = function () {
                return window.cancelAnimationFrame(requestId!)
            }

            function createExecuteCallback(context: any, args: any[]) {
                return function () {
                    requestId = null
                    callback.apply(context, args)
                }
            }

            return rafThrottled as any
        },

        /**
         * Debounce the given function, to be executed at most once per animation frame
         * This means that the last call in the animation frame will be executed, as
         * opposed to {DOMUtility.rafThrottle()} where the first call is executed.
         *
         * @param {Function} callback
         * @param {Object=} context
         * @return {Function}
         */
        rafDebounce: function <T extends (...args: any[]) => any>(
            callback: T,
            context = undefined
        ): T & { cancel: () => void } {
            let requestId: undefined | number | null
            let lastArgs: undefined | any[]
            let lastThis: undefined | any

            function executeCallback() {
                const args = lastArgs
                const thisArg = lastThis

                lastArgs = lastThis = requestId = undefined

                callback.apply(thisArg, args!)
            }

            function rafDebounced(this: unknown) {
                lastThis = context || this
                // eslint-disable-next-line
                lastArgs = arguments as any

                if (!requestId) {
                    requestId = window.requestAnimationFrame(executeCallback)
                }
            }

            rafDebounced.cancel = function cancel() {
                return window.cancelAnimationFrame(requestId!)
            }

            return rafDebounced as any
        },

        /**
         * Given an element, get or create animation cache for it.
         *
         * Used to avoid forced layout reflows
         *
         */
        getOrCreateAnimationCache: function (element: HTMLElement | JQuery<any>) {
            const $element = $(element)
            let animationCache = $element.data(DOM_ANIMATION_CACHE_DATA_KEY)

            if (!animationCache) {
                animationCache = DOMUtility.getAnimationData($element)
                $element.data(DOM_ANIMATION_CACHE_DATA_KEY, animationCache)
            }

            return animationCache
        },

        /**
         * Get animation data for an element
         *
         * @return {top: number, left: number, right: number, width: number}
         */
        getAnimationData: function (element: HTMLElement | JQuery<any>) {
            const $element = $(element)

            const position = $element.position()
            const width: number = $element.width()!
            const outerWidth: number = $element.outerWidth()!

            return {
                top: position.top,
                left: position.left,
                right: position.left + width,
                width: width,
                outerWidth: outerWidth,
                borderLeft: parseFloat($element.css('border-left-width')),
                borderRight: parseFloat($element.css('border-right-width')),
            }
        },

        /**
         * Remove the animation cache from an element
         */
        removeAnimationCache: function (target: HTMLElement | HTMLElement[] | JQuery<any>) {
            $(target).each(function () {
                $(this).removeData(DOM_ANIMATION_CACHE_DATA_KEY)
            })
        },

        /**
         * Add custom styles to the page
         *
         * Will build and insert a <style> element to <head> and return a
         * control interface with the following functions:
         *  - remove() - Remove the <style> element from <head>
         *  - update(css:string) - Update the <style> contents
         *
         * @param {string} css
         * @return {remove: function, update: function} style control interface
         */
        addCustomStyles: function (css = '') {
            let styleEl = $('<style/>')
            let fastdomTask: any

            fastdom.mutate(() => {
                styleEl = styleEl.appendTo('head').text(css)
            })

            return {
                remove: function () {
                    styleEl.remove()
                },
                update: function (css: string) {
                    if (!styleEl.length) {
                        throw new Error('Cannot update styles. Stlye element already removed.')
                    }

                    fastdom.clear(fastdomTask)
                    fastdomTask = fastdom.mutate(() => {
                        styleEl.text(css)
                    })
                },
            }
        },

        /**
         * Trigger a CSS animation on a target
         *
         * We're using a trick where removing an animation class,
         * triggering reflow and re-adding the class back will reliably
         * re-execute the animation
         */
        triggerAnimation: function (
            target: string | HTMLElement | HTMLElement[] | JQuery<any>,
            animationClass: string
        ) {
            $(target).each(function (this: HTMLElement) {
                // if we have triggered this animation class before, we need to
                // remove, reflow, and only after that add again so that it
                // triggers anew
                if (this.classList.contains(animationClass)) {
                    this.classList.remove(animationClass)

                    // offsetWidth is one of the DOMElement properties that forces reflow:
                    // https://gist.github.com/paulirish/5d52fb081b3570c81e3a
                    // What we do here is access it without doing anything with the value;
                    // We can't say "this.offsetWidth;' because this is not an expression and
                    // will be omitted by the browser, so we say "void this.offsetWidth" -
                    // this is an actual expression that will access the value, but won't change it.
                    // eslint-disable-next-line
                    void this.offsetWidth
                }

                this.classList.add(animationClass)
            })
        },

        /**
         * Scroll to a particular element using jQuery
         *
         * @param {string|DOMNode|jQuery} element
         * @param {object} opts {
         */
        scrollToLegacy: function (
            element: string | HTMLElement | JQuery<any>,
            opts: Partial<scrollToLegacyOpts> = {}
        ) {
            const _opts: scrollToLegacyOpts = _.defaults(opts, {
                offset: -100,
                container: 'html, body',
                duration: 500,
                relative: false,
                highlight: false,
            })

            const $element = $(element)
            softInvariant(
                !!$element.length,
                'Cannot find given element to execute DOMUtility.scrollTo(): %s',
                angular.toJson(element)
            )
            if (!$element.length) {
                return
            }
            const elemBounds = opts.relative ? $element.position() : $element.offset()!

            $(_opts.container).animate(
                {
                    scrollTop: elemBounds.top + _opts.offset,
                },
                _opts.duration
            )

            if (opts.highlight) {
                DOMUtility.triggerAnimation(
                    element,
                    opts.highlight !== true ? opts.highlight : 'trigger-highlight'
                )
            }
        },

        /**
         * Scroll to a prticular element using DOMNode.scrollIntoView()
         *
         * @param {string|DOMNode|jQuery} element
         * @param {object} opts {
         *      scrollIntoViewOpts: {
         *          behavior: 'smooth', // scroll behaviowr
         *      },
         *      highlight: false, // if the scrolled to element should be highlighted. Bool or animation class
         *      executeOnNextFrame: false, // should execute on next render frame
         *
         * @return function to cancelAnimationFrame or noop
         */
        scrollTo(
            element: string | HTMLElement | JQuery<HTMLElement>,
            opts: {
                scrollIntoViewOpts?: ScrollIntoViewOptions
                highlight?: boolean
                executeOnNextFrame?: boolean
            } = {}
        ) {
            opts = _.defaults(opts, {
                scrollIntoViewOpts: { behavior: 'smooth' },
                highlight: false,
                executeOnNextFrame: false,
            })

            if (opts.executeOnNextFrame) {
                const id = window.requestAnimationFrame(executeScrollTo)
                return () => {
                    window.cancelAnimationFrame(id)
                }
            } else {
                executeScrollTo()
                return () => {
                    /* do nothing */
                }
            }

            function executeScrollTo() {
                const $element = $(element)
                softInvariant(
                    !!$element.length,
                    'Cannot find given element to execute DOMUtility.scrollTo(): %s',
                    angular.toJson(element)
                )
                if (!$element.length) {
                    return
                }

                $element.get(0)?.scrollIntoView(opts.scrollIntoViewOpts)

                if (opts.highlight) {
                    DOMUtility.triggerAnimation(
                        element,
                        opts.highlight !== true ? opts.highlight : 'trigger-highlight'
                    )
                }
            }
        },

        waitForAnnotationMountAndScrollTo(target: string | HTMLElement | JQuery<HTMLElement>) {
            const $element = $('.js-annotations-list')

            const mutationObserver = new MutationObserver(callback)
            mutationObserver.observe($element[0], {
                childList: true,
                subtree: true,
            })

            return mutationObserver

            /////////////////////////////

            function callback() {
                setTimeout(() => {
                    DOMUtility.scrollTo(target, { highlight: true })
                }, 10)
                mutationObserver.disconnect()
            }
        },

        /**
         * Provide a global unique identifier to be used with DOM elements.
         * The implementation is monotonically incrementing integer.
         *
         * @return {number}
         */
        nextUid: function () {
            return ++globalUid
        },

        calculateScrolls: $calculateScrolls,
    }

    $calculateScrolls()

    return DOMUtility

    //////////////////////////

    /**
     * Dynamically calculate the actuall scroll width/height in the current browser
     */
    function $calculateScrolls() {
        try {
            // based on https://github.com/google/closure-library/blob/b578bf598c30814eef559ac0f1035b210dfd6408/closure/goog/style/style.js#L1991
            const outerDiv = $('<div/>').css({
                overflow: 'auto',
                position: 'absolute',
                top: 0,
                width: '100px',
                height: '100px',
            })
            const innerDiv = $('<div />').css({
                width: '200px',
                height: '200px',
            })

            outerDiv.append(innerDiv).appendTo('body')

            DOMUtility.scrollHeight = outerDiv[0].offsetHeight - outerDiv[0].clientHeight
            DOMUtility.scrollWidth = outerDiv[0].offsetWidth - outerDiv[0].clientWidth

            outerDiv.remove()
        } catch (e) {
            debug('Failed to recalculate scroll dimensions', e)
        }
    }
}

export const DOMUtility = DOMUtilityFactory()
export type DOMUtilityInstance = typeof DOMUtility
