import ReduxActions from 'services/ReduxActions'

export /* @ngInject */ function WatchVideoReducerFactory() {
    const WatchVideoReducer: any = ReduxActions.handleActions<any>(
        {},
        {
            videoData: {},
        }
    )

    return WatchVideoReducer
}
