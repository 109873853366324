export default /* @ngInject */ function statefulLabelDirective() {
    var directive = {
        restrict: 'E',
        require: '^^?formGroup',
        link: statefulLabelLinkFn,
    }

    return directive

    ///////////////////////////

    function statefulLabelLinkFn(scope, element, attrs, formGroupCtrl) {
        // short circuit
        if (!formGroupCtrl) {
            return
        }

        formGroupCtrl.label = element
        scope.$on('$destroy', function () {
            formGroupCtrl.label = null
        })
    }
}
