import { TimestampTransformInstance } from 'services/TimestampTransform.factory'

export default /* @ngInject */ function timestampFilter(
    $filter: ng.IFilterFunction,
    TimestampTransform: TimestampTransformInstance
) {
    return (timestamp: number, showLongTimestamp: boolean, removeMiliseconds: boolean) => {
        if (!showLongTimestamp) {
            return $filter('date')(timestamp * 1000 || 0, 'mm:ss')
        } else if (showLongTimestamp && removeMiliseconds) {
            return TimestampTransform.timestamp(timestamp)
        } else {
            return TimestampTransform.timestampWithMilli(timestamp)
        }
    }
}
