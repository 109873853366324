type TSortParameter = string | number
type TSortItem = [TSortParameter, string]

interface ISortbale {
    value: string
    type: string
    index: number
}

export default /* @ngInject */ function orderByNaturalFilter($filter: ng.IFilterFunction) {
    return (collection: any[], expression: string, reverse: boolean) => {
        if (!collection) return

        return $filter('orderBy')(collection, expression, reverse, natsortComparator)
    }
}

function natsortComparator(a: ISortbale, b: ISortbale) {
    return naturalSort(a.value, b.value)
}

export function naturalSort(a: TSortParameter, b: TSortParameter) {
    const ax: TSortItem[] = []
    const bx: TSortItem[] = []
    a = '' + a
    b = '' + b

    a.replace(/(\d+)|(\D+)/g, (_, $1, $2): any => {
        ax.push([$1 || Infinity, $2 || ''])
    })
    b.replace(/(\d+)|(\D+)/g, (_, $1, $2): any => {
        bx.push([$1 || Infinity, $2 || ''])
    })

    while (ax.length && bx.length) {
        const an = ax.shift() as TSortItem
        const bn = bx.shift() as TSortItem
        const nn = +an[0] - +bn[0] || an[1].localeCompare(bn[1])
        if (nn) {
            return nn
        }
    }

    return ax.length - bx.length
}
