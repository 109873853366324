/**
 * @ngdoc directive
 * @name collaspable
 * @module map3.core
 * @restrict EA
 *
 * @description
 * A convenient collapsable container
 *
 * @param {boolean=} open If it should start open. Defaults to `true`.
 * @param {boolean=} static If we should use static rendering (all content is renderd but hidden with css)
 *  or dynanmic rendering (content is actually removed / added to DOM). Defaults to `true`.
 *
 * @example
    <example>
        <collapsable>
            <collapsable-open>
                This is me open
                <collapsable-toggle class="btn btn-primaery">Close</collapsable-toggle>
            </collapsable-open>

            <collapsable-closed>
                This is me closed
                <collapsable-toggle class="btn btn-primaery">Open me up again</collapsable-toggle>
            </collapsable-closed>
        </collapsable>
    </example
 */
const collapsableComponent = {
    bindings: {
        open: '<',
    },
    transclude: {
        open: 'collapsableOpen',
        closed: '?collapsableClosed',
        common: '?collapsableCommon',
    },
    template: `
        <div ng-transclude="common"
            ng-class="{
                expanded: $ctrl.isOpen,
                collapsed: !$ctrl.isOpen
            }"
        ></div>

        <div ng-if="::$ctrl.isStatic">
            <div ng-show="$ctrl.isOpen" ng-transclude="open"></div>
            <div ng-show="!$ctrl.isOpen" ng-transclude="closed"></div>
        </div>

        <div ng-if="::!$ctrl.isStatic">
            <div ng-if="$ctrl.isOpen" ng-transclude="open"></div>
            <div ng-if="!$ctrl.isOpen" ng-transclude="closed"></div>
        </div>
    `,
    controller: CollapsableCtrl,
}

/* @ngInject */
function CollapsableCtrl($scope, $attrs) {
    const $ctrl = this

    $ctrl.$onInit = $onInit
    $ctrl.toggle = toggle

    return $ctrl

    function $onInit() {
        $ctrl.isOpen = angular.isUndefined($ctrl.open) || !!$ctrl.open
        $ctrl.isStatic = angular.isUndefined($attrs.static) || !!$scope.$eval($attrs.static)

        $scope.$on('toggleAll', function (event, collapsableToggleAllState) {
            $ctrl.isOpen = collapsableToggleAllState
        })
    }

    function toggle() {
        $ctrl.isOpen = !$ctrl.isOpen
    }
}

/* @ngInject */
function collapsableToggleDirective() {
    const directive = {
        restrict: 'AE',
        require: {
            collapsableCtrl: '^^collapsable',
        },
        link: collapsableToggleLinkFn,
    }

    return directive

    function collapsableToggleLinkFn(scope, element, attrs, ctrls) {
        element.on('click', () => {
            scope.$apply(() => {
                ctrls.collapsableCtrl.toggle()
            })
        })
    }
}

/* @ngInject */
function collapsableToggleAllChildrenDirective() {
    const directive = {
        restrict: 'AE',
        link: collapsableToggleAllChildrenLinkFn,
    }
    return directive

    function collapsableToggleAllChildrenLinkFn(scope, element, attrs) {
        const initialState = angular.isUndefined(attrs.elementsAreExpanded)
        scope.collapsableToggleAllState = initialState

        element.on('click', function () {
            scope.$applyAsync(() => {
                scope.$parent.$broadcast('toggleAll', scope.collapsableToggleAllState)

                scope.collapsableToggleAllState = !scope.collapsableToggleAllState
            })
        })
    }
}

export { collapsableComponent, collapsableToggleDirective, collapsableToggleAllChildrenDirective }
