export default /* @ngInject */ function MissingSongsFactory($http: ng.IHttpService) {
    const MissingSongs = {
        sendMissingSongRequest(song_data: TSongData) {
            // eslint-disable-line
            return $http.post('/api/missing-songs', { song_data })
        },

        getMissingSongRequest(missingSongRequestToken: string) {
            return $http
                .get(`/api/missing-songs/${missingSongRequestToken}`)
                .then((res) => res.data)
        },

        resolveMissingSongRequest(missingSongRequestToken: string, response_data: any) {
            // eslint-disable-line
            return $http
                .post(`/api/missing-songs/${missingSongRequestToken}`, { response_data })
                .then((res) => res.data)
        },
    }

    return MissingSongs
}

export type MissingSongsInstance = ReturnType<typeof MissingSongsFactory>
