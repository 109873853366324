import CommentInterfaceFactory from './CommentInterface.factory'
import commentAddDirective from './commentAdd.directive'
import commentViewAllDirective from './commentViewAll.directive'
import commentAddBookmarkDirective from './commentAddBookmark.directive'
import commentsDropdownDirective from './commentsDropdown.directive'

/**
 * @ngdoc module
 * @name map3.comments
 * @module map3.comments
 *
 * @description
 * Comments
 */
const map3comments = angular
    .module('map3.comments', [])

    .factory('CommentInterface', CommentInterfaceFactory)

    .directive('commentAdd', commentAddDirective)
    .directive('commentViewAll', commentViewAllDirective)
    .directive('commentAddBookmark', commentAddBookmarkDirective)
    .directive('commentsDropdown', commentsDropdownDirective)

export default map3comments.name
