import _ from 'lodash'
import GraphSearch from 'directives/graph/GraphSearch'
import { AdminModuleInstance } from 'admin/AdminModule.factory'
interface IVideoPreviewData {
    seriesTitle?: string
    seasonNumber?: number
    episodeTitle?: number
    episodeNumber?: number
    originalPremiereDate?: string
    title?: string
    releaseDate?: string
}

type TSearchResult = {
    title: string
    type: string
    releaseDate: string
    entries: {
        markerSetId: string
        createdAt: string
        createdBy: string
        hitId: string
    }[]
}

class MarkerTasksToLoadComponentController implements ng.IComponentController {
    isEditable = false
    node: any = null
    graph: any = null
    workflow: any = null
    taskConfig: any = null

    hasMandatoryData = false
    isLookupInProgress = false
    hasLookupError = false
    hasMultipleResults = false
    markerSetOrderByPredicate = ['-createdAt']
    predicateOrderByName = 'loadMarkersSorting'
    markerSetFilterPredicate: any = null
    predicatePerfieldName = 'loadMarkersFilter'
    searchResult: null | TSearchResult = null

    hasResults = false
    selectedSets: string[] = []

    adminModule: AdminModuleInstance
    userPreferences: any
    $state: ng.ui.IStateService
    $q: ng.IQService

    /* @ngInject */
    constructor(
        AdminModule: AdminModuleInstance,
        UserPreferences: any,
        $state: ng.ui.IStateService,
        $q: ng.IQService
    ) {
        this.adminModule = AdminModule
        this.userPreferences = UserPreferences
        this.$state = $state
        this.$q = $q
    }

    getVideoConfigs() {
        const nodes = GraphSearch.searchAllUpstream(
            this.graph,
            this.node,
            { info: { wizards: { template: 'GetVideos' } } },
            /* maxDepth */ 1
        )

        return _.map(nodes, 'info.config')
    }

    loadTaskData() {
        this.isLookupInProgress = true

        const videoConfigs = this.getVideoConfigs().filter((i) => !!i.table && !!i.jsonArray)
        this.hasMandatoryData = !_.isEmpty(videoConfigs)
        if (!this.hasMandatoryData) {
            return
        }

        if (videoConfigs.length) {
            // error emulation
            //videoConfigs[0].table = 'ooops'

            const allVideosPromise = videoConfigs.map(({ table, jsonArray }) =>
                this.adminModule.getVideoSelectionPreview(table, jsonArray).catch(() => {
                    this.hasLookupError = true
                    return null
                })
            )

            const allExcludedVideos = _.flatten(videoConfigs.map((i) => i.excludedVideoIds))

            this.$q
                .all(allVideosPromise)
                .then((results) => {
                    const allVideos = _.flatten(
                        results.filter((i) => !!i && i).map((i) => i!.results)
                    ).filter((i) => !allExcludedVideos.includes(i.map3id))
                    return allVideos
                })
                .then((allVideos) => {
                    if (!allVideos.length) {
                        this.hasResults = false
                        return null
                    }

                    return this.adminModule.getMarkersFromPMD(allVideos[0].map3id).then((data) => {
                        return {
                            titleData: allVideos[0],
                            dataSets: data,
                            multpleResults: allVideos.length > 1,
                        }
                    })
                })
                .then((result) => {
                    this.taskConfig.markerSets = this.taskConfig.markerSets || []

                    if (result) {
                        this.searchResult = {
                            ...{
                                title: this.getTitle(result.titleData),
                                type: this.getType(result.titleData),
                                releaseDate: this.getReleaseDate(result.titleData),
                            },
                            entries: result.dataSets,
                        }

                        this.selectedSets = this.taskConfig.markerSets.map(
                            (entry: { markerSetId: string; hitId: string }) => entry.markerSetId
                        )

                        this.hasResults = !!result.dataSets.length
                        this.hasMultipleResults = result.multpleResults
                        this.hasLookupError = false
                    }
                })
                .catch(() => {
                    this.hasLookupError = true
                })
                .finally(() => {
                    this.isLookupInProgress = false
                })
        }
    }

    $onInit() {
        this.loadTaskData()
    }

    getTitle(video: IVideoPreviewData) {
        if (video.seriesTitle) {
            return `${video.seriesTitle || 'unknown'}. Season: ${
                video.seasonNumber || 'unknown'
            }, Episode: ${video.episodeNumber}. ${video.episodeTitle || 'unknown'}`
        } else if (video.title) {
            return video.title
        } else {
            return ''
        }
    }

    getType(video: IVideoPreviewData) {
        return video.seriesTitle ? 'Series' : 'Feature'
    }

    getReleaseDate(video: IVideoPreviewData | IVideoPreviewData) {
        return video.originalPremiereDate || video.releaseDate || ''
    }

    onSelectionChange(entry: {
        markerSetId: string
        createdAt: string
        createdBy: string
        hitId: string
    }) {
        this.taskConfig.markerSets = [
            {
                markerSetId: entry.markerSetId,
                hitId: entry.hitId,
            },
        ]
    }
}

const markerTasksToLoadComponent = {
    controller: MarkerTasksToLoadComponentController,
    bindings: {
        isEditable: '<',
        node: '<',
        graph: '<',
        workflow: '<',
        taskConfig: '=',
    },
    templateUrl: 'js/admin/workflow-wizard/components/markerTasksToLoad.tpl.html',
}

export default markerTasksToLoadComponent
