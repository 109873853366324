import _ from 'lodash'

/**
 * @ngdoc filter
 * @name searchByFieldFilter
 * @module map3.core
 * @kind function
 *
 * @description
 * A special version of [filter](https://docs.angularjs.org/api/ng/filter/filter) that
 * takes array of items and pick only the specified fields to match against a search string
 *
 * @param {Array} array The source array.
 * @param {Array} searchableFields A predicate object of where each prop is an object of
 *      unique props and a `true/false` denoting if items with that prop are allowed.
 * @param {string} searchString  The search strings we match against
 * @return {Array} The filtered array.
 */

export default /* @ngInject */ function searchableFieldsFilterFilter($filter: ng.IFilterFunction) {
    return function (array: any[], searchableFields: string[], searchString: string) {
        return $filter('filter')(array, (object: any) => {
            const filteredObjet = _.pick(object, searchableFields)
            const search = searchString.toLowerCase()

            return _.some(filteredObjet, (v: any) =>
                v && _.isArray(v)
                    ? _.includes(v.join(' ').toLocaleLowerCase(), search)
                    : _.includes(v.toLowerCase(), search)
            )
        })
    }
}
