import _ from 'lodash'
import * as d3 from 'd3'
import { DOMUtility } from 'services/DOMUtility.factory'
import FastdomWrapper from 'services/FastdomWrapper'
import { logError } from 'util/newRelic'

/**
 * D3 drawer for the graph data structure. Provides callbacks for user actions like
 * selecting/dragging/clicking nodes and edges
 */
export default class GraphDrawer {
    static BOX_WIDTH = 180
    static BOX_IO_RADIUS = 7
    static BOX_IO_LABEL_OFFSET_X = 10
    static LINE_HEIGHT = 22
    static LINE_TEXT_OFFSET_Y = 4

    static STATUS_ERROR = 'error'
    static STATUS_WARNING = 'warning'
    static STATUS_VALID = 'valid'
    static STATUS_CLASSES = [
        'Current',
        'Finished',
        'Hold',
        GraphDrawer.STATUS_ERROR,
        GraphDrawer.STATUS_WARNING,
        GraphDrawer.STATUS_VALID,
    ]

    static ZOOM_SCALING = 0.1
    static ZOOM_MIN = 0.35
    static ZOOM_MAX = 3

    /**
     * @param {DOMNode} svgElement
     * @param {JQuery} $overlay
     */
    constructor(svgElement, $overlay) {
        this.svgElement = svgElement
        this.$svg = $(svgElement)
        // set $ovelray to false if given falsy element or empty jQuery selection
        this.$overlay = !!$overlay && !!$overlay.length && $overlay
        this.holder = d3.select(this.svgElement).append('g').classed('graph-holder', 'true')

        this.onNodeDrag = null
        this.onNodeClick = null
        this.onNodeDblClick = null
        this.onEdgeClick = null
        this.onGraphClick = null
        this.onGraphMousemove = null
        this.onIoClick = null

        this.fastdom = new FastdomWrapper()

        this.zoomScale = 1.0
        this.zoomTranslate = { x: 0, y: 0 }

        this.overlayTimer = null

        this.init()
    }

    /**
     * Setup global svg callbacks and prepare async draw functions
     */
    init() {
        const self = this

        // setup an async draw function with RequestAnimationFrame throtting
        this.drawAsync = DOMUtility.rafDebounce(this.draw, this)
        this.fullDrawAsync = DOMUtility.rafDebounce(this.fullDraw, this)

        // setup svg events
        d3.select(this.svgElement)
            .on('click', function () {
                // only capture clicks on the SVG canvas, and not on SVG elements
                if (d3.event.target === self.svgElement && angular.isFunction(self.onGraphClick)) {
                    return self.onGraphClick.apply(this, arguments)
                }
            })
            .on('mousemove', function () {
                if (angular.isFunction(self.onGraphMousemove)) {
                    self.onGraphMousemove.apply(this, arguments)
                }
            })
            .call(
                d3.drag().on('drag', function () {
                    self.handleSvgDrag()
                })
            )

        this.$svg.on('mousewheel', function (e) {
            self.handleMousewheel(e)
        })
    }

    /**
     * Free resources
     */
    destroy() {
        this.svgElement = null
        this.holder = null
        this.$overlay = null
        this.fastdom.destroy()
        clearTimeout(this.overlayTimer)
    }

    /**
     * Main drawer method. Given a graph object, render it into SVG
     *
     * @param {GraphObject.graph} graph
     */
    draw(graph) {
        const drawData = GraphDrawerData.getDrawData(graph)
        this.lastDrawData = drawData

        this.drawEdges(this.holder, drawData.drawEdges)
        this.drawNodes(this.holder, drawData.drawNodes)
        this.updatePanZoom()
    }

    /**
     * Force removal of all svg elements and then execute a draw
     *
     * @param {GraphObject.graph} graph
     */
    fullDraw(graph) {
        this.holder.selectAll('*').remove()
        this.draw(graph)
        this.fitToSVG()
    }

    /**
     * Fit the graph to the SVG dimensions
     */
    fitToSVG() {
        this.fastdom.measure(() => {
            // if we have no nodes, then there is no holder rect
            const holderRect = computeHolderRect(this.lastDrawData.drawNodes)
            if (!holderRect) {
                return
            }
            const svgRect = this.svgElement.getBoundingClientRect()

            const svgRatio = svgRect.width / svgRect.height
            const holderRatio = holderRect.width / holderRect.height
            let transHolderRect = this.transformOriginalToMatrixBounds(holderRect)

            if (svgRatio > holderRatio) {
                // fit height
                this.zoomScale = this.zoomScale * (svgRect.height / transHolderRect.height) * 0.9
            } else {
                // fit width
                this.zoomScale = this.zoomScale * (svgRect.width / transHolderRect.width) * 0.9
            }
            this.zoomScale = _.clamp(this.zoomScale, GraphDrawer.ZOOM_MIN, GraphDrawer.ZOOM_MAX)
            // we need to recompute afte zoom scale change
            transHolderRect = this.transformOriginalToMatrixBounds(holderRect)

            const widthBuffer = (svgRect.width - transHolderRect.width) / 2
            // ideal center height would be divided by 2, but human brain actually
            // doesn't like ideal height centering - it prefers that items are slightly
            // above the center to perceive them as centered
            const heightBuffer = (svgRect.height - transHolderRect.height) / 2.5

            this.zoomTranslate = {
                x: this.zoomTranslate.x - transHolderRect.x + widthBuffer,
                y: this.zoomTranslate.y - transHolderRect.y + heightBuffer,
            }

            this.fastdom.mutate(() => {
                this.updatePanZoom()
            })
        })

        /////////////////////////////////

        /**
         * getBoundingClientRect is unreliable for wrapping <g> !!!
         * we must manually compute a static rect from box positions
         *
         * @param {Array<GraphDrawerData.drawNode>}
         * @return {Object<x, y, height, width>||false}
         */
        function computeHolderRect(drawNodes) {
            if (!_.size(drawNodes)) {
                return false
            }

            const x1 = _.min(_.map(drawNodes, 'x'))
            const y1 = _.min(_.map(drawNodes, 'y'))
            const x2 = _.max(_.map(drawNodes, 'x')) + GraphDrawer.BOX_WIDTH
            const y2 = _.max(_.map(drawNodes, (drawNode) => drawNode.y + drawNode.height))

            return {
                x: x1,
                y: y1,
                width: x2 - x1,
                height: y2 - y1,
            }
        }
    }

    /**
     * Given coordinates of bounds, transform them from original coordinate system to
     * the coordinate system post graph matrix transform
     *
     * @param {Object<x,y,width,height>|Array[x,y]} input
     * @return {Object<x,y,width,height>|Array[x,y]}
     */
    transformOriginalToMatrixBounds(input) {
        const bounds = _.isArray(input) ? { x: input[0], y: input[1] } : input

        const transformed = {
            x: bounds.x * this.zoomScale + this.zoomTranslate.x,
            y: bounds.y * this.zoomScale + this.zoomTranslate.y,
            width: bounds.width ? bounds.width * this.zoomScale : false,
            height: bounds.height ? bounds.height * this.zoomScale : false,
        }

        return _.isArray(input) ? [transformed.x, transformed.y] : transformed
    }

    /**
     * Given coordinates of bounds, in the current matrix transformed graph,
     * transform them to the original coordinate system
     *
     * @param {Object<x,y,width,height>|Array[x,y]} input
     * @return {Object<x,y,width,height>|Array[x,y]}
     */
    transformMatrixToOriginalBounds(input) {
        const bounds = _.isArray(input) ? { x: input[0], y: input[1] } : input

        const transformed = {
            x: bounds.x / this.zoomScale - this.zoomTranslate.x / this.zoomScale,
            // x: bounds.x / this.zoomScale,
            y: bounds.y / this.zoomScale - this.zoomTranslate.y / this.zoomScale,
            // y: bounds.y / this.zoomScale,
            width: bounds.width ? bounds.width / this.zoomScale : false,
            height: bounds.height ? bounds.height / this.zoomScale : false,
        }

        return _.isArray(input) ? [transformed.x, transformed.y] : transformed
    }

    /**
     * Set the graph zoom and pan as a transform on graph holder
     */
    updatePanZoom() {
        this.holder.attr(
            'transform',
            `matrix(${this.zoomScale}, 0, 0, ${this.zoomScale}, ${this.zoomTranslate.x}, ${this.zoomTranslate.y})`
        )
    }

    /**
     * Draw graph nodes
     *
     * @param {D3.selection<svg g.graph-holder>} holder
     * @param {Array<GraphDrawerData.drawNode>} drawNode
     */
    drawNodes(holder, drawNodes) {
        const self = this

        const boxes = holder.selectAll('g.box').data(drawNodes, (drawNode) => drawNode.id)

        // when data gets removed, remove the box
        boxes.exit().remove()

        // create new data entrypoint
        const box = boxes
            .enter()
            .append('g')
            .classed('box', true)
            .on('dblclick', function () {
                if (angular.isFunction(self.onNodeDblClick)) {
                    self.onNodeDblClick.apply(this, arguments)
                }
            })

        // setup onNodeDrag and onNodeClick behavior and callbacks
        box.call(
            d3
                .drag()
                // d3-drag adds an undesired behavior to our boxes - the first click on a
                // draggable element gets swallowed by d3-drag. This is because d3-drag needs
                // to consume mouse up and mouse down and disable click to be able to handle
                // drag stard, dragging and drag end.
                //
                // To handle that, we attach a manual $$wasDragged property on our node.
                // If it is false, then there was no drag event between mouse down and mouse up.
                //
                // In that case, we can trigger our `onNodeClick()` handler. However, we want to
                // make this whole behavior completely transparent to the outside. For that,
                // we need to set the global `d3.event` property to the click MouseEvent.
                //
                // Luckily, d3 keeps a reference to the originating event - in this case, d3-drag
                // has a custom DrawEnd event, with a source MouseEvent that is the original.
                //
                // So, we use d3.customEvent() to execute our listener with the global d3.event
                // set to DrawEvent.sourceEvent, ie, MouseEvent. After our listener completes,
                // the original global d3.event will be restored and all is good and well :)
                //
                // Additionally, we need to handle click-drags from IO nodes, potentially
                // the whole way to another IO node.
                //
                // The way we do that is, we assume that if a drag starts on an IO node, then
                // it's not a drag and should be ignored. Same for a click - it should not be
                // treated as a node click if it starts on the IO node. This adds some
                // further complication, but the resultant UX is nice, so... c'est la vie :)
                .on('start', function (drawNode) {
                    d3.select(this).raise()

                    const sourceEvent = d3.event.sourceEvent
                    // prevent endless cycle on d3.sourceEvent() traversal
                    d3.event.sourceEvent = null

                    if (
                        sourceEvent.target.matches('g.io circle') &&
                        angular.isFunction(self.onIoClick)
                    ) {
                        d3.customEvent(sourceEvent, self.onIoClick, sourceEvent.target, [
                            d3.select(sourceEvent.target).datum(),
                        ])
                        drawNode.$$ignoreNodeClick = true
                    } else {
                        drawNode.$$ignoreNodeClick = false
                    }

                    // restore source event
                    d3.event.sourceEvent = sourceEvent

                    drawNode.$$wasDragged = false
                })
                .on('drag', function (drawNode) {
                    if (!drawNode.$$ignoreNodeClick) {
                        if (angular.isFunction(self.onNodeDrag)) {
                            self.onNodeDrag.apply(this, arguments)
                        }
                        drawNode.$$wasDragged = true
                    } else if (angular.isFunction(self.onGraphMousemove)) {
                        // if we have started on an IO node, we must act as if we're just moving
                        // the mouse over the graph, even though it's detected as a drag event on
                        // that IO node
                        self.onGraphMousemove.apply(this, arguments)
                    }
                })
                .on('end', function (drawNode) {
                    if (drawNode.$$wasDragged === false) {
                        const sourceEvent = d3.event.sourceEvent
                        // prevent endless cycle on d3.sourceEvent() traversal
                        d3.event.sourceEvent = null

                        if (
                            sourceEvent.target.matches('g.io circle') &&
                            angular.isFunction(self.onIoClick)
                        ) {
                            // only trigger the `onIoClick` handler if we're not still pointed
                            // to the same IO (ie, user clicked on IO without moving mouse).
                            // This is because double triggers for the same mouse click on the
                            // same IO node are undesirable for GraphObject - they basically mean
                            // "create provisional edge" followed by "remove provisional edge" -
                            // ie, nothing happens.
                            const edge = d3.select(sourceEvent.target).datum()
                            if (edge.nodeId !== drawNode.id) {
                                d3.customEvent(sourceEvent, self.onIoClick, sourceEvent.target, [
                                    edge,
                                ])
                            }
                        } else if (
                            !drawNode.$$ignoreNodeClick &&
                            angular.isFunction(self.onNodeClick)
                        ) {
                            d3.customEvent(sourceEvent, self.onNodeClick, this, arguments)
                        }

                        // restore source event
                        d3.event.sourceEvent = sourceEvent
                    }

                    drawNode.$$wasDragged = false
                    drawNode.$$ignoreNodeClick = false
                })
        )

        // for new data and when data gets updated, we can only update the
        // transform/translate property and the status class, so we use a .merge()
        box.merge(boxes)
            .classed('selected', (drawNode) => drawNode.isSelected)
            .attr('transform', (drawNode) => `translate(${drawNode.x},${drawNode.y})`)
            .attr('opacity', (drawNode) => (drawNode.isProvisional ? 0.5 : 1))
            // set classes
            .each(function (drawNode) {
                if (drawNode.statusClass && !this.classList.contains(drawNode.statusClass)) {
                    this.classList.remove(...GraphDrawer.STATUS_CLASSES)
                    this.classList.add(drawNode.statusClass)
                } else if (drawNode.statusClass === false) {
                    this.classList.remove(...GraphDrawer.STATUS_CLASSES)
                }
            })

        // draw boundig rect
        box.append('rect')
            .classed('background', true)
            .attr('width', GraphDrawer.BOX_WIDTH)
            .attr('height', (drawNode) => drawNode.height)

        // draw header rect
        box.append('rect')
            .classed('header', true)
            .attr('width', GraphDrawer.BOX_WIDTH)
            .attr('height', GraphDrawer.LINE_HEIGHT)

        // draw header text
        box.append('text')
            .attr('text-anchor', 'middle')
            .attr('x', GraphDrawer.BOX_WIDTH / 2)
            .attr('y', GraphDrawer.LINE_HEIGHT - GraphDrawer.LINE_TEXT_OFFSET_Y)
            .append('tspan')
            .text((drawNode) => drawNode.title)

        // draw input-output lines
        box.each(function (drawNode) {
            const box = d3.select(this)

            const io = box
                .selectAll('g.io')
                .data(drawNode.io.all)
                .enter()
                .append('g')
                .classed('io', true)

            io.append('line')
                .attr('x1', 0)
                .attr('y1', (io) => io.yOffset)
                .attr('x2', GraphDrawer.BOX_WIDTH)
                .attr('y2', (io) => io.yOffset)

            io.append('text')
                .attr('text-anchor', (io) => {
                    return io.type === 'input' ? 'start' : 'end'
                })
                .attr('x', (io) => {
                    return io.type === 'input'
                        ? GraphDrawer.BOX_IO_LABEL_OFFSET_X
                        : GraphDrawer.BOX_WIDTH - GraphDrawer.BOX_IO_LABEL_OFFSET_X
                })
                .attr(
                    'y',
                    (io) => io.yOffset + GraphDrawer.LINE_HEIGHT - GraphDrawer.LINE_TEXT_OFFSET_Y
                )
                .append('tspan')
                .text((io) => io.label)

            io.append('circle')
                .attr('cx', (io) => {
                    return io.type === 'input' ? 0 : GraphDrawer.BOX_WIDTH
                })
                .attr('cy', (io) => io.yOffset + GraphDrawer.LINE_HEIGHT / 2)
                .attr('r', GraphDrawer.BOX_IO_RADIUS)
        })
    }

    /**
     * Draw graph edges
     *
     * @param {D3.selection<svg g.graph-holder>} holder
     * @param {Array<GraphDrawerData.drawEdge>} drawEdge
     */
    drawEdges(holder, drawEdges) {
        const self = this
        const lineHolders = holder.selectAll('g.link').data(drawEdges)

        // clear removed
        lineHolders.exit().remove()

        // render new
        const lineHolder = lineHolders.enter().append('g').classed('link', true).lower()
        lineHolder.append('path')

        // update new/existing
        lineHolder
            .merge(lineHolders)
            .on('click', function () {
                if (angular.isFunction(self.onEdgeClick)) {
                    self.onEdgeClick.apply(this, arguments)
                }
            })
            .classed('selected', (drawEdge) => drawEdge.isSelected)
            .select('path')
            .attr('d', (drawEdge) => drawEdge.path)
            .attr('pointer-events', (drawEdge) => (drawEdge.isProvisional ? 'none' : 'auto'))
    }

    /**
     * Handle SVG drag event (using d3.drag())
     */
    handleSvgDrag() {
        this.zoomTranslate = {
            x: this.zoomTranslate.x + d3.event.dx,
            y: this.zoomTranslate.y + d3.event.dy,
        }
        this.fastdom.mutate(() => {
            this.updatePanZoom()
        })
    }

    /**
     * Handle SVG mousewheel event (using jQuery.mousewheel)
     *
     * @param {JQuery.event} e
     */
    handleMousewheel(e) {
        // do mousewheel handling only if we have an overlay
        if (!this.$overlay) {
            return
        }

        const self = this

        if (!e.metaKey && !e.ctrlKey) {
            showOverlay()
        } else {
            executeZoom(e)
            e.preventDefault()
        }

        /////////////////////////////

        function executeZoom(e) {
            self.fastdom.measure(() => {
                const zoom = _.clamp(
                    1 + e.deltaY * GraphDrawer.ZOOM_SCALING,
                    GraphDrawer.ZOOM_MIN / self.zoomScale,
                    GraphDrawer.ZOOM_MAX / self.zoomScale
                )
                self.zoomScale = zoom * self.zoomScale

                const offset = self.$svg.offset()
                const coordinates = {
                    x: e.pageX - offset.left,
                    y: e.pageY - offset.top,
                }
                self.zoomTranslate = {
                    x: zoom * self.zoomTranslate.x - zoom * coordinates.x + coordinates.x,
                    y: zoom * self.zoomTranslate.y - zoom * coordinates.y + coordinates.y,
                }

                self.fastdom.mutate(() => {
                    self.updatePanZoom()
                })
            })
        }

        function showOverlay() {
            self.fastdom.mutate(() => {
                self.$overlay.css('opacity', 1)
                clearTimeout(self.overlayTimer)
                self.overlayTimer = setTimeout(hideOverlay, 1000)
            })

            function hideOverlay() {
                self.$overlay.css('opacity', 0)
            }
        }
    }
}

/**
 * Given a graph, convert it to a data representation that contains computed draw information
 */
class GraphDrawerData {
    static getDrawData(graph) {
        const drawNodes = _.map(graph.nodes, (node) => GraphDrawerData.getNodeDrawData(node))
        const drawEdges = _.map(graph.edges, (edge) =>
            GraphDrawerData.getEdgeDrawData(edge, drawNodes)
        )

        return {
            drawNodes,
            drawEdges,
        }
    }

    /**
     * @param {Object} node|graph
     * @param {Number} nodeId
     *
     * @return {Object} drawNode
     */
    static getNodeDrawData(node, nodeId = undefined) {
        if (angular.isDefined(nodeId)) {
            const graph = node
            node = _.find(graph.nodes, { id: nodeId })
        }

        const io = extractIo(node)

        let statusClass
        if (node.errors && node.errors.length) {
            statusClass = 'error'
        } else if (node.warnings && node.warnings.length) {
            statusClass = 'warning'
        } else if (node.errors && node.warnings) {
            statusClass = 'valid'
        } else {
            statusClass = node.info.status || false
        }

        return {
            id: node.id,
            title: node.info.title || node.info.name,
            x: node.x,
            y: node.y,
            height: GraphDrawer.LINE_HEIGHT * (1 + io.all.length), // title + number of io lines
            io,
            statusClass,
            isProvisional: !!node.$$isProvisional,
            isSelected: !!node.$$isSelected,
        }

        /**
         * @param {Object} node { info: { inputs: array, outputs: array } }
         * @return {Array} { inputs/outputs/all: { type: string, label: string, x: number, y: number }[] }
         */
        function extractIo(node) {
            const inputs = parse('input', node)
            const outputs = parse('output', node, inputs.length)
            const all = [].concat(inputs, outputs)

            return {
                inputs,
                outputs,
                all,
            }

            /////////////////////

            function parse(type, node, indexOffset = 0) {
                const ioList = type === 'input' ? node.info.inputs : node.info.outputs

                return _.map(ioList, (io, i) => {
                    return {
                        // base data
                        type,
                        label: io.label ? io.label : io,
                        value: io.value ? io.value : io,
                        // edge data
                        nodeId: node.id,
                        inId: type === 'input' ? i : null,
                        outId: type === 'output' ? i : null,
                        // render data
                        x: type === 'input' ? node.x : node.x + GraphDrawer.BOX_WIDTH,
                        y:
                            node.y +
                            (1 + i + indexOffset) * GraphDrawer.LINE_HEIGHT +
                            GraphDrawer.LINE_HEIGHT / 2,
                        yOffset: (1 + i + indexOffset) * GraphDrawer.LINE_HEIGHT,
                    }
                })
            }
        }
    }

    static getEdgeDrawData(edge, drawNodes) {
        const sourceNode = _.find(drawNodes, { id: edge.source && edge.source.nodeId })
        const sourceIo = sourceNode
            ? sourceNode.io.outputs[edge.source.outId]
            : { x: edge.$$danglingX, y: edge.$$danglingY }

        if (sourceNode) {
            logError(
                sourceIo,
                `['${sourceNode.title}'-box]: no corresponding coordinates for graph-link start`
            )
        }

        const targetNode = _.find(drawNodes, { id: edge.target && edge.target.nodeId })
        const targetIo = targetNode
            ? targetNode.io.inputs[edge.target.inId]
            : { x: edge.$$danglingX, y: edge.$$danglingY }

        if (targetNode) {
            logError(
                targetIo,
                `['${targetNode.title}'-box]: no corresponding coordinates for graph-link end`
            )
        }

        // we need the ORs because edges start with $$danglingX/Y set to false
        const x1 = sourceIo?.x || targetIo?.x
        const y1 = sourceIo?.y || targetIo?.y
        const x2 = targetIo?.x || sourceIo?.x
        const y2 = targetIo?.y || sourceIo?.y

        return {
            edge,
            path: `M${x1},${y1}L${x2},${y2}`,
            isProvisional: !!edge.$$isProvisional,
            isSelected: !!edge.$$isSelected,
        }
    }
}
