import _ from 'lodash'

export default /* @ngInject */ function AdminManageGroupsCtrl(
    $scope,
    AdminModule,
    AdminUsersModule,
    Notification,
    $uibModal,
    GroupModel,
    groups
) {
    let adminVm = this

    adminVm.groups = groups

    adminVm.getGroupDetails = getGroupDetails
    adminVm.showConfirmModal = showConfirmModal
    adminVm.showNewGroupModal = showNewGroupModal
    adminVm.showEditGroupModal = showEditGroupModal
    adminVm.showRenameGroupModal = showRenameGroupModal
    adminVm.isGroupPublic = GroupModel.isPublic
    ////////////////////////////////////

    function getGroupDetails(group) {
        AdminModule.getGroupDetails(group).then(function (data) {
            adminVm.groupDetails = data
        })
    }

    function showNewGroupModal() {
        let instance = $uibModal.open({
            templateUrl: 'js/admin/manage-groups/admin.add-group.tpl.html',
            controller: function () {
                let addGroupVm = this
                addGroupVm.addGroup = addGroup

                function addGroup(groupName) {
                    let group = {
                        label: groupName,
                    }

                    let addPromise = AdminModule.addGroup(group)

                    instance.close()

                    addPromise
                        .then(function (res) {
                            group.uri = res.data
                            group.num_members = 1
                            adminVm.groups.push(group)
                        })
                        .catch(function (res) {
                            _.remove(adminVm.groups, group)
                        })

                    Notification.forPromise(addPromise, 'Group successfuly created!')
                }
            },
            controllerAs: 'addGroupVm',
        })
    }

    function showRenameGroupModal(group) {
        let instance = $uibModal.open({
            templateUrl: 'js/admin/manage-groups/admin.rename-group.tpl.html',
            controller: function () {
                let renameGroupVm = this
                renameGroupVm.group = group
                renameGroupVm.tempGroup = angular.copy(group)

                renameGroupVm.renameGroup = renameGroup

                function renameGroup(newGroupName) {
                    let renamePromise = AdminModule.renameGroup(group, newGroupName)

                    instance.close()
                    renamePromise.then(function (res) {
                        renameGroupVm.group.label = renameGroupVm.tempGroup.label
                    })

                    Notification.forPromise(renamePromise, 'Group name successfuly changed!')
                }
            },
            controllerAs: 'renameGroupVm',
        })
    }

    function showConfirmModal($event, group) {
        let instance = $uibModal.open({
            templateUrl: 'js/admin/manage-groups/admin.delete-group.tpl.html',
            controller: function () {
                let deleteGroupVm = this

                deleteGroupVm.group = group
                deleteGroupVm.groups = _.filter(
                    adminVm.groups,
                    (adminVmGroup) => adminVmGroup !== group
                )
                deleteGroupVm.moveTaskToGroup = _.find(deleteGroupVm.groups, GroupModel.isPublic)

                deleteGroupVm.deleteGroup = deleteGroup

                function deleteGroup() {
                    let removePromise = AdminModule.removeGroup(
                        group,
                        deleteGroupVm.moveTaskToGroup
                    )

                    instance.close()
                    removePromise.then(function () {
                        _.remove(adminVm.groups, group)
                    })

                    Notification.forPromise(removePromise, 'Group successfuly removed!')
                }
            },
            controllerAs: 'deleteGroupVm',
        })
    }

    function showEditGroupModal(group) {
        $uibModal.open({
            templateUrl: 'js/admin/manage-groups/admin.edit-group.tpl.html',
            controller: /* @ngInject */ function (groupDetails, availableUsers) {
                let editGroupVm = this
                editGroupVm.group = group

                editGroupVm.filterAvailableUsers = filterAvailableUsers
                editGroupVm.addUserToGroup = addUserToGroup
                editGroupVm.getAvailableUsers = getAvailableUsers
                editGroupVm.removeUserFromGroup = removeUserFromGroup

                function getAvailableUsers() {
                    return AdminModule.getAvailableUsers(editGroupVm.group)
                }

                function filterAvailableUsers(query) {
                    return _.filter(availableUsers, function (user) {
                        return _.includes(user.username, query)
                    })
                }

                function removeUserFromGroup(user) {
                    let removePromise = AdminUsersModule.removeUserFromGroup(user, group)

                    removePromise
                        .catch(function (res) {
                            groupDetails.members.push(user)
                        })
                        .then(function (argument) {
                            editGroupVm.group.num_members--
                        })

                    Notification.forPromise(
                        removePromise,
                        `Member successfuly removed from ${group.label} group!`
                    )
                }

                function addUserToGroup(user) {
                    let addPromise = AdminUsersModule.addUserToGroup(user, group)

                    addPromise
                        .catch(function (res) {
                            _.remove(groupDetails.members, user)
                        })
                        .then(function (argument) {
                            editGroupVm.group.num_members++
                        })

                    Notification.forPromise(
                        addPromise,
                        `Member successfuly added to ${group.label} group!`
                    )
                }
            },
            controllerAs: 'editGroupVm',
            resolve: {
                groupDetails: function () {
                    return AdminModule.getGroupDetails(group)
                },
                availableUsers: function () {
                    return AdminModule.getAvailableUsers(group)
                },
            },
        })
    }
}

AdminManageGroupsCtrl.resolve = {
    groups: /* @ngInject */ function (AdminUsersModule) {
        return AdminUsersModule.getGroups()
    },
}
