import _ from 'lodash'
import fp from 'lodash/fp'

/**
 * @ngdoc directive
 * @name fieldFilter
 * @module map3.core
 * @restrict E
 *
 * @description
 * This directive creates a filtering widget, to be used inside `<th>`.
 *
 * This is a convenience directive that creates a dropdown filter for a particular field.
 * The dropdown contains all unique values for that field, allowing us to filter out
 * items by field uniques.
 *
 * This is meant to be used for fields like `status` where we will have few unique
 * values and not fields like `email` - where all values are unique.
 *
 * The generated predicate is meant to be used with {@link perfieldFilter}
 *
 * @scope
 *
 * @param {Array} items The array of objects from which to extract unique values.
 *      Note that these are the full objects!
 * @param {string} field A string expression, compatible with [lodash.get()](https://lodash.com/docs#get).
 *      This will be the object field we will extract the unique values from.
 * @param {Object} predicate The {@link perfieldFilter} `perfieldPredicate`.
 *      Has a structure like: `{ field: { uniqueValue1: true, uniqueValue2: false } }`
 * @param {array=} options Allow to manually set the available options for the predicate,
 *      instead of extracting them from the items. Use for hardcoded option limits
 *      (ie, status "started", "stopped", "suspended", etc). Prefer to set them up in `constants.js`
 * @param {boolean=} includeEmpty Add an empty option to the filter
 * @param {string=} title The title used for wai-aria. Will default to the `field` value.
 * @param {string=} itemClass Allow to set custom css class on items (ie, `text-capitalize`)
 */
export default /* @ngInject */ function fieldFilterDirective(
    $filter,
    $state,
    PERFIELD_EMPTY_VALUE_KEY,
    UserPreferences
) {
    var directive = {
        restrict: 'E',
        scope: {
            items: '<?',
            field: '@',
            predicate: '=',
            predicateName: '@?',
            includeEmpty: '<?',
            options: '<?',
            title: '@?',
            itemClass: '@?',
        },
        template: `
            <div class="dropdown">
                <button data-toggle="dropdown"
                    class="btn p-0"
                    ng-class="{
                        'text-primary': !allAreSelected
                    }"
                >
                    <i class="material-icons md-18">filter_list</i>
                </button>
                <form>
                    <section class="dropdown-menu dropdown-menu-right">
                        <div class="form-group px-3 py-2">
                            <label>Filter:</label>
                            <input
                                name="filterQueryString"
                                class="form-control"
                                ng-model="filterQueryString"
                                placeholder="Search for an entry"
                            />
                        </div>

                        <div class="dropdown-divider"></div>

                        <div class="dropdown-item">
                            <div class="checkbox">
                                <label class="m-0">
                                    <input type="checkbox" class="md-primary"
                                        ng-checked="allAreSelected"
                                        ng-click="toggleSelectAll()"
                                    />
                                        Select All
                                </label>
                            </div>
                        </div>

                        <div class="dropdown-divider"></div>

                        <div class="dropdown-item" ng-repeat="uniqueValue in uniqueValues | filter: filterQueryString">
                            <div class="checkbox" ng-click="handleClick($event, uniqueValue)">
                                <label class="m-0">
                                    <input type="checkbox" class="md-primary"
                                        ng-model="predicate[field][uniqueValue]"
                                    />
                                    <span ng-class="itemClass">
                                        {{ ::options[uniqueValue] || uniqueValue || '(empty)' }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        `,
        link: fieldFilterLinkFn,
    }

    return directive

    //////////////////

    function fieldFilterLinkFn(scope, element, attr) {
        let noPreserve = !_.isUndefined(attr.noPreserve)

        scope.toggleSelectAll = toggleSelectAll
        scope.handleClick = handleClick
        scope.title = scope.title || scope.field
        scope.filterQueryString = ''

        activate()

        ///////////////////

        function activate() {
            // if (!(scope.items || scope.options)) {
            //     throw new Error('`fieldFilter` requires either options or items to be set');
            // }

            if (UserPreferences.get('common', 'preserveTableOrder', false) && !noPreserve) {
                const predicateName = scope.predicateName || attr.predicate
                if (_.isNil(scope.predicate)) {
                    throw new Error(
                        `
When using fieldFilter with UserPreferences.live() you MUST define the predicate in the controller first.
Please, consider adding something like the following to your controller:

${predicateName} = UserPreferences.get($state.current.name, '${predicateName}', {});
                    `.trim() + '\n\n'
                    )
                }

                scope.predicate[scope.field] = UserPreferences.live(
                    scope.$parent,
                    attr.predicate + `.` + scope.field,
                    $state.current.name,
                    `${predicateName}.${scope.field}`,
                    scope.predicate[scope.field]
                )
            } else if (!_.isObject(scope.predicate)) {
                scope.predicate = {}
            }

            if (scope.options) {
                // if we are given options, we generate unique values once and setup our predicate
                if (_.isArray(scope.options)) {
                    scope.$applyAsync(() => {
                        updatePredicate(scope.options)
                    })
                } else if (_.isObject(scope.options)) {
                    scope.$applyAsync(() => {
                        updatePredicate(_.keys(scope.options))
                    })
                } else {
                    throw Error(
                        `[fieldFilter] Cannot handle given options: ${angular.toJson(
                            scope.options
                        )}`
                    )
                }
            } else {
                // otherwize, we watch the items to create unique values
                // and then watch the unique values to update the predicate
                scope.$watchCollection('items', (items) => {
                    updatePredicate(generateUniqueValues(items))
                })
            }

            scope.$watch(`predicate['${scope.field}']`, areAllSelected, true)
        }

        function generateUniqueValues(items) {
            const uniqueValues = fp.flow(
                fp.map(scope.field),
                fp.flatten,
                // filter out null, undefined, ""
                fp.filter((val) => !!val),
                fp.uniq
            )(items)

            return $filter('orderByNatural')(uniqueValues)
        }

        function updatePredicate(uniqueValues) {
            if (scope.includeEmpty) {
                uniqueValues.unshift(PERFIELD_EMPTY_VALUE_KEY)
            }

            scope.uniqueValues = uniqueValues

            let newOptions = _.zipObject(uniqueValues, _.fill(Array(uniqueValues.length), true))

            // omit possible old keys that don't exist in the current options
            let oldOptions = _.pick(scope.predicate[scope.field], _.keys(newOptions))

            // update scope options,
            // preserving the old ones or defaulting to the new ones
            scope.predicate[scope.field] = _.assign({}, newOptions, oldOptions)

            // Add an indication to this predicate property that it is in fact
            // currently being used by a fieldFilter.
            // This prevents stale predicate properties
            // (ie, from obsolete user preferences) interfering with filtering.
            scope.predicate[scope.field].__REMOVE_FROM_PREFERENCES_PROP_ACTIVE_PREDICATE = true
        }

        function toggleSelectAll() {
            _.forEach(_.keys(scope.predicate[scope.field]), (key) => {
                if (!keyShouldBeIgnored(key)) {
                    scope.predicate[scope.field][key] = !scope.allAreSelected
                }
            })
        }

        function handleClick(event, value) {
            if (event.metaKey || event.ctrlKey) {
                _.forEach(scope.uniqueValues, (value) => {
                    scope.predicate[scope.field][value] = false
                })
                scope.predicate[scope.field][value] = true
            }
        }

        function areAllSelected(fieldPredicate) {
            const valuesArr = _.values(
                _.omitBy(fieldPredicate, (_, key) => keyShouldBeIgnored(key))
            )

            scope.allAreSelected = _.every(valuesArr)
            scope.someAreSelected = _.some(valuesArr)
        }

        function keyShouldBeIgnored(key) {
            return key.startsWith('__REMOVE_FROM_PREFERENCES_PROP')
        }
    }
}
