import UserProfileCtrl from './UserProfile.ctrl'

export default /* @ngInject */ function userRoutesConfig($stateProvider: ng.ui.IStateProvider) {
    $stateProvider
        .state('user', {
            url: '/user',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
        .state('user.profileUser', {
            url: '/profile',
            controller: UserProfileCtrl,
            controllerAs: 'userProfileVm',
            templateUrl: 'js/user/profile.html',
            resolve: UserProfileCtrl.resolve,
        })
}
