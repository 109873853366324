import _ from 'lodash'
import { naturalSort } from 'filters/orderByNatural.filter'
import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'
import { ExportStatusPollerInstance } from 'services/ExportStatusPoller.factory'
import { ResultsModuleInstance } from 'services/ResultsModule.factory'
import { ExportStatus, PROGRAMMING_TYPE_CONSTANTS as CONSTANTS } from 'constants.es6'

interface IEpisodeOption {
    value: number | string
    label: string
}

interface ISeasonOption {
    value: number
    label: string
}

interface IFilterData {
    enabled: boolean
    season?: string
    seasonOptions?: ISeasonOption[]
    seasonDisabled?: boolean
    episode?: string
    episodeOptions?: IEpisodeOption[]
    episodeDisabled?: boolean
}

interface ScopeBindings {
    STATUS_INIT: ExportStatus.Init
    STATUS_CHECKING: ExportStatus.Checking
    STATUS_POLLING: ExportStatus.Polling
    STATUS_DOWNLOAD_READY: ExportStatus.DownloadReady
    STATUS_FAILED: ExportStatus.Failed
    status: ExportStatus

    filterData: IFilterData
    exportConfig: TExportConfigData
    loadingProjectWithSegments: boolean
    projectsWithSegments: any[]
    exportModel: any
    errorMessage: string | false | null
    poller: any

    startExport: () => ng.IPromise<void>
    generateExportFileName: () => string
    getSelectedVideoIds: () => string[]
    closeOrCancelExport: () => void
}

export default /* @ngInject */ function ResultsExportModalFactory(
    $uibModal: ng.ui.bootstrap.IModalService
) {
    const ResultsExportModal = {
        handleExport(exportDataType: string, exportData: any) {
            $uibModal.open({
                templateUrl: 'js/results/results-viewer-export-modal.tpl.html',
                resolve: {
                    exportDataType: _.constant(exportDataType),
                    exportData: angular.copy(exportData),
                },
                controller: ExportModalCtrl,
                backdrop: 'static',
            })
        },
    }

    return ResultsExportModal
}

/* @ngInject */
function ExportModalCtrl(
    PROGRAMMING_TYPE_CONSTANTS: typeof CONSTANTS,
    RESULTS_EXPORT_VIDEO: string,
    RESULTS_EXPORT_SERIES: string,
    RESULTS_EXPORT_SERIES_SEASON: string,
    RESULTS_EXPORT_SERIES_EPISODE: string,

    $q: ng.IQService,
    $scope: ng.IScope & ScopeBindings,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,

    MapDialog: any,
    ErrorStringifier: ErrorStringifierInstance,
    ResultsModule: ResultsModuleInstance,
    ExportStatusPoller: ExportStatusPollerInstance,

    exportDataType: string,
    exportData: any
) {
    const SENTINEL_SELECT_ALL_EPISODES = 'SELECT_ALL_EPISODES'

    // allow closing of modal without warning user
    const STATUSES_REQUIRE_USER_CONFIRMATION_FOR_CANCEL = [ExportStatus.Checking]

    let abortCheckDeferred = $q.defer()

    $scope.filterData = buildFilterData()

    $scope.generateExportFileName = generateExportFileName
    $scope.startExport = startExport
    $scope.closeOrCancelExport = closeOrCancelExport
    $scope.getSelectedVideoIds = getSelectedVideoIds

    // make the statuses available to the template
    $scope.STATUS_INIT = ExportStatus.Init
    $scope.STATUS_CHECKING = ExportStatus.Checking
    $scope.STATUS_POLLING = ExportStatus.Polling
    $scope.STATUS_DOWNLOAD_READY = ExportStatus.DownloadReady
    $scope.STATUS_FAILED = ExportStatus.Failed
    $scope.status = ExportStatus.Init

    activate()

    /////////////////////////////////////////

    function activate() {
        $scope.exportConfig = {
            groupData: 'timestamp',
            exportFormat: 'xlsx',
            tabs: 'groupByProject',
            map3id: getSelectedVideoIds(),
        }

        $scope.loadingProjectWithSegments = true

        ResultsModule.getVideoProjectsWithScenes(getSelectedVideoIds()).then((data: any) => {
            $scope.projectsWithSegments = data.projects || []
            $scope.loadingProjectWithSegments = false

            if (!_.isEmpty(data.projects)) {
                $scope.exportConfig.project = _.get(_.head(data.projects), 'id')
            }
        })

        $scope.$on('$destroy', () => {
            if ($scope.poller) {
                $scope.poller.destroy()
                $scope.poller = null
            }
        })
    }

    function buildFilterData() {
        // handle single videos of type episode with RESULTS_EXPORT_SERIES_EPISODE
        if (
            exportDataType === RESULTS_EXPORT_VIDEO &&
            exportData.programmingType === PROGRAMMING_TYPE_CONSTANTS.PROGRAMMING_TYPE_EPISODE
        ) {
            exportDataType = RESULTS_EXPORT_SERIES_EPISODE
        }

        switch (exportDataType) {
            case RESULTS_EXPORT_VIDEO:
            case RESULTS_EXPORT_SERIES: {
                return {
                    enabled: false,
                }
            }

            case RESULTS_EXPORT_SERIES_EPISODE: {
                return {
                    enabled: true,

                    season: exportData.seasonNumber,
                    seasonOptions: buildSeasonOptions([exportData.seasonNumber]),
                    seasonDisabled: true,

                    episode: exportData.episodeNumber,
                    episodeOptions: buildEpisodeOptions([exportData.episodeNumber]),
                    episodeDisabled: true,
                }
            }

            case RESULTS_EXPORT_SERIES_SEASON: {
                const episodeNumbers = _.map(
                    exportData.episodes,
                    (episode) => episode.episodeNumber
                )

                return {
                    enabled: true,

                    season: exportData.seasonNumber,
                    seasonOptions: buildSeasonOptions([exportData.seasonNumber]),
                    seasonDisabled: true,

                    episode: SENTINEL_SELECT_ALL_EPISODES,
                    episodeOptions: buildEpisodeOptions(episodeNumbers),
                    episodeDisabled: false,
                }
            }

            default: {
                throw new Error(`Unknown export data type "${exportDataType}"`)
            }
        }

        ///////////////////////

        function buildSeasonOptions(seasonNumbers: number[]) {
            seasonNumbers = seasonNumbers.sort(naturalSort)

            return _.map(seasonNumbers, (seasonNumber) => {
                return {
                    value: seasonNumber,
                    label: `Season ${seasonNumber}`,
                }
            })
        }

        function buildEpisodeOptions(episodeNumbers: number[]) {
            episodeNumbers = episodeNumbers.sort(naturalSort)

            const sentinelSelectAllEpisodes = {
                value: SENTINEL_SELECT_ALL_EPISODES,
                label: 'All episodes',
            }

            const episodeOptions = _.map(episodeNumbers, (episodeNumber) => {
                return {
                    value: episodeNumber,
                    label: `Episode ${episodeNumber}`,
                }
            })

            return ([] as IEpisodeOption[]).concat(sentinelSelectAllEpisodes, episodeOptions)
        }
    }

    function generateExportFileName() {
        const exportFormat =
            $scope.exportConfig.exportFormat === 'xls-tabs'
                ? 'xls'
                : $scope.exportConfig.exportFormat

        switch (exportDataType) {
            case RESULTS_EXPORT_VIDEO:
            case RESULTS_EXPORT_SERIES_EPISODE: {
                // both when exporting a single video or an episode in a series,
                // just use the full video name
                return normalizeName(`${exportData.fullName}.${exportFormat}`)
            }

            case RESULTS_EXPORT_SERIES_SEASON: {
                const seriesTitle = exportData.seriesTitle
                const seasonNumber = _.padStart(exportData.seasonNumber, 2, '0')

                if ($scope.filterData.episode === SENTINEL_SELECT_ALL_EPISODES) {
                    return normalizeName(`${seriesTitle}_S${seasonNumber}.${exportFormat}`)
                } else {
                    const episodeNumber = _.padStart($scope.filterData.episode, 3, '0')
                    return normalizeName(
                        `${seriesTitle}_S${seasonNumber}E${episodeNumber}.${exportFormat}`
                    )
                }
            }

            case RESULTS_EXPORT_SERIES: {
                return normalizeName(`${exportData.seriesTitle}.${exportFormat}`)
            }

            default: {
                throw new Error(`Unknown export data type "${exportDataType}"`)
            }
        }

        //////////////////////////

        function normalizeName(name: string) {
            // remove windows invalid filename characters
            const validName = _.replace(name, /[\x00-\x1f<>\\?*:";|/]+/g, '') // eslint-disable-line
            // replace spaces with underscores
            const underscoreName = _.replace(validName, /\s+/g, '_')

            return underscoreName
        }
    }

    function getSelectedVideoIds() {
        switch (exportDataType) {
            case RESULTS_EXPORT_VIDEO:
            case RESULTS_EXPORT_SERIES_EPISODE: {
                return [exportData.map3id]
            }

            case RESULTS_EXPORT_SERIES_SEASON: {
                const episodes = _.filter(exportData.episodes, (episode) => {
                    return (
                        $scope.filterData.episode === SENTINEL_SELECT_ALL_EPISODES ||
                        episode.episodeNumber === $scope.filterData.episode
                    )
                })
                return _.map(episodes, (episode) => episode.video.map3id)
            }

            case RESULTS_EXPORT_SERIES: {
                return _.flatMap(exportData.seasons, (season) => {
                    return _.map(season.episodes, (episode) => episode.video.map3id)
                })
            }

            default: {
                throw new Error(`Unknown export data type "${exportDataType}"`)
            }
        }
    }

    function startExport() {
        const filename = generateExportFileName()
        const exportConfig = {
            ...$scope.exportConfig,
            filename,
            map3id: getSelectedVideoIds(),
        }

        $scope.status = ExportStatus.Checking
        $scope.errorMessage = false

        const exportPromise = ResultsModule.exportCheckConfig(
            exportConfig,
            abortCheckDeferred.promise
        )
            .then((availability: TExportAvailability) => {
                if (availability.annotations === 'none') {
                    if (
                        (availability.segments === 'none' || !exportConfig.includeSegment) &&
                        availability.cutntag === 'none'
                    ) {
                        const error = new Error('No data available for export')

                        $scope.errorMessage = ErrorStringifier.stringify(error)

                        throw error
                    } else if (availability.cutntag !== 'none') {
                        return true
                    } else {
                        return MapDialog.show(
                            MapDialog.confirm({
                                title: 'Warning',
                                htmlContent: `
                                    <strong>No annotations are available</strong>, but we can export segment data.
                                    <br>
                                    Do you wish to continue?
                                `,
                            })
                        )
                    }
                } else if (
                    availability.annotations === 'partial' ||
                    (availability.segments !== 'full' && exportConfig.includeSegment)
                ) {
                    return MapDialog.show(
                        MapDialog.confirm({
                            title: 'Warning',
                            htmlContent: `
                            <strong>Only partial export data is available.</strong>
                            <br>
                            Do you wish to continue?
                        `,
                        })
                    )
                } else if (
                    availability.annotations === 'full' &&
                    (!exportConfig.includeSegment || availability.segments === 'full')
                ) {
                    // success, continue to next promise
                    return true
                } else {
                    throw new Error(
                        `Something went wrong in export check: ${angular.toJson(availability)}`
                    )
                }
            })
            .then(() => {
                // the check went successfully, so we can now start the export process
                return ResultsModule.exportStart(exportConfig)
            })
            .then((exportModel) => {
                $scope.poller = new ExportStatusPoller($scope, exportModel)
                $scope.exportModel = $scope.poller.exportModel
            })
            // If we get an actial error, display it.
            // MapDialog rejections will not be shown.
            .catch((error) => {
                if (error) {
                    $scope.errorMessage = ErrorStringifier.stringify(error)
                }
            })

        return exportPromise
    }

    function closeOrCancelExport() {
        if (_.includes(STATUSES_REQUIRE_USER_CONFIRMATION_FOR_CANCEL, $scope.status)) {
            // if we're currently not downloading, close the modal
            // otherwize ask for verification
            MapDialog.show(
                MapDialog.confirm({
                    title: 'Warning',
                    htmlContent: `
                    <strong>A export is currently in progress!</strong>
                    <br>
                    Do you wish to cancel it?
                `,
                    ok: 'Cancel Export',
                    cancel: 'Continue Export',
                })
            ).then(abortExport)
        } else {
            $uibModalInstance.dismiss()
        }
    }

    function abortExport() {
        // resolve the abort promise
        abortCheckDeferred.resolve()

        // create a new deferred object
        abortCheckDeferred = $q.defer()
        $scope.status = ExportStatus.Init
        $scope.errorMessage = null
    }
}
