export default /* @ngInject */ function PopupVideoCtrl(
    $state,

    User,
    RoutingAuth,
    VideoCommunication,
    SharedVideoAPI,
    CommentInterface,

    id,
    videoData
) {
    let videoVm = this
    let ParentCommunication

    videoVm.video = videoData
    videoVm.annotations = window.annotations
    videoVm.useBoudningBoxes = window.useBoudningBoxes

    activate()

    ///////////////////////////////

    function activate() {
        // Allow this page to only exist as a popup.
        // If we are opened directly, redirect to the appropriate route
        if (!window.opener || !window.opener.videoParentCommunication) {
            if (User.hasActiveRole('worker')) {
                $state.go('tasks.find', { assignmentID: id })
            } else if (User.hasActiveRole('qa')) {
                $state.go('qa.review', { id: id })
            } else {
                $state.go(RoutingAuth.getDefaultState())
            }

            return
        }

        CommentInterface.initFromOpenerWindow()

        ParentCommunication = window.opener.videoParentCommunication

        // if our parent window gets closed, close us as well
        window.opener.addEventListener('beforeunload', function () {
            window.close()
        })

        SharedVideoAPI.onLoad(handleVideoApiReady)
    }

    function handleVideoApiReady(videoApi) {
        videoVm.videoApi = videoApi

        window.videoPopupCommunication = VideoCommunication.buildPopup(videoApi)

        // seek from current parent position
        videoApi.seek(ParentCommunication.getApi().getCurrentTime())

        ParentCommunication.onPopupVideoApiLoaded(videoApi)
    }
}

PopupVideoCtrl.resolve = {
    id: /* @ngInject */ function ($stateParams) {
        return $stateParams.id
    },
    videoData: /* @ngInject */ function (VideoService, id) {
        return VideoService.get(id)
    },
}

PopupVideoCtrl.controllerAs = 'videoVm'
