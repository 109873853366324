export default /* @ngInject */ function CommentServiceFactory($http) {
    let CommentService = {
        getThreads: function (hitOrAnnotationID) {
            return $http
                .get(`/api/communication/${hitOrAnnotationID}/comment-threads`)
                .then((res) => res.data || [])
        },

        createCommentThread: function (hitOrAnnotationID, comment) {
            return $http
                .post(`/api/communication/${hitOrAnnotationID}/comment-threads`, comment)
                .then((res) => res.data)
        },

        addCommentToThread: function (hitOrAnnotationID, threadId, comment) {
            return $http
                .post(
                    `/api/communication/${hitOrAnnotationID}/comment-threads/${threadId}/reply`,
                    comment
                )
                .then((res) => res.data)
        },

        deleteCommentFromThread: function (hitOrAnnotationID, threadID, commentID) {
            return $http
                .post(
                    `/api/communication/${hitOrAnnotationID}/comment-threads/${threadID}/comment/${commentID}/delete`
                )
                .then((res) => res.data)
        },

        editCommentInThread: function (hitOrAnnotationID, threadID, commentID, text) {
            return $http
                .post(
                    `/api/communication/${hitOrAnnotationID}/comment-threads/${threadID}/comment/${commentID}/edit`,
                    {
                        text,
                    }
                )
                .then((res) => res.data)
        },
    }

    return CommentService
}
