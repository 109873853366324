import { Annotorious } from '@recogito/annotorious'
import _ from 'lodash'
import angular from 'angular'

import {
    GlobalShortcutsInstance,
    ShortcutDefinition,
} from 'global-shortcuts/GlobalShortcuts.factory'
import {
    QuestionData,
    ParsedQuestionValue,
} from '../../directives/answer-questions/questionInput.directive'

export type WebAnnotationInstance = {
    bodies: { purpose: string; type: string; value: string }[]
}

const annotoriousComponent = {
    controller: annotoriousController,
    bindings: {
        image: '<',
        shape: '<',
        handleUpdate: '&',
    },
    template: `
        <div class="image-wrapper">
            <img ng-style="$ctrl.imageIsVisible" ng-src="{{ $ctrl.image.url }}" id="annotorious-image" />
        </div>
    
    `,
}

export default annotoriousComponent

/* @ngInject */ function annotoriousController(
    this: unknown,
    $scope: ng.IScope,
    $element: ng.IAugmentedJQuery,
    $window: ng.IWindowService,
    GlobalShortcuts: GlobalShortcutsInstance
) {
    const $ctrl = this as ng.IComponentController & {
        imageIsVisible: { visibility: 'visible' | 'hidden' }
        $onInit: typeof $onInit
    }

    $ctrl.$onInit = $onInit

    $ctrl.imageIsVisible = { visibility: 'hidden' }

    return $ctrl

    /////////////////////////////

    function $onInit() {
        const image = document.getElementById('annotorious-image')! as HTMLImageElement
        const $imageWrapper = $element.find('.image-wrapper')
        $imageWrapper.addClass('image-loading')

        if ($ctrl.image.questions.length === 2) {
            $imageWrapper.addClass('make-editor-wider-two-questions')
        } else if ($ctrl.image.questions.length === 3) {
            $imageWrapper.addClass('make-editor-wider-three-questions')
        }

        angular.element($window).on('resize', resizeImage)

        setupShortcuts()

        image.addEventListener('load', () => {
            $scope.$apply(() => {
                resizeImage()

                $ctrl.imageIsVisible = { visibility: 'visible' }
                $ctrl.annotorious = new Annotorious({
                    image,
                    formatter,
                    widgets: [
                        {
                            widget: SelectorWidget,
                            force: 'PlainJS',
                            questions: $ctrl.image.questions,
                            suggestedValuesForAnnotation: $ctrl.image.suggestedValuesForAnnotation,
                        },
                    ],
                })
                $ctrl.annotorious.setAnnotations($ctrl.image.tagging_data)
                $ctrl.annotorious.setDrawingTool($ctrl.shape)

                $ctrl.annotorious.on('createAnnotation', onUpdate)
                $ctrl.annotorious.on('updateAnnotation', onUpdate)
                $ctrl.annotorious.on('deleteAnnotation', onUpdate)
                $ctrl.annotorious.on('deleteAnnotation', onUpdate)
            })
        })

        image.addEventListener('load', () => {
            $scope.$apply(() => {
                resizeImage()

                $imageWrapper.removeClass('image-loading')

                $ctrl.imageIsVisible = { visibility: 'visible' }
            })
        })

        $scope.$watch(
            '$ctrl.image',
            () => {
                $imageWrapper.addClass('image-loading')

                $ctrl.imageIsVisible = { visibility: 'hidden' }

                if ($ctrl.annotorious) {
                    $ctrl.annotorious.destroy()
                    $ctrl.annotorious = undefined
                }

                _.forEach($ctrl.image.questions, (question) => {
                    question.parsedValues = QuestionData.parseQuestionValues(question)
                })
            },
            true
        )

        $scope.$watch('$ctrl.shape', () => {
            $ctrl.annotorious && $ctrl.annotorious.setDrawingTool($ctrl.shape)
        })

        $scope.$on('$destroy', function () {
            $ctrl.annotorious.destroy()
            angular.element($window).off('resize', resizeImage)
            angular.element(image).off('load')
        })

        /////////////////////////////

        function onUpdate() {
            const annotations = $ctrl.annotorious.getAnnotations()
            $ctrl.handleUpdate({ annotations })
        }

        function resizeImage() {
            $imageWrapper.css({ height: '1px', width: '1px', 'max-width': '1px' })

            const { width, height } = $element[0].getClientRects()[0]
            const aspect = image.naturalWidth / image.naturalHeight
            const callculatedWidth = height * aspect

            if (callculatedWidth < width) {
                $imageWrapper.css({
                    height: height + 'px',
                    width: callculatedWidth + 'px',
                    'max-width': width + 'px',
                })
            } else {
                $imageWrapper.css({
                    height: height + 'px',
                    width: width + 'px',
                    'max-width': width + 'px',
                })
            }

            _.forEach(
                document.getElementsByClassName('a9s-shape-label') as HTMLCollectionOf<HTMLElement>,
                (domNode) => {
                    domNode.style.fontSize = `${getScaledFontSize()}px`
                }
            )
        }

        function setupShortcuts() {
            const shortcuts: ShortcutDefinition[] = [
                {
                    description: 'Toggle annotation lables',
                    keyCombo: 'h',
                    shortcut: 'H',
                    callback: function () {
                        $element.toggleClass('hide-labels')
                    },
                },
            ]

            const unbind = GlobalShortcuts.bind({ title: 'Visability Shortcuts', shortcuts })
            $scope.$on('$destroy', unbind)
        }

        function formatter(annotation: WebAnnotationInstance) {
            const answers = _.isArray(annotation.bodies) ? annotation.bodies : [annotation.bodies]
            if (!answers.length) {
                return
            }

            const element = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
            element.setAttribute('width', '1px')
            element.setAttribute('height', '1px')
            element.innerHTML = `
                <div xmlns="http://www.w3.org/1999/xhtml" class="a9s-shape-label-wrapper">
                    <ul class="a9s-shape-label" style="font-size: ${getScaledFontSize()}px;">
                        ${answers.map((a) => `<li>${getLabelForValue(a.value)}</li>`).join('\n')}
                    </ul>
                </div>
            `

            return {
                element,
            }
        }

        function getScaledFontSize() {
            return (
                15 * ((image.naturalWidth / image.width + image.naturalHeight / image.height) / 2)
            )
        }
        const questionValues = $ctrl.image.questions.flatMap(
            (q: Question & { parsesdValues: ParsedQuestionValue }) => q.values
        )
        function getLabelForValue(answerValue: string): string {
            return _.get(
                _.find(questionValues, (question) => question.value === answerValue),
                'label',
                undefined
            )
        }
    }

    function SelectorWidget(args: any) {
        const questionContainer = document.createElement('div')
        questionContainer.className = 'row p-4'

        _.forEach(args.questions, (question) => {
            const questionInput = buildQuestioInputs(question)
            questionContainer.append(questionInput)
        })

        return questionContainer

        ///////////////////////////////////////

        function buildQuestioInputs(question: Question & { parsedValues: ParsedQuestionValue[] }) {
            const selectedValues = _.map(args.annotation?.bodies, 'value')

            const container = document.createElement('div')
            container.className =
                args.questions.length === 3
                    ? 'col-4'
                    : args.questions.length === 2
                    ? 'col-6'
                    : 'col-12'

            const header = document.createElement('label')
            header.innerText = question.placeholder || ''
            container.append(header)

            const select = document.createElement('select')
            select.setAttribute('multiple', 'multiple')
            select.id = `question-select-${question.uri}`

            container.appendChild(select)

            for (let i = 0; i < question.parsedValues.length; i++) {
                const option = document.createElement('option')
                const { value, label, $$renderLabel } = question.parsedValues[i]

                option.value = value
                option.text = $$renderLabel || label
                option.selected = _.includes(selectedValues, value)

                select.appendChild(option)
            }

            ;($(select) as any)
                .chosen({ width: '100%' })
                .on('change', (event: Event, params: Record<'selected' | 'deselected', string>) => {
                    const key = Object.keys(params)[0]
                    const value = Object.values(params)[0]

                    const draftTag = {
                        type: 'TextualBody',
                        value: '',
                        purpose: 'tagging',
                    }
                    const exsistingTag = _.find(args.annotation.bodies, { value })
                    switch (key) {
                        case 'selected':
                            args.onAppendBody({ ...draftTag, value })
                            break
                        case 'deselected':
                            event.stopPropagation()
                            args.onRemoveBody(exsistingTag)
                            break
                        default:
                            throw new Error()
                    }
                })

            const valuesForAnnotation = _.find(
                args.suggestedValuesForAnnotation,
                (valuesForAnnotation) => valuesForAnnotation.id === args.annotation?.id
            )
            if (valuesForAnnotation) {
                const values = _.get(
                    _.find(
                        valuesForAnnotation.valuesForQuestion,
                        (valuesForQuestion) => valuesForQuestion.questionUri === question.uri
                    ),
                    'values',
                    []
                )

                if (values.length) {
                    setupSuggestedValues(values)
                }
            }

            if (question.hasExamples) {
                setupExamples()
            }

            function setupSuggestedValues(values: { uri: string; label: string }[]) {
                const title = document.createElement('label')
                title.className = 'mt-2'
                title.innerHTML = 'Suggested terms:'
                container.appendChild(title)

                const suggestionsContainer = document.createElement('div')
                suggestionsContainer.className = 'questions-with-suggestions-container'

                const list = _.map(values, (value) => buildSuggesttion(value))

                $(suggestionsContainer)
                    .append(list)
                    .on('click', '.btn.active-result', function () {
                        const index = $(this).data('option-array-index')
                        const option = select.options[index]

                        if (index && option) {
                            option.selected = !option.selected

                            $(this).toggleClass('selected')

                            const params = {
                                [option.selected ? 'selected' : 'deselected']: option.value,
                            }
                            $(select).trigger('change', [{ ...params }])
                        }
                    })

                $(container).append(suggestionsContainer)

                //////////////////////////////////////////////

                function buildSuggesttion(value: { uri: string; label: string }) {
                    const index = _.findIndex(question.parsedValues, (pv) => pv.value === value.uri)
                    const isSelected = _.includes(
                        _.map(
                            _.filter(select.options, (o) => o.selected),
                            'value'
                        ),
                        value.uri
                    )

                    return `
                        <button class="btn btn-xs active-result ${isSelected ? 'selected' : ''}"
                        data-option-array-index="${index}"
                            >
                            ${value.label}
                            </button>
                            `
                }
            }

            function setupExamples() {
                $(container).on('mouseenter', '.active-result', function () {
                    clearDOM()

                    const zeroBasedIndex = $(this).data('option-array-index')
                    const term = question.parsedValues[zeroBasedIndex]
                    const example = findExampleForTerm(term.value)

                    if (!example) {
                        return
                    }

                    const { x, y, width } = container.getBoundingClientRect()
                    const offsetX = window.innerWidth < x + width * 2

                    const loaderHtml = `
                        <div 
                            id="example-gif-container"
                            class="
                                ${offsetX ? 'left' : 'right'}
                                top card mt-0
                            "
                            style="width: ${width}px; height: 124px;"
                        >
                            <div class="card-body text-center">
                                Example loading
                                <div class="loading-spinner" style="top: 84px;">
                                    <div class="spinner-icon"></div>
                                </div>
                            </div>
                        </div>
                    `
                    $ctrl.loader = $(loaderHtml).appendTo(container)

                    const imageHtml = `
                        <img src="${example}"
                            id="example-gif"
                            class="
                                ${offsetX ? 'left' : 'right'}
                                d-none top bottom
                            "
                            style="width: ${width}px"
                        />`
                    $ctrl.exampleImage = $(imageHtml).appendTo(container)

                    $ctrl.exampleImage
                        .on('load', function (this: HTMLImageElement) {
                            const computedHeight = width / (this.naturalWidth / this.naturalHeight)

                            if (computedHeight < window.innerHeight - y) {
                                $ctrl.exampleImage.removeClass('bottom')
                            }

                            $ctrl.exampleImage.removeClass('d-none')

                            $ctrl.loader.remove()
                        })
                        .on('error', () => {
                            $ctrl.loader.find('.card-body').html(`
                                <p>There has been error fetching the image!</p>
                            `)
                        })
                })
                $(container).on('mouseleave', '.active-result', clearDOM)
                $(container).on('mouseleave', clearDOM)

                $scope.$on('$destroy', () => {
                    $(container).off('mouseleave mouseenter')
                    clearDOM()
                })

                function findExampleForTerm(uri: string) {
                    return _.get(_.find(question.values, { value: uri }), 'exampleGifSrc')
                }

                function clearDOM() {
                    if ($ctrl.loader) {
                        $($ctrl.loader).remove()
                    }

                    if ($ctrl.exampleImage) {
                        $($ctrl.exampleImage).remove()
                    }
                }
            }

            return container
        }
    }
}
