import { ExportStatus } from 'constants.es6'

export default /* @ngInject */ function MonthlyExportModalFactory($uibModal) {
    const MonthlyExportModal = {
        showModal(exportParam) {
            $uibModal.open({
                templateUrl: 'js/admin/tasks-export-modal.tpl.html',
                controller: MonthlyExportModalCtrl,
                controllerAs: MonthlyExportModalCtrl.controllerAs,
                backdrop: 'static',
                resolve: {
                    exportParam: () => exportParam,
                },
            })
        },
    }

    return MonthlyExportModal
}

/* @ngInject */
function MonthlyExportModalCtrl(
    NO_LOAD_OVERLAY,

    $scope,
    $http,

    ErrorStringifier,
    ExportStatusPoller,

    exportParam
) {
    const vm = this

    $scope.STATUS_POLLING = ExportStatus.Polling
    $scope.STATUS_DOWNLOAD_READY = ExportStatus.DownloadReady
    $scope.STATUS_FAILED = ExportStatus.Failed
    vm.status = ExportStatus.Polling

    vm.startExport = startExport

    activate()

    return vm

    ////////////////////////////////////

    function activate() {
        startExport()

        $scope.$on('$destroy', () => {
            if (vm.poller) {
                vm.poller.destroy()
                vm.poller = null
            }
        })
    }

    function startExport() {
        const promise = $http
            .get(`/api/admin/tasks/monthly-report/${exportParam}`, { ...NO_LOAD_OVERLAY })
            .then((res) => res.data)
            .then((exportModel) => {
                vm.poller = new ExportStatusPoller(vm, exportModel)
                vm.exportModel = vm.poller.exportModel
            })
            .catch((err) => {
                if (err) {
                    vm.errorMessage = ErrorStringifier.stringify(err)
                }
            })

        return promise
    }
}

MonthlyExportModalCtrl.controllerAs = 'vm'
