import _ from 'lodash'
import invariant from 'util/invariant'

/**
 * @ngdoc directive
 * @name clickAndWait
 * @module map3.core
 * @restrict A
 *
 * @param {function} clickAndWait A function that returns a promise.
 *  The click event is supplied as `$event`.
 *
 * @description
 * Ignore subsequent clicks to a button/input/a element until a promise is resolved
 *
 * Use this to wait for backend request to complete before re-enabling a button
 *
 *
 * ```html
 * <button type="submit" click-and-wait="$ctrl.backendCall()" />
 * ```
 *
 * Will attach `disabled="disabled"` attribute and `class="disabled"`.
 */
export default /* @ngInject */ function clickAndWaitDirective() {
    const directive = {
        restrict: 'A',
        scope: {
            clickAndWait: '&',
        },
        link: clickAndWaitLinkFn,
    }

    return directive

    ///////////////////////////////////

    function clickAndWaitLinkFn(scope, element, attrs) {
        element.on('click', handleClick)
        scope.$on('$destroy', () => element.off('click', handleClick))

        ////////////////////////////////////////////

        function handleClick($event) {
            attrs.$set('disabled', true)
            attrs.$addClass('disabled')

            const promise = scope.clickAndWait({ $event })

            invariant(
                promise && _.isFunction(promise.finally),
                'The return value of the click-and-wait handler must be a Promise'
            )

            promise.finally(function () {
                if (attrs.ngDisabled) {
                    // handle the possibility that there is a separate disabled check,
                    // and we do not want to overwrite it
                    attrs.$set('disabled', scope.$parent.$eval(attrs.ngDisabled))
                } else {
                    attrs.$set('disabled', false)
                }

                attrs.$removeClass('disabled')
            })
        }
    }
}
