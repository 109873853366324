import FastdomWrapper from 'services/FastdomWrapper'

/**
 * @ngdoc directive
 * @name videoTime
 * @module map3.core
 * @restrict E
 *
 * @description
 * A video time widget, showing the current time and total duration, with millisecond precision.
 *
 * @param {videoAPI} videoApi A {@link videoAPI} instance
 */
export default /* @ngInject */ function videoTimeDirective(
    SharedVideoAPI,
    timestampFilter,
    DOMUtility
) {
    const MODE_SECONDS = 'seconds'
    const MODE_FRAMES = 'frames'

    const directive = {
        restrict: 'E',
        link: videoTimeDirectiveLinkFn,
        scope: true,
        template: `
            <button type="button" class="btn btn-unstyled p-a-0"
                ng-dblclick="switchModes()"
            >
                <time class="current"></time> / <time class="total"></time>
            </button>
        `,
    }

    return directive

    ///////////////////////

    function videoTimeDirectiveLinkFn(scope, element) {
        const currentTime = element.find('time.current')
        const totalTime = element.find('time.total')

        const fastdom = new FastdomWrapper()

        let videoApi
        let animationTimeout
        let lastCurrentTime = false
        let lastTotalTime = false
        let mode = MODE_SECONDS

        scope.switchModes = switchModes

        activate()

        ///////////////////////////////

        function activate() {
            updateTotalTime()
            updateCurrentTime()

            SharedVideoAPI.onLoad(handleVideoApiReady)

            scope.$on('$destroy', () => fastdom.destroy())
        }

        function switchModes() {
            if (mode === MODE_SECONDS) {
                mode = MODE_FRAMES
            } else {
                mode = MODE_SECONDS
            }

            updateCurrentTime()
            updateTotalTime()
            updateCurrentTime()
        }

        function handleVideoApiReady(videoApiParam) {
            videoApi = videoApiParam

            videoApi.addEventListener('timeupdate', updateCurrentTime)

            updateTotalTime()
            animateCurrentTime()

            scope.$on('$destroy', () => window.cancelAnimationFrame(animationTimeout))
        }

        function animateCurrentTime() {
            if (videoApi.isPlaying()) {
                updateCurrentTime()
            }

            animationTimeout = window.requestAnimationFrame(animateCurrentTime)
        }

        function updateTotalTime(time) {
            if (mode === MODE_SECONDS) {
                const newTime = videoApi && videoApi.getDuration()
                if (lastTotalTime !== newTime) {
                    $writeText(totalTime, timestampFilter(newTime, true))
                    lastTotalTime = newTime
                }
            } else if (mode === MODE_FRAMES) {
                const newTime = videoApi && videoApi.getDurationInFrames()
                if (lastTotalTime !== newTime) {
                    $writeText(totalTime, newTime)
                    lastTotalTime = newTime
                }
            }
        }

        function updateCurrentTime(time) {
            if (mode === MODE_SECONDS) {
                let newTime = videoApi && videoApi.getCurrentFrameTime()
                if (lastCurrentTime !== newTime) {
                    $writeText(currentTime, timestampFilter(newTime, true))
                    lastTotalTime = newTime
                }
            } else if (mode === MODE_FRAMES) {
                let newTime = videoApi && videoApi.getCurrentFrame()
                if (lastCurrentTime !== newTime) {
                    $writeText(currentTime, newTime)
                    lastTotalTime = newTime
                }
            }
        }

        function $writeText(element, text) {
            fastdom.mutate(() => {
                element.text(text)
            })
        }
    }
}
