import _ from 'lodash'

export default /* @ngInject */ function SharedVideoAPIFactory($rootScope) {
    let videoApi
    let onLoadCallbacks = []

    const SharedVideoAPI = {
        onLoad: function (callback) {
            if (videoApi && videoApi.assertPlayerLoaded()) {
                $rootScope.$applyAsync(callback.bind(null, videoApi))
            } else {
                onLoadCallbacks.push(callback)
            }
        },

        $setup: function (videoApiParam) {
            videoApi = videoApiParam

            videoApi.getPlayer().addEventListener('load', function () {
                _.forEach(onLoadCallbacks, (callback) => {
                    $rootScope.$applyAsync(callback.bind(null, videoApi))
                })

                onLoadCallbacks.length = 0
            })
        },

        $destroy: function () {
            videoApi = null
            onLoadCallbacks.length = 0
        },
    }

    return SharedVideoAPI
}
