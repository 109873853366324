import _ from 'lodash'
import angular from 'angular'

/**
 * Given a question, this function will return a new getter function
 * that takes an annotation (an object with an "answers" array proprty)
 * and returns the string value of the answer for the supplied question uri
 *
 * @param questionUri The `uri` prop of a Question object
 */
function buildGetAnswerValueBase(question: Pick<Question, 'uri'>) {
    return function getAnswerValue({ answers }: { answers: AnnotationAnswer[] }): string {
        return stringifyAnswer(_.find(answers, { id: question.uri }))
    }
}

export const buildGetAnswerValue = _.memoize(buildGetAnswerValueBase)

/**
 * Given an AnnotationAnswer, this function returns its stringified value.
 * When the AnnotationAnswer.value is an array, the labels are extracted, sorted
 * and stringified, so that answers like:
 *
 *      [{ value: 1, label: "Betty" }, { value: 2: "Amir" }]
 *
 * are turned into the string:
 *
 *      "Amir,Betty"
 *
 * Which can be used for string comparisons
 */
export function stringifyAnswer(answer: AnnotationAnswer | undefined): string {
    if (!answer) {
        return ''
    }

    if (angular.isArray(answer.value)) {
        return answer.value
            .map((answerAnswer) => answerAnswer.label)
            .sort()
            .toString()
    }

    return (answer.value ? answer.value.label : '') || ''
}
