import { VideoAPIInstance } from 'video/VideoAPIBuilder.factory'
import { CommentInterfaceInstance } from './CommentInterface.factory'

interface ScopeBinding {
    videoApi: VideoAPIInstance | null
    bookmarkOrCommentModal: () => void
}

export default /* @ngInject */ function commentAddBookmarkDirective(
    CommentInterface: CommentInterfaceInstance,
    SharedVideoAPI: any,
    MapDialog: any
) {
    const directive = {
        restrict: 'E',
        link: commentAddBookmarkLinkFn,
        scope: {
            componentClass: '@?',
        },
        template: `
            <button ng-disabled="!videoApi" ng-click="bookmarkOrCommentModal()"
                ng-class="componentClass" role="button"
            >
                <i class="material-icons md-18">star</i>
                Add Bookmark/Comment
            </button>
        `,
    }

    return directive

    //////////////////////////////////

    function commentAddBookmarkLinkFn(scope: ng.IScope & ScopeBinding) {
        scope.videoApi = null
        scope.bookmarkOrCommentModal = bookmarkOrCommentModal
        SharedVideoAPI.onLoad((videoApi: VideoAPIInstance) => (scope.videoApi = videoApi))

        function bookmarkOrCommentModal() {
            const modalConfig = MapDialog.confirm()
                .title('Do you want to leave a comment for this bookmark?')
                .cancel('No')
                .ok('Leave comment')

            MapDialog.show(modalConfig)
                .then(() =>
                    CommentInterface.showCreateThreadModal(scope.videoApi!.getCurrentFrameTime())
                )
                .catch(() => CommentInterface.emitAddBookmark())
        }
    }
}
