import _ from 'lodash'
import fp from 'lodash/fp'

export default function renderMetadataDirective() {
    var directive = {
        restrict: 'E',
        scope: {
            metadata: '<',
            definition: '<',
            groups: '<',
        },
        transclude: true,
        link: renderMetadataLinkFn,
        template: `
            <dl class="dl-horizontal">
                <ng-transclude></ng-transclude>

                <dt ng-repeat-start="(key, value) in filteredMetadata"
                    ng-init="::fieldDefinition = getFieldDefinition(key, definition)"
                    ng-if="::value !== undefined"
                >
                    {{ ::(fieldDefinition.label || key) | titleCase }}
                </dt>
                <dd ng-repeat-end
                    ng-if="::value !== undefined"
                >
                    <div ng-switch on="::key">
                        <span ng-switch-when="contentType">{{ ::definition.label || value | titleCase }}</span>
                        <span ng-switch-when="programmingType">{{ ::value | titleCase }}</span>
                        <span ng-switch-when="programmingSubType">{{ ::value | titleCase }}</span>

                        <span ng-switch-when="groups">{{ ::renderGroups(value) }}</span>

                        <div ng-switch-default
                             ng-switch on="::fieldDefinition.type"
                        >
                            <span ng-switch-when="date"><tz-date date="::value" format="mediumDate"></tz-date></span>
                            <span ng-switch-default>{{ ::value }}</span>
                        </div>
                    </div>
                </dd>
            </dl>
        `,
    }

    return directive

    /////////////////////////

    function renderMetadataLinkFn(scope) {
        const OMIT_METADATA_FIELDS = ['contentType']

        scope.$watch('metadata', filterMetadata)

        scope.getFieldDefinition = function (fieldName, definition) {
            return _.find(_.get(definition, 'fields', []), { name: fieldName })
        }

        scope.renderGroups = function (groupIds) {
            return fp.flow(
                fp.filter((group) => _.includes(groupIds, group.uri)),
                fp.map('label'),
                fp.join(', ')
            )(scope.groups)
        }

        function filterMetadata(metadata) {
            scope.filteredMetadata = _.omit(metadata, OMIT_METADATA_FIELDS)
        }
    }
}
