import _ from 'lodash'

import invariant from 'util/invariant'
import { naturalSort } from 'filters/orderByNatural.filter'
import MAP3Debug from 'services/MAP3Debug'
import { isFreeTaggingInputType } from 'util/annotateVideo'

export type ParsedQuestionValue = {
    value: string
    label: string
    $$renderLabel?: string
    $$disabled?: boolean
}

type ParsedQuestion = Question & {
    parsedValues: ParsedQuestionValue[]
    hasExamples?: boolean
    $$renderType:
        | typeof QuestionData.TYPE_SELECT_MULTI
        | typeof QuestionData.TYPE_SELECT
        | typeof QuestionData.TYPE_TAG_INPUT
        | typeof QuestionData.TYPE_INVALID_QUESTION
}

type ScopeBindings = {
    question: ParsedQuestion
    answer: AnnotationAnswer
    ngDisabled?: boolean
    onChosenShowingDropdown?: (...args: any) => any
    maxShownResults?: number
}

type ScopeVariables = {
    tabindex: number | undefined
    onTagAdded($tag: { label: string; value?: string }): void
}

export default /* @ngInject */ function questionInputDirective() {
    const directive = {
        restrict: 'E',
        scope: {
            question: '=',
            answer: '=',
            ngDisabled: '<?',
            onChosenShowingDropdown: '&',
            maxShownResults: '<?',
        },
        templateUrl: 'js/directives/answer-questions/questionInput.tpl.html',
        link: questionInputLinkFn,
    }

    return directive

    ////////////////////////////////////

    function questionInputLinkFn(
        scope: ng.IScope & ScopeBindings & ScopeVariables,
        element: ng.IAugmentedJQuery,
        attrs: ng.IAttributes
    ) {
        scope.tabindex = attrs.tabindex
        scope.onTagAdded = ($tag) => {
            // Adds additional property to normalize data for BE
            $tag.value = '' // Can be just empty string
        }

        activate()

        ////////////////////////////////////////////////////

        function activate() {
            invariant(!!scope.question, '[questionInputDirective] "question" param is required')

            scope.question.parsedValues =
                scope.question.parsedValues || QuestionData.parseQuestionValues(scope.question)
            scope.question.$$renderType = QuestionData.getQuestionRenderType(scope.question)

            QuestionData.ensureValidAnswer(scope.answer, scope.question)

            if (scope.question.$$renderType === QuestionData.TYPE_SELECT_MULTI) {
                handleChosenMultiselectRendering()
            }
        }

        function handleChosenMultiselectRendering() {
            let placeholderTimer: NodeJS.Timeout

            scope.$watch('answer', updatePlaceholder, true)

            // allow deselection of chosen options from the dropdown
            element.on('click', '.result-selected', function (e) {
                const elementIndex = $(this).data('option-array-index')

                // click chosen's "remove element" link
                element.find(`.search-choice [data-option-array-index="${elementIndex}"]`).click()

                // we hide this click event because it's outside chosen's dropdown,
                // and that would normally close the dropdown
                e.preventDefault()
                return false
            })

            ///////////////////////////

            function updatePlaceholder(answer: AnnotationAnswer) {
                let placeholderContent = ''

                if (_.isArray(answer.value) && answer.value.length) {
                    placeholderContent = answer.value.length + ' / ' + scope.question.values.length
                }

                writePlaceholder(placeholderContent)
            }

            function writePlaceholder(content: string) {
                clearTimeout(placeholderTimer)

                placeholderTimer = setTimeout(function () {
                    element.find('.chosen-choices').attr('data-question-selected', content)
                }, 0)
            }
        }
    }
}

export class QuestionData {
    static TYPE_SELECT = 'select'
    static TYPE_SELECT_MULTI = 'select-multi'
    static TYPE_TAG_INPUT = 'tag-input'
    static TYPE_INVALID_QUESTION = 'invalid-question'

    /**
     * @param {object} question
     * @return string
     */
    static getQuestionRenderType(question: ParsedQuestion) {
        const questionValues = _.get(question, 'values')

        if (isFreeTaggingInputType(question)) {
            return QuestionData.TYPE_TAG_INPUT
        }

        if (_.isEmpty(questionValues)) {
            return QuestionData.TYPE_INVALID_QUESTION
        }

        return question.multiple ? QuestionData.TYPE_SELECT_MULTI : QuestionData.TYPE_SELECT
    }

    /**
     * @param {object} question
     * @return array
     */
    static parseQuestionValues(question: Question): ParsedQuestionValue[] {
        let baseValues: QuestionValue[] = _.get(question, 'values', [])

        // We omit any values that are not validForTagging (unless debug is enabled)
        if (!MAP3Debug.check(MAP3Debug.ALLOW_INVALID_FOR_TAGGING)) {
            baseValues = baseValues.filter((value) => value && value.validForTagging !== false)
        }

        // and sort the remaining values.
        baseValues = baseValues.sort((a, b) => naturalSort(a.label, b.label))

        // We filter out hiearchy leaves from the values
        const values = _.filter(baseValues, (value) => !value.isSubClassValue)

        // We extract the leaves and add a special render label
        const hiearchyValues: (QuestionValue & { $$renderLabel: string })[] = _.filter(
            baseValues,
            (value) => !!value.isSubClassValue
        ).map((value) => {
            return {
                ...value,
                $$renderLabel: '\u21b3\u00a0\u00a0' + value.label,
            }
        })

        // Finally, we find the hiearchy roots
        const hiearchyRoots = _.filter(baseValues, (value) => !!value.isSubClass).map((value) => ({
            ...value,
            $$disabled: true,
        }))
        // and for each root insert its leaves right after itself in the main values array
        _.forEach(hiearchyRoots, (hiearchyRoot) => {
            const matchingHiearchyValues = _.filter(hiearchyValues, {
                parentSubClass: hiearchyRoot.value,
            })
            const idx = _.findIndex(values, { value: hiearchyRoot.value })
            values.splice(idx, 1, hiearchyRoot, ...matchingHiearchyValues)
        })

        // At the end, we output a new object that will get directly inserted into answer.value by ref
        return _.map(values, (value) =>
            _.pick(value as QuestionValue & { $$renderLabel?: string; $$disabled?: boolean }, [
                'value',
                'label',
                '$$renderLabel',
                '$$disabled',
            ])
        )
    }

    /**
     * Sometimes, backend might return a dataset with outdated or invalid answer values.
     * In that case, <recorded-answers> directive can still display the mismatched data,
     * but if we open it for editing, we must remove any invalid answer values. This function
     * does that, scrubbing anwer.value or anwer.value[] items that do not match question.values
     *
     * @param {object} answer
     * @param {object} parsedQuestion
     */
    static ensureValidAnswer(answer: AnnotationAnswer, parsedQuestion: ParsedQuestion) {
        if (parsedQuestion.$$renderType === QuestionData.TYPE_SELECT) {
            const answerValueValue = _.get(answer, 'value.value', '')

            if (!hasValidQuestionValue(parsedQuestion, answerValueValue)) {
                answer.value = null
            }
        }

        if (parsedQuestion.$$renderType === QuestionData.TYPE_SELECT_MULTI) {
            answer.value = _.filter(answer.value, (answerValue) => {
                const answerValueValue = _.get(answerValue, 'value')

                return hasValidQuestionValue(parsedQuestion, answerValueValue)
            })
        }

        ////////////////////////////////////////////////

        /**
         * A valid question value means that the value is present in the backend
         * list of question values, and that it has "validForTagging: true" prop (or not set)
         */
        function hasValidQuestionValue(parsedQuestion: ParsedQuestion, answerValueValue: string) {
            return !!_.find(parsedQuestion.values, (questionValue) => {
                return (
                    answerValueValue === questionValue.value &&
                    _.get(questionValue, 'validForTagging', true)
                )
            })
        }
    }
}
