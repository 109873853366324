/**
 * @ngdoc directive
 * @name singleClick
 * @module map3.core
 * @description
 * Ignore subsequent clicks to a button/input/a element.
 *
 * Use this to protect form submits from accidental double-clicks.
 *
 *
 * ```html
 * <input type="submit" single-click="temporary" />
 * ```
 *
 * Will attach `disabled="disabled"` attribute and `class="disabled"` class.
 * If `temporary` is set, the disable will be removed after 5 seconds
 *
 * @restrict A
 *
 * @param {string=} singleClick Setting type. The only supported value right now is
 *      `temporary`, which when set will remove the disable after 5 seconds.
 * @param {number=} singleClickTimeout The timeout in secodns, if type is `temporary`.
 */
export default /* @ngInject */ function singleClickDirective($timeout) {
    const DEFAULT_TIMEOUT = 5
    const SETTING_TEMPORARY = 'temporary'

    var directive = {
        restrict: 'A',
        link: singleClickLinkFn,
    }

    return directive

    ////////////////////

    function singleClickLinkFn(scope, element, attrs) {
        let timeoutPromise

        element.on('click', disableOnClick)

        scope.$on('$destroy', () => {
            element.off('click', disableOnClick)

            if (timeoutPromise) {
                $timeout.cancel(timeoutPromise)
            }
        })

        function disableOnClick() {
            if (!attrs.disabled) {
                attrs.$set('disabled', true)
                attrs.$addClass('disabled')

                if (attrs.singleClick === SETTING_TEMPORARY) {
                    timeoutPromise = $timeout(
                        resetDisable,
                        (attrs.singleClickTimeout || DEFAULT_TIMEOUT) * 1000
                    )
                }
            }
        }

        function resetDisable() {
            attrs.$removeClass('disabled')
            attrs.$set('disabled', false)
            timeoutPromise = null
        }
    }
}
