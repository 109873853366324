import _ from 'lodash'

export default /* @ngInject */ function helpMessagesDirective($timeout) {
    var directive = {
        require: '^form',
        scope: {
            model: '@',
            field: '@',
        },
        transclude: true,
        template: `
            <div ng-messages="formErrors" class="messages-form-errors">
                <div ng-messages-include="js/messages/common-error-messages.tpl.html"></div>
            </div>

            <div class="messages-backend-errors">
                <span ng-repeat="error in backendErrors"
                    class="help-block"
                >{{ error }}</span>
            </div>

            <ng-transclude></ng-transclude>
        `,
        link: helpMessagesLinkFn,
    }

    return directive

    ////////////////////////////////////

    function helpMessagesLinkFn(scope, element, attr, ngFormCtrl) {
        let promise = $timeout(
            function () {
                scope.$watch(
                    getFormErrors,
                    (formErrors) => (scope.formErrors = formErrors) // eslint-disable-line
                )

                if (scope.model && scope.field) {
                    scope.$watch(
                        getBackendErrors,
                        (backendErrors) => (scope.backendErrors = backendErrors) // eslint-disable-line
                    )
                }
            },
            0,
            false
        )

        scope.$on('$destroy', () => $timeout.cancel(promise))

        /////////////////////////////////////////////////////////

        function getFormErrors() {
            var formField = _.get(ngFormCtrl, scope.field)
            return formField && formField.$error
        }

        function getBackendErrors() {
            return _.get(scope.$parent.$eval(scope.model), ['errors', scope.field])
        }
    }
}
