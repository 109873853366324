import _ from 'lodash'
import { AdminModuleInstance } from './AdminModule.factory'

interface IAdminJobListCtrlInstance {
    jobs: TJob[]
    allJobs: TJob[]
    predicateJobs: TJob[]
    perfieldPredicate: any
    searchString: string | null
    totalItems: number
    currentPage: number
    itemsPerPage: number
    allJobsLoaded: boolean

    loadFinishedJobs: () => void
    deleteJob: (job: TJob) => void
    resumeJob: (job: TJob) => void
    stopJob: (job: TJob) => void
    suspendJob: (job: TJob) => void
    disableKeepAlive: (job: TJob) => void
    prioritize: (job: TJob) => void
}

export default /* @ngInject */ function AdminJobsListCtrl(
    this: unknown,
    jobs: TJob[],
    $state: ng.ui.IStateService,
    $scope: ng.IScope,
    $filter: ng.IFilterFunction,
    AdminModule: AdminModuleInstance,
    UserPreferences: any,
    Notification: any,
    MapDialog: any
) {
    const adminVm = this as IAdminJobListCtrlInstance
    adminVm.jobs = jobs
    adminVm.allJobs = jobs
    adminVm.totalItems = jobs.length
    adminVm.searchString = null

    adminVm.loadFinishedJobs = loadFinishedJobs
    adminVm.deleteJob = deleteJob
    adminVm.resumeJob = resumeJob
    adminVm.stopJob = stopJob
    adminVm.suspendJob = suspendJob
    adminVm.disableKeepAlive = disableKeepAlive
    adminVm.prioritize = prioritize

    adminVm.perfieldPredicate = UserPreferences.get(
        $state.current.name,
        'adminVm.perfieldPredicate',
        {}
    )
    adminVm.predicateJobs = UserPreferences.get($state.current.name, 'adminVm.predicateJobs', [])

    const perfieldFilter = $filter('perfieldFilter')
    //////////////////////////////

    function loadFinishedJobs() {
        if (adminVm.allJobsLoaded) {
            return
        }

        const finishedJobsPromise = AdminModule.getFinishedJobs()

        Notification.forPromise(finishedJobsPromise, 'All jobs loaded')

        finishedJobsPromise.then((finishedJobs) => {
            adminVm.allJobsLoaded = true
            adminVm.allJobs.push(...finishedJobs)
        })
    }

    adminVm.totalItems = 0
    adminVm.currentPage = 1
    adminVm.itemsPerPage = 50

    $scope.$watchCollection('adminVm.predicateJobs', prepareJobsArray)
    $scope.$watchCollection('adminVm.allJobs', prepareJobsArray)
    $scope.$watch('adminVm.perfieldPredicate', prepareJobsArray, true)
    $scope.$watch('adminVm.currentPage', prepareJobsArray)
    $scope.$watch('adminVm.searchString', prepareJobsArray)

    function prepareJobsArray() {
        const filteredJobs = perfieldFilter(adminVm.allJobs, adminVm.perfieldPredicate)
        const orderedJobs = $filter('orderBy')(filteredJobs, adminVm.predicateJobs)
        const filteredBySearch = adminVm.searchString
            ? $filter('filter')(orderedJobs, adminVm.searchString)
            : orderedJobs

        adminVm.jobs = filteredBySearch.slice(
            (adminVm.currentPage - 1) * adminVm.itemsPerPage,
            adminVm.currentPage * adminVm.itemsPerPage
        )
        adminVm.totalItems = filteredBySearch.length
    }

    function resumeJob(job: TJob) {
        const resumePromise = AdminModule.resumeJob(job)

        Notification.forPromise(resumePromise, 'Job successfully resumed')

        resumePromise.then(() => {
            job.status = 'ongoing'
        })
    }

    function suspendJob(job: TJob) {
        const suspendPromise = AdminModule.suspendJob(job)

        Notification.forPromise(suspendPromise, 'Job successfully suspended')

        suspendPromise.then(() => {
            job.status = 'suspended'
        })
    }

    function stopJob(job: TJob) {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to stop this job?')
            .ok('Yes')
            .cancel('No')
        MapDialog.show(confirmDialog)
            .then(() => {
                const stopPromise = AdminModule.stopJob(job)

                Notification.forPromise(stopPromise, 'Job successfully stopped')

                return stopPromise
            })
            .then(() => {
                job.status = 'stopped'
            })
    }

    function deleteJob(job: TJob) {
        const confirmDialog = MapDialog.confirm()
            .title('Are you sure?')
            .textContent('Are you sure you want to delete this job?')
            .ok('Yes')
            .cancel('No')

        MapDialog.show(confirmDialog)
            .then(() => {
                const deletePromise = AdminModule.deleteJob(job)

                Notification.forPromise(deletePromise, 'Job successfully deleted')

                return deletePromise
            })
            .then(() => {
                _.remove(adminVm.jobs, job)
            })
    }

    function disableKeepAlive(job: TJob) {
        const resumePromise = AdminModule.disableKeepAlive(job)
        Notification.forPromise(resumePromise, 'Keep Alive disabled')
    }

    function prioritize(job: TJob) {
        const promise = AdminModule.prioritizeJob(job, job.priority)

        Notification.forPromise(promise, 'Job priority changed')
    }
}

AdminJobsListCtrl.resolve = {
    jobs: /* @ngInject */ (AdminModule: AdminModuleInstance) => {
        return AdminModule.getActiveJobs()
    },
}
