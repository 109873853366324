import * as d3 from 'd3'
import d3tip from 'd3-tip'
import _ from 'lodash'

export default /* @ngInject */ function donutChart() {
    let directive = {
        restrict: 'E',
        link: donutChartLinkFn,
        scope: {
            job: '<',
        },
    }

    return directive

    ////////////////////

    function donutChartLinkFn(scope, element, attrs) {
        const colors = { active: '#f0ad4e', completed: '#5cb85c', unassigned: '#d9534f' }

        let tip = d3tip()
            .attr('class', 'd3-tip')
            .offset([-10, 0])
            .html(function (d) {
                return `
                    <div class='graph-tooltip tooltip-top' style='background-color: ${d.data.color}; border-color: ${d.data.color};'>
                        <section class='tooltip-arrow'  style='background-color: ${d.data.color};'></section>
                        <strong>${d.data.name}:</strong>
                        <span> ${d.value} tasks </span>
                    </div>
                `
            })

        let data = []
        _.forOwn(_.pick(scope.job.tasks, ['active', 'unassigned', 'completed']), (value, key) => {
            data.push({
                value,
                name: key,
                color: colors[key],
            })
        })

        let config = {
            width: 120,
            height: 120,
            radius: 120 / 2,
        }

        let svg = d3
            .select(element[0])
            .append('svg')
            .attr('class', 'pie')
            .attr('width', config.width)
            .attr('height', config.height)

        svg.call(tip)

        let g = svg
            .append('g')
            .attr('transform', 'translate(' + config.width / 2 + ',' + config.height / 2 + ')')

        let arc = d3.arc().innerRadius(0).outerRadius(config.radius)

        let pie = d3
            .pie()
            .value((d) => d.value)
            .sort(null)

        g.selectAll('path')
            .data(pie(data))
            .enter()
            .append('g')
            .append('path')
            .attr('d', arc)
            .attr('fill', (d) => d.data.color)
            .on('mouseover', tip.show)
            .on('mouseout', tip.hide)
            .each(function (d, i) {
                this._current = i
            })
    }
}
