import _ from 'lodash'

const NODE_ERROR_MSGS = {
    NEED_QUERY: 'Search for videos.',
    NEED_QUERY_RESULT: 'You need at least one video from the video search result.',
}

export default /* @ngInject */ function WorkflowWizardCtrl(
    $scope,
    $window,
    AdminModule,
    MapDialog,
    taskConfig,
    taskTemplateUrl,
    node,
    graph,
    workflow,
    autoSubmit,
    wizards,
    taskDefinition,
    taskDefaultConfig,
    isEditable
) {
    const configVm = this

    configVm.isEditable = isEditable
    configVm.templateUrl = taskTemplateUrl
    configVm.node = node
    configVm.graph = graph
    configVm.workflow = workflow
    configVm.taskDefinition = taskDefinition
    configVm.taskConfig = taskConfig
    configVm.autoSubmit = autoSubmit
    configVm.wizards = wizards
    configVm.showReset = !!taskDefaultConfig && isEditable

    configVm.haveAtLeastOneVideo = false

    configVm.saveConfig = saveConfig
    configVm.updateConfig = updateConfig
    configVm.resetToDefault = resetToDefault

    activate()

    ///////////////////////////

    function activate() {
        // make sure we have groups and users for every possible
        configVm.wizards = _.defaults(configVm.wizards, {
            groups: [],
            users: [],
        })
        configVm.taskConfig.internal_options = _.defaults(configVm.taskConfig.internal_options, {
            groups: [],
            users: [],
        })

        if (!configVm.isEditable) {
            // THIS IS DUMB HACK BUT IT'S NEEDED
            const missingGroups = _.difference(
                configVm.taskConfig.internal_options.groups,
                configVm.wizards.groups
            )
            configVm.wizards.groups = configVm.wizards.groups.concat(missingGroups)

            const missingUsers = _.difference(
                configVm.taskConfig.internal_options.users,
                configVm.wizards.users
            )
            configVm.wizards.users = configVm.wizards.users.concat(missingUsers)

            if (!configVm.taskConfig.jsonArray) {
                configVm.taskConfig.jsonArray = [configVm.taskConfig.json]
            }
        }

        if (!configVm.taskConfig.jsonArray) {
            configVm.taskConfig.jsonArray = [configVm.taskConfig.json]
        }

        configVm.wizards.users_and_groups = _.concat(
            _.map(configVm.wizards.groups, (group) => ({
                type: 'Group',
                name: group,
            })),
            _.map(configVm.wizards.users, (user) => ({
                type: 'User',
                name: user,
            }))
        )

        // Add dynamic validation  if we are inside 'GetVideos' template
        if (configVm.wizards.template === 'GetVideos') {
            $scope.$watchGroup(
                ['configVm.taskConfig.keepAlive', 'configVm.haveAtLeastOneVideo '],
                () => {
                    if (!configVm.taskConfig.keepAlive && !configVm.haveAtLeastOneVideo) {
                        addNodeError(NODE_ERROR_MSGS.NEED_QUERY_RESULT)
                        return
                    }

                    removeNodeError(NODE_ERROR_MSGS.NEED_QUERY_RESULT)
                }
            )

            $scope.$watchCollection('configVm.taskConfig.jsonArray', (newValue, oldValue) => {
                if (!configVm.taskConfig.jsonArray.length) {
                    addNodeError(NODE_ERROR_MSGS.NEED_QUERY)
                    return
                }

                removeNodeError(NODE_ERROR_MSGS.NEED_QUERY)
            })
        }
    }

    function saveConfig() {
        node.info.config = configVm.taskConfig
        $scope.$dismiss()
    }

    function updateConfig() {
        AdminModule.testWorkflowEdit(configVm.workflow.general.uri, node.uri, configVm.taskConfig)
            .then((data) => {
                const warningModal = MapDialog.confirm()
                    .title('This changes will take effect')
                    .htmlContent(
                        `
                    <strong>Do you want to save the changes?</strong>
                    <ul>
                        ${_.map(data.changes, (change) => `<li>${change}</li>`).join('')}
                    </ul>
                `
                    )
                    .ok('Confirm')
                    .cancel('Cancel')

                MapDialog.show(warningModal).then(() => {
                    AdminModule.workflowEdit(
                        configVm.workflow.general.uri,
                        node.uri,
                        configVm.taskConfig
                    ).then((res) => {
                        node.info.config = configVm.taskConfig

                        $scope.$emit('job-status.update', res.data)
                        $scope.$dismiss()
                    })
                })
            })
            .catch((data) => {
                const errorModal = MapDialog.alert()
                    .title('There has been some errors')
                    .htmlContent(
                        `<ul>
                    ${_.map(data.errors, (error) => `<li>${error.msg}</li>`).join('')}
                </ul>`
                    )
                    .cancel('Close')

                MapDialog.show(errorModal)
            })
    }

    function addNodeError(msg) {
        if (!_.isArray(configVm.node.errors)) {
            configVm.node.errors = [msg]
            return
        }

        if (configVm.node.errors.includes(msg)) return

        configVm.node.errors.push(msg)
    }

    function removeNodeError(msg) {
        if (!_.isArray(configVm.node.errors)) return

        if (configVm.node.errors.includes(msg)) {
            _.pull(configVm.node.errors, msg)
        }
    }

    function resetToDefault() {
        if (taskDefaultConfig) {
            if ($window.confirm('Are you sure you want to reset the wizard')) {
                configVm.taskConfig = angular.copy(taskDefaultConfig)
            }
        }
    }
}

WorkflowWizardCtrl.controllerAs = 'configVm'
