export default /* @ngInject */ function orderByFilterExtensionConfig($provide) {
    /**
     * Extension for [orderByFilter](https://docs.angularjs.org/api/ng/filter/orderBy)
     *
     * Sort `null` to be always on the bottom against any other value type, but do not change
     * order between items with null value
     */
    $provide.decorator(
        'orderByFilter',
        /* @ngInject */ function ($delegate) {
            return function (array, sortPredicate, reverseOrder, compareFn) {
                var compare = angular.isFunction(compareFn) ? compareFn : modifiedDefaultCompare

                return $delegate(array, sortPredicate, reverseOrder, compare)
            }

            function modifiedDefaultCompare(v1, v2) {
                /* eslint-disable */

                // handle null should always be sorted at the end, but not be reordered between eachother
                if (
                    v1.type === 'string' &&
                    v1.value === 'null' &&
                    v2.type === 'string' &&
                    v2.value === 'null'
                ) {
                    // for both null, use the position of the object in the collection
                    return v1.index < v2.index ? -1 : 1
                }
                if (v1.type === 'string' && v1.value === 'null') {
                    return -1
                }
                if (v2.type === 'string' && v2.value === 'null') {
                    return 1
                }

                var result = 0
                var type1 = v1.type
                var type2 = v2.type

                if (type1 === type2) {
                    var value1 = v1.value
                    var value2 = v2.value

                    if (type1 === 'string') {
                        // Compare strings case-insensitively
                        value1 = value1.toLowerCase()
                        value2 = value2.toLowerCase()
                    } else if (type1 === 'object') {
                        // For basic objects, use the position of the object
                        // in the collection instead of the value
                        if (angular.isObject(value1)) value1 = v1.index
                        if (angular.isObject(value2)) value2 = v2.index
                    }

                    if (value1 !== value2) {
                        result = value1 < value2 ? -1 : 1
                    }
                } else {
                    result = type1 < type2 ? -1 : 1
                }

                return result
            }
        }
    )
}
