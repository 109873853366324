export * as PROGRAMMING_TYPE_CONSTANTS from './programmingTypeConstants'

export const SESSION_STORAGE_SELECTED_PROJECT = 'selectedProject'
export const PERMANENT_VERTICAL_SCROLL_CLASS = 'js-with-permanent-vertical-scroll'
export const FILMSTRIP_DEFAULT_WIDTH = 108
export const CHROME_MAX_WIDTH = 16777216

export const WORKFLOW_ELEMENTS = {
    COMMON: ['GetVideos7', 'GetQuestions5', 'S3', 'ViewVideos4'],
    BASIC: [
        'QuestionVideos7',
        'AssistedAnnotate6',
        'TagScenes6',
        'MWD',
        'QATask2',
        'SceneBreaker6',
    ],
    MARKER: [
        'GetChapters',
        'MarkerLoad',
        'PublishMarkers',
        'MarkerAnnotate',
        'MarkerReviewQA',
        'CBQA',
        'EditMarkersQA',
        'AutomationInput',
    ],
    SPECIAL: [
        'AutomationTask',
        'BBTagging2',
        'CutnTag7',
        'ImageTagging',
        'MultipleImageTagging',
        'ShapeTagging',
        'TaskMapper',
    ],
}

export const QA_ROUTES = {
    CONTENT_MARKERS: {
        URL: '/:id/markers',
        STATE_NAME: 'qa.contentMarkers',
    },
    REVIEW_CONTENT_MARKERS: {
        URL: '/:id/markers-list',
        STATE_NAME: 'qa.review.contentMarkers',
    },
    QA_LIST: {
        URL: '',
        STATE_NAME: 'qa.list',
    },
}

export const WORKER_ROUTES = {
    WORKER_LIST: {
        URL: '',
        STATE_NAME: 'worker.list',
    },
    CONTENT_MARKERS: {
        URL: '/:assignmentID/markers',
        STATE_NAME: 'worker.contentMarkers',
    },
    WATCH_VIDEO: {
        URL: '/:assignmentID/watch-video',
        STATE_NAME: 'worker.watch',
    },
}

export enum SMS_CONTENT_TYPE {
    series = 'SERIES',
    feature = 'FEATURE',
}

export enum REPUBLISH_STATUS_TYPE {
    IN_PROGRESS = 'In Progress',
    PUBLISHED = 'Published',
    ERROR = 'Error',
}

export enum UserType {
    Admin = 'admin',
    Worker = 'worker',
    QA = 'qa',
}

export enum VGPlayerTimeFormat {
    Clock = 'Clock',
    Tape = 'Tape',
    TapeDF = 'TapeDF',
    Frame = 'Frame',
    Seconds = 'Seconds',
}

export enum ReportFormat {
    xlsx = 'xlsx',
    csv = 'csv',
    json = 'json',
}

export enum Environment {
    Development = 'development',
    LocalDevelopment = 'local-development',
    Preproduction = 'preproduction',
    Production = 'production',
}

export enum ApiURL {
    Dev = 'https://map3-dev-us-east-1.md.aws.seabc.go.com',
}

export enum NewRelicErrorCode {
    InvalidContentMarkerPairs = 0,
}

export enum ErrorReplacementId {
    IdleWarn,
}

export enum ExportStatus {
    // initial modal status, no export in progress
    Init = 'export_status_init',
    // checking if export data is valid
    Checking = 'export_status_checking',
    // polling for export to become complete by backend worker
    Polling = 'export_status_polling',
    // export was completed by backend worker and is ready for download
    DownloadReady = 'export_status_download_ready',
    // export has failed
    Failed = 'failed',
    NoResults = 'export_status_no_results',
}

export const FREE_TEXT_TAGGING_CLASS = 'free-text-tagging'
export const FREE_TEXT_QUESTION_TYPE = 'free-tagging-input'

export const DF_LIKE_VIDEOS = [29.97, 59.94]
