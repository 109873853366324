import * as d3 from 'd3'
import _ from 'lodash'
import fp from 'lodash/fp'

export default /* @ngInject */ function keepAliveChart($window) {
    let directive = {
        restrict: 'E',
        link: keepAliveChartLinkFn,
        scope: {
            jobs: '=*',
        },
    }

    return directive

    function keepAliveChartLinkFn(scope, element, attrs) {
        let xScale,
            yScaleMax,
            yScale,
            xAxis,
            yAxis,
            xAxisTicks,
            lineOpen,
            lineCumulative,
            svg,
            chart,
            config
        let parseDate = d3.timeParse('%Y-%m-%d')
        let $$margins = {
            top: 20,
            right: 20,
            bottom: 20,
            left: 50,
        }

        config = {
            width: element.width(),
            height: element.width() * 0.4,
        }

        chart = d3.select(element[0])

        _.forEach(scope.jobs, (job) => {
            job.date = parseDate(job.date)
        })

        xAxisTicks = fp.flow(
            fp.map('date'),
            fp.uniqBy((date) => date.toISOString())
        )(scope.jobs)

        svg = chart.append('svg').style('width', config.width).style('height', config.height)

        lineOpen = d3
            .line()
            .curve(d3.curveLinear)
            .x(function (d) {
                return xScale(d.date)
            })
            .y(function (d) {
                return yScale(d.open)
            })

        lineCumulative = d3
            .line()
            .curve(d3.curveLinear)
            .x(function (d) {
                return xScale(d.date)
            })
            .y(function (d) {
                return yScale(d.total)
            })

        prepareScalesAndDrawAxis()
        drawBands()
        drawLines()

        angular.element($window).on('resize', handleResizeFn)
        scope.$on('$destroy', function () {
            angular.element($window).off('resize', handleResizeFn)
        })

        handleResizeFn()

        ////////////////////

        function drawLines() {
            svg.append('path')
                .data([scope.jobs])
                .attr('fill', 'none')
                .attr('stroke', '#d9534f')
                .attr('stroke-linejoin', 'round')
                .attr('stroke-linecap', 'round')
                .attr('stroke-width', 2.5)
                .attr('d', lineOpen)

            if (_.some(scope.jobs, (job) => job.total !== null)) {
                svg.append('path')
                    .data([scope.jobs])
                    .attr('fill', 'none')
                    .attr('stroke', '#f0ad4e')
                    .attr('stroke-linejoin', 'round')
                    .attr('stroke-linecap', 'round')
                    .attr('stroke-width', 2.5)
                    .attr('d', lineCumulative)
            }
        }

        function drawBands() {
            svg.append('g')
                .attr('transform', 'translate(0,' + (config.height - $$margins.bottom) + ')')
                .call(xAxis.ticks(xAxisTicks.length || 0))

            svg.append('g')
                .attr('transform', 'translate(' + $$margins.left + ', 0)')
                .call(yAxis)
        }

        function drawNoDataText() {
            svg.append('text')
                .attr('class', 'no-data-text')
                .attr('x', config.width / 2)
                .attr('y', config.height / 2)
                .style('text-anchor', 'middle')
                .text('No Data')
                .style('font-size', '40px')
        }

        function prepareScalesAndDrawAxis() {
            xScale = d3
                .scalePoint()
                .range([$$margins.left, config.width - $$margins.right])
                .domain(xAxisTicks)

            xAxis = d3.axisBottom(xScale).tickValues(xAxisTicks).tickFormat(d3.timeFormat('%m/%d'))

            yScaleMax = d3.max(scope.jobs, function (d) {
                return Math.max(d.total, d.open)
            })
            yScale = d3
                .scaleLinear()
                .range([config.height - $$margins.top, $$margins.bottom])
                .domain([0, yScaleMax + 5])

            yAxis = d3.axisLeft(yScale).tickFormat(d3.format('d'))
        }

        function handleResizeFn() {
            svg.remove()
            config.width = element.width()
            config.height = element.width() * 0.4

            svg = chart.append('svg').style('width', config.width).style('height', config.height)

            if (_.size(scope.jobs)) {
                prepareScalesAndDrawAxis()
                drawBands()
                drawLines()
            } else {
                drawNoDataText()
            }
        }
    }
}
