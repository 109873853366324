import fp from 'lodash/fp'
import _ from 'lodash'
import angular from 'angular'

import {
    QAAnnotation,
    ANNOTATION_STATUS_APPROVED,
    ANNOTATION_STATUS_REJECTED,
} from './QAAnnotation.factory'
import { ErrorStringifierInstance } from 'services/ErrorStringifier.factory'
import { QAAssignmentClass } from 'qa/types'

export type AnnotationGroup = {
    groupID: number
    consensus: 'unknown' | 'dispute' | 'full'
    sceneData: unknown | false
    annotations: QAAnnotation[]
}

export type AnnotationTree = AnnotationGroup[]
export type BackendSegment = {
    id: number
    start: number
    end: number
}

export type ReviewDataPartial = {
    workers: unknown[]
    scenes: readonly BackendSegment[] | null
    subScenes: readonly BackendSegment[] | null
    annotationTree: AnnotationTree
}
export type ReviewDataFull = ReviewDataPartial & {
    review_class: QAAssignmentClass.joinQuestions
    bookmarks: unknown[]
    options: {
        use_timestamp: boolean
        use_bounding_box: boolean
        questions: unknown[]
    }
    show_instructions: boolean
}

export default /* @ngInject */ function QAAnnotationTreeFactory(
    $q: ng.IQService,
    $http: ng.IHttpService,
    Notification: any,
    ErrorStringifier: ErrorStringifierInstance,
    MarkerTransformation: any
) {
    const URL = '/api/hit/qa' as const

    /**
     * @ngdoc service
     * @module map3.qa
     * @name QAAnnotationTree
     */
    const QAAnnotationTree = {
        /**
         * Get annotation data
         */
        getReviewData(hitID: string): ng.IPromise<ReviewDataFull> {
            return $http
                .get<ReviewDataFull>(URL + '/' + hitID)
                .then((res) => res.data)
                .catch((error) => {
                    const errorMessage = ErrorStringifier.stringify(error)
                    Notification.error(errorMessage)

                    return $q.reject(error)
                })
        },

        /**
         * Approve a review
         *
         */
        approve: function (hitID: string) {
            return $http.post(URL + '/' + hitID + '/approve', {})
        },

        /**
         * Reject a review
         */
        reject: function (hitID: string, worker_ids: string[], message: string) {
            // eslint-disable-line camelcase
            const data = {
                worker_ids,
                message: message,
            }

            return $http.post(URL + '/' + hitID + '/reject', data)
        },

        /**
         * Extract markers from an annotationTree
         */
        extractMarkers: function (annotationTree: AnnotationTree): unknown[] {
            return fp.flow(
                fp.map((group: AnnotationGroup) => group.annotations),
                fp.flatten,
                fp.thru(MarkerTransformation.tags)
            )(annotationTree) as any
        },

        //
        // NON API METHODS
        //

        /**
         * When not given a `currentTree`, it just returns the new tree.
         *
         * When given a current tree, it assumes `newTree` only contains updated
         * groups, and it merges those into the current tree
         */
        mergeAnnotationTree(currentTree: AnnotationTree, newTree: AnnotationTree): AnnotationTree {
            if (!currentTree) {
                return newTree
            }

            const mergedTree = angular.copy(currentTree)

            _.forEach(newTree, (newGroup) => {
                const existingGroupIndex = _.findIndex(
                    currentTree,
                    (group) => group.groupID === newGroup.groupID
                )

                if (existingGroupIndex < 0) {
                    mergedTree.push(newGroup)
                } else {
                    mergedTree[existingGroupIndex] = newGroup
                }
            })

            return mergedTree
        },

        extractAllAnnotations(annotationTree: AnnotationTree): QAAnnotation[] {
            return fp.flow(
                // extract all annotations from groups, attaching `groupID` prop
                fp.map((group: AnnotationGroup) => {
                    return _.map(group.annotations, (annotation) => {
                        annotation.groupID = group.groupID
                        return annotation
                    })
                }),
                // flatten group annotation arrays
                fp.flatten,
                fp.sortBy((annotation) => annotation.timestamp)
            )(annotationTree)
        },

        extractBBAnnotations(annotationTree: AnnotationTree): QAAnnotation[] {
            return _.flatMap(annotationTree, ({ annotations }) => {
                const firstApproved = _.find(annotations, {
                    status: ANNOTATION_STATUS_APPROVED,
                })
                if (firstApproved) {
                    return [firstApproved]
                }
                // fix for multiple rejected
                const allRejected = _.every(annotations, {
                    status: ANNOTATION_STATUS_REJECTED,
                })
                if (allRejected) {
                    return [annotations[0]]
                }
                return annotations
            })
        },
    }

    return QAAnnotationTree
}

export const isReviewDataFull = (u: unknown): u is ReviewDataFull => {
    return !!(u as any).options
}

export type QAAnnotationTreeInstance = ReturnType<typeof QAAnnotationTreeFactory>
