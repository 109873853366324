import routes from './routes'

import MissingSongsFactory from './MissingSongs.factory'
import boundryBoxMarkerComponent from '../services/boundingBoxMarker.component'

/**
 * @ngdoc module
 * @name map3.public
 * @module map3.public
 *
 * @description
 * Module for publicly accessible routes
 */
const map3public = angular
    .module('map3.public', [require('angular-ui-router')])
    .config(routes)

    .component('boundryBoxMarker', boundryBoxMarkerComponent)

    .factory('MissingSongs', MissingSongsFactory)

export default map3public.name
