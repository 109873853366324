import _ from 'lodash'
import { interpret } from 'xstate'

import { createAPIMachine } from './api.machine'
import { TaskServiceInstance } from './TaskService.factory'
import { DataEntryNetworkGuardInstance } from '../services/annotation/DataEntryNetworkGuard.factory'

import {
    GlobalShortcutsInstance,
    ShortcutDefinition,
} from '../global-shortcuts/GlobalShortcuts.factory'
import { trackButtonClick } from 'util/snowplow'

type Image = {
    url: string
    id: number
    name: string
    hint: string | null
}

export default /* @ngInject */ function AnnotateImageCtrl(
    this: unknown,
    $scope: ng.IRootScopeService,
    $state: ng.ui.IStateService,
    task: ImageTaggingData,
    assignmentID: string,
    TaskService: TaskServiceInstance,
    DataEntryNetworkGuard: DataEntryNetworkGuardInstance,
    Notification: any,
    MapDialog: any,
    GlobalShortcuts: GlobalShortcutsInstance
) {
    interface AnnotateImageCtrlInstance {
        loading: boolean

        annotation: BaseAnnotation
        questions: Question[]
        currentImage: Image
        totalImagesCount: number
        currentIndex: number
        free_text_labels: string[]
        free_text_question: string | null

        changeImageIndex: typeof changeImageIndex
        onSubmit: typeof onSubmit
    }

    const vm = this as AnnotateImageCtrlInstance

    vm.loading = false
    vm.questions = task.questions
    vm.free_text_question = task.free_text_question
    vm.free_text_labels = _.isArray(task.free_text_labels) ? task.free_text_labels : []

    vm.totalImagesCount = task.total_images
    vm.annotation = task.annotations.length
        ? task.annotations[0]
        : { timestamp: false, answers: [] }

    vm.changeImageIndex = changeImageIndex
    vm.onSubmit = onSubmit

    activate()

    ///////////////////////////////////////

    function activate() {
        // API machine
        const initialData = {
            annotations: [vm.annotation],
            free_text_labels: vm.free_text_labels,
        }
        const apiService = interpret(
            createAPIMachine<{ annotations: any[]; free_text_labels: string[] }>({
                saveFn: DataEntryNetworkGuard.create(saveData) as any,
                initialData,
            })
        )
        apiService.start()
        $scope.$on('$destroy', () => apiService.stop())
        $scope.$watch(
            'vm.annotation.answers',
            () =>
                apiService.send({
                    type: 'UPDATE_DATA',
                    data: {
                        annotations: [vm.annotation],
                        free_text_labels: vm.free_text_labels,
                    },
                }),
            true
        )
        $scope.$watchCollection('vm.free_text_labels', () =>
            apiService.send({
                type: 'UPDATE_DATA',
                data: {
                    annotations: [vm.annotation],
                    free_text_labels: vm.free_text_labels,
                },
            })
        )
        // end API machine

        setupShortcuts()

        setCurrentImage(task)

        $(window).trigger('resize')
    }

    ///////////////////////////////////////

    function setupShortcuts() {
        const shortcuts: ShortcutDefinition[] = [
            {
                description: 'Go To Next Image',
                keyCombo: 'right',
                shortcut: 'Right Arrow',
                callback: () => {
                    changeImageIndex(1)
                },
            },
            {
                description: 'Go To Previous Image',
                keyCombo: 'left',
                shortcut: 'Left Arrow',
                callback: () => {
                    changeImageIndex(-1)
                },
            },
            {
                description: 'Go To Undecided',
                keyCombo: 'u',
                shortcut: 'U',
                callback: () => {
                    fetchNextUndecided()
                },
            },
        ]

        const unbind = GlobalShortcuts.bind({ title: 'Single Image Tagging', shortcuts })
        $scope.$on('$destroy', unbind)
    }

    const submitConfig = MapDialog.confirm()
        .title('Submit task')
        .htmlContent(
            `
                <strong>Are you sure you want to proceed?</strong>
                <p>When you submit your changes will be stored and you won't be able to edit them anymore.</p>
            `
        )
        .ok('Yes')
        .cancel('No')

    function onSubmit() {
        trackButtonClick({
            label: 'Submit Task',
            componentName: 'Single Image Tagging Task Submit',
        })
        MapDialog.show(submitConfig).then(() => {
            TaskService.submitTask(assignmentID).then(() => {
                $state.go('worker.list')
            })
        })
    }

    const debouncedGetImageData = _.debounce(fetchImage, 700, { trailing: true, leading: false })

    function changeImageIndex(offset: 1 | -1) {
        if (vm.loading) {
            return
        }
        const totalImages = vm.totalImagesCount
        const nextPage = vm.currentIndex + offset

        vm.currentIndex = nextPage === 0 ? totalImages : nextPage > totalImages ? 1 : nextPage

        debouncedGetImageData()
    }

    function setCurrentImage(data: Omit<ImageTaggingData, 'questions'>) {
        vm.currentImage = {
            id: data.image_id,
            url: data.url,
            name: data.image_name,
            hint: data.hint,
        }

        vm.currentIndex = data.index

        vm.annotation = data.annotations.length
            ? data.annotations[0]
            : { timestamp: false, answers: [] }

        vm.free_text_labels = _.isArray(data.free_text_labels) ? data.free_text_labels : []
    }

    function fetchImage() {
        vm.loading = true

        TaskService.getImage<ImageTaggingData>(assignmentID, vm.currentIndex)
            .then(setCurrentImage, () =>
                Notification.error(
                    'Image fetching failed. Try refreshing the page. If this error persist, please, contact an administrator.'
                )
            )
            .finally(() => {
                vm.loading = false
            })
    }

    function fetchNextUndecided() {
        vm.loading = false

        TaskService.getNextUndecided<ImageTaggingData>(assignmentID, vm.currentIndex)
            .then(
                (res) => {
                    if (res.status === 204) {
                        Notification.success('There are no more undecided images.')

                        return
                    }

                    setCurrentImage(res.data)
                },
                () =>
                    Notification.error(
                        'Page fetching failed. Try refreshing the page. If this error persist, please, contact an administrator.'
                    )
            )
            .finally(() => {
                vm.loading = false
            })
    }

    function saveData() {
        const lastSavedData = {
            annotations: _.clone([vm.annotation]),
            free_text_labels: _.clone(vm.free_text_labels),
        }
        return TaskService.updateImageAnnotation(
            assignmentID,
            vm.currentImage.id,
            lastSavedData
        ).then(() => lastSavedData)
    }
}

AnnotateImageCtrl.resolve = {
    assignmentID: /* @ngInject */ function ($stateParams: ng.ui.IStateParamsService) {
        return $stateParams.assignmentID
    },

    task: /* @ngInject */ function (TaskService: any, assignmentID: string): ImageTaggingData {
        return TaskService.getTask(assignmentID)
    },
}
