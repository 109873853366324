import { AdminUsersModuleInstance } from './AdminUsersModule.factory'

interface IZone {
    id: number
    utc: string
    gmt: string
    name: string
}

interface IAdminCreateUserCtrlInstance {
    allDefinedRoles: TRole[]
    allDefinedGroups: Group[]
    tempGroups: Group[]
    tempRoles: TRole[]
    rolesError: boolean
    user: any
    createUser: () => void
    zones: IZone[]
    userCreateForm: any
}

export default /* @ngInject */ function AdminCreateUserCtrl(
    this: unknown,
    $state: ng.ui.IStateService,
    AdminUsersModule: AdminUsersModuleInstance,
    Notification: any,
    MAP3_ROLES: TRole[],
    groups: Group[]
) {
    const adminVm = this as IAdminCreateUserCtrlInstance

    adminVm.allDefinedRoles = MAP3_ROLES
    adminVm.allDefinedGroups = groups

    adminVm.tempGroups = []
    adminVm.tempRoles = []
    adminVm.rolesError = false
    adminVm.user = {}

    adminVm.createUser = createUser

    adminVm.zones = [
        {
            id: 1,
            utc: 'UTC-08:00',
            gmt: 'GMT-8',
            name: 'America/Los_Angeles',
        },
        {
            id: 2,
            utc: 'UTC-05:00',
            gmt: 'GMT-5',
            name: 'America/Toronto',
        },
        {
            id: 3,
            utc: 'UTC+02:00',
            gmt: 'GMT+2',
            name: 'Europe/Sofia',
        },
    ]

    function createUser() {
        // Check for choosen Roles. They are a must.
        if (adminVm.tempRoles.length === 0) {
            adminVm.rolesError = true
            return
        }

        adminVm.user.roles = adminVm.tempRoles
        adminVm.user.groups = adminVm.tempGroups

        const createUserPromise = AdminUsersModule.createUser(adminVm.user)

        Notification.forPromise(
            createUserPromise,
            `User "${adminVm.user.username}" successfully created.`
        )

        createUserPromise
            .then(() => {
                adminVm.userCreateForm && adminVm.userCreateForm.$setPristine()
                delete adminVm.user.errors

                $state.go('admin.manageUsers', {}, { reload: true })
            })
            .catch((res: any) => {
                adminVm.user.errors = res.data
            })
    }
}

AdminCreateUserCtrl.resolve = {
    groups: /* @ngInject */ function (AdminUsersModule: AdminUsersModuleInstance) {
        return AdminUsersModule.getGroups()
    },
}
